import React from 'react';
import { IonContent, IonItemDivider, IonLabel, IonPage } from '@ionic/react';
import './style.scss';
import { RouteComponentProps } from 'react-router';
import { TopNavbar } from '../common/header/topbar';
import { ContactsList } from './components/ContactsList';
import { connect } from 'react-redux';
import { ContactsListMode } from '../../services/enumService';
import { locale } from '../../locales/local';
import { ContactStateTypes, ProfileStateTypes, ReduxStates } from '../../types/redux-states';
import { Navigation } from '../../services/navigationService';

interface iProps extends RouteComponentProps<{ name: string }> {
	contact: ContactStateTypes;
	profile: ProfileStateTypes;
}
interface iState {}

class RecentContacts extends React.Component<iProps, iState> {
	componentIsMounted: Boolean = false;

	constructor(props: iProps) {
		super(props);

		this.state = {};
	}

	setPageData = async () => {
		this.componentIsMounted = true;
	};

	componentDidMount = async () => {
		if (!this.componentIsMounted) {
			await this.setPageData();
		}
	};

	setSearchText = () => {};

	render() {
		const { contacts, receivedRequests, sentRequests } = this.props.contact;

		return (
			<>
				<IonPage className="contacts-page">
					<TopNavbar
						{...this.props}
						showBack={true}
						pageTitle={'New Contacts'}
						onLeftButtonPress={() => {
							Navigation.back();
						}}
					/>
					<IonContent className="main-content-profile has-bottom-navbar has-topbar-with-searchbar">
						<IonItemDivider className="recent-contacts-divider-title">
							<IonLabel>{locale.contacts.recent_contacts.new_req}</IonLabel>
						</IonItemDivider>
						<ContactsList props={this.props} hideAlphabetDevider={true} contacts={receivedRequests!} contactListMode={ContactsListMode.NewRequestContactsList} />

						<IonItemDivider className="recent-contacts-divider-title">
							<IonLabel>{locale.contacts.recent_contacts.most_recent}</IonLabel>
						</IonItemDivider>
						<ContactsList props={this.props} hideAlphabetDevider={true} contacts={sentRequests!} contactListMode={ContactsListMode.MostRecentContactsList} />

						<IonItemDivider className="recent-contacts-divider-title">
							<IonLabel>{locale.contacts.recent_contacts.recently_added}</IonLabel>
						</IonItemDivider>
						<ContactsList props={this.props} hideAlphabetDevider={true} contacts={contacts!} contactListMode={ContactsListMode.RecentlyAddedContactsList} />
					</IonContent>
				</IonPage>
			</>
		);
	}
}

const mapStateToProps = (state: ReduxStates) => {
	return {
		contact: state.contact,
		profile: state.profile,
	};
};

const mapDispatchToProps = (dispatch: any) => ({});

export default connect(mapStateToProps, mapDispatchToProps)(RecentContacts);
