import React, { useCallback } from "react";
import { IonImg, IonItemDivider, IonItemGroup, IonLabel, IonList, IonItem, IonThumbnail, IonCheckbox, IonButton, IonIcon } from "@ionic/react";
import "./style.scss";
import { ContactsListMode, ContactStatus, ProfileFriendActionTypes } from "../../../services/enumService";
import { locale } from "../../../locales/local";
import { removeCircleOutline } from "ionicons/icons";
import { sortByAlias } from "../../../helpers/common";
import _ from "lodash";
import SharedService from "../../../services/sharedService";
import { ContactItem } from "../../../types/contact";
import { DefaultProfilePic } from "../../../helpers/common";
import { Navigation } from "../../../services/navigationService";
const RedCrossIcon = "./assets/icon/redCross-icon.svg";

const StarredFillIcon = "./assets/icon/starred-fill.svg";

interface iProps {
  props: any;
  contactListMode?: string;
  hideAlphabetDevider?: boolean;
  showDisableContactChecked?: boolean;
  isGroupList?: boolean;
  contacts: Array<ContactItem>;
  starredContacts?: any;
  selectedContacts?: any;
  disabledContacts?: any;
  onContactRemove?: any;
  onContactSelect?: Function;
  isChatOnly?: string;
  shouldDisableGoToViewProfile?: boolean;
}

export const ContactsList: React.FC<iProps> = ({ props, contacts, starredContacts, showDisableContactChecked, contactListMode, hideAlphabetDevider, selectedContacts, disabledContacts, onContactSelect, onContactRemove, isGroupList, isChatOnly, shouldDisableGoToViewProfile }) => {
  const extractContactName = useCallback(
    (contact: ContactItem) => {
      if (isGroupList) {
        // return contact?.groupname;
      }
      return contact?.alias || contact?.userInfo.profileName;
    },

    [isGroupList]
  );
  const ContactItem = useCallback(
    ({ _contact, isDisabled, contactName, isSelected }: { _contact: ContactItem; isDisabled: boolean; contactName: string; isSelected: boolean }) => {
      let userPhoto: any = DefaultProfilePic;
      if (isGroupList && _contact?.userInfo?.profilePhotos?.length > 0) {
        userPhoto = SharedService.extractProfilePhoto(_contact.userInfo.profilePhotos)?.url;
      } else if (_contact?.userInfo?.profilePhotos?.length > 0) {
        userPhoto = SharedService.extractProfilePhoto(_contact.userInfo.profilePhotos)?.url;
      }

      return (
        <IonItem
          onClick={() => {
            onContactSelect && onContactSelect(_contact);

            if (!shouldDisableGoToViewProfile) {
              if (contactListMode === ContactsListMode.NormalContactsList || contactListMode === ContactsListMode.RecentlyAddedContactsList || _contact.status === ContactStatus.BLOCKED) {
                Navigation.profileFriend({
                  data: _contact.userInfo,
                  friendProfileActionType: ProfileFriendActionTypes.ContactView,
                });
              }
            }
          }}
          lines="none"
          disabled={isDisabled}
          detail={false}
        >
          {contactListMode === ContactsListMode.ChooseContactsList && <>{isChatOnly === "chatOnly" && isSelected ? <IonIcon slot="start" src={RedCrossIcon} /> : <IonCheckbox mode={"ios"} slot={"start"} checked={isSelected} />}</>}

          <IonThumbnail slot="start">
            <IonImg style={{ borderRadius: "3px" }} src={userPhoto} />
          </IonThumbnail>
          <IonLabel>
            <h3>{contactName}</h3>
            {_contact.userInfo?.profession && <p className="profession">{_contact.userInfo.profession}</p>}
          </IonLabel>

          {contactListMode === ContactsListMode.NewRequestContactsList && (
            <IonButton
              fill="clear"
              className="view-newrequest"
              slot="end"
              onClick={() => {
                Navigation.profileFriend({
                  data: _contact.userInfo,
                  friendProfileActionType: ProfileFriendActionTypes.ContactRequestView,
                });
              }}
            >
              {locale.contact_list.info.view}
            </IonButton>
          )}

          {contactListMode === ContactsListMode.MostRecentContactsList && (
            <>
              {_contact.status === ContactStatus.OUT_REQUEST ? (
                <IonButton
                  fill="clear"
                  className="view-recentlyadded"
                  slot="end"
                  onClick={() => {
                    Navigation.profileFriend({
                      data: _contact.userInfo,
                      friendProfileActionType: ProfileFriendActionTypes.ContactPending,
                    });
                  }}
                >
                  {locale.contact_list.info.pending}
                </IonButton>
              ) : (
                _contact.status === ContactStatus.BOTH && (
                  <IonButton fill="clear" className="view-mostrecent" slot="end">
                    {locale.contact_list.info.view}
                  </IonButton>
                )
              )}
            </>
          )}

          {contactListMode === ContactsListMode.RecentlyAddedContactsList && (
            <>
              {_contact.status === ContactStatus.IN_REQUEST ? (
                <IonButton fill="clear" className="view-recentlyadded" slot="end">
                  {locale.contact_list.info.pending}
                </IonButton>
              ) : (
                _contact.status === ContactStatus.BOTH && (
                  <IonButton fill="clear" className="view-recentlyadded" slot="end">
                    {locale.contact_list.info.added}
                  </IonButton>
                )
              )}
            </>
          )}
          {contactListMode === ContactsListMode.RemoveContactListMode && (
            <IonButton fill="clear" className="remove-contact-btn" slot="end" onClick={onContactRemove}>
              <IonIcon src={removeCircleOutline}></IonIcon>
            </IonButton>
          )}
        </IonItem>
      );
    },
    [contactListMode, onContactRemove, onContactSelect, props.history, isGroupList]
  );

  const sortedList = contacts?.sort(sortByAlias);

  return (
    <>
      <IonList className="contact-list-component" mode="md">
        <IonItemGroup className="alpha-contacts">
          {starredContacts && starredContacts.length > 0 && (
            <>
              <IonItemDivider className="cont-divider starred" id={"section_starred"}>
                <img src={StarredFillIcon} alt="icon" />
                <IonLabel>Starred</IonLabel>
              </IonItemDivider>

              {starredContacts.sort(sortByAlias)?.map((_contact: ContactItem, index: number) => {
                // let previous = index - 1;

                const contactName = extractContactName(_contact),
                  // previousContact = contacts[previous],
                  // prevContactName = previousContact ? extractContactName(previousContact) : null,
                  isSelected = _.includes(disabledContacts, _contact?.username) ? showDisableContactChecked : _.includes(selectedContacts, _contact?.username),
                  isDisabled = _.includes(disabledContacts, _contact?.username);
                return (
                  <React.Fragment key={index}>
                    <ContactItem _contact={_contact} contactName={contactName} isSelected={isSelected!} isDisabled={isDisabled!} />
                  </React.Fragment>
                );
              })}
            </>
          )}

          {contacts?.length > 0
            ? sortedList?.map((_contact: ContactItem, index: number) => {
                let previous = index - 1;
                const contactName = extractContactName(_contact),
                  previousContact = contacts[previous],
                  prevContactName = previousContact ? extractContactName(previousContact) : "",
                  isSelected = _.includes(selectedContacts, _contact?.username),
                  isDisabled = _.includes(disabledContacts, _contact?.username);

                return (
                  <React.Fragment key={index}>
                    {!hideAlphabetDevider && (!previousContact || prevContactName?.toLowerCase().charAt(0) !== contactName?.toLowerCase().charAt(0)) ? (
                      <>
                        <IonItemDivider className="cont-divider" id={"section_" + contactName?.charAt(0).toUpperCase()}>
                          <IonLabel>{contactName?.charAt(0).toUpperCase()}</IonLabel>
                        </IonItemDivider>
                      </>
                    ) : null}

                    <ContactItem _contact={_contact} contactName={contactName} isSelected={isSelected} isDisabled={isDisabled} />
                  </React.Fragment>
                );
              })
            : ""}
        </IonItemGroup>
      </IonList>
    </>
  );
};
