import React from 'react';
import { IonButton, IonContent, IonIcon, IonPage } from '@ionic/react';
import './style.scss';
import { RouteComponentProps } from 'react-router';
import { connect } from 'react-redux';
import { ContactAddBy, NavigationRoutes, ProfileFriendActionTypes } from '../../services/enumService';
import { TopNavbar } from '../common/header/topbar';
import { locale } from '../../locales/local';
import { apiService } from '../../services/apiService';
import { getProfileByUsername } from '../../redux/actions/profile';
import { GetProfileByUsernameRequestData } from '../../types/api-requests';
import { GlobalStateTypes, ProfileStateTypes, ReduxStates } from '../../types/redux-states';
import { UserProfile } from '../../types/profile';
import { showAlert } from '../../redux/actions/global';
import { Navigation } from '../../services/navigationService';
import QrCodeScanModal from '../quickaccess/QrCodeScanModal';
import QrCodeComponent from '../quickaccess/QrCodeComponent';
import QuickAccessModal from '../quickaccess/QuickAccessModal';
import { sharedService } from '../../services/sharedService';

const QrCodeIcon = './assets/icon/qrcode.svg';
const QrScanIcon = './assets/icon/scan.svg';
const SearchAppIdIcon = './assets/icon/search-app-id-icon.svg';

interface iProps extends RouteComponentProps<{}> {
	profile: ProfileStateTypes;
	getProfileByUsername: (data: GetProfileByUsernameRequestData) => Promise<UserProfile>;
	showAlert: (data: GlobalStateTypes) => void;
}

interface iState {
	searchText: string;
	showQrScanModal: boolean;
	showQrodeModal: boolean;
}

class SearchContacts extends React.Component<iProps, iState> {
	componentIsMounted: boolean = true;

	constructor(props: iProps) {
		super(props);

		this.state = {
			searchText: '',
			showQrScanModal: false,
			showQrodeModal: false,
		};
		{
		}
	}

	componentDidMount(): void {
		this.componentIsMounted = true;
	}

	componentWillUnmount(): void {
		this.componentIsMounted = false;
	}

	openUserProfile(user: UserProfile) {
		Navigation.profileFriend({
			data: user,
			friendProfileActionType: ProfileFriendActionTypes.ContactAdd,
			contactAddBy: ContactAddBy.Id,
		});
	}

	searchUserByName() {
		const { searchText } = this.state;
		if (searchText && searchText !== this.props.profile.loggedInUserProfile?.username) {
			apiService.searchUserByUsernameInDB(searchText).then((isFound) => {
				if (!isFound) {
					this.props
						.getProfileByUsername({ username: searchText })
						.then((response: UserProfile) => {
							this.openUserProfile(response);
						})
						.catch((_: any) => {
							this.props.showAlert({ alertMessage: 'Please edit your search and try again', alertTitle: 'Unable to find' });
						});
				}
			});
		} else {
			this.props.showAlert({ alertMessage: 'Please edit your search and try again', alertTitle: 'Unable to find' });
		}
	}

	navigateRoot(to: string) {
		sharedService.rootHistory.replace(to);
	}
	render() {
		if (this.componentIsMounted) {
			return (
				<IonPage className="search-contacts">
					<TopNavbar
						{...this.props}
						hideSearchBar={false}
						pageTitle="Add Contacts"
						showBack={true}
						onLeftButtonPress={() => {
							Navigation.back();
						}}
						searchbarPlaceholder={locale.contacts.search_contacts.placeholders.user_id}
						showSearchbarCancelButton={true}
						searchTypeHandler={(searchtxt: string) => {
							this.setState({ searchText: searchtxt });
						}}
						// searchHandler={(_: string) => {
						// this.searchAllGroups();
						// }}
						onSearchBarCancelPress={() => {
							this.setState({ searchText: '' });
							this.navigateRoot(NavigationRoutes.CONTACTS);
						}}
						isHideRightButton={true}
						isLoading={this.props.profile.isLoading}
						loadingText="Searching..."
					/>

					<IonContent className="wrap-contacts has-topbar-with-searchbar has-bottom-navbar">
						<>
							{this.state.searchText && this.state.searchText.length > 0 ? (
								<div
									className="search-app-id-view"
									onClick={() => {
										if (this.state.searchText.length >= 1) {
											this.searchUserByName();
										}
									}}
								>
									<IonIcon src={SearchAppIdIcon}></IonIcon>

									<div className="title">
										Search ID: <span className="matching">{this.state.searchText}</span>
									</div>
								</div>
							) : (
								<div style={{ textAlign: 'center', width: '100%' }}>
									{locale.contacts.search_contacts.my_id} {this.props.profile.loggedInUserProfile?.username || ''}
								</div>
							)}
						</>
					</IonContent>
					<div className="qr-code-deal">{this.state.showQrodeModal && <QrCodeComponent onClose={() => this.setState({ showQrodeModal: false })} />}</div>
					<div className="scanbtns-container">
						<IonButton
							mode="ios"
							fill="clear"
							onClick={() => {
								this.setState({ showQrodeModal: true });
							}}
							// onClick={() => {
							// 	Navigation.openMyQRCode();
							// }}
						>
							<IonIcon src={QrCodeIcon}></IonIcon>
						</IonButton>
						<IonButton
							mode="ios"
							fill="clear"
							onClick={() => {
								this.setState({ showQrScanModal: true });
							}}
						>
							<IonIcon src={QrScanIcon}></IonIcon>
						</IonButton>
					</div>

					{this.state.showQrScanModal && (
						<QrCodeScanModal
							show={this.state.showQrScanModal}
							onClose={() => {
								this.setState({ showQrScanModal: false });
							}}
						/>
					)}
					{this.state.showQrodeModal && (
						<div className="quick-access-modal">
							<QuickAccessModal
								loggedInUserProfile={this.props.profile?.loggedInUserProfile!}
								show={this.state.showQrodeModal}
								onClose={() => {
									this.setState({ showQrodeModal: false });
								}}
							/>
						</div>
					)}
				</IonPage>
			);
		} else {
			return <></>;
		}
	}
}

const mapStateToProps = (state: ReduxStates) => {
	return {
		profile: state.profile,
	};
};

const mapDispatchToProps = (dispatch: any) => ({
	getProfileByUsername: (payload: GetProfileByUsernameRequestData) => dispatch(getProfileByUsername(payload)),
	showAlert: (payload: GlobalStateTypes) => dispatch(showAlert(payload)),
});

export default connect(mapStateToProps, mapDispatchToProps)(SearchContacts);
