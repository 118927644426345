import { IonButton, IonButtons, IonCheckbox, IonIcon, IonItem, IonLabel, IonText, IonTitle, IonToolbar } from '@ionic/react';
import React from 'react';
import './style.scss';
import { addOutline } from 'ionicons/icons';
import { locale } from '../../locales/local';
const AddPlusIcon = './../../../assets/icon/add-plus-icon.svg';

interface ProjectTaskComponentProps {
	onClose?: any;
	ProjectName:string;
}

const ProjectTaskComponent: React.FC<ProjectTaskComponentProps> = ({ onClose, ProjectName }) => {
	const projects = [
		{ title: locale.quickaccess.festival, subtitle: locale.quickaccess.festival_sub },
		{ title: locale.quickaccess.mobile_game, subtitle: locale.quickaccess.mobile_game_sub },
	];

	return (
		<div
			className="project-task-component component-expand-full"
			onClick={(event) => {
				event.stopPropagation();
				onClose && onClose();
			}}
		>
			<IonLabel className="card-title">{locale.quickaccess.tasks}</IonLabel>
			<div className="card" onClick={(event) => event.stopPropagation()}>
				<IonToolbar style={{ '--background': 'transparent' }}>
					<IonTitle slot="start" style={{ textAlign: 'left', paddingLeft: '12px', fontWeight: '900', fontSize: '20px' }}>
						{ProjectName}
					</IonTitle>
					<IonButtons slot="end">
						<IonButton fill="clear">
							<IonIcon icon={AddPlusIcon} slot="icon-only"></IonIcon>
						</IonButton>
					</IonButtons>
				</IonToolbar>
				{projects.map((item, key) => {
					return <TaskItemButton key={key} title={item.title} subtitle={item.subtitle} />;
				})}
			</div>
		</div>
	);
};

export default ProjectTaskComponent;

interface TaskItemButtonProps {
	title: string;
	subtitle: string;
	onClick?: any;
}
const TaskItemButton: React.FC<TaskItemButtonProps> = ({ title, subtitle, onClick }) => {
	return (
		<IonItem lines="none" className="task-item">
			<div className="thumbnail">
				<img alt="person" src={'./assets/img/default-profile-photo.png'} />
				<IonText>{locale.quickaccess.due_date}</IonText>
				<IonText>04 / 10 / 21</IonText>
			</div>
			<div className={'task-item-label'}>
				<h2 style={{ margin: '0px', fontSize: '16px' }}>MOR茉🐝</h2>
				<div className="inner-item">
					<IonCheckbox style={{ margin: '0px', innerWidth: '24px' }}> </IonCheckbox>
					<div className="quick-access-paint">
						<text style={{ fontSize: '13px' }}>{locale.quickaccess.paint_slinky}</text>
						<p style={{ padding: '0px', fontSize: '10px', marginTop: '5px' }}>WAER A MASK PLEASE</p>
					</div>
				</div>
			</div>
		</IonItem>
	);
};
