import React from 'react';
import { IonAlert, IonLoading, IonToast } from '@ionic/react';

import { UpdateDotsData } from '../../types/api-requests';
import { GlobalStateTypes, ReduxStates } from '../../types/redux-states';
import { updateDots } from '../../redux/actions/profile';
import { connect } from 'react-redux';
import AppUpdatePopupModal from '../../modals/AppUpdatePopupModal';
import ToastStylish from '../../components/ToastStylish';
import { hideAlert, hideLoader, hideStylishToast, hideToast } from '../../redux/actions/global';
import './style.scss';

interface ContainerProps {
	global?: GlobalStateTypes;
	hideAlert?: () => void;
	hideToast?: () => void;
	hideStylishToast?: () => void;
	hideLoader?: () => void;
}
const CommonView = (props: ContainerProps) => {
	const { global } = props;

	return (
		<>
			<IonLoading isOpen={global?.isLoading!} onDidDismiss={props.hideLoader} message={global?.loaderMessage} spinner={'bubbles'} />
			<IonToast
				isOpen={global?.showToast!}
				color={global?.toastType || 'secondary'}
				onDidDismiss={props.hideToast}
				message={global?.toastMessage}
				position={'bottom'}
				duration={3000}
				cssClass={'global-toast-view'}
				buttons={[
					{
						text: global?.toastButtonText,
						handler: () => {
							props.hideToast && props.hideToast();
							if (global?.toastCallBack) {
								global?.toastCallBack(true);
							}
						},
					},
				]}
			/>
			<ToastStylish show={global?.showStylishToast!} message={global?.stylishToastMessage!} iconType={global?.stylishToastIcon} onClose={props.hideStylishToast} />
			<AppUpdatePopupModal
				isLoading={global?.appUpdatePopupLoading}
				loadingMessage={global?.appUpdatePopupLoadingMessage}
				show={global?.showAppUpdatePopup!}
				heading={global?.appUpdatePopupHeading}
				subHeading={global?.appUpdatePopupSubHeading}
				messageTitle={global?.appUpdatePopupMessageTitle}
				message={global?.appUpdatePopupMessage}
				actionButtonnTitle={global?.appUpdatePopupActionBtnTitle}
				callBack={global?.appUpdatePopupCallBack}
			/>
			<IonAlert
				mode="ios"
				isOpen={global?.showAlert!}
				onDidDismiss={props.hideAlert}
				cssClass="alert-controller-class"
				header={global?.alertTitle || undefined}
				message={global?.alertMessage}
				buttons={[
					{
						text: 'OK',
						role: 'destructive',
						handler: props.hideAlert,
					},
				]}
			/>
		</>
	);
};

const mapStateToProps = (state: ReduxStates) => {
	return {
		global: state.global,
	};
};

const mapDispatchToProps = (dispatch: any) => ({
	updateDots: (payload: UpdateDotsData) => dispatch(updateDots(payload)),
	hideAlert: () => dispatch(hideAlert()),
	hideToast: () => dispatch(hideToast()),
	hideStylishToast: () => dispatch(hideStylishToast()),
	hideLoader: () => dispatch(hideLoader()),
});

export default connect(mapStateToProps, mapDispatchToProps)(CommonView);
