import { IonModal, IonToolbar, IonButtons, IonButton, IonTitle, IonLabel, IonList, IonThumbnail, IonImg, IonCheckbox } from '@ionic/react';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import './style.scss';
import { connect } from 'react-redux';
import { blankProfilePic, info } from '../helpers/common';
import { LongpressContainer } from '../components/LongpressContainer';
import _ from 'lodash';

let isAlbhabetManuallySelect = false;

interface GroupMembersTagModalProps {
	show: boolean;
	onClose?: any;
	props?: any;
	onDone?: any;
	alphabetsList?: any;
	sortedMembersList?: any;
	allContacts?: any;
	searchHandler?: any;
	onProfilePhotoPress?: any;
	searchTypeHandler?: any;
}

const GroupMembersTagModal: React.FC<GroupMembersTagModalProps> = ({ show, onClose, onDone, allContacts, alphabetsList, sortedMembersList, onProfilePhotoPress, props }) => {
	const [onModalPresent, setOnModalPresent] = useState<boolean>(false);
	const [showActiveAlbhabet, setShowActiveAlbhabet] = useState<boolean>(false);
	const [activeAlbhabet, setActiveAlbhabet] = useState<string>('');
	const [showActiveAlbhabetBubble, setShowActiveAlbhabetBubble] = useState<boolean>(false);

	const [selectedMembersIds, setSelectedMembersIds] = useState<any>();

	let contentRef = useRef<HTMLDivElement>(null);

	const updateSize = useCallback(() => {
		const offsetHeight = contentRef.current?.offsetHeight;
		const scrollHeight = contentRef.current?.scrollHeight;
		const isActive = offsetHeight !== scrollHeight;
		if (isActive) {
			setActiveAlbhabet(alphabetsList?.length > 0 ? alphabetsList[0] : '');
			setShowActiveAlbhabet(isActive);
		} else {
			setActiveAlbhabet('');
		}
	}, [alphabetsList]);

	useEffect(() => {
		if (onModalPresent) {
			updateSize();
		}
	}, [onModalPresent, updateSize]);

	const selectAllMembers = useCallback(() => {
		const idsList: any = selectedMembersIds ? [...selectedMembersIds] : [];
		sortedMembersList.forEach((item: any) => {
			const alreadyAddedIndex = !_.includes(idsList, item.userId);
			if (!alreadyAddedIndex) {
				idsList.push(item.userId);
			}
		});
		setSelectedMembersIds(idsList);
	}, [sortedMembersList, selectedMembersIds]);

	const selectUnSelectMember = useCallback(
		(checked, item: any) => {
			const idsList: any = selectedMembersIds ? [...selectedMembersIds] : [];
			if (checked) {
				idsList.push(item.userId);
			} else {
				const alreadyAddedIndex = _.includes(idsList, item.userId);
				idsList.splice(alreadyAddedIndex, 1);
			}
			setSelectedMembersIds(idsList);
		},
		[selectedMembersIds]
	);

	const onCloseCallBack = useCallback(
		(event: any) => {
			onClose && onClose();
			setSelectedMembersIds(null);
		},
		[onClose]
	);

	const onDoneCallBack = useCallback(
		(event: any) => {
			if (selectedMembersIds?.length > 0) {
				onDone && onDone(selectedMembersIds);
				setSelectedMembersIds(null);
			}
		},
		[onDone, selectedMembersIds]
	);

	let nameFirstChar = '';

	const onAlbhabetSelect = useCallback(
		(item) => {
			if (alphabetsList && alphabetsList.length > 0) {
				const firstElement = document.getElementById('alphabet_' + alphabetsList[0].toLowerCase());
				const element = document.getElementById('alphabet_' + item.toLowerCase());

				if (element?.offsetTop && firstElement) {
					contentRef.current?.scrollTo({ top: element?.offsetTop - firstElement?.offsetTop });
				} else {
					contentRef.current?.scrollTo({ top: 0 });
				}
			}
			isAlbhabetManuallySelect = true;
			_.delay(() => {
				isAlbhabetManuallySelect = false;
			}, 500);

			setActiveAlbhabet(item);
		},
		[alphabetsList]
	);

	return (
		<IonModal
			mode="ios"
			showBackdrop={true}
			backdropDismiss={true}
			onDidDismiss={onCloseCallBack}
			isOpen={show}
			swipeToClose={false}
			cssClass="group-members-tag-modal"
			animated={true}
			onDidPresent={() => {
				setOnModalPresent(true);
			}}
			onWillDismiss={() => {
				info('onWillDismiss');
				setOnModalPresent(false);
			}}
		>
			<div
				className="popup-view-container"
				onClick={(event) => {
					onCloseCallBack(event);
				}}
			>
				<div
					className="popup-view"
					onClick={(event) => {
						event.stopPropagation();
					}}
				>
					<div className="header-toolbar">
						<IonToolbar mode="ios">
							<IonTitle className="title">Tag User</IonTitle>
							<IonButtons slot="end">
								<IonButton className={'all-btn' + (sortedMembersList?.length === selectedMembersIds?.length ? ' active' : '')} onClick={selectAllMembers}>
									@ALL
								</IonButton>
							</IonButtons>
						</IonToolbar>

						{showActiveAlbhabet && (
							<div className="active-name-letter-view">
								<div className="letter">{activeAlbhabet}</div>
							</div>
						)}
					</div>

					<div
						className="content"
						ref={contentRef}
						onScroll={(event) => {
							if (!isAlbhabetManuallySelect) {
								const scrollTop = event.currentTarget?.scrollTop;
								if (alphabetsList && alphabetsList.length > 0) {
									const firstElement = document.getElementById(`alphabet_${alphabetsList[0].toLowerCase()}`);
									alphabetsList.some((alphabet: any) => {
										let response: Boolean = false;

										const element = document.getElementById(`alphabet_${alphabet.toLowerCase()}`);

										if (element && firstElement) {
											if (element?.offsetTop + element?.offsetHeight - firstElement.offsetTop >= scrollTop) {
												setActiveAlbhabet(alphabet);
												response = true;
											}
										}
										return response;
									});
								}
							}
						}}
					>
						<IonList lines="none" style={{ marginTop: showActiveAlbhabet ? '101px' : '65px' }}>
							{sortedMembersList?.map((item: any, key: any) => {
								// self.conversations?.find((_contact: any) => _contact.userId === item?.userId) || self.props.chat?.receiver?.members?.find((_member: any) => _member.userId === item?.userId) || allContacts?.find((obj: any) => obj.userId === item.userId)
								const userInfo: any = {},
									userProfileName = item.alias,
									isChecked = _.includes(selectedMembersIds, item.userId);
								let alphabetDeviderId: any = null;
								if (nameFirstChar?.toLowerCase() !== userProfileName.charAt(0).toLowerCase()) {
									nameFirstChar = userProfileName.charAt(0).toLowerCase();
									alphabetDeviderId = 'alphabet_' + nameFirstChar;
								}

								return (
									<div
										className="tag-user-item"
										key={key}
										id={alphabetDeviderId}
										onClick={() => {
											selectUnSelectMember(!isChecked, item);
										}}
									>
										<IonCheckbox checked={isChecked} slot="start" />
										<IonThumbnail
											slot="start"
											onClick={(event) => {
												// event.stopPropagation();
												// onProfilePhotoPress && onProfilePhotoPress(item.userId);
											}}
										>
											<IonImg src={userInfo?.profilePhoto || blankProfilePic} />
										</IonThumbnail>
										<IonLabel>{userProfileName}</IonLabel>
									</div>
								);
							})}
						</IonList>

						<div className="alphabet-view-container">
							<div className="alphabet-view">
								{alphabetsList?.length > 0 &&
									alphabetsList.map((item: string, key: any) => {
										return (
											<LongpressContainer
												key={key}
												className="long-press-container"
												onClick={() => {
													if (showActiveAlbhabet) {
														onAlbhabetSelect(item);
													}
												}}
												onLongPress={() => {
													if (showActiveAlbhabet) {
														onAlbhabetSelect(item);
														setShowActiveAlbhabetBubble(true);
													}
												}}
												onFinish={() => {
													if (showActiveAlbhabet) {
														setShowActiveAlbhabetBubble(false);
													}
												}}
											>
												<div className={'alphabet ' + (activeAlbhabet.toLowerCase() === item.toLowerCase() ? 'active' : '')}>
													{activeAlbhabet === item && showActiveAlbhabetBubble && <div className="selected-alphabet">{activeAlbhabet || 'P'}</div>}
													{item}
												</div>
											</LongpressContainer>
										);
									})}
							</div>
						</div>
					</div>

					<div className="footer-toolbar">
						<IonToolbar>
							<IonButtons slot="start">
								<IonButton className="cancel-btn" fill="clear" onClick={onCloseCallBack}>
									Cancel
								</IonButton>
							</IonButtons>
							<IonButtons slot="end">
								<IonButton className={'done-btn' + (selectedMembersIds?.length > 0 ? ' active' : '')} fill="clear" onClick={onDoneCallBack}>
									Done {selectedMembersIds?.length > 0 ? <>({selectedMembersIds?.length})</> : ''}
								</IonButton>
							</IonButtons>
						</IonToolbar>
					</div>
				</div>
			</div>
		</IonModal>
	);
};

const mapStateToProps = (state: any) => {
	return {
		loggedInUser: state.global.loggedInUser,
		isLoggedIn: state.auth.isLoggedIn,
		xmpp: state.global.xmpp,
		searchContact: state.searchContact,
	};
};
const mapDispatchToProps = (dispatch: any) => ({});

export default connect(mapStateToProps, mapDispatchToProps)(GroupMembersTagModal);
