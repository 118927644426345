import React from 'react';
import { IonButton, IonContent, IonPage, IonIcon } from '@ionic/react';
import './personalstorage.scss';
import { TopNavbar } from '../common/header/topbar';
import { RouteComponentProps } from 'react-router';
import BottomNavbar from './navbar/bottomNavbar';
import PersonalStorageModal from './PersonalStorageModal';
import { PersonalFolderShowType } from '../../services/enumService';
import { Navigation } from '../../services/navigationService';
import { info } from '../../helpers/common';
const CloseIcon = './assets/icon/Close.svg';

const imagesList = [
	{
		image: 'pic-1.png',
		isShared: false,
		isFavorite: true,
	},
	{
		image: 'pic-1.png',
		isShared: false,
		isFavorite: true,
	},
	{
		image: 'pic-1.png',
		isShared: true,
		isFavorite: false,
	},
	{
		image: 'pic-1.png',
		isShared: false,
		isFavorite: true,
	},
	{
		image: 'pic-1.png',
		isShared: false,
		isFavorite: true,
	},
	{
		image: 'pic-1.png',
		isShared: false,
		isFavorite: true,
	},
	{
		image: 'pic-1.png',
		isShared: false,
		isFavorite: true,
	},
	{
		image: 'pic-1.png',
		isShared: false,
		isFavorite: true,
	},
	{
		image: 'pic-1.png',
		isShared: false,
		isFavorite: true,
	},
	{
		image: 'pic-1.png',
		isShared: false,
		isFavorite: true,
	},
];

const videoList = [
	{
		image: 'videoimage.svg',
		isShared: false,
		isFavorite: false,
		isPlay: true,
	},
	{
		image: 'videoimage.svg',
		isShared: true,
		isFavorite: true,
		isPlay: true,
	},
	{
		image: 'videoimage.svg',
		isShared: true,
		isFavorite: false,
		isPlay: true,
	},
	{
		image: 'videoimage.svg',
		isShared: false,
		isFavorite: true,
		isPlay: true,
	},
	{
		image: 'videoimage.svg',
		isShared: false,
		isFavorite: true,
		isPlay: true,
	},
	{
		image: 'videoimage.svg',
		isShared: false,
		isFavorite: true,
		isPlay: true,
	},
	{
		image: 'videoimage.svg',
		isShared: false,
		isFavorite: true,
		isPlay: true,
	},
	{
		image: 'videoimage.svg',
		isShared: false,
		isFavorite: true,
		isPlay: true,
	},
	{
		image: 'videoimage.svg',
		isShared: false,
		isFavorite: true,
		isPlay: true,
	},
	{
		image: 'videoimage.svg',
		isShared: false,
		isFavorite: true,
		isPlay: true,
	},
];
const fileList = [
	{
		image: 'Vector-1.svg',
		isShared: false,
		isFavorite: false,
		FolderName: 'File Name.pdf',
	},
	{
		image: 'PDF.svg',
		isShared: true,
		isFavorite: true,
		FolderName: 'Folder-name.pdf',
	},
	{
		image: 'EXCEL.svg',
		isShared: true,
		isFavorite: false,
		FolderName: 'folder-name.pdf',
	},
	{
		image: 'PPT.svg',
		isShared: false,
		isFavorite: true,
		FolderName: 'folder-name.pdf',
	},
	{
		image: 'Vector-1.svg',
		isShared: false,
		isFavorite: true,
		FolderName: 'folder-name.pdf',
	},
	{
		image: 'Vector-1.svg',
		isShared: false,
		isFavorite: true,
		FolderName: 'folder-name.pdf',
	},
	{
		image: 'Vector-1.svg',
		isShared: false,
		isFavorite: true,
		FolderName: 'folder-name.pdf',
	},
	{
		image: 'Vector-1.svg',
		isShared: false,
		isFavorite: true,
		FolderName: 'folder-name.pdf',
	},
];

const folderList = [
	{
		image: 'Vector-folder.svg',
		isShared: true,
		isFavorite: false,
		FolderName: 'folder-name.pdf',
	},
	{
		image: 'Vector-folder.svg',
		isShared: true,
		isFavorite: true,
		FolderName: 'folder-name.pdf',
	},
	{
		image: 'Vector-folder.svg',
		isShared: true,
		isFavorite: false,
		FolderName: 'folder-name.pdf',
	},
	{
		image: 'Vector-folder.svg',
		isShared: false,
		isFavorite: true,
		FolderName: 'folder-name.pdf',
	},
	{
		image: 'Vector-folder.svg',
		isShared: false,
		isFavorite: true,
		FolderName: 'folder-name.pdf',
	},
	{
		image: 'Vector-folder.svg',
		isShared: false,
		isFavorite: true,
		FolderName: 'folder-name.pdf',
	},
	{
		image: 'Vector-folder.svg',
		isShared: false,
		isFavorite: true,
		FolderName: 'folder-name.pdf',
	},
	{
		image: 'Vector-folder.svg',
		isShared: false,
		isFavorite: true,
		FolderName: 'folder-name.pdf',
	},
	{
		image: 'Vector-folder.svg',
		isShared: false,
		isFavorite: true,
		FolderName: 'folder-name.pdf',
	},
	{
		image: 'Vector-folder.svg',
		isShared: false,
		isFavorite: true,
		FolderName: 'folder-name.pdf',
	},
	{
		image: 'Vector-folder.svg',
		isShared: false,
		isFavorite: true,
		FolderName: 'folder-name.pdf',
	},
];
interface iProps {
	type: string;
	onCloseCallBack: any;
}

interface iProps extends RouteComponentProps<{ name: string }> {}

interface iState {
	selectedTab: string;
	selectedType: string;
	isShowPopupView: boolean;
	onsearch: boolean;
}

class PersonalStorage extends React.Component<iProps, iState> {
	constructor(props: any) {
		super(props);

		this.state = {
			isShowPopupView: false,
			onsearch: false,

			selectedTab: 'personal-drive', //personal-drive, chat-history
			selectedType: 'folder', //folder,image.video.file
		};
	}

	_categoryItem = (title: string, color: string) => {
		return (
			<div className="cat-item">
				<div className="square" style={{ background: color }}></div>
				<div className="cat-title">{title}</div>
			</div>
		);
	};
	_tabItem = (title: string, type: string) => {
		return (
			<div className="tabitem ">
				<IonButton
					fill="clear"
					onClick={() => {
						this.setState({ selectedTab: type });
					}}
					className={this.state.selectedTab === type ? 'active' : ''}
				>
					{title}
				</IonButton>
			</div>
		);
	};
	_tabType = (type: string) => {
		return (
			<div className="tabitem ">
				<IonButton
					fill="clear"
					onClick={() => {
						this.setState({ selectedType: type });
					}}
					className={this.state.selectedTab === type ? 'active' : ''}
				></IonButton>
			</div>
		);
	};

	render(): React.ReactNode {
		return (
			<IonPage className="personal-storage">
				<TopNavbar
					{...this.props}
					showBack={true}
					pageTitle={'Personal Drive'}
					// hideSearchBar={true}
					leftButtonIcon={CloseIcon}
					hideSearchBar={this.state.onsearch ? false : true}
					onSearchIconPress={() => {
						this.setState({ onsearch: !this.state.onsearch });
					}}
					isSearchButtonActive={this.state.onsearch}
					showSearchIcon={true}
					// onRightButtonPress={() => {
					//   this.setState({ isShowPopupView: true })
					// }}
					pageTitleDescription={'0.0 KB of 20 GB'}
					{...this.props}
					onLeftButtonPress={() => {
						if (this.state.onsearch) {
							this.setState({
								onsearch: false,
							});
						} else {
							Navigation.back();
						}
					}}
				/>

				<IonContent className="has-topbar has-bottom-navbar">
					<div className="div-first">
						<div className="graph">
							<div className="box1"></div>
							<div className="smallbox"></div>
						</div>
						<div className="category-items">
							{this._categoryItem('Personal Cloud', '#FF5E5E')}
							{this._categoryItem('Chat History', '#3EBAFF')}
							{this._categoryItem('Free Space', '#DADADA')}
						</div>
					</div>
					<div className="tabs">
						{this._tabItem('Personal Drive', 'personal-drive')}
						{this._tabItem('Chat History', 'chat-history')}
					</div>

					<div className="images">Images (1290)</div>
					<div className="image">
						{imagesList.map((item: any, key: any) => {
							return (
								<div
									className="img"
									key={key}
									onClick={() =>
										Navigation.openPersonalFolder({
											showFolderType: PersonalFolderShowType.Image,
										})
									}
								>
									<img alt="" className="inner-img" src={'../assets/img/' + item.image}></img>
									<div className="shared-star-view">
										{item.isFavorite && <img alt="" className="inner-star" src={'../assets/img/Star-3.svg'}></img>}
										{item.isShared && <img alt="" className="inner-shared" src={'../assets/img/shared-file.svg'}></img>}
									</div>
								</div>
							);
						})}
					</div>

					<div className="videoimage">Videos (432)</div>
					<div className="image">
						{videoList.map((item: any, key: any) => {
							return (
								<div
									className="img"
									key={key}
									onClick={() =>
										Navigation.openPersonalFolder({
											showFolderType: PersonalFolderShowType.Video,
										})
									}
								>
									<img alt="" className="inner-img" src={'../assets/img/' + item.image}></img>
									<div className="innerdiv-play">{item.isPlay && <img alt="" className="inner-play" src={'../assets/img/Group.svg'}></img>}</div>

									<div className="shared-star-view">
										{item.isFavorite && <img alt="" className="inner-star" src={'../assets/img/Star-3.svg'}></img>}
										{item.isShared && <img alt="" className="inner-shared" src={'../assets/img/shared-file.svg'}></img>}
									</div>
								</div>
							);
						})}
					</div>

					<div className="videoimage">Files (86)</div>
					<div className="files">
						{fileList.map((item: any, key: any) => {
							return (
								<div
									className="file"
									key={key}
									onClick={() =>
										Navigation.openPersonalFolder({
											showFolderType: PersonalFolderShowType.File,
										})
									}
								>
									<div className="image-view">
										<img alt="" className="inner-file" src={'../assets/img/' + item.image}></img>
										<div className="shared-star-view">
											{item.isFavorite && <img alt="" className="inner-star" src={'../assets/img/Star-3.svg'}></img>}
											{item.isShared && <img alt="" className="inner-shared" src={'../assets/img/shared-file.svg'}></img>}
										</div>
									</div>

									<div className="folder_name">{item.FolderName}</div>
								</div>
							);
						})}
					</div>
					<div className="videoimage">Folders (55)</div>
					<div className="folders">
						{folderList.map((item: any, key: any) => {
							return (
								<div
									className="folder-item"
									key={key}
									onClick={() =>
										Navigation.openPersonalFolder({
											showFolderType: PersonalFolderShowType.Folder,
										})
									}
								>
									<div className="image-view">
										<IonIcon className="inner-file" src={'../assets/img/' + item.image}></IonIcon>
										<div className="shared-star-view">
											{item.isFavorite && <img alt="" className="inner-star" src={'../assets/img/Star-3.svg'}></img>}
											{item.isShared && <img alt="" className="inner-shared" src={'../assets/img/shared-file.svg'}></img>}
										</div>
									</div>
									<div className="folder_name">{item.FolderName}</div>
								</div>
							);
						})}
					</div>

					{/* model footer */}
				</IonContent>

				<BottomNavbar
					// unreadCount={0} {...this.props}
					ispressAdd={() => {
						info('Modal');
						this.setState({ isShowPopupView: true });
					}}
				/>

				<PersonalStorageModal
					show={this.state.isShowPopupView}
					onCloseCallBack={() => {
						this.setState({ isShowPopupView: false });
					}}
				/>
			</IonPage>
		);
	}
}

export default PersonalStorage;
