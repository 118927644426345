import React, { useEffect, useRef, useState } from 'react';
import { IonIcon, IonRow, IonGrid, IonText, IonModal, IonImg, IonThumbnail } from '@ionic/react';
import './style.scss';
import { DefaultProfilePic } from '../../../../helpers/common';

type CardProps = {
	popupType?: string;
	show: boolean;
	isEnlarge: boolean;
	canRecall: boolean;
	canTranslate: boolean;
	align: string;
	onHide?: Function;
	snippet: any;
	selectedThreadElementPosition: any;
	topRowItems: any;
	bottomRowItems: any;
	onItemSelect?: Function;
	receiverphoto: any;
	receiverName: string;
	isGroup: boolean;
};
function getWindowDimensions() {
	const { innerWidth: width, innerHeight: height } = window;
	return {
		width,
		height,
	};
}

export const EmojiPopup = ({ isGroup, receiverphoto, receiverName, show, popupType, canRecall, canTranslate, align, snippet, isEnlarge, selectedThreadElementPosition, topRowItems, bottomRowItems, onItemSelect, onHide }: CardProps) => {
	const topRowRef: React.RefObject<HTMLIonRowElement> = useRef(null);
	const msgRef: React.RefObject<HTMLIonRowElement> = useRef(null);
	const gridRef: React.RefObject<HTMLIonGridElement> = useRef(null);
	const [windowDimensions, setWindowDimensions] = useState(getWindowDimensions());

	useEffect(() => {
		function handleResize() {
			setWindowDimensions(getWindowDimensions());
		}

		window.addEventListener('resize', handleResize);
		return () => window.removeEventListener('resize', handleResize);
	}, []);
	let screenHeight = windowDimensions;
	let popupHeight = 128;
	let topRowHeight = 0;
	let topPosition = 0;
	if (popupType === 'emoji') {
		topRowHeight = 58;
	} else if (popupType === 'quickFunction') {
		topRowHeight = 79;
	}
	if (topPosition + popupHeight > screenHeight.height) {
		topPosition = topPosition - (topPosition + popupHeight) - screenHeight.height;
	} else {
		topPosition = selectedThreadElementPosition?.top - topRowHeight;
	}

	useEffect(() => {
		if (show) {
			setTimeout(() => {
				const checkGridBottom = (gridRef?.current?.clientHeight || 0) + topPosition;
				if (checkGridBottom > window.innerHeight) {
					const gridRefObj: any = gridRef.current;
					gridRefObj.style.top = topPosition - (checkGridBottom - window.innerHeight) + 'px';
				}
			}, 50);
		}
	}, [show, gridRef, topPosition, gridRef?.current?.clientHeight]);

	useEffect(() => {
		if (show) {
			setTimeout(() => {
				let selection: any = window.getSelection();
				let myElement = document.getElementsByClassName('message')[0];
				if (selection.rangeCount > 0) {
					selection.removeAllRanges();
				}
				if (myElement) {
					let range = document.createRange();
					range.selectNode(myElement);
					selection.addRange(range);
				}
			}, 50);
		}
	}, [show, msgRef]);

	const gridStyle: any = {
		position: 'absolute',
		top: topPosition > 0 ? topPosition : 0 + 'px',
		minWidth: '70vw',
		display: 'flex',
		flexDirection: 'column',
	};

	if (show) {
		if (align === 'left') {
			gridStyle.left = selectedThreadElementPosition?.left - 72;
			gridStyle.alignItems = 'flex-start';
		} else if (align === 'right') {
			gridStyle.right = window.innerWidth - selectedThreadElementPosition?.right - 20;
			gridStyle.alignItems = 'flex-end';
		}
	}

	return (
		<IonModal
			mode="md"
			showBackdrop={false}
			isOpen={show}
			onDidDismiss={() => {
				onHide && onHide();
			}}
			swipeToClose={false}
			cssClass={'emoji-popup-container-modal'}
			animated={false}
		>
			<div
				id="emojiPopupContainer"
				className={'emoji-popup-container ' + align + '' + (isEnlarge ? ' enlarge' : '')}
				onClick={() => {
					onHide && onHide();
				}}
			>
				<IonGrid
					onClick={(event) => {
						if (!isEnlarge) {
							event.stopPropagation();
						}
					}}
					style={gridStyle}
					ref={gridRef}
				>
					{!isEnlarge && (
						<IonRow className="emoji-popover-row" ref={topRowRef}>
							<>
								{topRowItems &&
									topRowItems.map((item: any, key: any) => {
										if (!canRecall && item.type === 'recall') {
											return null;
										} else if (canRecall && item.type === 'delete') {
											return null;
										}

										return (
											<ChatOptionItem
												key={key}
												icon={item.icon}
												size={item.size}
												title={item.title}
												hide={item.forViewAdjustment}
												onClick={() => {
													onItemSelect && onItemSelect(item);
												}}
											/>
										);
									})}
							</>
						</IonRow>
					)}
					<IonRow className="main-row" ref={topRowRef} style={{ flexDirection: align === 'left' ? 'row' : 'row-reverse' }}>
						<IonThumbnail className="user-image" ref={topRowRef}>
							<IonImg className="image" title="Change" src={receiverphoto || DefaultProfilePic} />
						</IonThumbnail>

						<div className="name-msg">
							{isGroup === true && align === 'left' && (
								// style={{ maxWidth: selectedThreadElementPosition?.width + 10 + 'px' }}
								<IonRow ref={msgRef} className="name">
									{receiverName}
								</IonRow>
							)}

							<IonRow ref={msgRef} className="message">
								{snippet}
							</IonRow>
						</div>
					</IonRow>

					{!isEnlarge && (
						<IonRow className="emoji-popover-row">
							<>
								{bottomRowItems &&
									bottomRowItems.map((item: any, key: any) => {
										if (item.hide) {
											return null;
										}

										// if (!canTranslate && item.type === 'translate') {
										// 	return null;
										// }

										return (
											<ChatOptionItem
												key={key}
												size={item.size}
												icon={item.icon}
												title={item.title}
												hide={item.forViewAdjustment}
												onClick={() => {
													onItemSelect && onItemSelect(item);
												}}
											/>
										);
									})}
							</>
						</IonRow>
					)}
				</IonGrid>
			</div>
		</IonModal>
	);
};

interface ChatOptionItemProps {
	icon?: string;
	title?: string;
	size?: string;
	hide?: boolean;
	onClick?: any;
}

const ChatOptionItem: React.FC<ChatOptionItemProps> = ({ icon, hide, title, size, onClick }) => {
	return (
		<div className="popup-item" style={{ opacity: hide ? 0 : 1 }}>
			{icon && <IonIcon style={{ width: size || '26px', height: size || '26px' }} onClick={onClick} size="large" src={icon}></IonIcon>}
			{title && <IonText style={{ fontSize: '11px', color: '#000000', marginTop: 8 }}>{title}</IonText>}
		</div>
	);
};
