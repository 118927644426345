import React from 'react';
import { IonButton, IonContent, IonIcon, IonInput, IonItemDivider, IonLabel, IonItem, IonThumbnail, IonImg, IonPage, IonToggle, IonActionSheet } from '@ionic/react';
import './GroupManager.scss';
import { sortByAlias } from '../../../helpers/common';
import { Config } from '../../../config/config';
import { connect } from 'react-redux';
import { addGroup } from '../../../redux/actions/dashboard';
import { resetValues } from '../../../redux/actions/auth';
import store from '../../../redux/store';
import { initChat } from '../../../redux/actions/chat';
import { DASHBOARD_LAST_MESSAGE_CLEAR } from '../../../redux/constants/dashboard';
import { TopNavbar } from '../../common/header/topbar';
import ImageEditor, { OpenEditorType } from '../../../modals/ImageEditor/ImageEditor';
import { AddMembersPageActionType, ChatMessageState, ChatType, GroupMembersPageActionType, MessageType } from '../../../services/enumService';
import { locale } from '../../../locales/local';
import GroupAboutModal from '../../../modals/GroupAboutModal/GroupAboutModal';
import MyAliasInGroupPopup from '../../../modals/MyAliasInGroupPopup/MyAliasInGroupPopup';
import CameraService from '../../../services/cameraService';
import ContactView from '../../../components/ContactItems/ContactView';
import AddContactItem from '../../../components/ContactItems/AddContactItem';
import RemoveContactItem from '../../../components/ContactItems/RemoveContactItem';
import { sharedService } from '../../../services/sharedService';
import { dbService } from '../../../services/dbService';
import { DefaultProfilePic } from '../../../helpers/common';
import ChooseTagsModal from '../../../modals/ChooseTagsModal/ChooseTagsModal';
import { ContactStateTypes, ProfileStateTypes, ReduxStates } from '../../../types/redux-states';
import AddNewTagPopup from '../../../modals/AddNewTagPopup';
import { Navigation } from '../../../services/navigationService';
import { GroupManagerNavParams } from '../../../types/navigation';
import { apiService } from '../../../services/apiService';
import { ApiResponse } from '../../../types/api-responses';
import { xmpp } from '../../../services/xmpp';
import { createGroup } from '../../../redux/actions/group';
import { CreateMucRequestFormData } from '../../../types/api-requests';
import { GroupItem } from '../../../types/group';
import { hideLoader, showLoader } from '../../../redux/actions/global';
import { ContactItem } from '../../../types/contact';

const CameraIcon = './assets/icon/camera.svg';
const ArrowRightIcon = './assets/icon/arrow-right.svg';
const CrossIcon = './assets/icon/crossicon.svg';

interface iState {
	groupDetail?: GroupItem;
	aliasInGroup: string;
	groupDescription: string;
	selectedGroupPhoto?: File;
	myAliasGroupPhoto?: File;
	hasError: boolean;
	allContacts: any;
	errorMessage: string;
	selectedContacts: any;
	selectedContactIds?: any;
	contacts: any;
	loggedInUser?: any;
	groupName: string;
	selectedImage?: any;
	showPinturaEditor: boolean;
	showPinturaEditorFor: any;
	oldLayout: boolean;
	canDisplayQRCode: boolean;
	showMoreMembersModal: boolean;
	adminApproval: boolean;
	canInvite: boolean;
	showGroupAboutModal: boolean;
	aboutGroupValue?: string;
	showMyAliasInGroupPopup: boolean;
	myAliasInGroupValue?: string;
	openActionSheetFor?: string;
	showChooseTagModal: boolean;
	tags: any;
	showAddTagPopup: boolean;
}

interface iProps {
	props: any;
	profile: ProfileStateTypes;
	contact: ContactStateTypes;
	self: any;
	loggedInUser: any;
	history: any;
	contacts: any;
	actionHandler: Function;
	closeHandler: Function;
	addGroup: Function;
	resetValues: Function;
	initChat: Function;
	location: any;
	createGroup: (payload: CreateMucRequestFormData) => Promise<any>;
}

class GroupManager extends React.Component<iProps, iState> {
	limitShowContactOnThisPage = 8;
	navParams: GroupManagerNavParams = {} as any;

	cameraService = new CameraService();

	componentIsMounted: Boolean = false;
	componentIsUpdated: Boolean = false;

	constructor(props: iProps) {
		super(props);
		const locationState = props.location.state,
			groupDetail = locationState?.groupDetail;

		/*let user: any, contacts: any;

		dbService.me().then((_user: any) => (user = _user));
		apiService.getContacts(false, 'confirmed').then((_contacts: any) => (contacts = _contacts));*/

		this.state = {
			selectedContacts: [],
			selectedContactIds: [],
			loggedInUser: {}, //user,
			showPinturaEditor: false,
			showPinturaEditorFor: '',
			showMoreMembersModal: false,
			showGroupAboutModal: false,
			canDisplayQRCode: false,
			adminApproval: false,
			canInvite: false,
			showMyAliasInGroupPopup: false,
			myAliasInGroupValue: '',
			aliasInGroup: '',
			groupName: '',
			aboutGroupValue: '',
			groupDescription: '',
			hasError: false,
			oldLayout: false,
			allContacts: [],
			contacts: [], //contacts,
			errorMessage: '',
			openActionSheetFor: '',
			showChooseTagModal: false,
			tags: [],
			showAddTagPopup: false,
			groupDetail: groupDetail,
		};

		this.props.resetValues();
	}

	async setPageData() {
		let user: any = await dbService.me(),
			contacts: any = user.contacts.filter((_contact: any) => _contact.status === 'confirmed').sort(sortByAlias); /*
			contacts: any = await apiService.getContacts(false, 'confirmed').then((_contacts: any) => {
				let contacts: any = [];

				if (_contacts && _contacts.length > 0) {
					contacts = _contacts.sort(sortByAlias);
				}

				return contacts;
			});*/

		let selectedContactIds = this.state.selectedContactIds,
			selectedContacts = this.state.selectedContacts;

		if (sharedService.callBackData.groupManager) {
			if (sharedService.callBackData.groupManager.groupAllContacts) {
				selectedContacts = sharedService.callBackData.groupManager.groupAllContacts;
			}
			if (sharedService.callBackData.groupManager.groupAllContactIds) {
				selectedContactIds = sharedService.callBackData.groupManager.groupAllContactIds;
			}
		}

		this.setState({
			loggedInUser: user,
			contacts: contacts,
			allContacts: contacts,
			aliasInGroup: user.alias,
			myAliasInGroupValue: user?.username,
			selectedContacts,
			selectedContactIds,
		});
	}

	async componentDidMount() {
		// await this.setPageData();
		this.componentIsMounted = true;

		Navigation.getNavParamData(this.props).then((res: any) => {
			this.navParams = res;

			this.setState({ selectedContacts: this.navParams.selectedContacts, selectedContactIds: [...this.navParams.selectedContactIds] });
		});
	}

	componentWillUnmount() {
		sharedService.callBackData.groupManager = null;
		this.componentIsMounted = false;
	}

	async componentDidUpdate() {
		if (!this.componentIsUpdated) {
			this.componentIsUpdated = true;
			// await this.setPageData();
			this.componentIsMounted = true;
		}
	}

	_createGroup = () => {
		if (this.state.groupName && this.state.selectedContactIds && this.state.selectedContactIds?.length > 0) {
			this.props
				.createGroup({
					about: this.state.aboutGroupValue || '',
					tags: this.state.tags.join(','),
					name: this.state.groupName,
					mucImage: this.state.selectedGroupPhoto!,
					alias: this.state.myAliasInGroupValue || '',
					aliasImage: this.state.myAliasGroupPhoto!,
					users: this.state.selectedContactIds.join(','),
				})
				.then(async (groupDetail: GroupItem) => {
					// Send intial message to the new  group
					const usersNames = JSON.parse(JSON.stringify(this.state.selectedContactIds));
					const lastUserName = usersNames.pop();

					await xmpp.sendMessage({
						messageBody: `${this.props.profile.loggedInUserProfile?.username} invited <span class='invited-members'>${usersNames.join(', ')} and ${lastUserName}</span> to the group chat!`,
						messageState: ChatMessageState.ORIGINAL,
						messageType: MessageType.GROUP_CREATED,
						relatedMessageId: '',
						conversationType: ChatType.GROUP,
						receiverId: groupDetail.name,
					});

					await this.props.initChat({
						receiver: { ...groupDetail, handlerText: locale.dashboard.start_chat },
						type: groupDetail.mucName ? ChatType.GROUP : ChatType.P2P,
					});

					Navigation.chat({ receiver: groupDetail });
				});
		}
	};
	_renderAddRemoveContact = () => {
		// if (this.state.groupDetail) {
		return (
			<>
				<AddContactItem
					onClick={() => {
						Navigation.openAddMembers({
							actionType: AddMembersPageActionType.ChooseContactForCurrentGroup,
							previousSelectedContactsIds: this.navParams.selectedContactIds,
						});
					}}
				/>
				<RemoveContactItem
					onClick={() => {
						Navigation.openGroupMembers({
							selectedContacts: this.props.contact.contacts?.filter((contact) => this.state.selectedContactIds.indexOf(contact.username) !== -1),
							actionType: GroupMembersPageActionType.GroupManager,
							isDeleteMode: true,
						});
					}}
				/>
			</>
		);
		// }
	};

	toggleSelectedContact = (index: any) => {
		let allContacts = this.state.allContacts,
			selectedContacts = this.state.selectedContacts;

		allContacts[index].isSelected = !this.state.allContacts[index].isSelected;

		if (selectedContacts.hasOwnProperty(this.state.contacts[index]._id)) {
			delete selectedContacts[this.state.contacts[index]._id];
		} else {
			selectedContacts[this.state.contacts[index]._id] = {
				_id: this.state.contacts[index]._id,
				userId: this.state.contacts[index].userId,
				username: this.state.contacts[index].username,
				jid: `${this.state.contacts[index].userId}@${Config.xmppServer}`,
				admin: false,
			};
		}
		this.setState({
			allContacts: [...allContacts],
			selectedContacts: selectedContacts,
		});
	};

	_onPinturaEditingDone = (file: File) => {
		if (this.state.showPinturaEditorFor === 'group-photo') {
			this.setState({
				showPinturaEditor: false,
				showPinturaEditorFor: '',
				selectedGroupPhoto: file,
			});
		} else if (this.state.showPinturaEditorFor === 'alias-photo') {
			this.setState({
				showPinturaEditor: false,
				showPinturaEditorFor: '',
				myAliasGroupPhoto: file,
			});
		}
	};

	_renderSwitchItem = (title: any, value: any, onChange: any) => {
		return (
			<IonItem button lines="none" className={'swtich-item'}>
				<IonLabel slot="start">
					<p>{title}</p>
				</IonLabel>
				<IonToggle slot="end" className="toggle-customised" mode="ios" value={value} onChange={onChange} />
			</IonItem>
		);
	};

	extractContactName = (contact: any) => {
		return contact?.alias || contact?.username || contact?.userId || '';
	};

	_getActionsheetOptions = () => {
		const { openActionSheetFor } = this.state;

		const onPhotoAddedCallback = (photo: Array<File>) => {
			this.setState({
				showPinturaEditor: true,
				showPinturaEditorFor: openActionSheetFor,
				selectedImage: photo[0],
			});
		};
		const takePhoto = {
			text: locale.profile.upload_photo.take_photo,
			handler: () => {
				this.cameraService.capturePhotoFromCamera(onPhotoAddedCallback);
			},
		};

		const chooseFromAlbum = {
			text: locale.profile.upload_photo.choose_album,
			handler: () => {
				this.cameraService.choosePhotoFromAlbum(onPhotoAddedCallback);
			},
		};

		const cancelPhoto = {
			text: locale.global.cancel,
			handler: () => {
				this.setState({ openActionSheetFor: '' });
			},
		};

		if (openActionSheetFor === 'group-photo' || openActionSheetFor === 'alias-photo') {
			return [takePhoto, chooseFromAlbum, cancelPhoto];
		}
		return [];
	};

	render() {
		if (this.componentIsMounted) {
			const selectedContacts = this.props.contact.contacts?.filter((contact) => this.state.selectedContactIds.indexOf(contact.username) !== -1);
			const { groupName, selectedGroupPhoto } = this.state;
			return (
				<IonPage className="group-manager-page">
					<TopNavbar
						{...this.props}
						rightButtonDisabled={!this.state.groupName.trim()}
						rightButtonText="Create"
						onRightButtonPress={() => {
							this._createGroup();
						}}
						pageTitle={locale.groups.new_group}
						pageTitleDescription={this.state.selectedContactIds?.length > 0 ? this.state.selectedContactIds?.length + ' Members' : '# of Members'}
						showBack={true}
						hideSearchBar={true}
					/>

					<IonContent className="groupCustomCard has-topbar">
						<div className="fields-list">
							<IonItem lines="none" className="groupname-item">
								<IonThumbnail
									slot="start"
									onClick={() => {
										this.setState({ openActionSheetFor: 'group-photo' });
									}}
								>
									{selectedGroupPhoto ? <IonImg src={URL.createObjectURL(selectedGroupPhoto)} /> : <IonImg className="placeholder" src={CameraIcon} />}
								</IonThumbnail>

								<IonInput value={groupName} onIonChange={(e) => this.setState({ groupName: e.detail.value! })} placeholder="Please specify group name" />
								{this.state.groupName.trim().length > 0 && (
									<IonButton
										className="value"
										fill="clear"
										onClick={() => {
											this.setState({ groupName: '' });
										}}
									>
										<IonIcon src={CrossIcon} />
									</IonButton>
								)}
							</IonItem>

							<div className="group-field-item">
								<div className="row-first">
									<div className="title">About</div>
									{!this.state.aboutGroupValue && (
										<IonButton className="value" fill="clear" onClick={() => this.setState({ showGroupAboutModal: true })}>
											<span className="placeholder">{locale.groups.not_set}</span>
											<IonIcon src={ArrowRightIcon} />
										</IonButton>
									)}
								</div>
								{this.state.aboutGroupValue && (
									<div className="row-second" style={{ marginBottom: '14px' }}>
										<div className="title" style={{ maxWidth: 'unset' }}>
											{this.state.aboutGroupValue}
										</div>
										<div className="arrow-adjust-view">
											<IonButton fill="clear" style={{ height: '24px' }} className="value" onClick={() => this.setState({ showGroupAboutModal: true })}>
												<IonIcon src={ArrowRightIcon} />
											</IonButton>
										</div>
									</div>
								)}
							</div>

							<div className="group-field-item">
								<div className="row-first">
									<div className="title">My Alias Photo</div>
									<IonButton className="value" fill="clear" onClick={() => this.setState({ openActionSheetFor: 'alias-photo' })}>
										<img src={this.state.myAliasGroupPhoto ? URL.createObjectURL(this.state.myAliasGroupPhoto) : DefaultProfilePic} alt="profilePhoto" />
										<IonIcon src={ArrowRightIcon} />
									</IonButton>
								</div>
							</div>

							<div className="group-field-item">
								<div className="row-first">
									<div className="title">My Alias</div>
									<IonButton className="value" fill="clear" onClick={() => this.setState({ showMyAliasInGroupPopup: true })}>
										{this.state.myAliasInGroupValue ? <span className="data">{this.state.myAliasInGroupValue}</span> : <span className="placeholder">{locale.groups.not_set}</span>}
										<IonIcon src={ArrowRightIcon} />
									</IonButton>
								</div>
							</div>

							<div className="group-field-item" style={{ minHeight: '67px' }}>
								<div className="row-first">
									<div className="title">Set Tags</div>
									<IonButton
										className="value"
										fill="clear"
										onClick={() => {
											if (this.props.profile?.myTags && this.props.profile?.myTags.length > 0) {
												this.setState({ showChooseTagModal: true });
											} else {
												this.setState({ showAddTagPopup: true });
											}
										}}
									>
										{!this.state.tags || this.state.tags.length === 0 ? <span className="placeholder">{locale.groups.not_set}</span> : <div className="tagname">{this.state.tags.join(', ')}</div>}
										<IonIcon src={ArrowRightIcon} />
									</IonButton>
								</div>
								<div className="row-second">
									{/* <IonIcon icon={chevronForwardOutline}></IonIcon> */}

									<div className="title">Classify Groups by Tags</div>
								</div>
							</div>

							<div className="group-field-item" style={{ minHeight: '67px' }}>
								<div className="row-first">
									<div className="title">Guidelines</div>
									<IonButton className="value" fill="clear">
										<span className="placeholder">{locale.groups.not_set}</span>
										<IonIcon src={ArrowRightIcon} />
									</IonButton>
								</div>
								<div className="row-second">
									<div className="title">Rules, Agreements, Password protections, and more</div>
								</div>
							</div>

							<div className="group-field-item" style={{ minHeight: '99px' }}>
								<div className="row-first">
									<div className="title">Admin Approval</div>
									<div className="value">
										<IonToggle
											slot="end"
											className="toggle-customised"
											mode="ios"
											checked={this.state.adminApproval}
											onChange={() => {
												this.setState({
													adminApproval: !this.state.adminApproval,
												});
											}}
										/>
									</div>
								</div>
								<div className="row-second">
									<div className="title">Group members need group owner or admin approval before they can invite friends. Option to scan QR codes to join the group will be disabled.</div>
								</div>
							</div>
						</div>

						<IonItemDivider className="group-members-devider">
							<IonLabel>{locale.groups.members}</IonLabel>
							{/* {this.state.selectedContacts?.length > this.limitShowContactOnThisPage && ( */}
							<div
								slot="end"
								className="view-more-contact-btn-view"
								onClick={() => {
									Navigation.openGroupMembers({
										groupAllContactIds: this.state.selectedContactIds,
										selectedContacts: selectedContacts,
										actionType: GroupMembersPageActionType.ViewMore,
									});
								}}
							>
								<div className="title">View More</div>
								<IonIcon src={ArrowRightIcon}></IonIcon>
							</div>
							{/* )} */}
						</IonItemDivider>

						<div className="selected-contacts-container">
							{selectedContacts &&
								selectedContacts.map((_contact: ContactItem, key: any) => {
									if (key >= this.limitShowContactOnThisPage) {
										return null;
									}
									return <ContactView key={key} contact={_contact} onClick={() => {}} />;
								})}
							{this._renderAddRemoveContact()}
						</div>
						<div className="blank" />
					</IonContent>
					<div className="encryption-view">
						<h3>Encryption</h3>
						<p>All communication within this group chat is end-to-end encrypted</p>
					</div>

					<ImageEditor
						openEditorType={OpenEditorType.FOR_PROFILE_GROUP_PHOTO}
						selectedImage={this.state.selectedImage}
						show={this.state.showPinturaEditor}
						onClose={() => {
							this.setState({
								showPinturaEditor: false,
								showPinturaEditorFor: '',
							});
						}}
						onSave={this._onPinturaEditingDone}
					/>

					<GroupAboutModal
						props={this.props}
						initialValue={this.state.aboutGroupValue}
						show={this.state.showGroupAboutModal}
						onCloseCallBack={() => this.setState({ showGroupAboutModal: false })}
						onConfirm={(value: string) => {
							this.setState({
								aboutGroupValue: value,
								showGroupAboutModal: false,
							});
						}}
					/>

					<MyAliasInGroupPopup
						initialValue={this.state.myAliasInGroupValue}
						show={this.state.showMyAliasInGroupPopup}
						onCloseCallBack={() => this.setState({ showMyAliasInGroupPopup: false })}
						onConfirm={(value: string) => {
							this.setState({
								showMyAliasInGroupPopup: false,
								myAliasInGroupValue: value,
							});
						}}
					/>

					<IonActionSheet
						header={this.state.openActionSheetFor === 'group-photo' ? 'Add Group Photo' : 'Add Alias Photo'}
						mode="ios"
						isOpen={this.state.openActionSheetFor !== ''}
						onDidDismiss={() => this.setState({ openActionSheetFor: '' })}
						cssClass="group-manager-action-sheet"
						buttons={this._getActionsheetOptions()}
					></IonActionSheet>

					<ChooseTagsModal
						props={this.props}
						loggedInUser={this.props.profile.loggedInUserProfile}
						selectedTags={this.state.tags}
						show={this.state.showChooseTagModal}
						onCancel={() => {
							this.setState({ showChooseTagModal: false });
						}}
						onDone={(updatedTags: any) => {
							this.setState({ showChooseTagModal: false, tags: updatedTags });
						}}
						onTagCreated={(value: string) => {
							const tmpTagsList = this.state.tags;
							tmpTagsList.push(value);
							this.setState({ tags: tmpTagsList, showAddTagPopup: false });
						}}
					/>
					<AddNewTagPopup
						show={this.state.showAddTagPopup}
						onCloseCallBack={() => {
							this.setState({ showAddTagPopup: false });
						}}
						onTagCreated={(value: string) => {
							const tmpTagsList = this.state.tags;
							tmpTagsList.push(value);
							this.setState({ tags: tmpTagsList, showAddTagPopup: false });
						}}
					/>
				</IonPage>
			);
		} else {
			return <></>;
		}
	}
}
const mapStateToProps = (state: ReduxStates) => {
	return {
		profile: state.profile,
		contact: state.contact,
		loggedInUser: state.global.loggedInUser,
		isLoggedIn: state.auth.isLoggedIn,
	};
};

const mapDispatchToProps = (dispatch: any) => ({
	resetValues: () => dispatch(resetValues()),
	addGroup: (payload: any) => dispatch(addGroup(payload)),
	initChat: (payload: any) => dispatch(initChat(payload)),
	createGroup: (payload: CreateMucRequestFormData) => dispatch(createGroup(payload)),
});

export default connect(mapStateToProps, mapDispatchToProps)(GroupManager);
