import React from 'react';
import { IonCard, IonButton, IonButtons, IonContent, IonIcon, IonImg, IonLabel, IonLoading, IonPage, IonTitle, IonToolbar, IonRow, IonCol } from '@ionic/react';
import './style.scss';
import { RouteComponentProps } from 'react-router';
// import { createDefaultImageReader, createDefaultImageWriter, PinturaEditorOptions } from 'pintura';
import { connect } from 'react-redux';
import { updateUser } from '../../redux/actions/profile';
import store from '../../redux/store';
import { SHOW_TOAST } from '../../redux/constants/common';
import { chevronBackOutline } from 'ionicons/icons';
import { locale } from '../../locales/local';
import { dbService } from '../../services/dbService';

// const PrintIcon = './assets/icon/print.svg';
const DownloadIcon = './assets/icon/download.svg';
const ShareIcon = './assets/icon/share.svg';
const PencilNoteIcon = './assets/icon/pencil-note.svg';

interface iProps extends RouteComponentProps<{ name: string }> {
	location: any;
	imageUploader: any;
	updateUser: Function;
}
interface iState {
	selectedFile: any;
	selectedImage?: any;
	loggedInUser?: any;
	isLoading: boolean;
	myQrCodeType: string;
}

class MyQRCode extends React.Component<iProps, iState> {
	componentIsMounted: Boolean = false;
	componentIsUpdated: Boolean = false;

	constructor(props: iProps) {
		super(props);
		let locationState = props.location.state,
			myQrCodeType = locationState?.myQrCodeType;

		this.triggerCameraUpload = this.triggerCameraUpload.bind(this);
		this.onFileChange = this.onFileChange.bind(this);
		this.compressFileToBase64 = this.compressFileToBase64.bind(this);
		this.state = {
			loggedInUser: undefined,
			selectedImage: undefined,
			isLoading: false,
			selectedFile: null,
			myQrCodeType: myQrCodeType,
		};
	}

	setPageData = async () => {
		let user = await dbService.me();

		this.setState((state: any, props: any) => ({
			...state,
			loggedInUser: user,
			selectedImage: user?.qrCode,
		}));
	};

	componentDidMount = async () => {
		await this.setPageData();
	};

	componentDidUpdate = async () => {
		if (!this.componentIsUpdated) {
			this.componentIsUpdated = true;
			await this.setPageData();
		}
	};

	triggerCameraUpload() {
		let uploader = document.getElementById('qrcode-camera');
		uploader?.click();
	}

	onFileChange(event: any) {
		this.setState({ selectedFile: event.target.files[0] });
		let reader = new FileReader();
		reader.onload = (event: any) => this.setState({ selectedImage: event.target.result });
		reader.readAsDataURL(event.target.files[0]);
	}

	async compressFileToBase64() {
		// const options: PinturaEditorOptions = {
		// 	locale: 'en',
		// 	imageReader: createDefaultImageReader(),
		// 	imageWriter: createDefaultImageWriter({ mimeType: 'image/png' }),
		// };

		if (this.state.selectedFile) {
			this.setState({ isLoading: true });
			// const userImage: any = await processImage(this.state.selectedFile, options);

			// todo we need to review this ... "picture" is old and not used any longer - disabling for now

			//this.props.updateUser({ picture: userImage });

			this.setState({ isLoading: false });
		} else {
			store.dispatch({ type: SHOW_TOAST, payload: { showToast: true, toastMessage: locale.profile.select_image } });
		}
	}

	render() {
		const { loggedInUser, myQrCodeType } = this.state,
			profileUserName = loggedInUser?.username || loggedInUser?.userId || '';

		return (
			<IonPage className="my-qrcode-page">
				<IonToolbar className="toolbar-wrapper">
					{myQrCodeType !== 'all' && (
						<IonTitle style={{ textTransform: 'capitalize' }} className="toolbarTitle">
							{locale.profile.my_qrcode}
						</IonTitle>
					)}

					<IonButtons onClick={() => this.props.history.goBack()} slot="start">
						<IonButton>
							<IonIcon slot="icon-only" icon={chevronBackOutline} />
						</IonButton>
					</IonButtons>

					<IonButtons slot="end">
						{myQrCodeType === 'all' && (
							<IonButton className="scan-btn">
								<IonIcon size="large" style={{ fontSize: '40px', marginTop: 16, marginLeft: 16 }} slot="icon-only" src="assets/icon/scan.svg" />
							</IonButton>
						)}
					</IonButtons>
				</IonToolbar>
				<IonContent className="mainContent-profile has-bottom-navbar">
					{myQrCodeType === 'all' && (
						<IonRow className="qrcode-type-btns-container">
							<IonButton fill="clear">{locale.global.work}</IonButton>
							<IonButton fill="clear" className="active">
								{locale.global.me}
							</IonButton>
							<IonButton fill="clear">{locale.global.pay}</IonButton>
						</IonRow>
					)}
					<div className="inner-content">
						<IonLabel style={{ textAlign: 'center' }} color="light">
							<h1 className="usernametxt">{loggedInUser?.name}</h1>
						</IonLabel>
						<IonLabel style={{ textAlign: 'center' }} color="light">
							<p>{(!profileUserName.includes('@') ? '@' : '') + '' + profileUserName} </p>
						</IonLabel>
						<IonCard style={{ background: 'transparent', margin: ' 20px auto', width: '72vw', maxHeight: '72vw', boxShadow: '0px 4px 20px rgba(0, 0, 0, 0.35)' }}>
							<IonImg alt="" src={this.state.selectedImage} />
						</IonCard>

						<IonRow className="qr-share-option-btns">
							<IonCol>
								<IonLabel color="light">
									<IonIcon size="large" slot="icon-only" src={PencilNoteIcon} />
								</IonLabel>
							</IonCol>
							<IonCol>
								<IonLabel color="light">
									<IonIcon size="large" slot="icon-only" src={DownloadIcon} />
								</IonLabel>
							</IonCol>
							<IonCol>
								<IonLabel color="light">
									<IonIcon size="large" slot="icon-only" src={ShareIcon} />
								</IonLabel>
							</IonCol>
						</IonRow>

						{myQrCodeType !== 'all' && (
							<IonRow style={{ textAlign: 'center', marginTop: '20px' }}>
								<IonCol>
									<IonLabel color="light" style={{ cursor: 'pointer' }} onClick={(event: any) => this.triggerCameraUpload()}>
										<IonIcon size="large" style={{ fontSize: '40px' }} slot="icon-only" src="assets/icon/scan.svg" />
										<p style={{ marginTop: '5px' }}>{locale.global.scan}</p>
									</IonLabel>
								</IonCol>
							</IonRow>
						)}
					</div>
				</IonContent>
				<input accept="image/*" id="qrcode-camera" onChange={this.onFileChange} type="file" style={{ display: 'none' }} capture="environment" title={''} />
				<IonLoading isOpen={this.state.isLoading} onDidDismiss={() => this.setState({ isLoading: false })} message={locale.global.please_wait} duration={5000} />
			</IonPage>
		);
	}
}

const mapStateToProps = (state: any) => {
	return {
		imageUploader: state.profile,
		loggedInUser: state.global.loggedInUser,
	};
};

const mapDispatchToProps = (dispatch: any) => ({
	updateUser: (payload: String) => dispatch(updateUser(payload)),
});

export default connect(mapStateToProps, mapDispatchToProps)(MyQRCode);
