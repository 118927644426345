import { IonButton, IonIcon, IonItem, IonLabel, IonList, IonModal } from '@ionic/react';
import React, { useMemo, useState } from 'react';

import './style.scss';

interface VisibleToFriendProps {
	show: boolean;
	onCloseCallBack: () => void;
	selectedOption: any;
}

const VisibleToFriend: React.FC<VisibleToFriendProps> = ({ show, onCloseCallBack, selectedOption }) => {
	const [visible, setVisible] = useState(false);
	const [option, setOption] = React.useState('All');
	const options = useMemo(
		() => [
			{ title: 'All', value: 'All' },
			{ title: 'Last 6 months', value: 'Last 6 Month' },
			{ title: 'Last month', value: 'Last Month' },
			{ title: 'Last 3 days', value: 'Last 3 Days' },
			{ title: '24 hours', value: '24 Hours' },
		],
		[]
	);

	const selectOption = () => {
		selectedOption && selectedOption(option);
		onCloseCallBack();
	};

	return (
		<IonModal showBackdrop={true} isOpen={show} onDidDismiss={onCloseCallBack} swipeToClose={true} cssClass="visible-friend-modal" animated={true}>
			<div className="content">
				<div className="title">Visible to Friends</div>
				<IonList className="visibleList" lines="none">
					{options.map((item, key) => {
						return (
							<IonItem
								key={key}
								className={option && 'active'}
								lines="none"
								onClick={() => {
									setVisible(!visible);
									setOption(item.value);
								}}
							>
								{option === item.value && <IonIcon className="right-icon" slot="end" src="assets/icon/Combined Shape.svg"></IonIcon>}

								<IonLabel slot="start" className={option === item.value ? 'active' : ''}>
									{item.title}
								</IonLabel>
							</IonItem>
						);
					})}
				</IonList>
				<div className="bottom-buttons">
					<IonButton className="cancel" onClick={onCloseCallBack}>
						Cancel
					</IonButton>
					<IonButton
						disabled={!option || option == 'All'}
						className="done"
						onClick={() => {
							selectOption();
						}}
					>
						Done
					</IonButton>
				</div>
			</div>
		</IonModal>
	);
};

export default VisibleToFriend;
