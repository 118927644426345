import React, { useCallback, useEffect, useState } from 'react';
import { IonButton, IonInput, IonItem, IonLabel, IonModal } from '@ionic/react';
import './style.scss';
import { connect } from 'react-redux';
import { hidePageLoading, pageLoading, resetValues } from '../../redux/actions/dashboard';
import { TopNavbar } from '../../pages/common/header/topbar';
import { updateUser } from '../../redux/actions/profile';
import { ProfileStateTypes, ReduxStates } from '../../types/redux-states';
import { TagItem } from '../../types/contact';
const closeIcon = './assets/icon/Close.svg';

interface ChooseTagsModalProps {
	profile?: ProfileStateTypes;
	loggedInUser?: any;
	show: boolean;
	props: any;
	updateUser?: any; //redux
	pageLoading?: any; //redux
	hidePageLoading?: any; //redux
	selectedTags: any;
	onCancel?: any;
	onDone?: any;
	onTagCreated: any;
}

const ChooseTagsModal: React.FC<ChooseTagsModalProps> = ({ props, profile, loggedInUser, show, onCancel, updateUser, pageLoading, hidePageLoading, selectedTags, onDone, onTagCreated }) => {
	const [tagName, setTagName] = useState<string | null | undefined>('');

	const [tags, setTags] = useState<Array<TagItem>>([]);
	const [selectedTagsList, setSelectedTagsList] = useState<any>([...selectedTags]);

	const onCloseCallBack = useCallback(() => {
		onCancel && onCancel();
	}, [onCancel]);
	const onDoneCallBack = useCallback(async () => {
		if (tagName && tags.map((tagItem: TagItem) => tagItem.tag).indexOf(tagName) === -1) {
			let existingTags = tags || [];
			existingTags.push({ tag: tagName, hide: false });

			pageLoading({ loaderMessage: 'Creating...' });
			updateUser({
				_id: loggedInUser._id,
				tags: existingTags,
				updateServer: true,
				callBack: (status: boolean, response: any) => {
					hidePageLoading();
					if (status) {
						selectedTagsList.push(tagName);
						setTags([...existingTags]);
						setSelectedTagsList([...selectedTagsList]);
					}
					onDone && onDone(selectedTagsList);
				},
			});
		} else {
			pageLoading({ loaderMessage: 'Saving...' });
			setTimeout(() => {
				hidePageLoading();
			}, 1000);
			onDone && onDone(selectedTagsList);
		}
	}, [onDone, hidePageLoading, pageLoading, tagName, tags, selectedTagsList]);

	useEffect(() => {
		if (show) {
			setTagName('');
			setTags(profile?.myTags || []);
			setSelectedTagsList([...selectedTags]);
		}
	}, [show, selectedTags, profile]);
	// let pageTitle = '',
	// 		rightBtnTitle = 'Done()',
	// 		rightButtonDisable = true,
	// 		showSelectedContactsView = false,
	// 		previousSelectedContactsIds;

	return (
		<IonModal mode="ios" showBackdrop={true} isOpen={show} onDidDismiss={onCloseCallBack} swipeToClose={true} cssClass="choose-tags-modal" animated={true}>
			<TopNavbar
				{...props}
				pageTitle="Add Tags"
				hideSearchBar={true}
				leftButtonIcon={closeIcon}
				rightButtonText={'Done'}
				isHideRightButton={false}
				onRightButtonPress={() => {
					onDoneCallBack();
				}}
				isRightButtonActive={selectedTagsList.length > 0 || tagName ? 'active' : ''}
				rightButtonDisabled={(selectedTagsList === 0 && !tagName) || selectedTagsList.length === selectedTags.length}
				onLeftButtonPress={onCloseCallBack}
			/>

			<div className="content has-topbar">
				<IonLabel className="tag-name">Tag Name</IonLabel>

				{/* <div className='tag-name'>Tag Name</div> */}
				<div className="search-input">
					<IonItem lines="none">
						<IonInput value={tagName} placeholder="Enter Tag name" onIonChange={(e) => setTagName(e.detail.value!)}></IonInput>
					</IonItem>
				</div>

				<div className="title">All Tags</div>
				<div className="tag-container">
					{tags &&
						tags
							.filter((tag: TagItem) => (tagName ? tag.tag.toLowerCase().indexOf(tagName.toLowerCase()) !== -1 : true))
							.map((tagItem: TagItem, key: any) => {
								const isSelected = selectedTagsList.indexOf(tagItem.tag) !== -1;
								return (
									<IonButton
										key={key}
										fill="clear"
										className={'tag-item ' + (isSelected ? 'active-tag' : '')}
										onClick={() => {
											if (isSelected) {
												selectedTagsList.splice(selectedTagsList.indexOf(tagItem.tag), 1);
											} else {
												selectedTagsList.push(tagItem.tag);
											}

											setSelectedTagsList([...selectedTagsList]);
										}}
									>
										{tagItem.tag}
									</IonButton>
								);
							})}
				</div>
			</div>
		</IonModal>
	);
};

const mapStateToProps = (state: ReduxStates) => {
	return {
		profile: state.profile,
	};
};

const mapDispatchToProps = (dispatch: any) => ({
	resetValues: () => dispatch(resetValues()),
	updateUser: (payload: any) => dispatch(updateUser(payload)),
	pageLoading: (payload: any) => dispatch(pageLoading(payload)),
	hidePageLoading: () => dispatch(hidePageLoading()),
});

export default connect(mapStateToProps, mapDispatchToProps)(ChooseTagsModal);
