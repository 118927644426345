import { Config } from '../config/config';
import _, { method } from 'lodash';
import { info, isBlank, logError, setInternalStorage, getInternalStorage, logoutUser } from '../helpers/common';
import axios from 'axios';
import {
	RegisterUsernameRequestData,
	CreateAccountRequestData,
	GetUserProfileRequestData,
	LoginRequestData,
	UpdateProfileRequestData,
	UserExistsRequestData,
	GetAccessTokenRequestData,
	AddProfilePhotoRequestData,
	RemoveProfilePhotoRequestData,
	ActivateProfilePhotoRequestData,
	HideProfilePhotoRequestData,
	GetProfileByTokenRequestData,
	GetProfileByUsernameRequestData,
	SendInvitationRequestData,
	AcceptInvitationRequestData,
	RejectInvitationRequestData,
	GetRosterItemsByTagRequestData,
	RemoveRosterItemRequestData,
	BlockUserRequestData,
	UnBlockUserRequestData,
	DeleteTagRequestData,
	RemoveUserFromTagRequestData,
	AddUserToTagRequestData,
	SetTagVisibilityRequestData,
	UpdateChatOnlyProfileRequestData,
	UpdateDotsRequestData,
	ChangePrivacySettingRequestData,
	RefreshTokenRequestData,
	CreateMucRequestFormData,
	UpdateMucRequestFormData,
	AddUsersToMucRequestData,
	RemoveUsersFromMucRequestData,
	MucRequestData,
	CreateThreadRequestData,
	UpdateThreadRequestData,
	DeleteThreadRequestData,
	ListThreadsRequestData,
	GetThreadRequestData,
	TranslateRequestData,
	TranslateLanguagesListRequestData,
} from '../types/api-requests';
import { LocalStorageKeys, NetworkStatus, PubSubEventType, SessionStorageKeys } from './enumService';

import PubSub from 'pubsub-js';

enum API_PREPEND_PATH {
	AUTH = 'oauth/',
	REST_SERVICES = 'rest/services/',
	API_SERVICES = 'api/services/',
}

enum END_POINTS {
	LOGIN = 'token',

	GET_ACCESS_TOKEN = 'accountService/getAccessToken',
	USER_EXISTS = 'accountService/userExists',

	REGISTER_USERNAME = 'userService/registerUsername',
	CREATE_ACCOUNT = 'userService/createAccount',
	CHANGE_PASSWORD = 'userService/changePassword',
	GET_USER_PROFILE = 'userService/getUserProfile',
	GET_PROFILE_BY_TOKEN = 'userService/getProfileByToken',
	GET_PROFILE_BY_USERNAME = 'userService/getProfileByUsername',
	UPDATE_PROFILE = 'userService/updateProfile',
	UPDATE_DOTS = 'userService/updateDots',
	UPDATE_CHATONLY_PROFILE = 'userService/updateChatOnlyProfile',
	GENERATE_TOKEN = 'userService/generateToken',
	ADD_PROFILE_PHOTO = 'userService/addProfilePhoto',
	REMOVE_PROFILE_PHOTO = 'userService/removeProfilePhoto',
	UPDATE_PROFILE_PHOTO = 'userService/updateProfilePhoto',
	ACTIVATE_PROFILE_PHOTO = 'userService/activateProfilePhoto',
	HIDE_PROFILE_PHOTO = 'userService/hideProfilePhoto',
	UNHIDE_PROFILE_PHOTO = 'userService/unHideProfilePhoto',
	HIDE_ALL_PROFILE_PHOTO = 'userService/hideAllProfilePhoto',
	UNHIDE_ALL_PROFILE_PHOTO = 'userService/unHideAllProfilePhoto',
	GET_MESSAGE_ID_PREFIX = 'userService/getMessageIdPrefix',

	UPDATE_CHATONLY_PROFILE_PHOTO = 'user/updateChatOnlyProfilePhoto',
	UPLOAD_FILE = 'user/uploadFile',

	GET_TAGS = 'rosterService/getTags',
	SEND_INVITATION = 'rosterService/sendInvitation',
	ACCEPT_INVITATION = 'rosterService/acceptInvitation',
	REJECT_INVITATION = 'rosterService/rejectInvitation',
	BLOCK_USER = 'rosterService/blockUser',
	UN_BLOCK_USER = 'rosterService/unBlockUser',
	GET_ROSTER_ITEMS = 'rosterService/getRosterItems',
	GET_INVITATION_REQUESTS = 'rosterService/getInvitationRequests',
	GET_BLOCKED_ROSTER_ITEMS = 'rosterService/getBlockedRosterItems',
	GET_ROSTER_ITEMS_BY_TAG = 'rosterService/getRosterItemsByTag',
	REMOVE_ROSTER_ITEM = 'rosterService/removeRosterItem',

	ADD_USER_TO_TAG = 'rosterService/addUserToTag',
	REMOVE_USER_FROM_TAG = 'rosterService/removeUserFromTag',
	DELETE_TAG = 'rosterService/deleteTag',
	SET_TAGS_VISIBILITY = 'rosterService/setTagsVisibility',
	CHANGE_PRIVACY_SETTING = 'rosterService/changePrivacySetting',

	GET_MUC_ID = 'muc/getMucId',
	CREATE_MUC = 'muc/createMuc',
	UPDATE_MUC = 'muc/updateMuc',
	ADD_USERS_TO_MUC = 'muc/addUsersToMuc',
	REMOVE_USERS_FROM_MUC = 'muc/removeUsersFromMuc',
	GET_MUC_MEMBERS = 'muc/getMucMembers',
	GET_MUC_INFO = 'muc/getMucInfo',
	GET_MUC_LIST = 'muc/getMucList',
	DESTROY_MUC = 'muc/destroyMuc',
	LEAVE_MUC = 'muc/leaveMuc',

	GET_THREAD_ID = 'muc/getThreadId',
	CREATE_THREAD = 'muc/createThread',
	UPDATE_THREAD = 'muc/updateThread',
	LIST_THREAD = 'muc/listThread',
	DELETE_THREAD = 'muc/deleteThread',

	TRANSLATE = 'aws/translate/translate',
	TRANSLATE_LISTLANGUAGES = 'aws/translate/listLanguages',
}

let apiRequestPending: any = [];
let isRefreshingToken = false;

const // initWSS = async () => {
	// 	api.wss = new WebSocket('wss://local.charlesassaf.com:3100');

	// 	while (api.wss.readyState === 0) {
	// 		await sleep(200);
	// 	}

	// 	api.wss.onmessage = (event: any) => {
	// 		let data: any = isJsonString(event.data) ? JSON.parse(event.data) : event.data;
	// 		info('serverApi::initWSS.onMessage: received: %s', data);
	// 	};
	// },

	apiRequest = async ({
		prependPath = API_PREPEND_PATH.REST_SERVICES,
		endpoint = '',
		method = 'POST',
		data = {},
		params = null,
		idRequired = true,
		isFormData = false,
		report = false,
		timeout = 15000,
		additionalHeaders = {},
	}: {
		endpoint: string;
		data?: any;
		params?: any;
		method?: 'GET' | 'POST';
		prependPath?: string;
		idRequired?: Boolean;
		report?: Boolean;
		isFormData?: Boolean;
		timeout?: number;
		additionalHeaders?: any;
	}) => {
		const headers: any = {
				Accept: 'application/json',
				'Content-Type': 'application/json',
			},
			cookies: any = getInternalStorage();

		let response: any, token: string;
		// sanity check if network is considered offline
		if (window.navigator.onLine && (!cookies[SessionStorageKeys.Network] || cookies[SessionStorageKeys.Network] === 'offline')) {
			// attempt to send a ping to the api server

			await axios({
				method: 'post',
				url: `${Config.apiUrl}/${END_POINTS.GET_ACCESS_TOKEN}`,
				headers: headers,
				timeout: cookies[SessionStorageKeys.Mode] === 'LOCAL' ? 60000 : timeout,
			})
				.then((_response: any) => {
					cookies[SessionStorageKeys.Network] = NetworkStatus.ONLINE;
					setInternalStorage(SessionStorageKeys.Network, NetworkStatus.ONLINE);
				})
				.catch((err) => {
					logError('serverApi::fetch:: API server is offline' + err.message);
				});
		}

		if (window.navigator.onLine && cookies[SessionStorageKeys.Network] === NetworkStatus.ONLINE) {
			const fullUrl = `${Config.apiUrl}${prependPath}${endpoint}`;
			if (cookies[SessionStorageKeys.IsApp]) {
				info(`serverApi::fetch:: requesting: ${fullUrl} with`, data);
			}

			// todo: need to decrypt this when encryption is activated
			token = cookies[LocalStorageKeys.Uatk];

			let options: any = {
				method: method,
				url: fullUrl,
				headers: _.omitBy(
					{
						...headers,
						Authorization: idRequired ? `Bearer ${token}` : undefined,
						...additionalHeaders,
					},
					isBlank
				),
				timeout: cookies[SessionStorageKeys.Mode] === 'LOCAL' ? 60000 : timeout,
				params: method === 'GET' ? data : params ? params : null,
				data: method === 'POST' ? (isFormData ? data : JSON.stringify(data)) : null,
				// data:  JSON.stringify({ ...data, _id: idRequired ? user._id : undefined, uuid: idRequired ? cookies[LocalStorageKeys.Uuid] : data.uuid }, isBlank)),
			};

			if (report) {
				abortController = new AbortController();
				options.signal = abortController.signal;
				options.onUploadProgress = (progressEvent: ProgressEvent) => {
					if (report) {
						const progressComplete = Math.round((progressEvent.loaded * 100) / progressEvent.total);
						PubSub.publish(PubSubEventType.UploadMedia, {
							action: PubSubEventType.UploadMedia,
							messageKey: data.get('messageKey'),
							percentComplete: progressComplete,
						});
					}
				};
			}
			try {
				response = await axios(options)
					.then((_response: any) => {
						if (report) {
							abortController = null;
						}

						return _response?.data ? _response.data : _response;
					})
					.catch(async (error: any) => {
						info('Error', error);

						logError(`serverApi::apiFetch::axios: Error requesting: ${fullUrl}}/`, error);
						// throw error.response?.data || error.response || error
						throw error.response?.data || error.response || error;
					});
			} catch (err) {
				let error: any = err;
				logError('serverApi::fetch::Error in try:', error.message);
				// setInternalStorage(SessionStorageKeys.Network, NetworkStatus.OFFLINE);
				// throw typeof error === 'object' ? error : { error }
				throw typeof error === 'object' ? error : { error };
			}
		} else {
			response = { error: 'Network is offline' };
			throw response;
		}

		return response;
	},
	apiFetch = async (requestData: { endpoint: string; data?: any; params?: any; method?: 'GET' | 'POST'; prependPath?: string; idRequired?: Boolean; report?: Boolean; isFormData?: Boolean; timeout?: number; additionalHeaders?: any }) => {
		return new Promise((resolve, reject) => {
			if (isRefreshingToken) {
				apiRequestPending.push({
					data: requestData,
					resolve,
					reject,
				});
			} else {
				apiRequest(requestData)
					.then((res) => {
						resolve(res);
					})
					.catch((error) => {
						if (error.error === 'invalid_token' || error.message === 'Network Error') {
							apiRequestPending.push({
								data: requestData,
								resolve,
								reject,
							});

							if (!isRefreshingToken) {
								isRefreshingToken = true;
								const cookies = getInternalStorage();

								const data: RefreshTokenRequestData = {
									refresh_token: cookies[LocalStorageKeys.Urtk],
									grant_type: 'refresh_token',
								};

								apiRequest({
									prependPath: API_PREPEND_PATH.AUTH,
									endpoint: END_POINTS.LOGIN,
									params: data,
									idRequired: false,
									additionalHeaders: {
										Authorization: `Basic ${Buffer.from(`client:secret`, 'utf8').toString('base64')}`,
									},
								})
									.then((res: any) => {
										if (res.access_token) {
											setInternalStorage(LocalStorageKeys.Uatk, res.access_token);
											setInternalStorage(LocalStorageKeys.Urtk, res.refresh_token);
										} else {
											// logoutUser();
											reject(error);
										}
									})
									.catch(() => {
										// logoutUser();
										reject(error);
									})
									.finally(() => {
										isRefreshingToken = false;

										if (apiRequestPending && apiRequestPending.length > 0) {
											apiRequestPending.forEach((requestDetail: any) => {
												apiFetch(requestDetail.data).then(requestDetail.resolve).catch(requestDetail.reject);
											});
											apiRequestPending = [];
										}
									});
							}
						} else {
							reject(error);
						}
					});
			}
		}) as Promise<any>;
	},
	cancelFetch = () => abortController?.abort(),
	api = {
		wss: undefined as any,

		socketStarted: false as Boolean,

		ping: async (data: any) => {
			return new Promise((resolve) => {
				// await apiFetch({
				// 	endpoint: END_POINTS.GET_USER_PROFILE,
				// 	data,
				// 	method: 'POST',
				// 	idRequired: false,
				// })
				resolve(true);
			});
		},

		authenticate: async (data: LoginRequestData) =>
			await apiFetch({
				prependPath: API_PREPEND_PATH.AUTH,
				endpoint: END_POINTS.LOGIN,
				params: data,
				idRequired: false,
				additionalHeaders: {
					Authorization: `Basic ${Buffer.from(`client:secret`, 'utf8').toString('base64')}`,
				},
			}),

		userExists: async (data: UserExistsRequestData) =>
			await apiFetch({
				endpoint: END_POINTS.USER_EXISTS,
				data,
				method: 'GET',
				idRequired: false,
			}),

		getAccessToken: async (data: GetAccessTokenRequestData) =>
			await apiFetch({
				endpoint: END_POINTS.GET_ACCESS_TOKEN,
				data,
				method: 'GET',
				idRequired: false,
			}),

		registerUserName: async (data: RegisterUsernameRequestData) =>
			await apiFetch({
				endpoint: END_POINTS.REGISTER_USERNAME,
				data,
				method: 'POST',
				idRequired: false,
			}),

		registerUser: async (data: CreateAccountRequestData) =>
			await apiFetch({
				endpoint: END_POINTS.CREATE_ACCOUNT,
				data,
				method: 'POST',
				idRequired: false,
			}),

		getUserProfile: async (data?: GetUserProfileRequestData) =>
			await apiFetch({
				endpoint: END_POINTS.GET_USER_PROFILE,
				method: 'POST',
				data,
			}),

		getProfileByToken: async (data: GetProfileByTokenRequestData) =>
			await apiFetch({
				endpoint: END_POINTS.GET_PROFILE_BY_TOKEN,
				method: 'POST',
				data,
			}),

		getProfileByUsername: async (data: GetProfileByUsernameRequestData) =>
			await apiFetch({
				endpoint: END_POINTS.GET_PROFILE_BY_USERNAME,
				method: 'POST',
				data,
			}),

		getMessageIdPrefix: async () =>
			await apiFetch({
				endpoint: END_POINTS.GET_MESSAGE_ID_PREFIX,
				method: 'GET',
			}),

		updateDots: async (data: UpdateDotsRequestData) =>
			await apiFetch({
				endpoint: END_POINTS.UPDATE_DOTS,
				method: 'POST',
				data,
			}),

		updateMyProfile: async (data: UpdateProfileRequestData) =>
			await apiFetch({
				endpoint: END_POINTS.UPDATE_PROFILE,
				method: 'POST',
				data,
			}),

		updateChatOnlyProfile: async (data: UpdateChatOnlyProfileRequestData) =>
			await apiFetch({
				endpoint: END_POINTS.UPDATE_CHATONLY_PROFILE,
				method: 'POST',
				data,
			}),

		generateToken: async () =>
			await apiFetch({
				endpoint: END_POINTS.GENERATE_TOKEN,
				method: 'POST',
			}),

		addProfilePhoto: async (data: AddProfilePhotoRequestData) =>
			await apiFetch({
				endpoint: END_POINTS.ADD_PROFILE_PHOTO,
				method: 'POST',
				data,
				timeout: 120 * 1000,
			}),

		getContentLength: (formData: FormData) => {
			const formDataEntries = [...formData.entries()];

			const contentLength = formDataEntries.reduce((acc, [key, value]) => {
				if (typeof value === 'string') return acc + value.length;
				if (typeof value === 'object') return acc + value.size;

				return acc;
			}, 0);

			return contentLength;
		},

		updateChatOnlyProfilePhoto: async (data: FormData) => {
			// const contentLength = serverApi.getContentLength(data);
			return await apiFetch({
				additionalHeaders: {
					'Content-Type': 'multipart/form-data',
					Accept: '*/*',
				},
				prependPath: API_PREPEND_PATH.API_SERVICES,
				endpoint: END_POINTS.UPDATE_CHATONLY_PROFILE_PHOTO,
				method: 'POST',
				data,
				isFormData: true,
				timeout: 120 * 1000,
			});
		},

		uploadFile: async (data: FormData) => {
			// const contentLength = serverApi.getContentLength(data);
			return await apiFetch({
				additionalHeaders: {
					'Content-Type': 'multipart/form-data',
					Accept: '*/*',
				},
				prependPath: API_PREPEND_PATH.API_SERVICES,
				endpoint: END_POINTS.UPLOAD_FILE,
				method: 'POST',
				data,
				isFormData: true,
				timeout: 120 * 1000,
				report: true,
			});
		},

		removeProfilePhoto: async (data: RemoveProfilePhotoRequestData) =>
			await apiFetch({
				endpoint: END_POINTS.REMOVE_PROFILE_PHOTO,
				method: 'POST',
				data,
			}),

		updateProfilePhoto: async (data: UpdateProfileRequestData) =>
			await apiFetch({
				endpoint: END_POINTS.UPDATE_PROFILE_PHOTO,
				method: 'POST',
				data,
				timeout: 120 * 1000,
			}),

		activateProfilePhoto: async (data: ActivateProfilePhotoRequestData) =>
			await apiFetch({
				endpoint: END_POINTS.ACTIVATE_PROFILE_PHOTO,
				method: 'POST',
				data,
			}),

		hideProfilePhoto: async (data: HideProfilePhotoRequestData) =>
			await apiFetch({
				endpoint: END_POINTS.HIDE_PROFILE_PHOTO,
				method: 'POST',
				data,
			}),

		unHideProfilePhoto: async (data: HideProfilePhotoRequestData) =>
			await apiFetch({
				endpoint: END_POINTS.UNHIDE_PROFILE_PHOTO,
				method: 'POST',
				data,
			}),

		hideAllProfilePhoto: async () =>
			await apiFetch({
				endpoint: END_POINTS.HIDE_ALL_PROFILE_PHOTO,
				method: 'POST',
			}),

		unHideAllProfilePhoto: async () =>
			await apiFetch({
				endpoint: END_POINTS.UNHIDE_ALL_PROFILE_PHOTO,
				method: 'POST',
			}),

		getTags: async () =>
			await apiFetch({
				endpoint: END_POINTS.GET_TAGS,
				method: 'POST',
			}),

		sendInvitation: async (data: SendInvitationRequestData) =>
			await apiFetch({
				endpoint: END_POINTS.SEND_INVITATION,
				method: 'POST',
				data,
			}),

		acceptInvitation: async (data: AcceptInvitationRequestData) =>
			await apiFetch({
				endpoint: END_POINTS.ACCEPT_INVITATION,
				method: 'POST',
				data,
			}),

		rejectInvitation: async (data: RejectInvitationRequestData) =>
			await apiFetch({
				endpoint: END_POINTS.REJECT_INVITATION,
				method: 'POST',
				data,
			}),

		blockUser: async (data: BlockUserRequestData) =>
			await apiFetch({
				endpoint: END_POINTS.BLOCK_USER,
				method: 'POST',
				data,
			}),

		unBlockUser: async (data: UnBlockUserRequestData) =>
			await apiFetch({
				endpoint: END_POINTS.UN_BLOCK_USER,
				method: 'POST',
				data,
			}),

		getInvitationRequests: async () =>
			await apiFetch({
				endpoint: END_POINTS.GET_INVITATION_REQUESTS,
				method: 'POST',
			}),

		getBlockedRosterItems: async () =>
			await apiFetch({
				endpoint: END_POINTS.GET_BLOCKED_ROSTER_ITEMS,
				method: 'POST',
			}),

		getRosterItems: async () =>
			await apiFetch({
				endpoint: END_POINTS.GET_ROSTER_ITEMS,
				method: 'POST',
			}),

		getRosterItemsByTag: async (data: GetRosterItemsByTagRequestData) =>
			await apiFetch({
				endpoint: END_POINTS.GET_ROSTER_ITEMS_BY_TAG,
				method: 'POST',
				data,
			}),

		removeRosterItem: async (data: RemoveRosterItemRequestData) =>
			await apiFetch({
				endpoint: END_POINTS.REMOVE_ROSTER_ITEM,
				method: 'POST',
				data,
			}),

		addUserToTag: async (data: AddUserToTagRequestData) =>
			await apiFetch({
				endpoint: END_POINTS.ADD_USER_TO_TAG,
				method: 'POST',
				data,
			}),

		removeUserFromTag: async (data: RemoveUserFromTagRequestData) =>
			await apiFetch({
				endpoint: END_POINTS.REMOVE_USER_FROM_TAG,
				method: 'POST',
				data,
			}),

		deleteTag: async (data: DeleteTagRequestData) =>
			await apiFetch({
				endpoint: END_POINTS.DELETE_TAG,
				method: 'POST',
				data,
			}),

		setTagsVisibility: async (data: SetTagVisibilityRequestData) =>
			await apiFetch({
				endpoint: END_POINTS.SET_TAGS_VISIBILITY,
				method: 'POST',
				data,
			}),

		changePrivacySetting: async (data: ChangePrivacySettingRequestData) =>
			await apiFetch({
				endpoint: END_POINTS.CHANGE_PRIVACY_SETTING,
				method: 'POST',
				data,
			}),

		getUsers: async (data: any) =>
			await apiFetch({
				endpoint: 'getUsers',
				data,
			}),

		getConversation: async (data: any) =>
			await apiFetch({
				endpoint: 'getConversation',
				data,
			}),

		updateUser: async (data: any) =>
			await apiFetch({
				endpoint: 'updateUser',
				data,
			}),

		getMucId: async () => {
			return await apiFetch({
				prependPath: API_PREPEND_PATH.API_SERVICES,
				endpoint: END_POINTS.GET_MUC_ID,
				method: 'GET',
			});
		},

		createMuc: async (data: CreateMucRequestFormData) => {
			const formData = new FormData();

			Object.keys(data).forEach((key) => {
				formData.append(key, (data as any)[key]);
			});

			return await apiFetch({
				additionalHeaders: {
					'Content-Type': 'multipart/form-data',
					Accept: '*/*',
				},
				prependPath: API_PREPEND_PATH.API_SERVICES,
				endpoint: END_POINTS.CREATE_MUC,
				method: 'POST',
				data: formData,
				isFormData: true,
				timeout: 120 * 1000,
			});
		},

		updateMuc: async (data: UpdateMucRequestFormData) => {
			const formData = new FormData();

			Object.keys(data).forEach((key) => {
				formData.append(key, (data as any)[key]);
			});

			return await apiFetch({
				additionalHeaders: {
					'Content-Type': 'multipart/form-data',
					Accept: '*/*',
				},
				prependPath: API_PREPEND_PATH.API_SERVICES,
				endpoint: END_POINTS.UPDATE_MUC,
				method: 'POST',
				data: formData,
				isFormData: true,
				timeout: 120 * 1000,
			});
		},

		addUsersToMuc: async (data: AddUsersToMucRequestData) => {
			const formData = new FormData();

			Object.keys(data).forEach((key) => {
				formData.append(key, (data as any)[key]);
			});

			return await apiFetch({
				additionalHeaders: {
					'Content-Type': 'multipart/form-data',
					Accept: '*/*',
				},
				prependPath: API_PREPEND_PATH.API_SERVICES,
				endpoint: END_POINTS.ADD_USERS_TO_MUC,
				method: 'POST',
				data: formData,
				isFormData: true,
				timeout: 120 * 1000,
			});
		},

		removeUsersFromMuc: async (data: RemoveUsersFromMucRequestData) => {
			const formData = new FormData();

			Object.keys(data).forEach((key) => {
				formData.append(key, (data as any)[key]);
			});

			return await apiFetch({
				additionalHeaders: {
					'Content-Type': 'multipart/form-data',
					Accept: '*/*',
				},
				prependPath: API_PREPEND_PATH.API_SERVICES,
				endpoint: END_POINTS.REMOVE_USERS_FROM_MUC,
				method: 'POST',
				data: formData,
				isFormData: true,
				timeout: 120 * 1000,
			});
		},

		getMucMembers: async (data: MucRequestData) => {
			return await apiFetch({
				prependPath: API_PREPEND_PATH.API_SERVICES,
				endpoint: END_POINTS.GET_MUC_MEMBERS,
				method: 'GET',
				data,
			});
		},

		getMucInfo: async (data: MucRequestData) => {
			return await apiFetch({
				prependPath: API_PREPEND_PATH.API_SERVICES,
				endpoint: END_POINTS.GET_MUC_INFO,
				method: 'GET',
				data,
			});
		},

		destroyMuc: async (data: MucRequestData) => {
			const formData = new FormData();

			Object.keys(data).forEach((key) => {
				formData.append(key, (data as any)[key]);
			});

			return await apiFetch({
				additionalHeaders: {
					'Content-Type': 'multipart/form-data',
					Accept: '*/*',
				},
				prependPath: API_PREPEND_PATH.API_SERVICES,
				endpoint: END_POINTS.DESTROY_MUC,
				method: 'POST',
				data: formData,
				isFormData: true,
				timeout: 120 * 1000,
			});
		},

		leaveMuc: async (data: MucRequestData) => {
			const formData = new FormData();

			Object.keys(data).forEach((key) => {
				formData.append(key, (data as any)[key]);
			});

			return await apiFetch({
				additionalHeaders: {
					'Content-Type': 'multipart/form-data',
					Accept: '*/*',
				},
				prependPath: API_PREPEND_PATH.API_SERVICES,
				endpoint: END_POINTS.LEAVE_MUC,
				method: 'POST',
				data: formData,
				isFormData: true,
				timeout: 120 * 1000,
			});
		},

		getMucList: async () => {
			return await apiFetch({
				prependPath: API_PREPEND_PATH.API_SERVICES,
				endpoint: END_POINTS.GET_MUC_LIST,
				method: 'GET',
			});
		},

		getThreadId: async (data: GetThreadRequestData) => {
			return await apiFetch({
				prependPath: API_PREPEND_PATH.API_SERVICES,
				endpoint: END_POINTS.GET_THREAD_ID,
				method: 'GET',
				data: data,
			});
		},

		createThread: async (data: CreateThreadRequestData) => {
			const formData = new FormData();

			Object.keys(data).forEach((key) => {
				formData.append(key, (data as any)[key]);
			});

			return await apiFetch({
				additionalHeaders: {
					'Content-Type': 'multipart/form-data',
					Accept: '*/*',
				},
				prependPath: API_PREPEND_PATH.API_SERVICES,
				endpoint: END_POINTS.CREATE_THREAD,
				method: 'POST',
				data: formData,
				isFormData: true,
			});
		},

		updateThread: async (data: UpdateThreadRequestData) => {
			const formData = new FormData();

			Object.keys(data).forEach((key) => {
				formData.append(key, (data as any)[key]);
			});

			return await apiFetch({
				additionalHeaders: {
					'Content-Type': 'multipart/form-data',
					Accept: '*/*',
				},
				prependPath: API_PREPEND_PATH.API_SERVICES,
				endpoint: END_POINTS.UPDATE_THREAD,
				method: 'POST',
				data: formData,
				isFormData: true,
			});
		},

		deleteThread: async (data: DeleteThreadRequestData) => {
			const formData = new FormData();

			Object.keys(data).forEach((key) => {
				formData.append(key, (data as any)[key]);
			});

			return await apiFetch({
				additionalHeaders: {
					'Content-Type': 'multipart/form-data',
					Accept: '*/*',
				},
				prependPath: API_PREPEND_PATH.API_SERVICES,
				endpoint: END_POINTS.DELETE_THREAD,
				method: 'POST',
				data: formData,
				isFormData: true,
			});
		},

		listThreads: async (data: ListThreadsRequestData) => {
			const formData = new FormData();

			Object.keys(data).forEach((key) => {
				formData.append(key, (data as any)[key]);
			});

			return await apiFetch({
				additionalHeaders: {
					'Content-Type': 'multipart/form-data',
					Accept: '*/*',
				},
				prependPath: API_PREPEND_PATH.API_SERVICES,
				endpoint: END_POINTS.LIST_THREAD,
				method: 'POST',
				data: formData,
				isFormData: true,
			});
		},

		translateMessage: async (data: TranslateRequestData) => {
			const formData = new FormData();

			Object.keys(data).forEach((key) => {
				formData.append(key, (data as any)[key]);
			});

			return await apiFetch({
				prependPath: API_PREPEND_PATH.API_SERVICES,
				endpoint: END_POINTS.TRANSLATE,
				method: 'POST',
				data: formData,
				isFormData: true,
			});
		},

		translateLanguagesList: async (data: TranslateLanguagesListRequestData) => {
			return await apiFetch({
				prependPath: API_PREPEND_PATH.API_SERVICES,
				endpoint: END_POINTS.TRANSLATE_LISTLANGUAGES,
				method: 'GET',
				data,
			});
		},

		addContact: async (data: any) =>
			await apiFetch({
				endpoint: 'addContact',
				data,
			}),

		confirmContact: async (data: any) =>
			await apiFetch({
				endpoint: 'confirmContact',
				data,
			}),

		denyContact: async (data: any) =>
			await apiFetch({
				endpoint: 'denyContact',
				data,
			}),

		blockContact: async (data: any) =>
			await apiFetch({
				endpoint: 'denyContact',
				data,
			}),

		updateContact: async (data: any) =>
			await apiFetch({
				endpoint: 'updateContact',
				data,
			}),

		deleteContact: async (data: any) =>
			await apiFetch({
				endpoint: 'deleteContact',
				data,
			}),

		//getGroups: async () => await apiFetch('getGroups'),

		checkGroupname: async (data: any) =>
			await apiFetch({
				endpoint: 'Groupname',
				data,
			}),

		addGroup: async (data: any) =>
			await apiFetch({
				endpoint: 'addGroup',
				data,
			}),

		updateGroup: async (data: any) =>
			await apiFetch({
				endpoint: 'updateGroup',
				data,
			}),

		deleteGroup: async (data: any) =>
			await apiFetch({
				endpoint: 'deleteGroup',
				data,
			}),

		getMessages: async (data: any) =>
			await apiFetch({
				endpoint: 'getMessages',
				data,
			}),

		getSequence: async (data: any) =>
			await apiFetch({
				endpoint: 'getSequence',
				data,
			}),

		updateMessage: async (data: any) =>
			await apiFetch({
				endpoint: 'updateMessage',
				data,
			}),

		recallMessage: async (data: any) =>
			await apiFetch({
				endpoint: 'recallMessage',
				data,
			}),

		uploadMedia: async (data: any) =>
			await apiFetch({
				endpoint: 'uploadMedia',
				data,
				report: true,
				timeout: 60000,
			}),

		cancelUpload: async () => cancelFetch(),

		updateReadStatus: async (data: any) =>
			await apiFetch({
				endpoint: 'updateReadStatus',
				data,
			}),

		fetchLinkPreview: async (data: any) =>
			await apiFetch({
				endpoint: 'fetchLinkPreview',
				data,
			}),

		sendStatus: async (data: any) =>
			await apiFetch({
				endpoint: 'status',
				data,
			}),

		ackControl: async (data: any) =>
			await apiFetch({
				endpoint: 'ackControl',
				data,
			}),
	};

let abortController: any;

export const serverApi = api;
