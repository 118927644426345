import React, { createRef, CSSProperties, forwardRef, useCallback, useEffect, useImperativeHandle, useRef, useState } from 'react';
import './FormContainer.css';
import { QRCodeSVG } from 'qrcode.react';
import { apiService } from '../services/apiService';
import { DefaultProfilePic, info } from '../helpers/common';
import { IonSpinner } from '@ionic/react';
import moment from 'moment';

import * as domToImageMore from 'dom-to-image-more';

const styles: Record<string, CSSProperties> = {
	container: {
		width: '200px',
		height: '200px',
		alignItems: 'center',
		justifyContent: 'center',
		background: '#ffffff',
		borderRadius: '8px',
		display: 'flex',
		// marginBottom: '30px',
		position: 'relative',
	},
	myProfileThumbnail: {
		width: '30px',
		height: '30px',
		position: 'absolute',
	},
};

export interface MyQrCodeMethods {
	downloadMyQrCode: () => void;
}

interface Props {
	myProfilePhoto: string;
	containerStyle?: CSSProperties;
	forwardedRef?: any;
	onClose?: any;
}
const MyQrCode = forwardRef(({ myProfilePhoto, containerStyle, onClose }: Props, ref: React.ForwardedRef<MyQrCodeMethods>) => {
	const mounted = useRef(false);
	const [token, setToken] = useState<string>();

	const containerRef: any = createRef();

	const takeScreenShot = async (node: any) => {
		const dataURI = await domToImageMore.toPng(node);
		return dataURI;
	};

	const download = (image: any, { extension = 'png' } = {}) => {
		const a = document.createElement('a');
		a.href = image;
		a.download = `${moment().unix()}.${extension}`;
		a.click();
	};

	useImperativeHandle(ref, () => ({
		downloadMyQrCode: () => {
			takeScreenShot(containerRef.current).then(download);
		},
	}));

	const getToken = useCallback(() => {
		apiService
			.generateToken()
			.then((res: any) => {
				setToken(res);
			})
			.catch((error) => {
				info('Error', error);
			});
	}, []);

	useEffect(() => {
		if (!mounted.current) {
			getToken();

			mounted.current = true;
		}

		return () => {
			mounted.current = false;
		};
	}, [getToken]);

	const onCloseCallBack = useCallback(
		(event: any) => {
			onClose && onClose();
		},
		[onClose]
	);

	return (
		<>
			<div ref={containerRef} onClick={onCloseCallBack} style={{ ...styles.container, ...containerStyle }}>
				{token ? (
					<>
						<QRCodeSVG
							value={token}
							size={180}
							// imageSettings={{
							// 	src: myProfilePhoto || DefaultProfilePic,
							// 	height: 30,
							// 	width: 30,
							// 	excavate: false,
							// }}
						/>
						<img alt="" style={styles.myProfileThumbnail} src={myProfilePhoto || DefaultProfilePic} />
					</>
				) : (
					<IonSpinner name="crescent" />
				)}
			</div>
			{/* <div style={{ position: 'absolute', bottom: '-30px', fontSize: 12, maxWidth: '300px' }}>{token}</div> */}
		</>
	);
});

export default MyQrCode;
