import { IonPopover, IonLabel, IonList, IonItem, IonIcon } from '@ionic/react';
import React from 'react';
import './FormContainer.css';

interface IconTextButtonProps {
	popoverEvent?: any;
	list?: any;
	showPopover: boolean;
	onDismiss?: any;
	onItemSelect?: any;
	bottomContent?: any;
}
const DropdownPopover: React.FC<IconTextButtonProps> = ({ list, popoverEvent, showPopover, onDismiss, onItemSelect, bottomContent }) => {
	return (
		<IonPopover cssClass="my-dropdown-popover-style" event={popoverEvent} showBackdrop={false} isOpen={showPopover} onDidDismiss={onDismiss}>
			<IonList lines="none">
				{list &&
					list.map((item: any, key: any) => {
						return (
							<IonItem
								key={key}
								detail={false}
								button
								onClick={() => {
									onItemSelect && onItemSelect(item);
								}}
							>
								{(item.svgIcon || item.ionIcon) && (
									<div slot="start" className="icon-container">
										{item.svgIcon && (
											<IonIcon
												style={{
													width: item.width ? item.width : '20px',
													height: item.height ? item.height : '20px',
												}}
												slot="start"
												src={'./assets/icon/' + item.svgIcon}
											/>
										)}
										{item.ionIcon && <IonIcon slot="start" icon={item.ionIcon} />}
									</div>
								)}
								<IonLabel className={item.className && item.className}>{item.title}</IonLabel>
							</IonItem>
						);
					})}
			</IonList>
			{bottomContent && bottomContent}
		</IonPopover>
	);
};

export default DropdownPopover;
