import { Config } from '../../config/config';
import { info } from '../../helpers/common';
import { apiService } from '../../services/apiService';
import { IndexDbCollections } from '../../services/DbController';
import { DbController } from '../../services/DbController';
import { dbService } from '../../services/dbService';
import { AffiliationType } from '../../services/enumService';
import SharedService from '../../services/sharedService';
import { xmpp } from '../../services/xmpp';
import { AddUsersToMucRequestData, ChangeMucMemberAffiliation, CreateMucRequestFormData, MucRequestData, RemoveUsersFromMucRequestData, UpdateMucRequestFormData } from '../../types/api-requests';
import { ApiResponse } from '../../types/api-responses';
import { GroupItem, MemberItem } from '../../types/group';
import { ContactStateTypes, GroupStateTypes } from '../../types/redux-states';

import { GROUP_LIST_UPDATED, MEMBERS_LIST_UPDATED } from '../constants/group';
import { hideLoader, showLoader, showToast } from './global';

export function getGroups() {
	return async function (dispatch: any) {
		return new Promise(async (resolve, reject) => {
			try {
				const mucListResponse: ApiResponse = await apiService.getMucList();
				info('getGroups response');

				if (mucListResponse && !mucListResponse.error) {
					const groups: Array<GroupItem> = mucListResponse && mucListResponse.result ? mucListResponse.result : [];
					for (const item of groups) {
						try {
							const updateRes = await DbController.update(IndexDbCollections.GROUPS.name, item, item.name);
							if (!updateRes) {
								await DbController.put(IndexDbCollections.GROUPS.name, item);
							}
						} catch (error) {}
					}

					dispatch({
						type: GROUP_LIST_UPDATED,
						payload: {
							groups,
						} as ContactStateTypes,
					});
					resolve(groups);
				} else {
					resolve([]);
				}
			} catch (error) {
				reject(error);
			}
		});
	};
}

export function getMucMembers(data: MucRequestData) {
	return async function (dispatch: any) {
		return new Promise(async (resolve, reject) => {
			try {
				const mucmemebersResponse: ApiResponse = await apiService.getMucMembers(data);
				if (mucmemebersResponse && !mucmemebersResponse.error) {
					const members: Array<MemberItem> = mucmemebersResponse && mucmemebersResponse.result ? mucmemebersResponse.result : [];
					dbService.addMembers({ group: data.mucId, list: members });

					// Send IQ
					/*
					<iq 
							to='#MUC_ID#@conference.chat.be-society.com'
							type='get'
							id='E6E10350-76CF-40C6-B91B-1EA08C332FC7'>
						<subscriptions xmlns='urn:xmpp:mucsub:0' />
						</iq>
						*/

					// const resp = await xmpp.client.sendIQ({
					// 	pubsub: {
					// 		context: 'user',
					// 		subscriptions: {},
					// 	},
					// 	id: 'admin' + Math.floor(10000 * Math.random()),
					// 	to: `${data.mucId}@${Config.xmppMUCServer}`,
					// 	type: 'get',
					// });
					dispatch({ type: MEMBERS_LIST_UPDATED, payload: { activeGroupMembers: members } as GroupStateTypes });
					resolve(members);
				} else {
					resolve([]);
				}
			} catch (error) {
				reject(error);
			}
		});
	};
}

export function createGroup(groupData: CreateMucRequestFormData) {
	return async function (dispatch: any) {
		return new Promise(async (resolve, reject) => {
			showLoader({ loaderMessage: 'Group creating...' });

			apiService
				.getMucId()
				.then(async (res: ApiResponse) => {
					if (res.success) {
						const mucId = res.result;
						groupData.mucId = mucId;

						const users: any = [];
						groupData.users.split(',').forEach((username: string) => {
							users.push({
								affiliation: AffiliationType.MEMBER,
								jid: `${username}@${Config.xmppServer}`,
							});
						});

						// Set room member
						const sendIqRes = await xmpp.client?.sendIQ({
							muc: {
								type: 'user-list',
								users,
							},
							id: 'admin' + Math.floor(10000 * Math.random()),
							to: `${mucId}@${Config.xmppMUCServer}`,
							type: 'set',
						});

						if (sendIqRes) {
							apiService
								.createMuc(groupData)
								.then(async (res: ApiResponse) => {
									if (res.success) {
										const groupItem = res.result;
										if (!groupItem.memberAmount) {
											groupItem.memberAmount = groupItem.users.length;
										}

										try {
											const updateRes = await DbController.update(IndexDbCollections.GROUPS.name, groupItem, groupItem.name);
											if (!updateRes) {
												await DbController.put(IndexDbCollections.GROUPS.name, groupItem);
											}
										} catch (error) {}
										const groups = await dbService.groups();
										dispatch({
											type: GROUP_LIST_UPDATED,
											payload: {
												groups,
											} as ContactStateTypes,
										});
										resolve(groupItem);
									} else {
										dispatch(showToast({ toastMessage: res.message, toastType: 'danger' }));
										reject(res.message);
									}
								})
								.catch((error) => {
									reject(SharedService.extractErrorMessage(error));
									dispatch(showToast({ toastMessage: SharedService.extractErrorMessage(error), toastType: 'danger' }));
								})
								.finally(() => {
									hideLoader();
								});
						} else {
							reject('IQ not sent');
							hideLoader();
						}
					}
				})
				.catch((error) => {
					hideLoader();
					reject(SharedService.extractErrorMessage(error));
					dispatch(showToast({ toastMessage: SharedService.extractErrorMessage(error), toastType: 'danger' }));
				});
		});
	};
}

export function updateGroup(groupData: UpdateMucRequestFormData) {
	return async function (dispatch: any) {
		return new Promise(async (resolve, reject) => {
			showLoader({ loaderMessage: 'Updating group...' });

			apiService
				.updateMuc(groupData)
				.then(async (res: ApiResponse) => {
					if (res.success) {
						const updatedDetails = res.result;
						const groupItem = await dbService.getGroup(groupData.mucId);

						Object.keys(updatedDetails).forEach((key) => {
							if (updatedDetails[key]) {
								groupItem[key] = updatedDetails[key];
							}
						});

						try {
							const updateRes = await DbController.update(IndexDbCollections.GROUPS.name, groupItem, groupItem.name);
							if (!updateRes) {
								await DbController.put(IndexDbCollections.GROUPS.name, groupItem);
							}
						} catch (error) {}

						const groups = await dbService.groups();
						dispatch({
							type: GROUP_LIST_UPDATED,
							payload: {
								groups,
							} as ContactStateTypes,
						});
						resolve(groupItem);
					} else {
						dispatch(showToast({ toastMessage: res.message, toastType: 'danger' }));
						reject(res.message);
					}
				})
				.catch((error) => {
					reject(SharedService.extractErrorMessage(error));
					dispatch(showToast({ toastMessage: SharedService.extractErrorMessage(error), toastType: 'danger' }));
				})
				.finally(() => {
					hideLoader();
				});
		});
	};
}

export function addMembersToGroup(data: AddUsersToMucRequestData) {
	return async function (dispatch: any) {
		return new Promise(async (resolve, reject) => {
			showLoader({ loaderMessage: 'Adding members...' });
			const users: any = [];
			data.users.split(',').forEach((username: string) => {
				users.push({
					affiliation: AffiliationType.MEMBER,
					jid: `${username}@${Config.xmppServer}`,
				});
			});

			// Set room member
			const sendIqRes = await xmpp.client?.sendIQ({
				muc: {
					type: 'user-list',
					users,
				},
				id: 'admin' + Math.floor(10000 * Math.random()),
				to: `${data.mucId}@${Config.xmppMUCServer}`,
				type: 'set',
			});
			if (sendIqRes) {
				apiService
					.addUsersToMuc(data)
					.then(async (res: ApiResponse) => {
						if (res.success) {
							const members = await dispatch(
								getMucMembers({
									mucId: data.mucId,
								})
							);
							resolve(members);
						} else {
							dispatch(showToast({ toastMessage: res.message, toastType: 'danger' }));
							reject(res.message);
						}
					})
					.catch((error) => {
						reject(SharedService.extractErrorMessage(error));
						dispatch(showToast({ toastMessage: SharedService.extractErrorMessage(error), toastType: 'danger' }));
					})
					.finally(() => {
						hideLoader();
					});
			} else {
				reject('IQ not sent');
				hideLoader();
			}
		});
	};
}

export function removeMembersFromGroup(data: RemoveUsersFromMucRequestData) {
	return async function (dispatch: any) {
		return new Promise(async (resolve, reject) => {
			showLoader({ loaderMessage: 'Removing...' });

			apiService
				.removeUsersFromMuc(data)
				.then(async (res: ApiResponse) => {
					if (res.success) {
						const users: any = [];
						data.users.split(',').forEach((username: string) => {
							users.push({
								affiliation: AffiliationType.NONE,
								jid: `${username}@${Config.xmppServer}`,
							});
						});

						// Send IQ After remove users
						await xmpp.client?.sendIQ({
							muc: {
								type: 'user-list',
								users,
							},
							id: 'admin' + Math.floor(10000 * Math.random()),
							to: `${data.mucId}@${Config.xmppMUCServer}`,
							type: 'set',
						});

						const members = await dispatch(
							getMucMembers({
								mucId: data.mucId,
							})
						);
						resolve(members);
					} else {
						dispatch(showToast({ toastMessage: res.message, toastType: 'danger' }));
						reject(res.message);
					}
					hideLoader();
				})
				.catch((error) => {
					hideLoader();
					reject(SharedService.extractErrorMessage(error));
					dispatch(showToast({ toastMessage: SharedService.extractErrorMessage(error), toastType: 'danger' }));
				});
		});
	};
}

export function ownerChange(data: ChangeMucMemberAffiliation) {
	return async function (dispatch: any) {
		return new Promise(async (resolve, reject) => {
			showLoader({ loaderMessage: 'Adding members...' });
			const users: any = [];
			data.users.split(',').forEach((username: string) => {
				users.push({
					affiliation: AffiliationType.MEMBER,
					jid: `${username}@${Config.xmppServer}`,
				});
			});

			// Set room member
			const sendIqRes = await xmpp.client?.sendIQ({
				muc: {
					type: 'user-list',
					users,
				},
				id: 'admin' + Math.floor(10000 * Math.random()),
				to: `${data.mucId}@${Config.xmppMUCServer}`,
				type: 'set',
			});

			if (sendIqRes) {
				apiService
					.addUsersToMuc(data)
					.then(async (res: ApiResponse) => {
						if (res.success) {
							const members = await dispatch(
								getMucMembers({
									mucId: data.mucId,
								})
							);
							resolve(members);
						} else {
							dispatch(showToast({ toastMessage: res.message, toastType: 'danger' }));
							reject(res.message);
						}
					})
					.catch((error) => {
						reject(SharedService.extractErrorMessage(error));
						dispatch(showToast({ toastMessage: SharedService.extractErrorMessage(error), toastType: 'danger' }));
					})
					.finally(() => {
						hideLoader();
					});
			} else {
				reject('IQ not sent');
				hideLoader();
			}
		});
	};
}

export function destroyMuc(data: MucRequestData) {
	return async function (dispatch: any) {
		return new Promise(async (resolve, reject) => {
			showLoader({ loaderMessage: 'Destroying group...' });

			apiService
				.destroyMuc(data)
				.then(async (res: ApiResponse) => {
					if (res.success) {
						await dispatch(getGroups());
						resolve(true);
					} else {
						dispatch(showToast({ toastMessage: res.message, toastType: 'danger' }));
						reject(res.message);
					}
				})
				.catch((error) => {
					reject(SharedService.extractErrorMessage(error));
					dispatch(showToast({ toastMessage: SharedService.extractErrorMessage(error), toastType: 'danger' }));
				})
				.finally(() => {
					hideLoader();
				});
		});
	};
}

export function leaveMuc(data: MucRequestData) {
	return async function (dispatch: any) {
		return new Promise(async (resolve, reject) => {
			showLoader({ loaderMessage: 'Leaving group...' });

			apiService
				.leaveMuc(data)
				.then(async (res: ApiResponse) => {
					if (res.success) {
						await dispatch(getGroups());
						resolve(true);
					} else {
						dispatch(showToast({ toastMessage: res.message, toastType: 'danger' }));
						reject(res.message);
					}
				})
				.catch((error) => {
					reject(SharedService.extractErrorMessage(error));
					dispatch(showToast({ toastMessage: SharedService.extractErrorMessage(error), toastType: 'danger' }));
				})
				.finally(() => {
					hideLoader();
				});
		});
	};
}
