import { IonPopover, IonLabel, IonList, IonItem } from '@ionic/react';
import React from 'react';
import './ScketchpadTextStylePopover.scss';

interface IconTextButtonProps {
	popoverEvent?: any;
	list?: any;
	showPopover: boolean;
	selectedValue: any;
	onDismiss?: any;
	onItemSelect?: any;
} 
const ScketchpadTextStylePopover: React.FC<IconTextButtonProps> = ({ list, popoverEvent, showPopover, selectedValue, onDismiss, onItemSelect }) => {
	return (
		<IonPopover cssClass="scketchpad-textStyle-popover-style" event={popoverEvent} showBackdrop={false} isOpen={showPopover} onDidDismiss={onDismiss}>
			<IonList lines="none">
				{list &&
					list.map((item: any, key: any) => {
						return (
							<IonItem
								key={key}
								detail={false}
								button
								className={selectedValue && selectedValue.value === item.value ? 'active' : ''}
								onClick={() => {
									onItemSelect && onItemSelect(item);
								}}
							>
								<IonLabel style={item.style}>{item.title}</IonLabel>
							</IonItem>
						);
					})}
			</IonList>
		</IonPopover>
	);
};

export default ScketchpadTextStylePopover;
