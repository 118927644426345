import { Config } from '../../config/config';
import { apiService } from '../../services/apiService';
import { dbService } from '../../services/dbService';
import { AffiliationType, ThreadType } from '../../services/enumService';
import SharedService, { sharedService } from '../../services/sharedService';
import { xmpp } from '../../services/xmpp';
import { CreateThreadRequestData, DeleteThreadRequestData, GetThreadRequestData, UpdateThreadRequestData } from '../../types/api-requests';
import { ApiResponse } from '../../types/api-responses';
import { ThreadItem } from '../../types/thread';
import { THREAD_UPDATED } from '../constants/thread';

import { hideLoader, showLoader, showToast } from './global';

export function createThread(data: CreateThreadRequestData) {
	return async function (dispatch: any) {
		return new Promise(async (resolve, reject) => {
			showLoader({ loaderMessage: 'Creating Thread...' });
			apiService
				.getThreadId({
					userOrMucId: data.userOrMucId,
					type: data.type,
				})
				.then(async (res: ApiResponse) => {
					if (res.success) {
						let threads: Array<ThreadItem> = await dbService.getThreads(data.userOrMucId);
						if (!threads) {
							threads = [];
						}

						data.threadId = res.result;
						const toJID = `${data.userOrMucId}@${data.type === ThreadType.P2P ? Config.xmppServer : Config.xmppMUCServer}`;

						// Create default chat thread for first time
						if (data.threadId === sharedService.defaultThreadId) {
							const defaultThreadName = 'Chat';
							await apiService.createThread({
								...data,
								threadName: defaultThreadName,
							});
							threads.push({
								threadId: data.threadId,
								threadName: defaultThreadName,
							});

							// get new thread id for thread
							const apiResponse: ApiResponse = await apiService.getThreadId({
								userOrMucId: data.userOrMucId,
								type: data.type,
							});
							if (apiResponse.success) {
								data.threadId = apiResponse.result;
							}
						}
						await xmpp.client?.sendIQ({
							muc: {
								type: 'user-list',
								users: [
									{
										affiliation: AffiliationType.MEMBER,
										jid: toJID,
									},
								],
							},
							id: 'admin' + Math.floor(10000 * Math.random()),
							to: `${data.threadId}@${Config.xmppMUCServer}`,
							type: 'set',
						});

						apiService
							.createThread(data)
							.then(async (createRes: ApiResponse) => {
								if (createRes.success) {
									threads.push({
										threadId: data.threadId!,
										threadName: data.threadName,
									});

									await dbService.addThread({
										userOrMucId: data.userOrMucId,
										list: threads,
									});
									dispatch({ type: THREAD_UPDATED, payload: { threads } });
									resolve(threads);
								}
							})
							.catch((error) => {
								dispatch(showToast({ toastMessage: SharedService.extractErrorMessage(error), toastType: 'danger' }));
							})
							.finally(() => {});
					} else {
						dispatch(showToast({ toastMessage: res.message, toastType: 'danger' }));
						reject(res.message);
					}
				})
				.catch((error) => {
					reject(SharedService.extractErrorMessage(error));
					dispatch(showToast({ toastMessage: SharedService.extractErrorMessage(error), toastType: 'danger' }));
				})
				.finally(() => {
					hideLoader();
				});
		});
	};
}

export function listThreads(data: GetThreadRequestData) {
	return async function (dispatch: any) {
		return new Promise(async (resolve, reject) => {
			apiService
				.listThreads(data)
				.then(async (res: ApiResponse) => {
					if (res.success) {
						const threads = res.result;

						await dbService.addThread({
							userOrMucId: data.userOrMucId,
							list: threads,
						});
						dispatch({ type: THREAD_UPDATED, payload: { threads } });

						resolve(threads);
					} else {
						reject(res.message);
					}
				})
				.catch((error) => {
					reject(SharedService.extractErrorMessage(error));
				})
				.finally(() => {});
		});
	};
}

export function updateThread(data: UpdateThreadRequestData) {
	return async function (dispatch: any) {
		return new Promise(async (resolve, reject) => {
			showLoader({ loaderMessage: 'Updating Thread...' });

			apiService
				.updateThread(data)
				.then(async (createRes: ApiResponse) => {
					if (createRes.success) {
						let threads: Array<ThreadItem> = await dbService.getThreads(data.userOrMucId);
						if (!threads) {
							threads = [];
						}
						for (var i = 0; i < threads.length; i++) {
							if (data.threadId === threads[i].threadId) {
								threads[i].threadName = data.threadName;
								break;
							}
						}

						await dbService.addThread({
							userOrMucId: data.userOrMucId,
							list: threads,
						});
						dispatch({ type: THREAD_UPDATED, payload: { threads } });

						resolve(threads);
					} else {
						reject(createRes.message);
					}
				})
				.catch((error) => {
					dispatch(showToast({ toastMessage: SharedService.extractErrorMessage(error), toastType: 'danger' }));
				})
				.finally(() => {
					hideLoader();
				});
		});
	};
}

export function deleteThread(data: DeleteThreadRequestData) {
	return async function (dispatch: any) {
		return new Promise(async (resolve, reject) => {
			showLoader({ loaderMessage: 'Deleting Thread...' });
			apiService
				.deleteThread(data)
				.then(async (response: ApiResponse) => {
					if (response.success) {
						let threads: Array<ThreadItem> = await dbService.getThreads(data.mucId);
						if (!threads) {
							threads = [];
						}
						for (var i = 0; i < threads.length; i++) {
							if (data.mucId === threads[i].threadId) {
								var removeIndex = threads
									.map(function (item) {
										return item.threadId;
									})
									.indexOf(threads[i].threadId);
								threads.splice(removeIndex, 1);
								break;
							}
						}

						await dbService.addThread({
							userOrMucId: data.mucId,
							list: threads,
						});
						dispatch({ type: THREAD_UPDATED, payload: { threads } });

						resolve(threads);
					} else {
						reject(response.message);
					}
				})
				.catch((error) => {
					dispatch(showToast({ toastMessage: SharedService.extractErrorMessage(error), toastType: 'danger' }));
				})
				.finally(() => {
					hideLoader();
				});
		});
	};
}
