import { GlobalStateTypes } from '../../types/redux-states';
import { SHOW_ALERT, SHOW_LOADER, SHOW_STYLISH_TOAST, SHOW_TOAST } from '../constants/common';
import store from '../store';

export function showToast(payload: GlobalStateTypes) {
	return {
		type: SHOW_TOAST,
		payload: { ...payload, showToast: true } as GlobalStateTypes,
	};
}
export function hideToast() {
	return {
		type: SHOW_TOAST,
		payload: {
			showToast: false,
			toastMessage: '',
			toastType: undefined,
			toastCallBack: null,
		} as GlobalStateTypes,
	};
}

export function showStylishToast(payload: GlobalStateTypes) {
	return {
		type: SHOW_STYLISH_TOAST,
		payload: { ...payload, showStylishToast: true } as GlobalStateTypes,
	};
}
export function hideStylishToast() {
	return {
		type: SHOW_STYLISH_TOAST,
		payload: {
			showStylishToast: false,
			stylishToastMessage: '',
			stylishToastIcon: undefined,
		} as GlobalStateTypes,
	};
}

export function showLoader(payload: GlobalStateTypes) {
	return store.dispatch({
		type: SHOW_LOADER,
		payload: { ...payload, isLoading: true } as GlobalStateTypes,
	});
}
export function hideLoader() {
	return store.dispatch({
		type: SHOW_LOADER,
		payload: { isLoading: false, loaderMessage: '' } as GlobalStateTypes,
	});
}

export function showAlert(payload: GlobalStateTypes) {
	return {
		type: SHOW_ALERT,
		payload: { ...payload, showAlert: true } as GlobalStateTypes,
	};
}

export function hideAlert() {
	return {
		type: SHOW_ALERT,
		payload: {
			showAlert: false,
			alertTitle: '',
			alertMessage: '',
		} as GlobalStateTypes,
	};
}
