import { IonButton, IonInput, IonModal } from '@ionic/react';
import React, { createRef, useCallback, useEffect, useState } from 'react';
import './style.scss';
import { connect } from 'react-redux';

interface AddNewthreadProps {
	show: boolean;
	onCloseCallBack: any;
	threadName: any;
	onCreatThread?: (threadAddName: string) => void;
}

const AddNewthread: React.FC<AddNewthreadProps> = ({ show, onCloseCallBack, threadName, onCreatThread }) => {
	const threadInputRef: React.RefObject<HTMLIonInputElement> = createRef();
	const [threadAddName, setthreadAddName] = useState<string>(threadName);

	useEffect(() => {
		setthreadAddName(threadName);
	}, [threadName]);

	useEffect(() => {
		if (threadInputRef.current && show) {
			setTimeout(() => {
				threadInputRef.current?.setFocus();
			}, 500);
		}
	}, [threadInputRef.current, show]);

	return (
		<>
			<IonModal showBackdrop={true} isOpen={show} onDidDismiss={onCloseCallBack} swipeToClose={true} cssClass="add-new-tag-popup" animated={true}>
				<div className="container">
					<div className="content-view">
						{threadName ? (
							<div className="title">Rename Thread</div>
						) : (
							<>
								<div className="title">New Thread</div>
								<div className="msg-tag">Seperate discussions within a chat.</div>
							</>
						)}

						<div className="tag-input-container">
							<div className="input-view">
								<IonInput maxlength={16} ref={threadInputRef} id="threadNameInput" placeholder="Type thread name" className="tag-name-input" value={threadAddName.trimStart()} onIonChange={(event) => setthreadAddName(event.detail.value!)} />
							</div>
						</div>
						<div className="btns-view">
							<IonButton
								fill="clear"
								className="delete-btn"
								onClick={() => {
									setthreadAddName('');
									onCloseCallBack();
								}}
							>
								Cancel
							</IonButton>
							{threadName ? (
								<IonButton
									fill="clear"
									className="create-btn"
									onClick={() => {
										onCreatThread && onCreatThread(threadAddName);
										setthreadAddName('');
									}}
									disabled={threadAddName === threadName || threadAddName.length === 0}
								>
									Rename
								</IonButton>
							) : (
								<IonButton
									fill="clear"
									className="create-btn"
									onClick={() => {
										onCreatThread && onCreatThread(threadAddName);
										setthreadAddName('');
									}}
									disabled={!threadAddName}
								>
									Create
								</IonButton>
							)}
						</div>
					</div>
				</div>
			</IonModal>
		</>
	);
};

const mapStateToProps = (state: any) => {
	return {};
};
const mapDispatchToProps = (dispatch: any) => ({});

export default connect(mapStateToProps, mapDispatchToProps)(AddNewthread);
