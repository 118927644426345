import { IonModal } from '@ionic/react';
import React from 'react';
import CreateFolderpopup from './CreateFolderpopup';
import './PersonalStorageModal.scss';

interface PersonalStorageprops {
	show: boolean;
	onCloseCallBack: any;
}
interface iState {
	isShowPopupView: boolean;
}

class PersonalStorageModal extends React.Component<PersonalStorageprops, iState> {
	constructor(props: any) {
		super(props);

		this.state = {
			isShowPopupView: false,
		};
	}

	render() {
		return (
			<>
				<IonModal showBackdrop={true} isOpen={this.props.show} onDidDismiss={this.props.onCloseCallBack} swipeToClose={true} cssClass="personal-storage-modal" animated={true}>
					<div
						className="ion-page"
						onClick={() => {
							this.props.onCloseCallBack && this.props.onCloseCallBack();
						}}
					>
						<div
							className="content"
							onClick={(event) => {
								event.stopPropagation();
							}}
						>
							<div className="select-main">
								<div id="mobileview">
									<div className="storage">Add To Storage</div>

									<div className="sub-modal">
										<div className="modal-footer">
											<div
												className="modal-icons"
												onClick={() => {
													this.props.onCloseCallBack();
													this.setState({ isShowPopupView: true });
												}}
											>
												<img alt="" src="../assets/icon/folder-add.svg"></img>
												<div className="folder">folder</div>
											</div>
											<div className="modal-icons">
												<img alt="" src="../assets/icon/file-upload.svg"></img>
												<div className="folder">file</div>
											</div>
											<div className="modal-icons">
												<img alt="" src="../assets/icon/album.svg"></img>
												<div className="folder">Album</div>
											</div>
										</div>
										<div className="cancel" onClick={this.props.onCloseCallBack}>
											Cancel
										</div>
									</div>
								</div>

								{/* for desktop */}
								<div id="desktopview" className="content">
									<div className="storage">Add To Storage</div>

									<div className="sub-modal">
										<div className="modal-footer">
											<div
												className="modal-icons"
												onClick={() => {
													this.props.onCloseCallBack();
													this.setState({ isShowPopupView: true });
												}}
											>
												<img alt="" src="../assets/icon/folder-add.svg"></img>
												<div className="folder">folder</div>
											</div>
											<div className="modal-icons">
												<img alt="" src="../assets/icon/file-upload.svg"></img>
												<div className="folder">file-upload</div>
											</div>
											<div className="modal-icons">
												<img alt="" src="../assets/icon/folder-upload.svg"></img>
												<div className="folder">folder-upload</div>
											</div>
										</div>
										<div className="cancel" onClick={this.props.onCloseCallBack}>
											Cancel
										</div>
									</div>
								</div>
							</div>
						</div>{' '}
					</div>
				</IonModal>

				<CreateFolderpopup
					show={this.state.isShowPopupView}
					onCloseCallBack={() => {
						this.setState({ isShowPopupView: false });
					}}
				/>
			</>
		);
	}
}

export default PersonalStorageModal;
