import React from 'react';
import { IonButton, IonButtons, IonContent, IonGrid, IonIcon, IonLabel, IonPage, IonRow, IonTitle, IonToolbar } from '@ionic/react';
import './style.css';
import { RouteComponentProps } from 'react-router';
import BottomNavbar from '../common/navbar/bottomNavbar';
import { chevronBackOutline } from 'ionicons/icons';
import { locale } from '../../locales/local';

interface iProps extends RouteComponentProps<{ name: string }> {}
interface iState {}

export default class CameraPage extends React.Component<iProps, iState> {
	render() {
		const dashboardStyle = {
			dbGrid: {
				height: '100%',
			},
			dbRow: {
				display: 'flex',
				alignItems: 'center',
				justifyContent: 'center',
				height: '100%',
			},
		};
		return (
			<IonPage>
				<IonToolbar>
					<IonTitle className="ct-toolbar-title">{locale.camera.title}</IonTitle>
					<IonButtons slot="start">
						<IonButton onClick={(e) => this.props.history.goBack()}>
							<IonIcon className="ct-icon-back" slot="icon-only" icon={chevronBackOutline} />
						</IonButton>
					</IonButtons>
				</IonToolbar>
				<IonContent className="has-bottom-navbar">
					<IonGrid style={dashboardStyle.dbGrid}>
						<IonRow style={dashboardStyle.dbRow}>
							<IonLabel color="light">
								<h1>{locale.camera.camera_page}</h1>
							</IonLabel>
						</IonRow>
					</IonGrid>
				</IonContent>
				{/* <BottomNavbar {...this.props} unreadCount={0} /> */}
			</IonPage>
		);
	}
}
