import { IonModal } from '@ionic/react';
import React, { useCallback } from 'react';
import './style.scss';
import { connect } from 'react-redux';
import { locale } from '../locales/local';
import moment from 'moment';

interface RecallPopupModalProps {
	show: boolean;
	onCancel?: any;
	onRecall?: any;
	remainingTimeInSeconds?: any;
}

const RecallPopupModal: React.FC<RecallPopupModalProps> = ({ show, remainingTimeInSeconds, onCancel, onRecall }) => {
	const onCloseCallBack = useCallback(() => {
		onCancel && onCancel();
	}, [onCancel]);

	const onRecallCallBack = useCallback(() => {
		onRecall && onRecall();
	}, [onRecall]);

	return (
		<IonModal showBackdrop={true} isOpen={show} onDidDismiss={onCloseCallBack} swipeToClose={true} cssClass="recall-popup-modal" animated={true}>
			<div className="recall-timer">
				<img alt="icon" src="./assets/icon/5minclock.svg" />
				<div className="time">{moment.utc(remainingTimeInSeconds * 1000).format('mm:ss')}</div>
			</div>
			<div className="content-view">
				<div className="title">{locale.dashboard.thread.confirm_recall}</div>
				<div className="action-btn" onClick={onRecallCallBack}>
					Recall
				</div>
				<div className="cancel-btn" onClick={onCloseCallBack}>
					Cancel
				</div>
			</div>
		</IonModal>
	);
};

const mapStateToProps = (state: any) => {
	return {
		loggedInUser: state.global.loggedInUser,
		isLoggedIn: state.auth.isLoggedIn,
		searchContact: state.searchContact,
	};
};
const mapDispatchToProps = (dispatch: any) => ({});

export default connect(mapStateToProps, mapDispatchToProps)(RecallPopupModal);
