import { IonLabel } from '@ionic/react';
import React from 'react';
import './FormContainer.css';

interface IconTextButtonProps {
	selected?: boolean;
	icon: string;
	selectedIcon?: string;
	title: string;
	onClick?: any;
	containerStyle?: any;
	iconStyle?: any;
	labelStyle?: any;
}
const IconTextButton: React.FC<IconTextButtonProps> = ({ icon, selectedIcon, title, selected, onClick, iconStyle, labelStyle, containerStyle }) => {
	return (
		<div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', margin: '10px 16px', ...containerStyle }} onClick={onClick}>
			<img alt="icon" src={'assets/icon/' + (selected ? selectedIcon : icon)} style={{ width: 30, height: 30, ...iconStyle }} />
			<IonLabel style={{ marginTop: 8, fontSize: '11px', color: selected ? '#FF5E5E' : '#000000', ...labelStyle }}>{title}</IonLabel>
		</div>
	);
};

export default IconTextButton;
