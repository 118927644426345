import { IonLabel, IonText } from '@ionic/react';
import React, { createRef } from 'react';
import './style.scss';
import MyQrCode, { MyQrCodeMethods } from '../../components/MyQRCode';
import { MyProfile, ProfilePhotoItem } from '../../types/profile';
import { DefaultProfilePic } from '../../helpers/common';
import store from '../../redux/store';
import { showStylishToast } from '../../redux/actions/global';

interface QrCodeComponentProps {
	loggedInUser?: MyProfile;
	onClose?: any;
	onQrComponent?: any;
	handleScanQrCode?: () => void;
	showScanButton?: boolean;
}

const QrCodeComponent: React.FC<QrCodeComponentProps> = ({ showScanButton, handleScanQrCode, loggedInUser, onClose, onQrComponent }) => {
	const profileUserName = loggedInUser?.username || loggedInUser?.userId || '';
	const { profilePhotos } = loggedInUser || {};
	let activeProfilePhoto: ProfilePhotoItem | null = null;
	if (profilePhotos && profilePhotos.length > 0) {
		activeProfilePhoto = profilePhotos[0] && !profilePhotos[0].hide ? profilePhotos[0] : null;
	}

	const myQrCodeRef = createRef<MyQrCodeMethods>();

	return (
		<>
			<div className="save-scan-container">
				{showScanButton && (
					<div
						className="thumbnail"
						onClick={(event) => {
							event.stopPropagation();
							handleScanQrCode && handleScanQrCode();
						}}
					>
						<img alt="person" src={'./assets/img/scan.png'} />
					</div>
				)}

				<div className="fill-space" />
				<div
					className="thumbnail-first"
					onClick={(event) => {
						event.stopPropagation();
						myQrCodeRef.current?.downloadMyQrCode();
						store.dispatch(showStylishToast({ stylishToastMessage: 'Saved', stylishToastIcon: 'check' }));
					}}
				>
					<img alt="person" src={'./assets/img/save.svg'} />
				</div>
			</div>

			<div
				className="qrcode-component"
				onClick={(event) => {
					event.stopPropagation();
					onClose && onClose();
					onQrComponent && onQrComponent();
				}}
			>
				<IonLabel>{loggedInUser?.profileName || ''}</IonLabel>
				<IonText style={{ marginBottom: 4 }}>{(!profileUserName.includes('@ ') ? '@' : '') + '' + profileUserName}</IonText>
				<div className="qrimage">
					{/* <div className="userdiv">{activeProfilePhoto && <IonImg className="userimage" src={activeProfilePhoto} />}</div>
				<IonImg className="qrcode" onClick={(event) => event.stopPropagation()} src={loggedInUser?.qrCode || './assets/icon/qrcodeimg.svg'} /> */}
					<MyQrCode ref={myQrCodeRef} myProfilePhoto={activeProfilePhoto?.url || DefaultProfilePic} />
				</div>
			</div>

			{showScanButton && <div className="fill-space" />}
		</>
	);
};
export default QrCodeComponent;
