export const CHAT_INIT = 'CHAT_INIT',
	CHAT_RELOAD = 'CHAT_RELOAD',
	CHAT_CLEAR = 'CHAT_CLEAR',
	CHAT_RESET = 'CHAT_RESET',
	CHAT_PUSH_SELF_MSG_TO_HISTORY = 'CHAT_PUSH_SELF_MSG_TO_HISTORY',
	CHAT_PUSH_TO_HISTORY = 'CHAT_PUSH_TO_HISTORY',
	CHAT_UPDATE_HISTORY = 'CHAT_UPDATE_HISTORY',
	CHAT_REMOVE_FROM_HISTORY = 'CHAT_REMOVE_FROM_HISTORY',
	CHAT_FETCHING_HISTORY = 'CHAT_FETCHING_HISTORY',
	CHAT_LOADING = 'CHAT_LOADING',
	CHAT_HISTORY_LOADED = 'CHAT_HISTORY_LOADED',
	CHAT_HISTORY_LOADED_ERROR = 'CHAT_HISTORY_LOADED_ERROR',
	CHAT_ACCUMULATED_HISTORY = 'CHAT_ACCUMULATED_HISTORY',
	CHAT_UPDATE_CONVERSATION = 'CHAT_UPDATE_CONVERSATION',
	UPDATE_CHAT_HISTORY = 'UPDATE_CHAT_HISTORY',
	UPDATE_LAST_MESSAGES = 'UPDATE_LAST_MESSAGES',
	FETCHING_LAST_MESSAGES = 'FETCHING_LAST_MESSAGES',
	FETCHED_LAST_MESSAGES = 'FETCHED_LAST_MESSAGES';
