import React, { useCallback } from 'react';
import { IonActionSheet, IonButton, IonIcon, IonInput, IonItem, IonModal, IonText, IonTextarea, IonDatetime, IonLabel } from '@ionic/react';
import './style.scss';
import { locale } from '../locales/local';
import { cameraService } from '../services/cameraService';
import ImageEditor, { OpenEditorType } from './ImageEditor/ImageEditor';
import store from '../redux/store';
import { SHOW_TOAST } from '../redux/constants/common';
import { Navigation } from '../services/navigationService';
import { AddMembersPageActionType } from '../services/enumService';
const SettingProject = './../../../assets/icon/settingproject.svg';
const GroupProject = './../../../assets/icon/group-create.svg';
const CelenderProject = './../../../assets/icon/celender.svg';
const ReminderProject = './../../../assets/icon/reminder-icon.svg';
const AlbumProject = './../../../assets/icon/album-icon.svg';
const CreateNote = './../../../assets/icon/createNode.svg';
const ThreeCicle = './../../../assets/icon/threeCircle.svg';
const Right_pro = './../../../assets/icon/right_pro.svg';
const AddDocument = './../../../assets/icon/addDocument.svg';
const Location = './../../../assets/icon/location.svg';
const AddTask = './../../../assets/icon/addTask.svg';
const PDF = './../../../assets/img/PDF.svg';
const EXCEL = './../../../assets/img/EXCEL.svg';
const Picp = './../../../assets/img/picp.svg';
const Plusp = './../../../assets/img/plus.svg';
const closeIcon = './assets/icon/Close.svg';

interface NewProjectProps {
	show: boolean;
	props: any;
	onCancel?: any;
	onCreatProject: () => void;
}

const NewProject: React.FC<NewProjectProps> = ({ show, onCancel, props, onCreatProject }) => {
	const [projectName, setProjectName] = React.useState('');
	const [createTask, setCreateTask] = React.useState('');
	const [projectNote, setProjectNote] = React.useState('');
	const [selectedImage, setSelectedImage] = React.useState<any>(null);
	const [editedImage, setEditedImage] = React.useState<any>(null);
	const [showEditor, setShowEditor] = React.useState(false);
	const [deadline, setDeadline] = React.useState(false);
	const [meeting, setMeeting] = React.useState(false);
	const [showPhotoOptionsActionSheet, setShowPhotoOptionsActionSheet] = React.useState(false);

	const onCloseCallBack = useCallback(() => {
		onCancel && onCancel();
	}, [onCancel]);

	const _getActionsheetOptions = () => {
		const takePhoto = {
			text: locale.profile.upload_photo.take_photo,
			handler: () => {
				cameraService.capturePhotoFromCamera((photo: any) => {
					setSelectedImage(photo);
					setShowEditor(true);
				}, true);
			},
		};

		const chooseFromAlbum = {
			text: locale.profile.upload_photo.choose_album,
			handler: () => {
				cameraService.choosePhotoFromAlbum((photo: Array<File>) => {
					setSelectedImage(photo[0]);
					setShowEditor(true);
				});
			},
		};

		const cancelPhoto = {
			class: 'cancel',
			text: locale.global.cancel,
			role: 'cancel',
			handler: () => {
				setShowPhotoOptionsActionSheet(false);
			},
		};

		return [takePhoto, chooseFromAlbum, cancelPhoto];
	};

	const onPinturaEditingDone = (file: any) => {
		// encode the file using the FileReader API
		const reader = new FileReader();
		reader.onloadend = async () => (
			setShowEditor(false),
			setEditedImage(reader.result),
			() => {
				uploadPhoto(file);
			}
		);
		reader.readAsDataURL(file);
	};
	const uploadPhoto = async (file: File) => {
		if (file) {
			// this.props.updateChatOnlyProfilePhoto(file).finally(() => {
			// 	Navigation.back();
			// });
		} else {
			store.dispatch({
				type: SHOW_TOAST,
				payload: {
					showToast: true,
					toastMessage: locale.profile.upload_photo.select_image,
				},
			});
		}
	};
	// info("createProject", createProject);

	return (
		<div>
			<IonModal mode="ios" showBackdrop={false} isOpen={show} onDidDismiss={onCloseCallBack} swipeToClose={false} cssClass="new-project-modal">
				<div
					className="new-project-create-component"
					onClick={(event) => {
						event.stopPropagation();
					}}
				>
					<div className="header-row">
						<div className="header-text">New Project</div>
						{/* <div className="header-text">PROJECT TITLE</div> */}
					</div>
					<div className="card">
						<div className="field-item">
							<IonButton fill="clear">
								<IonIcon icon={SettingProject} slot="icon-only"></IonIcon>
							</IonButton>
							<IonItem>
								<IonInput maxlength={18} className="title" value={projectName} onIonChange={(e) => setProjectName(e.detail.value!)} placeholder="Title" />
							</IonItem>
						</div>
						{/* <div className="field-item">
							<IonButton fill="clear">
								<IonIcon icon={AddTask} slot="icon-only"></IonIcon>
							</IonButton>
							<IonItem>
								<IonInput maxlength={78} className="title" value={createTask} onIonChange={(e) => setCreateTask(e.detail.value!)} placeholder="Task Title" />
							</IonItem>
						</div> */}
						<div className="number115">
							<IonText className="numberlast">{projectName.trim().length}</IonText>
						</div>
						<div className="field-item">
							<IonButton slot="start" fill="clear">
								<IonIcon icon={GroupProject} slot="icon-only"></IonIcon>
							</IonButton>
							<IonItem
								onClick={() => {
									Navigation.openAddMembers({
										actionType: AddMembersPageActionType.ChooseContactForNewProject,
									});
								}}
							>
								<IonInput placeholder="Assign Contact or Group" className="assignContact">
									{/* <IonThumbnail className="default-image-container">
										<IonImg title="Change" src={DefaultProfilePic} className="profile_photo" />
									</IonThumbnail>
									<div className="contact-profession-container">
										<div className="contact-name">Vijay</div>
										<div className="profession-name">Artist</div>
									</div> */}
								</IonInput>
							</IonItem>
						</div>
						<div className="field-item">
							<IonButton fill="clear">
								<IonIcon className="createNote" icon={CreateNote} slot="icon-only"></IonIcon>
							</IonButton>
							<IonItem>
								<IonTextarea className="notes" autoGrow placeholder="Notes" value={projectNote} maxlength={115} onIonChange={(e) => setProjectNote(e.detail.value!)} />
							</IonItem>
						</div>
						<div className="number115-note">
							<IonText className="numberlast">{projectNote.trim().length}</IonText>
						</div>
						<div className="field-item">
							<IonButton fill="clear" className="tack-pic">
								<IonIcon icon={AlbumProject} slot="icon-only"></IonIcon>
							</IonButton>
							<IonItem
								onClick={() => {
									setShowPhotoOptionsActionSheet(true);
								}}
							>
								<IonInput placeholder="Assign Project Image" className="assignContact"></IonInput>
							</IonItem>
							{/* <IonItem>
								<div className="picp-div">
									<IonIcon icon={Picp} slot="icon-only" className="picp-img"></IonIcon>
								</div>
								<div className="plusp-div">
									<IonIcon icon={Plusp} slot="icon-only" className="plusp-img"></IonIcon>
								</div>
							</IonItem> */}
						</div>
						<div className="field-item">
							<IonButton fill="clear">
								<IonIcon icon={CelenderProject} slot="icon-only"></IonIcon>
							</IonButton>
							<IonItem>
								<div className="button_deadline">
									<IonButton
										onClick={() => {
											setDeadline(!deadline);
										}}
										className={deadline ? 'active' : 'deadline'}
									>
										Deadline
									</IonButton>
									<IonButton
										onClick={() => {
											setMeeting(!meeting);
										}}
										className={meeting ? 'active' : 'deadline'}
									>
										Meeting
									</IonButton>
								</div>
								<IonDatetime displayFormat="MM/DD/YYYY" className="date-container" placeholder="  0/00/0000"></IonDatetime>
							</IonItem>
						</div>
						{/* <div className="field-item"> */}
						{/* <IonButton fill="clear">
								<IonIcon icon={AddDocument} slot="icon-only"></IonIcon>
							</IonButton> */}
						{/* <div className="pdf-div">
								<IonIcon icon={PDF} slot="icon-only" className="pdf-img"></IonIcon>
								<IonLabel className="label-text">File name .pdf</IonLabel>
							</div>
							<div className="execl-div">
								<IonIcon icon={EXCEL} slot="icon-only" className="execl-img"></IonIcon>
								<IonLabel className="label-execl">DESIGN HIJACK PRESS RELEASE.docx</IonLabel>
							</div> */}
						{/* </div> */}
						{/* <div className="number115">
							<IonText className="numberlast">{projectName.trim().length}</IonText>
						</div> */}
						<div className="field-item">
							<IonButton fill="clear">
								<IonIcon icon={ReminderProject} slot="icon-only"></IonIcon>
							</IonButton>
							<IonItem>
								<IonDatetime displayFormat="HH:mm A" className="time-container" placeholder="  00:00 AM"></IonDatetime>
							</IonItem>
						</div>
						<div className="field-item">
							<IonButton slot="start" fill="clear">
								<IonIcon icon={Location} slot="icon-only"></IonIcon>
							</IonButton>
							<IonItem>
								<IonInput placeholder="Add Location" className="assignContact"></IonInput>
							</IonItem>
						</div>
						<div className="field-item">
							<IonButton slot="start" fill="clear">
								<IonIcon icon={AddDocument} slot="icon-only"></IonIcon>
							</IonButton>
							<IonItem>
								<IonInput placeholder="Add Document(s)" className="assignContact"></IonInput>
							</IonItem>
						</div>
						<div className="field-item">
							<IonButton fill="clear">
								<IonIcon className="three_circle" icon={ThreeCicle} slot="icon-only"></IonIcon>
							</IonButton>
							<IonItem>
								<div className="color-container">
									<div className="color"></div>
									<div className="color1"></div>
									<div className="color2">
										<IonIcon icon={Right_pro} slot="icon-only"></IonIcon>
									</div>
									<div className="color3"></div>
									<div className="color4"></div>
									<div className="color5"></div>
									<div className="color6"></div>
								</div>
							</IonItem>
						</div>
					</div>
					<div className="allRow">
						<div className="btn-row">
							<button className="cancel" onClick={() => onCloseCallBack()}>
								Cancel
							</button>
							<button
								className={projectName === '' ? 'active' : 'create-task'}
								onClick={() => {
									onCreatProject();
								}}
							>
								Create
							</button>
							{/* <button
								className="create-task"
								disabled={!projectName}
								onClick={() => {
									onCreatProject();
								}}
							>
								Create Task
							</button> */}
						</div>
					</div>
				</div>
			</IonModal>
			<IonActionSheet
				mode="ios"
				isOpen={showPhotoOptionsActionSheet}
				onDidDismiss={() => {
					setShowPhotoOptionsActionSheet(false);
				}}
				cssClass="upload-photo-option-action-sheet"
				buttons={_getActionsheetOptions()}
			/>
			<ImageEditor
				imageOptions={{ targetWidth: 512, targetHeight: 512, quality: 0.5 }}
				openEditorType={OpenEditorType.FOR_NEW_PROJECT}
				selectedImage={selectedImage}
				show={showEditor}
				onClose={() => {
					setShowEditor(false);
				}}
				outputFormat={'file'}
				onSave={onPinturaEditingDone}
			/>
		</div>
	);
};

export default NewProject;
