import React from 'react';
import { IonContent, IonIcon, IonImg, IonItem, IonLabel, IonList, IonPage, IonThumbnail } from '@ionic/react';
import './style.scss';
import { RouteComponentProps } from 'react-router';
import { TopNavbar } from '../common/header/topbar';
import { connect } from 'react-redux';
import { apertureOutline, chatboxEllipsesOutline, newspaperOutline, storefrontOutline } from 'ionicons/icons';
import { locale } from '../../locales/local';
import { Navigation } from '../../services/navigationService';

interface iProps extends RouteComponentProps<{ name: string }> {}
interface iState {}

class Discover extends React.Component<iProps, iState> {
	constructor(props: iProps) {
		super(props);
		this.state = {};
	}

	render() {
		return (
			<IonPage className="discover-page">
				<TopNavbar {...this.props} pageTitle="DISCOVER" unreadCount={0} searchTypeHandler={() => {}} searchHandler={() => {}} />

				<IonContent className="main-content-profile has-bottom-navbar has-topbar-with-searchbar">
					<IonList className="profile-list-sec2" lines="none">
						<IonItem button lines="none" detail={true} onClick={() => Navigation.openMoments()}>
							<IonIcon className="discover-icn" slot="start" icon={apertureOutline}></IonIcon>
							<IonLabel slot="start" color="light">
								<p>{locale.discover.moments}</p>
							</IonLabel>
							<IonThumbnail className="profile-thumb-user-edit" slot="end">
								<IonImg src="./assets/img/demo-usermoments-topbg.png" />
							</IonThumbnail>
						</IonItem>
						<IonItem button lines="none" detail={true} onClick={() => alert(locale.discover.coming_soon)}>
							<IonIcon className="discover-icn" slot="start" src="assets/icon/film-roll.svg"></IonIcon>
							<IonLabel color="light">
								<p>{locale.discover.channels}</p>
							</IonLabel>
						</IonItem>
						<IonItem button lines="none" detail={true} onClick={() => alert(locale.discover.coming_soon)}>
							<IonIcon className="discover-icn" slot="start" icon={newspaperOutline}></IonIcon>
							<IonLabel color="light">
								<p>{locale.discover.news}</p>
							</IonLabel>
						</IonItem>
						<IonItem button lines="none" detail={true} onClick={() => alert(locale.discover.coming_soon)}>
							<IonIcon className="discover-icn" slot="start" icon={chatboxEllipsesOutline}></IonIcon>
							<IonLabel color="light">
								<p>{locale.discover.challenge}</p>
							</IonLabel>
						</IonItem>
						<IonItem button lines="none" detail={true} onClick={() => alert(locale.discover.coming_soon)}>
							<IonIcon className="discover-icn" slot="start" src="assets/icon/board.svg"></IonIcon>
							<IonLabel color="light">
								<p>{locale.discover.education}</p>
							</IonLabel>
						</IonItem>
						<IonItem button lines="none" detail={true} onClick={() => alert(locale.discover.coming_soon)}>
							<IonIcon className="discover-icn" slot="start" src="assets/icon/record.svg"></IonIcon>
							<IonLabel color="light">
								<p>{locale.discover.live_stream}</p>
							</IonLabel>
						</IonItem>
						<IonItem button lines="none" detail={true} onClick={() => alert(locale.discover.coming_soon)}>
							<IonIcon className="discover-icn" slot="start" icon={storefrontOutline}></IonIcon>
							<IonLabel color="light">
								<p>{locale.discover.buy_sell}</p>
							</IonLabel>
						</IonItem>
						<IonItem button lines="none" detail={true} onClick={() => alert(locale.discover.coming_soon)}>
							<IonIcon slot="start" className="discover-icn" src="assets/icon/infinity.svg"></IonIcon>
							<IonLabel color="light">
								<p>{locale.discover.mini_prog}</p>
							</IonLabel>
						</IonItem>
					</IonList>
				</IonContent>

			</IonPage>
		);
	}
}

const mapStateToProps = (state: any) => {
	return {
		dashboard: state.dashboard,
		loggedInUser: state.global.loggedInUser,
		isLoggedIn: state.auth.isLoggedIn,
	};
};

const mapDispatchToProps = (dispatch: any) => ({});

export default connect(mapStateToProps, mapDispatchToProps)(Discover);
