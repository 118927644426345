import { IonIcon } from '@ionic/react';
import React from 'react';
import './style.scss';

const VideoCallIcon = './assets/icon/video-icon.svg';
const MessageIcon = './assets/icon/message-round.svg';
const PhonCallIcon = './assets/icon/phoncall.svg';

interface IconTextButtonProps {
	chatMediaClick?: any;
}
const UserProfileMedia: React.FC<IconTextButtonProps> = ({ chatMediaClick }) => {
	return (
		<div className="container">
			<div className="media-icon-container-video">
				<div className="media-icon-video">
					<IonIcon className="media-icon" src={VideoCallIcon}></IonIcon>
				</div>
			</div>
			<div className="media-icon-container-message">
				<div className="media-icon-message">
					<IonIcon className="media-icon-message" onClick={chatMediaClick} src={MessageIcon}></IonIcon>
				</div>
			</div>
			<div className="media-icon-container-phoncall">
				<IonIcon className="media-icon-phoncall" src={PhonCallIcon}></IonIcon>
			</div>
		</div>
	);
};

export default UserProfileMedia;
