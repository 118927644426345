import { LOGIN_REQUESTED, LOGIN_FAILED, REGISTER, LOGIN_SUCCESS } from '../constants/auth';
import { DASHBOARD_INIT } from '../constants/dashboard';
import { AUTH_SHOW_LOADER, AUTH_FORM_ERROR, AUTH_RESET_VALUES } from '../constants/common';
import md5 from 'md5';
import { apiService } from '../../services/apiService';
import { getInternalStorage, setInternalStorage, isBlank, info, clearInternalStorage } from '../../helpers/common';
import store from '../store';
import { xmpp } from '../../services/xmpp';
import { locale } from '../../locales/local';
import { LoginResponse } from '../../types/profile';
import { LoginRequestData } from '../../types/api-requests';
import { dbService } from '../../services/dbService';
import { getMyChatOnlyProfile, getMyProfile, getMyTags } from './profile';
import { getMessageIdPrefix } from './chat';
import { LocalStorageKeys, PubSubEventType, SessionStorageKeys } from '../../services/enumService';
import SharedService from '../../services/sharedService';
import PubSub from 'pubsub-js';

export const resetValues = () => ({ type: AUTH_RESET_VALUES });

export const authenticated = async (user: LoginResponse, payload: any = {}, callback: any = null) => {
	if (!isBlank(payload)) {
		try {
			const isWebBrowser = 'serviceWorker' in window.navigator;

			let cookies = getInternalStorage();

			// todo: need to encrypt this
			setInternalStorage(LocalStorageKeys.Uatk, user.access_token);
			setInternalStorage(LocalStorageKeys.Urtk, user.refresh_token);
			setInternalStorage(SessionStorageKeys.SessionId, user['OAuth2.SESSION_ID']);
			setInternalStorage(LocalStorageKeys.Uuid, user.userId);
			setInternalStorage(LocalStorageKeys.DB, md5(user.userId!));
			setInternalStorage(LocalStorageKeys.PersonalDevice, payload.personalDevice || false);

			// user = await prefixMedia(user);

			// is this a personalDevice?  If not, delete any databases found here
			if (!payload.personalDevice) {
				if (isWebBrowser) {
					let database = (await dbService.listDatabases()).find((_database: any) => _database.includes(cookies[LocalStorageKeys.DB]));

					if (database) {
						await dbService.deleteDatabase(database);
					}
				}
			}
			await store.dispatch(getMyProfile());
			store.dispatch(getMyChatOnlyProfile(user.userId!));

			if (!cookies[LocalStorageKeys.MessageIdPrefixResult]) {
				store.dispatch(getMessageIdPrefix());
			}

			if (!xmpp.isReady) {
				xmpp.xmppManager();
			}

			let timeoutRef: any = null,
				loadInitialDataSuccess = false;

			const onLoadingInitialDataSuccess = () => {
				info('auth::onLoadingInitialDataSuccess');

				PubSub.unsubscribe(chatMessagePubSubToken);

				loadInitialDataSuccess = true;
				store.dispatch({ type: DASHBOARD_INIT, payload: { user: user } });

				// SharedService.loadInitialMessages();
				info('auth::authenticated: dashboard initialized.');
				callback && callback(user);
			};

			let noOfUserHaveMessageCountStored = -1;
			let noOfUserMessagesRevceivedCount = 0;

			const checkMessageLoadSuccess = () => {
				info('auth::messageload::checkMessageLoadSuccess::noOfUserMessagesRevceivedCount', noOfUserMessagesRevceivedCount);
				info('auth::messageload::checkMessageLoadSuccess::noOfUserHaveMessageCountStored', noOfUserHaveMessageCountStored);

				if (noOfUserHaveMessageCountStored >= 0 && noOfUserMessagesRevceivedCount >= noOfUserHaveMessageCountStored) {
					onLoadingInitialDataSuccess();
				}
			};

			SharedService.refreshAppData((noOfUserHaveMessageCount) => {
				noOfUserHaveMessageCountStored = noOfUserHaveMessageCount;
				if (noOfUserHaveMessageCount === 0) {
					onLoadingInitialDataSuccess();
				} else {
					checkMessageLoadSuccess();
				}
			});

			const chatMessagePubSubToken = PubSub.subscribe(PubSubEventType.ChatMessage, (msg: string, data: any) => {
				info('auth::authenticated::onMessageFetched', noOfUserMessagesRevceivedCount);
				if (data.action === PubSubEventType.ChatMessage) {
					noOfUserMessagesRevceivedCount++;
					checkMessageLoadSuccess();
				}

				if (timeoutRef) {
					clearTimeout(timeoutRef);
				}

				// If no message received for few seconds then assume onLoad success
				timeoutRef = setTimeout(() => {
					if (!loadInitialDataSuccess) {
						onLoadingInitialDataSuccess();
					}
				}, 3000);
			});

			// --End

			// xmpp.setMessagesLoaded(false);

			// if (user.contacts) {
			// 	for (let contact of user.contacts) {
			// 		contact = await prefixMedia(contact);
			// 	}
			// }

			// if (user.groups) {
			// 	for (let group of user.groups) {
			// 		group = await prefixMedia(group);
			// 	}
			// }

			// await apiService.addUser(await prefixMedia(user));
			// info('auth::authenticated: user added.');
			//await apiService.addContacts(user.contacts);
			//info('auth::authenticated: contacts added.');
			//await apiService.addGroups(user.groups);
			//info('auth::authenticated: groups added.');

			// store.dispatch({ type: LOGIN_INIT_CONTACTS });
			// user = await apiService.processConversations(false, user.conversations, true, user);
			// info('auth::authenticated: initial conversations seeded');
			// this initializes the dashboard

			store.dispatch({ type: LOGIN_SUCCESS, payload: user });
			info('auth::authenticated: user authenticated.');

			return user;
		} catch (error) {
			clearInternalStorage();
			store.dispatch({
				type: LOGIN_FAILED,
				payload: { error_description: locale.global.technical_difficulties },
			});
		}
	} else {
		clearInternalStorage();
	}
	return false;
};
export const login = async (payload: LoginRequestData, callBack: Function) => {
	store.dispatch({ type: LOGIN_REQUESTED, payload: {} });

	// deleteItemFromInternalStorage('groups');
	// let cookies = getInternalStorage();
	// payload.uuid = cookies[LocalStorageKeys.Uuid] || uuidv4();

	payload.grant_type = 'password';

	let user: any = await apiService.authenticate(payload);
	if (user.error || !user.username) {
		callBack(false, user);
		store.dispatch({ type: LOGIN_FAILED, payload: user });
	} else {
		authenticated(user, payload, (userData: any) => {
			if (userData) {
				callBack(true, userData);
			} else {
				callBack(false, {
					error_description: locale.global.technical_difficulties,
				});
			}
		});
	}
};

export const formHasErrors = (payload: any) => ({
	type: AUTH_FORM_ERROR,
	payload: payload,
});
export const isLoading = (loader: boolean) => ({
	type: AUTH_SHOW_LOADER,
	payload: loader,
});
export const register = (payload: any) => ({ type: REGISTER, payload: payload });
