import React, { createRef } from 'react';
import { IonContent, IonList, IonItem, IonButton, IonLabel, IonGrid, IonRow, IonModal, IonToast, IonRefresher, IonRefresherContent, IonPage } from '@ionic/react';
import { RouteComponentProps } from 'react-router';
import { connect } from 'react-redux';
import { addGroup, pageLoading, resetValues, searchContacts } from '../../redux/actions/dashboard';
import { initChat } from '../../redux/actions/chat';
import { addContact } from '../../redux/actions/addContact';
import store from '../../redux/store';
import './style.scss';
import { fetchProfilePic, validateEmail, validatePhone, info, getInternalStorage, isBlank } from '../../helpers/common';
import { TopNavbar } from '../common/header/topbar';
import { apiService } from '../../services/apiService';
import ConversationItem from './components/ConversationItem';
import ConversationProfilePhoto from './components/ConversationProfilePhoto';
import { locale } from '../../locales/local';
import { xmpp } from '../../services/xmpp';
import { DASHBOARD_INIT, DASHBOARD_SEARCH_END, DASHBOARD_SEARCH_REQUESTED, DASHBOARD_SEARCH_SUCCESS, DASHBOARD_SEARCH_SUCCESS_WITH_LOADING, DASHBOARD_SHOW_GROUP_MODAL, DASHBOARD_SHOW_MODAL, DASHBOARD_SHOW_TOAST } from '../../redux/constants/dashboard';
import { RefresherEventDetail } from '@ionic/core';
import { chevronDownCircleOutline } from 'ionicons/icons';
import ChatSearchBar from '../../modals/ChatSearchBar/ChatSearchBar';
import { dbService } from '../../services/dbService';
import { ChatStateTypes, ContactStateTypes, GroupStateTypes, ProfileStateTypes, ReduxStates } from '../../types/redux-states';
import { ContactItem, TagItem } from '../../types/contact';
import { Message } from '../../types/message';
import moment from 'moment';
import { updateDots } from '../../redux/actions/profile';
import { UpdateDotsData } from '../../types/api-requests';
import { ChatType, LocalStorageKeys, NavigationRoutes } from '../../services/enumService';
import { Navigation } from '../../services/navigationService';
import { GroupItem } from '../../types/group';

const BasketIcon = './assets/icon/notepad-icon.svg';

class Dashboard extends React.Component<iProps, iState> {
	cookies = getInternalStorage();
	ionSlidesRef: any = createRef();
	conversations: any = createRef();
	componentIsMounted: Boolean = false;
	componentIsUpdating: Boolean = true;
	messagesRefreshing: Boolean = false;

	constructor(props: iProps) {
		super(props);

		if (!this.cookies[LocalStorageKeys.Uuid]) {
			this.props.history.replace(NavigationRoutes.LOGIN);
		}

		this.state = {
			...this.props.dashboard,
			searchText: '',
			showPopover: false,
			isHideAllTags: false,
			showChatSearchBar: false,
			modal: {},
			actionHandler: () => {},
			selectedChatTag: 'All',
			tagVisibility: {},
			tags: [],
		};
	}

	async setPageData() {
		let user = await dbService.me();

		if (!xmpp.isReady) {
			await xmpp.xmppManager();
		}

		if (user) {
			info(`Dashboard::setPageData: Tags`);

			if (this.state.isHideAllTags !== user.hideAllTags) {
				this.setState({ isHideAllTags: user.hideAllTags });
			}
			if (this.state.tags.length !== user.tags?.length) {
				this.setState({ tags: user.tags });
			}
			if (user.tagVisibility && Object.keys(user.tagVisibility).length > 0) {
				this.setState({ tagVisibility: user.tagVisibility });
			}
		}

		if (xmpp.messagesLoaded && !xmpp.isReady) {
			info(`Dashboard::setPageData: Calling xmpp.xmppManager because xmpp.isReady is ${xmpp.isReady}`);
			await xmpp.xmppManager();
		}

		if (!xmpp.messagesLoaded) {
			user = await apiService.refreshMessages();
		}

		if (isBlank(this.props.dashboard?.conversations)) {
			store.dispatch({ type: DASHBOARD_INIT, payload: { user: user } });
		}

		if (!this.messagesRefreshing && !xmpp.messagesLoaded) {
			this.messagesRefreshing = true;
			info(`Dashboard::setPageData: Calling apiService.refreshMessages because xmpp.messagesLoaded is false`);
			user = await apiService.refreshMessages();
			store.dispatch({ type: DASHBOARD_INIT, payload: { user: user } });
			this.messagesRefreshing = false;
		}

		this.componentIsMounted = true;
	}

	async componentDidMount() {
		//info('Dashboard::componentDidMount:start');
		if (!this.componentIsMounted) {
			await this.setPageData();
			this.componentIsMounted = true;
			//info('Dashboard::componentDidMount:end');
		}
	}

	async componentDidUpdate(prevProps: any) {
		if (this.componentIsMounted && !this.componentIsUpdating) {
			this.componentIsUpdating = true;

			if (!xmpp.isReady) {
				await xmpp.xmppManager();
			}

			await this.setPageData();
			this.componentIsUpdating = false;
			//info('Dashboard::componentDidUpdate:end');
		} else {
			//info('Dashboard::componentDidUpdate:ignored');
		}
	}

	/*denyContact(contact: any) {
		this.props.denyContact({ contactId: contact._id });
	}*/

	addContact(contact: any) {
		this.props.addContact({ contactId: contact._id });
	}

	async chatClickHandler(data: ContactItem | GroupItem) {
		await this.props.initChat({
			receiver: { ...data, handlerText: locale.dashboard.start_chat },
			type: (data as any).mucName ? ChatType.GROUP : ChatType.P2P,
		});
		Navigation.chat({ receiver: data });
	}

	searchAllGroups(value: String, props: any) {
		store.dispatch({
			type: DASHBOARD_SEARCH_REQUESTED,
			payload: { loader: true, loaderMessage: locale.global.searching },
		});
		if (value.length >= 3) {
			let payload: any;
			if (validateEmail(value.toLowerCase())) {
				payload = { email: value.toLowerCase() };
			} else if (validatePhone(value.toLowerCase())) {
				payload = { phone: value.toLowerCase() };
			} else {
				payload = { userId: value.toLowerCase() };
			}
			props.searchContacts(payload);
			props.pageLoading({
				loader: true,
				loaderMessage: locale.global.searching,
			});
		} else {
			info('----reseting values----', this.props);
			props.resetValues();
		}
	}

	inviteUser() {
		info('inviting user ---', this.state.searchText);
		if (validateEmail(this.state.searchText)) {
			let br = '%0D%0A';
			window.open(`mailto:${this.state.searchText}?subject=Join bChat!&body=Hey there!${br}${br}Join%20bChat!${br}${br}It's great app!`);
		} else {
			store.dispatch({
				type: DASHBOARD_SHOW_TOAST,
				payload: { toastMessasge: locale.dashboard.error.default_trigger },
			});
		}
	}

	searchClickHandler(data: any) {
		data.imgSrc = fetchProfilePic(data.userId, 'contact');
		data.handlerText = locale.dashboard.send_req;
		this.setState({ modal: data, actionHandler: this.addContact });
		store.dispatch({ type: DASHBOARD_SHOW_MODAL, payload: true });
	}

	createGroupHandler() {
		this.setState({ modal: {}, actionHandler: this.addContact });
		store.dispatch({ type: DASHBOARD_SHOW_GROUP_MODAL, payload: true });
	}

	setShowModal(showModal: boolean) {
		store.dispatch({ type: DASHBOARD_SHOW_MODAL, payload: showModal });
		this.setState({ modal: {} });
	}

	performSearchText(searchText: String) {
		if (searchText.length >= 3) {
			this.props.searchContacts({ userId: searchText });
			this.props.pageLoading({ loader: true, loaderMessage: locale.searching });
		} else {
			info('----reseting values----', this.props);
			this.props.resetValues();
		}
	}

	setSearchText(searchText: String, props: any) {
		if (searchText.length >= 3) {
			let fetchedResults = props.dashboard.conversations.filter((contact: any) => searchText.toLowerCase() === (contact.type === 'chat' ? contact.userId.substring(0, searchText.length) : contact.room.substring(0, searchText.length)));
			if (fetchedResults.length > 0) {
				store.dispatch({
					type: DASHBOARD_SEARCH_SUCCESS,
					payload: fetchedResults,
				});
			} else {
				store.dispatch({
					type: DASHBOARD_SEARCH_SUCCESS_WITH_LOADING,
					showInvite: false,
					payload: fetchedResults,
				});
			}
		} else {
			store.dispatch({ type: DASHBOARD_SEARCH_END, payload: {} });
		}
	}

	createGroupSubmitHandler(payload: any, self: any) {
		self.props.addGroup(payload);
	}

	_buildChatList() {
		const { selectedChatTag } = this.state;
		let finalConversationsList: Array<ContactItem | GroupItem> = [];
		const contacts = this.props.contact.contacts || [];
		const groups = this.props.group.groups || [];

		if (selectedChatTag === 'All') {
			finalConversationsList = [...contacts, ...groups];
		} else {
			[...contacts, ...groups].forEach((conversaion) => {
				if (conversaion.tags && conversaion.tags?.indexOf(selectedChatTag) !== -1) {
					finalConversationsList.push(conversaion);
				}
			});
		}

		finalConversationsList = finalConversationsList.sort((conversation1: any, conversation2: any) => {
			let conversationKey1 = '';
			let conversationKey2 = '';
			if (conversation1.mucName) {
				conversationKey1 = (conversation1 as GroupItem).name;
			} else if (conversation1.username) {
				conversationKey1 = (conversation1 as ContactItem).username;
			}

			if (conversation2.mucName) {
				conversationKey2 = (conversation2 as GroupItem).name;
			} else if (conversation2.username) {
				conversationKey2 = (conversation2 as ContactItem).username;
			}

			const lastMessageU1: Message = this.props.chat.lastMessages[conversationKey1];
			const lastMessageU2: Message = this.props.chat.lastMessages[conversationKey2];
			let u1Time, u2Time;
			if (lastMessageU1) {
				u1Time = lastMessageU1.datetime;
			} else {
				u1Time = conversation1.created;
			}
			if (lastMessageU2) {
				u2Time = lastMessageU2.datetime;
			} else {
				u2Time = conversation2.created;
			}

			return moment(u1Time).diff(moment(u2Time), 'seconds') > 0 ? -1 : 1;
		});

		let isConversations = contacts.length > 0 || groups.length > 0 ? true : false;

		return (
			<>
				<IonContent className="full-background has-bottom-navbar has-topbar" style={{ opacity: this.props.chat.fetchingLastMessages ? 0 : 1 }}>
					<IonRefresher slot="fixed" onIonRefresh={this.doRefresh}>
						<IonRefresherContent pullingIcon={chevronDownCircleOutline} pullingText="" refreshingSpinner="circles" refreshingText=""></IonRefresherContent>
					</IonRefresher>

					{finalConversationsList.length > 0 && !this.props.profile.hideAllTags! && this.props.profile.myTags && this.props.profile.myTags!.length > 0 && this._renderTags()}

					{finalConversationsList && finalConversationsList.length > 0 ? (
						<>
							<IonList className="conversation-list">
								{finalConversationsList.map(
									// eslint-disable-next-line array-callback-return
									(contact: any, index: any) => {
										let conversation = { ...contact, lastMessage: this.props.chat.lastMessages ? this.props.chat.lastMessages[contact.username || contact.name] : null };

										isConversations = true;
										return <ConversationItem key={index} index={index} conversation={conversation} chatClickHandler={() => this.chatClickHandler(conversation)} />;
									}
								)}
							</IonList>
						</>
					) : (
						<>
							{!this.props.chat.fetchingLastMessages && !isConversations && (!this.props.dashboard.isLoading || this.componentIsMounted) && (
								<div className="no-conversations-view">
									<div className="title">{locale.dashboard.welcome}</div>
									<div className="description">{locale.dashboard.search_conversation}</div>
								</div>
							)}
						</>
					)}
				</IonContent>
			</>
		);
	}

	_buildSearch() {
		return (
			<>
				<IonContent className="has-bottom-navbar has-topbar-with-searchbar">
					{this._renderTags()}
					{this.props.dashboard.searchResults.length > 0 ? (
						<>
							<IonList className="conversation-list">
								{this.props.dashboard.searchResults.map((conversation: any, i: any) => (
									<IonItem className="searched-list-item" lines="none" detail={false} key={i}>
										<ConversationProfilePhoto user={conversation} />

										{this.props.dashboard.conversations.find((e: any) => e._id === conversation._id) || false ? null : (
											<IonButton slot="end" color="light" disabled={false} onClick={() => this.addContact(conversation)}>
												Send
											</IonButton>
										)}
										<IonLabel onClick={() => this.searchClickHandler(conversation)}>
											<h3>{conversation && conversation?.userId ? conversation.userId : conversation.groupname}</h3>
										</IonLabel>
									</IonItem>
								))}
							</IonList>
						</>
					) : (
						<>
							{!this.props.dashboard.isLoading && this.props.dashboard.showInvite ? (
								<IonGrid className="dbGrid">
									<IonRow className="dbRow">
										<IonButton color="light" disabled={false} onClick={() => this.inviteUser()}>
											Invite user
										</IonButton>
									</IonRow>
								</IonGrid>
							) : null}
						</>
					)}
				</IonContent>
			</>
		);
	}

	doRefresh = async (event: CustomEvent<RefresherEventDetail>) => {
		this.props.pageLoading({
			loader: true,
			loaderMessage: locale.global.searching,
		});
		await apiService.refreshMessages(this.props.dashboard?.conversations);
		event.detail.complete();
		this.props.pageLoading({ loader: false, loaderMessage: '' });
	};

	_renderTags() {
		const { selectedChatTag } = this.state;
		const { myTags } = this.props.profile;
		const visibleTags = myTags ? myTags.filter((tag: TagItem) => !tag.hide).map((tag: TagItem) => tag.tag) : [];
		if (visibleTags.length === 0) {
			return null;
		}

		return (
			<div className="tag-tabs">
				{['All', ...visibleTags].map((item: string, key: any) => {
					const tagContactIndex = this.props.contact.contacts?.findIndex((conversaion: ContactItem) => {
						return conversaion.tags && conversaion.tags?.indexOf(item) !== -1;
					});

					const tagGroupIndex = this.props.group.groups?.findIndex((conversaion: GroupItem) => {
						return conversaion.tags && conversaion.tags?.indexOf(item) !== -1;
					});

					if (item !== 'All' && (tagContactIndex === undefined || tagContactIndex < 0) && (tagGroupIndex === undefined || tagGroupIndex < 0)) {
						return null;
					}
					return (
						<div
							className={'tab-item ' + (selectedChatTag === item ? 'active' : '')}
							key={key}
							onClick={() => {
								this.setState({ selectedChatTag: item });
							}}
						>
							{item}
						</div>
					);
				})}
			</div>
		);
	}

	_handleLeftButtonPress = async () => {
		const myProfile = this.props.profile.loggedInUserProfile;
		await this.props.initChat({
			receiver: { ...myProfile, handlerText: locale.dashboard.start_chat },
			type: ChatType.P2P,
		});

		if (!this.props.profile.loggedInUserProfile?.notepadDot) {
			this.props.updateDots({ notepad: true });
		}

		if (myProfile) {
			Navigation.personalNotepad({ receiver: myProfile });
		}
	};

	render() {
		return (
			<IonPage className="dashboard-page">
				<TopNavbar
					{...this.props}
					leftButtonIcon={BasketIcon}
					showUnViewDotOnLeftIcon={!this.props.profile.loggedInUserProfile?.notepadDot}
					onLeftButtonPress={this._handleLeftButtonPress}
					pageTitle={locale.global.app_name}
					hideSearchBar={true}
					showSearchIcon={!this.state.showChatSearchBar}
					onSearchIconPress={() => this.setState({ showChatSearchBar: true })}
				/>

				{this.props.dashboard.isSearching ? this._buildSearch() : this._buildChatList()}
				{/* ---------------------Main Page area starts here--------------------- */}

				{/* ---------------------Main Page Ends here--------------------- */}
				<IonToast
					isOpen={this.props.dashboard.showToast}
					onDidDismiss={() => {
						this.setState({ searchText: '' });
						this.props.resetValues();
					}}
					message={this.props.dashboard.errorMessage}
					position="bottom"
					buttons={[
						{
							text: locale.global.close,
							role: 'cancel',
							handler: () => {
								info('Cancel clicked');
							},
						},
					]}
				/>

				<IonModal isOpen={this.props.dashboard.showGroupModal} onDidDismiss={() => store.dispatch({ type: DASHBOARD_SHOW_GROUP_MODAL, payload: false })} swipeToClose={true}>
					<div key={Math.random()}></div>
				</IonModal>

				{this.state.showChatSearchBar && (
					<ChatSearchBar
						history={this.props.history}
						location={this.props.location}
						type={'dashboard'}
						show={this.state.showChatSearchBar}
						onCancel={() => {
							this.setState({ showChatSearchBar: false });
						}}
					/>
				)}

				{/* <ChooseTagsModal loggedInUser={this.props?.dashboard?.loggedInUser} props={this.props} selectedTags={[]} show={true} /> */}
			</IonPage>
		);
	}
}

interface iProps extends RouteComponentProps<{ name: string }> {
	profile: ProfileStateTypes;
	contact: ContactStateTypes;
	chat: ChatStateTypes;
	group: GroupStateTypes;
	dashboard: any;
	loggedInUser: any;
	searchContacts: Function;
	updateDots: (payload: UpdateDotsData) => void;
	pageLoading: Function;
	startLoading: Function;
	resetValues: Function;
	isLoggedIn: boolean;
	addContact: Function;
	//confirmContact: Function;
	//denyContact: Function;
	addGroup: Function;
	initChat: (payload: any) => void;
	historyCatchup: Function;
}

interface iState {
	isHideAllTags: boolean;
	searchText: String;
	showModal: boolean;
	showChatSearchBar: boolean;
	showPopover: boolean;
	popoverEvent: any;
	selectedChatTag: any;
	modal: any;
	actionHandler: Function;
	cookies: any;
	tagVisibility: any;
	tags: any;
}

const mapStateToProps = (state: ReduxStates) => {
	return {
		profile: state.profile,
		group: state.group,
		contact: state.contact,
		chat: state.chat,
		dashboard: (state as any).dashboard,
		loggedInUser: (state as any).auth.loggedInUser as any,
		isLoggedIn: (state as any).auth.isLoggedIn,
	};
};

const mapDispatchToProps = (dispatch: any) => ({
	updateDots: (payload: UpdateDotsData) => dispatch(updateDots(payload)),
	searchContacts: (payload: String) => dispatch(searchContacts(payload)),
	addContact: (payload: any) => dispatch(addContact(payload)),
	pageLoading: (payload: any) => dispatch(pageLoading(payload)),
	resetValues: () => dispatch(resetValues()),
	addGroup: (payload: any) => dispatch(addGroup(payload)),
	initChat: (payload: any) => dispatch(initChat(payload)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Dashboard);
