import React from 'react';
import './index.scss';

interface AddContactItemProps {
	onClick?: any;
}
const AddContactItem: React.FC<AddContactItemProps> = ({ onClick }) => {
	return (
		<div className="contact-item" onClick={onClick}>
			<div className="contact-add-remove-item">
				<div className="horizontal-line"></div>
				<div className="vertical-line"></div>
			</div>
		</div>
	);
};

export default AddContactItem;
