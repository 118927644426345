import { IonButton, IonModal, IonIcon, IonSearchbar, IonToolbar, IonSpinner, IonToggle, IonLabel } from '@ionic/react';
import React, { useCallback, useState, useEffect, useRef } from 'react';
import './ChatSearchBar.scss';
import ContactItem from './ContactItem/ContactItem';
import ManageContacts from '../ManageContacts/ManageContacts';
import SearchContacts from './SearchContacts';
import { searchPageLoading, searchUserContact, resetSearchState } from '../../redux/actions/searchContact';
import { connect } from 'react-redux';
import { ContactAddBy, ProfileFriendActionTypes } from '../../services/enumService';
import { GlobalStateTypes, ProfileStateTypes, ReduxStates } from '../../types/redux-states';
import { GetProfileByUsernameRequestData } from '../../types/api-requests';
import { getProfileByUsername } from '../../redux/actions/profile';
import { UserProfile } from '../../types/profile';
import { apiService } from '../../services/apiService';
import { showAlert } from '../../redux/actions/global';
import { Navigation } from '../../services/navigationService';

const SearchIcon = './assets/icon/search.svg';
const ArrowIcon = './assets/icon/arrow-right-light.svg';
const SearchAppIdIcon = './assets/icon/search-app-id-icon.svg';

const TESTING_NO_CONTENT_SEARCH = 'no123';

const searchAllCategories: any = {
	saved: {
		title: 'Saved',
		icon: 'saved-icon.svg',
		type: 'saved',
		style: {
			width: '15.54px',
			height: '20px',
		},
	},
	audio: {
		title: 'Audio',
		icon: 'audio-search.svg',
		type: 'audio',
		style: {
			width: '21.25px',
			height: '20px',
		},
	},
	media: {
		title: 'Media',
		icon: 'media-search.svg',
		type: 'media',
		style: {
			width: '20px',
			height: '20px',
		},
	},
	links: {
		title: 'Links',
		icon: 'links-search.svg',
		type: 'links',
		style: {
			width: '20px',
			height: '20px',
		},
	},
	sketch: {
		title: 'Sketch',
		icon: 'sketchpad-draw-icon-active.svg',
		type: 'sketch',
		style: {
			width: '17px',
			height: '19px',
		},
	},
	files: {
		title: 'Files',
		icon: 'file-search.svg',
		type: 'files',
		style: {
			width: '15.17px',
			height: '20px',
		},
	},
	todo: {
		title: 'To Do',
		icon: 'todo-search.svg',
		type: 'todo',
		style: {
			width: '20px',
			height: '21.5px',
		},
	},
	mention: {
		title: 'Mention',
		icon: 'mention-search.svg',
		type: 'mention',
		style: {
			width: '22px',
			height: '25px',
		},
	},
	drive: {
		title: 'Drive',
		icon: 'drive-search.svg',
		type: 'drive',
		style: {
			width: '19px',
			height: '20px',
		},
	},
	market: {
		title: 'Market',
		icon: 'market-search.svg',
		type: 'market',
		style: {
			width: '21px',
			height: '20px',
		},
	},
	transfer: {
		title: 'Transfer',
		icon: 'transfer-icon.svg',
		type: 'transfer',
		style: {
			width: '25.45px',
			height: '20px',
		},
	},
	shared: {
		title: 'Shared',
		icon: 'shared-search.svg',
		type: 'shared',
		style: {
			width: '30px',
			height: '24px',
		},
	},
};

enum ShowMoreTypes {
	CONTACTS = 'contacts',
	GROUP_CHATS = 'group chats',
	CHATS = 'chats',
	SAVED = 'saved',
	FILES = 'files',
	TRANSFER = 'transfer',
	NONE = '',
}

let searchingTimerRef: any = null;

let results = [
	{
		categoryName: 'Contacts',
		type: ShowMoreTypes.CONTACTS,
		contacts: [
			{ name: 'Username', profession: 'Profession', id: 1 },
			{ name: 'Tulum Designer', profession: 'Profession', id: 2 },
			{ name: 'Username', profession: 'Uber Driver', id: 3 },
			{ name: 'Username', profession: 'Uber Owner', id: 4 },
		],
	},
	{
		categoryName: 'Group Chats',
		type: ShowMoreTypes.GROUP_CHATS,
		contacts: [{ name: 'Uber Drivers', profession: '8 Members', id: 1 }],
	},
	{
		categoryName: 'Chat History',
		type: ShowMoreTypes.CHATS,
		contacts: [
			{ name: 'Uber Drivers', profession: '66 related message(s)', id: 1 },
			{ name: 'Uber Drivers', profession: '283 related message(s)', id: 2 },
			{ name: 'Group Chat', profession: '28 related message(s)', id: 3 },
			{ name: 'Group Chat', profession: '28 related message(s)', id: 4 },
			{ name: 'Group Chat', profession: '28 related message(s)', id: 5 },
			{ name: 'Group Chat', profession: '28 related message(s)', id: 6 },
			{ name: 'Group Chat', profession: '2 related message(s)', id: 7 },
		],
	},
];

interface ChatSearchBarProps {
	profile?: ProfileStateTypes;
	searchProfileByUsername?: (data: GetProfileByUsernameRequestData) => Promise<UserProfile>;
	showAlert?: (data: GlobalStateTypes) => void;

	show: boolean;
	type: string; //dashboard, contacts, p2p, group, notepad
	onCancel?: any;
	loggedInUser: any;
	history: any;
	location: any;
	searchUserContact: Function;
	searchPageLoading: Function;
	resetSearchState: Function;
}

const ChatSearchBar: React.FC<ChatSearchBarProps> = ({ show, type, onCancel, history, profile, searchProfileByUsername, showAlert }) => {
	const inputRef: React.RefObject<HTMLIonSearchbarElement> = useRef(null);
	const [openModal, setOpenModal] = useState<boolean>(false);
	const [searchText, setSearchText] = useState<string>('');
	const [isSearching, setIsSearching] = useState<boolean>(false);
	const [isChatSelected, setIsChatSelected] = useState<boolean>(true);
	const [isGroupSelected, setIsGroupSelected] = useState<boolean>(true);
	const [searchCategories, setSearchCategories] = useState<any>([]);
	const [selectedCategory, setSelectedCategory] = useState<any>(null);
	const [showMoreType, setShowMoreType] = useState<ShowMoreTypes>(ShowMoreTypes.NONE);
	const [showMoreList, setShowMoreList] = useState<any>([]);
	const [showManageContactsModal, setShowManageContactsModal] = useState<boolean>(false);

	useEffect(() => {
		if (show) {
			let listUser: any = [];
			switch (type) {
				case 'contacts':
				case 'dashboard':
					listUser = ['saved', 'audio', 'media', 'links', 'sketch', 'files', 'todo', 'mention', 'drive', 'market', 'transfer', 'shared'];
					break;
				case 'add-member':
					listUser = ['saved', 'audio', 'media', 'links', 'sketch', 'files', 'todo', 'shared', 'transfer'];
					break;
				case 'notepad':
					listUser = ['saved', 'audio', 'media', 'links', 'sketch', 'files', 'todo', 'shared', 'transfer'];
					break;
				case 'group':
					listUser = ['saved', 'audio', 'media', 'links', 'sketch', 'files', 'todo', 'mention', 'shared', 'transfer'];
					break;
				case 'p2p':
					listUser = ['saved', 'audio', 'media', 'links', 'sketch', 'files', 'todo', 'drive'];
					break;

				default:
					break;
			}

			let list: any = [];
			listUser.forEach((element: string) => {
				if (searchAllCategories[element]) {
					list.push(searchAllCategories[element]);
				}
			});

			setOpenModal(true);
			setSearchCategories(list);
		}
	}, [type, show]);

	const onCloseCallBack = useCallback(() => {
		setSearchText('');
		setShowMoreList([]);
		setShowMoreType(ShowMoreTypes.NONE);
		onCancel && onCancel();
	}, [onCancel]);

	useEffect(() => {
		if (show) {
			setTimeout(() => {
				inputRef.current?.setFocus();
			}, 100);
		}
	}, [show, inputRef]);

	const SwitchItem = useCallback((title, value, onChange) => {
		return (
			<div className={'switch-item'}>
				<IonToggle className="toggle-customised" mode="ios" value={value} checked={value} onChange={onChange} />
				<IonLabel>{title}</IonLabel>
			</div>
		);
	}, []);

	const openUserProfile = useCallback(
		(user: UserProfile) => {
			Navigation.profileFriend({
				data: user,
				friendProfileActionType: ProfileFriendActionTypes.ContactAdd,
				contactAddBy: ContactAddBy.Id,
			});

			setOpenModal(false);
		},
		[history]
	);

	const searchUserByName = useCallback(() => {
		if (searchText && searchText !== profile?.loggedInUserProfile?.username) {
			apiService.searchUserByUsernameInDB(searchText).then((isFound) => {
				if (!isFound && searchProfileByUsername) {
					searchProfileByUsername({ username: searchText })
						.then((response: UserProfile) => {
							openUserProfile(response);
						})
						.catch((_: any) => {
							showAlert && showAlert({ alertMessage: 'Please edit your search and try again', alertTitle: 'Unable to find' });
						});
				}
			});
		} else {
			showAlert && showAlert({ alertMessage: 'Please edit your search and try again', alertTitle: 'Unable to find' });
		}
	}, [searchText, profile?.loggedInUserProfile?.username, openUserProfile, searchProfileByUsername, history, showAlert]);

	return (
		<>
			<IonModal mode="ios" showBackdrop={false} isOpen={openModal} onDidDismiss={onCloseCallBack} swipeToClose={false} cssClass={'chat-searchbar' + (searchText.length > 0 && type === 'add-member' ? ' chat-active' : '')} animated={false}>
				<div className="container">
					<IonToolbar mode="ios" className="dashboard-searchbar">
						<form
							onSubmit={(e) => {
								e.preventDefault();
							}}
						>
							<IonSearchbar
								animated={false}
								mode="ios"
								className="searchbar-wrapper"
								style={{ color: '#000000' }}
								debounce={1}
								value={String(searchText)}
								onIonChange={(e) => {
									setSearchText(e.detail.value!);

									if (e.detail.value) {
										setIsSearching(true);
										if (searchingTimerRef) {
											clearTimeout(searchingTimerRef);
											searchingTimerRef = null;
										}
										searchingTimerRef = setTimeout(() => {
											setIsSearching(false);
										}, 1000);
									} else {
										if (searchingTimerRef) {
											setIsSearching(searchingTimerRef);
											searchingTimerRef = null;
										}
										setIsSearching(false);
									}
								}}
								ref={inputRef}
								showCancelButton="never"
							></IonSearchbar>
							<IonButton
								className="cancel-search-btn"
								fill="clear"
								onClick={() => {
									setOpenModal(false);
								}}
							>
								Cancel
							</IonButton>
						</form>
					</IonToolbar>

					<div
						className="content"
						onScroll={() => {
							inputRef.current?.blur();
						}}
					>
						{isSearching ? (
							<div className="searching-view">
								<IonSpinner name="crescent"></IonSpinner>
								<div className="loading-text">Searching...</div>
							</div>
						) : (
							<>
								{showMoreType === ShowMoreTypes.NONE ? (
									<>
										{searchText && (
											<div className="search-result-container">
												{results.length > 0 ? (
													<>
														{results.map((item, key) => {
															return (
																<div key={key} className="inner-item">
																	<div className="group-title">{item.categoryName}</div>
																	{item.contacts.map((_item, _key) => {
																		if (_key > 2) return null;
																		return <ContactItem key={'item-contact' + key + '' + _key} item={_item} />;
																	})}

																	{item.contacts.length > 3 && (
																		<div
																			className="more-contacts"
																			onClick={() => {
																				setShowMoreType(item.type);
																				setShowMoreList(item.contacts);
																			}}
																		>
																			<IonIcon className="search-icon" src={SearchIcon}></IonIcon>
																			<div className="title">More {item.type}</div>
																			<IonIcon className="arrow-icon" src={ArrowIcon}></IonIcon>
																		</div>
																	)}
																</div>
															);
														})}

														<div className="search-app-id-view">
															<IonIcon src={SearchAppIdIcon}></IonIcon>
															<div className="title" onClick={searchUserByName}>
																Search ID: <span className="matching">{searchText}</span>
															</div>
														</div>

														<div className="ecosystem-view">
															<div className="search-icon-view">
																<IonIcon className="search-icon" src={SearchIcon}></IonIcon>
															</div>
															<div className="details-view">
																<div className="title">
																	Search: <span className="matching">{searchText}</span>
																</div>
																<div className="subtitle">Search BE-SOCIETY ecosystem, articles, and more</div>
															</div>
															<IonIcon className="arrow-icon" src={ArrowIcon}></IonIcon>
														</div>
													</>
												) : (
													<div className="no-content-found">No Content Found</div>
												)}
											</div>
										)}

										{!searchText && (
											<div className="category-container">
												{searchCategories.map((item: any, key: any) => {
													return (
														<div
															className={'item' + (selectedCategory && selectedCategory.type === item.type ? ' active' : selectedCategory ? ' inactive' : '')}
															key={'item' + key}
															onClick={() => {
																if (selectedCategory && selectedCategory.type === item.type) {
																	setSelectedCategory(null);
																} else {
																	setSelectedCategory(item);
																}
															}}
														>
															<IonIcon src={'./assets/icon/' + item.icon} style={item.style}></IonIcon>
															<div className="title"> {item.title}</div>
														</div>
													);
												})}
												<div className="item-fill"></div>
												<div className="item-fill"></div>
												<div className="item-fill"></div>
												<div className="item-fill"></div>
												<div className="item-fill"></div>
												<div className="setshowSearchLoaderitem-fill"></div>
												<div className="item-fill"></div>
												<div className="item-fill"></div>
												<div className="item-fill"></div>
												<div className="item-fill"></div>
												<div className="item-fill"></div>
												<div className="item-fill"></div>
											</div>
										)}
									</>
								) : (
									<>
										{searchText === TESTING_NO_CONTENT_SEARCH ? (
											<>
												<div className="no-content-found">
													No "<span className="heightlight">{searchText}</span>" contacts Found
												</div>
											</>
										) : (
											<>{showMoreType === ShowMoreTypes.CONTACTS && <SearchContacts contacts={showMoreList} onManageContacts={() => setShowManageContactsModal(true)} />}</>
										)}
									</>
								)}
							</>
						)}
					</div>

					{(type === 'add-member' || type === 'dashboard' || type === 'contacts' || type === 'chat' || type === 'notepad' || type === 'p2p' || type === 'group') && selectedCategory && (
						<div className="option-toggle">
							{SwitchItem('Chats', isChatSelected, () => {
								setIsChatSelected(!isChatSelected);
							})}
							{SwitchItem('Groups', isGroupSelected, () => {
								setIsGroupSelected(!isGroupSelected);
							})}
						</div>
					)}
				</div>
			</IonModal>

			<ManageContacts show={showManageContactsModal} contacts={showMoreList} onCancel={() => setShowManageContactsModal(false)} />
		</>
	);
};

const mapStateToProps = (state: ReduxStates) => {
	return {
		profile: state.profile,

		loggedInUser: state.global.loggedInUser,
		isLoggedIn: state.auth.isLoggedIn,
	};
};

const mapDispatchToProps = (dispatch: any) => ({
	searchProfileByUsername: (payload: GetProfileByUsernameRequestData) => dispatch(getProfileByUsername(payload)),
	showAlert: (payload: GlobalStateTypes) => dispatch(showAlert(payload)),

	searchUserContact: (payload: String) => dispatch(searchUserContact(payload)),
	searchPageLoading: (payload: String) => dispatch(searchPageLoading(payload)),
	resetSearchState: () => dispatch(resetSearchState()),
});

export default connect(mapStateToProps, mapDispatchToProps)(ChatSearchBar);
