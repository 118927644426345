import React from 'react';
import { IonItemDivider, IonItemGroup, IonLabel, IonList, IonItem } from '@ionic/react';
import './style.scss';
import _ from 'lodash';
import { GroupItem } from '../../../types/group';
import ConversationProfilePhoto from '../../dashboard/components/ConversationProfilePhoto';
import { Navigation } from '../../../services/navigationService';
import { ChatType } from '../../../services/enumService';
import { locale } from '../../../locales/local';
import store from '../../../redux/store';
import { CHAT_INIT } from '../../../redux/constants/chat';

interface iProps {
	props: any;
	hideAlphabetDevider?: boolean;
	showDisableContactChecked?: boolean;
	isGroupList?: boolean;
	groups: Array<GroupItem>;
}

export const GroupsList: React.FC<iProps> = ({ props, groups }) => {
	const sortedList = groups.sort((a: any, b: any) => {
		let a1: any = a?.mucName.toLowerCase(),
			b1: any = b?.mucName?.toLowerCase();

		return a1 > b1 ? 1 : b1 > a1 ? -1 : 0;
	});

	const GroupItemComponent = ({ group }: { group: GroupItem }) => {
		return (
			<IonItem
				onClick={async () => {
					store.dispatch({
						type: CHAT_INIT,
						payload: {
							type: (group as any).mucName ? ChatType.GROUP : ChatType.P2P,
							receiver: { ...group, handlerText: locale.dashboard.start_chat },
						},
					});
					Navigation.chat({ receiver: group });
				}}
				lines="none"
				detail={false}
			>
				<ConversationProfilePhoto conversation={group} />

				<IonLabel>
					<h3>{group.mucName}</h3>
					<p className="profession">{group.memberAmount}</p>
				</IonLabel>
			</IonItem>
		);
	};

	return (
		<>
			<IonList className="group-list-component" mode="md">
				<IonItem lines="none">
					<IonLabel className="group-length">Groups({groups.length})</IonLabel>
				</IonItem>

				<IonItemGroup className="alpha-contacts">
					{groups?.length > 0 &&
						sortedList?.map((group: GroupItem, index: number) => {
							let previous = index - 1;
							const priviousGroup = sortedList[previous]?.mucName;
							return (
								<React.Fragment key={index}>
									{priviousGroup?.toLowerCase().charAt(0) !== group.mucName?.toLowerCase().charAt(0) ? (
										<IonItemDivider className="cont-divider" id={'section_' + group.mucName?.charAt(0).toUpperCase()}>
											<IonLabel>{group.mucName?.charAt(0).toUpperCase()}</IonLabel>
										</IonItemDivider>
									) : null}

									<GroupItemComponent group={group} />
								</React.Fragment>
							);
						})}
				</IonItemGroup>
			</IonList>
		</>
	);
};
