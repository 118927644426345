import { SHOW_TOAST } from '../constants/common';
import { DASHBOARD_ADD_CONTACT, DASHBOARD_ADD_CONTACT_FAILED, DASHBOARD_ADD_CONTACT_SUCCESS, DASHBOARD_CONFIRM_CONTACT_SUCCESS, DASHBOARD_LAST_MESSAGE_FAILED, DASHBOARD_CONFIRM_CONTACT_FAILED, DASHBOARD_CONFIRM_CONTACT } from '../constants/dashboard';

import { ADD_USER_ERROR, ADD_USER_FAILED, ADD_USER_REQUESTED, ADD_USER_SUCCESS, SEARCH_USER_REQUESTED, UPDATE_CONTACT_REQUESTED, UPDATE_CONTACT_FAILED, UPDATE_CONTACT_SUCCESS, UPDATE_CONTACT_ERROR } from '../constants/searchUser';
import { apiService } from '../../services/apiService';
import { locale } from '../../locales/local';
import { logError } from '../../helpers/common';
import { dbService } from '../../services/dbService';

export function addContactLoading(payload: String) {
	return { type: SEARCH_USER_REQUESTED, payload: { loader: payload, loaderMessage: locale.global.searching } };
}

export function sendContactRequest(payload: String) {
	return function (dispatch: any) {
		dispatch({ type: ADD_USER_REQUESTED, payload: { loader: true, loaderMessage: locale.global.sending_request } });
		apiService
			.addContact(payload, true)
			.then(async (response: any) => {
				if (response.constructor.name === 'Object' && (response.Error || Object.keys(response).length === 0)) {
					dispatch({ type: ADD_USER_FAILED, payload: response.Error });
					dispatch({ type: SHOW_TOAST, payload: { showToast: true, toastMessage: response.Error?.message || locale.global.error_occured } });
				} else {
					let user: any = await dbService.me();

					dispatch({ type: DASHBOARD_ADD_CONTACT_SUCCESS, payload: { contacts: [...response, user.groups] } });
					// dispatch({ type: ADD_USER_SUCCESS, payload: response, showInvite: response.length > 0 || true });
					dispatch({ type: ADD_USER_SUCCESS, payload: response, showInvite: false });
				}
			})
			.catch((error: any) => {
				dispatch({ type: ADD_USER_ERROR, payload: error });
				dispatch({ type: SHOW_TOAST, payload: { showToast: true, toastMessage: locale.global.error_occured } });
			});
	};
}

export function updateContact(payload: any) {
	return function (dispatch: any) {
		dispatch({ type: UPDATE_CONTACT_REQUESTED, payload: { loader: true, loaderMessage: locale.global.updating_contact } });
		apiService
			.updateUser(payload)
			.then((response: any) => {
				if (response.Error) {
					dispatch({ type: UPDATE_CONTACT_FAILED, payload: response });
					dispatch({ type: SHOW_TOAST, payload: { showToast: true, toastMessage: locale.global.error_occured } });
				} else {
					dispatch({ type: UPDATE_CONTACT_SUCCESS, payload: response, showInvite: response.length > 0 || true });
					dispatch({ type: SHOW_TOAST, payload: { showToast: true, toastMessage: locale.global.contact_updated } });
				}
			})
			.catch((error: any) => {
				dispatch({ type: UPDATE_CONTACT_ERROR, payload: error });
				dispatch({ type: SHOW_TOAST, payload: { showToast: true, toastMessage: locale.global.error_occured } });
			});
	};
}

export function addContact(payload: any) {
	return function (dispatch: any) {
		dispatch({ type: DASHBOARD_ADD_CONTACT, payload: { loaderMessage: locale.global.sending_request } });

		(async () => {
			let contacts: any = await apiService.addContact(payload); // returns all confirmed contacts

			if (contacts.Error) {
				dispatch({ type: DASHBOARD_ADD_CONTACT_FAILED, payload: contacts });
			} else {
				dispatch({ type: DASHBOARD_ADD_CONTACT_SUCCESS, payload: { conversations: await apiService.getConversations() } });
			}
		})();
	};
}

export function confirmContact(payload: any) {
	return function (dispatch: any) {
		dispatch({ type: DASHBOARD_CONFIRM_CONTACT, payload: { loaderMessage: locale.global.confirming } });

		(async () => {
			let contact: any = await apiService.confirmContact(payload, true);

			if (contact.Error) {
				logError('dashboard::action::confirmContact-api::ServerError::response', contact.Error);
				logError('dashboard::action::confirmContact-api::ServerError::payload', payload);

				dispatch({ type: DASHBOARD_CONFIRM_CONTACT_FAILED, payload: contact.Error.message });
				dispatch({ type: SHOW_TOAST, payload: { showToast: true, toastMessage: contact.Error.message } });
			} else {
				dispatch({ type: DASHBOARD_CONFIRM_CONTACT_SUCCESS, payload: { conversations: await apiService.getConversations(), contact: contact } });
			}
		})();
	};
}

export function denyContact(payload: any) {
	return function (dispatch: any) {
		dispatch({ type: DASHBOARD_ADD_CONTACT, payload: { loaderMessage: locale.global.denying } });

		(async () => {
			let contact: any = await apiService.denyContact(payload);

			if (contact.Error) {
				dispatch({ type: DASHBOARD_LAST_MESSAGE_FAILED, payload: contact.Error.message });
			} else {
				dispatch({ type: DASHBOARD_CONFIRM_CONTACT_SUCCESS, payload: { message: locale.global.req_denied, conversations: await apiService.getConversations() } });
			}
		})();
	};
}
