import React from 'react';
import { IonIcon, IonImg, IonLabel, IonThumbnail, IonCard, IonCardHeader, IonCardSubtitle, IonCardTitle, IonCardContent, IonNote, IonText, IonSlides, IonSlide } from '@ionic/react';
import { locationOutline, timeOutline } from 'ionicons/icons';

interface ContainerProps {
	moments: Array<any>;
}

const RepostsContent: React.FC<ContainerProps> = ({ moments }) => {
	const cards = [1, 2, 3, 4];
	const slideOpts = {
		loop: false,
		freeMode: true,
	};
	return (
		<>
			<div className="reposts-list">
				{moments.map((item, key) => {
					return (
						<React.Fragment key={key}>
							<div className="list-item">
								<div className="header-view">
									<div className="left-content">
										<IonThumbnail>
											<IonImg src={'assets/img/' + item.profile} />
										</IonThumbnail>
										<div className="time">
											<IonIcon icon={timeOutline}></IonIcon>
											<IonNote>2 min</IonNote>
										</div>
									</div>

									<div className="right-content">
										<IonLabel className="name">
											CNN
											<IonNote>@CNN</IonNote>
										</IonLabel>
										<div className="location">
											<IonIcon color="medium" icon={locationOutline}></IonIcon>
											<IonNote>Dallas, TX, USA</IonNote>
										</div>
										<IonLabel className="title">TRUSTED NEWS</IonLabel>
									</div>
								</div>

								<div className="cards-container">
									<IonSlides options={{ ...slideOpts, width: 290 }}>
										{cards.map((card, innerKey) => {
											return (
												<IonSlide key={innerKey}>
													<IonCard mode="md">
														<div className="img-view-container">
															<IonImg src="https://data.monkingme.space/file/mm-img-bucket/500_GHGoFpfToMbsGY906FPbY_1ufsiz_tp2NXjibhs-Pk2PkG_C0IetTQt" alt="ion" />
															<div className="img-overlay-view">
																<div className="left">
																	<button className="icon-btn">
																		<IonIcon src="assets/icon/share.svg"></IonIcon>
																	</button>
																</div>
																<div className="right">
																	<button className="icon-btn">
																		<IonIcon src="assets/icon/view.svg"></IonIcon>
																		<IonLabel>2.3K</IonLabel>
																	</button>
																	<button className="icon-btn">
																		<IonIcon src="assets/icon/comment.svg"></IonIcon>
																		<IonLabel>33</IonLabel>
																	</button>
																	<button className="icon-btn">
																		<IonIcon src="assets/icon/heart.svg"></IonIcon>
																		<IonLabel>124K</IonLabel>
																	</button>
																</div>
															</div>
														</div>
														<IonCardHeader>
															<IonCardTitle>Keep close to Nature's heart... and break clear away, once in awhile.</IonCardTitle>
															<IonCardSubtitle className="link-view">
																<IonImg src="assets/icon/link-copy-icon.png"></IonImg>
																breitbart.com
															</IonCardSubtitle>
														</IonCardHeader>
													</IonCard>
												</IonSlide>
											);
										})}
									</IonSlides>
								</div>
							</div>

							<div className="alternet-view-points">
								<IonLabel className="title">Alternate View Points</IonLabel>
								<IonSlides options={{ ...slideOpts, width: 220 }}>
									{cards.map((card, innerKey) => {
										return (
											<IonSlide key={innerKey}>
												<IonCard mode="md">
													<IonCardHeader>
														<div className="logo-row">
															<img src="assets/img/demo-cnn-icon.png" alt="logo"></img>
															<div style={{ flex: 1 }}></div>
															<img src="assets/img/demo-alternetview-icon.png" alt="logo"></img>
														</div>

														<IonCardSubtitle>1 week ago</IonCardSubtitle>
													</IonCardHeader>
													<IonImg src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSE1PoVdEN5UlznYbteS7k2B7TuUdAS-JRm8g&usqp=CAU"></IonImg>
													<IonCardContent>
														<IonText color="primary">Keep close to Nature's heart.</IonText>
													</IonCardContent>
												</IonCard>
											</IonSlide>
										);
									})}
								</IonSlides>
							</div>
						</React.Fragment>
					);
				})}
			</div>
		</>
	);
};
export default RepostsContent;
