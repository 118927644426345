export const DASHBOARD_REQUESTED = 'DASHBOARD_REQUESTED';
export const DASHBOARD_SUCCESS = 'DASHBOARD_SUCCESS';
export const DASHBOARD_FAILED = 'DASHBOARD_FAILED';
export const DASHBOARD_ERROR = 'DASHBOARD_ERROR';

export const DASHBOARD_SHOW_LOADER = 'DASHBOARD_SHOW_LOADER';
export const DASHBOARD_INIT = 'DASHBOARD_INIT_CONTACTS';

export const DASHBOARD_HIDE_LOADER = 'DASHBOARD_HIDE_LOADER';

export const DASHBOARD_SEARCH_REQUESTED = 'DASHBOARD_SEARCH_REQUESTED';
export const DASHBOARD_SEARCH_SUCCESS = 'DASHBOARD_SEARCH_SUCCESS';
export const DASHBOARD_SEARCH_SUCCESS_WITH_LOADING = 'DASHBOARD_SEARCH_SUCCESS_WITH_LOADING';
export const DASHBOARD_SEARCH_FAILED = 'DASHBOARD_SEARCH_FAILED';
export const DASHBOARD_SEARCH_ERROR = 'DASHBOARD_SEARCH_ERROR';
export const DASHBOARD_SEARCH_END = 'DASHBOARD_SEARCH_END';

export const DASHBOARD_SHOW_TOAST = 'DASHBOARD_SHOW_TOAST';

export const DASHBOARD_ADD_CONTACT = 'DASHBOARD_ADD_CONTACT';
export const DASHBOARD_CONFIRM_CONTACT = 'DASHBOARD_CONFIRM_CONTACT';
export const DASHBOARD_SHOW_MODAL = 'DASHBOARD_ADD_CONTACT';
export const DASHBOARD_SHOW_GROUP_MODAL = 'DASHBOARD_SHOW_GROUP_MODAL';
export const DASHBOARD_ADD_CONTACT_FAILED = 'DASHBOARD_ADD_CONTACT_FAILED';
export const DASHBOARD_ADD_CONTACT_SUCCESS = 'DASHBOARD_ADD_CONTACT_SUCCESS';
export const DASHBOARD_CONFIRM_CONTACT_SUCCESS = 'DASHBOARD_CONFIRM_CONTACT_SUCCESS';
export const DASHBOARD_CONFIRM_CONTACT_FAILED = 'DASHBOARD_CONFIRM_CONTACT_FAILED';
export const DASHBOARD_ADD_CONTACT_ERROR = 'DASHBOARD_ADD_CONTACT_ERROR';

export const DASHBOARD_LAST_MESSAGE_CLEAR = 'DASHBOARD_LAST_MESSAGE_CLEAR';
export const DASHBOARD_PUSH_CONTACT = 'DASHBOARD_PUSH_CONTACT';
export const DASHBOARD_PUSH_GROUP = 'DASHBOARD_PUSH_GROUP';
export const DASHBOARD_PUSH_CONTACT_ERROR = 'DASHBOARD_PUSH_CONTACT_ERROR';

export const DASHBOARD_LAST_MESSAGE = 'DASHBOARD_LAST_MESSAGE';
export const DASHBOARD_LAST_MESSAGE_FAILED = 'DASHBOARD_LAST_MESSAGE_FAILED';
export const DASHBOARD_LAST_MESSAGE_ERROR = 'DASHBOARD_LAST_MESSAGE_ERROR';

export const DASHBOARD_CREATE_GROUP_REQUESTED = 'DASHBOARD_CREATE_GROUP_REQUESTED';
export const DASHBOARD_CREATE_GROUP_FAILED = 'DASHBOARD_CREATE_GROUP_FAILED';
export const DASHBOARD_CREATE_GROUP_SUCCESS = 'DASHBOARD_CREATE_GROUP_SUCCESS';
export const DASHBOARD_MESSAGES_INITIALIZED = 'DASHBOARD_MESSAGES_INITIALIZED';
export const DASHBOARD_MESSAGES_LOADED = 'DASHBOARD_MESSAGES_LOADED';
