import { REGISTER_REQUESTED, REGISTER_FAILED, USERNAME_UNAVAILABLE, USERNAME_AVAILABLE, USERNAME_ERROR, REGISTER_SUCCESS } from '../constants/register';
import { REGISTER_SHOW_LOADER, REGISTER_FORM_ERROR, REGISTER_RESET_VALUES } from '../constants/common';
import { login } from './auth';
import { apiService } from '../../services/apiService';
import { setInternalStorage, getInternalStorage, deleteItemFromInternalStorage } from '../../helpers/common';
import { delay } from 'lodash';
import { RegisterUsernameRequestData, CreateAccountRequestData, LoginRequestData } from '../../types/api-requests';
import { LocalStorageKeys } from '../../services/enumService';

export function register(payload: CreateAccountRequestData) {
	return async (dispatch: any) => {
		dispatch({ type: REGISTER_REQUESTED });

		return new Promise((resolve, reject) => {
			delay(async () => {
				apiService
					.registerUser(payload)
					.then(async (_response: any) => {
						if (!_response.error && _response.success) {
							deleteItemFromInternalStorage(LocalStorageKeys.ReservedUname);

							const loginData: LoginRequestData = {
								username: payload.user.username,
								password: payload.user.password,
							};

							login(loginData, (status: boolean, response: any) => {
								if (status) {
									resolve(response);
									dispatch({ type: REGISTER_SUCCESS, payload: response });
								} else {
									dispatch({ type: REGISTER_FAILED, payload: response });
									reject(response);
								}
							});
						} else {
							dispatch({ type: REGISTER_FAILED, payload: _response });
							reject(_response);
						}
					})
					.catch((error: any) => {
						dispatch({ type: REGISTER_FAILED, payload: error });
						reject(error);
					});
			}, 200);
		});
	};
}

export function resetValues() {
	return { type: REGISTER_RESET_VALUES };
}

export function formHasErrors(payload: any) {
	return { type: REGISTER_FORM_ERROR, payload: payload };
}

export function isLoading(loader: boolean) {
	return { type: REGISTER_SHOW_LOADER, payload: loader };
}

export function registerUserName(payload: RegisterUsernameRequestData) {
	return async (dispatch: any) => {
		dispatch({ type: REGISTER_SHOW_LOADER, payload: true });

		return new Promise(async (resolve, reject) => {
			await apiService
				.registerUserName(payload)
				.then((response: any) => {
					if (response.success) {
						resolve(response);
						dispatch({ type: USERNAME_AVAILABLE, payload: response });
						setInternalStorage(LocalStorageKeys.ReservedUname, JSON.stringify({ username: payload.username, result: response.result }));
					} else {
						let reserveduname = getInternalStorage()[LocalStorageKeys.ReservedUname];
						if (reserveduname) {
							reserveduname = JSON.parse(reserveduname);
							if (reserveduname.username === payload.username && reserveduname.result) {
								resolve(response);
								dispatch({ type: USERNAME_AVAILABLE, payload: reserveduname });
							} else {
								reject(response);
								dispatch({
									type: USERNAME_UNAVAILABLE,
									payload: response.message,
								});
							}
						} else {
							reject(response);
							dispatch({
								type: USERNAME_UNAVAILABLE,
								payload: response.message,
							});
						}
					}
				})
				.catch((error: any) => {
					reject(error);
					dispatch({ type: USERNAME_ERROR, payload: error });
				});
		});
	};
}
