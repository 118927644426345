/* eslint-disable array-callback-return */
import React, { useState, useCallback, useEffect } from 'react';
import { IonImg, IonModal, IonIcon, IonButton } from '@ionic/react';
import { connect } from 'react-redux';
import './VoiceRecord.scss';
import { CircleProgress } from 'react-gradient-progress';
import AudioReactRecorder, { RecordState } from './AudioReactRecorder';
import SharedService, { sharedService } from '../../services/sharedService';
import { info } from '../../helpers/common';

const RecordingStopIcon = './assets/icon/record-stop-icon.svg';
const RecordingMicIcon = './assets/icon/record-mic-icon.svg';
const RecordingMicIconGray = './assets/icon/record-mic-icon-gray.svg';
const MicActionBtnBg = './assets/img/mic-action-btn-bg.png';
const VoiceTxtIcon = './assets/icon/Voice-Txt.svg';
const VoiceCloseIcon = './assets/icon/Voice-Close.svg';
const SpeakerIcon = './assets/icon/speaker-icon.svg';
const EnIcon = './assets/icon/En.svg';

interface VoiceRecordModalProps {
	show: boolean;
	onClose?: any;
	onAudioRecorded?: (audioData: any) => void;
}

const VoiceRecordModal: React.FC<VoiceRecordModalProps> = ({ show, onClose, onAudioRecorded }) => {
	const [convertStart, setConvertStart] = useState(false);
	const [isRecording, setIsRecording] = useState<boolean>(false);
	const [recordingSeconds, setRecordingSeconds] = useState<number>(0);
	const [convertProgress] = useState(0);
	const [recordState, setRecordState] = useState<any>(null);
	const recordingInterval = React.useRef<any>(null);

	const onCloseCallBack = useCallback(() => {
		onClose && onClose();
		setConvertStart(false);
		setIsRecording(false);
		setRecordState(RecordState.NONE);
		setRecordingSeconds(0);
		if (recordingInterval.current) {
			clearInterval(recordingInterval.current);
		}
	}, [onClose, recordingInterval]);

	const stopRecording = useCallback(() => {
		setRecordState(RecordState.STOP);
		setIsRecording(false);
		if (recordingInterval.current) {
			clearInterval(recordingInterval.current);
		}
		setRecordingSeconds(0);
	}, [recordingInterval]);

	const recordingTimeUpdate = useCallback(() => {
		setRecordingSeconds((prev) => {
			if (prev >= sharedService.chatVoiceRecordMaxTimeInSeconds) {
				stopRecording();
				return prev;
			}
			return prev + 1;
		});
	}, [recordingSeconds, stopRecording]);

	const startStopRecording = useCallback(() => {
		if (isRecording) {
			stopRecording();
		} else {
			setIsRecording(true);
			setRecordState(RecordState.START);
			recordingInterval.current = setInterval(recordingTimeUpdate, 1000);
		}
	}, [isRecording, recordingTimeUpdate, stopRecording]);

	const ActionButton = useCallback((icon, className = '', onClick) => {
		return (
			<IonButton className={'action-fab-btn ' + className} fill="clear" onClick={onClick}>
				<div className="container">
					<img alt="" src={MicActionBtnBg} />
					<IonIcon src={icon}></IonIcon>
				</div>
			</IonButton>
		);
	}, []);

	const onStop = (audioData: any) => {
		info('audioData', audioData);
		onAudioRecorded && onAudioRecorded(audioData);
	};

	// const WaveComponent = useCallback(() => {
	// 	return (
	// 		<div className="waves">
	// 			{normalizedData &&
	// 				normalizedData.length > 0 &&
	// 				normalizedData.map((data) => {
	// 					return <div className="wave-line" style={{ height: data * 48 + 'px' }}></div>;
	// 				})}
	// 		</div>
	// 	);
	// }, [normalizedData]);

	return (
		<IonModal showBackdrop={false} isOpen={show} onDidDismiss={onCloseCallBack} swipeToClose={true} cssClass="voicerecord-modal" animated={false}>
			<div className="content">
				<div className="recording-info-view">
					{/* {WaveComponent()} */}
					<AudioReactRecorder state={recordState} onStop={onStop} />
					{isRecording && <div className="duration">{recordingSeconds}</div>}
				</div>

				<div className="actions-btns">
					{/* It is not used now, but don't remove it, it is using for now to place button correctly */}
					<div style={{ width: '100px' }}>
						{isRecording && (
							<IonButton
								className="record-btn"
								fill="clear"
								disabled={true}
								style={{ opacity: 0 }}
								onClick={() => {
									stopRecording();
								}}
							>
								<IonIcon src={RecordingStopIcon}></IonIcon>
							</IonButton>
						)}
					</div>

					{ActionButton(VoiceCloseIcon, 'close', () => {
						onCloseCallBack();
					})}

					<div style={{ width: '100px' }}>
						{isRecording && (
							<>
								{convertStart ? (
									<div className={'convert-btn-progress-container'}>
										<div className="title">Convert</div>

										<IonButton className={'convert-btn-progress'} fill="clear" onClick={() => setConvertStart(false)}>
											<div className="container">
												<div className="circle-progress">
													<CircleProgress width={72} percentage={convertProgress} strokeWidth={3} strokeLinecap={'round'} primaryColor={['#ff5e5e', '#ffeeee']} secondaryColor="#F3F3F3" />
												</div>
												<IonIcon src={EnIcon}></IonIcon>
											</div>
										</IonButton>
									</div>
								) : (
									ActionButton(VoiceTxtIcon, 'txt', () => {
										setConvertStart(true);
									})
								)}
							</>
						)}
					</div>
				</div>
				<div className="bottom-mic-view">
					<IonButton fill="clear" className="recorded-view-btn" onClick={startStopRecording}>
						<div className="recorded-view">
							<div className="title">{isRecording ? 'Tap to Send' : 'Tap to Record'}</div>
							<IonIcon src={isRecording ? RecordingMicIcon : RecordingMicIconGray}></IonIcon>
						</div>
					</IonButton>
					<IonImg src={'./assets/img/voice-record-bottom-mask.svg'} className="bottom-mask"></IonImg>
				</div>
			</div>
		</IonModal>
	);
};

const mapStateToProps = (state: any) => {
	return {
		loggedInUser: state.global.loggedInUser,
		isLoggedIn: state.auth.isLoggedIn,
		searchContact: state.searchContact,
	};
};
const mapDispatchToProps = (dispatch: any) => ({});

export default connect(mapStateToProps, mapDispatchToProps)(VoiceRecordModal);
