import { IonButton, IonButtons, IonInput, IonModal } from '@ionic/react';
import React, { createRef, useEffect, useState } from 'react';
import './style.scss';
import { connect } from 'react-redux';
import { updateUser } from '../redux/actions/profile';
import { pageLoading, hidePageLoading } from '../redux/actions/dashboard';

interface ThreadPopUpProps {
	show: boolean;
	onCloseCallBack: any;
	onOptionSelect: (OptionValue: string) => void;
	threadName: string;
	onMinimize: any;
	onThreadContainer: any;
}

const ThreadPopUp: React.FC<ThreadPopUpProps> = ({ show, onCloseCallBack, onOptionSelect, threadName, onMinimize, onThreadContainer }) => {
	const tagInputRef: React.RefObject<HTMLIonInputElement> = createRef();
	const [threadAddName, setthreadAddName] = useState<string>(threadName);

	useEffect(() => {
		setthreadAddName(threadName);
	}, [threadName]);

	return (
		<>
			<IonModal showBackdrop={true} isOpen={show} onDidDismiss={onCloseCallBack} swipeToClose={true} cssClass="add-new-tag-popup" animated={true}>
				<div className="container-thread">
					<div className="content-thread-view">
						<div className="title">Thread</div>
						{threadName === 'Chat' ? (
							<div className="chat-text">“Chats” Thread cannot be renamed</div>
						) : (
							<div className="tag-thread-input-container">
								<div className="input-view">
									<IonInput
										maxlength={16}
										ref={tagInputRef}
										id="tagNameInput"
										placeholder="Type thread name"
										className="tag-thread-name-input"
										value={threadAddName}
										onIonChange={(event) => setthreadAddName(event.detail.value!)}
										onClick={() => {
											onOptionSelect && onOptionSelect('rename');
											onCloseCallBack();
										}}
									/>
								</div>
							</div>
						)}

						<div className="thread-btns-view">
							<IonButton fill="clear" className="create-btn">
								Search
							</IonButton>
							<IonButton
								fill="clear"
								className="create-btn"
								onClick={() => {
									onOptionSelect && onOptionSelect('viewContent');
									onCloseCallBack();
								}}
							>
								View Content
							</IonButton>
							<IonButton
								fill="clear"
								className="create-btn"
								onClick={() => {
									onOptionSelect && onOptionSelect('manage');
									onCloseCallBack();
								}}
							>
								Manage/Edit
							</IonButton>
							<IonButton
								fill="clear"
								className="create-btn"
								onClick={() => {
									onMinimize();
									onThreadContainer();
									onCloseCallBack();
								}}
							>
								Minimize All
							</IonButton>
							{/* 							
							<IonButton
								fill="clear"
								className="delete-btn"
								onClick={() => {
									onOptionSelect && onOptionSelect('delete');
									onCloseCallBack();
								}}
							>
								Delete / Leave
							</IonButton> */}

							<IonButtons className="cancel-add-container">
								<IonButton
									fill="clear"
									className="cancel"
									onClick={() => {
										setthreadAddName('');
										onCloseCallBack();
									}}
								>
									Cancel
								</IonButton>
								<IonButton
									fill="clear"
									className="ok-btn"
									onClick={() => {
										onOptionSelect && onOptionSelect('newThread');
										onCloseCallBack();
									}}
								>
									Add
								</IonButton>
							</IonButtons>
						</div>
					</div>
				</div>
			</IonModal>
		</>
	);
};

const mapStateToProps = (state: any) => {
	return {};
};
const mapDispatchToProps = (dispatch: any) => ({
	updateUser: (payload: String) => dispatch(updateUser(payload)),
	pageLoading: (payload: any) => dispatch(pageLoading(payload)),
	hidePageLoading: () => dispatch(hidePageLoading()),
});

export default connect(mapStateToProps, mapDispatchToProps)(ThreadPopUp);
