import React, { useEffect, useState } from 'react';

interface iProps {
	file: File;
}
export const Image: React.FC<iProps> = ({ file }) => {
	const [base64, setBase64] = useState<string>('');

	useEffect(() => {
		const fileReader = new FileReader();
		fileReader.onload = () => {
			const base64String = fileReader.result as string;
			base64String.replace('data:', '').replace(/^.+,/, '');
			setBase64(base64String);
		};
		fileReader.readAsDataURL(file);
	}, [file]);

	return <img src={base64} />;
};
