export enum NetworkStatus {
	ONLINE = 'online',
	OFFLINE = 'offline',
}

export enum LocalStorageKeys {
	PushNotifications = 'pushNotifications',
	PersonalDevice = 'personalDevice',
	Uuid = 'uuid',
	SupportsPush = 'supportsPush',
	Uatk = 'uatk',
	Urtk = 'urtk',
	MessageIdPrefixResult = 'messageIdPrefix_result',
	MessageIdPrefixTimestamp = 'messageIdPrefix_timestamp',
	SupportsNotification = 'supportsNotification',
	ReservedUname = 'reserveduname',
	DB = 'databse_name',
	DBVersion = 'dbVersion',
	Verfication = 'verification',
}

export enum SessionStorageKeys {
	Version = 'version',
	SessionId = 'sessionId',
	Safari = 'Safari',
	DisplayMode = 'displayMode',
	MobileDevice = 'mobileDevice',
	IsApp = 'isApp',
	Listeners = 'listeners',
	DesktopNotifications = 'desktopNotifications',
	SupportsPush = 'supportsPush',
	SupportsNotification = 'supportsNotification',
	Network = 'network',
	Registered = 'registered',
	Reloading = 'reloading',
	Active = 'active',
	SM = 'sm',
	SkipUpdates = 'skipUpdates',
	Mode = 'mode',
	Rebuilding = 'rebuilding',
	Refreshing = 'refreshing',
}

export enum NavigationRoutes {
	LOGIN = '/login',
	VERIFICATIONS_REQUIRED = '/verification-required',
	SIGNUP = '/signup',

	TABS = '/auth',
	DASHBOARD = '/auth/dashboard',
	CONTACTS = '/auth/contacts',
	DISCOVER = '/auth/discover',
	PROFILE = '/auth/profile',
	RECENT_CONTACTS = '/auth/contacts/recent',
	SETTINGS = '/auth/profile/settings',
	My_PROFILE = '/profile',
	CHAT = '/chat',
	GROUP_CHAT = '/groupchat',
	PROFILE_FRIEND = '/profile-friend',
	EDIT_THREADS = '/edit-thread',
	PERSONAL_NOTEPAD = '/personal-notepad',
	MOMENTS = '/moments',
	CREATE_POST = '/create-post',
	ADD_LOCATION = '/add-location',
	SHARE_WITH = '/share-with',
	EDIT_PROFILE = '/profile/edit-profile',
	EDIT_CHATONLY_PROFILE = '/profile/edit-chatonlyprofile',
	PROFILE_UPLOAD_PHOTO = '/profile/upload-photo',
	PROFILE_UPLOAD_PHOTO_CHATONLY = '/profile/uploadphoto-chatonly',
	VIEWAS_CHATONLY = '/contacts/viewas-chatonly',
	CHAT_CONTACTS = '/contacts/chat',
	SEARCH_CONTACTS = '/contacts/search',
	ADD_CONTACTS = '/contacts/add',
	MANAGE_CONTACTS = '/contacts/manage',
	ADD_MEMBERS = '/contacts/add-members',
	CONTACTS_LIST = '/contacts/list',
	GROUP_CHATS = '/groupChats',
	TAGS = '/tags',
	TAGS_INFO = '/taginfo',
	CAMERA = '/camera',
	PRIVACY = '/privacy',
	MY_QRCODE = '/my-qrcode',
	NEW_GROUP = '/new-group',
	GROUP_MANAGER = '/group-manager',
	GROUP_SETTING = '/group-setting',
	GROUP_INFO = '/group-info',
	MANAGE_GROUP = '/group-manage',
	GROUP_GUIDELINE = '/group-guidline',
	GROUP_ADMIN = '/group-admin',
	GROUP_MEMBERS = '/group-members',
	CHAT_MEDIAS = '/chat-medias',
	PERSONAL_STORAGE = '/personal-storage',
	PERSONAL_FOLDER = '/personal-folder',
	USER_PRIVACY = '/userprivacy',
	DELETE_ACCOUNT = '/delete-account',
	GENERAL = '/auth/profile/general',
}

export enum ProfileFriendActionTypes {
	ContactRequestView = 'contact-request-view',
	ContactAdd = 'contact-add',
	ContactView = 'contact-view',
	ChatProfileView = 'chat-profile-view',
	ContactPending = 'contact-pending',
}

export enum ContactStatus {
	IN_REQUEST = 'I',
	OUT_REQUEST = 'O',
	BOTH = 'B',
	REJECTED = 'R',
	BLOCKED = 'BLK',
	DELETE = 'DELETE',
}

export enum ContactPrivacySettingType {
	MOMENTS = 1,
	CHATS_ONLY,
}

export enum ChatType {
	P2P = 'chat',
	GROUP = 'groupchat',
}

export enum MessageType {
	TEXT = 'text',
	MEDIA = 'media',
	FILE = 'file',
	GIF = 'giphy',
	CONTACT = 'contact',
	REQUEST_ACCEPTED = 'request_accepted',
	GROUP_CREATED = 'group_created',
	LOCATION = 'location',
}

export enum ContactAddBy {
	Id = 'Searched ID',
	QrCode = 'Qr Code',
	Group = 'Group',
}

export enum ContactsListType {
	BLOCK = 'block',
	CHAT_ONLY = 'chat_Only',
	MY_MOMENTS = 'my_moments',
	THERE_MOMENTS = 'there_moments',
}

export enum AddMembersPageActionType {
	ChooseContactForCurrentGroup = 'choose-contact-for-current-group',
	ChooseContactForCurrentGroupInfoAdmin = 'choose-contact-for-current-groupInfo-admin',
	ChooseContactForGroup = 'choose-contact-for-group',
	ChooseContactForTag = 'choose-contact-for-tag',
	ChooseContactWithCreateNewTag = 'choose-contact-with-new-tag',
	ChooseContactWithShareContact = 'choose-contact-with-share-contact',
	ChooseContactForAddChatOnlyContact = 'choose-contact-for-add-chatonly-contact',
	ChooseContactForOwnerShip = 'choose-contact-for-ownership',
	ChooseContactForSecondaryAdmin = 'choose-contact-for-secondary-admin',
	ChooseContactForTertiaryAdmin = 'choose-contact-for-tertiary-admin',
	ChooseforThreadDetail = 'choose-for-thread-detail',
	ForwardMessage = 'forward-message',
	ChooseContactForForwordSketchpad = 'choose-contact-for-forword-sketchpad',
	ChooseContactForNewProject = 'choose-contact-for-new-project',
	ChooseContactForSelectMessageSend = 'choose-contact-for-select-message-send',
	ChooseContactWithAdditionalOptionShareConatact = 'choose-contact-with-additional-option-share-contact',
	ChooseContactForShareContactInGroup = 'choose-contact-for-share-contact-in-group',
}
export enum PersonalFolderShowType {
	ThreadDetail = 'thread_detail',
	Image = 'image',
	Video = 'video',
	File = 'file',
	Folder = 'folder',
}

export enum HideType {
	MyMoments = 'my-moments',
	ThereMoments = 'there-moments',
}

export enum ContactsPageActionType {
	CreateGroup = 'create-group',
	ForwardMessage = 'forward-message',
	ChooseContactForTag = 'choose-contact-for-tag',
}

export enum ChatMessageState {
	ORIGINAL = 'original',
	REPLY = 'reply',
	RESEND = 'resend',
}

export enum ChatMediaType {
	IMAGE = 'image',
	VIDEO = 'video',
	AUDIO = 'audio',
	DOCUMENT = 'document',
	THUMBNAIL = 'thumbnail',
}

export enum ChatContactsPageActionType {
	ForwardMessage = 'forwardmessage',
	ShareContact = 'sharecontact',
}
export enum GroupMembersPageActionType {
	ViewMore = 'viewMore',
	ViewMoreMembers = 'viewMoreMembers',
	GroupManager = 'groupmanager',
	GroupInfo = 'groupInfo',
}

export enum ContactsListMode {
	ChatContactsList = 'chatcontactslist',
	NormalContactsList = 'normalcontactslist',
	ChooseContactsList = 'choosecontactslist',
	NewRequestContactsList = 'newrequestcontactslist',
	MostRecentContactsList = 'mostrecentcontactslist',
	RecentlyAddedContactsList = 'recentlyaddedcontactslist',
	RemoveContactListMode = 'removecontactslistmode',
	StarredContact = 'starredcontacts',
}

export enum MessageSendStatus {
	PendingSent = 'PendingSent',
	PendingUpload = 'PendingUpload',
	Sent = 'Sent',
	SentFailed = 'SentFailed',
	Received = 'Received',
}

export enum ManageContactActionsSheetOptions {
	ClearChatHistory = 'clear_chat_history',
	BlockContact = 'block_contact',
	DeleteContact = 'delete_contact',
	PrivacyContact = 'privacy_contact',
	ReportContact = 'report_contact',
}

export enum PubSubEventType {
	RecallEdit = 'RecallEdit',
	QuickAccessBtnVisibility = 'QuickAccessBtnVisibility',
	ReplyToTheConversation = 'ReplyToTheConversation',
	UploadMedia = 'uploadMedia',
	ChatMessage = 'chat_message',
	Translated = 'translated_message',
	XMPPEvent = 'XMPPEvent',
	SelectedMsgLength = 'SelectedMsgLength',
	TotalChatMessages = 'TotalChatMessages',
	IsSelectedMsg = 'IsSelectedMsg',
	FavGifsUpdated = 'FavouriteGifsUpdated',
	OnCancel = 'OnCancel',
	ViewThread = 'ViewThread',
	MoveThreadPopup = 'MoveThreadPopup',
	ThreadChangeInChat = 'ThreadChangeInChat',
}

export enum CreatePostType {
	TEXT = 'text',
	MEDIA = 'media',
}

export enum ThreadType {
	P2P = 1,
	GROUP = 2,
}

export enum AffiliationType {
	OWNER = 'owner',
	ADMIN = 'admin',
	MEMBER = 'member',
	NONE = 'none',
}
