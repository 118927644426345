import React from 'react';
import { IonContent, IonLabel, IonPage } from '@ionic/react';
import './style.scss';
import { RouteComponentProps } from 'react-router';
import { IonList, IonItem } from '@ionic/react';
import { connect } from 'react-redux';
import { pageLoading, updateUser } from '../../redux/actions/profile';
import { locale } from '../../locales/local';
import { getInternalStorage, logoutUser } from '../../helpers/common';
import { TopNavbar } from '../common/header/topbar';
import { SessionStorageKeys } from '../../services/enumService';
import { Navigation } from '../../services/navigationService';

interface iProps extends RouteComponentProps<{ name: string }> {}

interface iState {}
class SettingsPage extends React.Component<iProps, iState> {
	cookies: any = getInternalStorage();
	_categoryItem = (title: string, color: string) => {
		return (
			<div className="cat-item">
				<div className="square" style={{ background: color }}></div>
				<div className="cat-title">{title}</div>
			</div>
		);
	};

	render() {
		return (
			<IonPage className="account-setting-page">
				<TopNavbar
					{...this.props}
					pageTitle={locale.settings.title}
					showBack={true}
					hideSearchBar={true}
					onLeftButtonPress={() => {
						Navigation.back();
					}}
				/>

				<IonContent className="main-content-profile has-bottom-navbar has-topbar">
					<IonList className="profile-list-sec2" lines="none">
						<IonItem
							button
							lines="none"
							detail={true}
							onClick={() => {
								Navigation.openGeneral();
							}}
						>
							<IonLabel slot="start" color="light">
								<p className="edit-profile-start">{locale.global.general}</p>
							</IonLabel>
						</IonItem>
						<IonItem button lines="none" detail={true}>
							<IonLabel slot="start" color="light">
								<p className="edit-profile-start">{locale.settings.security}</p>
							</IonLabel>
						</IonItem>
						<IonItem button lines="none" detail={true}>
							<IonLabel slot="start" color="light">
								<p className="edit-profile-start">{locale.settings.notification}</p>
							</IonLabel>
						</IonItem>

						<IonItem button lines="none" detail={true}>
							<IonLabel
								slot="start"
								color="light"
								onClick={() => {
									Navigation.openPrivacy();
								}}
							>
								<p className="edit-profile-start">{locale.global.privacy}</p>
							</IonLabel>
						</IonItem>
						<IonItem button lines="none" detail={true} onClick={() => this.setState({ openUploader: true })}>
							<IonLabel slot="start" color="light">
								<p className="edit-profile-start">{locale.global.storage}</p>
							</IonLabel>
							<p className="edit-profile-p" slot="end">
								{locale.global.storage_space}
							</p>
						</IonItem>

						<IonItem button lines="none" detail={true}>
							<IonLabel slot="start" color="light">
								<p className="edit-profile-start">{locale.settings.help_feedback}</p>
							</IonLabel>
						</IonItem>

						<IonItem button lines="none" detail={true} onClick={() => this.setState({ openUploader: true })}>
							<IonLabel slot="start" color="light">
								<p className="edit-profile-start">{locale.global.about}</p>
							</IonLabel>
							<p className="edit-profile-p" slot="end">
								{`${locale.global.app_name} ${locale.global.version_word} ${this.cookies[SessionStorageKeys.Version]}`}
							</p>
						</IonItem>

						{/* <IonItem> */}
						{/* <div className="div-first">
							<div className="storage-space">
								<p slot="start" className="storage">
									{locale.global.storage_space}
								</p>
							</div>
							<div className="graph">
								<div className="box1"></div>
								<div className="smallbox"></div>
								<div className="smallboxyellow"></div>
							</div>
							<div className="category-items">
								{this._categoryItem('BE-SOCIETY Data', '#FF5E5E')}
								{this._categoryItem('Personal', '#3EBAFF')}
								{this._categoryItem('Chats', '#FFCC4D')}
								{this._categoryItem('Free Space', '#DADADA')}
							</div>
						</div> */}
						{/* </IonItem> */}

						<IonItem button lines="none" detail={false} onClick={() => this.setState({ openUploader: true })}>
							<IonLabel className="toolbarTitle" color="light">
								<p className="edit-profile-start">{locale.global.switch_ac}</p>
							</IonLabel>
						</IonItem>

						<IonItem button lines="none" detail={false} onClick={() => logoutUser()}>
							<IonLabel className="toolbarTitle" color="light">
								<p className="edit-profile-start">{locale.global.logout}</p>
							</IonLabel>
						</IonItem>
					</IonList>
				</IonContent>
			</IonPage>
		);
	}
}

const mapStateToProps = (state: any) => {
	return {
		profile: state.profile,
		loggedInUser: state.global.loggedInUser,
	};
};

const mapDispatchToProps = (dispatch: any) => ({
	updateUser: (payload: String) => dispatch(updateUser(payload)),
	pageLoading: (payload: any) => dispatch(pageLoading(payload)),
});

export default connect(mapStateToProps, mapDispatchToProps)(SettingsPage);
