import _ from 'lodash';

const controlTypes: any = [
		'selfUpdate',
		'contactRequest',
		'contactConfirmed',
		'contactDenied',
		'contactChange',
		'contactBlock',
		'contactUnblock',
		'contactDelete',
		'groupAdd',
		'groupChange',
		'groupDelete',
		'moment',
		'messageUpdated',
		'messageRecall',
		'messageRead',
		'mediaChanged',
		'contact',
		'contacts',
		'group',
		'groups',
		'message',
		'messages',
		'conversations',
		'messageTranslated',
	],
	messageTypes: any = ['text', 'media', 'url', 'document'],
	messageStatusTypes: any = ['PreSend', 'PendingSent', 'PendingAck', 'PendingUpload', 'SentFailed', 'Reconciled'],
	stanzaEventTypes: any = [
		'*',
		'--reset-stream-features',
		'--transport-disconnected',
		'auth:failed',
		'auth:success',
		'available',
		'carbon:sent',
		'chat',
		'chat:received',
		'chat:received:resent',
		'chat:sent',
		'chat:sent:viaCarbon',
		'chat:sent:acked',
		'connected',
		'controlMessage',
		'credentials:update',
		'disco',
		'disco:caps',
		'disconnected',
		'error',
		'extraneous',
		'features',
		'groupchat:sent',
		'groupchat:received',
		'groupchat:received:resent',
		'iq',
		'iq:get:ping',
		'mam:item',
		'message',
		'message:acked',
		'message:error',
		'message:failed',
		'message:hibernated',
		'message:id:',
		'message:sent',
		'presence',
		'presence:id:',
		'pubsub:event',
		'pubsub:published',
		'raw:incoming',
		'raw:outgoing',
		'sasl',
		'session:bound',
		'session:prebind',
		'session:started',
		'session:end',
		'sm',
		'sm:id:',
		'stanza',
		'stanza:acked',
		'stream:data',
		'stream:error',
		'stanza:failed',
		'stream:management:ack',
		'stream:management:resumed',
		'stream:end',
		'stream:start',
		'streamError',
		'unavailable',
	],
	stanzaErrorConditionTypes: any = ['invalid-xml', 'conflict', 'policy-violation', 'connection-timeout', 'system-shutdown', 'not-authorized', 'stream:error'];

export const Constants: any = {
	CONTROL: _.keyBy(controlTypes),
	MESSAGE_STATUS: _.keyBy(messageStatusTypes),
	MESSAGE_TYPES: _.keyBy(messageTypes),
	STANZA_EVENT_TYPES: _.keyBy(stanzaEventTypes),
	STANZA_ERROR_CONDITIONS: _.keyBy(stanzaErrorConditionTypes),
};
