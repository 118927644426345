import { IonButton, IonModal } from '@ionic/react';
import React from 'react';
import CreateFolderpopup from './CreateFolderpopup';
import './DeleteFileModal.scss';
import store from '../../redux/store';
import { showToast } from '../../redux/actions/global';

interface DeleteFileModalprops {
	show: boolean;
	onCloseCallBack: any;
	type: string;
	item: any;
}
interface iState {
	isDeletePopupView: boolean;
}

class DeleteFileModal extends React.Component<DeleteFileModalprops, iState> {
	constructor(props: any) {
		super(props);

		this.state = {
			isDeletePopupView: false,
		};
	}

	render() {
		return (
			<>
				<IonModal showBackdrop={true} isOpen={this.props.show} onDidDismiss={this.props.onCloseCallBack} swipeToClose={true} cssClass="delete-file-modal" animated={true}>
					<div className="content">
						<div className="storage">Move to trash?</div>
						<div className="storege-msg">Media and File will be deleted forever in 30 days.</div>

						<div className="image-container">
							<>
								{this.props.type === 'folder' ? <img alt="" className="file-image" src={'../assets/img/' + this.props.item?.image} /> : ''}

								{this.props.type === 'image' ? (
									<div className="delete-image">
										<img alt="" className="delete" src={'../assets/img/' + this.props.item?.image} />
									</div>
								) : (
									''
								)}

								{this.props.type === 'video' ? (
									<div className="delete-image">
										<img alt="" className="delete" src={'../assets/img/' + this.props.item?.image} />
									</div>
								) : (
									''
								)}

								{this.props.type === 'file' ? <img alt="" className="file-image" src={'../assets/img/' + this.props.item?.image} /> : ''}
							</>
							{this.props.type === 'folder' || this.props.type === 'file' ? (
								<p className="file-name">
									{this.props.item?.FolderName}
									{this.props.item?.FileName}
								</p>
							) : (
								''
							)}
						</div>

						<div
							className="move-trash"
							onClick={() => {
								store.dispatch(showToast({ toastMessage: '<img src="../assets/icon/right-thread.svg" />   Moved to Trash', toastType: 'secondary', toastButtonText: 'UNDO' }));
								this.props.onCloseCallBack();
							}}
						>
							<IonButton>Move to Trash</IonButton>
						</div>
						<div className="cancel" onClick={this.props.onCloseCallBack}>
							<p> Cancel</p>
						</div>
					</div>
				</IonModal>

				<CreateFolderpopup
					show={this.state.isDeletePopupView}
					onCloseCallBack={() => {
						this.setState({ isDeletePopupView: false });
					}}
				/>
			</>
		);
	}
}

export default DeleteFileModal;
