import { IonPopover, IonLabel, IonList, IonItem, IonIcon } from '@ionic/react';
import React from 'react';
import './ScketchpadShapeStylePopover.scss';

interface IconTextButtonProps {
	popoverEvent?: any;
	list?: any;
	showPopover: boolean;
	selectedValue: any;
	onDismiss?: any;
	onItemSelect?: any;
}
const ScketchpadShapeStylePopover: React.FC<IconTextButtonProps> = ({ list, popoverEvent, showPopover, selectedValue, onDismiss, onItemSelect }) => {
	return (
		<IonPopover cssClass="scketchpad-shapeStyle-popover-style" event={popoverEvent} showBackdrop={false} isOpen={showPopover} onDidDismiss={onDismiss}>
			<IonList lines="none">
				{list &&
					list.map((item: any, key: any) => {
						const active = selectedValue && selectedValue.value === item.value;

						return (
							<IonItem
								key={key}
								detail={false}
								button
								className={active ? 'active' : ''}
								onClick={() => {
									onItemSelect && onItemSelect(item);
								}}
							>
								<IonIcon slot="start" src={active ? item.iconActive : item.icon} style={item.style} />
								<IonLabel>{item.title}</IonLabel>
							</IonItem>
						);
					})}
			</IonList>
		</IonPopover>
	);
};

export default ScketchpadShapeStylePopover;
