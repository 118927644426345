import { IonModal, IonButton } from '@ionic/react';
import React, { useCallback } from 'react';
import './style.scss';
import { connect } from 'react-redux';
import { ReduxStates } from '../types/redux-states';

interface ChatMediaShareOptionModalProps {
	show: boolean;
	onClose?: any;
}
const ChatMediaShareOptionModal: React.FC<ChatMediaShareOptionModalProps> = ({ show, onClose }) => {
	const onCloseCallBack = useCallback(
		(_: any) => {
			onClose && onClose();
		},
		[onClose]
	);

	const moreOptions = [
		{
			title: 'Edit',
			icon: './assets/icon/edit-photo-icon.svg',
			iconWidth: '24px',
			iconHeight: '24px',
		},
		{
			title: 'Send to Notepad',
			icon: './assets/icon/notepad.svg',
			iconWidth: '21.28px',
			iconHeight: '25px',
		},
		{
			title: 'Find in Chat',
			icon: './assets/icon/chat-icon.svg',
			iconWidth: '21.88px',
			iconHeight: '22px',
		},
		{
			title: 'Add to Saved',
			icon: './assets/icon/saved-icon.svg',
			iconWidth: '19.43px',
			iconHeight: '25px',
		},
		{
			title: 'Forward',
			icon: './assets/icon/forward.svg',
			iconWidth: '24.11px',
			iconHeight: '22.38px',
		},
	];

	return (
		<IonModal mode="ios" showBackdrop={true} backdropDismiss={true} onDidDismiss={onCloseCallBack} isOpen={show} swipeToClose={false} cssClass="chatmedia-share-option-modal" animated={true}>
			<div
				className="popup-view-container"
				onClick={(event) => {
					// onCloseCallBack(event);
				}}
			>
				<div className="popup-view" onClick={(event) => event.stopPropagation()}>
					<div className="title">Send To</div>
					<div className="contacts-container">
						{/* {props.chat.conversations?.map((_contact: any, key: any) => {
							return (
								<div className="contact-item" key={key}>
									<IonImg src={_contact.profilePhoto || blankProfilePic} />
									<div className="contact-name">{_contact.userId ? _contact.alias : _contact.groupname}</div>
								</div>
							);
						})} */}
					</div>
					<div className="more-options">
						{moreOptions.map((item, key) => {
							return (
								<div className="more-option-item" key={key}>
									<div className="image-container">
										<img src={item.icon} style={{ width: item.iconWidth, height: item.iconHeight }} alt="icon" />
									</div>
									<div className="option-title">{item.title}</div>
								</div>
							);
						})}
					</div>

					<div className="cancel-btn-container">
						<IonButton fill="clear" onClick={onCloseCallBack}>
							Cancel
						</IonButton>
					</div>
				</div>
			</div>
		</IonModal>
	);
};

const mapStateToProps = (state: ReduxStates) => {
	return {};
};
const mapDispatchToProps = (_: any) => ({});

export default connect(mapStateToProps, mapDispatchToProps)(ChatMediaShareOptionModal);
