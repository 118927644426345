import { IonModal, IonContent, IonButton, IonIcon, IonActionSheet, ActionSheetButton, IonLoading } from '@ionic/react';
import React, { useEffect, useRef, useState } from 'react';
import './style.scss';
import { close } from 'ionicons/icons';
import { locale } from '../../locales/local';
import { ChatMediaType, ChatMessageState, ChatType, MessageType } from '../../services/enumService';
import ImageEditor, { OpenEditorType } from '../ImageEditor/ImageEditor';
import ChatMediaShareOptionModal from '../ChatMediaShareOptionModal';
import { Message } from '../../types/message';
import { Player } from 'video-react';
import 'video-react/dist/video-react.css'; // import css
import { Swiper as SwiperComponent, SwiperSlide } from 'swiper/react';
import 'swiper/swiper-bundle.min.css';
import 'swiper/swiper.min.css';
import { Swiper } from 'swiper/types';
import { xmpp } from '../../services/xmpp';
import moment from 'moment';
import { ChatStateTypes, ProfileStateTypes, ReduxStates } from '../../types/redux-states';
import { connect, useDispatch } from 'react-redux';
import { GroupItem } from '../../types/group';
import { ContactItem } from '../../types/contact';
import { LongpressContainer } from '../../components/LongpressContainer';
import { showStylishToast, showToast } from '../../redux/actions/global';
import { copyImageToClipboard, requestClipboardWritePermission } from 'copy-image-clipboard';
import axios from 'axios';
import UtilService from '../../services/util';
import { info } from '../../helpers/common';

const PhotoEditIcon = './assets/icon/photo-edit-icon.svg';
const PhotoDownloadIcon = './assets/icon/photo-downlooad-icon.svg';
const PhotoGridIcon = './assets/icon/photo-grid-icon.svg';
const PhotoMenuIcon = './assets/icon/photo-menu-icon.svg';
const CloseIcon = './assets/icon/close-icon-outline.svg';

interface MediaPreviewModalProps {
	chat?: ChatStateTypes;
	profile?: ProfileStateTypes;

	show: boolean;
	selectedMediaMessageKey: any;
	onClose?: any;
	onViewAllMediaClick?: any;
	onSend?: any;
}

const MediaPreviewModal: React.FC<MediaPreviewModalProps> = ({ selectedMediaMessageKey, chat, profile, show, onClose, onViewAllMediaClick }) => {
	const dispatch = useDispatch();

	const [isDownloading, setIsDownloading] = useState(false),
		[showPhotoEditor, setShowPhotoEditor] = useState(false),
		[showChatMediaShareModal, setShowChatMediaShareModal] = useState(false),
		[actionSheetBtns, setActionSheetBtns] = useState<(string | ActionSheetButton)[]>([]),
		[showActionSheet, setShowActionSheet] = useState(false),
		[openModal, setOpenModal] = useState(false),
		[isSave, setIsSave] = useState(false),
		[mediaMessages, setMediaMessages] = useState<Array<Message>>(),
		[slideIntialIndex, setSlideIntialIndex] = useState<number>(-1);

	useEffect(() => {
		if (!show && openModal) {
			setOpenModal(false);
			setMediaMessages([]);
			setSlideIntialIndex(-1);

			const slidesElem: any = document.getElementById('slidesId');
			if (slidesElem) {
				slidesElem.style.opacity = 0;
			}
		} else {
			if (!openModal && show) {
				setOpenModal(true);
				const filteredMessages = chat?.history?.filter((item: Message) => item?.messageType === MessageType.MEDIA || (item?.messageType === MessageType.FILE && (item?.media?.mediaType === ChatMediaType.IMAGE || item?.media?.mediaType === ChatMediaType.VIDEO)));
				const currentMessageIndex = filteredMessages?.findIndex((message: Message) => message.messageKey === selectedMediaMessageKey);
				setSlideIntialIndex(currentMessageIndex!);
				setMediaMessages(filteredMessages);

				setTimeout(() => {
					const slidesElem: any = document.getElementById('slidesId');
					if (slidesElem) {
						slidesElem.style.opacity = 1;
					}
				}, 500);
			}
		}
	}, [show, openModal, chat]);

	const _sendToChat = async (mediaFiles: Array<File> = [], isFullImage: boolean, type: string) => {
		let mediaResolutions: any = [],
			isImageVideo: Boolean = true;
		let files: any = [];
		const _mediaResolutions: any = [];

		let messageType: any,
			messageState = ChatMessageState.ORIGINAL,
			relatedMessageId = '';
		if (isImageVideo) {
			messageType = MessageType.MEDIA;
			for (let index in mediaFiles) {
				let outputFile: File | null = null;

				const media = mediaFiles[index],
					mediaResolution = parseInt(index) === 0 ? mediaResolutions[index] : 0; // Remove this condition when image editor impletmented or multiple images

				// Remove this condition when image editor impletmented or multiple images
				if (parseInt(index) === 0) {
					_mediaResolutions.push(mediaResolution === 0 ? 'full image' : 'reduced image');
				} else {
					// No need this after image editor implemented for multiple images
					_mediaResolutions.push('full image');
				}

				if (mediaResolution !== 0) {
					outputFile = media;
				} else {
					outputFile = media;
				}

				if (outputFile) {
					files.push(outputFile);
				}
			}
		} else {
			files = mediaFiles;
		}
		let receiverGroupid = (chat?.receiver as GroupItem)?.name || (chat?.receiver as ContactItem)?.username;
		let receiverId = type === 'Send to chat' ? receiverGroupid : profile?.loggedInUserProfile?.username!;

		let conversationType = chat?.type === 'chat' ? ChatType.P2P : ChatType.GROUP;
		await xmpp.sendMessage({
			messageBody: files,
			messageState: messageState,
			messageType: messageType,
			relatedMessageId: relatedMessageId,
			conversationType: conversationType,
			receiverId,
			isShareContact: true,
		});

		onClose();
	};

	const download = (mediaMessages: any = {}) => {
		{
			mediaMessages.map((message: any, key: any) => {
				if (key == slideIntialIndex) {
					const image = message.media?.mediaFile;
					const extension = image.split('.').pop();
					const a = document.createElement('a');
					a.href = image;
					a.download = `${moment().unix()}.${extension}`;
					a.click();
				}
				setIsSave(false);

				dispatch(
					showStylishToast({
						stylishToastMessage: 'Saved',
						stylishToastIcon: 'check',
					})
				);
			});
		}
	};
	return (
		<>
			<div className="all-media-preview">
				{mediaMessages?.map((message: any, key: any) => {
					return (
						<SwiperSlide key={key}>
							{message.media?.mediaType === ChatMediaType.VIDEO && (
								<div className="video-container" onClick={(e) => e.stopPropagation()}>
									<Player autoPlay={false} poster={message.media?.thumbnail} preload={'metadata'} src={message.media?.mediaFile} />
								</div>
							)}
							{message.media?.mediaType === ChatMediaType.IMAGE && <img src={message.media?.thumbnail || message.media?.mediaFile} alt={'image'} />}
						</SwiperSlide>
					);
				})}
			</div>

			<IonModal cssClass="media-preview-modal" isOpen={openModal} onDidDismiss={() => onClose()} swipeToClose={false} showBackdrop={false}>
				<div className="header-btns">
					<div className="right">
						{isDownloading ? (
							<IonButton fill="clear" className="btn download-progress-btn">
								<div className="percent-text">30%</div>
								<IonButton fill="clear" onClick={() => setIsDownloading(false)}>
									<IonIcon slot="icon-only" icon={close} />
								</IonButton>
							</IonButton>
						) : (
							<IonButton fill="clear" className="btn" onClick={() => setIsDownloading(true)}>
								{locale.photo.photo_viewer_modal.full_image} (1.8M)
							</IonButton>
						)}
					</div>
				</div>

				<IonContent>
					<div className="image-container" onClick={onClose}>
						{mediaMessages && mediaMessages.length > 0 && slideIntialIndex >= 0 && <SwiperView slideIntialIndex={slideIntialIndex} mediaMessages={mediaMessages} />}
					</div>
				</IonContent>

				<div className="photo-options-btns-container">
					<div className="btns">
						<IonButton fill="clear" slot="icon-only" onClick={onClose}>
							<IonIcon className="close-icon" src={CloseIcon} />
						</IonButton>

						<div style={{ flex: 1 }}></div>

						<IonButton fill="clear" slot="icon-only" onClick={() => setShowPhotoEditor(true)}>
							<IonIcon src={PhotoEditIcon} />
						</IonButton>
						<IonButton
							fill="clear"
							slot="icon-only"
							onClick={() => {
								setIsSave(true);
								download(mediaMessages);
							}}
						>
							<IonIcon src={PhotoDownloadIcon} style={{ width: '19px', height: '24px' }} />
						</IonButton>
						<IonButton fill="clear" slot="icon-only" onClick={onViewAllMediaClick}>
							<IonIcon src={PhotoGridIcon} />
						</IonButton>
						<IonButton
							fill="clear"
							slot="icon-only"
							onClick={() => {
								setShowChatMediaShareModal(true);
							}}
						>
							<IonIcon src={PhotoMenuIcon} />
						</IonButton>
					</div>
				</div>

				<IonActionSheet mode="md" isOpen={showActionSheet} onDidDismiss={() => setShowActionSheet(false)} cssClass={'photo-preview-menu-actionsheet'} buttons={actionSheetBtns}></IonActionSheet>

				<ImageEditor
					openEditorType={OpenEditorType.FOR_EDIT_IMAGE}
					selectedImage={showPhotoEditor && mediaMessages ? mediaMessages[slideIntialIndex]?.media?.mediaFile || mediaMessages[slideIntialIndex]?.media?.thumbnail : null}
					show={showPhotoEditor}
					onClose={() => {
						setShowPhotoEditor(false);
					}}
					onSave={(file: any, isFullImage: boolean) => {
						setActionSheetBtns([
							{
								text: 'Send to chat',
								handler: () => {
									_sendToChat([file], isFullImage, 'Send to chat');

									setShowActionSheet(false);
								},
							},
							{
								text: 'Bookmark',
								handler: () => {
									setShowActionSheet(false);
								},
							},
							{
								text: 'Send to Notepad',
								handler: () => {
									_sendToChat([file], isFullImage, 'Send to Notepad');
									setShowActionSheet(false);
								},
							},
							{
								text: 'Cancel',
								cssClass: 'action-sheet-group-cancel',
								handler: () => {
									setShowActionSheet(false);
								},
							},
						]);
						setShowActionSheet(true);
					}}
				/>

				<ChatMediaShareOptionModal
					show={showChatMediaShareModal}
					onClose={() => {
						setShowChatMediaShareModal(false);
					}}
				/>
				<IonLoading
					isOpen={isSave}
					onDidDismiss={() => {
						setIsSave(false);
					}}
					message={'Saving...'}
				/>
			</IonModal>
		</>
	);
};

const SwiperView = ({ slideIntialIndex, mediaMessages }: any) => {
	const dispatch = useDispatch();

	const slidesRef = useRef<Swiper>();

	useEffect(() => {
		document.onkeydown = checkKey;
		function checkKey(e: any) {
			e = e || window.event;
			if (e.keyCode == '37') {
				// left arrow
				slidesRef.current?.slidePrev();
			} else if (e.keyCode == '39') {
				// right arrow
				slidesRef.current?.slideNext();
			}
		}
	}, [slidesRef]);

	return (
		<>
			<SwiperComponent
				initialSlide={slideIntialIndex}
				spaceBetween={0}
				slidesPerView={1}
				onSwiper={(swiper) => {
					slidesRef.current = swiper;
				}}
			>
				{mediaMessages.map((message: any, key: any) => {
					return (
						<SwiperSlide key={key}>
							{message.media?.mediaType === ChatMediaType.VIDEO && (
								<div className="video-container" onClick={(e) => e.stopPropagation()}>
									<Player autoPlay={false} poster={message.media?.thumbnail} preload={'metadata'} src={message.media?.mediaFile} />
								</div>
							)}
							{message.media?.mediaType === ChatMediaType.IMAGE && (
								<LongpressContainer
									className="longpress-container-img"
									onLongPress={async () => {
										const response = await axios.get(message.media?.mediaFile, {
											headers: {},
											responseType: 'blob', // Important
										});

										const blob = new Blob([response.data], { type: UtilService.fileMimeTypes[UtilService.get_url_extension(message.media?.mediaFile)!] });
										const blobUrl = URL.createObjectURL(blob);

										// message.media?.mediaFile
										// Can be an URL too, but be careful because this may cause CORS errors
										requestClipboardWritePermission().then((hasPermission) => {
											if (hasPermission) {
												copyImageToClipboard(blobUrl)
													.then(() => {
														info('Image Copied');
														setTimeout(() => {
															dispatch(
																showStylishToast({
																	stylishToastMessage: 'Copied',
																	stylishToastIcon: 'check',
																})
															);
														}, 500);
													})
													.catch((e) => {
														info('Error: ', e.message);
													});
											} else {
												info('Clipboard write permission denied');
												dispatch(
													showToast({
														toastMessage: 'Clipboard write permission denied',
													})
												);
											}
										});
									}}
								>
									<img src={message.media?.thumbnail || message.media?.mediaFile} alt={'image'} />
								</LongpressContainer>
							)}
						</SwiperSlide>
					);
				})}
			</SwiperComponent>
		</>
	);
};

const mapStateToProps = (state: ReduxStates) => {
	return {
		chat: state.chat,
		profile: state.profile,
	};
};

const mapDispatchToProps = (_: any) => ({});

export default connect(mapStateToProps, mapDispatchToProps)(MediaPreviewModal);
