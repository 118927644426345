import React, { useCallback, useState } from 'react';
import { IonIcon, IonLabel, IonTabBar, IonTabButton } from '@ionic/react';
import './style.scss';
import { LongpressContainer } from '../../../components/LongpressContainer';
import QuickAccessModal from '../../quickaccess/QuickAccessModal';
import { connect } from 'react-redux';
import { locale } from '../../../locales/local';
import NotificationBadge from '../../../components/NotificationBadge';
import { Link } from 'react-router-dom';
import QuickAccessPopup from '../../quickaccess/QuickAccessPopup';
import { ContactStateTypes, ProfileStateTypes, ReduxStates } from '../../../types/redux-states';
import { UpdateDotsData } from '../../../types/api-requests';
import { updateDots } from '../../../redux/actions/profile';
import { info } from '../../../helpers/common';

interface ContainerProps {
	updateDots?: (payload: UpdateDotsData) => void;
	profile?: ProfileStateTypes;
	contact?: ContactStateTypes;
	unreadCount?: number;
	history?: any;
	location?: any;
	dashboard?: any;
}
const defaultProps: ContainerProps = {
	unreadCount: 0,
};
var isPlusButtonLongress = false;

export const BottomNavbar: React.FC<ContainerProps> = (props: ContainerProps = {}) => {
	const [showQuickAccessModal, setShowQuickAccessModal] = useState(false);
	const [showQuickAccessPopup, setShowQuickAccessPopup] = useState(false);
	const [showTagspage] = useState(false);

	const extractPath = props.location?.pathname?.replace(/^[/]/, '');

	// const newContactRequestCount = 0;
	// useMemo(() => {
	// let newContacts = props.dashboard?.conversations ? props.dashboard.conversations?.filter((_conversation: any) => _conversation.status === 'pendingConfirm') : null;
	// if (!newContacts || newContacts.length === 0) {
	// 	// newContacts = props.dashboard.loggedInUser ? props.dashboard.loggedInUser?.contacts?.filter((_contact: any) => _contact.status === 'pendingConfirm') : null;
	// }
	// return newContacts || [];
	// }, [props]).length || 0;

	const _handleTabChange = useCallback((data: UpdateDotsData) => {
		props.updateDots && props.updateDots(data);
	}, []);

	return (
		<IonTabBar
			slot="bottom"
			className={'bottom-tabs-bar '}
			onIonTabsDidChange={(event) => {
				info('TabsDidchange', event);
			}}
		>
			<IonTabButton tab="auth" href="/auth" className={'bottom-tabs ' + (extractPath === 'auth' ? 'tab-selected' : '')}>
				<IonIcon className="ionIcon1" src="/assets/icon/tab-chat-selected.svg" color="primary"></IonIcon>
				<IonIcon className="ionIcon2" src="/assets/icon/tab-chat.svg" color="primary"></IonIcon>
				<IonLabel>{locale.bottom_navbar.titles.chat}</IonLabel>
				<NotificationBadge unreadCount={props.unreadCount} />
			</IonTabButton>
			<IonTabButton tab="contacts" href="/contacts" className={'bottom-tabs ' + (extractPath === 'contacts' ? 'tab-selected' : '')} onClick={() => !props.profile?.loggedInUserProfile?.contactDot && _handleTabChange({ contact: true })}>
				<IonIcon className="ionIcon1" src="/assets/icon/tab-contacts-selected.svg"></IonIcon>
				<IonIcon className="ionIcon2" src="/assets/icon/tab-contacts.svg"></IonIcon>
				<IonLabel>{locale.bottom_navbar.titles.contacts}</IonLabel>
				{!props.profile?.loggedInUserProfile?.contactDot && <NotificationBadge className="dot-circle" showOnlyCircle={true} />}
				<NotificationBadge unreadCount={props.contact?.receivedRequests?.length} />
			</IonTabButton>

			<IonTabButton className={'bottom-tabs large-btn ' + (extractPath === 'quick-access' || showQuickAccessModal ? 'tab-selected' : '')}>
				<Link to="#">
					<LongpressContainer
						threshold={500}
						onLongPress={() => {
							isPlusButtonLongress = true;
							// props.history?.push('/quick-access-qrcode', { showMyQrCodeOnly: true });
							info('onLongPress');
							setShowQuickAccessModal(true);
						}}
						onFinish={() => {
							info('onLongPress Finish');
							// setShowQuickAccessModal(false);
							setTimeout(() => {
								isPlusButtonLongress = false;
							}, 2000);
						}}
						onCancel={() => {
							info('onLongPress Cancel');
							setShowQuickAccessModal(false);
							isPlusButtonLongress = false;
						}}
						onClick={() => {
							!props.profile?.loggedInUserProfile?.discoverDot && _handleTabChange({ hotkey: true });
							setShowQuickAccessModal(false);
							if (!isPlusButtonLongress) {
								//   if (props.location?.pathname !== '/quick-access') {
								setShowQuickAccessPopup(true);
								//   }
							}
						}}
					>
						<IonIcon className="ionIcon1" src="/assets/icon/tab-add-selected.svg"></IonIcon>
						<IonIcon className="ionIcon2" src="/assets/icon/tab-add.svg"></IonIcon>
					</LongpressContainer>
				</Link>
				{!props.profile?.loggedInUserProfile?.discoverDot && <NotificationBadge className="dot-circle" showOnlyCircle={true} />}{' '}
			</IonTabButton>

			<IonTabButton
				tab="discover"
				href="/discover"
				onClick={() => {
					setShowQuickAccessPopup(false);
					!props.profile?.loggedInUserProfile?.discoverDot && _handleTabChange({ discover: true });
				}}
				className={'bottom-tabs ' + (extractPath === 'discover' ? 'tab-selected' : '')}
			>
				<IonIcon className="ionIcon1" src="/assets/icon/tab-discover-selected.svg"></IonIcon>
				<IonIcon className="ionIcon2" src="/assets/icon/tab-discover.svg"></IonIcon>
				<IonLabel>{locale.bottom_navbar.titles.discover}</IonLabel>
				{!props.profile?.loggedInUserProfile?.discoverDot && <NotificationBadge className="dot-circle" showOnlyCircle={true} />}{' '}
			</IonTabButton>
			<IonTabButton tab="profile" href="/profile" className={'bottom-tabs ' + (extractPath === 'profile' ? 'tab-selected' : '')} onClick={() => !props.profile?.loggedInUserProfile?.meDot && _handleTabChange({ me: true })}>
				<IonIcon className="ionIcon1" src="/assets/icon/tab-me-selected.svg"></IonIcon>
				<IonIcon className="ionIcon2" src="/assets/icon/tab-me.svg"></IonIcon>
				<IonLabel>{locale.bottom_navbar.titles.me}</IonLabel>
				{!props.profile?.loggedInUserProfile?.meDot && <NotificationBadge className="dot-circle" showOnlyCircle={true} />}{' '}
			</IonTabButton>

			<QuickAccessModal
				loggedInUserProfile={props.profile?.loggedInUserProfile!}
				show={showQuickAccessModal}
				onClose={() => {
					setShowQuickAccessModal(false);
				}}
			/>

			<QuickAccessPopup
				props={props}
				showTagOnly={showTagspage}
				show={showQuickAccessPopup}
				onClose={() => {
					setShowQuickAccessPopup(false);
				}}
			/>
		</IonTabBar>
	);
};

BottomNavbar.defaultProps = defaultProps;

const mapStateToProps = (state: ReduxStates) => {
	return {
		contact: state.contact,
		profile: state.profile,
	};
};

const mapDispatchToProps = (dispatch: any) => ({
	updateDots: (payload: UpdateDotsData) => dispatch(updateDots(payload)),
});

export default connect(mapStateToProps, mapDispatchToProps)(BottomNavbar);
