import { IonButton, IonButtons, IonIcon, IonTitle, IonToolbar } from '@ionic/react';
import React, { useEffect, useState } from 'react';
import './style.scss';
import { ChatMediaType, ChatMessageState, MessageType, MessageSendStatus, PubSubEventType, ChatType } from '../../../../services/enumService';

import { Message } from '../../../../types/message';
import PubSub from 'pubsub-js';
import { ProfileStateTypes, ReduxStates } from '../../../../types/redux-states';
import { connect } from 'react-redux';

const TrashIcon = './assets/icon/popup-delete.svg';
const ThreadMenu = './assets/icon/thread-menu.svg';
const SaveIcon = './assets/icon/downloadThread.svg';
const SendIcon = './assets/icon/sendthread.svg';
const noteIcon = './assets/icon/send-notepad.svg';
const DeleteLight = '../assets/icon/popup-delete-light.svg';
const SaveLight = '../assets/icon/save-light.svg';
const NotePadLight = '../assets/icon/select-notepad-light.svg';
const ThreadLight = '../assets/icon/thread-light2.svg';
const SendLight = '../assets/icon/send-light2.svg';

interface iProps {
	profile?: ProfileStateTypes;

	threadCount: number;
	onOption?: (OptionValue: string) => void;
}

const ChatSelectView: React.FC<iProps> = ({ profile, onOption, threadCount }) => {
	const [count, setCount] = React.useState(0);
	const [allMsgLength, setAllMsgLength] = React.useState(0);
	const [messageType, setMessageType] = React.useState('');
	const [messageTypeAudio, setMessageTypeAudio] = React.useState('');
	let messageFrom: string = '';
	let loginUserName: string = '';
	useEffect(() => {
		const selectedMsgLengthPubSubToken = PubSub.subscribe(PubSubEventType.SelectedMsgLength, (msg: string, data: any) => {
			if (data.action === PubSubEventType.SelectedMsgLength) {
				let countLength = data.selectedMessageLength;
				let totalMsgLength = data.allMsgLength;
				setCount(countLength);
				setAllMsgLength(totalMsgLength);
				setMessageType(data.message?.messageType);
				setMessageTypeAudio(data.message?.media?.mediaType);
				messageFrom = data.message?.from;
				loginUserName = profile?.loggedInUserProfile?.username!;
			}
		});

		return () => {
			PubSub.unsubscribe(selectedMsgLengthPubSubToken);
		};
	}, [count, allMsgLength, messageType, messageTypeAudio, profile]);
	return (
		<>
			<div className="chat-select-view">
				<IonToolbar>
					{allMsgLength !== 0 && count !== 0 && count > 1 ? (
						<IonTitle className="itemCount" slot="start">
							{count} items
						</IonTitle>
					) : (
						count > 0 && (
							<IonTitle className="itemCount" slot="start">
								{count} item
							</IonTitle>
						)
					)}
					<IonButtons className="image-container">
						{(messageFrom === loginUserName || count || allMsgLength) && (
							<IonButton
								fill="clear"
								className="image-button"
								onClick={() => {
									onOption && onOption('delete');
								}}
								disabled={count === 0}
							>
								<IonIcon slot="icon-only" src={count > 0 ? TrashIcon : DeleteLight} />
							</IonButton>
						)}
						{(allMsgLength === 0 || count < 10 || allMsgLength !== count) && (
							<IonButton
								fill="clear"
								className="image-button"
								onClick={() => {
									onOption && onOption('save');
								}}
								disabled={count === 0}
							>
								<IonIcon slot="icon-only" src={count > 0 ? SaveIcon : SaveLight} />
							</IonButton>
						)}
						{messageTypeAudio !== ChatMediaType.AUDIO && (count < 10 || allMsgLength === 0 || messageFrom !== loginUserName || allMsgLength !== count) && (
							<IonButton
								fill="clear"
								className="image-button"
								onClick={() => {
									onOption && onOption('notepad');
								}}
								disabled={count === 0}
							>
								<IonIcon slot="icon-only" src={count > 0 ? noteIcon : NotePadLight} />
							</IonButton>
						)}

						{threadCount > 1 && (
							<IonButton
								fill="clear"
								className="image-button"
								onClick={() => {
									onOption && onOption('threadmenu');
								}}
								disabled={count === 0}
							>
								<IonIcon slot="icon-only" src={count > 0 ? ThreadMenu : ThreadLight} />
							</IonButton>
						)}

						{(messageTypeAudio !== ChatMediaType.AUDIO || messageFrom !== loginUserName) && (allMsgLength === 0 || count < 10 || allMsgLength !== count) && (
							<IonButton
								fill="clear"
								className="image-button"
								onClick={() => {
									onOption && onOption('send');
									setAllMsgLength(0);
									setCount(0);
								}}
								disabled={count === 0}
							>
								<IonIcon slot="icon-only" src={count > 0 ? SendIcon : SendLight} />
							</IonButton>
						)}
					</IonButtons>
				</IonToolbar>
			</div>
		</>
	);
};

const mapStateToProps = (state: ReduxStates) => {
	return {
		profile: state.profile,
	};
};
const mapDispatchToProps = (dispatch: any) => ({});

export default connect(mapStateToProps, mapDispatchToProps)(ChatSelectView);
