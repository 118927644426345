import { IonModal } from '@ionic/react';
import React from 'react';
import './InfoModal.scss';

interface InfoModalProps {
	show: boolean;
	onCloseCallBack: any;
}
interface iState {}

class InfoModal extends React.Component<InfoModalProps, iState> {
	// constructor(props: any) {
	// 	super(props);
	// }

	render() {
		return (
			<>
				<IonModal showBackdrop={true} isOpen={this.props.show} onDidDismiss={this.props.onCloseCallBack} swipeToClose={true} cssClass="info-modal" animated={true}>
					<div className="content">
						<div className="sharefolder">
							<div className="shareicon">
								<img alt="" className="file-image" src={'../assets/icon/AddContact.svg'} />
							</div>

							<div className="sharename">Share</div>
						</div>
						<div className="sharefolder">
							<div className="shareicon">
								<img alt="" className="file-image" src={'../assets/icon/links-search.svg'} />
							</div>

							<div className="sharename">Get Link</div>
						</div>
						<div className="sharefolder">
							<div className="shareicon">
								<img alt="" className="file-image" src={'../assets/icon/wright.svg'} />
							</div>

							<div className="sharename">Rename</div>
						</div>
						<div className="sharefolder">
							<div className="shareicon">
								<img alt="" className="file-image" src={'../assets/icon/Download.svg'} />
							</div>

							<div className="sharename">Download</div>
						</div>
						<div className="sharefolder">
							<div className="shareicon">
								<img alt="" className="file-image" src={'../assets/icon/Deleteg.svg'} />
							</div>

							<div className="sharename">Delete</div>
						</div>

						<div className="cancel" onClick={this.props.onCloseCallBack}>
							<p> Cancel</p>
						</div>
					</div>
				</IonModal>
			</>
		);
	}
}

export default InfoModal;
