import React, { useCallback, useEffect, useRef, useState } from 'react';
import { peopleOutline, personAddOutline, settingsOutline, heartOutline, listOutline, ellipsisHorizontal } from 'ionicons/icons';
import { IonToolbar, IonTitle, IonList, IonItem, IonButtons, IonButton, IonIcon, IonLabel, IonSearchbar, IonPopover, IonImg } from '@ionic/react';
import './style.scss';
import { AddMembersPageActionType, NavigationRoutes, ProfileFriendActionTypes } from '../../../services/enumService';
import DropdownPopover from '../../../components/DropdownPopover';
import FavoriteSearchModal from '../../../modals/favoriteSearchModal';
import CustomSpinner from '../../../components/CustomSpinner';
import { locale } from '../../../locales/local';
import { logoutUser } from '../../../helpers/common';
import NotificationBadge from '../../../components/NotificationBadge';
import { ProfilePhotoItem, UserProfile } from '../../../types/profile';
import SharedService from '../../../services/sharedService';
import { ContactItem } from '../../../types/contact';
import { GroupItem } from '../../../types/group';
import { connect } from 'react-redux';
import { ContactStateTypes, ProfileStateTypes, ReduxStates } from '../../../types/redux-states';
import { Navigation } from '../../../services/navigationService';

const BackBlackIcon = './assets/icon/back-black.svg';
const InfoIcon = './assets/icon/info.svg';
const ProfileIcon = './assets/img/default-profile-photo.png';
const AddPlusIcon = './assets/icon/addicon.svg';
const AddContactIcon = './assets/icon/add-contact-icon.svg';
const SearchIcon = './assets/icon/search-icon.svg';
const checkedIcon = './assets/icon/selectRightIconUnselected.svg';
const checkIcon = './assets/icon/selectedRightIcon.svg';

interface ContainerProps {
	containerClassName?: string;
	showUnViewDotOnLeftIcon?: boolean;
	isLoading?: boolean;
	loadingText?: string;
	showBack?: boolean;
	hideSearchBar?: boolean;
	showSearchIcon?: boolean;
	isHideLeftButton?: boolean;
	isHideRightButton?: boolean;
	leftButtonIcon?: any;
	leftButtonText?: string;
	rightButtonDisabled?: boolean;
	rightButtonText?: string;
	rightButtonColor?: {};
	rightButtonIcon?: any; //it supports IonIcon or icon path
	history: any;
	friendProfile?: UserProfile;
	groupInfo?: GroupItem;
	contactItem?: ContactItem;
	pageTitle: String;
	onTitlePress?: Function;
	pageTitleDescription?: String;
	searchbarPlaceholder?: string;
	showSearchbarCancelButton?: boolean;
	unreadCount?: number;
	searchHandler?: Function;
	searchTypeHandler?: Function;
	onSearchBarFocus?: Function;
	onSearchBarCancelPress?: Function;
	onLeftButtonPress?: Function;
	onRightButtonPress?: Function;
	onDropDownOptionPress?: Function;
	onSearchIconPress?: Function;
	appendAdditionalViewOnBottom?: any;
	isSearchButtonActive?: boolean;
	isRightButtonActive?: boolean;

	profile?: ProfileStateTypes;
	contact?: ContactStateTypes;
	dashboard?: any;
}

const menuOptions: any = {
	[NavigationRoutes.DASHBOARD]: [
		{
			title: locale.topbar.placeholder.new_group,
			svgIcon: 'group-create.svg',
			width: '28px',
			height: '20px',
			type: 'createGroup',
		},
		{
			title: locale.topbar.placeholder.add_contact,
			svgIcon: 'add-contact-icon.svg',
			width: '33px',
			height: '20px',
			type: 'addContact',
		},
		{
			title: locale.topbar.placeholder.saved,
			svgIcon: 'saved-icon.svg',
			width: '20px',
			height: '18px',
			type: 'saved',
		},
		{
			title: locale.topbar.placeholder.pay,
			svgIcon: 'pay.svg',
			type: 'pay',
		},
		{
			title: locale.topbar.placeholder.privacy,
			svgIcon: 'privacy.svg',
			type: 'myprivacy',
		},
		{
			title: locale.topbar.placeholder.settings,
			svgIcon: 'setting.svg',
			width: '20px',
			height: '20px',
			type: 'settings',
		},
	],
	[NavigationRoutes.PROFILE || NavigationRoutes.My_PROFILE]: [
		{
			title: locale.topbar.placeholder.edit_profile,
			svgIcon: 'edit-profile.svg',
			type: 'editProfile',
		},
		{
			title: locale.topbar.placeholder.view_as,
			svgIcon: 'eye.svg',
			type: 'viewAs',
		},
		{
			title: locale.topbar.placeholder.pay,
			svgIcon: 'pay.svg',
			type: 'pay',
		},
		{
			title: locale.topbar.placeholder.saved,
			svgIcon: 'saved-icon.svg',
			type: 'saved',
		},
		{
			title: locale.topbar.placeholder.privacy,
			svgIcon: 'privacy.svg',
			type: 'myprivacy',
		},
		{
			title: locale.topbar.placeholder.settings,
			svgIcon: 'setting.svg',
			type: 'settings',
		},
		{
			title: locale.topbar.placeholder.logout,
			svgIcon: 'signout.svg',
			type: 'logout',
		},
	],
	[NavigationRoutes.PROFILE_FRIEND]: [
		// {
		// 	title: locale.topbar.placeholder.view_profile,
		// 	svgIcon: 'edit-profile.svg',
		// 	width: '24px',
		// 	height: '24px',
		// 	type: 'viewprofile',
		// },
		{
			title: locale.topbar.placeholder.manage_contact,
			svgIcon: 'information.svg',
			width: '24px',
			height: '24px',
			type: 'manageContacts',
		},
		{
			title: locale.topbar.placeholder.create_group,
			svgIcon: 'addcontact-light.svg',
			width: '28.36px',
			height: '18.55px',
			type: 'createGroup',
		},

		{
			title: locale.topbar.placeholder.saved,
			svgIcon: 'saved-light.svg',
			width: '27.5px',
			height: '22px',
			type: 'pay',
		},

		{
			title: locale.topbar.placeholder.threads,
			svgIcon: 'threads.svg',
			width: '22px',
			height: '22px',
			type: 'thread',
		},
		{
			title: locale.topbar.placeholder.to_dos,
			svgIcon: 'todo.svg',
			width: '22px',
			height: '22px',
			type: 'todo',
		},
		{
			title: locale.topbar.placeholder.privacy,
			svgIcon: 'eye-icon.svg',
			width: '26px',
			height: '26px',
			type: 'userprivacy',
		},
		{
			title: locale.topbar.placeholder.share,
			svgIcon: 'share-light-icon.svg',
			width: '22px',
			height: '22px',
			type: 'shareContact',
		},
	],
	[NavigationRoutes.My_PROFILE]: [
		// {
		// 	title: locale.topbar.placeholder.view_profile,
		// 	svgIcon: 'edit-profile.svg',
		// 	width: '24px',
		// 	height: '24px',
		// 	type: 'viewprofile',
		// },
		{
			title: locale.topbar.placeholder.manage_contact,
			svgIcon: 'information.svg',
			width: '24px',
			height: '24px',
			type: 'manageContacts',
		},
		{
			title: locale.topbar.placeholder.create_group,
			svgIcon: 'addcontact-light.svg',
			width: '28.36px',
			height: '18.55px',
			type: 'createGroup',
		},

		{
			title: locale.topbar.placeholder.saved,
			svgIcon: 'saved-light.svg',
			width: '27.5px',
			height: '22px',
			type: 'pay',
		},

		{
			title: locale.topbar.placeholder.threads,
			svgIcon: 'threads.svg',
			width: '22px',
			height: '22px',
			type: 'thread',
		},
		{
			title: locale.topbar.placeholder.to_dos,
			svgIcon: 'todo.svg',
			width: '22px',
			height: '22px',
			type: 'todo',
		},
		{
			title: locale.topbar.placeholder.privacy,
			svgIcon: 'eye-icon.svg',
			width: '26px',
			height: '26px',
			type: 'userprivacy',
		},
		{
			title: locale.topbar.placeholder.share,
			svgIcon: 'share-light-icon.svg',
			width: '22px',
			height: '22px',
			type: 'shareContact',
		},
	],

	[NavigationRoutes.PERSONAL_STORAGE]: [
		{
			title: locale.topbar.placeholder.recent,
			svgIcon: 'recent.svg',
			width: '24px',
			height: '24px',
			type: 'recent',
		},
		{
			title: locale.topbar.placeholder.trash,
			svgIcon: 'trash.svg',
			width: '28.36px',
			height: '18.55px',
			type: 'trash',
		},

		{
			title: locale.topbar.placeholder.settings,
			svgIcon: 'setting.svg',
			width: '27.5px',
			height: '22px',
			type: 'setting',
		},

		{
			title: locale.topbar.placeholder.information,
			svgIcon: 'info.svg',
			width: '22px',
			height: '22px',
			type: 'information',
		},
		{
			title: 'Buy storage',
			className: 'buy-storage',
		},
	],

	[NavigationRoutes.GROUP_INFO]: [
		{
			title: 'Chat	',
			svgIcon: 'message.svg',
			type: 'chat',
			width: '26px',
			height: '26px',
		},
		{
			title: 'Search',
			width: '20px',
			height: '21px',
			svgIcon: 'search-gray.svg',
			type: 'search',
		},
		{
			width: '26px',
			height: '26px',
			title: 'Bookmarks',
			svgIcon: 'saved-light.svg',
			type: 'saved-light',
		},
		{
			width: '26px',
			height: '26px',

			title: 'Threads',
			svgIcon: 'threads.svg',
			type: 'information',
		},
		{
			title: 'To Do`s',
			svgIcon: 'todo.svg',
			type: 'todo',
			width: '26px',
			height: '26px',
		},
		{
			title: 'QR Code',
			svgIcon: 'qrcode.svg',
			type: 'qrcode',
			width: '26px',
			height: '26px',
		},
	],
	[NavigationRoutes.MANAGE_GROUP]: [
		{
			title: 'Chat	',
			svgIcon: 'message.svg',
			type: 'message',
			width: '26px',
			height: '26px',
		},
		{
			title: 'Group Info',
			width: '20px',
			height: '21px',
			svgIcon: 'info.svg',
			type: 'group-info',
		},
		{
			title: 'Search',
			width: '20px',
			height: '21px',
			svgIcon: 'search-gray.svg',
			type: 'search',
		},
		{
			width: '26px',
			height: '26px',
			title: 'Bookmarks',
			svgIcon: 'saved-light.svg',
			type: 'saved-light',
		},
		{
			width: '26px',
			height: '26px',

			title: 'Threads',
			svgIcon: 'threads.svg',
			type: 'information',
		},
		{
			title: 'To Do`s',
			svgIcon: 'todo.svg',
			type: 'todo',
			width: '26px',
			height: '26px',
		},
		{
			title: 'QR Code',
			svgIcon: 'qrcode.svg',
			type: 'qrcode',
			width: '26px',
			height: '26px',
		},
	],

	[NavigationRoutes.CHAT]: [
		{
			title: locale.topbar.placeholder.view_profile,
			svgIcon: 'edit-profile.svg',
			width: '24px',
			height: '24px',
			type: 'view-profile',
		},
		{
			title: locale.topbar.placeholder.manage_contact,
			svgIcon: 'information.svg',
			width: '24px',
			height: '24px',
			type: 'manageContacts',
		},
		{
			title: locale.topbar.placeholder.create_group,
			svgIcon: 'addcontact-light.svg',
			width: '28.36px',
			height: '18.55px',
			type: 'createGroup',
		},

		{
			title: locale.topbar.placeholder.saved,
			svgIcon: 'saved-light.svg',
			width: '27.5px',
			height: '22px',
			type: 'pay',
		},

		{
			title: locale.topbar.placeholder.threads,
			svgIcon: 'threads.svg',
			width: '22px',
			height: '22px',
			type: 'threads',
		},
		{
			title: locale.topbar.placeholder.to_dos,
			svgIcon: 'todo.svg',
			width: '22px',
			height: '22px',
			type: 'todo',
		},
		{
			title: locale.topbar.placeholder.privacy,
			svgIcon: 'eye-icon.svg',
			width: '26px',
			height: '26px',
			type: 'userprivacy',
		},
		{
			title: locale.topbar.placeholder.share,
			svgIcon: 'share-light-icon.svg',
			width: '22px',
			height: '22px',
			type: 'shareContact',
		},
	],

	[NavigationRoutes.GROUP_CHAT]: [
		{
			title: locale.dashboard.chat.group_info,
			svgIcon: 'info.svg',
			type: 'groupInfo',
		},
		{
			title: locale.dashboard.chat.search,
			svgIcon: 'search-gray.svg',
			width: '20px',
			height: '20px',
			type: 'search',
		},
		{
			title: locale.dashboard.chat.saved,
			svgIcon: 'saved-light.svg',
			type: 'saved',
		},
		{
			title: locale.dashboard.chat.threads,
			svgIcon: 'threads.svg',
			type: 'threads',
		},
		{ title: locale.dashboard.chat.todo, svgIcon: 'todo.svg', type: 'todos' },
		{
			title: locale.dashboard.chat.qrcode,
			svgIcon: 'qrcode.svg',
			type: 'qrcode',
		},
	],

	[NavigationRoutes.PERSONAL_NOTEPAD]: [
		{
			title: locale.topbar.placeholder.info,
			svgIcon: 'info.svg',
			width: '22px',
			height: '22px',
			type: 'information',
		},
		{
			title: locale.topbar.placeholder.saved,
			svgIcon: 'saved-light.svg',
			width: '27.5px',
			height: '22px',
			type: 'pay',
		},

		{
			title: locale.topbar.placeholder.threads,
			svgIcon: 'threads.svg',
			width: '22px',
			height: '22px',
			type: 'thread	',
		},
		{
			title: locale.topbar.placeholder.to_dos,
			svgIcon: 'todo.svg',
			width: '22px',
			height: '22px',
			type: 'todo',
		},
	],
};

const TopNavbarComponent: React.FC<ContainerProps> = (props: ContainerProps) => {
	const logout = useCallback(() => {
		logoutUser();
	}, []);
	const { profilePhotos } = props.profile?.loggedInUserChatOnlyProfile || {};

	const activeChatOnlyProfilePhoto: ProfilePhotoItem = SharedService.extractProfilePhoto(profilePhotos!)!;

	let showBack = props.showBack ? props.showBack : false,
		friendProfile: UserProfile = props.friendProfile!,
		hideSearchBar = props.hideSearchBar ? props.hideSearchBar : false;

	let contactItem: ContactItem | undefined;
	let groupInfo: any = props.groupInfo && props.groupInfo;
	if (friendProfile) {
		contactItem = props.contact?.contacts?.find((contact) => contact.username === friendProfile.username);
	}

	const routesIcon: any = {
			[NavigationRoutes.DASHBOARD]: <IonIcon slot="icon-only" icon={ellipsisHorizontal} />,
			[NavigationRoutes.CONTACTS]: <IonIcon slot="icon-only" src={AddContactIcon} />,
			[NavigationRoutes.RECENT_CONTACTS]: <IonIcon slot="icon-only" icon={personAddOutline} />,
			[NavigationRoutes.PERSONAL_FOLDER]: <IonIcon slot="icon-only" icon={props.isRightButtonActive ? checkedIcon : checkIcon} />,
			[NavigationRoutes.PERSONAL_STORAGE]: <IonIcon slot="icon-only" icon={ellipsisHorizontal} />,
			[NavigationRoutes.PERSONAL_NOTEPAD]: <IonIcon slot="icon-only" icon={ellipsisHorizontal} />,

			[NavigationRoutes.PROFILE_FRIEND]: <IonIcon slot="icon-only" icon={ellipsisHorizontal} />,
			[NavigationRoutes.CHAT]: <IonIcon slot="icon-only" icon={ellipsisHorizontal} />,
			[NavigationRoutes.GROUP_CHAT]: <IonIcon slot="icon-only" icon={ellipsisHorizontal} />,
			[NavigationRoutes.PROFILE]: <IonIcon slot="icon-only" icon={ellipsisHorizontal} />,
			[NavigationRoutes.My_PROFILE]: <IonIcon slot="icon-only" icon={ellipsisHorizontal} />,
			[NavigationRoutes.PROFILE_UPLOAD_PHOTO]: <IonIcon slot="icon-only" icon={ellipsisHorizontal} />,
			[NavigationRoutes.PROFILE_UPLOAD_PHOTO_CHATONLY]: <IonIcon slot="icon-only" icon={ellipsisHorizontal} />,
			[NavigationRoutes.VIEWAS_CHATONLY]: <IonIcon slot="icon-only" icon={ellipsisHorizontal} />,
			[NavigationRoutes.GROUP_INFO]: <IonIcon slot="icon-only" icon={ellipsisHorizontal} />,
			[NavigationRoutes.MANAGE_GROUP]: <IonIcon slot="icon-only" icon={ellipsisHorizontal} />,
			[NavigationRoutes.GROUP_SETTING]: <IonIcon slot="icon-only" icon={InfoIcon} />,
			[NavigationRoutes.CONTACTS_LIST]: <IonImg slot="icon-only" src={activeChatOnlyProfilePhoto ? activeChatOnlyProfilePhoto?.url : ProfileIcon} />,
			// 'group-info': <IonIcon slot="icon-only" icon={SettingIcon} />,
			[NavigationRoutes.TAGS]: <IonIcon slot="icon-only" src={AddPlusIcon} />,
			[NavigationRoutes.GROUP_CHATS]: <IonIcon slot="icon-only" src={AddPlusIcon} />,
			[NavigationRoutes.TAGS_INFO]: <IonIcon slot="icon-only" src={AddPlusIcon} />,
			[NavigationRoutes.EDIT_THREADS]: <IonIcon slot="icon-only" src={AddPlusIcon} />,
		},
		[searchText, setSearchText] = useState<string>(''),
		[popoverEvent, setPopoverEvent] = useState<any>(),
		[showFavoriteSearchModal, setShowFavoriteSearchModal] = useState<boolean>(false),
		[showPopover, setShowPopover] = useState<boolean>(false),
		[showDropdownPopover, setShowDropdownPopover] = useState<boolean>(false),
		mounted = useRef(false);
	let extractPath = window.location.pathname;
	if (extractPath.includes(`${NavigationRoutes.CHAT}/`)) {
		extractPath = NavigationRoutes.CHAT;
	} else if (extractPath.includes(`${NavigationRoutes.GROUP_CHAT}/`)) {
		extractPath = NavigationRoutes.GROUP_CHAT;
	} else if (extractPath.includes(`${NavigationRoutes.PERSONAL_NOTEPAD}/`)) {
		extractPath = NavigationRoutes.PERSONAL_NOTEPAD;
	}

	useEffect(() => {
		mounted.current = true;
		return () => {
			mounted.current = false;
		};
	}, []);
	{
	}

	return (
		<>
			<div className={'topbar-header ' + (props.containerClassName && props.containerClassName)}>
				<IonToolbar mode="ios" className="toolbar-heading">
					{!props.isHideLeftButton && (
						<IonButtons slot="start" className="left-btns">
							<IonButton
								slot="start"
								className="back-button"
								onClick={(event: any) => {
									event.persist();
									if (mounted.current) {
										if (props.onLeftButtonPress) {
											props.onLeftButtonPress();
										} else {
											!showBack ? Navigation.openSetting() : Navigation.back();
										}
									}
								}}
							>
								{props.leftButtonText ? props.leftButtonText : showBack ? <IonIcon style={{ width: '10px', height: '18px' }} slot="icon-only" src={BackBlackIcon} /> : <IonIcon slot="icon-only" icon={props.leftButtonIcon || settingsOutline} />}
							</IonButton>
							{props.showUnViewDotOnLeftIcon && <NotificationBadge className="dot-circle" showOnlyCircle={true} />}
						</IonButtons>
					)}

					<IonTitle className="toolbarTitle">
						{props.dashboard?.isLoading && props.dashboard?.loaderMessage ? (
							<div className="loading-view">
								<CustomSpinner size={22} />
								<IonLabel>{props.dashboard?.loaderMessage || 'Loading...'}</IonLabel>
							</div>
						) : (
							<>
								<div
									className="title"
									onClick={() => {
										props.onTitlePress && props.onTitlePress();
									}}
								>
									{props.pageTitle}
								</div>
								{props.pageTitleDescription && <div className="description">{props.pageTitleDescription}</div>}
							</>
						)}
					</IonTitle>

					<IonButtons slot="end" style={{ minWidth: 26 }}>
						{props.showSearchIcon && !props.isHideRightButton && (
							<IonButton
								onClick={() => {
									setTimeout(() => {
										const search: any = document.getElementById('searchBar');
										search?.setFocus();
									}, 200);
									props.onSearchIconPress && props.onSearchIconPress();
								}}
								className={' search-icon-btn ' + (props.isSearchButtonActive ? 'search-icon-btn-active' : '')}
							>
								<IonIcon src={SearchIcon} />
							</IonButton>
						)}

						{!props.isHideRightButton && (
							<IonButton
								className={'right-btn ' + (props.isRightButtonActive && 'right-button-active')}
								disabled={props.rightButtonDisabled}
								style={props.rightButtonColor && props.rightButtonColor}
								onClick={(event: any) => {
									event.persist();

									if (mounted.current) {
										if (props.onRightButtonPress) {
											props.onRightButtonPress();
										} else {
											if (extractPath === NavigationRoutes.CONTACTS || extractPath === NavigationRoutes.RECENT_CONTACTS) {
												Navigation.openSearchContacts();
											} else if (
												extractPath === NavigationRoutes.PROFILE ||
												extractPath === NavigationRoutes.My_PROFILE ||
												extractPath === NavigationRoutes.PROFILE_FRIEND ||
												extractPath === NavigationRoutes.DASHBOARD ||
												extractPath === NavigationRoutes.PERSONAL_STORAGE ||
												extractPath === NavigationRoutes.CHAT ||
												extractPath === NavigationRoutes.GROUP_CHAT ||
												extractPath === NavigationRoutes.GROUP_INFO ||
												extractPath === NavigationRoutes.MANAGE_GROUP ||
												extractPath === NavigationRoutes.GROUP_MANAGER ||
												extractPath === NavigationRoutes.GROUP_MANAGER ||
												extractPath === NavigationRoutes.PERSONAL_NOTEPAD
											) {
												setPopoverEvent(event);
												setShowDropdownPopover(true);
											} else {
												// setShowPopover(true);
												setPopoverEvent(event);
											}
										}
									}
								}}
							>
								{props.rightButtonIcon ? <IonIcon slot="icon-only" src={props.rightButtonIcon} /> : props.rightButtonText ? props.rightButtonText : routesIcon[extractPath]}
							</IonButton>
						)}
					</IonButtons>

					<IonPopover
						cssClass="toggle-menu-pop"
						event={popoverEvent}
						showBackdrop={false}
						isOpen={showPopover}
						onDidDismiss={() => {
							if (mounted.current) {
								setShowPopover(false);
								setPopoverEvent(undefined);
							}
						}}
					>
						<IonList>
							<IonItem
								detail={false}
								className="custom-menu-pop"
								style={{ color: '#000000' }}
								button
								onClick={() => {
									Navigation.openAddMembers({
										data: friendProfile,
										actionType: AddMembersPageActionType.ChooseContactForGroup,
									});
								}}
							>
								<IonIcon slot="start" color="#000000" icon={peopleOutline} />
								<IonLabel style={{ textTransform: 'capitalize' }}> {locale.topbar.label.new_group} </IonLabel>
							</IonItem>
							<IonItem detail={false} className="custom-menu-pop" style={{ color: '#000000' }} button onClick={() => {}}>
								<IonIcon slot="start" color="#000000" icon={listOutline} />
								<IonLabel> To Do’s</IonLabel>
							</IonItem>
							<IonItem className="custom-menu-pop" style={{ color: '#000000' }} lines="none" detail={false} button onClick={() => logout()}>
								<IonIcon slot="start" color="#000000" icon={heartOutline} />
								<IonLabel> {locale.topbar.label.favorite} </IonLabel>
							</IonItem>
						</IonList>
					</IonPopover>
				</IonToolbar>
				{!hideSearchBar ? (
					<>
						<IonToolbar mode="ios" className="toolbar-searchbar">
							<form
								onSubmit={(e) => {
									e.preventDefault();
									props.searchHandler && props.searchHandler(searchText, props);
								}}
							>
								<IonSearchbar
									animated={true}
									mode="ios"
									id="searchBar"
									placeholder={props.searchbarPlaceholder || 'Search'}
									className="searchbar-wrapper"
									style={{ color: '#000000' }}
									debounce={1}
									value={String(searchText)}
									onIonChange={(e) => {
										if (mounted.current) {
											setSearchText(e.detail.value!);
											props.searchTypeHandler && props.searchTypeHandler(e.detail.value!, props);
										}
									}}
									onIonCancel={() => props.onSearchBarCancelPress && props.onSearchBarCancelPress()}
									onFocus={() => props.onSearchBarFocus && props.onSearchBarFocus()}
									showCancelButton={props.showSearchbarCancelButton ? 'always' : 'never'}
								></IonSearchbar>
							</form>
						</IonToolbar>
					</>
				) : null}

				{props.appendAdditionalViewOnBottom ? props.appendAdditionalViewOnBottom : null}
			</div>

			<DropdownPopover
				bottomContent={extractPath === 'personal-storage' && <IonButton className="buy-storage">Buy storage</IonButton>}
				showPopover={showDropdownPopover}
				list={menuOptions[extractPath]}
				popoverEvent={popoverEvent}
				onDismiss={() => {
					if (mounted.current) {
						setShowDropdownPopover(false);
						setPopoverEvent(undefined);
					}
				}}
				onItemSelect={(item: any) => {
					if (mounted.current) {
						setShowDropdownPopover(false);
						setPopoverEvent(undefined);

						props.onDropDownOptionPress && props.onDropDownOptionPress(item.type);

						switch (item.type) {
							case 'view-profile':
								Navigation.profileFriend({
									data: friendProfile,
									friendProfileActionType: ProfileFriendActionTypes.ContactView,
								});
								break;

							case 'editProfile':
								Navigation.openEditProfile();
								break;

							case 'logout':
								logoutUser();
								break;

							case 'createGroup':
								Navigation.openAddMembers({
									actionType: AddMembersPageActionType.ChooseContactForGroup,
									data: friendProfile,
								});

								break;

							case 'manageContacts':
								Navigation.openManageContacts({
									data: friendProfile,
									friendProfileActionType: ProfileFriendActionTypes.ContactView,
								});
								break;

							case 'threads':
								break;
							case 'myprivacy':
								Navigation.openPrivacy();
								break;
							case 'userprivacy':
								Navigation.openUserPrivacy({
									contactItem: contactItem,
								});
								break;

							case 'settings':
								Navigation.openSetting();
								break;
							case 'addContact':
								Navigation.openSearchContacts();
								break;
							case 'favorite':
								setShowFavoriteSearchModal(true);
								break;

							case 'shareContact':
								Navigation.openAddMembers({
									data: friendProfile,
									actionType: AddMembersPageActionType.ChooseContactWithShareContact,
								});
								break;

							case 'chat':
								Navigation.chat({ receiver: groupInfo });
								break;
							default:
								break;
						}
					}
				}}
			/>

			<FavoriteSearchModal
				show={showFavoriteSearchModal}
				onClose={() => {
					if (mounted.current) {
						setShowFavoriteSearchModal(false);
					}
				}}
			/>
		</>
	);
};
const mapStateToProps = (state: ReduxStates) => {
	return {
		profile: state.profile,
		contact: state.contact,
	};
};

const mapDispatchToProps = (dispatch: any) => ({});
export const TopNavbar = connect(mapStateToProps, mapDispatchToProps)(TopNavbarComponent);
