import React, { useCallback, useEffect, useState } from 'react';
import './style.css';
import { Message } from '../types/message';
import UtilService from '../services/util';
import { sharedService } from '../services/sharedService';

const AudioIcon = '../assets/icon/audio-icon.svg';
const AudioGif = '../assets/gif/voice-message.gif';

interface iProps {
	message: Message;
}

export const AudioThreadComponent = ({ message }: iProps) => {
	const [isPlaying, setIsPlaying] = useState<boolean>(false);
	const [currentDuration, setCurrentDuration] = useState<any>();
	const isComponentMounted = React.useRef(true);

	useEffect(() => {
		return () => {
			isComponentMounted.current = false;
		};
	});

	const clearTimeInterval = useCallback(() => {
		if (sharedService.currentPlayingAudioComponentRef) {
			sharedService.currentPlayingAudioComponentRef();
			sharedService.currentPlayingAudioComponentRef = null;
		}
		if (sharedService.currentPlayingAudioMesageRef) {
			setCurrentDuration(0);
			setIsPlaying(false);
		}
		if (sharedService.currentPlayingAudioTimeIntervalRef) {
			clearInterval(sharedService.currentPlayingAudioTimeIntervalRef);
			sharedService.currentPlayingAudioTimeIntervalRef = null;
			sharedService.currentPlayingAudioComponentRef = null;
		}
	}, []);

	const startAudioTimeUpdate = useCallback(() => {
		setIsPlaying(true);
		setCurrentDuration(0);
		sharedService.currentPlayingAudioTimeIntervalRef = setInterval(() => {
			if (sharedService.currentPlayingAudioMesageRef?.ended || sharedService.currentPlayingAudioMesageRef?.paused) {
				if (isComponentMounted.current) {
					setIsPlaying(false);
				}
				clearTimeInterval();
			} else {
				setCurrentDuration(sharedService.currentPlayingAudioMesageRef?.currentTime);
			}
		}, 1000);
	}, [clearTimeInterval]);

	const playAudio = useCallback(() => {
		if (sharedService.currentPlayingAudioMesageRef) {
			if (sharedService.currentPlayingAudioMesageKey === message.messageKey) {
				if (sharedService.currentPlayingAudioMesageRef.paused || sharedService.currentPlayingAudioMesageRef.ended) {
					initializeCallbackForStateReset();
					sharedService.currentPlayingAudioMesageRef.play();
					startAudioTimeUpdate();
				} else if (sharedService.currentPlayingAudioMesageRef.duration > 0 && !sharedService.currentPlayingAudioMesageRef.paused && !sharedService.currentPlayingAudioMesageRef.ended) {
					sharedService.currentPlayingAudioMesageRef.pause();
					clearTimeInterval();
				}
			} else {
				sharedService.currentPlayingAudioMesageRef.pause();
				sharedService.currentPlayingAudioMesageRef = null;
				clearTimeInterval();
			}
		}

		if (!sharedService.currentPlayingAudioMesageRef) {
			sharedService.currentPlayingAudioMesageRef = new Audio(message.media?.mediaFile);
			sharedService.currentPlayingAudioMesageKey = message.messageKey;
			sharedService.currentPlayingAudioMesageRef.play().then(() => {
				initializeCallbackForStateReset();
				startAudioTimeUpdate();
			});
		}
	}, [clearTimeInterval, message.media?.mediaFile, message.messageKey, startAudioTimeUpdate]);

	const initializeCallbackForStateReset = () => {
		sharedService.currentPlayingAudioComponentRef = null;
		sharedService.currentPlayingAudioComponentRef = () => {
			if (isComponentMounted.current) {
				setIsPlaying(false);
				setCurrentDuration(0);
			}
		};
	};

	useEffect(() => {
		if (sharedService.currentPlayingAudioMesageRef && sharedService.currentPlayingAudioMesageKey === message.messageKey) {
			if (sharedService.currentPlayingAudioMesageRef?.paused || sharedService.currentPlayingAudioMesageRef?.ended) {
				if (isComponentMounted.current) {
					setIsPlaying(false);
				}
				clearTimeInterval();
			} else {
				initializeCallbackForStateReset();
				if (isComponentMounted.current) {
					setIsPlaying(true);
				}
				sharedService.currentPlayingAudioMesageRef.play();
				startAudioTimeUpdate();
			}
		}
	}, [clearTimeInterval, message.messageKey, startAudioTimeUpdate]);

	return (
		<div className="audio-type-message" onClick={playAudio}>
			{!isPlaying ? <img className="icon" src={AudioIcon} alt="media" /> : <img className="audiogif" src={AudioGif} alt="my-gif" />}

			{currentDuration > 0 && isPlaying && <div className="file-name">{currentDuration ? `${UtilService.formatDuration(currentDuration)}` : ''}</div>}
			{!isPlaying && <div className="file-name">{message.media?.duration ? `${UtilService.formatDuration(message.media?.duration)}` : 'N/A'}</div>}
		</div>
	);
};
