import { IonIcon, IonModal } from '@ionic/react';
import React from 'react';
import './SketchPad.scss';
import { Navigation } from '../../services/navigationService';
import { AddMembersPageActionType, ChatMessageState, ChatType, MessageType } from '../../services/enumService';
import { xmpp } from '../../services/xmpp';
import store from '../../redux/store';
import SharedService from '../../services/sharedService';

interface SketchpadNavigationProps {
	sketchpadImg: any;
	show: boolean;
	onCloseCallBack: () => void;
}

const SketchpadNavigation: React.FC<SketchpadNavigationProps> = ({ sketchpadImg, show, onCloseCallBack }) => {
	const imageIconList = [
		{
			icon: 'maskman.svg',
			name: 'peter',
			group: 'Groupchat',
		},
		{
			icon: 'peter.svg',
			name: 'peter',
			group: 'Groupchat',
		},
		{
			icon: 'xylo.svg',
			name: 'peter',
			group: 'Groupchat',
		},
		{
			icon: 'peter.svg',
			name: 'peter',
			group: 'Groupchat',
		},
		{
			icon: 'xylo.svg',
			name: 'peter',
			group: 'Groupchat',
		},
		{
			icon: 'peter.svg',
			name: 'peter',
			group: 'Groupchat',
		},
		{
			icon: 'xylo.svg',
			name: 'peter',
			group: 'Groupchat',
		},
		{
			icon: 'xylo.svg',
			name: 'peter',
			group: 'Groupchat',
		},
		{
			icon: 'xylo.svg',
			name: 'peter',
			group: 'Groupchat',
		},
		{
			icon: 'xylo.svg',
			name: 'peter',
			group: 'Groupchat',
		},
	];
	const _sendToNotePad = async (mediaFiles: Array<File> = []) => {
		let mediaResolutions: any = [],
			isImageVideo: Boolean = true;
		let files: any = [];
		const _mediaResolutions: any = [];

		let messageType: any,
			messageState = ChatMessageState.ORIGINAL,
			relatedMessageId = '';
		if (isImageVideo) {
			messageType = MessageType.MEDIA;
			for (let index in mediaFiles) {
				let outputFile: File | null = null;

				const media = mediaFiles[index],
					mediaResolution = parseInt(index) === 0 ? mediaResolutions[index] : 0; // Remove this condition when image editor impletmented or multiple images

				// Remove this condition when image editor impletmented or multiple images
				if (parseInt(index) === 0) {
					_mediaResolutions.push(mediaResolution === 0 ? 'full image' : 'reduced image');
				} else {
					// No need this after image editor implemented for multiple images
					_mediaResolutions.push('full image');
				}

				if (mediaResolution !== 0) {
					outputFile = media;
				} else {
					outputFile = media;
				}

				if (outputFile) {
					files.push(outputFile);
				}
			}
		} else {
			files = mediaFiles;
		}
		let receiverId = store.getState().profile.loggedInUserProfile?.username;

		let conversationType = ChatType.P2P;
		if (receiverId) {
			await xmpp.sendMessage({
				messageBody: files,
				messageState: messageState,
				messageType: messageType,
				relatedMessageId: relatedMessageId,
				conversationType: conversationType,
				receiverId,
				isShareContact: true,
			});
		}
		onCloseCallBack();
	};

	return (
		<IonModal showBackdrop={true} isOpen={show} onDidDismiss={onCloseCallBack} swipeToClose={true} cssClass="sketchpad-navigation-modal" animated={true}>
			<div className="selected-component-container">
				<div className="send-to">Send To</div>
				<div className="shared">
					{imageIconList.map((item: any, key: any) => {
						return (
							<div className="shared-image-skeched" key={key}>
								<IonIcon src={'../assets/icon/' + item.icon}></IonIcon>
								<div className="name-container">
									<div className="icon-name">{item.name}</div>
									<div className="icon-name">{item.group}</div>
								</div>
							</div>
						);
					})}
				</div>
				<div className="icon-contain-container">
					<div className="icon-container">
						<div className="icon-name-container">
							<div className="add-drive">
								<IonIcon src={'./assets/icon/drive-search.svg'} />
							</div>
							<div className="icon-name">Add to Drive</div>
						</div>
						<div
							className="icon-name-container"
							onClick={() => {
								const file = SharedService.dataURLtoFile(sketchpadImg, 'sketchpad.png');
								_sendToNotePad([file]);
							}}
						>
							<div className="add-drive">
								<IonIcon src={'./assets/icon/send-notepad.svg'} />
							</div>
							<div className="icon-name">Send to Notepad</div>
						</div>
						<div
							className="icon-name-container"
							onClick={() => {
								Navigation.openAddMembers({
									data: sketchpadImg,
									actionType: AddMembersPageActionType.ChooseContactForForwordSketchpad,
								});
							}}
						>
							<div className="add-drive">
								<IonIcon src={'./assets/icon/forward.svg'} />
							</div>
							<div className="icon-name">Forward</div>
						</div>
					</div>
				</div>
				<div className="cancel" onClick={onCloseCallBack}>
					Cancel
				</div>
			</div>
		</IonModal>
	);
};

export default SketchpadNavigation;
