import { IonModal, IonButton, IonLabel } from '@ionic/react';
import React, { useCallback } from 'react';
import './style.scss';
import { connect } from 'react-redux';
import CustomSpinner from '../components/CustomSpinner';

interface AppUpdatePopupModalProps {
	show: boolean;
	isLoading?: boolean;
	loadingMessage?: any;
	heading?: any;
	subHeading?: any;
	messageTitle?: any;
	message: any;
	actionButtonnTitle?: any;
	callBack?: any;
}

const AppUpdatePopupModal: React.FC<AppUpdatePopupModalProps> = ({ show, heading, subHeading, messageTitle, message, actionButtonnTitle, isLoading, loadingMessage, callBack, ...props }) => {
	const onCloseCallBack = useCallback(
		(event: any) => {
			callBack && callBack();
		},
		[callBack]
	);

	return (
		<IonModal showBackdrop={false} isOpen={show} onDidDismiss={onCloseCallBack} swipeToClose={false} cssClass="app-update-popup-modal" animated={false}>
			<div className="content-view">
				<IonLabel className="heading-label">{heading}</IonLabel>
				<IonLabel className="subheading-label">{subHeading}</IonLabel>

				{isLoading ? (
					<div className="loading-container">
						<div className="loading-view">
							<CustomSpinner size={50} />
						</div>
						<IonLabel className="loading-title">{loadingMessage}</IonLabel>
					</div>
				) : (
					<>
						<div className="message-container">
							<div className="message-title">{messageTitle}</div>
							<div className="message" dangerouslySetInnerHTML={{ __html: message }}></div>
						</div>

						<div className="btn-container">
							<IonButton className="action-btn" fill="clear" onClick={onCloseCallBack}>
								{actionButtonnTitle}
							</IonButton>
						</div>
					</>
				)}
			</div>
		</IonModal>
	);
};

const mapStateToProps = (state: any) => {
	return {};
};
const mapDispatchToProps = (dispatch: any) => ({});

export default connect(mapStateToProps, mapDispatchToProps)(AppUpdatePopupModal);
