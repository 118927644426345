import React from 'react';
import { IonToggle, IonPage, IonButton, IonToolbar, IonContent, IonItem, IonList, IonLabel, IonInput, IonIcon, IonImg, IonThumbnail } from '@ionic/react';
import './style.scss';
import { connect } from 'react-redux';
import { TopNavbar } from '../common/header/topbar';
import { ManageContactActionsSheetOptions } from '../../services/enumService';
import { locale } from '../../locales/local';
import { chevronForwardOutline } from 'ionicons/icons';
import { DefaultProfilePic } from '../../helpers/common';
import DeleteContact from '../../modals/DeleteContact';
import SharedService from '../../services/sharedService';
import { ProfilePhotoItem } from '../../types/profile';
import { showStylishToast } from '../../redux/actions/global';
import { BlockUserRequestData, RemoveRosterItemRequestData, UnBlockUserRequestData } from '../../types/api-requests';
import { ContactStateTypes, GlobalStateTypes, ProfileStateTypes } from '../../types/redux-states';
import { blockUser, removeRosterItem, unBlockUser } from '../../redux/actions/contact';
import Blockpopup from '../../modals/Blockpopup';
import { Navigation } from '../../services/navigationService';
import { ManageContactsNavParams } from '../../types/navigation';
import AddNewTagPopup from '../../modals/AddNewTagPopup';
import ChooseTagsModal from '../../modals/ChooseTagsModal/ChooseTagsModal';

class ManageContact extends React.Component<iProps, iState> {
	componentIsMounted: Boolean = false;

	contactsPrivacySettings = [
		{ title: locale.contacts.manage_contact?.moment?.title, description: locale.contacts.manage_contact.moment?.description, value: 'moments' },
		{ title: locale.contacts.manage_contact?.chats_only?.title, description: locale.contacts.manage_contact?.chats_only?.description, value: 'chats' },
	];

	user: any;

	visiblesFields = {
		personalMessage: false,
		tags: false,
		alias: false,
		privacySetting: false,
		contactSetting: false,
	};

	friendProfileActionType: any;
	navParams: ManageContactsNavParams = {} as any;
	constructor(props: iProps) {
		super(props);

		this.state = {
			muteNotification: false,
			stickOnTop: false,
			starred: false,
			blockContact: false,
			showActionSheetFor: null,
			showDeleteContact: false,
			isBlock: false,
			showAddTagPopup: false,
			showChooseTagModal: false,
			tags: [],
			contactAlias: '',
		};
	}

	componentDidMount(): void {
		this.componentIsMounted = true;

		Navigation.getNavParamData(this.props).then((res: any) => {
			this.navParams = res;

			const allContacts = this.props.contact.blockedList;
			const contact = allContacts?.find((name) => name.username === this.navParams.data.username);

			this.setState({
				blockChecked: contact?.status ? true : false,
				contactAlias: '',
			});
		});
	}

	componentWillUnmount() {
		this.componentIsMounted = false;
	}

	_renderSwitchItem(title: any, value: any, onChange: any) {
		return (
			<div className={'swtich-item'}>
				<IonToggle className="toggle-customised" mode="ios" value={value} onChange={onChange} />
				<IonLabel>{title}</IonLabel>
			</div>
		);
	}

	// _renderActionSheet() {
	// 	const { showActionSheetFor } = this.state;
	// 	let headerTitle: any = null;
	// 	let buttons = [];

	// 	if (showActionSheetFor === ManageContactActionsSheetOptions.DeleteContact) {
	// 		headerTitle = locale.contacts.manage_contact.delete_contact_header;
	// 		buttons.push({
	// 			text: locale.contacts.manage_contact.delete_contact,
	// 			role: 'destructive',
	// 			handler: () => {
	// 				info('Delete clicked');
	// 			},
	// 		});

	// 		buttons.push({
	// 			text: locale.contacts.manage_contact.cancel,
	// 			handler: () => {
	// 				info('Cancel clicked');
	// 			},
	// 		});
	// 	} else if (showActionSheetFor === ManageContactActionsSheetOptions.BlockContact) {
	// 		headerTitle = locale.contacts.manage_contact.block_headsup;
	// 		buttons.push({
	// 			text: locale.global.ok,
	// 			role: 'destructive',
	// 			handler: () => {
	// 				this.setState({ blockContact: true });
	// 			},
	// 		});

	// 		buttons.push({
	// 			text: locale.global.cancel,
	// 			handler: () => {
	// 				info('Cancel clicked');
	// 			},
	// 		});
	// 	} else if (showActionSheetFor === ManageContactActionsSheetOptions.ClearChatHistory) {
	// 		buttons.push({
	// 			text: locale.contacts.manage_contact.clear_chat_history,
	// 			role: 'destructive',
	// 			handler: () => {
	// 				this.setState({ blockContact: true });
	// 			},
	// 		});

	// 		buttons.push({
	// 			text: locale.global.cancel,
	// 			handler: () => {
	// 				info('Cancel clicked');
	// 			},
	// 		});
	// 	} else if (showActionSheetFor === ManageContactActionsSheetOptions.ReportContact) {
	// 		buttons.push({
	// 			text: locale.contacts.manage_contact.lewd_content,
	// 			handler: () => {},
	// 		});
	// 		buttons.push({
	// 			text: locale.contacts.manage_contact.fraud,
	// 			handler: () => {},
	// 		});
	// 		buttons.push({
	// 			text: locale.contacts.manage_contact.account_compromised,
	// 			handler: () => {},
	// 		});
	// 		buttons.push({
	// 			text: locale.contacts.manage_contact.bot_account,
	// 			handler: () => {},
	// 		});

	// 		buttons.push({
	// 			text: locale.global.cancel,
	// 			handler: () => {
	// 				info('Cancel clicked');
	// 			},
	// 		});
	// 	} else if (showActionSheetFor === ManageContactActionsSheetOptions.PrivacyContact) {
	// 		headerTitle = locale.contacts.manage_contact.block_headsup;
	// 		buttons.push({
	// 			text: locale.global.ok,
	// 			role: 'destructive',
	// 			handler: () => {},
	// 		});

	// 		buttons.push({
	// 			text: locale.global.cancel,
	// 			handler: () => {
	// 				info('Cancel clicked');
	// 			},
	// 		});
	// 	}

	// 	return headerTitle ? (
	// 		<IonActionSheet mode="ios" header={headerTitle} isOpen={this.state.showActionSheetFor != null} onDidDismiss={() => this.setState({ showActionSheetFor: null })} cssClass="manage-contact-action-sheet" buttons={buttons}></IonActionSheet>
	// 	) : (
	// 		<IonActionSheet mode="ios" isOpen={this.state.showActionSheetFor != null} onDidDismiss={() => this.setState({ showActionSheetFor: null })} cssClass="manage-contact-action-sheet" buttons={buttons}></IonActionSheet>
	// 	);
	// }

	/**
	 * Update the contact alias to the server
	 */
	updateContactAlias = () => {
		// call api here for update the alias of contact
		// const data = {
		// 	alias: this.state.contactAlias,
		// }
	};

	render() {
		const { tags } = this.state;
		const activeProfilePhoto: ProfilePhotoItem = SharedService.extractProfilePhoto(this.navParams.data?.profilePhotos!)!;

		return (
			<>
				<IonPage className="manage-contact-page">
					<IonToolbar className="toolbar-wrapper">
						<TopNavbar
							{...this.props}
							pageTitle={'Manage Contact'}
							onLeftButtonPress={() => {
								Navigation.back();
							}}
							showBack={true}
							hideSearchBar={true}
						/>
					</IonToolbar>
					<IonContent>
						<div className="user-detail-main">
							<div className="user-detail-container">
								<IonThumbnail className="default-image-container">
									<IonImg title="Change" src={activeProfilePhoto?.url || DefaultProfilePic} className="default-image" />
								</IonThumbnail>

								{/* <IonImg src={activeProfilePhoto?.url || DefaultProfilePic} className="deafult-image" /> */}
								<div className="user-name-container">
									<div className="user-name">{this.navParams.data?.profileName}</div>
									<div className="user-id">
										UserId: {this.navParams.data?.username}
										{this.navParams.data?.region && <div className="region">Region: {this.navParams.data.region}</div>}
									</div>
								</div>
							</div>
							<div className="star">
								<IonIcon src={'./assets/icon/Star2.svg'}></IonIcon>
							</div>
						</div>

						<IonList className="manage-contact-options-list" lines="none">
							<div className="setting-toggle-container">
								{this._renderSwitchItem('Stick on Top', this.state.stickOnTop, () => {
									this.setState({ stickOnTop: !this.state.stickOnTop });
								})}

								<div className="vertical-line"></div>
								{this._renderSwitchItem('Mute Notifications', this.state.muteNotification, () => {
									this.setState({ muteNotification: !this.state.muteNotification });
								})}

								<div className="vertical-line"></div>
								{this._renderSwitchItem('Starred', this.state.starred, () => {
									this.setState({ starred: !this.state.starred });
								})}
							</div>

							<IonItem lines="none" className="alias-input">
								<IonLabel position="stacked" className="set-alias">
									Set Alias for (Contacts Preffered Username)
								</IonLabel>
								<IonInput
									style={{ color: '#000000' }}
									value={this.state.contactAlias}
									onIonChange={(e) => {
										this.setState({ contactAlias: e.detail.value! });
									}}
									onBlur={this.updateContactAlias}
								/>
							</IonItem>
							<IonItem lines="none">
								<IonLabel position="stacked" className="set-tag">
									{locale.add_contacts.label.set_tags}
								</IonLabel>
							</IonItem>
							<IonItem
								lines="none"
								className="tags-inner-manage"
								onClick={() => {
									if (this.props.profile?.myTags && this.props.profile?.myTags.length > 0) {
										this.setState({ showChooseTagModal: true });
									} else {
										this.setState({ showAddTagPopup: true });
									}
								}}
							>
								<div className="tag-input">
									<div className="inner-tag-input">
										{!tags || tags.length === 0 ? (
											<div className="placeholder-txt">Classify Contacts by Tags</div>
										) : (
											<>
												<div className="tagname">{tags.join()},</div>
												<div className="add-tag">add tags</div>
											</>
										)}
									</div>

									<IonIcon icon={chevronForwardOutline}></IonIcon>
								</div>
							</IonItem>

							<IonItem detail={false} lines="none">
								<IonLabel>{locale.global.block}</IonLabel>
								<div className={'swtich-item'}>
									<IonToggle
										// onIonChange={blockContact:false}
										checked={this.state.blockChecked}
										className="toggle-customised"
										mode="ios"
										onClick={() => {
											if (this.state.blockChecked === true) {
												this.props.unBlockUser({ username: this.navParams.data?.username }).then(() => {
													this.props.showStylishToast({ stylishToastMessage: 'Unblocked' });
													Navigation.openManageContacts();
													this.setState({ blockChecked: false });
												});
											} else {
												this.setState({ blockContact: true });
											}
										}}
									/>
								</div>
							</IonItem>
							<IonItem
								detail={true}
								lines="none"
								onClick={() => {
									Navigation.openPrivacy();
								}}
							>
								<IonLabel>{locale.global.privacy}</IonLabel>
							</IonItem>
							<IonItem
								detail={true}
								lines="none"
								onClick={() => {
									this.setState({ showActionSheetFor: ManageContactActionsSheetOptions.ReportContact });
								}}
							>
								<IonLabel>{locale.global.report}</IonLabel>
							</IonItem>
						</IonList>
						<div className="action-btn-container">
							<IonButton
								className="action-btn"
								expand="block"
								onClick={() => {
									this.setState({ showDeleteContact: true });
								}}
							>
								{locale.global.delete_contact}
							</IonButton>
						</div>
					</IonContent>
				</IonPage>

				<ChooseTagsModal
					props={this.props}
					loggedInUser={this.props.profile.loggedInUserProfile}
					selectedTags={this.state.tags}
					show={this.state.showChooseTagModal}
					onCancel={() => {
						this.setState({ showChooseTagModal: false });
					}}
					onDone={(updatedTags: any) => {
						this.setState({ showChooseTagModal: false, tags: updatedTags });
					}}
					onTagCreated={(value: string) => {
						const tmpTagsList = this.state.tags;
						tmpTagsList.push(value);
						this.setState({ tags: tmpTagsList, showAddTagPopup: false });
					}}
				/>

				<AddNewTagPopup
					show={this.state.showAddTagPopup}
					onCloseCallBack={() => {
						this.setState({ showAddTagPopup: false });
					}}
					onTagCreated={(value: string) => {
						const tmpTagsList = this.state.tags;
						tmpTagsList.push(value);
						this.setState({ tags: tmpTagsList, showAddTagPopup: false });
					}}
				/>

				<Blockpopup
					show={this.state.blockContact}
					isOpenfrom={'manageConact'}
					onBlockCallBack={() => {
						this.props.blockUser({ username: this.navParams.data?.username }).then(() => {
							this.props.showStylishToast({ stylishToastMessage: 'Blocked' });
							Navigation.openManageContacts();
							this.setState({ blockChecked: true });
							this.setState({ blockContact: false });
						});
					}}
					onCloseCallBack={() => {
						this.setState({ blockContact: false });
					}}
				/>

				<DeleteContact
					type=""
					userData={this.navParams.data}
					show={this.state.showDeleteContact}
					onDeleteCallBack={() => {
						this.props.removeRosterItem({ username: this.navParams.data?.username }).then(() => {
							Navigation.contactsTab();
						});
					}}
					onCloseCallBack={() => {
						this.setState({ showDeleteContact: false });
					}}
				/>
			</>
		);
	}
}

interface iProps {
	profile: ProfileStateTypes;
	contact: ContactStateTypes;
	addContacts: any;
	isLoading: boolean;
	history: any;
	location: any;
	confirmContact: Function;
	blockUser: (data: BlockUserRequestData) => Promise<any>;
	unBlockUser: (data: UnBlockUserRequestData) => Promise<any>;
	removeRosterItem: (data: RemoveRosterItemRequestData) => Promise<any>;
	showStylishToast: (payload: GlobalStateTypes) => void;
}
interface iState {
	muteNotification?: boolean;
	stickOnTop?: boolean;
	starred?: boolean;
	blockContact: boolean;
	showActionSheetFor: string | null;
	showDeleteContact: boolean;
	isBlock: boolean;
	blockChecked?: boolean;
	showAddTagPopup: boolean;
	showChooseTagModal: boolean;
	tags: any;
	contactAlias: string;
}
const mapStateToProps = (state: any) => {
	return {
		profile: state.profile,
		loggedInUser: state.global.loggedInUser,
		isLoggedIn: state.auth.isLoggedIn,
		dashboard: state.dashboard,
		addContacts: state.addContacts,
		contact: state.contact,
	};
};
const mapDispatchToProps = (dispatch: any) => ({
	blockUser: (payload: BlockUserRequestData) => dispatch(blockUser(payload)),
	unBlockUser: (payload: UnBlockUserRequestData) => dispatch(unBlockUser(payload)),
	removeRosterItem: (payload: RemoveRosterItemRequestData) => dispatch(removeRosterItem(payload)),
	showStylishToast: (payload: GlobalStateTypes) => dispatch(showStylishToast(payload)),
});

export default connect(mapStateToProps, mapDispatchToProps)(ManageContact);
