import { IonButton, IonIcon, IonInput, IonModal } from '@ionic/react';
import React, { createRef, useState } from 'react';
import './style.scss';
import { connect } from 'react-redux';
import { updateUser } from '../redux/actions/profile';
import { pageLoading, hidePageLoading } from '../redux/actions/dashboard';

const ClearIcon = './assets/icon/close-icon.svg';

interface AddNewLinkPopUpProps {
	show: boolean;
	defaultValue: string;
	onCloseCallBack: () => void;
	onAddLink: (link: string) => void;
}

const AddNewLinkPopUp: React.FC<AddNewLinkPopUpProps> = ({ show, defaultValue, onCloseCallBack, onAddLink }) => {
	const [link, setLink] = useState<string>(defaultValue);
	const tagInputRef: React.RefObject<HTMLIonInputElement> = createRef();

	return (
		<>
			<IonModal showBackdrop={true} isOpen={show} onDidDismiss={onCloseCallBack} swipeToClose={true} cssClass="add-new-tag-popup" animated={true}>
				<div className="container">
					<div className="content-view">
						<div className="title">Add Link</div>

						<div className="tag-input-container">
							<div className="input-view">
								<IonInput ref={tagInputRef} id="addLinkInput" placeholder="http://example.com" className="tag-name-input" value={link} onIonChange={(event) => setLink(event.detail.value!)} />
								{link && (
									<IonIcon
										className="clear-icon"
										src={ClearIcon}
										onClick={() => {
											setLink('');
											const tagNameInput: any = document.getElementById('addLinkInput');
											if (tagNameInput && tagNameInput.setFocus) {
												tagNameInput.setFocus();
											}
										}}
									></IonIcon>
								)}
							</div>
						</div>
						<div className="btns-view">
							<IonButton fill="clear" className="delete-btn" onClick={onCloseCallBack}>
								Cancel
							</IonButton>
							<IonButton
								fill="clear"
								className="ok-btn"
								onClick={() => {
									onAddLink(link);
								}}
								disabled={!link || link === defaultValue}
							>
								Done
							</IonButton>
						</div>
					</div>
				</div>
			</IonModal>
		</>
	);
};

const mapStateToProps = (state: any) => {
	return {};
};
const mapDispatchToProps = (dispatch: any) => ({
	updateUser: (payload: String) => dispatch(updateUser(payload)),
	pageLoading: (payload: any) => dispatch(pageLoading(payload)),
	hidePageLoading: () => dispatch(hidePageLoading()),
});

export default connect(mapStateToProps, mapDispatchToProps)(AddNewLinkPopUp);
