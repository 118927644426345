import React from 'react';
import { IonButton, IonCheckbox, IonContent, IonLabel, IonListHeader, IonPage, IonToggle, IonActionSheet } from '@ionic/react';
import './style.scss';
import { RouteComponentProps } from 'react-router';
import { IonList, IonItem } from '@ionic/react';
import { TopNavbar } from '../common/header/topbar';
import { connect } from 'react-redux';
import { deleteTag, getMyTags, setTagsVisibility } from '../../redux/actions/profile';
import { sortByAlias } from '../../helpers/common';
import AddNewTagPopup from '../../modals/AddNewTagPopup';
import SharedService, { sharedService } from '../../services/sharedService';
import store from '../../redux/store';
import { SHOW_TOAST } from '../../redux/constants/common';
import { AddMembersPageActionType } from '../../services/enumService';
import ToastStylish from '../../components/ToastStylish';
import { checkmarkOutline } from 'ionicons/icons';
import { DASHBOARD_INIT } from '../../redux/constants/dashboard';
import { dbService } from '../../services/dbService';
import { ContactStateTypes, ProfileStateTypes, ReduxStates } from '../../types/redux-states';
import { ContactItem, TagItem, ContactsTagItem } from '../../types/contact';
import { DeleteTagRequestData, SetTagVisibilityRequestData } from '../../types/api-requests';
import { Navigation } from '../../services/navigationService';

interface iProps extends RouteComponentProps<{ name: string }> {
	profile: ProfileStateTypes;
	contact: ContactStateTypes;
	getMyTags: () => Promise<void>;
	setTagsVisibility: (payload: SetTagVisibilityRequestData) => Promise<unknown>;
	deleteTag: (payload: DeleteTagRequestData) => Promise<unknown>;
	location: any;
}
interface iState {
	loggedInUser?: any;
	contacts: any;
	isLoading?: boolean;
	loadingMessage?: string;
	showAddTagPopup: boolean;
	isManagingTag: boolean;
	selectedTags?: any;
	tagsList?: Array<ContactsTagItem>;
	showActionSheetFor?: any;
	showToast: boolean;
	toastMessage: string;
	toastIcon: string;
	showDeletedAllBgMsg: boolean;
}

class Tags extends React.Component<iProps, iState> {
	componentIsMounted: Boolean = false;
	componentIsUpdated: Boolean = false;

	tagUpdating: boolean = false;

	constructor(props: iProps) {
		super(props);

		this.state = {
			showAddTagPopup: false,
			selectedTags: [],
			contacts: undefined,
			isManagingTag: false,
			isLoading: false,
			loadingMessage: '',
			tagsList: [],
			showToast: false,
			showDeletedAllBgMsg: false,
			toastMessage: '',
			toastIcon: '',
		};
	}
	getData = async () => {
		let user = await dbService.me(),
			contacts: any = user.contacts?.filter((_contact: any) => _contact.status === 'confirmed').sort(sortByAlias);

		return [user, contacts];
	};

	setPageData = async () => {
		const { myTags } = this.props.profile;
		const { contacts } = this.props.contact;

		const tagsList: Array<ContactsTagItem> = [];
		if (myTags) {
			myTags
				.sort((a, b) => (a.tag.toLowerCase() > b.tag.toLowerCase() ? 1 : -1))
				.forEach((tagItem: TagItem) => {
					const filteredContacts = contacts?.filter((contact: ContactItem) => contact.tags.indexOf(tagItem.tag) !== -1);
					if (filteredContacts && filteredContacts.length > 0) {
						tagsList.push({ ...tagItem, contacts: filteredContacts });
					}
				});
		}

		this.setState({ tagsList });

		this.componentIsMounted = true;
	};

	componentDidMount = async () => {
		setTimeout(() => {
			this.setPageData();
		}, 500);
		await this.props.getMyTags();

		if (!this.componentIsMounted) {
			return;
			const state: any = this.props.history.location?.state;
			const availableContactIds = state?.availableContactIds || [];
			const currentTagname = state?.currentTagname;
			const newTagname = state?.newTagname;
			const isTagNameChanged = currentTagname && newTagname && currentTagname !== newTagname;

			let [user, contacts] = await this.getData();

			const filteredContacts: any = [];
			contacts.forEach((_contact: any) => {
				if (_contact.chatTags && _contact.chatTags.indexOf(currentTagname) !== -1) {
					filteredContacts.push(_contact);
				}
			});

			if (isTagNameChanged || availableContactIds?.length > 0 || (filteredContacts.length > 0 && filteredContacts.length !== availableContactIds?.length)) {
				let tags = user.tags || [];

				const tempConversations = user?.conversations || [];

				tempConversations.forEach((_conversation: any) => {
					if (!_conversation.chatTags) {
						_conversation.chatTags = [];
					}

					// for add remove new members to the tag
					if (availableContactIds.indexOf(_conversation._id) !== -1) {
						if (currentTagname && _conversation.chatTags && _conversation.chatTags?.indexOf(currentTagname) === -1) {
							_conversation.chatTags.push(currentTagname);
						}
					} else {
						if (_conversation.chatTags && _conversation.chatTags?.indexOf(currentTagname) !== -1) {
							_conversation.chatTags.splice(_conversation.chatTags.indexOf(currentTagname), 1);
						}
					}

					// for update tag name of current contacts
					if (isTagNameChanged) {
						if (_conversation.chatTags && _conversation.chatTags?.indexOf(currentTagname) !== -1) {
							_conversation.chatTags[_conversation.chatTags?.indexOf(currentTagname)] = newTagname;
						}
					}
				});

				contacts.forEach((_contact: any) => {
					if (!_contact.chatTags) {
						_contact.chatTags = [];
					}

					// for add remove new members to the tag
					if (availableContactIds.indexOf(_contact._id) !== -1) {
						if (currentTagname && _contact.chatTags && _contact.chatTags?.indexOf(currentTagname) === -1) {
							_contact.chatTags.push(currentTagname);
						}
					} else {
						if (_contact.chatTags && _contact.chatTags?.indexOf(currentTagname) !== -1) {
							_contact.chatTags.splice(_contact.chatTags.indexOf(currentTagname), 1);
						}
					}

					// for update tag name of current contacts
					if (isTagNameChanged) {
						if (_contact.chatTags && _contact.chatTags?.indexOf(currentTagname) !== -1) {
							_contact.chatTags[_contact.chatTags?.indexOf(currentTagname)] = newTagname;
						}
					}
				});

				if (availableContactIds.length === 0) {
					const tagIndex = tags.indexOf(currentTagname);
					tags.splice(tagIndex, 1);
				}

				if (isTagNameChanged) {
					if (tags.indexOf(currentTagname) !== -1) {
						tags[tags.indexOf(currentTagname)] = newTagname;
					}
				}

				// this.props.pageLoading({ loaderMessage: 'Saving Tag...' });
				const userPostData: any = {
					_id: user._id,
					tags: tags,
					contacts: contacts,
					updateServer: true,
					callBack: (status: boolean, response: any) => {
						if (status) {
							const userData = {
								...user,
								tags: tags,
								contacts: contacts,
							};
							if (tempConversations && tempConversations.length > 0) {
								userData['conversations'] = tempConversations;
							}
							store.dispatch({
								type: DASHBOARD_INIT,
								payload: { user: userData },
							});
							setTimeout(() => {
								this.setPageData();
							}, 500);
						} else {
							store.dispatch({
								type: SHOW_TOAST,
								payload: {
									showToast: true,
									toastMessage: response || 'Tags update failed',
								},
							});
						}
					},
				};
				if (tempConversations && tempConversations.length > 0) {
					userPostData['conversations'] = tempConversations;
				}
				// this.props.updateUser(userPostData);
			} else {
				await this.setPageData();
			}
		}
	};

	setSearchText() {}
	searchAllGroups(searchValue: string, props: any) {
		Navigation.openSearchContacts({
			searchTerm: searchValue,
		});
	}

	_onContactSelect = (cont: any) => {};

	_renderSwitchItem = (title: any, value: any, onChange: any) => {
		return (
			<div className={'swtich-item'} slot="end">
				<IonLabel>{title}</IonLabel>
				<IonToggle
					slot="end"
					className="toggle-customised"
					mode="ios"
					checked={value}
					onIonChange={(event: any) => {
						if (this.componentIsMounted) {
							onChange(event);
						}
					}}
				/>
			</div>
		);
	};

	onDeleteTags = async () => {
		await this.props.deleteTag({ tags: this.state.selectedTags });

		this.setPageData();
		const { myTags } = this.props.profile;

		if (myTags?.length === 0) {
			this.setState({
				isManagingTag: false,
				selectedTags: [],
				showToast: true,
				toastIcon: checkmarkOutline,
				toastMessage: 'Deleted',
				showDeletedAllBgMsg: true,
			});

			setTimeout(() => {
				this.setState({
					showToast: false,
					toastIcon: '',
					toastMessage: '',
					showDeletedAllBgMsg: false,
				});
			}, 2000);
		} else {
			this.setState({ isManagingTag: false, selectedTags: [] });
		}
	};

	openAddNewTagPage = () => {
		sharedService.callBackData = { taginfo: null };
		Navigation.openAddMembers({
			actionType: AddMembersPageActionType.ChooseContactWithCreateNewTag,
		});
	};

	render() {
		const { tagsList } = this.state;

		return (
			<>
				<IonPage className="tags-page">
					<TopNavbar
						{...this.props}
						isHideRightButton={false}
						showBack={true}
						hideSearchBar={true}
						// `Tag${taggedContacts?.length || ''})`
						pageTitle={this.state.isManagingTag ? 'Manage Tags' : `Tags (${tagsList ? tagsList.length : 0})`}
						// 'Tag' + '(' + (tagsList ? tagsList.length : 0) + ')'}
						onRightButtonPress={this.openAddNewTagPage}
						onLeftButtonPress={() => {
							// Navigation.back();
							Navigation.contactsTab();
						}}
					/>

					{this.state.showDeletedAllBgMsg ? (
						<div className="deleted-all-msg-view">
							<div className="msg">Create Tags to help users keep their chats organized.</div>
						</div>
					) : (
						<>
							{tagsList && tagsList.length > 0 ? (
								<IonContent className="main-content-profile has-bottom-navbar has-topbar">
									<IonList lines="none">
										{tagsList?.map((item, key) => {
											const onTagInfo = () => {
												Navigation.openTagInfo({
													tagDetail: item,
												});
											};
											const contactList = item.contacts;

											return (
												<div key={key} className="tag-item-container">
													<IonListHeader onClick={onTagInfo}>
														{item.tag} ({contactList.length})
													</IonListHeader>
													<IonItem className="contacts-container" onClick={onTagInfo} style={{ opacity: item.hide || this.props.profile.hideAllTags ? 0.5 : 1 }}>
														<div className="starred-wrapper">
															{this.state.isManagingTag && (
																<div className="select-checkbox-view" onClick={(event) => event.stopPropagation()}>
																	<IonCheckbox
																		mode={'ios'}
																		slot={'start'}
																		checked={this.state.selectedTags.indexOf(item.tag) !== -1}
																		onIonChange={(event) => {
																			const isChecked = event.detail.checked;
																			const ids = this.state.selectedTags;
																			const tagIdIndex = ids.indexOf(item.tag);

																			if (isChecked) {
																				if (tagIdIndex === -1) {
																					ids.push(item.tag);
																				}
																			} else {
																				if (tagIdIndex !== -1) {
																					ids.splice(tagIdIndex, 1);
																				}
																			}
																			this.setState({ selectedTags: ids });
																		}}
																	/>
																</div>
															)}

															{contactList.map((_contact: ContactItem, innerKey: any) => {
																return (
																	<div className="contact-item" key={innerKey}>
																		<img alt="" style={{ borderRadius: '3px' }} src={SharedService.extractProfilePhoto(_contact.userInfo.profilePhotos)?.url || 'assets/img/default-profile-photo.png'} />
																		<div className="username">{_contact.alias || _contact.userInfo.profileName || _contact.username}</div>
																	</div>
																);
															})}
														</div>
													</IonItem>
													<IonItem className="toggle-item" disabled={this.props.profile.hideAllTags}>
														{this._renderSwitchItem('Show in Chats', this.props.profile.hideAllTags ? false : !item.hide, (event: any) => {
															if (event.detail.checked !== undefined && !this.tagUpdating) {
																this.tagUpdating = true;
																this.props.setTagsVisibility({ tagsVisibility: { tags: [{ tag: item.tag, hide: !item.hide }] } }).then(() => {
																	this.setPageData();
																	setTimeout(() => {
																		this.tagUpdating = false;
																	}, 500);
																});
															}
														})}
													</IonItem>
												</div>
											);
										})}
									</IonList>
								</IonContent>
							) : (
								<>
									{this.componentIsMounted && (
										<div className="empty-tags-screen-message">
											<span className="message">Tags are to help users keep their chats organized.</span>
											<IonButton fill="clear" slot="end" className="add-tag-btn" onClick={this.openAddNewTagPage}>
												Create Tag
											</IonButton>
										</div>
									)}
								</>
							)}

							{tagsList && tagsList?.length > 0 && (
								<IonItem className="manage-toggle-item" lines="none">
									{!this.state.isManagingTag && tagsList && tagsList.length > 0 && (
										<>
											<IonButton
												fill="clear"
												className="title"
												onClick={() => {
													this.setState({ isManagingTag: true });
												}}
											>
												Manage
											</IonButton>

											{this._renderSwitchItem('Hide All', this.props.profile.hideAllTags, (event: any) => {
												if (!this.tagUpdating) {
													this.tagUpdating = true;
													this.props.setTagsVisibility({ tagsVisibility: { hideAll: !this.props.profile.hideAllTags } }).then(() => {
														setTimeout(() => {
															this.tagUpdating = false;
														}, 500);
													});
												}
											})}
										</>
									)}

									{this.state.isManagingTag && (
										<>
											<IonButton
												fill="clear"
												className="cancel-btn"
												onClick={() => {
													this.setState({ isManagingTag: false, selectedTags: [] });
												}}
											>
												Cancel
											</IonButton>
											<IonButton
												fill="clear"
												slot="end"
												className="delete-tags-btn"
												disabled={this.state.selectedTags.length === 0}
												onClick={() => {
													this.setState({ showActionSheetFor: true });
												}}
											>
												Delete ({this.state.selectedTags.length > 0 ? this.state.selectedTags.length : '0'})
											</IonButton>
										</>
									)}
								</IonItem>
							)}
						</>
					)}
				</IonPage>

				<AddNewTagPopup
					show={this.state.showAddTagPopup}
					onCloseCallBack={() => {
						this.setState({ showAddTagPopup: false });
					}}
					onTagCreated={(value: string) => {}}
				/>
				<IonActionSheet
					mode="ios"
					header="Contacts in tag will not be deleted."
					isOpen={this.state.showActionSheetFor}
					onDidDismiss={() => this.setState({ showActionSheetFor: false })}
					cssClass="delete-tags-actionsheet"
					buttons={[
						{
							cssClass: 'thread-menu-button',
							text: 'Delete',
							role: 'destructive',
							handler: () => {
								this.onDeleteTags();
							},
						},
						{
							text: 'Cancel',
							handler: () => {},
						},
					]}
				></IonActionSheet>

				<ToastStylish
					show={this.state.showToast}
					message={this.state.toastMessage}
					svgIcon={this.state.toastIcon}
					onClose={() => {
						if (this.componentIsMounted) {
							this.setState({
								showToast: false,
								toastIcon: '',
								toastMessage: '',
								showDeletedAllBgMsg: false,
							});
						}
					}}
				/>
			</>
		);
	}
}

const mapStateToProps = (state: ReduxStates) => {
	return {
		profile: state.profile,
		contact: state.contact,
	};
};

const mapDispatchToProps = (dispatch: any) => ({
	getMyTags: () => dispatch(getMyTags()),
	setTagsVisibility: (payload: SetTagVisibilityRequestData) => dispatch(setTagsVisibility(payload)),
	deleteTag: (payload: DeleteTagRequestData) => dispatch(deleteTag(payload)),

	//addGroup: (payload: any) => dispatch(addGroup(payload)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Tags);
