import React from 'react';
import { IonActionSheet, IonButton, IonContent, IonImg, IonPage } from '@ionic/react';
import './style.scss';
import { RouteComponentProps } from 'react-router';
import { DefaultProfilePic } from '../../helpers/common';
import { connect } from 'react-redux';
import { updateChatOnlyProfilePhoto } from '../../redux/actions/profile';
import { showToast } from '../../redux/actions/global';
import store from '../../redux/store';
import { SHOW_TOAST } from '../../redux/constants/common';
import ImageEditor, { OpenEditorType } from '../../modals/ImageEditor/ImageEditor';
import { TopNavbar } from '../common/header/topbar';
import CameraService from '../../services/cameraService';
import { locale } from '../../locales/local';
import { GlobalStateTypes, ProfileStateTypes, ReduxStates } from '../../types/redux-states';
import { ProfilePhotoItem } from '../../types/profile';
import SharedService from '../../services/sharedService';
import { Navigation } from '../../services/navigationService';

interface iProps extends RouteComponentProps<{ name: string }> {
	profile: ProfileStateTypes;
	updateChatOnlyProfilePhoto: (data: File) => Promise<any>;
	location: any;
	showToast: (payload: GlobalStateTypes) => void;
}
interface iState {
	showEditor: boolean;
	selectedImage: any;
	editedImage: any;
	loggedInUser?: any;
	showPhotoOptionsActionSheet: boolean;
	loadingMessage: string;
	showToast: boolean;
	showBottomMenu: boolean;
	toastMessage: string;
}

class UploadPhotoChatOnly extends React.Component<iProps, iState> {
	cameraService = new CameraService();
	componentIsMounted: Boolean = false;
	componentIsUpdated: Boolean = false;

	constructor(props: iProps) {
		super(props);

		this.state = {
			loggedInUser: {},
			showBottomMenu: false,
			showPhotoOptionsActionSheet: false,
			showEditor: false,
			editedImage: null,
			loadingMessage: '',
			showToast: false,
			toastMessage: '',
			selectedImage: null,
		};
	}

	setPageData = async () => {
		this.componentIsMounted = true;
		this.setState((state: any, props: any) => ({
			...state,
			loggedInUserChatOnly: this.props.profile.loggedInUserChatOnlyProfile,
		}));
	};

	componentDidMount = async () => {
		if (!this.componentIsMounted) {
			await this.setPageData();
		}
	};

	componentDidUpdate = async () => {
		if (!this.componentIsUpdated) {
			this.componentIsUpdated = true;
			await this.setPageData();
		}
	};

	detectWebcam = (callback: any) => {
		let md = navigator.mediaDevices;
		if (!md || !md.enumerateDevices) return callback(false);
		md.enumerateDevices().then((devices) => {
			callback(devices.some((device) => 'videoinput' === device.kind));
		});
	};

	uploadPhoto = async (file: File) => {
		if (file) {
			this.props.updateChatOnlyProfilePhoto(file).finally(() => {
				Navigation.back();
			});
		} else {
			store.dispatch({
				type: SHOW_TOAST,
				payload: {
					showToast: true,
					toastMessage: locale.profile.upload_photo.select_image,
				},
			});
		}
	};

	onPinturaEditingDone = (file: any) => {
		// encode the file using the FileReader API
		const reader = new FileReader();
		reader.onloadend = async () =>
			this.setState({ showEditor: false, editedImage: reader.result }, () => {
				this.uploadPhoto(file);
			});
		reader.readAsDataURL(file);
	};

	_getActionsheetOptions = () => {
		const takePhoto = {
			text: locale.profile.upload_photo.take_photo,
			handler: () => {
				this.cameraService.capturePhotoFromCamera((photo: Array<File>) => {
					this.setState({
						selectedImage: photo[0],
						showEditor: true,
					});
				}, true);
			},
		};

		const chooseFromAlbum = {
			text: locale.profile.upload_photo.choose_album,
			handler: () => {
				this.cameraService.choosePhotoFromAlbum((photo: Array<File>) => {
					this.setState({
						selectedImage: photo[0],
						showEditor: true,
					});
				});
			},
		};

		const cancelPhoto = {
			class: 'cancel',
			text: locale.global.cancel,
			role: 'cancel',
			handler: () => {
				this.setState({ showPhotoOptionsActionSheet: false });
			},
		};

		return [takePhoto, chooseFromAlbum, cancelPhoto];
	};

	addAnother = () => {
		Navigation.back();
	};

	render() {
		const { profilePhotos } = this.props.profile.loggedInUserChatOnlyProfile || {};

		const activeChatOnlyProfilePhoto: ProfilePhotoItem = SharedService.extractProfilePhoto(profilePhotos!)!;

		let profilePhotoUrl = DefaultProfilePic;
		if (this.state.editedImage) {
			profilePhotoUrl = this.state.editedImage;
		} else if (profilePhotos && profilePhotos.length > 0) {
			profilePhotoUrl = profilePhotos[0].url;
		}

		return this.componentIsMounted ? (
			<IonPage className="upload-photo-chatonly">
				<TopNavbar
					{...this.props}
					pageTitle={'Chats Only'}
					hideSearchBar={true}
					isHideLeftButton={this.props.location.state?.chatOnlyProfile ? false : true}
					showBack={true}
					isHideRightButton={this.props.location.state?.chatOnlyProfile ? false : true}
					onRightButtonPress={() => {
						this.setState({ showPhotoOptionsActionSheet: true });
					}}
					onLeftButtonPress={() => {
						Navigation.back();
					}}
				/>

				<IonContent className={'upload-chat-only'}>
					<div className="chat-only">Chats only profile photo purpose is to keep your personal profile seperate because not everyone we meet deserves to be in the friend zone</div>
					<div className="profile-placeholder-container" onClick={() => this.setState({ showPhotoOptionsActionSheet: true })}>
						<IonImg className="profile-placeholder" src={activeChatOnlyProfilePhoto ? activeChatOnlyProfilePhoto?.url : profilePhotoUrl}></IonImg>
					</div>
					{!this.props.location.state?.chatOnlyProfile &&
						(activeChatOnlyProfilePhoto ? (
							<div className="buttons">
								<IonButton
									className="add-button-buttom"
									onClick={() => {
										this.setState({ showPhotoOptionsActionSheet: true });
									}}
								>
									Edit Photo
								</IonButton>
								<IonButton className="another-button-buttom" onClick={this.addAnother}>
									Cancel
								</IonButton>
							</div>
						) : (
							<div className="buttons">
								<IonButton
									className="add-button-buttom"
									onClick={() => {
										this.setState({ showPhotoOptionsActionSheet: true });
									}}
								>
									Add Photo
								</IonButton>
								<IonButton className="another-button-buttom" onClick={this.addAnother}>
									Add Another Time
								</IonButton>
							</div>
						))}
				</IonContent>

				<ImageEditor
					imageOptions={{ targetWidth: 512, targetHeight: 512, quality: 0.5 }}
					openEditorType={OpenEditorType.FOR_PROFILE_GROUP_PHOTO}
					selectedImage={this.state.selectedImage}
					show={this.state.showEditor}
					onClose={() => {
						this.setState({ showEditor: false });
					}}
					outputFormat={'file'}
					onSave={this.onPinturaEditingDone}
				/>

				<IonActionSheet
					mode="ios"
					isOpen={this.state.showPhotoOptionsActionSheet}
					onDidDismiss={() => {
						this.setState({ showPhotoOptionsActionSheet: false });
					}}
					cssClass="upload-photo-option-action-sheet"
					buttons={this._getActionsheetOptions()}
				/>
			</IonPage>
		) : (
			<></>
		);
	}
}

const mapStateToProps = (state: ReduxStates) => {
	return {
		profile: state.profile,
		global: state.global,
	};
};

const mapDispatchToProps = (dispatch: any) => ({
	updateChatOnlyProfilePhoto: (payload: File) => dispatch(updateChatOnlyProfilePhoto(payload)),
	showToast: (payload: GlobalStateTypes) => dispatch(showToast(payload)),
});

export default connect(mapStateToProps, mapDispatchToProps)(UploadPhotoChatOnly);
