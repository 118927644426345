import { IonModal } from '@ionic/react';
import React, { useCallback, useState } from 'react';
import { MyProfile } from '../../types/profile';
import QrCodeComponent from './QrCodeComponent';
import QrCodeScanModal from './QrCodeScanModal';
import './style.scss';

interface QuickAccessModalProps {
	loggedInUserProfile: MyProfile;
	show: boolean;
	showMyQrCodeOnly?: boolean;
	onClose?: any;
	onDownloadQrCode?: any;
}

const QuickAccessModal: React.FC<QuickAccessModalProps> = ({ show, onClose, loggedInUserProfile, onDownloadQrCode }: QuickAccessModalProps) => {
	const [showQrScanModal, setShowQrScanModal] = useState(false);

	const onCloseCallBack = useCallback(
		(event: any) => {
			event.stopPropagation();
			onClose && onClose();
		},
		[onClose]
	);

	return (
		<IonModal animated={false} showBackdrop={true} isOpen={show} onDidDismiss={onCloseCallBack} swipeToClose={false} cssClass="quick-access-modal">
			<div className="hotkey-container" onClick={onCloseCallBack}>
				<div className="hotkey-content-container" onClick={onCloseCallBack}>
					<QrCodeComponent handleScanQrCode={() => setShowQrScanModal(true)} showScanButton={true} loggedInUser={loggedInUserProfile} onClose={() => {}} />
				</div>
			</div>

			<QrCodeScanModal
				show={showQrScanModal}
				onClose={() => {
					setShowQrScanModal(false);
				}}
			/>
		</IonModal>
	);
};

export default QuickAccessModal;
