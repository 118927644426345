import React from 'react';
import { IonContent, IonPage } from '@ionic/react';
import './groupChats.scss';
import { RouteComponentProps } from 'react-router';
import { TopNavbar } from '../../common/header/topbar';
import { Navigation } from '../../../services/navigationService';
import { ContactAlbhabetView } from '../components/ContactAlbhabetView';
import { GroupStateTypes, ReduxStates } from '../../../types/redux-states';
import { connect } from 'react-redux';
import { GroupsList } from '../components/GroupsList';
import { AddMembersPageActionType } from '../../../services/enumService';
import _ from 'lodash';

interface iProps extends RouteComponentProps<{ name: string }> {
	group: GroupStateTypes;
}
interface iState {
	searchTerm: string;
}

class GroupChats extends React.Component<iProps, iState> {
	constructor(props: iProps) {
		super(props);
		this.state = {
			searchTerm: '',
		};
	}

	_getGroups = () => {
		if (this.state.searchTerm) {
			return (
				this.props.group.groups?.filter((group) => {
					return group.mucName.toLowerCase().includes(this.state.searchTerm.toLowerCase());
				}) ?? []
			);
		}
		return this.props.group.groups ?? [];
	};

	render() {
		let groups = this.props.group.groups || [];

		let alphabetsList = groups?.map((group: any) => {
			const groupName = group?.mucName || '';
			return groupName.charAt(0).toUpperCase();
		});
		alphabetsList = _.uniq(alphabetsList).sort();

		return (
			<IonPage className="tags-page">
				<TopNavbar
					{...this.props}
					isHideRightButton={false}
					showBack={true}
					searchTypeHandler={(event: string) => this.setState({ searchTerm: event })}
					pageTitle={'Group Chats'}
					onRightButtonPress={() => {
						Navigation.openAddMembers({
							actionType: AddMembersPageActionType.ChooseContactForGroup,
						});
					}}
				/>
				<IonContent id="add-members-content">
					<GroupsList props={this.props} groups={this._getGroups()} />
					<ContactAlbhabetView
						alphabets={alphabetsList}
						showStarredInsideAlbhabetList={true}
						// onAlphabetSelect={(albhabet: any) => {
						// 	this.scrollToContactSection('section_' + albhabet, 'contacts-content');
						// }}
						// onGoToStarredContact={async () => {
						// 	this.scrollToContactSection('section_starred', 'contacts-content');
						// }}
					/>
				</IonContent>
			</IonPage>
		);
	}
}
const mapStateToProps = (state: ReduxStates) => {
	return {
		group: state.group,
	};
};

const mapDispatchToProps = (dispatch: any) => ({});
export default connect(mapStateToProps, mapDispatchToProps)(GroupChats);
