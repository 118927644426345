import React from 'react';
import { IonButton, IonContent, IonIcon, IonImg, IonLabel, IonPage, IonThumbnail } from '@ionic/react';
import './style.scss';
import { RouteComponentProps } from 'react-router';
import { IonItem } from '@ionic/react';
import { DefaultProfilePic, fetchProfilePic, info } from '../../helpers/common';
//import { BS } from '../../global.d';
import { TopNavbar } from '../common/header/topbar';
import { locale } from '../../locales/local';
import { connect } from 'react-redux';
import { getMyProfile } from '../../redux/actions/profile';
import MyQrCode from '../../components/MyQRCode';
import { ProfilePhotoItem } from '../../types/profile';
import { ProfileStateTypes, ReduxStates } from '../../types/redux-states';
import SharedService from '../../services/sharedService';
import { dbService } from '../../services/dbService';
import { Navigation } from '../../services/navigationService';
import ChatSearchBar from '../../modals/ChatSearchBar/ChatSearchBar';

interface iProps extends RouteComponentProps<{ name: string }> {
	location: any;
	profile: ProfileStateTypes;
	getMyProfile: () => Promise<unknown>;
}
interface iState {
	loggedInUser: any;
	shouldBack: boolean;
	profilePic: any;
	showChatSearchBar: boolean;
}

class ProfilePage extends React.Component<iProps, iState> {
	starredContacts = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18];
	componentIsMounted: Boolean = false;
	componentIsUpdated: Boolean = false;
	constructor(props: iProps) {
		super(props);

		this.state = {
			loggedInUser: undefined,
			shouldBack: props.location?.state?.shouldBack,
			profilePic: undefined,
			showChatSearchBar: false,
		};
	}

	setPageData = async () => {
		let user: any = await dbService.me(),
			profilePic: any = await fetchProfilePic('', 'user', true);

		this.setState((state: any, props: any) => ({
			...state,
			loggedInUser: user,
			profilePic: profilePic,
		}));
	};

	componentDidMount = async () => {
		if (!this.componentIsMounted) {
			await this.props.getMyProfile();
			await this.setPageData();
		}
	};

	componentDidUpdate = async () => {
		if (!this.componentIsUpdated) {
			this.componentIsUpdated = true;
			await this.setPageData();
		}
	};

	render() {
		const { profilePhotos, username, profileName, profession, region, bio, website } = this.props.profile.loggedInUserProfile || {};

		const activeProfilePhoto: ProfilePhotoItem = SharedService.extractProfilePhoto(profilePhotos!)!;

		return (
			<IonPage className="profile-page">
				<TopNavbar
					{...this.props}
					pageTitle={username!}
					// showSearchIcon={!this.state.showChatSearchBar}
					// onSearchIconPress={() => this.setState({ showChatSearchBar: true })}
					isHideLeftButton={!this.state.shouldBack}
					showBack={this.state.shouldBack}
					hideSearchBar={true}
					onLeftButtonPress={() => {
						Navigation.back();
					}}
				/>

				<IonContent className="main-content-profile has-bottom-navbar has-topbar">
					<div className="inner-content">
						<div className="user-name-item">
							<div className="profile-row">
								<IonThumbnail className="profile-photo" onClick={() => Navigation.openEditProfile()}>
									<IonImg title="Change" src={activeProfilePhoto?.url || DefaultProfilePic} />
								</IonThumbnail>

								<div className="half-rounded-btns" onClick={(event) => event.stopPropagation()}>
									<IonButton className="wallet">
										<IonIcon src="./assets/icon/wallet.svg"></IonIcon>
									</IonButton>
									<IonButton fill="outline" className="favorite">
										<IonIcon src="./assets/icon/savedRed.svg"></IonIcon>
									</IonButton>
								</div>
							</div>

							<IonLabel className="name" color="light">
								{profileName}
							</IonLabel>

							{profession && (
								<IonLabel className="professiontxt" color="light">
									{profession}
								</IonLabel>
							)}
							{bio && (
								<IonLabel className="quote" color="light">
									{bio}
								</IonLabel>
							)}
							{website && (
								<a href={website} className="website">
									{/* target={'_blank'} */}
									{website}
								</a>
							)}

							{region && (
								<IonLabel className="region" color="light">
									{region}
								</IonLabel>
							)}
						</div>

						<IonLabel className="moment-label">{locale.global.moments}</IonLabel>

						<IonItem
							className="starred-wrapper"
							lines="none"
							detail={true}
							onClick={() => {
								Navigation.openMoments();
							}}
						>
							{this.starredContacts.map((e: any, index: any) => {
								return (
									<div key={index} className="starred">
										<IonThumbnail onClick={() => info('')}>
											<IonImg src={`https://randomuser.me/api/portraits/${e % 2 === 0 ? 'men' : 'women'}/${e}.jpg`} />
										</IonThumbnail>
									</div>
								);
							})}
							<div style={{ minWidth: '20px' }}></div>
						</IonItem>

						<div className="qrimage">
							<MyQrCode myProfilePhoto={activeProfilePhoto?.url || DefaultProfilePic} />
						</div>

						{/* <div className="my-qr-view">
							<img
								onClick={() => {
									Navigation.openMyQRCode()
								}}
								src={loggedInUser?.qrCode}
								alt="my-qr-view"
							/>
						</div> */}
					</div>
					<div className='blank'></div>
				</IonContent>
			</IonPage>
		);
	}
}

const mapStateToProps = (state: ReduxStates) => {
	return {
		profile: state.profile,
	};
};

const mapDispatchToProps = (dispatch: any) => ({
	getMyProfile: () => dispatch(getMyProfile()),
});
export default connect(mapStateToProps, mapDispatchToProps)(ProfilePage);
