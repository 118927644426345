import { IonNote, IonRow, IonSpinner } from '@ionic/react';
import moment from 'moment';
import React, { forwardRef, useCallback, useEffect, useImperativeHandle, useMemo, useRef, useState } from 'react';
import { connect } from 'react-redux';
import { Virtuoso } from 'react-virtuoso';
import { DefaultProfilePic, info, isPersonalNotepad } from '../../../helpers/common';
import { usePrependedMessagesCount } from '../../../hooks/usePrependMessagesCount';
import { locale } from '../../../locales/local';
import { getMessages } from '../../../redux/actions/chat';
import { showToast } from '../../../redux/actions/global';
import { AddMembersPageActionType, ChatContactsPageActionType, ChatMediaType, ChatType, ContactAddBy, MessageSendStatus, MessageType, ProfileFriendActionTypes, PubSubEventType } from '../../../services/enumService';
import { Navigation } from '../../../services/navigationService';
import SharedService, { sharedService } from '../../../services/sharedService';
import { GetProfileByUsernameRequestData } from '../../../types/api-requests';
import { ContactItem } from '../../../types/contact';
import { GroupItem, MemberItem } from '../../../types/group';
import { Media, Message } from '../../../types/message';
import { MyProfile, UserProfile } from '../../../types/profile';
import { ChatStateTypes, ContactStateTypes, GlobalStateTypes, GroupStateTypes, ProfileStateTypes, ReduxStates, ThreadStateTypes } from '../../../types/redux-states';
import { ThreadItem } from '../../../types/thread';
import ThreadComponent from './thread';
import MediaPreviewModal from '../../../modals/MediaPreviewModal';
import MoveThreadPopUp from '../../../modals/MoveThreadPopUp';
import { getProfileByUsername } from '../../../redux/actions/profile';
import { apiService } from '../../../services/apiService';
import { xmpp } from '../../../services/xmpp';
import PubSub from 'pubsub-js';
import PreviewFile from '../../../modals/PreviewFile';
import ShareLocationModal from '../../../modals/ShareLocation';

const PREPEND_OFFSET = 10 ** 7;
let isMessageFetching: boolean = false;
let noMoreData: boolean = false;
let previousMessageTime: any = '';
let componentHasMounted: Boolean = false;
let personalNotepad: Boolean = false;

type VirtualizedMessageListProps = {
	thread?: ThreadStateTypes;
	profile?: ProfileStateTypes;
	chat?: ChatStateTypes;
	contact?: ContactStateTypes;
	group?: GroupStateTypes;
	showToastView: (data: GlobalStateTypes) => void;
	getProfileByUsername: (data: GetProfileByUsernameRequestData) => Promise<UserProfile>;

	contactItem: ContactItem;
	groupItem: GroupItem;
	loggedInUserProfile: MyProfile;
	selectedThread?: ThreadItem;
	getMessages?: any;
	onSelectMessage?: any;
	onDselect?: any;
};

const VirtualizedMessageList = ({ onSelectMessage, showToastView, contactItem, groupItem, selectedThread, loggedInUserProfile, chat, profile, contact, group, getProfileByUsername, getMessages, onDselect, thread }: VirtualizedMessageListProps) => {
	useEffect(() => {
		const threadChangePubSubToken = PubSub.subscribe(PubSubEventType.ThreadChangeInChat, (msg: string, data: any) => {
			if (data.action === PubSubEventType.ThreadChangeInChat) {
				isMessageFetching = true;
				setChatMessagesList([]);
				_fetchMessages(0, data.thread);
			}
		});
		return () => {
			PubSub.unsubscribe(threadChangePubSubToken);
		};
	}, []);

	const [loadingMore, setLoadingMore] = useState<boolean>(false),
		[chatMessagesList, setChatMessagesList] = useState<Array<Message>>([]),
		[hasMoreNewer, setHasMoreNewer] = useState<boolean>(false),
		[selectingMessages, setSelectingMessages] = useState<boolean>(false),
		[selectedMessages, setSelectedMessages] = useState<any[]>([]),
		[chatMediaMessage, setChatMediaMessage] = useState<any>(),
		[highlightedMessageId, setHighlightedMessageId] = useState<string>(''),
		[selectedMediaMessageKey, setSelectedMediaMessageKey] = useState<any>(),
		[showMediaPreview, setShowMediaPreview] = useState<boolean>(false),
		[showDocumentViewer, setShowDocumentViewer] = useState<boolean>(false),
		[selectedDocumentMedia, setSelectedDocumentMedia] = useState<Media | undefined>(undefined),
		[overscan, setOverscan] = useState<number | { main: number; reverse: number }>(5),
		[showMoveThread, setShowMoveThread] = useState<boolean>(false),
		sharedLocationModalRef: React.ForwardedRef<any> = useRef();
	const numItemsPrepended = usePrependedMessagesCount(chatMessagesList, false);
	const selectedMessagesList: any = selectedMessages || [];

	const atBottom = useRef(true),
		virtuosoRef = useRef<any>(),
		audioUrlRef = useRef<any>(null),
		audioRef = useRef<any>(null);

	const membersByUsername: { [username: string]: MemberItem } = {};
	const contactByUsername: { [username: string]: ContactItem } = {};
	if (chat?.type === ChatType.GROUP) {
		contact?.contacts?.forEach((_contact) => (contactByUsername[_contact.username] = _contact));
		group?.activeGroupMembers?.forEach((member) => (membersByUsername[member.username] = member));
	}

	useEffect(() => {
		personalNotepad = isPersonalNotepad();
		componentHasMounted = true;
		return () => {
			componentHasMounted = false;
		};
	}, []);

	const handleMessageListCallBack = useCallback(
		(action, message: Message) => {
			if (action === PubSubEventType.ChatMessage && (message.username === contactItem.username || message.username === groupItem.name || message.username === thread?.activeThreadInChat?.threadId)) {
				const lastMessage = chatMessagesList[chatMessagesList.length - 1];
				const findMessageIndex = chatMessagesList.findIndex((item) => item.messageKey === message.messageKey);
				if (findMessageIndex === -1 && (!lastMessage || ((message.datetime >= lastMessage.datetime || message.status === MessageSendStatus.PendingUpload) && message.messageKey !== lastMessage.messageKey))) {
					chatMessagesList.push(message);
					info('VirtualizedMessageList::New message added to chat list', message);
					setChatMessagesList(chatMessagesList);
				} else if (findMessageIndex >= 0 && chatMessagesList[findMessageIndex] && message.status !== chatMessagesList[findMessageIndex].status) {
					info('VirtualizedMessageList::New message updated to chat list', message);
					chatMessagesList[findMessageIndex] = message;
					setChatMessagesList([...chatMessagesList]);
				}
			} else if (action === PubSubEventType.Translated) {
				if (chatMessagesList && chatMessagesList.length > 0) {
					const indexOfMsg = chatMessagesList.findIndex((item) => item.messageKey === message.messageKey);
					chatMessagesList[indexOfMsg] = message;
					setChatMessagesList([...chatMessagesList]);
				}
			}
		},
		[chatMessagesList]
	);

	useEffect(() => {
		// we come to the page from the chat list
		isMessageFetching = true;
		_fetchMessages(0);
	}, []);

	useEffect(() => {
		const isSelectMsgPubSubToken = PubSub.subscribe(PubSubEventType.IsSelectedMsg, (msg: string, data: any) => {
			if (data.action === PubSubEventType.IsSelectedMsg) {
				setSelectingMessages(data.isSelected);
			}
		});

		const onCancelPubSubToken = PubSub.subscribe(PubSubEventType.OnCancel, (msg: string, data: any) => {
			if (data.action === PubSubEventType.OnCancel) {
				setSelectingMessages(false);
				setSelectedMessages([]);
			}
		});

		const totalChatMessagePubSubToken = PubSub.subscribe(PubSubEventType.TotalChatMessages, (msg: string, data: any) => {
			if (data.action === PubSubEventType.TotalChatMessages) {
				PubSub.publish(PubSubEventType.SelectedMsgLength, {
					action: PubSubEventType.SelectedMsgLength,
					selectedMessageLength: data.totalMessageList.length,
					allMsgLength: data.totalMessageList.length,
				});

				PubSub.publish(PubSubEventType.SelectedMsgLength, {
					action: PubSubEventType.SelectedMsgLength,
					selectedMessageLength: data.totalMessageList.length,
					allMsgCount: data.totalMessageList,
				});
				setSelectedMessages([...data.totalMessageList]);
			}
			// else if (msg.action === PubSubEventType.TotalChatMessages && msg && msg.totalMessageList.length === 0 && msg.selectedMessage) {
			// 	setSelectedMessages([]);
			// }
		});

		const chatMessageToken = PubSub.subscribe(PubSubEventType.ChatMessage, (msg: string, data: any) => {
			if (!isMessageFetching) {
				const message = data.message as Message;
				handleMessageListCallBack(data.action, message);
			}
		});

		return () => {
			PubSub.unsubscribe(chatMessageToken);
			PubSub.unsubscribe(totalChatMessagePubSubToken);
			PubSub.unsubscribe(isSelectMsgPubSubToken);
			PubSub.unsubscribe(onCancelPubSubToken);
		};
	}, [isMessageFetching]);

	const loadMore = () => {
		if (!isMessageFetching && !loadingMore && !noMoreData) {
			isMessageFetching = true;
			setLoadingMore(true);

			if (chat) {
				const virtualsoElements = document.getElementsByClassName('virtualso');
				let virtualsoElement: any = null;
				if (virtualsoElements?.length > 0) {
					virtualsoElement = virtualsoElements[0];
					if (virtualsoElement) {
						virtualsoElement.style.overflowY = 'hidden';
					}
				}

				_fetchMessages(chatMessagesList.length, selectedThread);
			}
		}
	};

	const _openFriendProfile = (profileType: string, userId?: any) => {
		const contactItem = chat?.receiver as ContactItem;
		const groupItem = chat?.receiver as GroupItem;

		if (profileType === 'chat') {
			Navigation.profileFriend({
				data: contactItem?.userInfo,
				friendProfileActionType: ProfileFriendActionTypes.ChatProfileView,
			});
		} else if (profileType === 'me') {
			Navigation.myProfile({ shouldBack: false });
		} else if (profileType === 'groupchat' && userId) {
			let singleContact = contact?.contacts?.find((c: ContactItem) => c.username === userId);
			if (!singleContact) {
				apiService.searchUserByUsernameInDB(userId).then((isFound) => {
					if (!isFound) {
						getProfileByUsername({ username: userId })
							.then((response: UserProfile) => {
								openUserProfile(response);
							})
							.catch((_: any) => {});
					}
				});
			} else {
				Navigation.profileFriend({
					data: singleContact.userInfo,
					friendProfileActionType: ProfileFriendActionTypes.ChatProfileView,
				});
			}
		}
	};

	const loadMoreNewer = () => {};

	const startSelectingMessages = () => {
		if (componentHasMounted) {
			setSelectingMessages(true);
			onSelectMessage();
		}
	};
	const openUserProfile = (user: UserProfile) => {
		Navigation.profileFriend({
			data: user,
			friendProfileActionType: ProfileFriendActionTypes.ContactAdd,
			contactAddBy: ContactAddBy.Id,
		});
	};

	const previewActionHandler = useCallback(
		(media: Media, message: Message) => {
			if (componentHasMounted) {
				if (message?.messageType === MessageType.MEDIA || (message?.messageType === MessageType.FILE && (message.media?.mediaType === ChatMediaType.VIDEO || message.media?.mediaType === ChatMediaType.IMAGE))) {
					setSelectedMediaMessageKey(message.messageKey);
					setShowMediaPreview(true);
				} else if (message?.messageType === MessageType.FILE) {
					if (message.media?.mediaType !== ChatMediaType.AUDIO) {
						setSelectedDocumentMedia(message.media);
						setShowDocumentViewer(true);
					}
				} else if (message?.messageType === MessageType.CONTACT) {
					let username: string = message.body.username;
					if (username && username !== profile?.loggedInUserProfile?.username) {
						apiService.searchUserByUsernameInDB(username).then((isFound) => {
							if (!isFound) {
								getProfileByUsername({ username: username })
									.then((response: UserProfile) => {
										openUserProfile(response);
									})
									.catch((_: any) => {});
							}
						});
					} else {
						info("Can't open own profile");
					}
				} else if (message?.messageType === MessageType.LOCATION) {
					let username: string = message.username;
					if (username && username !== profile?.loggedInUserProfile?.username) {
						setSelectedDocumentMedia(message.body);
						sharedLocationModalRef.current.show({ latitude: message.body.geometry.coordinates[1], longitude: message.body.geometry.coordinates[0] });
					} else {
						info("Can't open Location");
					}
				}
			}
		},
		[componentHasMounted, audioRef, audioUrlRef, sharedLocationModalRef]
	);

	const _startForwardingMessages = (message: any) => {
		Navigation.openChatContacts({ actionType: ChatContactsPageActionType.ForwardMessage, chatDetail: message });
	};
	const pushToSelectedMessages = useCallback(
		(message: any) => {
			setChatMediaMessage(message);
			let selectedIndex = -1;
			selectedMessagesList.some((item: any, key: number) => {
				if (item.messageKey === message.messageKey) {
					selectedIndex = key;
					return true;
				}
			});
			if (selectedIndex >= 0) {
				selectedMessagesList.splice(selectedIndex, 1);
			} else {
				selectedMessagesList.push(message);
			}

			if (componentHasMounted) {
				setSelectedMessages([...selectedMessagesList]);
			}

			PubSub.publish(PubSubEventType.ViewThread, {
				action: PubSubEventType.ViewThread,
				selectedMessageLength: selectedMessages.length,
				message: message,
			});

			PubSub.publish(PubSubEventType.SelectedMsgLength, {
				action: PubSubEventType.SelectedMsgLength,
				selectedMessageLength: selectedMessages.length,
				message: message,
				allMsgLength: chatMessagesList.length,
				allMsgCount: chatMessagesList,
			});
		},

		[chatMessagesList, chatMediaMessage, selectedMessages, componentHasMounted]
	);

	const optionClickHandler = async (optionValue: string, message: any) => {
		if (optionValue === 'threadMenu') {
			setSelectingMessages(true);
			// onDselect();

			pushToSelectedMessages(message);
			setShowMoveThread(true);
		} else if (optionValue === 'forward') {
			// setSelectingMessages(true);
			// pushToSelectedMessages(message);
			Navigation.openAddMembers({
				data: message,
				actionType: AddMembersPageActionType.ForwardMessage,
			});
		} else if (optionValue === 'download') {
			showToastView({ toastMessage: '<img src="../assets/icon/right-thread.svg" />   Saved to Device', toastType: 'secondary', toastButtonText: '' });
		} else if (optionValue === 'notepad') {
			let conversationType = ChatType.P2P;

			await xmpp.sendToNotePad({
				conversationType: conversationType,
				messageToNotePad: message,
			});
			showToastView({ toastMessage: '<img src="../assets/icon/right-thread.svg" />   Sent to Notepad', toastType: 'secondary', toastButtonText: 'Cancel' });
			setSelectingMessages(false);
		}
		// else if (optionValue === 'notepad') {
		// 	showToastView({ toastMessage: '<img src="../assets/icon/right-thread.svg" />   Sent to Notepad', toastType: 'secondary', toastButtonText: 'Cancel' });
		// }
	};

	const endReached = () => {
		if (hasMoreNewer && loadMoreNewer) {
			loadMoreNewer();
		}
	};

	const messageRenderer = (virtuosoIndex: number) => {
		const index = virtuosoIndex + numItemsPrepended - PREPEND_OFFSET;
		const message = chatMessagesList[index];
		if (!message) {
			return <div></div>;
		}
		if (index === 0) {
			previousMessageTime = undefined;
		}
		const sameElseDateTimeFormat: string = '(MMM D, YYYY) h:mm A',
			messageSentTime: string = message?.datetime
				? moment(message.datetime).calendar(null, {
						sameDay: 'h:mm A',
						lastDay: `[(${locale.dashboard.chat.yesterday}]) h:mm A`,
						lastWeek: '(dddd) h:mm A',
						sameElse: sameElseDateTimeFormat,
				  })
				: locale.dashboard.chat.recently,
			shouldShowTime: Boolean = previousMessageTime !== messageSentTime;

		previousMessageTime = messageSentTime;

		// if (!isBlank(message.linkPreview) && message.linkPreview !== false && !message.linkPreview.Error) {
		// 	message.body = `<div class="preview-link-view"><a href="${message.linkPreview.url}" target="_blank"><img src="${message.linkPreview.image || message.linkPreview.logo}" /></a><div class="title">${message.linkPreview.title}</div><div class="url">${
		// 		message.linkPreview.url.replace(/http?s:\/\//, '').split('/')[0]
		// 	}</div></div>`;
		// }

		// If confirm request not by logged in user then hide contact added msg
		if (message.messageType === MessageType.REQUEST_ACCEPTED && message.from !== loggedInUserProfile.username) {
			return null;
		}

		let userProfilePhoto: any = DefaultProfilePic,
			receiverNameToDisplay = message.from;
		if (message.from === loggedInUserProfile.username) {
			userProfilePhoto = SharedService.extractProfilePhoto(loggedInUserProfile.profilePhotos)?.url || DefaultProfilePic;
			receiverNameToDisplay = 'Me';
		} else {
			if (chat?.type === ChatType.GROUP) {
				const memberDetailToDisplay = SharedService.getMemberUsernameAndPhoto(contactByUsername[message.from], membersByUsername[message.from]);
				receiverNameToDisplay = memberDetailToDisplay.name;
				userProfilePhoto = memberDetailToDisplay.photo;
			} else {
				receiverNameToDisplay = contactItem.alias ?? contactItem?.userInfo?.profileName;
				userProfilePhoto = SharedService.extractProfilePhoto(contactItem?.userInfo?.profilePhotos || [])?.url || DefaultProfilePic;
			}
		}

		return (
			<div key={message.messageType} className="chat-item-container" id={message.messageType}>
				{shouldShowTime && (
					<IonRow className="timestamp-row">
						<IonNote color="danger">
							<span
								dangerouslySetInnerHTML={{
									__html: messageSentTime.replace('(', '<span class="date-day">').replace(')', '</span>'),
								}}
							></span>
						</IonNote>
					</IonRow>
				)}

				{message.messageType === MessageType.REQUEST_ACCEPTED ? (
					<>{message.from === loggedInUserProfile.username ? <div className="request-confirm-msg" dangerouslySetInnerHTML={{ __html: message.body }}></div> : ''}</>
				) : message.messageType === MessageType.GROUP_CREATED ? (
					<div className="invited-to-newgroup-msg" dangerouslySetInnerHTML={{ __html: message.from === loggedInUserProfile.username ? message.body.replace(message.from + ' ', 'You ') : message.body }}></div>
				) : (
					<ThreadComponent
						type={chat?.type!}
						selectingMessages={selectingMessages}
						selectedMessages={selectedMessages as []}
						opctionClick={async (optionValue: string) => {
							if (optionValue === 'check') {
								pushToSelectedMessages(message);
							}
							optionClickHandler(optionValue, message);
						}}
						startSelectingMessages={startSelectingMessages}
						startForwardingMessages={() => _startForwardingMessages(message)}
						actionHandler={(mediaData: Media, message: Message) => previewActionHandler(mediaData, message)}
						receiverProfilePhoto={userProfilePhoto ? userProfilePhoto : DefaultProfilePic}
						senderProfileName={receiverNameToDisplay}
						align={!personalNotepad && message.from === loggedInUserProfile.username ? 'right' : 'left'}
						message={message}
						onProfilePhotoPress={() => {
							_openFriendProfile(message.from === loggedInUserProfile.username ? 'me' : chat?.type!, message?.from);
						}}
						onReplyItemSelect={(message: Message) => {
							const repliedItemIndex = chatMessagesList.findIndex((item) => item.id === message.id);
							if (repliedItemIndex > 0) {
								// scrollToIndex(repliedItemIndex - 1);
							} else {
								// scrollToIndex(repliedItemIndex);
							}
						}}
					/>
				)}
			</div>
		);
	};
	const atBottomStateChange = (isAtBottom: boolean) => {
		atBottom.current = isAtBottom;
		// setIsMessageListScrolledToBottom(isAtBottom);
		// if (isAtBottom && newMessagesNotification) {
		//   setNewMessagesNotification(false);
		// }
	};

	const _fetchMessages = useCallback(
		(index: number, item?: ThreadItem) => {
			if (noMoreData) {
				return;
			}
			isMessageFetching = true;

			const contactItem = chat?.receiver as ContactItem;
			const groupItem = chat?.receiver as GroupItem;
			let isGroupChat = contactItem?.username ? false : true;

			let username = isGroupChat ? groupItem.name : contactItem.username;

			if (item && item.threadId !== sharedService.defaultThreadId) {
				username = item?.threadId;
				isGroupChat = true;
			}

			const virtualsoElements = document.getElementsByClassName('virtualso');
			let virtualsoElement: any = null;
			if (virtualsoElements?.length > 0) {
				virtualsoElement = virtualsoElements[0];
			}

			getMessages({
				username,
				index,
				isGroupChat,
			})
				.then(({ messages, isNoMoreData }: any) => {
					if (index === 0 || messages.length > 0) {
						setChatMessagesList(messages);
					}
					if (virtualsoElement) {
						virtualsoElement.style.overflowY = 'auto';
					}
					isMessageFetching = false;
					// noMoreData = isNoMoreData;
					setLoadingMore(false);
				})
				.catch(() => {
					if (virtualsoElement) {
						virtualsoElement.style.overflowY = 'auto';
					}
					isMessageFetching = false;
					setLoadingMore(false);
				})
				.finally(() => {});
		},
		[chat, chatMessagesList]
	);

	function findMessageIndex(messages: Array<{ id: string }>, id: string) {
		return messages.findIndex((message) => message.id === id);
	}

	function calculateInitialTopMostItemIndex(messages: Array<Message>, highlightedMessageId: string | undefined) {
		if (highlightedMessageId) {
			const index = findMessageIndex(messages, highlightedMessageId);
			if (index !== -1) {
				return { align: 'center', index } as const;
			}
		}
		return messages.length - 1;
	}

	const virtuosoComponents: any = useMemo(
		() => ({
			Header: () => (
				<div id="virtualsoHeader">
					{loadingMore && (
						<div style={{ width: '100%', height: '140px', display: 'flex', alignItems: 'flex-end', justifyContent: 'center' }}>
							<IonSpinner />
						</div>
					)}
				</div>
			),
			Footer: () => <div id="virtualsoFooter" style={{ height: '75px' }}></div>,
		}),
		[loadingMore]
	);

	if (chatMessagesList.length === 0) {
		return <></>;
	}

	return (
		<>
			<Virtuoso
				atBottomStateChange={atBottomStateChange}
				atBottomThreshold={200}
				components={virtuosoComponents}
				computeItemKey={(index) => {
					const newIndex = numItemsPrepended + index - PREPEND_OFFSET;
					const message = newIndex >= 0 ? chatMessagesList[newIndex] : ({} as any);
					return message?.messageKey || Math.random();
				}}
				startReached={loadMore}
				endReached={endReached}
				/*logLevel={LogLevel.DEBUG}*/
				ref={virtuosoRef}
				className="virtualso"
				// itemSize={fractionalItemSize}
				overscan={overscan}
				defaultItemHeight={120}
				firstItemIndex={PREPEND_OFFSET - numItemsPrepended}
				increaseViewportBy={{ bottom: 200, top: 0 }}
				initialTopMostItemIndex={calculateInitialTopMostItemIndex(chatMessagesList, highlightedMessageId)}
				itemContent={(i) => messageRenderer(i)}
				followOutput={(isAtBottom) => {
					// if (chatMessagesList[chatMessagesList.length - 1].from === loggedInUserProfile.username) {
					// 	// if the user has scrolled away and sends a message, bring him to the bottom instantly
					// 	return isAtBottom ? 'smooth' : 'auto';
					// } else {
					// 	// a message from another user has been received - don't pull to bottom unless already there
					// }
					return isAtBottom ? 'smooth' : false;
				}}
				totalCount={chatMessagesList.length}
				// atBottomStateChange={(bottom) => {
				// 	if (!bottom) {
				// 		//info('Chat::render: bottom = ', bottom);
				// 	}
				// }}
				// itemsRendered={handleItemsRendered}
				// isScrolling={(status) => {
				// 	// 	// sharedService.isChatScrolling = status;
				// }}
				// onScroll={(event: any) => {
				// chatMessagesList = chatMessagesList.concat(currentMessage.slice(chatMessagesList.length, showMessageList + chatMessagesList.length));
				// info('newMsgList', chatMessagesList);
				// if (chatMessagesList.length >= currentMessage) {
				// 	event.target.disabled = true;
				// }
				/*if (event.target?.scrollTop === 0) {
                                                isAtBottom = true;
                                            } else {
                                                isAtBottom = false;
                                            }
                                            e.log(event);
                                            sharedService.isChatScrolling = true;
                                            if (timerRef !== null) {
                                                clearTimeout(timerRef);
                                            }
                                            timerRef = setTimeout(function () {
                                                sharedService.isChatScrolling = false;
                                            }, 500);

                                            const scrollTop = event.target?.scrollTop;
                                            const scrollHeight = event.target?.scrollHeight;
                                            const offsetHeight = event.target?.offsetHeight;

                                            virtualScrollOffsetHeight = offsetHeight;
                                            virtualScrollContentHeight = scrollHeight;
                                            virtualScrollOffsetTop = scrollTop;

                                            // info('Event scrollTop ', scrollTop);
                                            // info('Event scrollHeight ', scrollHeight);
                                            // info('Event offsetHeight', offsetHeight);

                                            if (unreadMessagesIds && unreadMessagesIds.length > 1) {
                                                unreadMessagesIds.forEach((unreadMessagesId: any) => {
                                                    const unreadMessageElem = document.getElementById(unreadMessagesId);
                                                    if (unreadMessageElem) {
                                                        const unreadMessageElemTop = unreadMessageElem?.offsetTop + unreadMessageElem?.offsetHeight - offsetHeight;
                                                        if (scrollTop >= unreadMessageElemTop) {
                                                            if (!_.includes(readMessageStore, unreadMessagesId)) {
                                                                this.readMessageStore.push(unreadMessagesId);
                                                                if (componentHasMounted && !isManualScrolling) {
                                                                    this.setReadMessagesByIds([unreadMessagesId]);
                                                                }
                                                            }
                                                        }
                                                    }
                                                });
                                            }
                                            this.checkBtnVisibility(scrollTop, scrollHeight, offsetHeight);*/
				// }}
			/>
			<MediaPreviewModal
				onViewAllMediaClick={() => {
					if (componentHasMounted) {
						setShowMediaPreview(false);
						setSelectedMediaMessageKey('');
						Navigation.openChatMedias();
					}
				}}
				selectedMediaMessageKey={selectedMediaMessageKey}
				show={showMediaPreview}
				onClose={() => {
					if (componentHasMounted) {
						setShowMediaPreview(false);
						setSelectedMediaMessageKey('');
					}
				}}
			/>
			{showMoveThread && (
				<MoveThreadPopUp
					onMoveCallBack={() => {
						showToastView({ toastMessage: '<img src="../assets/icon/right-thread.svg" />   Moved to “UI/UX”', toastType: 'secondary' });
						setShowMoveThread(false);
						PubSub.publish(PubSubEventType.IsSelectedMsg, {
							action: PubSubEventType.IsSelectedMsg,
							isSelected: false,
						});
					}}
					show={showMoveThread}
					onCloseCallBack={() => {
						setShowMoveThread(false);
						PubSub.publish(PubSubEventType.IsSelectedMsg, {
							action: PubSubEventType.IsSelectedMsg,
							isSelected: false,
						});
					}}
				/>
			)}
			<ShareLocationModal ref={sharedLocationModalRef} data={selectedDocumentMedia} viewLocation={true} onClose={() => {}} />
			<PreviewFile show={showDocumentViewer} onCancel={() => setShowDocumentViewer(false)} filePath={selectedDocumentMedia?.mediaFile} contentType={selectedDocumentMedia?.contentType} fileName={selectedDocumentMedia?.fileName} />
		</>
	);
};

const mapStateToProps = (state: ReduxStates) => {
	return {
		chat: state.chat,
		contact: state.contact,
		group: state.group,
		profile: state.profile,
		thread: state.thread,
	};
};

const mapDispatchToProps = (dispatch: any) => ({
	getProfileByUsername: (payload: GetProfileByUsernameRequestData) => dispatch(getProfileByUsername(payload)),
	getMessages: (payload: { username: string; index: number; isGroupChat: boolean }) => dispatch(getMessages(payload)),
	showToastView: (payload: GlobalStateTypes) => dispatch(showToast(payload)),
});

export default connect(mapStateToProps, mapDispatchToProps)(VirtualizedMessageList);
