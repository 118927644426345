import { IonButton, IonIcon } from '@ionic/react';
import React from 'react';
import { DefaultProfilePic } from '../../helpers/common';
import SharedService from '../../services/sharedService';
import { ContactItem } from '../../types/contact';
import { MemberItem } from '../../types/group';
import './index.scss';
const CloseCircleIcon = './assets/icon/close-circle.svg';

interface ContactItemProps {
	isDeleteMode?: boolean;
	onClick?: any;
	contact?: ContactItem | MemberItem;
	dataType?: 'contact' | 'member';
}
const ContactView: React.FC<ContactItemProps> = ({ contact, isDeleteMode, onClick, dataType = 'contact' }) => {
	let profilePhoto = DefaultProfilePic,
		contactName = '';

	if (contact) {
		if (dataType === 'contact') {
			const contactItem = contact as ContactItem;
			profilePhoto = SharedService.extractProfilePhoto(contactItem.userInfo?.profilePhotos)?.url ?? DefaultProfilePic;
			contactName = contactItem?.alias || contactItem?.userInfo.profileName || contactItem?.username || '';
		} else if (dataType === 'member') {
			const memberItem = contact as MemberItem;
			profilePhoto = memberItem.aliasImage || memberItem.profilePhoto || DefaultProfilePic;
			contactName = memberItem.alias || memberItem.profileName || memberItem.username;
		}
	}

	return (
		<div className="contact-item" onClick={onClick}>
			{contact && (
				<>
					<img src={profilePhoto} alt="profilePhoto" />
					<div className="title">{contactName}</div>
					{isDeleteMode && (
						<IonButton className="remove-icon" fill="clear">
							<IonIcon src={CloseCircleIcon} />
						</IonButton>
					)}
				</>
			)}
		</div>
	);
};

export default ContactView;
