import { ContactStateTypes } from '../../types/redux-states';
import {
	ACCEPT_INVITATION_FAILED,
	ACCEPT_INVITATION_START,
	ACCEPT_INVITATION_SUCCESS,
	CONTACT_RESET_STATES,
	GET_ROASTER_ITEMS_FAILED,
	GET_ROASTER_ITEMS_START,
	GET_ROASTER_ITEMS_SUCCESS,
	REMOVE_ROASTER_ITEM_FAILED,
	REMOVE_ROASTER_ITEM_START,
	REMOVE_ROASTER_ITEM_SUCCESS,
	REJECT_INVITATION_FAILED,
	REJECT_INVITATION_START,
	REJECT_INVITATION_SUCCESS,
	SEND_INVITATION_FAILED,
	SEND_INVITATION_START,
	SEND_INVITATION_SUCCESS,
	CONTACT_INIT,
	GET_BLOCKED_ROASTER_ITEMS_START,
	GET_BLOCKED_ROASTER_ITEMS_SUCCESS,
	GET_BLOCKED_ROASTER_ITEMS_FAILED,
	GET_INVITATION_REQUEST_START,
	GET_INVITATION_REQUEST_SUCCESS,
	GET_INVITATION_REQUEST_FAILED,
} from '../constants/contact';

const initialState: ContactStateTypes = {
	isLoading: false,
	contacts: [],
	sentRequests: [],
	receivedRequests: [],
	blockedList: [],
};
// eslint-disable-next-line import/no-anonymous-default-export
export default function (state = initialState, action: any) {
	switch (action.type) {
		case CONTACT_RESET_STATES: {
			state.isLoading = false;
			state.contacts = [];
			state.blockedList = [];
			break;
		}

		case CONTACT_INIT: {
			state.isLoading = false;
			if (action.payload) {
				state.contacts = action.payload.contacts || [];
				state.sentRequests = action.payload.sentRequests || [];
				state.receivedRequests = action.payload.receivedRequests || [];
				state.blockedList = action.payload.blockeList || [];
			}
			break;
		}

		case SEND_INVITATION_START: {
			state.isLoading = true;
			break;
		}
		case SEND_INVITATION_SUCCESS: {
			state.isLoading = false;
			if (action.payload) {
				state.contacts = action.payload;
			}
			break;
		}
		case SEND_INVITATION_FAILED: {
			state.isLoading = false;
			break;
		}

		case ACCEPT_INVITATION_START: {
			state.isLoading = true;
			break;
		}
		case ACCEPT_INVITATION_SUCCESS: {
			state.isLoading = false;
			if (action.payload) {
				state.contacts = action.payload;
			}
			break;
		}
		case ACCEPT_INVITATION_FAILED: {
			state.isLoading = false;
			break;
		}

		case REJECT_INVITATION_START: {
			state.isLoading = true;
			break;
		}
		case REJECT_INVITATION_SUCCESS: {
			state.isLoading = false;
			if (action.payload) {
				state.contacts = action.payload;
			}
			break;
		}
		case REJECT_INVITATION_FAILED: {
			state.isLoading = false;
			break;
		}

		// GET_ROASTER_ITEMS
		case GET_ROASTER_ITEMS_START: {
			state.isLoading = true;
			break;
		}
		case GET_ROASTER_ITEMS_SUCCESS: {
			state.isLoading = false;
			if (action.payload) {
				if (action.payload.contacts) {
					state.contacts = action.payload.contacts || [];
				}
				if (action.payload.sentRequests) {
					state.sentRequests = action.payload.sentRequests || [];
				}
				if (action.payload.receivedRequests) {
					state.receivedRequests = action.payload.receivedRequests || [];
				}
				if (action.payload.blockedList) {
					state.blockedList = action.payload.blockedList || [];
				}
			}
			break;
		}
		case GET_ROASTER_ITEMS_FAILED: {
			state.isLoading = false;
			break;
		}

		// GET_INVITATION_REQUEST
		case GET_INVITATION_REQUEST_START: {
			state.isLoading = true;
			break;
		}
		case GET_INVITATION_REQUEST_SUCCESS: {
			state.isLoading = false;
			if (action.payload && action.payload.receivedRequests) {
				state.receivedRequests = action.payload.receivedRequests || [];
			}
			break;
		}
		case GET_INVITATION_REQUEST_FAILED: {
			state.isLoading = false;
			break;
		}

		//GET_BLOCKED_ROASTER_ITEMS
		case GET_BLOCKED_ROASTER_ITEMS_START: {
			state.isLoading = true;
			break;
		}
		case GET_BLOCKED_ROASTER_ITEMS_SUCCESS: {
			state.isLoading = false;
			if (action.payload && action.payload.blockedList) {
				state.blockedList = action.payload.blockedList || [];
			}
			break;
		}
		case GET_BLOCKED_ROASTER_ITEMS_FAILED: {
			state.isLoading = false;
			break;
		}

		case REMOVE_ROASTER_ITEM_START: {
			state.isLoading = true;
			break;
		}
		case REMOVE_ROASTER_ITEM_SUCCESS: {
			state.isLoading = false;
			break;
		}
		case REMOVE_ROASTER_ITEM_FAILED: {
			state.isLoading = false;
			break;
		}

		default:
			break;
	}
	return { ...state };
}
