import { ThreadStateTypes } from '../../types/redux-states';
import { CREATE_THREAD, THREAD_UPDATED, ACTIVE_THREAD_UPDATED } from '../constants/thread';

const initialState: ThreadStateTypes = {
	isLoading: false,
};

export default function (state = initialState, action: any) {
	switch (action.type) {
		case CREATE_THREAD:
			if (action.payload) {
			}
			break;

		case THREAD_UPDATED:
			if (action.payload) {
				state.threads = action.payload.threads || [];
			}
			break;

		case ACTIVE_THREAD_UPDATED:
			if (action.payload) {
				state.activeThreadInChat = action.payload.activeThreadInChat;
			}
			break;

		default:
			break;
	}
	return { ...state };
}
