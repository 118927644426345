import { info, logError, sortByTimestamp } from '../../helpers/common';
import {
	CHAT_CLEAR,
	CHAT_FETCHING_HISTORY,
	CHAT_HISTORY_LOADED,
	CHAT_HISTORY_LOADED_ERROR,
	CHAT_INIT,
	CHAT_RELOAD,
	CHAT_LOADING,
	CHAT_UPDATE_HISTORY,
	CHAT_UPDATE_CONVERSATION,
	CHAT_REMOVE_FROM_HISTORY,
	CHAT_RESET,
	UPDATE_CHAT_HISTORY,
	UPDATE_LAST_MESSAGES,
	FETCHING_LAST_MESSAGES,
	FETCHED_LAST_MESSAGES,
} from '../constants/chat';
import { locale } from '../../locales/local';
import _ from 'lodash';
import { ChatStateTypes } from '../../types/redux-states';
import { ChatType } from '../../services/enumService';

const initialState: ChatStateTypes = {
	type: ChatType.P2P,
	receiver: null,
	history: [],
	lastMessages: {} as any,
	fetchingLastMessages: false,
	loaderMessage: '',
	emptyMessage: locale.reducers.chat.init_state.syncing_messages,
	showLoading: false,
};

// eslint-disable-next-line import/no-anonymous-default-export
export default function (state = initialState, action: any) {
	let returnValue: any = null,
		history: any;

	switch (action.type) {
		case CHAT_RESET:
			returnValue = {
				...state,
				history: [],
				receiver: false,
			};

			info('CHAT_RESET');
			break;

		case CHAT_INIT: {
			returnValue = {
				...state,
				receiver: action.payload.receiver,
				history: action.payload.history,
				type: action.payload.receiver && action.payload.receiver.username ? ChatType.P2P : ChatType.GROUP,
			};
			info('CHAT_INIT', action.payload.receiver);

			break;
		}

		case UPDATE_CHAT_HISTORY: {
			returnValue = {
				...state,
				history: action.payload.history,
			};
			break;
		}

		case FETCHING_LAST_MESSAGES: {
			returnValue = {
				...state,
				fetchingLastMessages: true,
			};
			break;
		}

		case UPDATE_LAST_MESSAGES: {
			returnValue = {
				...state,
				lastMessages: action.payload.lastMessages,
			};
			break;
		}
		case FETCHED_LAST_MESSAGES: {
			returnValue = {
				...state,
				fetchingLastMessages: false,
			};
			break;
		}

		case CHAT_RELOAD: {
			const chatHistory = action.payload.history || state.history;
			action.payload.history && delete action.payload['history'];

			returnValue = {
				...state,
				history: chatHistory,
			};
			break;
		}

		case CHAT_CLEAR: {
			returnValue = {
				...state,
				receiver: false,
			};
			info('CHAT_CLEAR');

			break;
		}

		case CHAT_UPDATE_CONVERSATION:
			returnValue = {
				...state,
				history: action.payload.history ? action.payload.history.sort(sortByTimestamp) : state.history,
				receiver: action.payload.receiver || state.receiver,
			};
			info('CHAT_UPDATE_CONVERSATION', action.payload.receiver);
			break;

		case CHAT_FETCHING_HISTORY:
			returnValue = {
				...state,
				showLoading: true,
				loaderMessage: action.payload.loaderMessage,
			};

			break;

		case CHAT_LOADING:
			returnValue = {
				...state,
				showLoading: action.payload.showLoading,
				loaderMessage: action.payload.loaderMessage,
			};

			break;

		case CHAT_HISTORY_LOADED:
			if (!state) {
				logError('chat::reducer: state is empty.  setting to initial state');
				state = initialState;
			}

			history = state?.history;

			if (history?.length > 0) {
				history.push(action.payload.history);
			} else {
				history = action.payload.history;
			}

			returnValue = {
				...state,
				showLoading: false,
				hasError: false,
				history: history,
				emptyMessage: state?.history ? locale.reducers.chat.init_state.no_message : '',
				type: action.payload.type,
			};

			if (action.payload.cb) {
				action.payload.cb();
			}
			break;

		case CHAT_HISTORY_LOADED_ERROR:
			returnValue = {
				...state,
				showLoading: false,
				hasError: true,
				emptyMessage: action.payload.error && action.payload.error.text ? action.payload.error.text : locale.reducers.chat.init_state.error_history,
			};

			break;

		default:
			returnValue = state;
			break;
	}

	return returnValue;
}
