import { IonButton, IonModal } from '@ionic/react';
import React from 'react';
import './style.scss';
import { connect } from 'react-redux';

interface ResendMessageModalProps {
	show: boolean;
	onCloseCallBack: any;
	onConfirm: any;
	onDelete: any;
}

const ResendMessageModal: React.FC<ResendMessageModalProps> = ({ show, onCloseCallBack, onConfirm, onDelete }) => {
	return (
		<IonModal showBackdrop={true} isOpen={show} onDidDismiss={onCloseCallBack} swipeToClose={true} cssClass="resend-message-popup-modal" animated={true}>
			<div className="container">
				<div className="content-view">
					<div className="title">Message was not sent</div>
					<div className="message">Resend?</div>

					<div className="btns-view">
						<IonButton fill="clear" className="delete-btn" onClick={onDelete}>
							Delete
						</IonButton>
						<IonButton fill="clear" className="ok-btn" onClick={onConfirm}>
							Yes
						</IonButton>
					</div>
				</div>

				<div className="cancel-btn" onClick={onCloseCallBack}>
					Cancel
				</div>
			</div>
		</IonModal>
	);
};

const mapStateToProps = (state: any) => {
	return {};
};
const mapDispatchToProps = (dispatch: any) => ({});

export default connect(mapStateToProps, mapDispatchToProps)(ResendMessageModal);
