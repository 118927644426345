import React from 'react';
import { IonActionSheet, IonButton, IonContent, IonIcon, IonLoading, IonPage, IonToggle } from '@ionic/react';
import './style.scss';
import { RouteComponentProps } from 'react-router';
import { connect } from 'react-redux';
import { pageLoading, updateUser } from '../../redux/actions/profile';
import { TopNavbar } from '../common/header/topbar';
import { locale } from '../../locales/local';
// import _ from 'lodash';
import { dbService } from '../../services/dbService';
import { AddMembersPageActionType} from '../../services/enumService';
import { Navigation } from '../../services/navigationService';
const ArrowRightIcon = './assets/icon/arrow-right.svg';

const FieldsKey = {
	GroupPhoto: 'groupPhoto',
	GroupAlias: 'groupAlias',
	MyAliasInGroup: 'myAliasInGroup',
	MuteNotification: 'muteNotification',
	StickOnTop: 'stickOnTop',
	AlertOn: 'alertOn',
	DisappearingMessages: 'disappearingMessages',
};
interface iState {
	showActionSheetFor: boolean;
}

interface iProps extends RouteComponentProps<{ name: string }> {
	profile: any;
	loggedInUser: any;
	pageLoading: Function;
	updateUser: Function;
	location: any;
}

class ManageGroup extends React.Component<iProps, any> {
	componentIsMounted: Boolean = false;
	componentIsUpdated: Boolean = false;
	constructor(props: iProps) {
		super(props);
		const locationState = props.location.state,
			groupDetail = locationState?.groupDetail;

		this.state = {
			showActionSheetFor: false,
			loggedInUser: undefined,
			isManager: undefined,
			groupDetail: groupDetail,
			currentActiveEditField: '',
			[FieldsKey.GroupPhoto]: null,
			[FieldsKey.GroupAlias]: 'Not Set',
			[FieldsKey.MyAliasInGroup]: 'Not Set',
			[FieldsKey.MuteNotification]: false,
			[FieldsKey.StickOnTop]: false,
			[FieldsKey.AlertOn]: false,
			disappearingMessages: 'off',
		};

		this.submitChanges = this.submitChanges.bind(this);
	}

	componentDidMount = async () => {
		let user: any = await dbService.me();

		this.setState({
			loggedInUser: user,
			// isManager: _.includes(this.state.groupDetail?.groupAdmins, user._id),
		});

		this.componentIsMounted = true;
	};

	componentDidUpdate = async () => {
		if (!this.componentIsUpdated) {
			let user: any = await dbService.me();

			this.componentIsUpdated = true;

			this.setState({
				loggedInUser: user,
				// isManager: _.includes(this.state.groupDetail?.groupAdmins, user._id),
			});

			this.componentIsMounted = true;
		}
	};
	submitChanges() {}

	render() {
		if (this.componentIsMounted) {
			// const { groupDetail, currentActiveEditField } = this.state;

			return (
				<IonPage className="manage-group-page">
					<TopNavbar
						{...this.props}
						hideSearchBar={true}
						pageTitle="Manage Group"
						showBack={true}
						onLeftButtonPress={() => {
							Navigation.back();
						}}
					/>

					<IonContent className="main-content-profile has-topbar">
						<div className="group-field-item" style={{ minHeight: '67px' }}>
							<div className="row-first">
								<div className="title">Guidelines</div>
								<IonButton
									className="value"
									fill="clear"
									onClick={() => {
										Navigation.openGroupGuideline();
									}}
								>
									<span className="placeholder">{locale.groups.not_set}</span>
									<IonIcon src={ArrowRightIcon} />
								</IonButton>
							</div>
							<div className="row-second">
								<div className="title">Rules, Agreements, Password protections, and more</div>
							</div>
						</div>
						<div className="group-field-item" style={{ minHeight: '67px' }}>
							<div className="row-first">
								<div className="title">Admin Approval</div>
								<div className={'swtich-item'}>
									<IonToggle className="toggle-customised" mode="ios" />
								</div>
							</div>
							<div className="row-second">
								<div className="title">Group members need group owner or admin approval before they can invite friends. Option to scan QR codes to join the group will be disabled.</div>
							</div>
						</div>
						<div className="group-field-item" style={{ minHeight: '67px' }}>
							<div className="row-first">
								<div className="title">Transfar Ownership</div>
								<IonButton
									className="value"
									fill="clear"
									onClick={() => {
										Navigation.openAddMembers({
											actionType: AddMembersPageActionType.ChooseContactForOwnerShip,
										});
									}}
								>
									<IonIcon src={ArrowRightIcon} />
								</IonButton>
							</div>
						</div>
						<div className="group-field-item" style={{ minHeight: '67px' }}>
							<div className="row-first">
								<div className="title">Group Admin</div>
								<IonButton className="value" fill="clear" onClick={() => Navigation.openGroupAdmin()}>
									<IonIcon src={ArrowRightIcon} />
								</IonButton>
							</div>
						</div>

						<div className="bottom-btns">
							<IonButton
								mode="ios"
								fill="clear"
								onClick={() => {
									this.setState({ showActionSheetFor: true });
								}}
							>
								Disband Group
							</IonButton>
						</div>
						<div className="blanck"></div>
					</IonContent>
					<div className="encryption-view">
						<h3>Encryption</h3>
						<p>All communication within this group chat is end-to-end encrypted</p>
					</div>
					<IonLoading isOpen={this.props.profile.isLoading} onDidDismiss={() => this.props.pageLoading({ loader: false })} message="Please wait..." duration={20000} />
					<IonActionSheet
						mode="ios"
						isOpen={this.state.showActionSheetFor}
						onDidDismiss={() => this.setState({ showActionSheetFor: false })}
						cssClass="upload-photo-option-action-sheet"
						subHeader="After disbanding, all members (including group owner) will be removed from this group."
						header=""
						buttons={[
							{
								cssClass: 'ok',
								text: 'Disband',
								role: 'destructive',
								handler: () => {
									// this.onDisBand();
								},
							},
							{ cssClass: 'cancel', text: 'Cancel', handler: () => {} },
						]}
					></IonActionSheet>
					
				</IonPage>
			);
		} else {
			return <></>;
		}
	}
}

const mapStateToProps = (state: any) => {
	return {
		profile: state.profile,
		loggedInUser: state.global.loggedInUser,
	};
};

const mapDispatchToProps = (dispatch: any) => ({
	updateUser: (payload: String) => dispatch(updateUser(payload)),
	pageLoading: (payload: any) => dispatch(pageLoading(payload)),
});

export default connect(mapStateToProps, mapDispatchToProps)(ManageGroup);
