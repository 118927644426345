import { IonPage, IonButton, IonIcon } from '@ionic/react';
import React from 'react';
import { connect } from 'react-redux';
import AddContactItem from '../../../components/ContactItems/AddContactItem';
import ContactView from '../../../components/ContactItems/ContactView';
import RemoveContactItem from '../../../components/ContactItems/RemoveContactItem';
import { getMucMembers, removeMembersFromGroup } from '../../../redux/actions/group';
import { dbService } from '../../../services/dbService';
import { AddMembersPageActionType, AffiliationType, GroupMembersPageActionType } from '../../../services/enumService';
import { Navigation } from '../../../services/navigationService';
import SharedService, { sharedService } from '../../../services/sharedService';
import { MucRequestData, RemoveUsersFromMucRequestData } from '../../../types/api-requests';
import { GroupItem, MemberItem } from '../../../types/group';
import { GroupMembersNavParams } from '../../../types/navigation';
import { ProfileStateTypes, ReduxStates } from '../../../types/redux-states';
import { TopNavbar } from '../../common/header/topbar';
import './GroupMembers.scss';
import { DefaultProfilePic } from '../../../helpers/common';
import { ContactItem } from '../../../types/contact';
const CloseCircleIcon = './assets/icon/close-circle.svg';

interface iProps {
	history: any;
	profile: ProfileStateTypes;
	getMucMembers: (payload: MucRequestData) => Promise<Array<MemberItem>>;
	removeMembersFromGroup: (payload: RemoveUsersFromMucRequestData) => Promise<Array<MemberItem>>;
}

interface iState {
	isDeleteMode: boolean;
	groupDetail?: GroupItem;
	mucMembers?: Array<MemberItem>;
	ownerMember?: MemberItem;
	loggedinMember?: MemberItem;
	groupMembers?: any;
	pageActionType?: any;
}

class GroupMembers extends React.Component<iProps, iState> {
	componentIsMounted: boolean = false;
	finalContactsList = [];
	list: any;

	navParams: GroupMembersNavParams = {} as any;

	constructor(props: iProps) {
		super(props);

		this.state = {
			isDeleteMode: false,
			pageActionType: '',
		};
	}

	componentDidMount() {
		this.componentIsMounted = true;
		Navigation.getNavParamData(this.props).then(async (res: any) => {
			this.navParams = res;
			if (this.navParams) {
				const groupDetail = this.navParams.data;
				this.setState({ pageActionType: this.navParams.actionType, isDeleteMode: this.navParams.isDeleteMode!, groupDetail, mucMembers: this.navParams.selectedContacts, groupMembers: this.navParams.selectedContacts });

				if (groupDetail) {
					const members = await dbService.getMembers(groupDetail.name);
					this.setMembersInfo(members);

					this.props
						.getMucMembers({
							mucId: groupDetail.name,
						})
						.then((members) => {
							this.setMembersInfo(members);
						});
				}
			}
		});
	}

	setMembersInfo = (members: Array<MemberItem>) => {
		const owner = members.find((member) => member.affiliation === AffiliationType.OWNER);
		const loggedinMember = members.find((member) => member.username === this.props.profile.loggedInUserProfile?.username);
		this.setState({
			mucMembers: members,
			ownerMember: owner,
			loggedinMember: loggedinMember,
		});
	};

	componentWillUnmount() {
		sharedService.callBackData.groupMembers = null;
	}

	_handleRemoveMember = (member: MemberItem) => {
		if (this.state.groupDetail?.name) {
			this.props
				.removeMembersFromGroup({
					mucId: this.state.groupDetail.name,
					users: member.username,
				})
				.then((members) => {
					this.setMembersInfo(members);
				});
		}
	};

	render() {
		const { isDeleteMode, ownerMember } = this.state;
		const isOwnerLoggedIn = ownerMember?.username === this.props.profile.loggedInUserProfile?.username;

		return (
			<IonPage className="group-members-page">
				<TopNavbar
					{...this.props}
					pageTitle={'Group Members'}
					onLeftButtonPress={() => {
						if (this.finalContactsList.length > 0 && (this.state.pageActionType === GroupMembersPageActionType.GroupManager || this.state.pageActionType === GroupMembersPageActionType.ViewMore)) {
							Navigation.openGroupManager({
								selectedContactIds: this.list,
							});
						} else {
							Navigation.back();
						}
					}}
					pageTitleDescription={this.state.groupMembers?.length || this.state.mucMembers?.length + ' Members'}
					hideSearchBar={false}
					isHideRightButton={true}
					showBack={true}
					searchTypeHandler={() => {}}
				/>
				{(this.state.pageActionType === GroupMembersPageActionType.GroupManager || this.state.pageActionType === GroupMembersPageActionType.ViewMore) && (
					<div className="selected-contacts-container">
						{this.state.groupMembers?.map((member: ContactItem, key: any) => {
							let userProfilePhoto: any = DefaultProfilePic;
							userProfilePhoto = SharedService.extractProfilePhoto(member?.userInfo?.profilePhotos || [])?.url || DefaultProfilePic;

							return (
								<div className="contact-item">
									{(this.state.pageActionType === GroupMembersPageActionType.GroupManager || isDeleteMode) && (
										<IonIcon
											className="cross"
											src={CloseCircleIcon}
											onClick={() => {
												if (this.state.groupMembers.length > 2 && (this.state.pageActionType === GroupMembersPageActionType.GroupManager || this.state.pageActionType === GroupMembersPageActionType.ViewMore)) {
													const memberIndex = this.state.groupMembers.findIndex((_contact: ContactItem) => _contact.username === member.username);
													if (memberIndex >= 0) {
														this.state.groupMembers.splice(memberIndex, 1);
														this.setState({ groupMembers: this.state.groupMembers });
													}

													this.finalContactsList = this.state.groupMembers?.filter((_contact: any) => {
														return _contact.username !== member.username;
													});
													this.list = this.finalContactsList.map((item: any) => item.username);
												}
												// else if (this.state.pageActionType === GroupMembersPageActionType.GroupInfo) {
												// 	this._handleRemoveMember(member);
												// }
											}}
										/>
									)}
									<img className="contact-img" src={userProfilePhoto} alt="" />
									<div className="contact-name">{member.username}</div>
								</div>
							);
						})}
					</div>
				)}
				{(this.state.pageActionType === GroupMembersPageActionType.GroupInfo || this.state.pageActionType === GroupMembersPageActionType.ViewMoreMembers) && (
					<div className="selected-contacts-container">
						{this.state.mucMembers?.map((member: MemberItem, key: any) => {
							const isLoggedInUser = member.username === this.props.profile.loggedInUserProfile?.username;
							const isAdminType = member.affiliation === AffiliationType.ADMIN;
							const shouldDeleteMode = isLoggedInUser ? false : !isOwnerLoggedIn && isAdminType ? false : isDeleteMode;
							return (
								<ContactView
									dataType="member"
									isDeleteMode={shouldDeleteMode}
									key={key}
									contact={member}
									onClick={() => {
										if (shouldDeleteMode && this.state.mucMembers && this.state.mucMembers?.length > 2) {
											this._handleRemoveMember(member);
										}
									}}
								/>
							);
						})}
						{/* <ContactView />
						<ContactView />
						<ContactView />
						<ContactView />
						<ContactView />
						<ContactView />
						<ContactView /> */}
					</div>
				)}

				{/* {this.state.loggedinMember && (this.state.loggedinMember.affiliation === AffiliationType.ADMIN || this.state.loggedinMember.affiliation === AffiliationType.OWNER) && ( */}
				{(this.state.pageActionType === GroupMembersPageActionType.ViewMore || this.state.pageActionType === GroupMembersPageActionType.GroupManager || this.state.pageActionType === GroupMembersPageActionType.ViewMoreMembers) && (
					<div className="add-remove-btns-container">
						{this.state.mucMembers && this.state.mucMembers?.length < sharedService.groupMaxMembersLimit && (
							<AddContactItem
								onClick={() => {
									Navigation.openAddMembers({
										data: this.state.groupDetail,
										actionType: this.state.pageActionType === GroupMembersPageActionType.ViewMoreMembers ? AddMembersPageActionType.ChooseContactForCurrentGroupInfoAdmin : AddMembersPageActionType.ChooseContactForCurrentGroup,
										previousSelectedContactsIds: this.state.mucMembers?.map((member) => member.username),
									});
								}}
							/>
						)}

						{/* {!isDeleteMode && ( */}
						{this.state.pageActionType !== GroupMembersPageActionType.GroupManager && (
							<RemoveContactItem
								onClick={() => {
									this.setState({ isDeleteMode: true });
								}}
							/>
						)}
					</div>
				)}
			</IonPage>
		);
	}
}

const mapStateToProps = (state: ReduxStates) => {
	return {
		profile: state.profile,
	};
};

const mapDispatchToProps = (dispatch: any) => ({
	getMucMembers: (payload: MucRequestData) => dispatch(getMucMembers(payload)),
	removeMembersFromGroup: (payload: RemoveUsersFromMucRequestData) => dispatch(removeMembersFromGroup(payload)),
});

export default connect(mapStateToProps, mapDispatchToProps)(GroupMembers);
