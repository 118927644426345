import { ADD_USER_ERROR, ADD_USER_FAILED, ADD_USER_REQUESTED, ADD_USER_RESET_VALUES, ADD_USER_SHOW_LOADER, ADD_USER_SUCCESS, UPDATE_CONTACT_SHOW_LOADER, UPDATE_CONTACT_RESET_VALUES, UPDATE_CONTACT_REQUESTED, UPDATE_CONTACT_FAILED, UPDATE_CONTACT_ERROR, UPDATE_CONTACT_SUCCESS } from '../constants/searchUser';
import { locale } from '../../locales/local';

const initialState = {
	hasErrors: false,
	errorMessage: false,
	showLoading: false,
	isLoading: false,
	showSentToast: false,
	userRequestSent: false,
};

// eslint-disable-next-line import/no-anonymous-default-export
export default function (state = initialState, action: any) {
	let fullState: any;
	switch (action.type) {
		case ADD_USER_SHOW_LOADER: {
			fullState = {
				...state,
				isLoading: action.payload,
				hasErrors: false,
				errorMessage: locale.global.error_occured, //action.payload.Error.message,
			};
			break;
		}
		case ADD_USER_RESET_VALUES: {
			fullState = {
				...state,
				isLoading: action.payload,
				hasErrors: false,
				showSentToast: false,
				userRequestSent: false,
			};
			break;
		}
		case ADD_USER_REQUESTED: {
			fullState = {
				...state,
				isLoading: true,
				userRequestSent: false,
				hasErrors: false,
				errorMessage: locale.global.error_occured, //action.payload.Error.message,
			};
			break;
		}
		case ADD_USER_FAILED: {
			fullState = {
				...state,
				isLoading: false,
				userRequestSent: false,
				hasErrors: true,
				errorMessage: locale.global.error_occured, //action.payload.Error.message,
			};
			break;
		}
		case ADD_USER_ERROR: {
			fullState = {
				...state,
				isLoading: false,
				userRequestSent: false,
				hasErrors: true,
				errorMessage: locale.global.error_occured, //action.payload.Error.message,
			};
			break;
		}
		case ADD_USER_SUCCESS: {
			fullState = {
				...state,
				isLoading: false,
				hasErrors: false,
				userRequestSent: true,
				errorMessage: locale.reducers.add_contacts.init_state.req_sent,
			};
			break;
		}

		case UPDATE_CONTACT_SHOW_LOADER: {
			fullState = {
				...state,
				isLoading: action.payload,
				hasErrors: false,
				errorMessage: locale.global.error_occured, //action.payload.Error.message,
			};
			break;
		}
		case UPDATE_CONTACT_RESET_VALUES: {
			fullState = {
				...state,
				isLoading: action.payload,
				hasErrors: false,
				showSentToast: false,
			};
			break;
		}
		case UPDATE_CONTACT_REQUESTED: {
			fullState = {
				...state,
				isLoading: true,
				hasErrors: false,
				errorMessage: locale.global.error_occured, //action.payload.Error.message,
			};
			break;
		}
		case UPDATE_CONTACT_FAILED: {
			fullState = {
				...state,
				isLoading: false,
				hasErrors: true,
				errorMessage: locale.global.error_occured, //action.payload.Error.message,
			};
			break;
		}
		case UPDATE_CONTACT_ERROR: {
			fullState = {
				...state,
				isLoading: false,
				hasErrors: true,
				errorMessage: locale.global.error_occured, //action.payload.Error.message,
			};
			break;
		}
		case UPDATE_CONTACT_SUCCESS: {
			fullState = {
				...state,
				isLoading: false,
				hasErrors: false,
				showSentToast: true,
				errorMessage: locale.reducers.add_contacts.init_state.contact_updated,
			};
			break;
		}
		default:
			fullState = state;
	}
	return fullState;
}
