import React from 'react';
import { IonButton, IonContent, IonLoading, IonPage } from '@ionic/react';
import './style.scss';
import { RouteComponentProps } from 'react-router';
import { IonList, IonItem } from '@ionic/react';
import { connect } from 'react-redux';
import { pageLoading, updateUser } from '../../redux/actions/profile';
import store from '../../redux/store';
import { PROFILE_EDITED } from '../../redux/constants/profile';
import { TopNavbar } from '../common/header/topbar';
import MultiInputListItem from '../../components/MultiInputListItem';
import PhotoInputItem from '../../components/PhotoInputItem';
import { locale } from '../../locales/local';
import _ from 'lodash';
import { dbService } from '../../services/dbService';
import { Navigation } from '../../services/navigationService';

const FieldsKey = {
	GroupPhoto: 'groupPhoto',
	GroupAlias: 'groupAlias',
	MyAliasInGroup: 'myAliasInGroup',
	MuteNotification: 'muteNotification',
	StickOnTop: 'stickOnTop',
	AlertOn: 'alertOn',
	DisappearingMessages: 'disappearingMessages',
};

interface iProps extends RouteComponentProps<{ name: string }> {
	profile: any;
	loggedInUser: any;
	pageLoading: Function;
	updateUser: Function;
	location: any;
}

class GroupSetting extends React.Component<iProps, any> {
	componentIsMounted: Boolean = false;
	componentIsUpdated: Boolean = false;
	constructor(props: iProps) {
		super(props);
		const locationState = props.location.state,
			groupDetail = locationState?.groupDetail;

		this.state = {
			loggedInUser: undefined,
			isManager: undefined,
			groupDetail: groupDetail,
			currentActiveEditField: '',
			[FieldsKey.GroupPhoto]: null,
			[FieldsKey.GroupAlias]: 'Not Set',
			[FieldsKey.MyAliasInGroup]: 'Not Set',
			[FieldsKey.MuteNotification]: false,
			[FieldsKey.StickOnTop]: false,
			[FieldsKey.AlertOn]: false,
			disappearingMessages: 'off',
		};

		this.submitChanges = this.submitChanges.bind(this);
	}

	componentDidMount = async () => {
		let user: any = await dbService.me();

		this.setState({
			loggedInUser: user,
			isManager: _.includes(this.state.groupDetail?.groupAdmins, user._id),
		});

		this.componentIsMounted = true;
	};

	componentDidUpdate = async () => {
		if (!this.componentIsUpdated) {
			let user: any = await dbService.me();

			this.componentIsUpdated = true;

			this.setState({
				loggedInUser: user,
				isManager: _.includes(this.state.groupDetail?.groupAdmins, user._id),
			});

			this.componentIsMounted = true;
		}
	};
	submitChanges() {}

	render() {
		if (this.componentIsMounted) {
			const { groupDetail, currentActiveEditField } = this.state;

			return (
				<IonPage className="group-setting-page profile-edit-page">
					<TopNavbar
						{...this.props}
						hideSearchBar={true}
						pageTitle={locale.groups.settings}
						showBack={true}
						onRightButtonPress={() => {
							Navigation.openGroupInfo({
								groupDetail: groupDetail,
							});
						}}
					/>

					<IonContent className="main-content-profile has-topbar">
						<IonList lines="none">
							<PhotoInputItem
								isEdittable={true}
								label={locale.groups.photo}
								fieldKey={FieldsKey.GroupPhoto}
								photo={this.state[FieldsKey.GroupPhoto] || groupDetail?.groupPhoto}
								isDisabled={false}
								onPhotoAdded={(photo: any) => {
									this.setState({ [FieldsKey.GroupPhoto]: photo });
								}}
							/>

							<MultiInputListItem
								key={FieldsKey.GroupAlias}
								inputType="text"
								isEdittable={true}
								shouldDisabled={currentActiveEditField !== '' && currentActiveEditField !== FieldsKey.GroupAlias}
								profileValueKey={FieldsKey.GroupAlias}
								label={locale.groups.alias}
								value={this.state[FieldsKey.GroupAlias]}
								isEditMode={currentActiveEditField === FieldsKey.GroupAlias}
								onEditModeOn={() => {
									this.setState({ currentActiveEditField: FieldsKey.GroupAlias });
								}}
								onChange={(value: any) => {
									this.setState({ [FieldsKey.GroupAlias]: value });
								}}
								props={this.props}
							/>

							<MultiInputListItem
								key={FieldsKey.MyAliasInGroup}
								inputType="text"
								isEdittable={true}
								shouldDisabled={currentActiveEditField !== '' && currentActiveEditField !== FieldsKey.MyAliasInGroup}
								profileValueKey={FieldsKey.MyAliasInGroup}
								label={locale.groups.my_alias}
								value={this.state[FieldsKey.MyAliasInGroup]}
								isEditMode={currentActiveEditField === FieldsKey.MyAliasInGroup}
								onEditModeOn={() => {
									this.setState({ currentActiveEditField: FieldsKey.MyAliasInGroup });
								}}
								onChange={(value: any) => {
									this.setState({ [FieldsKey.MyAliasInGroup]: value });
								}}
								props={this.props}
							/>

							<MultiInputListItem
								key={FieldsKey.MuteNotification}
								inputType="toggle"
								isEdittable={false}
								shouldDisabled={false}
								profileValueKey={FieldsKey.MuteNotification}
								label={locale.groups.mute_notif}
								value={this.state[FieldsKey.MuteNotification]}
								isEditMode={true}
								onChange={(value: any) => {
									this.setState({ [FieldsKey.MuteNotification]: value });
								}}
								props={this.props}
							/>

							<MultiInputListItem
								key={FieldsKey.StickOnTop}
								inputType="toggle"
								isEdittable={false}
								shouldDisabled={false}
								profileValueKey={FieldsKey.StickOnTop}
								label={locale.groups.stick_ontop}
								value={this.state[FieldsKey.StickOnTop]}
								isEditMode={true}
								onChange={(value: any) => {
									this.setState({ [FieldsKey.StickOnTop]: value });
								}}
								props={this.props}
							/>

							<MultiInputListItem
								key={FieldsKey.AlertOn}
								inputType="toggle"
								isEdittable={false}
								shouldDisabled={false}
								profileValueKey={FieldsKey.AlertOn}
								label={locale.groups.alert}
								value={this.state[FieldsKey.AlertOn]}
								isEditMode={true}
								onChange={(value: any) => {
									this.setState({ [FieldsKey.AlertOn]: value });
								}}
								props={this.props}
							/>

							<MultiInputListItem
								key={FieldsKey.DisappearingMessages}
								inputType="dropdown"
								options={[
									{ title: locale.global.off, value: 'off' },
									{ title: locale.global.on, value: 'on' },
								]}
								isEdittable={true}
								shouldDisabled={currentActiveEditField !== '' && currentActiveEditField !== FieldsKey.DisappearingMessages}
								profileValueKey={FieldsKey.DisappearingMessages}
								label={locale.groups.disappearing_messages}
								value={this.state[FieldsKey.DisappearingMessages]}
								isEditMode={currentActiveEditField === FieldsKey.DisappearingMessages}
								onEditModeOn={() => {
									this.setState({ currentActiveEditField: FieldsKey.DisappearingMessages });
								}}
								onChange={(value: any) => {
									this.setState({ [FieldsKey.DisappearingMessages]: value });
								}}
								props={this.props}
							/>
						</IonList>

						{this.props.profile.hasEdited ? (
							<IonItem key={'SaveCancel'} className="save-cancel-btns" button lines="none" detail={false}>
								<IonButton
									slot="end"
									mode="ios"
									className="edit-cancel-btn"
									onClick={() => {
										store.dispatch({ type: PROFILE_EDITED, payload: false });
									}}
								>
									{locale.global.cancel}
								</IonButton>
								<IonButton
									mode="ios"
									className="edit-save-btn"
									slot="end"
									onClick={() => {
										this.setState({ currentActiveEditField: '' });
										this.submitChanges();
									}}
								>
									{locale.global.save}
								</IonButton>
							</IonItem>
						) : null}
					</IonContent>

					<IonLoading isOpen={this.props.profile.isLoading} onDidDismiss={() => this.props.pageLoading({ loader: false })} message="Please wait..." duration={20000} />

					<div className="clear-delete-btns">
						<IonButton mode="ios" fill="clear">
							{locale.global.clear_chat_history}
						</IonButton>
						<IonButton mode="ios" fill="clear">
							{locale.global.delete_n_leave}
						</IonButton>
					</div>
				</IonPage>
			);
		} else {
			return <></>;
		}
	}
}

const mapStateToProps = (state: any) => {
	return {
		profile: state.profile,
		loggedInUser: state.global.loggedInUser,
	};
};

const mapDispatchToProps = (dispatch: any) => ({
	updateUser: (payload: String) => dispatch(updateUser(payload)),
	pageLoading: (payload: any) => dispatch(pageLoading(payload)),
});

export default connect(mapStateToProps, mapDispatchToProps)(GroupSetting);
