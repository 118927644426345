import { IonModal, IonLoading } from '@ionic/react';
import React from 'react';
import './CreateFolderpopup.scss';
import { showToast } from '../../redux/actions/global';
import store from '../../redux/store';

interface CreateFolderpopupprops {
	show: boolean;
	props?: any;
	onCloseCallBack: any;
}
interface iState {
	showfolderModal: boolean;
	isShowPopupView: boolean;
	loader: boolean;
}

class CreateFolderpopup extends React.Component<CreateFolderpopupprops, iState> {
	constructor(props: any) {
		super(props);

		this.state = {
			isShowPopupView: false,
			showfolderModal: true,
			loader: false,
		};
	}

	render() {
		return (
			<>
				<IonModal isOpen={this.props.show} onDidDismiss={this.props.onCloseCallBack} swipeToClose={true} cssClass="create-folder-popup" animated={true}>
					<div className="content">
						<div className="newfolder">New Folder</div>

						<div className="modal-head">
							<label>Name</label>
							<input placeholder="Untitled Folder" />
						</div>

						<div className="buttons">
							<div className="inner-btn">
								<button className="cancel" onClick={this.props.onCloseCallBack}>
									Cancel
								</button>
								<button
									className="create"
									onClick={() => {
										this.setState({ loader: true });
										this.props.onCloseCallBack();
										setTimeout(() => {
											this.setState({ loader: false });
										}, 2000);
										setTimeout(() => {
											store.dispatch(showToast({ toastMessage: '<img src="../assets/icon/right-thread.svg" />   Folder Created', toastType: 'secondary', toastButtonText: '' }));
										}, 2100);
									}}
								>
									Create
								</button>
							</div>
						</div>
					</div>

					{/* <CustomSpinner loader/> */}
				</IonModal>

				<IonLoading isOpen={this.state.loader} message="Creating..." />
			</>
		);
	}
}

// <Loader/>

export default CreateFolderpopup;
