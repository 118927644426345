import { ChatMediaType } from './enumService';
import React from 'react';

export default class UtilService {
	static fileMimeTypes: { [key: string]: string } = {
		'3dmf': 'x-world/x-3dmf',
		'3dm': 'x-world/x-3dmf',
		avi: 'video/x-msvideo',
		ai: 'application/postscript',
		bin: 'application/octet-stream',
		bmp: 'image/bmp',
		cab: 'application/x-shockwave-flash',
		c: 'text/plain',
		'c++': 'text/plain',
		class: 'application/java',
		css: 'text/css',
		csv: 'text/comma-separated-values',
		cdr: 'application/cdr',
		doc: 'application/msword',
		dot: 'application/msword',
		docx: 'application/msword',
		dwg: 'application/acad',
		eps: 'application/postscript',
		exe: 'application/octet-stream',
		gif: 'image/gif',
		gz: 'application/gzip',
		gtar: 'application/x-gtar',
		flv: 'video/x-flv',
		fh4: 'image/x-freehand',
		fh5: 'image/x-freehand',
		fhc: 'image/x-freehand',
		help: 'application/x-helpfile',
		hlp: 'application/x-helpfile',
		html: 'text/html',
		htm: 'text/html',
		ico: 'image/x-icon',
		imap: 'application/x-httpd-imap',
		inf: 'application/inf',
		jpe: 'image/jpeg',
		jpeg: 'image/jpeg',
		jpg: 'image/jpeg',
		js: 'application/x-javascript',
		java: 'text/x-java-source',
		latex: 'application/x-latex',
		log: 'text/plain',
		m3u: 'audio/x-mpequrl',
		midi: 'audio/midi',
		mid: 'audio/midi',
		mov: 'video/quicktime',
		mp3: 'audio/mpeg',
		mpeg: 'video/mpeg',
		mpg: 'video/mpeg',
		mp2: 'video/mpeg',
		mp4: 'video/mp4',
		webm: 'video/webm',
		'3gp': 'video/3gp',
		ogg: 'application/ogg',
		phtml: 'application/x-httpd-php',
		php: 'application/x-httpd-php',
		pdf: 'application/pdf',
		pgp: 'application/pgp',
		png: 'image/png',
		pps: 'application/mspowerpoint',
		ppt: 'application/mspowerpoint',
		ppz: 'application/mspowerpoint',
		pot: 'application/mspowerpoint',
		ps: 'application/postscript',
		qt: 'video/quicktime',
		qd3d: 'x-world/x-3dmf',
		qd3: 'x-world/x-3dmf',
		qxd: 'application/x-quark-express',
		rar: 'application/x-rar-compressed',
		ra: 'audio/x-realaudio',
		ram: 'audio/x-pn-realaudio',
		rm: 'audio/x-pn-realaudio',
		rtf: 'text/rtf',
		spr: 'application/x-sprite',
		sprite: 'application/x-sprite',
		stream: 'audio/x-qt-stream',
		swf: 'application/x-shockwave-flash',
		svg: 'text/xml-svg',
		sgml: 'text/x-sgml',
		sgm: 'text/x-sgml',
		tar: 'application/x-tar',
		tiff: 'image/tiff',
		tif: 'image/tiff',
		tgz: 'application/x-compressed',
		tex: 'application/x-tex',
		txt: 'text/plain',
		vob: 'video/x-mpg',
		wav: 'audio/x-wav',
		wrl: 'x-world/x-vrml',
		xla: 'application/msexcel',
		xlsx: 'application/msexcel',
		xls: 'application/vnd.ms-excel',
		xlc: 'application/vnd.ms-excel',
		xml: 'text/xml',
		zip: 'application/zip',
	};

	static getMediaType(mimeType: string) {
		if (mimeType.indexOf('video') !== -1) {
			return ChatMediaType.VIDEO;
		} else if (mimeType.indexOf('image') !== -1) {
			return ChatMediaType.IMAGE;
		} else if (mimeType.indexOf('audio') !== -1) {
			return ChatMediaType.AUDIO;
		} else if (mimeType.indexOf('application') !== -1 || mimeType.indexOf('text') !== -1) {
			return ChatMediaType.DOCUMENT;
		}
		return ChatMediaType.IMAGE;
	}

	static urltoFile(url: string, filename: string, mimeType: string) {
		return fetch(url)
			.then(function (res) {
				return res.arrayBuffer();
			})
			.then(function (buf) {
				return new File([buf], filename, { type: mimeType });
			});
	}

	static dataURLtoFile(dataurl: string, filename: string) {
		try {
			const arr = dataurl.split(',') as any;
			const mime = arr[0].match(/:(.*?);/)[1];
			const bstr = atob(arr[arr.length - 1]);
			let n = bstr.length;
			const u8arr = new Uint8Array(n);
			while (n--) {
				u8arr[n] = bstr.charCodeAt(n);
			}

			return new File([u8arr], filename, { type: mime });
		} catch (error) {}
		return null;
	}

	static getAudioFileDuration(file: File) {
		return new Promise((resolve: (durations: number) => void) => {
			const objectUrl = URL.createObjectURL(file);
			const myAudio = document.createElement('audio');
			myAudio.oncanplaythrough = function (e: any) {
				const seconds = e.currentTarget.duration as number;
				resolve(seconds);
			};
			myAudio.src = objectUrl;
		});
	}

	static formatDuration(durationSeconds: number) {
		const seconds = Math.floor(durationSeconds);
		if (seconds < 60) {
			return Math.floor(durationSeconds) + 's';
		}
		const minutes = Math.floor(seconds / 60);
		if (minutes < 60) {
			const remainingSeconds = Math.floor(durationSeconds - minutes * 60);
			return minutes + ':' + (remainingSeconds > 0 ? Math.floor(durationSeconds - minutes * 60) + 'm' : 'm');
		}
		return durationSeconds + 's';
	}

	static formatSize(sizeInBytes: number) {
		const kb = Math.floor(sizeInBytes / 1024);
		if (kb < 1024) {
			return kb + 'KB';
		}
		const mb = Math.floor(kb / 1024);
		return mb + 'MB';
	}

	static bytesToMb(sizeInBytes: number) {
		const kb = Math.floor(sizeInBytes / 1024);
		const mb = Math.floor(kb / 1024);
		return mb;
	}

	static replaceUrlsWithLinks(text: string) {
		const urlRegex = /(^|\s)(https?:\/\/[^\s]+|www\.[^\s]+|[\w-]+\.(com|in|org|edu|gov|mil|net|biz|info|name|co|io|ai|cc|tv|me|pro|jobs|asia|eu|africa|america|antarctica|au|ca|uk|us|in|jp|cn|br|ru|de|fr|es|it|mx|ar|za)[^\s]*)/g;
		return text.replace(urlRegex, (match, space, url) => {
			let originalUrl = url;
			if (url.startsWith('www.')) {
				url = `https://${url}`;
			} else if (!url.startsWith('http://') && !url.startsWith('https://')) {
				url = `https://www.${url}`;
			}
			return `${space}<a class='urllink' href="${url}" target="_blank" rel="noopener noreferrer">${originalUrl}</a>`;
		});
	}

	static get_url_extension(url: string) {
		return url.split(/[#?]/)[0]?.split('.')?.pop()?.trim();
	}
}
