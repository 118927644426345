export const RevertIcon = './assets/icon/undo-icon-new.svg',
	CloseIcon = './assets/icon/Close.svg',
	SketchDownload = './assets/icon/sketchDownload.svg',
	ToolbarDrawIcon = './assets/icon/sketchpad-draw-icon.svg',
	ToolbarDrawIconActive = './assets/icon/sketchpad-draw-icon-active.svg',
	ToolbarGalleryIcon = './assets/icon/galery-photo.svg',
	ToolbarGalleryIconActive = './assets/icon/galery-photo.svg',
	ToolbarShapeIcon = './assets/icon/sketchpad-shape-icon.svg',
	ToolbarShapeIconActive = './assets/icon/sketchpad-shape-icon-active.svg',
	ToolbarBackgroundIcon = './assets/icon/sketchpad-background-icon.svg',
	ToolbarBackgroundIconActive = './assets/icon/sketchpad-background-icon-active.svg',
	ToolbarTextIcon = './assets/icon/sketchpad-text-icon.svg',
	ToolbarTextIconActive = './assets/icon/sketchpad-text-icon-active.svg',
	ToolbarEmojjiIcon = './assets/icon/sketchpad-emojji-icon.svg',
	ToolbarEmojjiIconActive = './assets/icon/sketchpad-emojji-icon-active.svg',
	ArrowUpIcon = './assets/icon/arrow-up.svg',
	DeleteIcon = './assets/icon/Vector.svg',
	TextStyleIcon = './assets/icon/text-styleicon.svg',
	TextBgIcon = './assets/icon/T.svg';

export const editorColors = ['#000000', '#FFFFFF', '#FF3B30', '#FFCC4D', '#FF8300', '#04DB19', '#00A3FF', '#FF00C7'];

export const EditingType = {
	DRAWING: 'drawing',
	EMOJII: 'emojji',
	TEXT: 'text',
	BACKGROUND: 'background',
	SHAPE: 'shape',
	Gallery: 'gallery',
};
export const shapeStyles = [
	{
		title: 'Rectangle',
		value: 'rectangle',
		style: {
			width: '24px',
			height: '24px',
		},
		icon: './assets/icon/shape-rectangle.svg',
		iconActive: './assets/icon/shape-rectangle-active.svg',
	},

	{
		title: 'Line',
		value: 'line',
		style: {
			width: '24px',
			height: '24px',
		},
		icon: './assets/icon/shape-line.svg',
		iconActive: './assets/icon/shape-line-active.svg',
	},
	{
		title: 'Arrow',
		value: 'arrow',
		style: {
			width: '24px',
			height: '24px',
		},
		icon: './assets/icon/shape-arrow.svg',
		iconActive: './assets/icon/shape-arrow-active.svg',
	},
	{
		title: 'Ellipse',
		value: 'ellipse',
		style: {
			width: '24px',
			height: '24px',
		},
		icon: './assets/icon/shape-ellipse.svg',
		iconActive: './assets/icon/shape-ellipse-active.svg',
	},
	{
		title: 'Polygon',
		value: 'polygon',
		style: {
			width: '24px',
			height: '24px',
		},
		icon: './assets/icon/shape-polygon.svg',
		iconActive: './assets/icon/shape-polygon-active.svg',
	},

	{
		title: 'Star',
		value: 'star',
		style: {
			width: '24px',
			height: '24px',
		},
		icon: './assets/icon/shape-star.svg',
		iconActive: './assets/icon/shape-star-active.svg',
	},
	{
		title: 'Cross',
		value: 'cross',
		style: {
			width: '24px',
			height: '24px',
		},
		icon: './assets/icon/shape-vector.svg',
		iconActive: './assets/icon/shape-vector-active.svg',
	},
];

export const textStyles = [
	{
		title: 'Thin',
		value: 'thin',
		style: {
			fontStyle: 'normal',
			fontWeight: 100,
			fontSize: '16px',
			lineHeight: '16px',
		},
	},
	{
		title: 'Regular',
		value: 'regular',
		style: {
			fontStyle: 'normal',
			fontWeight: 400,
			fontSize: '18px',
			lineHeight: '18px',
		},
	},
	{
		title: 'Semi Bold',
		value: 'semiBold',
		style: {
			fontStyle: 'normal',
			fontWeight: 600,
			fontSize: '18px',
			lineHeight: '18px',
		},
	},
	{
		title: 'Bold',
		value: 'bold',
		style: {
			fontStyle: 'normal',
			fontWeight: 700,
			fontSize: '18px',
			lineHeight: '18px',
		},
	},
	{
		title: 'Black',
		value: 'black',
		style: {
			fontStyle: 'normal',
			fontWeight: 800,
			fontSize: '18px',
			lineHeight: '18px',
		},
	},
	{
		title: 'Thin Italic',
		value: 'thinItalic',
		style: {
			fontStyle: 'italic',
			fontWeight: 100,
			fontSize: '18px',
			lineHeight: '18px',
		},
	},
	{
		title: 'Regular Italic',
		value: 'regularItalic',
		style: {
			fontStyle: 'italic',
			fontWeight: 400,
			fontSize: '18px',
			lineHeight: '18px',
		},
	},
	{
		title: 'Semi Bold Italic',
		value: 'semiBoldItalic',
		style: {
			fontStyle: 'italic',
			fontWeight: 600,
			fontSize: '18px',
			lineHeight: '18px',
		},
	},
	{
		title: 'Bold Italic',
		value: 'boldItalic',
		style: {
			fontStyle: 'italic',
			fontWeight: 700,
			fontSize: '18px',
			lineHeight: '18px',
		},
	},
	{
		title: 'Black Italic',
		value: 'blackItalic',
		style: {
			fontStyle: 'italic',
			fontWeight: 900,
			fontSize: '18px',
			lineHeight: '18px',
		},
	},
];
export const toolbarIcons = [
	{
		type: EditingType.DRAWING,
		icon: ToolbarDrawIcon,
		iconActive: ToolbarDrawIconActive,
		size: { width: 20, height: 21 },
	},
	{
		type: EditingType.EMOJII,
		icon: ToolbarEmojjiIcon,
		iconActive: ToolbarEmojjiIconActive,
		size: { width: 25, height: 25 },
	},
	{
		type: EditingType.TEXT,
		icon: ToolbarTextIcon,
		iconActive: ToolbarTextIconActive,
		size: { width: 32, height: 24 },
	},

	{
		type: EditingType.SHAPE,
		icon: ToolbarShapeIcon,
		iconActive: ToolbarShapeIconActive,
		size: { width: 28, height: 22 },
	},
	{
		type: EditingType.Gallery,
		icon: ToolbarGalleryIcon,
		iconActive: ToolbarGalleryIconActive,
		size: { width: 25, height: 22 },
	},
	{
		type: EditingType.BACKGROUND,
		icon: ToolbarBackgroundIcon,
		iconActive: ToolbarBackgroundIconActive,
		size: { width: 25, height: 20 },
	},
];
