import React, { useEffect, useRef, useState } from 'react';
import { IonButton, IonButtons, IonCheckbox, IonIcon, IonLoading, IonModal, IonTitle, IonToolbar } from '@ionic/react';
import './ImageEditor.scss';
import { locale } from '../../locales/local';
import { PinturaEditor } from 'react-pintura';
// Import styles and functionalities from `pintura`
import 'pintura/pintura.css';

import {
	// editor
	locale_en_gb,
	createDefaultImageReader,
	createDefaultImageWriter,
	createDefaultShapePreprocessor,
	// plugins
	setPlugins,
	plugin_crop,
	plugin_crop_locale_en_gb,
	plugin_finetune,
	plugin_finetune_locale_en_gb,
	plugin_finetune_defaults,
	plugin_filter,
	plugin_filter_locale_en_gb,
	plugin_filter_defaults,
	plugin_decorate,
	markup_editor_defaults,
	plugin_decorate_locale_en_gb,
	markup_editor_locale_en_gb,
	getEditorDefaults,
	plugin_sticker,
	plugin_sticker_locale_en_gb,
	degToRad,
	createMarkupEditorToolbar,
	createMarkupEditorToolStyle,
	createMarkupEditorToolStyles,
} from 'pintura';
import SharedService from '../../services/sharedService';
import { TopNavbar } from '../../pages/common/header/topbar';

export const OpenEditorType = {
	FOR_PROFILE_GROUP_PHOTO: 'for_profile_group_photo',
	FOR_SEND_IMAGE: 'for_send_image',
	FOR_EDIT_IMAGE: 'for_edit_image',
	FOR_NEW_PROJECT: 'for_new_project',
};

export const EditingType = {
	DRAWING: 'drawing',
	STICKER: 'sticker',
	ORIGINAL: 'text',
	CROP: 'crop',
	RECTANGLE: 'rectangle',
};

interface iProps {
	show: boolean;
	outputFormat?: 'file' | 'imageData' | 'canvas' | undefined;
	openEditorType?: string;
	selectedImage: any;
	onClose?: any;
	onSave?: any;
	props?: any;
	imageOptions?: { targetWidth: number; targetHeight: number; quality: number };
}

const ImageEditor: React.FC<iProps> = ({ outputFormat = 'file', imageOptions, openEditorType, show, selectedImage, onClose, onSave, props }: iProps) => {
	setPlugins(plugin_crop, plugin_finetune, plugin_filter, plugin_decorate, plugin_sticker);

	const RotateIcon = './assets/icon/photo-rorate-icon.svg',
		CloseIcon = './assets/icon/close-icon-outline.svg',
		PhotoEditSmileIcon = './assets/icon/photo-edit-smile-icon.svg',
		PhotoEditTextIcon = './assets/icon/photo-edit-text-icon.svg',
		PhotoEditCropIcon = './assets/icon/photo-edit-crop-icon.svg',
		PhotoEditSquareIcon = './assets/icon/photo-edit-square-icon.svg',
		PhotoEditDrawIcon = './assets/icon/photo-edit-draw-icon.svg',
		RevertIcon = './assets/icon/revert-icon.svg',
		AlignLeftIcon = './assets/icon/align-left-icon.svg',
		AlignLCenterIcon = './assets/icon/align-center-icon.svg',
		AlignLRightIcon = './assets/icon/align-right-icon.svg',
		TextNormalIcon = './assets/icon/text-normal-icon.svg',
		TextSelectedIcon = './assets/icon/text-selected-icon.svg',
		editorColors = ['#FFFFFF', '#000000', '#FF3B30', '#FFCC4D', '#FF8300', '#04DB19', '#00A3FF', '#FF00C7'],
		processDefaults = {
			imageReader: createDefaultImageReader(),
			imageWriter: createDefaultImageWriter({
				mimeType: 'image/webp',
				quality: imageOptions?.quality || 1,
				format: outputFormat,
				targetSize: imageOptions?.targetWidth && imageOptions?.targetHeight ? { width: imageOptions?.targetWidth, height: imageOptions?.targetHeight } : undefined,
			}),
		},
		editorDefaults = {
			...getEditorDefaults(),
			...processDefaults,
			enableButtonRevert: true,
			enableButtonExport: false,
			...plugin_finetune_defaults,
			...plugin_filter_defaults,
			...markup_editor_defaults,
			locale: {
				...locale_en_gb,
				...plugin_crop_locale_en_gb,
				...plugin_finetune_locale_en_gb,
				...plugin_filter_locale_en_gb,
				...plugin_decorate_locale_en_gb,
				...markup_editor_locale_en_gb,
				...plugin_sticker_locale_en_gb,
			},
			// shapePreprocessor: createDefaultShapePreprocessor(),
		},
		[isImageProcessing, setIsImageProcessing] = useState(false),
		[isHideEditor, setIsHideEditor] = useState(false),
		[isUndoButtonDisabled, setIsUndoButtonDisabled] = useState(true),
		[isFullImage, setIsFullImage] = useState(false),
		[editorActiveColor, setEditorActiveColor] = useState(editorColors[0]),
		[currentEditingType, setCurrentEditingType] = useState(''),
		[loaded, setLoaded] = useState(false),
		[textSettings, setTextSettings] = useState({ color: '#fff', backgroundColor: 'transparent', textAlign: 'left', textStyle: 'regular', backgroundEnabled: false }),
		pinturaRef: React.RefObject<any> = useRef(null);

	if (openEditorType === OpenEditorType.FOR_PROFILE_GROUP_PHOTO) {
		editorDefaults.imageCropAspectRatio = 1;
		editorDefaults.enableButtonRevert = true;
		editorDefaults.enableToolbar = false;
		editorDefaults.enableUtils = false;
		editorDefaults.enableButtonClose = false;
		editorDefaults.enableButtonExport = false;
		editorDefaults.enableNavigateHistory = false;
		editorDefaults.cropEnableButtonFlipHorizontal = false;
		editorDefaults.cropEnableButtonRotateLeft = false;
		editorDefaults.cropEnableZoomInput = false;
		editorDefaults.cropEnableRotationInput = false;
		editorDefaults.cropImageSelectionCornerStyle = 'hook';
		editorDefaults.utils = ['crop', 'filter', 'finetune', 'annotate', 'decorate', 'frame', 'redact', 'resize'];
	} else if (openEditorType === OpenEditorType.FOR_SEND_IMAGE || openEditorType === OpenEditorType.FOR_EDIT_IMAGE) {
		editorDefaults.imageCropAspectRatio = 0;
		editorDefaults.enableButtonRevert = false;
		editorDefaults.enableUtils = true;
		editorDefaults.enableToolbar = false;
		editorDefaults.cropEnableButtonFlipHorizontal = false;
		editorDefaults.cropEnableButtonRotateLeft = false;
		editorDefaults.cropEnableZoomInput = false;
		editorDefaults.cropEnableZoomInput = false;
		editorDefaults.cropEnableRotationInput = false;
		editorDefaults.stickerStickToImage = true;
		editorDefaults.annotateEnableButtonFlipVertical = false;
		editorDefaults.shapePreprocessor = createDefaultShapePreprocessor();
		editorDefaults.class = '';

		// editorDefaults.utils = ['crop', 'filter', 'finetune', 'annotate', 'decorate', 'frame', 'redact', 'resize'];
		editorDefaults.utils = ['decorate', 'crop', 'filter', 'frame', 'sticker'];
		editorDefaults.markupEditorToolbar = createMarkupEditorToolbar([]); //'sharpie', 'text', 'rectangle'

		// Default
		if (currentEditingType === EditingType.DRAWING) {
			editorDefaults.decorateActiveTool = 'sharpie';
			editorDefaults.markupEditorToolStyles = createMarkupEditorToolStyles({ sharpie: createMarkupEditorToolStyle('path', { strokeColor: SharedService.hexToRgb('#ffffff'), strokeWidth: 5 }) });
		}
	}
	editorDefaults.willRenderCanvas = (shapes, state) => {
		const { utilVisibility, selectionRect, lineColor } = state;

		// exit if crop utils is not visible
		if (utilVisibility.crop <= 0) return shapes;

		// shortcuts to selection rect
		const { x, y, width, height } = selectionRect,
			partVertical = height / 3,
			partHorizontal = width / 3;
		// return updated shapes
		return {
			// copy props from shapes param
			...shapes,
			// add an `ellipse` shape
			interfaceShapes: [
				...shapes.interfaceShapes,

				{
					x1: x,
					y1: y + partVertical,
					x2: x + width,
					y2: y + partVertical,
					// opacity: utilVisibility.crop,
					inverted: true,
					// backgroundColor: [...backgroundColor, 0.5],
					strokeWidth: 5,
					strokeColor: [...lineColor],
				},
				{
					x1: x,
					y1: y + partVertical * 2,
					x2: x + width,
					y2: y + partVertical * 2,
					// opacity: utilVisibility.crop,
					inverted: true,
					// backgroundColor: [...backgroundColor, 0.5],
					strokeWidth: 5,
					strokeColor: [...lineColor],
				},
				{
					x1: x + partHorizontal,
					y1: y,
					x2: x + partHorizontal,
					y2: y + height,
					// opacity: utilVisibility.crop,
					inverted: true,
					// backgroundColor: [...backgroundColor, 0.5],
					strokeWidth: 5,
					strokeColor: [...lineColor],
				},
				{
					x1: x + partHorizontal * 2,
					y1: y,
					x2: x + partHorizontal * 2,
					y2: y + height,
					// opacity: utilVisibility.crop,
					inverted: true,
					// backgroundColor: [...backgroundColor, 0.5],
					strokeWidth: 5,
					strokeColor: [...lineColor],
				},
			],
		};
	};

	useEffect(() => {
		if (show) {
			setTimeout(() => {
				// const element: any = document.querySelector("[title='Revert']");
				// if (element) {
				// 	var observer = new MutationObserver((mutations) => {
				// 		mutations?.forEach((mutation) => {
				// 			if (mutation.type === 'attributes') {
				// 				setIsUndoButtonDisabled(element.disabled);
				// 			}
				// 		});
				// 	});
				// 	if (observer) {
				// 		observer.observe(element, {
				// 			attributes: true, //configure it to listen to attribute changes
				// 		});
				// 	}
				// }
			}, 4000);

			setTimeout(() => {
				setLoaded(true);
			}, 500);
		} else {
			setLoaded(false);
			setIsUndoButtonDisabled(true);
			setCurrentEditingType('');
		}
	}, [show]);

	const RevertButton = (
		<IonButton
			className="revert-btn"
			fill="clear"
			slot="icon-only"
			onClick={() => {
				pinturaRef?.current?.editor?.history?.undo();
			}}
		>
			<IonIcon src={RevertIcon} />
		</IonButton>
	);

	return (
		<IonModal key="pinturaEditorModal" isOpen={show} onDidDismiss={onClose} swipeToClose={false} showBackdrop={false} cssClass="pintura-editor-modal">
			{(openEditorType === OpenEditorType.FOR_SEND_IMAGE || openEditorType === OpenEditorType.FOR_EDIT_IMAGE) && (
				<>
					<IonToolbar className="top-toolbar">
						<IonButtons slot="start">
							<IonButton fill="clear" slot="icon-only" onClick={onClose}>
								<IonIcon src={CloseIcon}></IonIcon>
							</IonButton>
						</IonButtons>

						<IonButtons slot="end">
							{openEditorType === OpenEditorType.FOR_SEND_IMAGE && currentEditingType === '' && (
								<IonButton className="full-image-btn" fill="clear" onClick={() => setIsFullImage(!isFullImage)}>
									Full Image
									<IonCheckbox checked={isFullImage} />
								</IonButton>
							)}
							{!isUndoButtonDisabled && currentEditingType !== '' && RevertButton}
						</IonButtons>
					</IonToolbar>

					{currentEditingType === EditingType.ORIGINAL && (
						<IonToolbar className="top-toolbar photoedit-text-toolbar">
							<IonButtons slot="start">{!isUndoButtonDisabled && currentEditingType !== '' && RevertButton}</IonButtons>

							<IonTitle>
								<IonButton
									className="text-align-btn"
									onClick={() => {
										if (textSettings.textAlign === 'left') {
											textSettings.textAlign = 'center';
										} else if (textSettings.textAlign === 'center') {
											textSettings.textAlign = 'right';
										} else if (textSettings.textAlign === 'right') {
											textSettings.textAlign = 'left';
										}
										setTextSettings({ ...textSettings });
									}}
								>
									{textSettings.textAlign === 'left' && <IonIcon src={AlignLeftIcon}></IonIcon>}
									{textSettings.textAlign === 'center' && <IonIcon src={AlignLCenterIcon}></IonIcon>}
									{textSettings.textAlign === 'right' && <IonIcon src={AlignLRightIcon}></IonIcon>}
								</IonButton>
								<IonButton className="text-style-change-btn">Regular</IonButton>
							</IonTitle>

							<IonButtons slot="end">
								<IonButton
									className="action-btn done-btn"
									slot="end"
									onClick={() => {
										setIsImageProcessing(true);
										pinturaRef?.current?.editor?.processImage();
									}}
								>
									{locale.global.done}
								</IonButton>
							</IonButtons>
						</IonToolbar>
					)}
				</>
			)}

			{openEditorType === OpenEditorType.FOR_NEW_PROJECT && <TopNavbar {...props} hideSearchBar={true} pageTitle={'Project Photo'} isHideRightButton={true} />}

			<div
				className={'editor-container'}
				onClick={() => {
					setIsHideEditor(!isHideEditor);
				}}
			>
				{loaded && selectedImage && (
					<PinturaEditor
						disabled={currentEditingType === '' && openEditorType !== OpenEditorType.FOR_PROFILE_GROUP_PHOTO}
						key="pinturaEditor"
						{...editorDefaults}
						className="editor"
						ref={pinturaRef}
						src={selectedImage}
						onUpdateshape={() => {}}
						onRevert={() => {
							document.getElementById('undoBtn')?.setAttribute('disabled', 'true');
						}}
						onLoad={(res: any) => {
							pinturaRef?.current?.editor?.on('update', () => {
								setIsUndoButtonDisabled(false);
								document.getElementById('undoBtn')?.setAttribute('disabled', 'false');
							});
						}}
						onProcess={(result: any) => {
							const file = result?.dest;
							setIsImageProcessing(false);
							pinturaRef?.current?.editor?.close && pinturaRef.current.editor.close();
							onSave && onSave(file, isFullImage);
						}}
					/>
				)}
			</div>

			{(openEditorType === OpenEditorType.FOR_PROFILE_GROUP_PHOTO || openEditorType === OpenEditorType.FOR_NEW_PROJECT) && (
				<div className="bottom-view-for-croponly">
					<div className="tool-containers">
						<IonButton
							className="rotate-icon"
							fill="clear"
							slot="icon-only"
							onClick={() => {
								const editorRef = pinturaRef?.current?.editor;
								if (editorRef) {
									editorRef.imageRotation = (editorRef.imageRotation || 0) - degToRad(90);
								}
							}}
						>
							<IonIcon src={RotateIcon} />
						</IonButton>
					</div>
					<IonToolbar className="save-cancel-btns">
						<IonButtons slot="start">
							<IonButton
								slot="end"
								className="action-btn edit-cancel-btn"
								onClick={() => {
									pinturaRef?.current?.editor?.close && pinturaRef.current.editor.close();
									onClose && onClose();
								}}
							>
								{locale.global.cancel}
							</IonButton>
						</IonButtons>
						<IonTitle>
							<IonButton
								disabled={isUndoButtonDisabled}
								fill="clear"
								mode="ios"
								id="undoBtn"
								className="action-btn undo-btn"
								onClick={() => {
									pinturaRef?.current?.editor?.history?.revert();
								}}
							>
								{locale.global.undo}
							</IonButton>
						</IonTitle>
						<IonButtons slot="end">
							<IonButton
								className="action-btn edit-save-btn"
								slot="end"
								onClick={() => {
									setIsImageProcessing(true);
									pinturaRef?.current?.editor?.processImage();
								}}
							>
								{locale.global.done}
							</IonButton>
						</IonButtons>
					</IonToolbar>
				</div>
			)}

			{(openEditorType === OpenEditorType.FOR_SEND_IMAGE || openEditorType === OpenEditorType.FOR_EDIT_IMAGE) && (
				<div className="bottom-tools-view">
					{currentEditingType === EditingType.DRAWING && (
						<div className="colors-container">
							{editorColors.map((color, key) => (
								<div
									onClick={() => {
										setEditorActiveColor(color);
										setTimeout(() => {
											const editor = pinturaRef?.current?.editor;
											editor.decorateActiveTool = 'sharpie';
											editor.markupEditorToolStyles = createMarkupEditorToolStyles({ sharpie: createMarkupEditorToolStyle('path', { strokeColor: SharedService.hexToRgb(color), strokeWidth: 5 }) });
										}, 500);
									}}
									className={'color-circle' + (editorActiveColor === color ? ' active' : '')}
									key={key}
									style={{ backgroundColor: color }}
								></div>
							))}
						</div>
					)}

					{currentEditingType === EditingType.ORIGINAL && (
						<div className="colors-container">
							<div
								className="t-select"
								onClick={() => {
									textSettings.backgroundEnabled = !textSettings.backgroundEnabled;
									setTextSettings({ ...textSettings });
								}}
							>
								<IonIcon src={textSettings.backgroundEnabled ? TextSelectedIcon : TextNormalIcon} />
							</div>
							{editorColors.map((color, key) => (
								<div
									onClick={() => {
										setEditorActiveColor(color);
										setTimeout(() => {
											const editor = pinturaRef?.current?.editor;
											editor.decorateActiveTool = 'text';
											editor.markupEditorToolStyles = createMarkupEditorToolStyles({ sharpie: createMarkupEditorToolStyle('text', { color: SharedService.hexToRgb(color) }) });
										}, 500);
									}}
									className={'color-circle' + (editorActiveColor === color ? ' active' : '')}
									key={key}
									style={{ backgroundColor: color }}
								></div>
							))}
						</div>
					)}

					<div className="container">
						<div className="edit-options">
							<IonButton
								fill="clear"
								className={'edit-type-btn drawing-btn' + (currentEditingType === EditingType.DRAWING || currentEditingType === '' ? ' active' : '')}
								onClick={() => {
									// if (openEditorType === OpenEditorType.FOR_SEND_IMAGE) {
									// 	// const editor = pinturaRef?.current?.editor;
									// 	const decorateElement: any = document.querySelector('li[id$="decorate"] button');
									// 	decorateElement && decorateElement.click();

									// 	const PinturaControlPanelsElement: any = document.querySelector('.PinturaUtilFooter');
									// 	PinturaControlPanelsElement.style.opacity = 0;
									// 	setTimeout(() => {
									// 		const sharpieElement: any = document.querySelector('.PinturaControlListOption input[value="sharpie"]');
									// 		sharpieElement && sharpieElement.click();
									// 		sharpieElement && sharpieElement.focus();
									// 		PinturaControlPanelsElement.style.opacity = 1;
									// 	}, 100);
									// } else {
									// }
									const editor = pinturaRef?.current?.editor;
									editor.decorateActiveTool = 'sharpie';
									editor.markupEditorToolStyles = createMarkupEditorToolStyles({ sharpie: createMarkupEditorToolStyle('path', { strokeColor: SharedService.hexToRgb(editorColors[0]), strokeWidth: 5 }) });
									setCurrentEditingType(EditingType.DRAWING);
								}}
							>
								<IonIcon style={{ width: 20, height: 25 }} src={PhotoEditDrawIcon}></IonIcon>
							</IonButton>
							<IonButton
								fill="clear"
								className={'edit-type-btn' + (currentEditingType === EditingType.STICKER || currentEditingType === '' ? ' active' : '')}
								onClick={() => {
									// if (openEditorType === OpenEditorType.FOR_SEND_IMAGE) {
									// 	const element: any = document.querySelector('li[id$="sticker"] button');
									// 	element && element.click();
									// }
									const editor = pinturaRef?.current?.editor;
									editor.decorateActiveTool = 'sticker';
									editor.markupEditorToolStyles = createMarkupEditorToolStyles({ sticker: createMarkupEditorToolStyle('path', { strokeColor: SharedService.hexToRgb(editorColors[0]), strokeWidth: 5 }) });
									setCurrentEditingType(EditingType.STICKER);
								}}
							>
								<IonIcon style={{ width: 25, height: 25 }} src={PhotoEditSmileIcon}></IonIcon>
							</IonButton>
							<IonButton
								fill="clear"
								className={'edit-type-btn' + (currentEditingType === EditingType.ORIGINAL || currentEditingType === '' ? ' active' : '')}
								onClick={() => {
									// if (openEditorType === OpenEditorType.FOR_SEND_IMAGE) {
									// 	const decorateElement: any = document.querySelector('li[id$="decorate"] button');
									// 	decorateElement && decorateElement.click();

									// 	const PinturaControlPanelsElement: any = document.querySelector('.PinturaUtilFooter');
									// 	PinturaControlPanelsElement.style.opacity = 0;
									// 	setTimeout(() => {
									// 		const textElement: any = document.querySelector('.PinturaControlListOption input[value="text"]');
									// 		textElement && textElement.click();
									// 		textElement && textElement.focus();
									// 		PinturaControlPanelsElement.style.opacity = 1;
									// 	}, 100);
									// } else {
									// }
									const editor = pinturaRef?.current?.editor;
									editor.decorateActiveTool = 'text';
									editor.markupEditorToolStyles = createMarkupEditorToolStyles({ text: createMarkupEditorToolStyle('text', { color: SharedService.hexToRgb(editorColors[0]), fontSize: 30 }) });
									setCurrentEditingType(EditingType.ORIGINAL);
									setEditorActiveColor(editorColors[0]);
								}}
							>
								<IonIcon style={{ width: 32, height: 24 }} src={PhotoEditTextIcon}></IonIcon>
							</IonButton>
							<IonButton
								fill="clear"
								className={'edit-type-btn' + (currentEditingType === EditingType.CROP || currentEditingType === '' ? ' active' : '')}
								onClick={() => {
									// if (openEditorType === OpenEditorType.FOR_SEND_IMAGE) {
									// 	const element: any = document.querySelector('li[id$="crop"] button');
									// 	element && element.click();
									// } else {
									// }
									const element: any = document.querySelector('li[id$="crop"] button');
									element && element.click();
									setCurrentEditingType(EditingType.CROP);
								}}
							>
								<IonIcon style={{ width: 25, height: 25 }} src={PhotoEditCropIcon}></IonIcon>
							</IonButton>
							<IonButton
								fill="clear"
								className={'edit-type-btn' + (currentEditingType === EditingType.RECTANGLE || currentEditingType === '' ? ' active' : '')}
								onClick={($event) => {
									// if (openEditorType === OpenEditorType.FOR_SEND_IMAGE) {
									// 	// const editor = pinturaRef?.current?.editor;
									// 	const decorateElement: any = document.querySelector('li[id$="decorate"] button');
									// 	decorateElement && decorateElement.click();

									// 	const PinturaControlPanelsElement: any = document.querySelector('.PinturaUtilFooter');
									// 	PinturaControlPanelsElement.style.opacity = 0;
									// 	setTimeout(() => {
									// 		const rectangleElement: any = document.querySelector('.PinturaControlListOption input[value="rectangle"]');
									// 		rectangleElement && rectangleElement.click();
									// 		rectangleElement && rectangleElement.focus();
									// 		PinturaControlPanelsElement.style.opacity = 1;
									// 	}, 100);
									// } else {
									// }
									const editor = pinturaRef?.current?.editor;
									editor.decorateActiveTool = 'rectangle';
									setCurrentEditingType(EditingType.RECTANGLE);
								}}
							>
								<IonIcon style={{ width: 22, height: 22 }} src={PhotoEditSquareIcon}></IonIcon>
							</IonButton>
						</div>

						{currentEditingType === '' ? (
							<IonButton
								fill="clear"
								className="send-btn"
								onClick={() => {
									setIsImageProcessing(true);
									pinturaRef?.current?.editor?.processImage();
								}}
							>
								Send
							</IonButton>
						) : (
							<IonButton
								fill="clear"
								className="send-btn"
								onClick={() => {
									setCurrentEditingType('');
								}}
							>
								Done
							</IonButton>
						)}
					</div>
				</div>
			)}

			<IonLoading isOpen={isImageProcessing} message={'Processing'} />
		</IonModal>
	);
};

export default ImageEditor;
