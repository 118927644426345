import React from 'react';
import { IonButton, IonIcon } from '@ionic/react';
import './SelectComponent.scss';
import DeleteFileModal from './DeleteFileModal';
import InfoModal from './InfoModal';
import store from '../../redux/store';
import { showToast } from '../../redux/actions/global';

const imageIconList = [
	{
		icon: 'maskman.svg',
		name: 'peter',
	},
	{
		icon: 'peter.svg',
		name: 'peter',
	},
	{
		icon: 'xylo.svg',
		name: 'peter',
	},
	{
		icon: 'peter.svg',
		name: 'peter',
	},
	{
		icon: 'xylo.svg',
		name: 'peter',
	},
	{
		icon: 'peter.svg',
		name: 'peter',
	},
	{
		icon: 'xylo.svg',
		name: 'peter',
	},
	{
		icon: 'xylo.svg',
		name: 'peter',
	},
	{
		icon: 'xylo.svg',
		name: 'peter',
	},
	{
		icon: 'xylo.svg',
		name: 'peter',
	},
];
interface SelectComponentProps {
	selectedItems?: any;
	selectedName?: any;
	List: number;
	item: any;
	useFor: any; // selection, optionModal
	selectedTab: any;
	onSelectAll?: any;
	onCloseCallBack: any;
	onToastCallBack: any;
	//   Back: any
}
interface iState {
	isDeletePopupView: boolean;
	isInfoModalView: boolean;
	isShowToastView: boolean;
}

class SelectComponent extends React.Component<SelectComponentProps, iState> {
	constructor(props: any) {
		super(props);

		this.state = {
			isDeletePopupView: false,
			isInfoModalView: false,
			isShowToastView: false,
		};
	}

	_starredButton = ({ isActive = false }) => {
		return (
			<IonButton
				className="popup-media"
				fill="clear"
				onClick={() =>
					isActive
						? this.props.onToastCallBack({
								msg: '<img src="../assets/icon/Star-4.svg" />  Added to Starred',
								show: 'star',
						  })
						: ''
				}
			>
				<div className="popup-image-container"></div>

				<IonIcon className="popup-media-image" src={isActive ? '../assets/icon/popup-Star.svg' : '../assets/icon/popup-Star-light.svg'}></IonIcon>
			</IonButton>
		);
	};

	_deleteButton = ({ isActive = false }) => {
		return (
			<IonButton className="popup-media" fill="clear" onClick={() => (isActive ? this.setState({ isDeletePopupView: true }) : '')}>
				<div className="popup-image-container"></div>

				<IonIcon className="popup-media-image" src={isActive ? '../assets/icon/popup-delete.svg' : '../assets/icon/popup-delete-light.svg'}></IonIcon>
			</IonButton>
		);
	};

	_infoButton = ({ isActive = false }) => {
		return (
			<IonButton className="popup-media" fill="clear">
				<div className="popup-image-container"></div>

				<IonIcon className="popup-media-image" src={isActive ? '../assets/icon/popup-Info.svg' : '../assets/icon/popup-Info-light.svg'}></IonIcon>
			</IonButton>
		);
	};
	_rightArrowButton = ({ isActive = false }) => {
		return (
			<IonButton className="popup-media" fill="clear">
				<div className="popup-image-container"></div>

				<IonIcon className="popup-media-image" src={isActive ? '../assets/icon/popup-Right-Arrow.svg' : '../assets/icon/popup-Right-Arrow-light.svg'}></IonIcon>
			</IonButton>
		);
	};

	_rightButton = ({ isActive = false }) => {
		return (
			<IonButton className="popup-media" fill="clear">
				<div className="popup-image-container"></div>

				<IonIcon className="popup-media-image" src={isActive ? '../assets/icon/popup-Right-Arrow.svg' : '../assets/icon/popup-Right-Arrow-light.svg'}></IonIcon>
			</IonButton>
		);
	};
	_mediaButton = ({ isActive = false }) => {
		return (
			<IonButton className="popup-media" fill="clear">
				<div className="popup-image-container"></div>

				<IonIcon className="popup-media-image" src={isActive ? '../assets/icon/popup-media.svg' : '../assets/icon/popup-media-light.svg'}></IonIcon>
			</IonButton>
		);
	};
	_arrowDownButton = ({ isActive = false }) => {
		return (
			<IonButton
				className="popup-media"
				fill="clear"
				onClick={() =>
					isActive
						? (this.props.onCloseCallBack(),
						  this.props.onToastCallBack({
								msg: '<img src="../assets/icon/right.svg" />   Saved to Device',
						  }))
						: ''
				}
			>
				<div className="popup-image-container"></div>

				<IonIcon className="popup-media-image" src={isActive ? '../assets/icon/popup-Arrow-Down.svg' : '../assets/icon/popup-Arrow-Down-light.svg'}></IonIcon>
			</IonButton>
		);
	};

	_groupDotButton = ({ isActive = false }) => {
		return (
			<IonButton className="popup-media" fill="clear">
				<div className="popup-image-container"></div>

				<IonIcon className="popup-media-image" src={isActive ? '../assets/icon/Groupdot.svg' : '../assets/icon/Groupdot-light.svg'}></IonIcon>
			</IonButton>
		);
	};
	_groupAddButton = ({ isActive = false }) => {
		return (
			<IonButton className="popup-media" fill="clear">
				<div className="popup-image-container"></div>

				<IonIcon className="popup-media-image" src={isActive ? '../assets/icon/Groupadd.svg' : '../assets/icon/Groupadd-light.svg'}></IonIcon>
			</IonButton>
		);
	};

	_arrowRightButton = ({ isActive = false }) => {
		return (
			<IonButton className="popup-media" fill="clear">
				<div className="popup-image-container"></div>

				<IonIcon className="popup-media-image-right" src={isActive ? '../assets/icon/arrow-right-light.svg' : '../assets/icon/arrow-right.svg'}></IonIcon>
			</IonButton>
		);
	};
	_copyButton = ({ isActive = false }) => {
		return (
			<IonButton className="popup-media" fill="clear" onClick={() => isActive && store.dispatch(showToast({ toastMessage: '<img src="../assets/icon/right-thread.svg" />   Copied image', toastType: 'secondary', toastButtonText: 'UNDO' }))}>
				<div className="popup-image-container"></div>

				<IonIcon className="popup-media-image" src={isActive ? '../assets/icon/copymini.svg' : '../assets/icon/popup-Star-light.svg'}></IonIcon>
			</IonButton>
		);
	};
	_forwardButton = ({ isActive = false }) => {
		return (
			<IonButton className="popup-media" fill="clear">
				<div className="popup-image-container"></div>

				<IonIcon className="popup-media-image-right" src={isActive ? '../assets/icon/forwardmini1.svg' : '../assets/icon/arrow-right.svg'}></IonIcon>
			</IonButton>
		);
	};
	_checkRightButton = ({ isActive = false }) => {
		return (
			<IonButton className="popup-media" fill="clear">
				<div className="popup-image-container"></div>

				<IonIcon className="popup-media-image-right" src={isActive ? '../assets/icon/checkright.svg' : '../assets/icon/arrow-right.svg'}></IonIcon>
			</IonButton>
		);
	};

	render() {
		return (
			<>
				<>
					{this.props.useFor === 'optionModal' && (
						<div className="option-bottom-popup">
							<div className="share-files">
								<>
									{(this.props.selectedTab === 'folder' || this.props.selectedTab === 'image' || this.props.selectedTab === 'video' || this.props.selectedTab === 'file') && (
										<>
											<div className="selected-send-file">Share with</div>
											<div>{this._arrowRightButton({ isActive: true })}</div>
										</>
									)}
								</>
							</div>

							<div className="shared">
								{imageIconList.map((item: any, key: any) => {
									return (
										<div className="shared-image" key={key}>
											<IonIcon src={'../assets/icon/' + item.icon}></IonIcon>
											<div className="icon-name">{item.name}</div>
										</div>
									);
								})}
							</div>

							<div className="option-Icon-list">
								{this._deleteButton({ isActive: true })}
								{this._arrowDownButton({ isActive: true })}
								{this._starredButton({ isActive: true })}
								{this.props.selectedTab === 'folder' ? (
									<>
										{this._checkRightButton({ isActive: true })}

										{this._mediaButton({ isActive: true })}
									</>
								) : (
									<>
										{this._forwardButton({ isActive: true })}
										{this._copyButton({ isActive: true })}
									</>
								)}
								{this._rightArrowButton({ isActive: true })}
								{this._infoButton({ isActive: true })}
							</div>
						</div>
					)}
				</>
				<>
					{this.props.useFor === 'selection' ? (
						<>
							<>
								{this.props.selectedItems?.length === 1 ? (
									<>
										{this.props.selectedItems[0].isShared ? (
											<div className="bottom-manage-send-popup">
												{this.props.selectedTab === 'folder' || this.props.selectedTab === 'image' || this.props.selectedTab === 'video' || this.props.selectedTab === 'file' ? (
													<div className="select-content">
														<>
															{this.props.selectedTab === 'folder' ? <img alt="" className="img" src={'../assets/icon/foldericon.svg'} /> : ''}
															{this.props.selectedTab === 'image' ? <img alt="" className="img" src={'../assets/icon/imageicon.svg'} /> : ''}

															{this.props.selectedTab === 'video' ? <img alt="" className="img" src={'../assets/icon/videoicon.svg'} /> : ''}

															{this.props.selectedTab === 'file' ? <img alt="" className="img" src={'../assets/icon/fileicon.svg'} /> : ''}
														</>

														<p className="file-name">
															{this.props.selectedItems[0]?.FolderName}
															{this.props.selectedItems[0]?.ImageName}
															{this.props.selectedItems[0]?.VideoName}
															{this.props.selectedItems[0]?.FileName}
														</p>
													</div>
												) : (
													''
												)}
												<div className="manage-share-top">
													<div className="selected-send-file">
														<div className="manage">Manage access({imageIconList.length} )</div>

														{this._arrowRightButton({ isActive: true })}
													</div>

													<div className="shared">
														{imageIconList.map((item: any, key: any) => {
															return (
																<div className="shared-image" key={key}>
																	<IonIcon src={'../assets/icon/' + item.icon}></IonIcon>
																	<div className="icon-name">{item.name}</div>
																</div>
															);
														})}
													</div>
												</div>
											</div>
										) : (
											<div className="bottom-manage-send-popup">
												<div className="share-files">
													<>
														{this.props.selectedTab === 'folder' ? (
															<div className="select-content">
																<>{this.props.selectedTab === 'folder' ? <img alt="" className="img" src={'../assets/icon/foldericon.svg'} /> : ''}</>

																<p className="file-name">{this.props.selectedItems[0]?.FolderName}</p>
															</div>
														) : (
															''
														)}

														{this.props.selectedTab === 'folder' ? (
															<div className="selected-send-file">
																Share
																{this._arrowRightButton({ isActive: true })}
															</div>
														) : (
															<div className="select-content">
																<>
																	{this.props.selectedTab === 'folder' ? <img alt="" className="img" src={'../assets/icon/foldericon.svg'} /> : ''}
																	{this.props.selectedTab === 'image' ? <img alt="" className="img" src={'../assets/icon/imageicon.svg'} /> : ''}

																	{this.props.selectedTab === 'video' ? <img alt="" className="img" src={'../assets/icon/videoicon.svg'} /> : ''}

																	{this.props.selectedTab === 'file' ? <img alt="" className="img" src={'../assets/icon/fileicon.svg'} /> : ''}
																</>

																<p className="file-name">
																	{this.props.selectedItems[0]?.FolderName}
																	{this.props.selectedItems[0]?.ImageName}
																	{this.props.selectedItems[0]?.VideoName}
																	{this.props.selectedItems[0]?.FileName}
																</p>
															</div>
														)}
													</>
												</div>
												{this.props.selectedTab === 'image' || this.props.selectedTab === 'video' || this.props.selectedTab === 'file' ? (
													<div className="manage-share-top">
														<div className="selected-send-file">
															Send to (1)
															{this._arrowRightButton({ isActive: true })}
															{/* <IonButton className="popup-manage" fill="clear">
																<div className="arrow-right"></div>
																<IonIcon className="arrow-right-light" src={'../assets/icon/arrow-right-light.svg'}></IonIcon>
															</IonButton> */}
														</div>
													</div>
												) : (
													''
												)}
												<div className="shared">
													{imageIconList.map((item: any, key: any) => {
														return (
															<div className="shared-image" key={key}>
																<IonIcon src={'../assets/icon/' + item.icon}></IonIcon>
																<div className="icon-name">{item.name}</div>
															</div>
														);
													})}
												</div>
											</div>
										)}
									</>
								) : (
									''
								)}
							</>

							<>
								{this.props.selectedItems?.length > 1 ? (
									<div className="bottom-manage-send-popup">
										<div className="manage-share-top">
											<>
												{this.props.selectedTab === 'folder' ? (
													<div className="selected-send-file">{this.props.selectedItems?.length} items</div>
												) : (
													<>
														{this.props.selectedItems?.length < 10 ? (
															<div className="send">
																<div className="selected-send-file">
																	Send to ({this.props.selectedItems?.length}){this._arrowRightButton({ isActive: true })}
																</div>

																<div className="shared">
																	{imageIconList.map((item: any, key: any) => {
																		return (
																			<div className="shared-image" key={key}>
																				<IonIcon src={'../assets/icon/' + item.icon}></IonIcon>
																				<div className="icon-name">{item.name}</div>
																			</div>
																		);
																	})}
																</div>
															</div>
														) : (
															<div className="selected-send-file">{this.props.selectedItems?.length} items</div>
														)}
													</>
												)}
											</>
										</div>
									</div>
								) : (
									''
								)}
							</>

							<>
								<div className="bottom-media-option-popup">
									<>
										{this.props.onSelectAll ? (
											<>
												<div>
													<IonButton
														className={this.props.List === this.props.selectedItems.length ? 'select-all-active' : 'select-all'}
														fill="clear"
														onClick={() => {
															this.props.onSelectAll && this.props.onSelectAll();
														}}
													>
														Select All
													</IonButton>
												</div>

												{this.props.selectedItems?.length === 0 ? (
													<div className="popup-Icon-list">
														<div className="popup-image-container"></div>

														{this.props.selectedTab === 'folder' ? this._mediaButton({ isActive: false }) : ''}
														{this._arrowDownButton({ isActive: false })}

														{this._starredButton({ isActive: false })}
														{this._deleteButton({ isActive: false })}
														{this._rightArrowButton({ isActive: false })}

														{this._infoButton({ isActive: false })}

														<>{this.props.selectedTab === 'image' || this.props.selectedTab === 'video' || this.props.selectedTab === 'file' ? this._groupAddButton({ isActive: false }) : ''}</>
													</div>
												) : (
													<div className="popup-Icon-list">
														{this.props.selectedTab === 'folder' ? this._mediaButton({ isActive: true }) : ''}
														{this._arrowDownButton({ isActive: true })}

														{this.props.selectedItems?.length < 10 ? this._starredButton({ isActive: true }) : ''}

														{this._deleteButton({ isActive: true })}

														{this._rightArrowButton({ isActive: true })}

														{this.props.selectedItems?.length < 2 ? this._infoButton({ isActive: true }) : ''}

														{this.props.selectedItems?.length < 2 ? (
															<>
																{this.props.selectedItems?.length === 1 ? <>{this.props.selectedTab === 'image' && this.props.selectedItems[0].isShared ? this._groupDotButton({ isActive: true }) : ''}</> : ''}
																<>
																	{this.props.selectedTab === 'image' ? (
																		<>
																			{(this.props.selectedItems.length > 0 && !this.props.selectedItems[0].isShared) || this.props.selectedItems.length === 0
																				? this._groupAddButton({
																						isActive: true,
																				  })
																				: ''}
																		</>
																	) : (
																		''
																	)}
																</>
																<>{this.props.selectedTab === 'video' || this.props.selectedTab === 'file' ? this._groupAddButton({ isActive: true }) : ''}</>
															</>
														) : (
															''
														)}
													</div>
												)}
											</>
										) : (
											<div className="popup-Icon-list">
												{this._mediaButton({ isActive: true })}
												{this._arrowDownButton({ isActive: true })}

												{this._starredButton({ isActive: true })}

												{this._deleteButton({ isActive: true })}
												{this._rightArrowButton({ isActive: true })}

												{this._infoButton({ isActive: true })}

												{/* 
												<IonButton
													onClick={() => {
														//  this.props.onCloseCallBack()

														this.setState({ isInfoModalView: true });
													}}
													className="popup-media"
													fill="clear"
												>

													<IonIcon className="popup-media-image" src={'../assets/icon/popup-Info.svg'}></IonIcon>
												</IonButton>  */}
											</div>
										)}
									</>
								</div>
							</>
						</>
					) : (
						''
					)}
				</>

				<DeleteFileModal
					item={this.props.selectedItems && this.props.selectedItems.length > 0 ? this.props.selectedItems[0] : this.props.item}
					type={this.props.selectedTab}
					show={this.state.isDeletePopupView}
					onCloseCallBack={() => {
						this.setState({ isDeletePopupView: false });
					}}
				/>

				<InfoModal
					show={this.state.isInfoModalView}
					onCloseCallBack={() => {
						this.setState({ isInfoModalView: false });
					}}
				/>
			</>
		);
	}
}

export default SelectComponent;
