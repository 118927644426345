import React from 'react';
import './FormContainer.css';

interface CustomSpinnerProps {
	size?: number;
}
const CustomSpinner: React.FC<CustomSpinnerProps> = ({ size }) => {
	return (
		<div className="custom-spinner" style={{ width: size || 22 + 'px', height: size || 22 + 'px' }}>
			<img alt="icon" src="./assets/icon/loading.svg" />
		</div>
	);
};

export default CustomSpinner;
