import { IonLabel } from '@ionic/react';
import React from 'react';
import './FormContainer.css';

interface ChatIconTextButtonProps {
	selected?: boolean;
	icon: string;
	selectedIcon?: string;
	title: string;
	onClick?: any;
	containerStyle?: any;
	iconStyle?: any;
	labelStyle?: any;
}
const ChatIconTextButton: React.FC<ChatIconTextButtonProps> = ({ icon, selectedIcon, title, selected, onClick, iconStyle, labelStyle, containerStyle }) => {
	return (
		<div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', margin: '0px 16px', ...containerStyle }} onClick={onClick}>
			<div style={{ width: '64px', height: '64px', borderRadius: '14px', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
				<img alt="icon" src={'assets/icon/' + (selected ? selectedIcon : icon)} style={{ width: 30, height: 30, ...iconStyle }} />
			</div>
			<IonLabel style={{ marginTop: 8, fontSize: '14px', color: selected ? '#FF5E5E' : '#000000', ...labelStyle }}>{title}</IonLabel>
		</div>
	);
};

export default ChatIconTextButton;
