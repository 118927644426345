import store from '../redux/store';
import { apiService } from './apiService';
import { DASHBOARD_INIT, DASHBOARD_PUSH_CONTACT, DASHBOARD_LAST_MESSAGE } from '../redux/constants/dashboard';
import { Constants } from '../constants';
import { info, prefixMedia, isBlank } from '../helpers/common';
import { createNotification } from '../components/Notifications/notifications';
import { dbService } from './dbService';
// import { xmpp } from './xmpp';
// import SharedService from './sharedService';

export const controlMessageService = {
	handler: async (message: any, messageKey: any = undefined) => {
		info(`controlMessageService::processing control message ${message.action} control messageKey:`, messageKey);

		if (message.action !== 'delete') {
			let user = await dbService.me();

			let contact: any;

			switch (message.action) {
				case Constants.CONTROL.selfUpdate:
					info('controlMessageService::selfUpdate: updating user details', message.data);
					await apiService.updateUser(await prefixMedia(message.data));
					break;

				case Constants.CONTROL.contactRequest:
					info('controlMessageService::handler::contactRequest: adding unconfirmed contact', message.data.userId);
					contact = await apiService.getContactBy_id(message.data._id);

					if (isBlank(contact)) {
						await apiService.addContact(await prefixMedia(message.data));
					}

					store.dispatch({ type: DASHBOARD_PUSH_CONTACT, payload: message.data });
					createNotification({ from: 'control', body: `${message.data.userId} wants to connect with you.` }, true);
					break;

				case Constants.CONTROL.contactConfirmed:
					contact = {
						...(await prefixMedia(message.data || {})),
					};

					const contactIndex: number = user.contacts.findIndex((_contact: any) => _contact._id === contact._id);
					user.contacts[contactIndex] = contact;

					const contactConversationResponse = await apiService.getConversationByHash(contact.conversationHash, true);
					if (!contactConversationResponse.Error && contactConversationResponse.conversationHash) {
						user.conversations.unshift(contactConversationResponse);
						user = await apiService.processConversations(false, user.conversations, true, user);
					}

					setTimeout(async () => {
						await apiService.updateUser(user);
						store.dispatch({ type: DASHBOARD_INIT, payload: { user: user } });
					}, 2000);

					// store.dispatch({ type: DASHBOARD_INIT, payload: { user: user } });

					break;

				case Constants.CONTROL.contactDenied:
					info('controlMessageService::handler::contactDenied: denying contact', message.data.userId);
					await apiService.deleteContact(message.data);
					store.dispatch({ type: DASHBOARD_LAST_MESSAGE, payload: message.data });
					break;

				case Constants.CONTROL.contactBlock:
					info('controlMessageService::handler::contactBlock: blocking contact', message.data.userId);
					await apiService.blockContact(await prefixMedia(message.data));
					store.dispatch({ type: DASHBOARD_LAST_MESSAGE, payload: message.data });
					break;

				case Constants.CONTROL.contactUnblock:
					info('controlMessageService::handler::contactUnblock: unblocking contact', message.data.userId);
					await apiService.unblockContact(await prefixMedia(message.data));
					store.dispatch({ type: DASHBOARD_LAST_MESSAGE, payload: message.data });
					break;

				case Constants.CONTROL.contactChange:
					info('controlMessageService::handler::contactChange: changing contact details', message.data);
					await apiService.updateContact(await prefixMedia(message.data));
					store.dispatch({ type: DASHBOARD_LAST_MESSAGE, payload: message.data });
					break;

				case Constants.CONTROL.contactDelete:
					info('controlMessageService::handler::contactDelete: deleting contact', message.data.userId);
					await apiService.deleteContact(message.data);
					store.dispatch({ type: DASHBOARD_LAST_MESSAGE, payload: message.data });
					break;

				case Constants.CONTROL.groupAdd:
					info('controlMessageService::handler::groupAdd: adding group', message.data.groupname);

					/**
					 * first, add the group, then evaluate the members
					 * add any members that are not contacts to the contacts db
					 * with a type of groupMember
					 * ... then do the rest
					 */

					if (!user.groups.find((_group: any) => _group._id === message.data._id)) {
						await apiService.addGroup(message.data);
						store.dispatch({ type: DASHBOARD_INIT, payload: { user: user } });
					}

					break;

				case Constants.CONTROL.groupChange:
					info('controlMessageService::handler::groupChange: changing group', message.data.groupname);

					/**
					 * first, add the group, then evaluate the members
					 * add any members that are not contacts to the contacts db
					 * with a type of groupMember
					 * ... then do the rest
					 */

					if (!user.groups.find((_group: any) => _group._id === message.data._id)) {
						await apiService.updateGroup(prefixMedia(message.data));
						store.dispatch({ type: DASHBOARD_INIT, payload: { user: user } });
					}

					break;

				case Constants.CONTROL.groupDelete:
					info('controlMessageService::handler::groupDelete: deleting group', message.data.userId);

					// TODO: This needs to be redone.
					/**
					 * first, add the group, then evaluate the members
					 * delete any members that are not contacts from the contacts db
					 * with a type of groupMember
					 * ... then do the rest
					 */

					/*if (user.groups.find((_group: any) => _group.jid === message.data.jid)) {
						let groups: any = await apiService.getGroups(),
							contacts: any = await apiService.getConversations(),
							groupIndex = groups.findIndex((_group: any) => _group.jid === message.data.jid);

						groups.splice(groupIndex, 1);
						user.contacts = [...contacts, ...groups];

						await apiService.deleteGroup(message.data);
						store.dispatch({ type: DASHBOARD_INIT, payload: user: user });
					} else {
						logError(`controlMessageService::handler::groupDelete: attempt to delete non-existent group ${message.data.jid}`);
					}*/

					break;

				case Constants.CONTROL.moment:
					break;

				case Constants.CONTROL.messageUpdated:
					await apiService.updateMessage({ ...message.data, fromControl: true });
					break;

				case Constants.CONTROL.messageRead:
					await apiService.handleUpdateReadStatus({ messageKeys: message.data.messageKeys });
					break;

				case Constants.CONTROL.messageTranslated:
					await apiService.handleMessageTranslated({ message: message.data });
					break;

				case Constants.CONTROL.mediaChanged:
					await apiService.handleMediaChanged(message.data);
					break;

				case Constants.CONTROL.conversations:
					await apiService.refreshMessages();
					break;

				default:
					info(`controlMessageService::handler::not handled: ${message.type} --- ${message.action}`);
					break;
			}
		}

		info(`controlMessageService::handler `, await apiService.ackControl({ messageKey: messageKey }));
	},
};
