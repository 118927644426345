import { IonButton, IonItem, IonLabel } from '@ionic/react';
import React from 'react';
import './style.scss';
import { locale } from '../../locales/local';

interface ProjectCreatComponentProps {
	onCreateNewProject: any;
	
}

const ProjectCreatComponent: React.FC<ProjectCreatComponentProps> = ({ onCreateNewProject }) => {
	return (
		<div className="projects-creat-component">
			<IonLabel className="card-title-project">{locale.quickaccess.projects}</IonLabel>

			<div className="card" onClick={(event) => event.stopPropagation()}>
				<ProjectItemButton
					onClick={() => {
						onCreateNewProject();
					}}
				/>
			</div>
		</div>
	);
};

export default ProjectCreatComponent;

interface ProjectItemButtonProps {
	onClick?: any;
}
const ProjectItemButton: React.FC<ProjectItemButtonProps> = ({ onClick }) => {
	return (
		// <IonItem lines="none" onClick={onClick}>
		<IonButton onClick={onClick} className="create">
			Create
		</IonButton>
		// </IonItem>
	);
};
