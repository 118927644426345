import React from 'react';
import { IonButton, IonContent, IonInput, IonLabel, IonPage, IonToggle } from '@ionic/react';
import './style.scss';
import { RouteComponentProps } from 'react-router';
import { connect } from 'react-redux';
import { locale } from '../../locales/local';
import { LocalStorageKeys, NavigationRoutes } from '../../services/enumService';
import { GlobalStateTypes } from '../../types/redux-states';
import { showToast } from '../../redux/actions/global';

interface iProps {
	location: any;
	history: any;
	showToast: (payload: GlobalStateTypes) => void;
}

interface iState {
	visibleOption: any;
	codes: any;
	errorToastMessage?: string;
}

class VerificationRequired extends React.Component<iProps, iState> {
	codeInputsRefs: any = {};
	codeFields = [0, 1, 2, 3, 4, 5];
	isManuallyFocus = false;

	constructor(props: iProps) {
		super(props);
		this.state = {
			visibleOption: '',
			codes: {},
		};
	}
	_verificationAccess = () => {
		const finalCode = Object.values(this.state.codes).join('');
		if (finalCode && finalCode.toUpperCase() === 'MUMBAI') {
			this.setState({ errorToastMessage: '' });
			localStorage.setItem(LocalStorageKeys.Verfication, 'verified');
			this.props.history.push(NavigationRoutes.LOGIN);
		} else {
			this.setState({
				errorToastMessage: 'You have entered invalid code',
			});
		}
	};

	_handleInputChange = (e: any, item: number) => {
		let value = '';

		if (e.detail.value?.length! <= 1) {
			value = e.detail.value!;
		} else if (e.detail.value) {
			value = e.detail.value![0];
		}
		const codes = this.state.codes;
		codes[item] = value;
		this.setState({ codes });

		if (value) {
			if (item < this.codeFields.length - 1) {
				this.isManuallyFocus = true;
				setTimeout(() => {
					this.isManuallyFocus = false;
				}, 200);
				this.codeInputsRefs[item + 1]?.current?.setFocus(true);
			}
		} else if (!this.isManuallyFocus) {
			if (item > 0) {
				this.codeInputsRefs[item - 1]?.current?.setFocus(true);
			}
		}
	};

	render() {
		let shouldDisableButton = false;
		this.codeFields.forEach((item: number) => {
			if (!this.state.codes[item]) {
				shouldDisableButton = true;
			}
		});

		return (
			<IonPage className="auth-page">
				<IonContent className="auth-wrapper" fullscreen>
					<div className="inner-content">
						<div className="vertical-space-5" />

						<div className="be-society">
							<h1>{locale.global.app_name}</h1>
							<IonLabel>{locale.global.tagline}</IonLabel>
						</div>

						<div className="vertical-space-5" />

						<div className="verification-required">Verification Required</div>
						<div className="input-text-container">
							{this.codeFields.map((item: number, key: any) => {
								if (!this.codeInputsRefs[item]) {
									this.codeInputsRefs[item] = React.createRef();
								}
								return (
									<IonInput
										key={key}
										type="text"
										maxlength={1}
										className="input-text-field"
										value={this.state.codes[item]}
										onIonChange={(e) => this._handleInputChange(e, item)}
										ref={this.codeInputsRefs[item]}
										// onKeyDown={(e) => {
										//   if (!this.isManuallyFocus) {
										//     if (e.key === "Backspace") {
										//       if (item > 0) {
										//         this.codeInputsRefs[item - 1]?.current?.setFocus(
										//           true
										//         );
										//       }
										//     }
										//   }
										// }}
									/>
								);
							})}
						</div>
						{this.state.errorToastMessage && <div className="error-message">{this.state.errorToastMessage}</div>}
						<div className="vertical-space-5" />

						<div className="accept-button">
							<IonButton disabled={shouldDisableButton} className="send-button-buttom" onClick={this._verificationAccess}>
								Accept
							</IonButton>
						</div>
					</div>
				</IonContent>
			</IonPage>
		);
	}
}

const mapStateToProps = (state: any) => {
	return {
		profile: state.profile,
		loggedInUser: state.global.loggedInUser,
	};
};

const mapDispatchToProps = (dispatch: any) => ({
	showToast: (payload: GlobalStateTypes) => dispatch(showToast(payload)),
});

export default connect(mapStateToProps, mapDispatchToProps)(VerificationRequired);
