import React from 'react';
import { IonButton, IonButtons, IonContent, IonIcon, IonImg, IonLabel, IonPage, IonThumbnail, IonNote } from '@ionic/react';
import './style.scss';
import { RouteComponentProps } from 'react-router';
import { IonItem } from '@ionic/react';
import { DefaultProfilePic, info } from '../../helpers/common';
import { ChatType, ContactPrivacySettingType, ContactStatus, ProfileFriendActionTypes } from '../../services/enumService';
import { connect } from 'react-redux';
import { DASHBOARD_LAST_MESSAGE_CLEAR } from '../../redux/constants/dashboard';
import { initChat } from '../../redux/actions/chat';
import store from '../../redux/store';
import { TopNavbar } from '../common/header/topbar';
import { locale } from '../../locales/local';
import { apiService } from '../../services/apiService';
import SharedService from '../../services/sharedService';
import { ProfilePhotoItem, UserProfile } from '../../types/profile';
import { ContactStateTypes, GlobalStateTypes, ReduxStates } from '../../types/redux-states';
import { getProfileByToken, getProfileByUsername } from '../../redux/actions/profile';
import { BlockUserRequestData, GetProfileByTokenRequestData, GetProfileByUsernameRequestData, UnBlockUserRequestData } from '../../types/api-requests';
import { ContactItem } from '../../types/contact';
import Blockpopup from '../../modals/Blockpopup';
import { blockUser, unBlockUser } from '../../redux/actions/contact';
// import { ApiResponse } from '../../types/api-responses';
import { showStylishToast } from '../../redux/actions/global';
import UserProfileMedia from '../../components/UserProfileMedia';
import ChatSearchBar from '../../modals/ChatSearchBar/ChatSearchBar';
import { Navigation } from '../../services/navigationService';
import { ProfileFriendNavParams } from '../../types/navigation';
const MessageIcon = './assets/icon/message-round.svg';

interface iProps extends RouteComponentProps<{ name: string }> {
	getProfileByUsername: (data: GetProfileByUsernameRequestData) => Promise<UserProfile>;
	getProfileByToken: (data: GetProfileByTokenRequestData) => Promise<UserProfile>;
	blockUser: (data: BlockUserRequestData) => Promise<any>;
	showStylishToast: (payload: GlobalStateTypes) => void;
	unBlockUser: (data: UnBlockUserRequestData) => Promise<any>;

	location: any;
	confirmContact: Function;
	initChat: Function;
	contact: ContactStateTypes;
}
interface iState {
	friendProfile?: UserProfile;
	contactItem?: ContactItem;
	friendProfileActionType?: ProfileFriendActionTypes;
	showBlockPopup: boolean;
	onsearch: boolean;
}

class ProfileFriend extends React.Component<iProps, iState> {
	starredContacts = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18];
	componentIsMounted: Boolean = false;
	navParams: ProfileFriendNavParams = {} as any;

	constructor(props: iProps) {
		super(props);

		this.state = {
			showBlockPopup: false,
			onsearch: false,
			contactItem: {} as ContactItem,
			friendProfile: {} as UserProfile,
		};
	}
	setPageData = async () => {
		Navigation.getNavParamData(this.props).then((res: any) => {
			this.navParams = res;

			const userProfile: UserProfile = this.navParams.data!;
			const contactItem = userProfile ? [...(this.props.contact.contacts || []), ...(this.props.contact.blockedList || []), ...(this.props.contact.receivedRequests || []), ...(this.props.contact.sentRequests || [])]?.find((contact: ContactItem) => contact.username === userProfile.username) : undefined;

			this.setState({
				contactItem: contactItem,
				friendProfile: userProfile,
				friendProfileActionType: this.navParams.friendProfileActionType ? this.navParams.friendProfileActionType : ProfileFriendActionTypes.ContactView,
			});
			if (!userProfile && this.props.location.search) {
				const locsearch = this.props.location.search;
				const username = SharedService.getQueryStringValue(locsearch, 'username');
				if (username) {
					this.props
						.getProfileByUsername({ username })
						.then((friendProfile: UserProfile) => {
							this.setState({ friendProfile, friendProfileActionType: this.state.friendProfileActionType || ProfileFriendActionTypes.ContactAdd });
						})
						.catch((_: any) => {});
				} else {
					const token = SharedService.getQueryStringValue(locsearch, 'token');
					if (token) {
						this.props
							.getProfileByToken({ token })

							.then((friendProfile: UserProfile) => {
								this.setState({ friendProfile, friendProfileActionType: this.state.friendProfileActionType || ProfileFriendActionTypes.ContactAdd });
							})
							.catch((_: any) => {});
					}
				}
			}
		});
	};
	componentDidMount = async () => {
		this.componentIsMounted = true;
		setTimeout(() => {
			this.setPageData();
		}, 500);
	};

	componentWillUnmount(): void {
		this.componentIsMounted = false;
	}

	sendOrAcceptInvitation = () => {
		const user: UserProfile = this.state.friendProfile!;
		Navigation.addContacts({
			data: user,
			friendProfileActionType: this.state.friendProfileActionType!,
		});
	};

	blockContact = (user: any) => {
		apiService
			.blockContact({
				contactId: user._id,
				username: user.username || '',
				type: 'chat',
			})
			.then((res: any) => {});
	};

	chatClickHandler = () => {
		const data: any = this.state.friendProfile;
		data.handlerText = locale.global.start_chat;
		this.props.initChat(data);
		store.dispatch({ type: DASHBOARD_LAST_MESSAGE_CLEAR, payload: data });
		Navigation.chat({
			receiver: data,
		});
	};

	_renderHeader = () => {
		const { friendProfileActionType, friendProfile, contactItem } = this.state,
			profileUserName = friendProfile?.username || contactItem?.username || friendProfile?.profileName,
			profileUserNameToDisplay = (!profileUserName?.includes('') ? '@' : '') + '' + profileUserName;

		let pageTitle: any = '';
		if (friendProfileActionType === ProfileFriendActionTypes.ContactAdd || friendProfileActionType === ProfileFriendActionTypes.ContactView || friendProfileActionType === ProfileFriendActionTypes.ChatProfileView) {
			pageTitle = profileUserNameToDisplay;
		} else if (this.state.contactItem?.privacySetting === ContactPrivacySettingType.MOMENTS) {
			pageTitle = profileUserNameToDisplay;
		} else if (friendProfileActionType === ProfileFriendActionTypes.ContactRequestView) {
			pageTitle = profileUserName;
		}

		return (
			<TopNavbar
				{...this.props}
				hideSearchBar={true}
				onSearchIconPress={() => {
					this.setState({ onsearch: !this.state.onsearch });
				}}
				isSearchButtonActive={this.state.onsearch}
				showSearchIcon={!this.state.onsearch}
				friendProfile={this.state.friendProfile}
				pageTitle={pageTitle}
				showBack={true}
				onLeftButtonPress={() => {
					Navigation.back();
				}}
				isHideRightButton={friendProfileActionType === ProfileFriendActionTypes.ContactPending || (friendProfileActionType !== ProfileFriendActionTypes.ChatProfileView && friendProfileActionType !== ProfileFriendActionTypes.ContactView)}
			/>
		);
	};

	_renderBottomContentForAddFriend = () => {
		const contactAddBy = this.navParams.contactAddBy;
		return (
			<div
				style={{
					display: 'flex',
					alignItems: 'center',
					flex: 1,
					flexDirection: 'column',
				}}
			>
				<IonItem style={{ width: '100%' }} lines="none">
					<IonLabel>
						{locale.profile.profile_friend.from}
						<IonNote style={{ marginLeft: '16px' }}>{contactAddBy}</IonNote>
					</IonLabel>
				</IonItem>
				<div style={{ flex: 1 }}></div>
				<IonButton className="add-button" expand="block" onClick={this.sendOrAcceptInvitation}>
					{locale.global.add}
				</IonButton>

				<div className="cancel" onClick={() => Navigation.back()}>
					Cancel
				</div>
			</div>
		);
	};

	_renderBottomContentForFriendRequestView = () => {
		const { contactItem } = this.state;

		return (
			<div className="friendrequest-view-bottom-content">
				<IonItem style={{ width: '100%' }} lines="none">
					<IonLabel>
						{locale.profile.profile_friend.from}
						<IonNote style={{ marginLeft: '16px' }}>{locale.profile.profile_friend.search_id}</IonNote>
					</IonLabel>
				</IonItem>
				<div className="personal-msg">{contactItem?.message}</div>
				<div style={{ flex: 1 }}></div>
				<IonButton className="action-btn" expand="block" onClick={this.sendOrAcceptInvitation}>
					Confirm Request
				</IonButton>
				<div className="block-report">
					<IonButtons className="block-report-btns">
						<IonButton
							fill="clear"
							onClick={() => {
								this.setState({ showBlockPopup: true });
							}}
						>
							{locale.global.block}
						</IonButton>
						<div
							style={{
								width: '1px',
								height: '12px',
								backgroundColor: '#535353',
							}}
						></div>
						<IonButton fill="clear">{locale.global.report}</IonButton>
					</IonButtons>
				</div>
			</div>
		);
	};
	onChatMediaClick = async () => {
		const allContacts = this.props.contact.contacts;
		const username = this.state.friendProfile?.username;

		const contact = allContacts?.find((name) => name.username === username);

		await this.props.initChat({
			receiver: { ...contact, handlerText: locale.dashboard.start_chat },
			type: ChatType.P2P,
		});

		Navigation.chat({
			receiver: contact!,
		});
	};

	_renderBottomContentForViewFriendProfile = () => {
		return (
			<div className="communication-btns-view">
				<div className="blanck"></div>
				{this.state.contactItem?.status !== ContactStatus.BLOCKED || this.state.friendProfileActionType !== ProfileFriendActionTypes.ContactView ? (
					<UserProfileMedia chatMediaClick={() => this.onChatMediaClick()} />
				) : (
					<div className="container">
						<div className="media-icon-container-message">
							<div className="media-icon-message">
								<IonIcon className="media-icon-message" src={MessageIcon}></IonIcon>
							</div>
						</div>
					</div>
				)}

				<div className="searchid-container">
					<div className="searchid">Searched Id</div>
					<div className="searchid">03/22/22</div>
				</div>
			</div>
		);
	};

	_renderBottomContentForPendingRequestView = () => {
		return (
			<div className="communication-btns-view">
				<div className="blanck"></div>

				<div className="searchid-container">
					<div className="searchid">Searched Id</div>
					<div className="searchid">03/22/22</div>
				</div>
			</div>
		);
	};

	render() {
		const { friendProfile, friendProfileActionType, contactItem } = this.state;
		const activeProfilePhoto: ProfilePhotoItem = SharedService.extractProfilePhoto(friendProfile?.profilePhotos!)!;

		return (
			<>
				<IonPage className="profile-page">
					{this._renderHeader()}

					<IonContent className="main-content-profile has-bottom-navbar has-topbar">
						<div className="inner-content">
							<div className="user-name-item">
								<div className="profile-row">
									<IonThumbnail className="profile-photo">
										<IonImg title="Change" src={activeProfilePhoto?.url || DefaultProfilePic} />
									</IonThumbnail>
								</div>

								<IonLabel className="name" color="light">
									{contactItem?.alias || friendProfile?.profileName || '' || contactItem?.userInfo?.profileName}
								</IonLabel>

								{friendProfile?.profession && (
									<IonLabel className="professiontxt" color="light">
										{friendProfile?.profession || contactItem?.userInfo.profession}
									</IonLabel>
								)}

								{friendProfile?.bio && (
									<IonLabel className="quote" color="light">
										{friendProfile.bio}
									</IonLabel>
								)}

								{friendProfile?.website && (
									<IonLabel className="website" color="light">
										{friendProfile.website}
									</IonLabel>
								)}

								{friendProfile?.region && (
									<IonLabel className="region" color="light">
										{friendProfile.region}
									</IonLabel>
								)}
							</div>
							{this.state.contactItem?.status === ContactStatus.BLOCKED && (
								<div className="block-msg">
									<IonIcon src="assets/icon/not-recived.svg" className="not-recived"></IonIcon>
									<div className="block-text">
										{' '}
										This user has been blocked. You will not receive any messages from them.
										<span
											onClick={() => {
												if (this.state.friendProfile) {
													this.props.unBlockUser({ username: this.state.friendProfile?.username }).then(() => {
														this.props.showStylishToast({ stylishToastMessage: 'Unblocked' });
														Navigation.contactsTab();
													});
												}
											}}
										>
											Unblock
										</span>
									</div>
								</div>
							)}

							{this.state.contactItem?.privacySetting === ContactPrivacySettingType.MOMENTS && this.state.contactItem?.status !== ContactStatus.BLOCKED ? (
								<>
									<IonLabel className="moment-label">{locale.global.moments}</IonLabel>

									<IonItem
										className="starred-wrapper"
										lines="none"
										detail={true}
										onClick={() => {
											Navigation.openMoments();
										}}
									>
										{this.starredContacts.map((e: any, index: any) => {
											return (
												<div key={index} className="starred">
													<IonThumbnail onClick={() => info('')}>
														<IonImg src={`https://randomuser.me/api/portraits/${e % 2 === 0 ? 'men' : 'women'}/${e}.jpg`} />
													</IonThumbnail>
												</div>
											);
										})}
										<div style={{ minWidth: '20px' }}></div>
									</IonItem>
								</>
							) : (
								''
							)}

							{friendProfileActionType === ProfileFriendActionTypes.ContactAdd && this._renderBottomContentForAddFriend()}
							{friendProfileActionType === ProfileFriendActionTypes.ContactPending && this._renderBottomContentForPendingRequestView()}
							{friendProfileActionType === ProfileFriendActionTypes.ContactRequestView && this._renderBottomContentForFriendRequestView()}
							{(friendProfileActionType === ProfileFriendActionTypes.ContactView || friendProfileActionType === ProfileFriendActionTypes.ChatProfileView) && this._renderBottomContentForViewFriendProfile()}
						</div>
					</IonContent>
				</IonPage>
				<Blockpopup
					show={this.state.showBlockPopup}
					isOpenfrom={'profileFriend'}
					onBlockCallBack={() => {
						this.props.blockUser({ username: this.state.friendProfile?.username! }).then(() => {
							this.props.showStylishToast({ stylishToastMessage: 'Blocked' });
							Navigation.contactRecent();
						});
					}}
					onCloseCallBack={() => {
						this.setState({ showBlockPopup: false });
					}}
				/>
				<ChatSearchBar
					history={this.props.history}
					location={this.props.location}
					type={'add-member'}
					show={this.state.onsearch}
					onCancel={() => {
						this.setState({ onsearch: false });
					}}
				/>
			</>
		);
	}
}

const mapStateToProps = (state: ReduxStates) => {
	return {
		profile: state.profile,
		contact: state.contact,
	};
};

const mapDispatchToProps = (dispatch: any) => ({
	initChat: (payload: any) => dispatch(initChat(payload)),
	getProfileByUsername: (payload: GetProfileByUsernameRequestData) => dispatch(getProfileByUsername(payload)),
	getProfileByToken: (payload: GetProfileByTokenRequestData) => dispatch(getProfileByToken(payload)),
	blockUser: (payload: BlockUserRequestData) => dispatch(blockUser(payload)),
	showStylishToast: (payload: GlobalStateTypes) => dispatch(showStylishToast(payload)),
	unBlockUser: (payload: UnBlockUserRequestData) => dispatch(unBlockUser(payload)),
});
export default connect(mapStateToProps, mapDispatchToProps)(ProfileFriend);
