import { DbController } from './DbController';
import { serverApi } from './serverApi';
import { CHAT_UPDATE_CONVERSATION } from '../redux/constants/chat';
import store from '../redux/store';
import { Parser } from 'xml2js';
import _ from 'lodash';
import { isBlank, getInternalStorage, logError, info, prefixMedia, unprefixMedia, isConversationOpen, getActiveConversation, sortByTimestamp, sleep } from '../helpers/common';
import md5 from 'md5';
import { locale } from '../locales/local';
import { DASHBOARD_SHOW_LOADER, DASHBOARD_HIDE_LOADER, DASHBOARD_INIT } from '../redux/constants/dashboard';
import { xmpp } from '../services/xmpp';
import { Constants } from '../constants';
import { ChatMessageState, MessageType, ContactStatus, LocalStorageKeys, NavigationRoutes, ProfileFriendActionTypes, ChatType } from '../services/enumService';
import { MyChatOnlyProfile, MyProfile } from '../types/profile';
import {
	RegisterUsernameRequestData,
	CreateAccountRequestData,
	LoginRequestData,
	UpdateProfileRequestData,
	AddProfilePhotoRequestData,
	RemoveProfilePhotoRequestData,
	ActivateProfilePhotoRequestData,
	HideProfilePhotoRequestData,
	UnHideProfilePhotoRequestData,
	GetProfileByUsernameRequestData,
	GetUserProfileRequestData,
	GetProfileByTokenRequestData,
	SendInvitationRequestData,
	AcceptInvitationRequestData,
	RejectInvitationRequestData,
	GetRosterItemsByTagRequestData,
	RemoveRosterItemRequestData,
	AddUserToTagRequestData,
	RemoveUserFromTagRequestData,
	DeleteTagRequestData,
	BlockUserRequestData,
	UnBlockUserRequestData,
	SetTagVisibilityRequestData,
	UpdateChatOnlyProfileRequestData,
	UpdateDotsRequestData,
	ChangePrivacySettingRequestData,
	CreateMucRequestFormData,
	UpdateMucRequestFormData,
	AddUsersToMucRequestData,
	RemoveUsersFromMucRequestData,
	MucRequestData,
	CreateThreadRequestData,
	DeleteThreadRequestData,
	ListThreadsRequestData,
	UpdateThreadRequestData,
	GetThreadRequestData,
	TranslateRequestData,
	TranslateLanguagesListRequestData,
} from '../types/api-requests';
import moment from 'moment';
import { IndexDbCollections } from './DbController';
import { dbService } from './dbService';
import { ContactItem } from '../types/contact';
import { showAlert } from '../redux/actions/global';
import { Navigation } from './navigationService';

const messageFormat = {
	id: undefined,
	to: undefined,
	from: undefined,
	conversationHash: undefined,
	sender: undefined,
	originalTimestamp: undefined,
	language: undefined,
	type: undefined,
	read: 0,
	body: undefined,
	translated: 0,
	translation: undefined,
	messageType: undefined,
	tags: [],
	recalled: 0,
	deleted: 0,
	replaces: undefined,
	replaced: 0,
	replacedBy: undefined,
	inReplyTo: undefined,
	forwardedFrom: undefined,
	forwardedCount: 0,
	tagged: [],
	mediaType: undefined,
	mediaUrl: undefined,
	mediaThumbnail: undefined,
	linkPreview: undefined,
	status: undefined,
	messageKey: undefined,
};

export const apiService = {
	isReady: false,

	ping: async (data?: any) => {
		return await serverApi
			.ping(data)
			.catch((error: any) => {
				apiService.isReady = false;
				return error;
			})
			.then((response: any) => {
				apiService.isReady = true;
				return response;
			});
	},

	setReady: async () => {
		// await serverApi.setReady().catch((error: any) => error);
	},

	searchUserByUsernameInDB: async (username: string) => {
		return new Promise(async (resolve: (isFound: boolean) => void) => {
			const userProfile: ContactItem = await dbService.getContactBy_username(username);
			if (userProfile && userProfile.status) {
				if (userProfile.status === ContactStatus.BOTH) {
					Navigation.profileFriend({
						data: userProfile.userInfo,
						friendProfileActionType: ProfileFriendActionTypes.ContactView,
					});
				} else if (userProfile.status === ContactStatus.OUT_REQUEST) {
					Navigation.profileFriend({
						data: userProfile.userInfo,
						friendProfileActionType: ProfileFriendActionTypes.ContactPending,
					});
				} else if (userProfile.status === ContactStatus.IN_REQUEST) {
					store.dispatch(
						showAlert({
							alertTitle: 'Request!',
							alertMessage: `Reuqest to '${username}' is already sent`,
						})
					);
				}
				resolve(true);
			} else {
				resolve(false);
			}
		});
	},

	//////////////////////////////////////////////////////////////////////////////////////////
	////////////////////////////////////////NEW APIs//////////////////////////////////////////
	//////////////////////////////////////////////////////////////////////////////////////////

	authenticate: async (payload: LoginRequestData) =>
		await serverApi
			.authenticate(payload)
			.then(async (response: any) => {
				if (response.Error) {
					logError('apiService::authenticate::Error', response);
				}

				return response;
			})
			.catch((error: any) => error),

	getUserProfile: async (data?: GetUserProfileRequestData) => serverApi.getUserProfile(data),
	getProfileByUsername: async (data: GetProfileByUsernameRequestData) => serverApi.getProfileByUsername(data),
	getMessageIdPrefix: async () => serverApi.getMessageIdPrefix(),
	getProfileByToken: async (data: GetProfileByTokenRequestData) => serverApi.getProfileByToken(data),
	updateMyProfile: async (data: UpdateProfileRequestData) => serverApi.updateMyProfile(data),
	updateDots: async (data: UpdateDotsRequestData) => serverApi.updateDots(data),
	updateChatOnlyProfile: async (data: UpdateChatOnlyProfileRequestData) => serverApi.updateChatOnlyProfile(data),
	updateChatOnlyProfilePhoto: async (data: FormData) => serverApi.updateChatOnlyProfilePhoto(data),
	uploadFile: async (data: FormData) => serverApi.uploadFile(data),

	getMucId: async () => serverApi.getMucId(),
	createMuc: async (data: CreateMucRequestFormData) => serverApi.createMuc(data),
	updateMuc: async (data: UpdateMucRequestFormData) => serverApi.updateMuc(data),
	addUsersToMuc: async (data: AddUsersToMucRequestData) => serverApi.addUsersToMuc(data),
	removeUsersFromMuc: async (data: RemoveUsersFromMucRequestData) => serverApi.removeUsersFromMuc(data),
	getMucMembers: async (data: MucRequestData) => serverApi.getMucMembers(data),
	getMucInfo: async (data: MucRequestData) => serverApi.getMucInfo(data),
	destroyMuc: async (data: MucRequestData) => serverApi.destroyMuc(data),
	leaveMuc: async (data: MucRequestData) => serverApi.leaveMuc(data),
	getMucList: async () => serverApi.getMucList(),

	getThreadId: async (data: GetThreadRequestData) => serverApi.getThreadId(data),
	createThread: async (data: CreateThreadRequestData) => serverApi.createThread(data),
	updateThread: async (data: UpdateThreadRequestData) => serverApi.updateThread(data),
	listThreads: async (data: ListThreadsRequestData) => serverApi.listThreads(data),
	deleteThread: async (data: DeleteThreadRequestData) => serverApi.deleteThread(data),

	translateMessage: async (data: TranslateRequestData) => serverApi.translateMessage(data),
	translateLanguagesList: async (data: TranslateLanguagesListRequestData) => serverApi.translateLanguagesList(data),

	/**
	 * Generate the token for qr code generations
	 * @returns
	 */
	generateToken: async () => {
		return new Promise(async (resolve) => {
			let qrCodeToken: string = '';
			const localQrCodeToken = await DbController.where(IndexDbCollections.DATA.name, IndexDbCollections.DATA.primaryIndex, IndexDbCollections.DATA.QR_CODE_TOKEN);
			if (localQrCodeToken && localQrCodeToken.length > 0) {
				const tokeDetail = localQrCodeToken[0];
				const timeInMins = moment().diff(moment(tokeDetail.time), 'minutes');
				if (timeInMins < 30) {
					qrCodeToken = tokeDetail.token;
				}
			}
			if (!qrCodeToken) {
				const generateTokenRes = await serverApi.generateToken();
				if (generateTokenRes.success) {
					qrCodeToken = generateTokenRes.result;
					try {
						const updateRes = await DbController.update(
							IndexDbCollections.DATA.name,
							{
								token: qrCodeToken,
								time: moment().toISOString(),
								type: IndexDbCollections.DATA.QR_CODE_TOKEN,
							},
							IndexDbCollections.DATA.primaryIndex
						);
						if (!updateRes) {
							DbController.put(IndexDbCollections.DATA.name, {
								token: qrCodeToken,
								time: moment().toISOString(),
								type: IndexDbCollections.DATA.QR_CODE_TOKEN,
							});
						}
					} catch (error) {}
				}
			}

			const qrCodeUrl = (window.location.hostname === 'localhost' ? window.location.origin.replace('localhost', '192.168.1.7') : window.location.origin) + '/profile-friend?token=' + qrCodeToken;
			resolve(qrCodeUrl);
		});
	},

	addProfilePhoto: async (data: AddProfilePhotoRequestData) => serverApi.addProfilePhoto(data),
	removeProfilePhoto: async (data: RemoveProfilePhotoRequestData) => serverApi.removeProfilePhoto(data),
	updateProfilePhoto: async (data: UpdateProfileRequestData) => serverApi.updateProfilePhoto(data),
	activateProfilePhoto: async (data: ActivateProfilePhotoRequestData) => serverApi.activateProfilePhoto(data),
	hideProfilePhoto: async (data: HideProfilePhotoRequestData) => serverApi.hideProfilePhoto(data),
	unHideProfilePhoto: async (data: UnHideProfilePhotoRequestData) => serverApi.unHideProfilePhoto(data),

	getTags: async () => serverApi.getTags(),
	sendInvitation: async (data: SendInvitationRequestData) => serverApi.sendInvitation(data),
	acceptInvitation: async (data: AcceptInvitationRequestData) => serverApi.acceptInvitation(data),
	rejectInvitation: async (data: RejectInvitationRequestData) => serverApi.rejectInvitation(data),
	blockUser: async (data: BlockUserRequestData) => serverApi.blockUser(data),
	unBlockUser: async (data: UnBlockUserRequestData) => serverApi.unBlockUser(data),
	getInvitationRequests: async () => serverApi.getInvitationRequests(),
	getBlockedRosterItems: async () => serverApi.getBlockedRosterItems(),
	getRosterItems: async () => serverApi.getRosterItems(),
	getRosterItemsByTagtems: async (data: GetRosterItemsByTagRequestData) => serverApi.getRosterItemsByTag(data),
	removeRosterItem: async (data: RemoveRosterItemRequestData) => serverApi.removeRosterItem(data),
	addUserToTag: async (data: AddUserToTagRequestData) => serverApi.addUserToTag(data),
	removeUserFromTag: async (data: RemoveUserFromTagRequestData) => serverApi.removeUserFromTag(data),
	deleteTag: async (data: DeleteTagRequestData) => serverApi.deleteTag(data),
	setTagsVisibility: async (data: SetTagVisibilityRequestData) => serverApi.setTagsVisibility(data),
	changePrivacySetting: async (data: ChangePrivacySettingRequestData) => serverApi.changePrivacySetting(data),

	registerUserName: async (payload: RegisterUsernameRequestData) => serverApi.registerUserName(payload),

	registerUser: async (postData: CreateAccountRequestData) => await serverApi.registerUser(postData).catch((error: any) => error),

	addUser: async (user: any) => {
		try {
			await DbController.put('user', user);
		} catch (error) {}
	},

	updateUser: async (user: MyProfile) => {
		if (await DbController.update(IndexDbCollections.USER.name, user, user.userId)) {
			return true;
		}
		return false;
	},

	updateChatOnlyUser: async (profile: MyChatOnlyProfile) => {
		try {
			if (await DbController.update(IndexDbCollections.CHATONLYUSER.name, profile, profile.userId)) {
				return true;
			}
			await DbController.put(IndexDbCollections.CHATONLYUSER.name, profile);
		} catch (error) {}

		return false;
	},

	//////////////////////////////////////////////////////////////////////////////////////////
	/////////////////////////////////OLD APIs////////////////////////////////////
	//////////////////////////////////////////////////////////////////////////////////////////
	getUsers: async (payload: any) => {
		let response: any;

		response = await serverApi.getUsers(payload).then(async (_response: any) => {
			if (!_response.Error) {
				if (Array.isArray(_response)) {
					_response.forEach(async (_user: any) => (_user = await prefixMedia(_user)));
				} else if (_response && Object.keys(_response).length > 0) {
					_response = [await prefixMedia(_response)];
				} else {
					_response = [];
				}
			} else {
				logError('apiService::getUsers::Error', response);
			}

			return _response;
		});

		return response;
	},

	/*getContacts: async (fetchFromServer: Boolean = false, status: string = 'confirmed', type: string = 'chat') => {
		let response: any;

		try {
			if (fetchFromServer) {
				response = await serverApi
					.getContacts()
					.then(async (_response: any) => {
						if (!_response.Error) {
							for (let contact of _response) {
								contact = await prefixMedia(contact);
							}
						} else {
							logError('apiService::getContacts::serverApi.getContacts::Error:', _response);
						}

						return _response;
					})
					.catch((error: any) => error);
			} else {
				let query: any = _.omitBy(
					{
						type: type !== 'all' ? type : undefined,
						status: status !== 'all' ? status : undefined,
					},
					isBlank
				);

				response = await DbController.whereObject('contacts', query, 'lastMessage.originalTimestamp');
			}
		} catch (err) {
			logError('apiService::getContacts::Error:', err);
		}

		return response;
	},*/

	getConfirmedContacts: async () =>
		await DbController.whereObject('contacts', {
			type: 'chat',
			status: 'confirmed',
		}),

	getPendingReply: async () =>
		await DbController.whereObject('contacts', {
			type: 'chat',
			status: 'pendingReply',
		}),

	getPendingConfirm: async () =>
		await DbController.whereObject('contacts', {
			type: 'chat',
			status: 'pendingConfirm',
		}),

	getContactBy_id: async (_id: any) => {
		let user: any = await dbService.me();
		return user.contacts.find((_contact: any) => _contact._id === _id);
	},

	getContactByJid: async (jid: any) => {
		let user: any = await dbService.me();
		return user.contacts.find((_contact: any) => _contact.jid === jid);
	},

	getContactByUserId: async (userId: any) => {
		let user: any = await dbService.me();
		return user.contacts.find((_contact: any) => _contact.userId === userId);
	},

	/*addContacts: async (data: any, clear: Boolean = false, bulk: Boolean = true) => {
		let response: any;

		if (clear) {
			await DbController.clear('contacts');
		}

		if (bulk) {
			response = await DbController.bulkPut('contacts', data);
		} else {
			response = await DbController.put('contacts', data);
		}

		return response;
	},*/

	addContact: async (contact: any, updateServer: Boolean = false) => {
		let user = await dbService.me();

		// called if user initiates a contact request
		if (updateServer) {
			let updatedContact = await serverApi.addContact(contact).catch((error: any) => error);

			if (!updatedContact.Error && Object.keys(updatedContact).length > 0) {
				updatedContact = await prefixMedia(updatedContact);
				//updatedContact = await DbController.put('contacts', { ..._.omit(contact, ['personalMessage', 'remarks', 'tags']), ...updatedContact });

				user.contacts.push({
					_id: updatedContact._id,
					alias: updatedContact.alias,
					status: updatedContact.status,
					personalMessage: updatedContact.personalMessage,
					accessType: updatedContact.privacySetting,
					type: updatedContact.type,
					userId: updatedContact.userId,
				});

				try {
					await apiService.updateUser(user);
				} catch (error) {
					logError('apiService::addContact::updateUser::Error', error);
				}
			} else {
				logError('apiService::addContact::Error', updatedContact);
				return updatedContact;
			}
		} else {
			// called if responding to a contact initiated request ... needs to be confirmed
			// also called when a group contains a member that is not a contact
			//await DbController.put('contacts', contact);

			if (contact.type !== 'groupMember') {
				user.contacts.push({
					_id: contact._id,
					alias: contact.alias,
					status: contact.status,
					personalMessage: contact.personalMessage,
					accessType: contact.privacySetting,
					type: contact.type,
					userId: contact.userId,
				});

				await apiService.updateUser(user);
			}
		}

		// return await apiService.getContacts(false, 'confirmed');
		return user.contacts;
	},

	confirmContact: async (contact: any, updateServer: Boolean = false) => {
		let user: any = await dbService.me(),
			contactIndex: number = user.contacts.findIndex((_contact: any) => _contact._id === contact._id),
			contactToUpdate: any = user.contacts[contactIndex];

		contactToUpdate = { ...contactToUpdate, ...contact };

		if (updateServer) {
			const confirmContactResponse = await serverApi
				.confirmContact({
					contactId: contactToUpdate._id,
					alias: contactToUpdate.alias,
					accessType: contact.privacySetting,
					tags: contact.tags,
				})
				.catch((error: any) => error);

			if (!confirmContactResponse.Error) {
				contact = await prefixMedia(confirmContactResponse);

				const contactConversationResponse = await apiService.getConversationByHash(confirmContactResponse.conversationHash, true);
				user.contacts[contactIndex] = contact;
				if (!contactConversationResponse.Error && contactConversationResponse.conversationHash) {
					user.conversations.unshift(contactConversationResponse);
					user = await apiService.processConversations(false, user.conversations, true, user);
				} else {
					await apiService.updateUser(user);
				}

				const message = 'Contact request confirmed';
				await xmpp.sendMessage({
					messageBody: message,
					messageState: ChatMessageState.ORIGINAL,
					messageType: MessageType.REQUEST_ACCEPTED,
					conversationType: ChatType.P2P,
					receiverId: contact.username,
				});

				return contact;
			} else {
				logError('apiService::confirmContact::Error', contact);
			}
		} else {
			await apiService.updateUser(user);
		}

		return user.contacts[contactIndex];
	},

	denyContact: async (contact: any, updateServer: Boolean = false) => {
		let user: any = await dbService.me(),
			contactIndex: number = user.contacts.findIndex((_contact: any) => _contact._id === contact._id),
			contactToUpdate: any = user.contacts[contactIndex];

		contactToUpdate = { ...contactToUpdate, ...contact };

		if (updateServer) {
			contact = await serverApi.denyContact({ contactId: contactToUpdate._id }).catch((error: any) => error);

			if (contact.Error) {
				logError('apiService::denyContact::Error', contact);
			}
		}

		if (!contact.Error) {
			await apiService.updateUser(user);
		}

		return user.contacts[contactIndex];
	},

	blockContact: async (contact: any, updateServer: Boolean = false) => {
		let user: any = await dbService.me(),
			contactIndex: number = user.contacts.findIndex((_contact: any) => _contact._id === contact._id),
			contactToUpdate: any = user.contacts[contactIndex];

		contactToUpdate = { ...contactToUpdate, ...contact };

		if (updateServer) {
			contact = await serverApi.blockContact({ contactId: contactToUpdate._id }).catch((error: any) => error);

			if (contact.Error) {
				logError('apiService::denyContact::Error', contact);
			}
		}

		if (!contact.Error) {
			await apiService.updateUser(user);
		}

		return user.contacts[contactIndex];
	},

	unblockContact: async (contact: any, updateServer: Boolean = false) => {
		let user: any = await dbService.me(),
			contactIndex: number = user.contacts.findIndex((_contact: any) => _contact._id === contact._id),
			contactToUpdate: any = user.contacts[contactIndex];

		contactToUpdate = { ...contactToUpdate, ...contact };

		if (updateServer) {
			contactToUpdate = await serverApi.blockContact({ contactId: contactToUpdate._id }).catch((error: any) => error);

			if (contactToUpdate.Error) {
				logError('apiService::denyContact::Error', contact);
			}
		}

		if (!contact.Error) {
			await apiService.updateUser(user);
		}

		return user.contacts[contactIndex];
	},

	updateContact: async (contact: any, updateServer: Boolean = false) => {
		let user: any = await dbService.me(),
			contactIndex: number = user.contacts.findIndex((_contact: any) => _contact._id === contact._id);
		// contactToUpdate: any = user.contacts[contactIndex];

		// contactToUpdate = { ...contactToUpdate, ...contact };

		if (updateServer) {
			// contactToUpdate = await serverApi.updateContact(unprefixMedia(contactToUpdate)).catch((error: any) => error);

			if (contact.Error) {
				logError('apiService::updateContact::Error', contact);
			}
		}

		if (!contact.Error) {
			await apiService.updateUser(user);
		}

		return user.contacts[contactIndex];
	},

	deleteContact: async (contact: any, updateServer: Boolean = false) => {
		let user: any = await dbService.me(),
			contactIndex: number = user.contacts.findIndex((_contact: any) => _contact._id === contact._id),
			contactToUpdate: any = user.contacts[contactIndex],
			response = false;

		contactToUpdate = { ...contactToUpdate, ...contact };

		if (updateServer) {
			contact = await serverApi.deleteContact({ contactId: contactToUpdate }).catch((error: any) => error);

			if (contact.Error) {
				logError('apiService::deleteContact::Error', contact);
			}
		}

		if (!contact.Error) {
			user.contacts.splice(contactIndex, 1);
			await apiService.updateUser(user);
			response = true;
		}

		return response;
	},

	getGroupByJid: async (jid: any) => {
		let user: any = await dbService.me();
		return user.groups.find((_group: any) => _group.jid === jid);
	},

	/*getGroups: async (fetchFromServer: Boolean = false) => {
		let response: any;

		if (fetchFromServer) {
			response = await serverApi
				.getGroups()
				.then(async (_response: any) => {
					if (!_response.Error) {
						for (let _group of _response) {
							_group = await prefixMedia(_group);
						}
					} else {
						logError('apiService::getGroups::Error', _response);
					}
					return _response;
				})
				.catch((error: any) => logError('apiService::getGroups::Error:', error));
		} else {
			response = await DbController.get('groups');
		}

		return response;
	},*/

	getGroupMembers: async () => {
		let user: any = await dbService.me();
		return user.groups;
	},

	/*addGroups: async (data: any, clear: Boolean = false, bulk: Boolean = true) => {
		let response: any;

		if (clear) {
			await DbController.clear('groups');
		}

		if (bulk) {
			response = await DbController.bulkPut('groups', data);
		} else {
			response = await DbController.put('groups', data);
		}

		return response;
	},*/

	addGroup: async (group: any, updateServer: Boolean = false) => {
		let user = await dbService.me(),
			response: any;

		if (updateServer) {
			response = await serverApi.addGroup(group).then(async (_group: any) => {
				if (!_group.group.Error && !_group.user.Error) {
					info(`apiService::addGroup: group ${_group.group.groupname} created.`);
					delete _group.group.groupPhoto;
					delete _group.group.qrCode;
					try {
						//await DbController.put('groups', _group.group);
						await DbController.put('user', await prefixMedia(_group.user));
						_group.group.conversationHash = md5(`${_group.user.jid}_${_group.group.jid}`);
						return await DbController.put('contacts', {
							..._group.group,
							firstMessage: {},
							lastMessage: {},
							unreadMessages: [],
							unreadMessageCount: 0,
							taggedMessages: [],
							taggedCount: 0,
							type: ChatType.GROUP,
						});
					} catch (error) {}
				} else {
					logError('apiService::addGroup::Error(s)', _group);
					return _group;
				}
			});
		} else {
			let newGroup: any = prefixMedia(group);

			try {
				await DbController.put('groups', newGroup);
				newGroup.conversationHash = md5(`${(await dbService.me()).jid}_${newGroup.jid}`);

				response = await DbController.put('contacts', {
					newGroup,
					firstMessage: {},
					lastMessage: {},
					unreadMessages: [],
					unreadMessageCount: 0,
					taggedMessages: [],
					taggedCount: 0,
					type: ChatType.GROUP,
				});
			} catch (error) {}
		}

		if (!response?.group?.Error) {
			for (let memberIndex in response.members) {
				if (response.members[memberIndex]._id !== user._id && !user.contacts.some((_contact: any) => _contact._id === response.members[memberIndex]._id)) {
					await apiService.addContact({ ...response.members[memberIndex], type: 'groupMember' }, false);
					// should now be added to contacts as groupMember
				}
			}
		}

		return response;
	},

	updateGroup: async (group: any, updateServer: Boolean = false) => {
		let response: any;

		if (updateServer) {
			serverApi
				.updateGroup(unprefixMedia(group))
				.then(async (_response: any) => {
					if (!_response.Error) {
						_response = await prefixMedia(_response);
						try {
							await DbController.put('groups', await prefixMedia(_response));
						} catch (error) {}
					} else {
						logError('apiService::updateGroup::Error', _response);
					}

					return _response;
				})
				.catch((error: any) => error);
		} else {
			try {
				response = await DbController.put('groups', group);
			} catch (error) {}
		}

		return response;
	},

	deleteGroup: async (group: any, updateServer: Boolean = false) => {
		let response: Boolean;

		if (updateServer) {
			response = await serverApi.deleteGroup(group).then(async (_response: any) => {
				if (!_response.Error) {
					await DbController.delete('groups', {
						deleteBy: '_id',
						_id: group._id,
					});
					_response = true;
				} else {
					logError('apiService::deleteGroup::Error', _response);
					_response = false;
				}

				return _response;
			});
		} else {
			await DbController.delete('groups', { deleteBy: '_id', _id: group._id });
			response = true;
		}

		return response;
	},

	parseMessageXml: async (message: any) => {
		const parser: any = new Parser();

		// --start Decode special chars so it will not crash in parse xml
		const bodyOpen = '<body>';
		const closeOpen = '</body>';

		let startIndex = message.xml.indexOf(bodyOpen);
		let endIndex = message.xml.indexOf(closeOpen);
		let part1 = message.xml.substring(0, startIndex + bodyOpen.length);
		let part2 = message.xml.substring(startIndex + bodyOpen.length, endIndex);
		let part3 = message.xml.substring(endIndex, message.xml.length);

		var el = document.createElement('div');
		// el.innerText = el.textContent = part2;
		el.innerHTML = part2;
		part2 = el.innerHTML;

		// // --start Append slash to the  double quote so it will not crash in JSON.parse
		const innerBodyOpen = 'body":"';
		const innerBodyClose = '","messageType"';

		let startIndexOfBody = part2.indexOf(innerBodyOpen);
		let endIndexOfBody = part2.indexOf(innerBodyClose);
		let partInnerBody1 = part2.substring(0, startIndexOfBody + innerBodyOpen.length);
		let partInnerBody2 = part2.substring(startIndexOfBody + innerBodyOpen.length, endIndexOfBody);
		let partInnerBody3 = part2.substring(endIndexOfBody, part2.length);
		partInnerBody2 = partInnerBody2.replace('"', '\\"');
		part2 = partInnerBody1 + '' + partInnerBody2 + '' + partInnerBody3;
		// // --end

		message.xml = part1 + '' + part2 + '' + part3;
		// --end

		if (message.xml) {
			message.xml = await parser
				.parseStringPromise(message.xml)
				.then(async (parsed: any) => parsed)
				.catch((err: any) => info(err));
		} else {
			logError(`Messages::parseMessageXml::message`, message);
		}

		return message.xml;
	},

	reformatMessageXml: async (message: any, parsed: any, isGroup: Boolean, contact: any = {}, user: any = undefined) => {
		let response: any, messageBody: any, id: String;

		try {
			user = user || (await dbService.me());

			let fromEvent: Boolean = parsed.message.hasOwnProperty('event'),
				actualMessage: any = fromEvent ? parsed.message.event[0].items[0].item[0].message[0].$ : parsed.message.$,
				actualFrom: String = actualMessage.from.includes('conference') ? actualMessage.from.split('/')[1] : message.from.split('@')[0],
				fromJid: String = actualMessage.from.split('/')[0],
				isMe: Boolean = (isGroup && actualFrom === user.userId) || (!isGroup && (user.jid === fromJid || user.userId === actualFrom)),
				sender: String = isMe ? 'Me' : isGroup && contact?.members ? contact.members.find((member: any) => member.userId === actualFrom).alias : contact.alias || contact.username || contact.userId;

			messageBody = parsed.message?.body && _.isArray(parsed.message.body) ? parsed.message.body[0] : parsed.message?.body ? parsed.message.body : fromEvent ? parsed.message.event[0].items[0].item[0].message[0].body[0] : undefined;

			id = parsed.message.hasOwnProperty('origin-id') ? parsed.message['origin-id'][0].$.id : fromEvent ? parsed.message.event[0].items[0].item[0].message[0]['origin-id'][0].$.id : parsed.message.$.id;

			// this will fail if there is an embedded quote in the body or translation of messageBody
			try {
				messageBody = JSON.parse(messageBody);
			} catch (error) {
				// so, we manually parse the message here to find the actual body and translation
				let bodyIndex: any = messageBody.includes('"body":') ? messageBody.indexOf('"body":') + 8 : undefined,
					endBodyIndex: any = !isBlank(bodyIndex) ? (messageBody.includes('",') ? messageBody.indexOf('",', bodyIndex) : messageBody.indexOf('",', bodyIndex)) : undefined,
					body: any = !isBlank(endBodyIndex) ? messageBody.substring(bodyIndex, endBodyIndex).replace(/"/g, '&quot;') : undefined,
					translationIndex: any = message.isTranslated === 1 ? (messageBody.indexOf('"translation":', endBodyIndex) >= 0 ? messageBody.indexOf('"translation":', endBodyIndex) + 15 : undefined) : undefined,
					endTranslatedIndex =
						message.isTranslated === 1
							? messageBody.indexOf(`','`, translationIndex) >= 0
								? messageBody.indexOf(`','`, translationIndex)
								: messageBody.indexOf(`'}'`, translationIndex) >= 0
								? messageBody.indexOf(`'}`, translationIndex)
								: messageBody.indexOf(`","`, translationIndex) >= 0
								? messageBody.indexOf(`","`, translationIndex)
								: messageBody.indexOf(`"}`, translationIndex) >= 0
								? messageBody.indexOf(`"}`, translationIndex)
								: undefined
							: undefined,
					translation: any = message.isTranslated === 1 ? messageBody.substring(translationIndex, endTranslatedIndex).replace(/"/g, '&quot;') : undefined;

				messageBody = `${messageBody.substr(0, bodyIndex)}${body}",${message.isTranslated === 1 ? `"translation":"${translation}"${messageBody.substr(endTranslatedIndex + 1)}` : `${messageBody.substr(endBodyIndex + 2)}`}`.replace(/\\/g, '');
				messageBody = JSON.parse(messageBody);
			}

			if (!isBlank(messageBody.mediaUrl) && _.isArray(messageBody.mediaUrl)) {
				for (let mediaUrlIndex in messageBody.mediaUrl) {
					messageBody.mediaUrl[mediaUrlIndex] = await prefixMedia(messageBody.mediaUrl[mediaUrlIndex]);
				}
			}

			if (!isBlank(messageBody.mediaThumbnail) && _.isArray(messageBody.mediaThumbnail)) {
				for (let mediaThumbnailIndex in messageBody.mediaThumbnail) {
					messageBody.mediaThumbnail[mediaThumbnailIndex] = await prefixMedia(messageBody.mediaThumbnail[mediaThumbnailIndex]);
				}
			}

			response = _.omitBy(
				{
					...messageFormat,
					id: id,
					to: isGroup ? fromJid : parsed.message.$.to,
					from: fromJid,
					conversationHash: message.conversationHash,
					sender: sender,
					originalTimestamp: message.originalTimestamp,
					language: actualMessage['xml:lang'],
					type: actualMessage.type || messageBody.type,
					read: messageBody.read ? 1 : 0,
					recalled: messageBody.recalled ? 1 : 0,
					deleted: messageBody.deleted ? 1 : 0,
					replaced: messageBody.replaced ? 1 : 0,
					translated: messageBody?.translated ? 1 : 0,
					tagged: messageBody?.tagged ? (messageBody.tagged.findIndex((_tagged: string) => _tagged === user.userId) >= 0 ? 1 : 0) : 0,
					body: message.messageBody,
					translation: message.isTranslated ? message.translationBody : '',
					messageType: messageBody.messageType,
					tags: messageBody.tags || [],
					replaces: !isBlank(messageBody.replaces) ? messageBody.replaces : undefined,
					replacedBy: !isBlank(messageBody.replacedBy) ? messageBody.replacedBy : undefined,
					inReplyTo: !isBlank(messageBody.inReplyTo) ? messageBody.inReplyTo : undefined,
					forwardedFrom: !isBlank(messageBody.forwardedFrom) ? messageBody.forwardedFrom : undefined,
					forwardedCount: !isBlank(messageBody.forwardedCount) ? messageBody.forwardedCount : undefined,
					mediaType: messageBody.mediaType,
					mediaThumbnail: !isBlank(messageBody.mediaThumbnail) ? messageBody.mediaThumbnail : undefined,
					mediaUrl: !isBlank(messageBody.mediaUrl) ? messageBody.mediaUrl : undefined,
					mediaStorageSize: messageBody.mediaStorageSize ? messageBody.mediaStorageSize : [0],
					linkPreview: messageBody.linkPreview,
					status: messageBody?.status,
					messageKey: messageBody?.messageKey,
				},
				isBlank
			);
		} catch (error) {
			logError('apiService::reformatMessageXml::Error', error);
			logError('apiService::reformatMessageXml::message', message);
			logError('apiService::reformatMessageXml::parsed', parsed);
			throw error;
		}

		return response;
	},

	storeMessages: async (parsedMessages: any[], conversation: any) => {
		info(`apiService::processConversations::storeMessages: adding ${parsedMessages.length} messages for ${conversation.conversationHash} (${conversation.jid})`);
		//await apiService.addMessages(parsedMessages);
		return conversation.conversationHash;
	},

	processConversations: async (fetchFromServer: Boolean = false, requestedConversations: any = undefined, fromLatest: Boolean = true, userObj: any = null) => {
		info(`apiService::processConversations:: ${fetchFromServer ? 'requesting conversations fromServer' : 'using provided conversations'}`, requestedConversations);
		const user: any = userObj || (await dbService.me());

		// if not provided, request all conversations that have been active in the past 7 days
		if (isBlank(requestedConversations)) {
			let theDate = new Date();
			theDate.setDate(theDate.getDate() - 7);
			requestedConversations = user.conversations && user.conversations.length > 0 ? user.conversations.filter((_conversation: any) => _conversation?.lastMessage?.originalTimestamp >= theDate.toISOString()) : [];
		}

		let response: any = undefined,
			conversationHashes: any = [
				...requestedConversations.map((_conversation: any) => ({
					conversationHash: _conversation.conversationHash,
					start: fromLatest && xmpp.messagesLoaded && fetchFromServer && _conversation.lastMessage ? _conversation.lastMessage.originalTimestamp : undefined,
					type: _conversation.type,
				})),
			],
			receivedConversations: any;

		if (fetchFromServer && conversationHashes.length > 0) {
			receivedConversations = await serverApi.getMessages({ conversationHashes: conversationHashes }).catch((error: any) => {
				logError(`apiService::processConversations::error:`, error);
			});

			receivedConversations = (!_.isArray(receivedConversations) && [receivedConversations]) || receivedConversations;
		} else {
			receivedConversations = requestedConversations;
		}

		if (!isBlank(receivedConversations) && !receivedConversations.Error) {
			try {
				for (let conversationIndex in user.conversations) {
					if (
						_.includes(
							receivedConversations.map((_conversation: any) => _conversation.conversationHash),
							user.conversations[conversationIndex].conversationHash
						)
					) {
						let conversation = user.conversations[conversationIndex],
							receivedConversation = receivedConversations.find((_conversation: any) => _conversation.conversationHash === conversation.conversationHash),
							contactIndex = user.contacts?.findIndex((_contact: any) => _contact.conversationHash === conversation.conversationHash),
							groupIndex = user.groups?.findIndex((_group: any) => _group.conversationHash === conversation.conversationHash),
							contact = contactIndex >= 0 ? user.contacts[contactIndex] : groupIndex >= 0 ? user.groups[groupIndex] : user,
							isNotepad: Boolean = user.conversationHash === contact.conversationHash,
							isGroup: Boolean = !isNotepad && conversation.type === ChatType.GROUP,
							parsedMessages: any[] = [],
							mappedMessages = conversation.messages?.map((_m: any) => _m.messageKey);

						if (!conversation.messages) conversation.messages = [];

						if (!isNotepad) {
							conversation = { ...contact, ...conversation };
							user.conversations[conversationIndex] = conversation;
						} else {
							conversation = {
								userId: user.userId,
								alias: user.alias,
								username: user.username,
								notepadJid: user.notepadJid,
								jid: user.notepadJid,
								conversationHash: user.conversationHash,
								pubKey: user.pubKey,
								profilePhoto: user.profilePhoto,
								profileThumb: user.profileThumb,
								...conversation,
							};
							user.conversations[conversationIndex] = conversation;
						}

						if (receivedConversation?.messages && receivedConversation?.messages?.length > 0) {
							if (isGroup) {
								conversation.members = contact.members;
							}

							// sequence of messages should be in descending order
							receivedConversation.messages = receivedConversation.messages.sort(sortByTimestamp).reverse();

							for (let message of receivedConversation.messages) {
								let parsed, reformatted;

								if (message.xml) {
									parsed = await apiService.parseMessageXml(message);
									reformatted = await apiService.reformatMessageXml(message, parsed, isGroup, conversation, user);
								} else {
									reformatted = message;
								}

								// check if this message contains media.  if so, the media must be prefixed
								if (reformatted.isMedia) {
									reformatted.mediaThumbnail[0] = await prefixMedia(reformatted.mediaThumbnail[0]);
									reformatted.mediaUrl[0] = await prefixMedia(reformatted.mediaUrl[0]);
								}

								parsedMessages.push(reformatted);

								if (!isBlank(mappedMessages) && !mappedMessages.includes(message.messageKey)) {
									conversation.messages.push(reformatted);
								} else if (!isBlank(conversation.messages)) {
									const messageIndex = conversation.messages.findIndex((_message: any) => _message.messageKey === message.messageKey);
									if (messageIndex !== -1) {
										conversation.messages[messageIndex] = reformatted;
									} else {
										conversation.messages.push(reformatted);
									}
								} else {
									conversation.messages.push(reformatted);
								}
							}

							if (parsedMessages.length > 0) {
								conversation.firstMessage = parsedMessages.slice(-1)[0];
								contact.firstMessage = conversation.firstMessage;
								conversation.lastMessage = parsedMessages[0];
								contact.lastMessage = conversation.lastMessage;
								await apiService.storeMessages(parsedMessages, {
									...conversation,
									jid: contact.jid,
								});
							}
						} else {
							if (!isNotepad) {
								conversation.unreadMessages = [];
								conversation.unreadCount = 0;
							}

							if (!conversation || !conversation.messages) {
								info(`apiService::processConversations:: conversation ${contact.userId || contact.groupname} (${contact.conversationHash}) does not have a message array`);
							}
						}

						user.contacts[contactIndex] = contact;
						user.conversations[conversationIndex] = conversation;
						const indexNumber: any = conversationIndex;
						await apiService.updateConversation(user, indexNumber);

						if (isConversationOpen() && getActiveConversation(user) === contact.jid.split('@')[0]) {
							// No needed as we changed in mysqlMonitor -: commented sdev
							await apiService.updateReadStatus({
								messageKeys: parsedMessages.map((_message: any) => _message.messageKey),
							});
						}
					}
				}

				await apiService.updateUser(user);
				response = user;
			} catch (error) {
				logError('apiService::processConversations::Error:', error);
				logError('last known serverMessages: ', JSON.stringify(receivedConversations));
				response = user;
				throw error;
			}
		} else {
			//logError('apiService::processConversations::Server Error:', receivedConversations);
			response = user;
		}

		info('apiService::processConversations: done');
		return response;
	},

	refreshMessages: async (conversations: any = undefined, fromLatest: Boolean = true) => {
		let user = await dbService.me(),
			cookies: any = getInternalStorage();

		if (cookies[LocalStorageKeys.Uuid]) {
			store.dispatch({
				type: DASHBOARD_SHOW_LOADER,
				payload: {
					loader: true,
					loaderMessage: locale.reducers.chat.init_state.syncing_messages,
				},
			});

			if (!isBlank(conversations) && fromLatest) {
				let theDate = new Date();
				theDate.setDate(theDate.getDate() - 7);
				conversations = user.conversations.filter((_conversation: any) => _conversation?.lastMessage?.originalTimestamp >= theDate.toISOString());
			}

			user = await apiService.processConversations(true, conversations, fromLatest).catch(async (error: any) => {
				logError(`apiService::refreshMessages::error: ${error}`);
				await sleep(1000);
			});

			store.dispatch({ type: DASHBOARD_HIDE_LOADER });
			xmpp.setMessagesLoaded(true);
			return user;
		}
	},

	getConversations: async (conversationHash: any = undefined) => {
		let user = await dbService.me(),
			response: any;

		if (conversationHash) {
			if (conversationHash === user.conversationHash) {
				response = user;
			} else {
				response = {
					...user.contacts.find((_contact: any) => _contact.conversationHash === conversationHash),
					...user.conversations.find((_conversation: any) => _conversation.conversationHash === conversationHash),
				};
			}
		} else {
			response = user?.conversations;
		}

		return response;
	},

	getConversationByHash: async (conversationHash: any, fetchFromServer: boolean = false, userId: string = '') => {
		let response: any,
			user: any = await dbService.me();

		if (fetchFromServer) {
			const serverResponse = await serverApi.getConversation({
				id: userId || user._id,
				conversationHash,
			});
			if (serverResponse.Error) {
				response = null;
			} else {
				response = serverResponse;
			}
		} else {
			if (conversationHash === user.conversationHash) {
				response = user;
			} else {
				response = {
					...user.contacts.find((_contact: any) => _contact.conversationHash === conversationHash),
					...user.conversations.find((_conversation: any) => _conversation.conversationHash === conversationHash),
				};
			}
		}

		return response;
	},

	updateConversation: async (user: any, conversationIndex: number) => {
		let response: any;

		if (isConversationOpen() && _.includes([user.conversations[conversationIndex].userId, user.conversations[conversationIndex].room], getActiveConversation(user))) {
			store.dispatch({
				type: CHAT_UPDATE_CONVERSATION,
				payload: {
					history: user.conversations[conversationIndex].messages,
					receiver: user.conversations[conversationIndex],
				},
			});
		}

		store.dispatch({ type: DASHBOARD_INIT, payload: { user: user } });
		return response;
	},

	getMessage: async (data: any) => {
		let user: any = dbService.me(),
			messages = _.flatten(user.conversations.map((_conversation: any) => _conversation.messages)),
			search: string = Object.keys(data)[0],
			message: any = messages.find((_message: any) => _message[search] === data[search]);
		return message ? message[0] : undefined;
	},

	setFirstMessage: async (conversationHash: string, firstMessage: any) => {
		let response: any,
			user = await dbService.me();

		if (conversationHash === user.conversationHash) {
			user.firstMessage = firstMessage;
		} else {
			user.conversations.find((_conversation: any) => _conversation.conversationHash === conversationHash).firstMessage = firstMessage;
		}

		response = await apiService.updateUser(user);
		return response;
	},

	setLastMessage: async (conversationHash: string, lastMessage: any) => {
		let response: any,
			user = await dbService.me();

		if (conversationHash === user.conversationHash) {
			user.firstMessage = lastMessage;
		} else {
			user.conversations.find((_conversation: any) => _conversation.conversationHash === conversationHash).lastMessage = lastMessage;
		}

		response = await apiService.updateUser(user);
		return response;
	},

	saveMessage: async (message: any, user: any) => {
		let conversationIndex: number = user.conversations.findIndex((_conversation: any) => _conversation.conversationHash === message.conversationHash),
			conversation: any = user.conversations[conversationIndex];

		if (conversationIndex >= 0) {
			const messageIndex: number = conversation?.messages?.findIndex((_message: any) => _message.messageKey === message.messageKey);
			if (!conversation.messages) {
				conversation.messages = [];
			}
			if (messageIndex < 0) {
				conversation.messages.push(message);
				conversation.lastMessage = message;
			} else {
				conversation.messages[messageIndex] = message;
			}

			user.conversations[conversationIndex] = conversation;
		}
		await apiService.updateUser(user);
		return user;
	},

	updateMessage: async (data: any, updateServer: Boolean = false, messageType = '') => {
		let response: any,
			user: any = await dbService.me(),
			updatedMessage: any,
			isRead: Boolean = !data.fromControl ? data.read === 1 : data.isRead,
			isRecalled: Boolean = !data.fromControl ? data.recalled === 1 : data.isRecalled,
			isReplaced: Boolean = !data.fromControl ? data.replaced === 1 : data.isReplaced,
			isTagged: Boolean = !data.fromControl ? data.tagged === 1 : data.isTagged,
			isTranslated: Boolean = !data.fromControl ? data.translated === 1 : data.isTranslated,
			isDeleted: Boolean = !data.fromControl ? data.deleted === 1 : data.isDeleted;

		if (updateServer) {
			// if the message is internal from xmpp, data.isX will be populated
			// otherwise if it comes from a control message, data.X will be populated
			updatedMessage = await serverApi
				.updateMessage(
					_.omitBy(
						{
							message: data,
							isRead: isRead,
							isRecalled: isRecalled,
							isReplaced: isReplaced,
							isTranslated: isTranslated,
							translation: isTranslated ? data.translation : '',
							isTagged: isTagged,
							isDeleted: isDeleted,
						},
						isBlank
					)
				)
				.then(async (updatedMessage: any) => updatedMessage)
				.catch((error: any) => error);
		}

		if (updatedMessage.updated) {
			let conversationIndex: number = user.conversations.findIndex((_conversation: any) => _conversation.conversationHash === updatedMessage.conversationHash),
				conversation: any = user.conversations[conversationIndex],
				messageIndex: number = conversation.findIndex((_message: any) => _message.messageKey === updatedMessage.messageKey),
				parsed: any = await apiService.parseMessageXml(updatedMessage);

			if (!isDeleted) {
				updatedMessage = await apiService.reformatMessageXml(updatedMessage, parsed, updatedMessage.type === ChatType.GROUP, conversation);
				user = await apiService.saveMessage(updatedMessage, user);
			} else {
				conversation.messages.splice(messageIndex, 1);
				await apiService.updateUser(user);
				await DbController.delete('messages', { deleteBy: 'id', id: data.id });
			}

			await apiService.updateConversation(user, conversationIndex);
			info('apiService::updateMessage::reponse: ', response);
		} else {
			logError('apiService::updateMessage: message was not updated');
		}

		return response;
	},

	updateReadStatusQueue: [] as [],
	updateReadStatusProcessing: false as Boolean,

	updateReadStatus: async (data: any) => {
		if (data.conversationHash || (data.messageKeys && data.messageKeys.length > 0)) {
			apiService.updateReadStatusProcessing = true;
			info(`apiService::updateReadStatus: updating server. data:`, data);
			let serverRequest = data.messageKeys ? { messageKeys: data.messageKeys } : { conversationHash: data.conversation.conversationHash },
				serverResponse: any = await serverApi.updateReadStatus({ ...serverRequest, v2: true }).catch((error: any) => error);

			if (serverResponse.Error) {
				logError(`apiService::updateReadStatus: Error:`, serverResponse.Error);
			} else {
				info(`apiService::updateReadStatus: serverResponse:`, serverResponse);

				if (serverResponse.updatedReadStatus) {
					let messageKeys = data.messageKeys ? data.messageKeys : data.conversation.unreadMessages;
					await apiService.handleUpdateReadStatus(
						messageKeys.map((_messageKey: any) => ({
							messageKey: _messageKey,
							conversationHash: data.conversationHash,
						}))
					);
				}
			}

			apiService.updateReadStatusProcessing = false;
		}
	},

	handleUpdateReadStatus: async (data: any) => {
		if (_.isArray(data) && data.length > 0) {
			const user: any = await dbService.me(),
				updateUnreadCount = (conversation: any) => conversation.messages.reduce((a: any, v: any) => (v.read === 0 ? a + 1 : a), 0);

			for (let dataIndex in data) {
				info(`apiService::handleUpdateReadStatus: fetching messageKey:`, data[dataIndex].messageKey);
				let conversationIndex: number = user.conversations.findIndex((_conversation: any) => _conversation.conversationHash === data[dataIndex].conversationHash),
					conversation: any = conversationIndex >= 0 ? user.conversations[conversationIndex] : undefined,
					messageIndex: number = conversation ? conversation.messages.findIndex((_message: any) => _message.messageKey === data[dataIndex].messageKey) : -1,
					message: any = messageIndex >= 0 ? conversation.messages[messageIndex] : undefined;

				if (message) {
					message = {
						...message,
						read: 1,
						status: Constants.MESSAGE_STATUS.Reconciled,
					};
					conversation.messages[messageIndex] = message;

					if (conversation.unreadMessages && conversation.unreadMessages.length > 0) {
						conversation.unreadMessages.splice(
							conversation.unreadMessages.findIndex((_message: any) => _message.messageKey === message.messageKey),
							1
						);
						conversation.unreadCount = updateUnreadCount(conversation);
					}

					user.conversations[conversationIndex] = conversation;
					await apiService.updateUser(user);
					await apiService.updateConversation(user, conversationIndex);
					info(`apiService::updateReadStatus: ${data.length} message status changed to read.`);
				} else {
					logError(`apiService::updateReadStatus: Unable to find conversation for conversationHash ${data[dataIndex].conversationHash} with messageKey ${data[dataIndex].messageKey}`);
				}
			}
		}
	},

	handleMessageTranslated: async (data: any) => {
		if (data.isTranslated === 1) {
			let user: any = await dbService.me(),
				conversationIndex: number = user.conversations.findIndex((_conversation: any) => _conversation.conversationHash === data.conversationHash),
				conversation: any = conversationIndex >= 0 && user.conversations[conversationIndex],
				messageIndex: number = conversation.messages.findIndex((_message: any) => _message.messageKey === data.messageKey),
				message: any = messageIndex >= 0 && conversation.messages[messageIndex];

			message = {
				...message,
				translated: data.isTranslated,
				translation: data.translation,
			};
			user = await apiService.saveMessage(message, user);
			await apiService.updateConversation(user, conversationIndex);
			info(`apiService::handleTranslated: translation applied`);
		}
	},

	uploadMedia: async (data: any) => {
		let response: any = await serverApi.uploadMedia(data);

		if (!response.mediaId) {
			logError(`apiService::uploadMedia: media was not uploaded`);
		}

		return response;
	},

	cancelUpload: () => serverApi.cancelUpload(),

	handleMediaChanged: async (data: any) => {
		// this handles the incoming mediaChanged requests
		// there will be a message indicating the thumbnail has been processed
		// followed by a message indicating the original media has been processed

		info(`apiService::handleMediaChanged: ${data.mediaTypes[0]} media changed for messageKey`, data.messageKeys[0]);

		/*if(data.mediaTypes[0] !== ChatMediaType.THUMBNAIL) {
			let user: any = await dbService.me(),
				conversationIndex: number = user.conversations.findIndex((_conversation: any) => _conversation.conversationHash === data.conversationHashes[0]),
				conversation: any = conversationIndex >= 0 ? user.conversations[conversationIndex] : undefined,
				messageIndex: number = conversation ? conversation.messages.findIndex((_message: any) => _message.messageKey === data.messageKeys[0]) : undefined,
				galleryIndex: number = user.gallery.findIndex((_galleryItem: any) => _galleryItem.messageKey === data.messageKeys[0] && _galleryItem.type === data.mediaTypes[0]);

			user.gallery[galleryIndex].original = data.mediaIds[0];
			user.conversations[conversationIndex].messages[messageIndex].mediaUrl = data.mediaIds;
			await apiService.updateUser(user, false);
			await apiService.updateConversation(user, conversationIndex);
		}*/

		xmpp.setMediaProcessing(false);
	},

	getFromData: async (data: any) => {
		let saved = await DbController.whereObject('data', data);

		if (saved && saved.length === 1) {
			saved = saved[0];
		} else {
			saved = undefined;
		}

		return saved;
	},

	saveToData: async (data: any) => {
		try {
			await DbController.put('data', data);
		} catch (error) {}
	},

	deleteFromData: async (data: any, message: any = undefined) => {
		await DbController.delete('data', data);

		if (message) {
			await apiService.deleteMessage({ ...message, deleted: 1 }, false);
		}
	},

	//addMessages: async (messages: any) => await DbController.bulkPut('messages', messages),

	// following actions are asynchronous.  Server will send final response via control message
	recallMessage: async (message: any) => await apiService.updateMessage(message, true, 'recallMessage'),

	deleteMessage: async (message: any, updateServer: Boolean) => await apiService.updateMessage(message, updateServer),

	fetchLinkPreview: async (url: any) => await serverApi.fetchLinkPreview(url),

	sendStatus: async (message: any) => await serverApi.sendStatus(message).catch((error: any) => error),

	ackControl: async (data: any) => await serverApi.ackControl(data).catch((error: any) => error),
};
