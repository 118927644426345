import React, { createRef, useCallback, useEffect, useRef, useState } from 'react';
import { IonContent, IonButton, IonIcon, IonLabel, IonGrid, IonRow, IonPage, IonAlert, IonActionSheet } from '@ionic/react';
import { RouteComponentProps } from 'react-router';
import { connect } from 'react-redux';
import './style.scss';
import { searchContacts } from '../../redux/actions/dashboard';
import store from '../../redux/store';
import { initChat, reloadChat, uploadFile } from '../../redux/actions/chat';
import { info, isBlank, isPersonalNotepad } from '../../helpers/common';
import { apiService } from '../../services/apiService';
import ChatInputBox from './components/ChatBottomComponents/ChatInputBox';
import moment from 'moment';
import { ChatContactsPageActionType, ChatMediaType, ChatMessageState, MessageType, ChatType, ProfileFriendActionTypes, PubSubEventType, ContactStatus, ThreadType } from '../../services/enumService';
import { sharedService } from '../../services/sharedService';
import ChatSelectView from './components/ChatBottomComponents/ChatSelectView';
import FavoriteSearchModal from '../../modals/favoriteSearchModal';
import _ from 'lodash';
import { publishData, resetPublishData } from '../../redux/actions/pubsub';
import { locale } from '../../locales/local';
import UnreadMsgAccessBtnsView from './components/ChatBottomComponents/UnreadMsgAccessBtnsView';
import { xmpp } from '../../services/xmpp';
import { CHAT_INIT } from '../../redux/constants/chat';
import MultipleImageEditor, { OpenEditorType } from '../../modals/ImageEditor/MultipleImageEditor';
import ChatSearchBar from '../../modals/ChatSearchBar/ChatSearchBar';
import AddNewThread from '../../modals/AddNewThread';
import { LongpressContainer } from '../../components/LongpressContainer';
import ThreadPopUp from '../../modals/ThreadPopUp';
import { dbService } from '../../services/dbService';
import { ContactItem } from '../../types/contact';
import { ChatStateTypes, ContactStateTypes, GlobalStateTypes, GroupStateTypes, ProfileStateTypes, ReduxStates } from '../../types/redux-states';
import { Media, Message } from '../../types/message';
import { MyProfile } from '../../types/profile';
import { TopNavbar } from '../common/header/topbar';
import { Navigation } from '../../services/navigationService';
import { UploadFileReponse } from '../../types/api-responses';
import { ChatNavParams } from '../../types/navigation';
import MediaPreviewModal from '../../modals/MediaPreviewModal';
import UtilService from '../../services/util';
import { showAlert } from '../../redux/actions/global';
import { GroupItem, MemberItem } from '../../types/group';
import { CreateThreadRequestData, DeleteThreadRequestData, GetThreadRequestData, UpdateThreadRequestData } from '../../types/api-requests';
import { createThread, deleteThread, listThreads, updateThread } from '../../redux/actions/thread';
import { ThreadItem } from '../../types/thread';
import { ACTIVE_THREAD_UPDATED } from '../../redux/constants/thread';
import MoveThreadPopUp from '../../modals/MoveThreadPopUp';
import ChatSelectViewThread from '../../modals/ChatSelectViewThread';
import DeleteContact from '../../modals/DeleteContact';
import { showToast } from '../../redux/actions/global';
import VirtualizedMessageList from './components/VirtualizedMessageList';
import PubSub from 'pubsub-js';

require('default-passive-events');

const selectNotAllicon = './assets/icon/selectNotAllIcon.svg';
const selectAllicon = './assets/icon/selectAll.svg';
const CloseIcon = './assets/icon/closeicon.svg';

const maxMediaSelectLimit: number = 9;

let componentHasMounted: Boolean = false;
let componentIsUpdating: Boolean = false;
let personalNotepad: Boolean = false;
let isAllUnreadMessageUpdatingInProgress: Boolean = false;
let isManualScrolling: boolean = false;
let isNewMessageSent: boolean = false;
let unreadThreadlist: any = [];
let isAtBottom: boolean = false;
let unreadRequestSentIds: any = []; // It is usefull when send unread update request send
let readMessageStore: any = [];
let virtualScrollOffsetHeight: number = 0;
let virtualScrollContentHeight: number = 0;
let virtualScrollOffsetTop: number = 0;
let showQuickScrollComponent: Boolean = false;
let isRendered: Boolean = false;
let bodyHeight: any = document?.querySelector('body')?.offsetHeight;
let conversationHeight: number = bodyHeight - 84 - 58;
let maxConversationItems: number = Math.trunc(conversationHeight / 112);

let uploadController: any;
let isRebuilding: Boolean = false;
let firstItem: any = 0;
let isInitialDataLoadCompleted: Boolean = false;
let count: number = 0;
let DOUBLE_CLICK_THRESHOLD = 250;

let navParams: ChatNavParams = {} as any;

const Chat: React.FC<iProps> = (props: iProps) => {
	const [userDetail, setUserDetail] = useState<ContactItem | undefined>(undefined),
		[userProfession, setUserProfession] = useState<string>(''),
		[selectedUserName, setSelectedUserName] = useState<string>(''),
		[unreadMessagesIds, setUnreadMessagesIds] = useState<any>([]),
		[receiver, setReceiver] = useState<any>(props.chat.receiver),
		[showMediaPreview, setShowMediaPreview] = useState<boolean>(false),
		[showPhotoEditor, setShowPhotoEditor] = useState<boolean>(false),
		[message, setMessage] = useState<string>(''),
		[showToast, setShowToast] = useState<boolean>(false),
		[showChatThread, setShowChatThread] = useState<boolean>(false),
		[showMoveThread, setShowMoveThread] = useState<boolean>(false),
		[showFavoriteSearchModal, setShowFavoriteSearchModal] = useState<boolean>(false),
		[captionMessage, setCaptionMessage] = useState<string>(''),
		[medias, setMedias] = useState<any>(null),
		[selectedMediaMessageKey, setSelectedMediaMessageKey] = useState<any>(),
		[selectingMessages, setSelectingMessages] = useState<boolean>(false),
		[showReload, setShowReload] = useState<boolean>(false),
		[selectedMessages, setSelectedMessages] = useState<any[]>([]),
		[chatBoxHeight, setChatBoxHeight] = useState<number>(0),
		[selectedMessageCount, setSelectedMessageCount] = useState<number>(0),
		[allChatMsgCount, setAllChatMsgCount] = useState<any[]>([]),
		[isNetworkConnectionOnline, setIsNetworkConnectionOnline] = useState<boolean>(window.navigator.onLine),
		[showChatSearchBar, setShowChatSearchBar] = useState<boolean>(false),
		[showActionSheetFor, setShowActionSheetFor] = useState<boolean>(false),
		[showThreadMenu, setShowThreadMenu] = useState<boolean>(false),
		[showAddThreadPopup, setShowAddThreadPopup] = useState<boolean>(false),
		[isMinimize, setIsMinimize] = useState<boolean>(false),
		[isThreadContainer, setIsThreadContainer] = useState<boolean>(false),
		[threadList, setThreadList] = useState<Array<ThreadItem>>([]),
		[selectedThread, setSelectedThread] = useState<ThreadItem>(),
		[threadPopOption, setThreadPopOption] = useState<string>(''),
		[threadDisplayType, setThreadDisplayType] = useState<'grid' | 'list'>('grid'),
		[filePath, setFilePath] = useState<string>(''),
		[showPreview, setShowPreview] = useState<boolean>(false),
		[showDeletePopUp, setShowDeletePopUp] = useState<boolean>(false),
		[chatMediaMessage, setChatMediaMessage] = useState<any>();

	const chatInputBoxRef = useRef<any>();

	useEffect(() => {
		componentHasMounted = true;
		return () => {
			componentHasMounted = false;

			// Stop audio if it is playing when component unmount
			if (sharedService.currentPlayingAudioComponentRef) {
				sharedService.currentPlayingAudioComponentRef();
				sharedService.currentPlayingAudioComponentRef = null;
			}
			if (sharedService.currentPlayingAudioTimeIntervalRef) {
				clearInterval(sharedService.currentPlayingAudioTimeIntervalRef);
				sharedService.currentPlayingAudioTimeIntervalRef = null;
				sharedService.currentPlayingAudioMesageKey = undefined;
				if (sharedService.currentPlayingAudioMesageRef) {
					sharedService.currentPlayingAudioMesageRef.pause();
					sharedService.currentPlayingAudioMesageRef.src = '';
					sharedService.currentPlayingAudioMesageRef = null;
				}
			}
		};
	}, []);

	useEffect(() => {
		componentHasMounted = true;
		personalNotepad = isPersonalNotepad();
		const selectedMsgLengthPubSubToken = PubSub.subscribe(PubSubEventType.SelectedMsgLength, (msg: string, data: any) => {
			if (data.action === PubSubEventType.SelectedMsgLength) {
				let selectedMsgCount = data.selectedMessageLength;
				let allMsg: [] = data.allMsgCount;
				setSelectedMessageCount(selectedMsgCount);
				setAllChatMsgCount([...allMsg]);
			}
		});
		// bePubsubMoveThreadPopup.subscribe((msg: any) => {
		// 	if (msg.action === PubSubEventType.MoveThreadPopup) {
		// 		setShowMoveThread(true);
		// 	}
		// });

		// if (personalNotepad) {
		// 	isMessageFetching = false;
		// 	props
		// 		.getMessages({
		// 			username: loggedInUserProfile.username,
		// 			index: 0,
		// 			isGroupChat: false,
		// 		})
		// 		.then(({ messages, isNoMoreData }) => {
		// 			setFirstItemIndex(firstItemIndex - (messages.length - allChatMsgCount));
		// 			setChatMessagesList(messages);
		// 			isMessageFetching = false;
		// 			noMoreData = isNoMoreData;
		// 			setLoadingMore(false);
		// 		})
		// 		.catch(() => {
		// 			isMessageFetching = false;
		// 			setLoadingMore(false);
		// 		})
		// 		.finally(() => {});
		// }

		Navigation.getNavParamData(props).then(async (res: any) => {
			navParams = res;
			let userdata: any = {};
			let username = '';

			let contactItem: ContactItem | undefined;
			let groupItem: GroupItem | undefined;

			if (navParams && navParams.receiver) {
				contactItem = navParams.receiver as ContactItem;
				groupItem = navParams.receiver as GroupItem;

				// Fetch thread list from dbService
				let threadList = await dbService.getThreads(contactItem.username ? contactItem.username : groupItem.name);
				if (threadList && threadList.length > 1) {
					if (threadList.length > 1) {
						setIsThreadContainer(true);
						setSelectedThread(threadList[0]);
					}
					setThreadList(threadList);
				}

				// Fetch thread list from api
				props
					.listThread({
						userOrMucId: contactItem.username ? contactItem.username : groupItem.name,
						type: contactItem.username ? ThreadType.P2P : ThreadType.GROUP,
					})
					.then((res) => {
						threadList = res;
						setThreadList(threadList);
						if (threadList.length > 1) {
							setIsThreadContainer(true);
							setSelectedThread(threadList[0]);
						}
					});
			} else {
				username = window.location.pathname.split('/').pop() || '';
				contactItem = props.contact.contacts?.find((contact: ContactItem) => contact.username === username);
			}

			if (contactItem) {
				userdata = contactItem;
				username = contactItem.username;

				// If not receiver in redux state then set it from databse for selected username
				if (props.chat && !props.chat.receiver) {
					props.chat.receiver = contactItem;
					props.initChat({
						receiver: { ...contactItem, handlerText: locale.dashboard.start_chat },
						type: ChatType.P2P,
					});
				}

				setUserProfession(contactItem.userInfo?.profession);
				setSelectedUserName(contactItem.username);
				setUserDetail(userdata);
			}

			info('Chat::componentDidMount:start');
			window.addEventListener('online', onOnline, true);
			window.addEventListener('offline', onOffline, true);
			await setPageData();

			if (isRebuilding) {
				isRebuilding = false;
				info('Chat::componentDidMount: isRebuilding reset');
			}

			isRendered = true;

			if (componentHasMounted) {
				isInitialDataLoadCompleted = true;
			}

			info('Chat::componentDidMount:end');
		});

		return () => {
			window.removeEventListener('online', onOnline, true);
			window.removeEventListener('offline', onOffline, true);
			uploadController = null;
			PubSub.unsubscribe(selectedMsgLengthPubSubToken);
		};
	}, []);

	useEffect(() => {
		if (!isPersonalNotepad()) {
			if (!navParams?.receiver && props.contact?.contacts?.length && (!userDetail || Object.keys(userDetail!).length === 0)) {
				let contactItem: ContactItem | undefined,
					userprofession = '',
					userdata: ContactItem;

				if (navParams && navParams.receiver) {
					contactItem = navParams.receiver as ContactItem;
				} else {
					const username = window.location.pathname.split('/').pop() || '';
					contactItem = props.contact.contacts?.find((contact: ContactItem) => contact.username === username);
				}

				if (contactItem) {
					userdata = contactItem;
					userprofession = contactItem.userInfo?.profession;

					if (props.chat && !props.chat.receiver) {
						props.chat.receiver = contactItem;
						props.initChat({
							receiver: { ...contactItem, handlerText: locale.dashboard.start_chat },
							type: ChatType.P2P,
						});
					}

					// if (contactItem && props.chat.history.length <= 20) {
					// 	_onTopReached();
					// }
					setSelectedUserName(contactItem.username);
					setUserProfession(userProfession);
					setUserDetail(userdata);
				}
			}
		}
	}, [userProfession, selectedUserName, navParams, props.contact, userDetail, props.chat]);

	const threadMenuDoubleClick = useCallback(() => {
		if (count === 0) {
			setTimeout(() => {
				if (count === 1) {
					setIsThreadContainer(true);
					setIsMinimize(true);
					setThreadDisplayType('list');
					count = 0;
				}
				if (count > 1) {
					count = 0;
					setIsThreadContainer(true);
					setIsMinimize(false);
					setThreadDisplayType('grid');
				}
			}, DOUBLE_CLICK_THRESHOLD);
		}
		count++;
	}, [count]);

	const membersByUsername: { [username: string]: MemberItem } = {};
	const contactByUsername: { [username: string]: ContactItem } = {};
	if (props.chat.type === ChatType.GROUP) {
		props.contact.contacts?.forEach((_contact) => (contactByUsername[_contact.username] = _contact));
		props.group.activeGroupMembers?.forEach((member) => (membersByUsername[member.username] = member));
	}

	const scrollTo = useCallback(
		(index: number = -1, duration = 0, scrollFrom = '') => {
			if (sharedService.isChatScrolling) {
				return;
			}
			info('Log_Scroll_to_call ', scrollFrom);
			_.defer(async () => {
				//info('isManualScrolling', true);
				isManualScrolling = true;
				// let ionContent: any = document.getElementById('chat-ion-content');
				// if (ionContent) {
				// 	const elem = await ionContent.getScrollElement();
				// 	if (offsetTop) {
				// 		elem?.scrollTo(0, offsetTop);
				// 	} else {
				// 		ionContent?.scrollToBottom(duration);
				// 	}
				// } else {
				// 	info('scrollTo-call-from', 'scrollTo');
				// 	scrollTo(0);
				// }
				const scrollVirtualListTo = (indexNumber: Number) => {
					// virtuosoRef?.current?.scrollToIndex({
					// 	index: indexNumber,
					// 	align: 'top',
					// 	behavior: duration ? 'smooth' : 'auto',
					// });
				};
				if (index >= 0) {
					scrollVirtualListTo(index);
				} else {
					scrollVirtualListTo(props?.chat?.history?.length - 1);
				}
				_.defer(() => {
					isManualScrolling = false;
				}, 1000);
				return '';
			});
		},
		[
			sharedService.isChatScrolling,
			// virtuosoRef,
			props.chat,
		]
	);

	const scrollToIndex = useCallback(
		(index: number = -1, duration = 0, scrollFrom = '') => {
			if (sharedService.isChatScrolling) {
				return;
			}
			_.defer(async () => {
				isManualScrolling = true;

				const scrollVirtualListTo = (indexNumber: Number) => {
					// virtuosoRef?.current?.scrollToIndex({
					// 	index: indexNumber,
					// 	align: 'top',
					// 	behavior: duration ? 'smooth' : 'auto',
					// });
				};
				if (index >= 0) {
					scrollVirtualListTo(index);
				} else {
					scrollVirtualListTo(props.chat?.history?.length - 1);
				}
				_.defer(() => {
					isManualScrolling = false;
				}, 1000);
				return '';
			});
		},
		[
			sharedService.isChatScrolling,
			// virtuosoRef,
			props.chat,
		]
	);

	const previewActionHandler = useCallback(
		(media: Media, message: Message) => {
			if (componentHasMounted) {
				if (message?.messageType === MessageType.MEDIA || (message?.messageType === MessageType.FILE && (message.media?.mediaType === ChatMediaType.VIDEO || message.media?.mediaType === ChatMediaType.IMAGE || message.media?.mediaType === ChatMediaType.AUDIO))) {
					setSelectedMediaMessageKey(message.messageKey);
					setShowMediaPreview(true);
				} else if (message?.messageType === MessageType.FILE) {
					setShowMediaPreview(true);
					setSelectedMediaMessageKey(media.mediaFile);
				}
			}
		},
		[componentHasMounted]
	);

	const sendMedia = useCallback(
		async (mediaFiles: Array<File> = [], mediaResolutions: any = [], isImageVideo = true) => {
			let files = [];
			const _mediaResolutions: any = [];
			const contactItem = props.chat?.receiver as ContactItem;
			const groupItem = props.chat?.receiver as GroupItem;

			let messageType,
				messageState = ChatMessageState.ORIGINAL,
				relatedMessageId = '';

			if (isImageVideo) {
				messageType = MessageType.MEDIA;
				for (let index in mediaFiles) {
					let outputFile: File | null = null;

					const media = mediaFiles[index],
						mediaResolution = parseInt(index) === 0 ? mediaResolutions[index] : 0; // Remove this condition when image editor impletmented or multiple images

					// Remove this condition when image editor impletmented or multiple images
					if (parseInt(index) === 0) {
						_mediaResolutions.push(mediaResolution === 0 ? 'full image' : 'reduced image');
					} else {
						// No need this after image editor implemented for multiple images
						_mediaResolutions.push('full image');
					}

					if (mediaResolution !== 0) {
						outputFile = media;

						// const compressedFile = await compressSelectedFile(media, {
						// 	x: mediaResolution,
						// 	y: mediaResolution,
						// 	fit: 'contain',
						// 	upscale: false,
						// });
						// if (!compressedFile?.hasError) {
						// 	outputFile = compressedFile;
						// }
					} else {
						outputFile = media;
					}

					if (outputFile) {
						files.push(outputFile);
					}
				}
			} else {
				files = mediaFiles;
				messageType = MessageType.FILE;
			}

			if (chatInputBoxRef.current?.isReplyViewOpen) {
				messageState = ChatMessageState.REPLY;
				relatedMessageId = chatInputBoxRef.current.replyForMessageId;
				chatInputBoxRef.current.closeReplyView();
			}

			let receiverId = '',
				conversationType: ChatType = ChatType.P2P;
			if (contactItem?.username) {
				receiverId = contactItem.username;
			} else if (groupItem?.name) {
				receiverId = groupItem.name;
				conversationType = ChatType.GROUP;
			}

			uploadController = xmpp.sendMessage({
				messageBody: files,
				messageState: messageState,
				messageType: messageType,
				relatedMessageId: relatedMessageId,
				conversationType: conversationType,
				receiverId,
			});

			if (componentHasMounted) {
				setShowMediaPreview(false);
				setMessage('');
				setCaptionMessage('');
				setMedias(null);
				setSelectedMediaMessageKey('');
			}
			//scrollTo(-1, 0, 'sendMedia');
		},
		[xmpp, props.chat]
	);
	const _onLocationShare = async (location: any) => {
		if (location && props.chat?.receiver) {
			const contactItem = props.chat?.receiver as ContactItem;
			const groupItem = props.chat?.receiver as GroupItem;
			let replyMessage;
			let messageState = ChatMessageState.ORIGINAL;

			let receiverId = '',
				conversationType: ChatType = ChatType.P2P;
			if (contactItem.username) {
				receiverId = contactItem.username;
			} else if (groupItem.mucName) {
				receiverId = groupItem.name;
				conversationType = ChatType.GROUP;
			}
			await xmpp.sendMessage({
				messageBody: location,
				messageState: messageState,
				messageType: MessageType.LOCATION,
				conversationType,
				receiverId,
				replyMessage,
			});
		}
	};
	const cancelUpload = useCallback(async () => {
		await apiService.cancelUpload();
		if (componentHasMounted) {
			setShowMediaPreview(false);
			setMedias(null);
		}
	}, []);

	const pushToSelectedMessages = useCallback(
		(message: any) => {
			setChatMediaMessage(message);

			const selectedMessagesList: any = selectedMessages || [];
			let selectedIndex = -1;
			selectedMessagesList.some((item: any, key: number) => {
				if (item.messageKey === message.messageKey) {
					selectedIndex = key;
					return true;
				}
			});

			if (selectedIndex >= 0) {
				selectedMessagesList.splice(selectedIndex, 1);
			} else {
				selectedMessagesList.push(message);
			}

			if (componentHasMounted) {
				setSelectedMessages([...selectedMessagesList]);
			}
		},
		[chatMediaMessage, selectedMessages, componentHasMounted]
	);
	const onOptionSelect = useCallback(
		(OptionValue: string) => {
			if (componentHasMounted) {
				if (OptionValue === 'delete') {
					setSelectedMessages([]);
					setShowDeletePopUp(true);
				}
				if (OptionValue === 'threadmenu') {
					setSelectedMessages([]);
					setShowMoveThread(true);
				}
				if (OptionValue === 'notepad') {
					props.showToast({ toastMessage: '<img src="../assets/icon/right-thread.svg" />   Sent to Notepad', toastType: 'secondary' });
					setShowChatThread(false);
					setSelectedMessages([]);
					PubSub.publish(PubSubEventType.IsSelectedMsg, {
						action: PubSubEventType.IsSelectedMsg,
						isSelected: false,
					});
				}
				if (OptionValue === 'send') {
					setShowChatThread(true);
					setSelectingMessages(false);
					setSelectedMessages([]);
				}
				if (OptionValue === 'save') {
					props.showToast({ toastMessage: '<img src="../assets/icon/right-thread.svg" />   Saved', toastType: 'secondary' });
					PubSub.publish(PubSubEventType.IsSelectedMsg, {
						action: PubSubEventType.IsSelectedMsg,
						isSelected: false,
					});
				}
			}
		},
		[componentHasMounted, selectingMessages]
	);

	const onOnline = useCallback(() => {
		if (componentHasMounted) {
			if (!isNetworkConnectionOnline) {
				(async () => await xmpp.xmppManager())();
			}

			if (componentHasMounted) {
				setIsNetworkConnectionOnline(true);
			}
		}
	}, [componentHasMounted, isNetworkConnectionOnline, xmpp]);

	const onOffline = useCallback(() => {
		if (!componentHasMounted) {
			if (componentHasMounted) {
				setIsNetworkConnectionOnline(false);
			}
			xmpp.reset();
		}
	}, [componentHasMounted, xmpp]);

	const setPageData = useCallback(async () => {
		// if (!xmpp.isReady) {
		// 	await xmpp.xmppManager();
		// }

		return;

		let user: any = props.profile.loggedInUserProfile,
			userUpdated: Boolean = false;

		if (isBlank(user)) {
			user = await dbService.me();
			user.updated = true;
		}

		let receiver: any,
			receiverUpdated: Boolean = false,
			history: any,
			historyUpdated: Boolean = false,
			conversation: any;

		if (isBlank(props.chat.history) && !isRebuilding) {
			isRebuilding = true;
			info('Chat::setPageData: isRebuilding set');

			let theDate = new Date();
			theDate.setDate(theDate.getDate() - 7);

			let conversationIndex: number = user.conversations.findIndex((_conversation: any) => _conversation.conversationHash === receiver?.conversationHash);
			conversation = user.conversations[conversationIndex];

			if (conversation.messages.length <= 1 || conversation.lastMessage.timestamp < theDate) {
				user = await apiService.refreshMessages([conversation], false);
			}

			history = user?.conversations[conversationIndex]?.messages;
			historyUpdated = true;
		} else if (!isRebuilding) {
			history = props.chat.history;
		}

		if (isBlank(receiver)) {
			Navigation.back();
		} else if (userUpdated || receiverUpdated || historyUpdated) {
			store.dispatch({
				type: CHAT_INIT,
				payload: {
					loggedInUser: user,
					conversation: conversation,
					history: history,
					receiver: receiver,
				},
			});
		} else if (!isRebuilding) {
			xmpp.setMessagesLoaded(true);
		}
	}, [props.profile, props.chat, isRebuilding]);

	const openEditThread = useCallback((item: any, itemType: any) => {
		Navigation.openEditThreads({
			data: { item, itemType },
		});
	}, []);

	const _onMediaUploadHandler = async (medias: any) => {
		if (componentHasMounted) {
			const finalMedias: Array<File> = [];
			let isLargeFilesRemoved = 0;

			if (Array.isArray(medias)) {
				medias.forEach((file: File) => {
					if (UtilService.bytesToMb(file.size) <= sharedService.maxMediaFileSizeMb) {
						finalMedias.push(file);
					} else {
						isLargeFilesRemoved++;
					}
				});
			} else {
				if (UtilService.bytesToMb(medias.size) <= sharedService.maxMediaFileSizeMb) {
					finalMedias.push(medias);
				} else {
					isLargeFilesRemoved++;
				}
			}

			if (finalMedias.length > maxMediaSelectLimit) {
				props.showAlert({ alertMessage: `Select a Maximum of ${maxMediaSelectLimit} photos` });
			} else {
				if (finalMedias.length > 0) {
					setShowPhotoEditor(true);
					setMedias(finalMedias);
				}
			}

			if (isLargeFilesRemoved > 0) {
				props.showAlert({ alertMessage: `Files large then ${sharedService.maxMediaFileSizeMb} Mb cannot be send` });
			}
		}
	};

	const _onFileUploadHandler = async (medias: any) => {
		if (componentHasMounted) {
			const finalMedias: Array<File> = [];
			let isLargeFilesRemoved = 0;

			if (Array.isArray(medias)) {
				medias.forEach((file: File) => {
					if (UtilService.bytesToMb(file.size) <= sharedService.maxDocFileSizeMb) {
						finalMedias.push(file);
					} else {
						isLargeFilesRemoved++;
					}
				});

				if (isLargeFilesRemoved > 0) {
					props.showAlert({ alertMessage: `Files large then ${sharedService.maxDocFileSizeMb} Mb cannot be send` });
				}

				if (finalMedias.length > 0) {
					sendMedia(finalMedias, [], false);
				}
			}
		}
	};

	function findMessageIndex(messages: Array<{ id: string }>, id: string) {
		return messages.findIndex((message) => message.id === id);
	}

	function calculateInitialTopMostItemIndex(messages: Array<Message>, highlightedMessageId: string | undefined) {
		if (highlightedMessageId) {
			const index = findMessageIndex(messages, highlightedMessageId);
			if (index !== -1) {
				return { align: 'center', index } as const;
			}
		}
		return messages.length - 1;
	}

	function fractionalItemSize(element: HTMLElement) {
		return element.getBoundingClientRect().height;
	}

	const _handleMenuOptionPress = (type: string) => {
		switch (type) {
			case 'threads':
				if (threadList.length === 0) {
					setShowAddThreadPopup(true);
				} else {
					setShowThreadMenu(true);
				}

				break;
			case 'groupInfo':
				Navigation.openGroupInfo({
					groupDetail: props.chat.receiver as GroupItem,
				});
				break;
		}
	};

	const threadChangeHandle = (item: ThreadItem, showThreadMenu = false) => {
		setSelectedThread(item);
		setShowThreadMenu(showThreadMenu);
		store.dispatch({ type: ACTIVE_THREAD_UPDATED, payload: { activeThreadInChat: item.threadId === sharedService.defaultThreadId ? undefined : item } });
		// _fetchMessages(0, item);
		PubSub.publish(PubSubEventType.ThreadChangeInChat, {
			action: PubSubEventType.ThreadChangeInChat,
			thread: item,
		});
	};

	const setAllMessagesRead = async () => {
		if (!isAllUnreadMessageUpdatingInProgress && !personalNotepad && unreadMessagesIds && unreadMessagesIds.length > 0) {
			let isMsgNotSentAlreadyForReadStatus = false;
			const unreadMsgIds: any = [];
			unreadMessagesIds.forEach((item: any) => {
				if (!_.includes(unreadRequestSentIds, item)) {
					isMsgNotSentAlreadyForReadStatus = true;
					unreadMsgIds.push(item);
				}
			});

			if (isMsgNotSentAlreadyForReadStatus) {
				unreadRequestSentIds = unreadRequestSentIds.concat(unreadMsgIds);
				isAllUnreadMessageUpdatingInProgress = true;
				//await apiService.updateReadStatus({ conversation: ContactItem });
				unreadMessagesIds.splice(0, unreadMessagesIds.length);

				if (componentHasMounted) {
					setUnreadMessagesIds([]);
				}
				isAllUnreadMessageUpdatingInProgress = false;
			}
		}
	};

	const setReadMessagesByIds = async (unreadMessageIds: any) => {
		if (!isAllUnreadMessageUpdatingInProgress && unreadMessageIds?.length > 0) {
			const messageKeys: any = [];

			unreadMessageIds.forEach((messageKey: any) => {
				if (!_.includes(unreadRequestSentIds, messageKey)) {
					messageKeys.push(messageKey);
				}
			});

			unreadRequestSentIds = unreadRequestSentIds.concat(messageKeys);

			/*if (messageKeys && messageKeys.length > 0) {
				await apiService.updateReadStatus({ messageKeys: messageKeys });
			}*/
		}
	};

	const readNextUnreadMessage = async (nextIndex: number) => {
		await setReadMessagesByIds([nextIndex]);
	};

	const readNextUnreadMessage2 = async (nextIndex: number) => {
		// if (!contactItem?.notepadJid) {
		// 	const unreadCount = contactItem?.unreadCount ? contactItem?.unreadCount : unreadMessagesIds ? unreadMessagesIds.length : 0;
		// 	if (unreadCount > 0) {
		// 		const firstUnreadMsgId: string = nextIndex < unreadCount && nextIndex >= 0 ? unreadMessagesIds[nextIndex] : unreadMessagesIds[0];
		// 		const element: any = document.getElementById(firstUnreadMsgId);
		// 		if (unreadMessagesIds?.length === 1) {
		// 			//info('scrollTo-call-from', 'readNextUnreadMessage 1');
		// 			scrollTo(-1, 0, 'readNextUnreadMessage->unreadMessagesIds.length is 1');
		// 			await this.setReadMessagesByIds([firstUnreadMsgId]);
		// 		} else {
		// 			const chatInputBox: any = document.getElementById('chatInputBox');
		// 			if (chatInputBox) {
		// 				const minY = element ? element.offsetTop - (virtualScrollOffsetHeight || 0) / 2 : 0,
		// 					maxY = minY + virtualScrollOffsetHeight - chatInputBox.offsetHeight,
		// 					readThisMsgIds: any = [firstUnreadMsgId];
		// 				unreadMessagesIds.map((unreadMessagesId: any) => {
		// 					const msgElement = document.getElementById(unreadMessagesId);
		// 					if (msgElement) {
		// 						const offsetTop = msgElement.offsetTop,
		// 							offsetBottom = offsetTop + msgElement.offsetHeight;
		// 						if (offsetBottom <= maxY) {
		// 							if (!_.includes(readThisMsgIds, unreadMessagesId)) {
		// 								readThisMsgIds.push(unreadMessagesId);
		// 							}
		// 						}
		// 					}
		// 				});
		// 				//info('scrollTo-call-from', 'readNextUnreadMessage 2');
		// 				// scrollTo(element.offsetTop - (virtualScrollOffsetHeight || 0) / 2);
		// 				const scrollToIndex = this.props?.chat?.history
		// 					.map(function (o: any) {
		// 						return o.messageKey;
		// 					})
		// 					.indexOf(firstUnreadMsgId);
		// 				scrollTo(scrollToIndex - 1, 0, 'readNextUnreadMessage->scrollToIndex');
		// 				if (readThisMsgIds.length > 0) {
		// 					await this.setReadMessagesByIds(readThisMsgIds);
		// 				}
		// 			}
		// 		}
		// 	}
		// }
	};
	const readNextUnreadTaggedMessage = async (nextIndex: number) => {
		// if (!personalNotepad) {
		// 	const taggedMessages = contactItem?.taggedMessages;
		// 	if (taggedMessages && taggedMessages.length > 0) {
		// 		const firstUnreadTaggedMsg: any = nextIndex < taggedMessages.length && nextIndex >= 0 ? taggedMessages[nextIndex] : taggedMessages[0],
		// 			firstUnreadMsgId: string = firstUnreadTaggedMsg.messageKey,
		// 			element: any = document.getElementById(firstUnreadMsgId),
		// 			chatInputBox: any = document.getElementById('chatInputBox');
		// 		if (element && chatInputBox) {
		// 			const minY = element.offsetTop - (virtualScrollOffsetHeight || 0) / 2,
		// 				maxY = minY + virtualScrollOffsetHeight - chatInputBox.offsetHeight,
		// 				readThisMsgIds: any = [];
		// 			unreadMessagesIds.map((unreadMessagesId: any) => {
		// 				const msgElement = document.getElementById(unreadMessagesId);
		// 				if (msgElement) {
		// 					const offsetTop = msgElement.offsetTop,
		// 						offsetBottom = offsetTop + msgElement.offsetHeight;
		// 					if (offsetBottom <= maxY) {
		// 						readThisMsgIds.push(unreadMessagesId);
		// 					}
		// 				}
		// 			});
		// 			taggedMessages.map((msg: any) => {
		// 				const unreadMessagesId = msg.messageKey,
		// 					msgElement = document.getElementById(unreadMessagesId);
		// 				if (msgElement) {
		// 					const offsetTop = msgElement.offsetTop,
		// 						offsetBottom = offsetTop + msgElement.offsetHeight;
		// 					if (offsetBottom <= maxY) {
		// 						readThisMsgIds.push(unreadMessagesId);
		// 					}
		// 				}
		// 			});
		// 			//info('scrollTo-call-from', 'readNextUnreadTaggedMessage');
		// 			const scrollToIndex = this.props?.chat?.history
		// 				.map(function (o: any) {
		// 					return o.messageKey;
		// 				})
		// 				.indexOf(firstUnreadMsgId);
		// 			scrollTo(scrollToIndex - 1, 0, 'readNextUnreadTaggedMessage');
		// 			if (readThisMsgIds.length > 0) {
		// 				await this.setReadMessagesByIds(readThisMsgIds);
		// 			}
		// 		}
		// 	}
		// }
	};
	const _openFriendProfile = (profileType: string, userId?: any) => {
		const contactItem = props.chat?.receiver as ContactItem;
		const groupItem = props.chat?.receiver as GroupItem;

		if (profileType === 'chat') {
			Navigation.profileFriend({
				data: contactItem?.userInfo,
				friendProfileActionType: ProfileFriendActionTypes.ChatProfileView,
			});
		} else if (profileType === 'me') {
			Navigation.myProfile({ shouldBack: false });
		} else if (profileType === 'groupchat') {
			Navigation.openGroupInfo({
				groupDetail: props.chat.receiver as GroupItem,
			});
			// let userProfile: any;
			// props.chat.conversations.some((user: any) => {
			// 	if (user?.userId?.toLowerCase() === userId?.toLowerCase()) {
			// 		userProfile = user;
			// 		return true;
			// 	}
			// });
			// Navigation.profileFriend({
			// 	data: userProfile,
			// 	friendProfileActionType: ProfileFriendActionTypes.ChatProfileView,
			// });
		}
	};

	const startSelectingMessages = () => {
		if (componentHasMounted) {
			setSelectingMessages(true);
		}
	};

	const optionClickHandler = (optionValue: string) => {
		// if (optionValue === 'check') {
		// 	pushToSelectedMessages(message);
		// }
		if (optionValue === 'threadMenu') {
			setShowMoveThread(true);
		}
	};
	const _selectAllMessages = () => {
		if (componentHasMounted && selectedMessages?.length !== props.chat.history.length) {
			setSelectedMessages(JSON.parse(JSON.stringify(props.chat.history)));
		}
	};

	const _startForwardingMessages = (message: any) => {
		Navigation.openChatContacts({ actionType: ChatContactsPageActionType.ForwardMessage, chatDetail: message });
	};

	const checkBtnVisibility = (scrollTop: number, scrollHeight: number, offsetHeight: number) => {
		// const leaveSpaceFromBottomForVisible = (offsetHeight * 380) / 100;
		const leaveSpaceFromBottomForVisible = (offsetHeight * 100) / 100;
		const isChatContentScrollable = scrollHeight - 20 > offsetHeight;
		if (isChatContentScrollable && scrollTop + offsetHeight <= scrollHeight - leaveSpaceFromBottomForVisible) {
			if (!sharedService.isQuickChatAccessBtnVisible) {
				props.publishData({
					type: PubSubEventType.QuickAccessBtnVisibility,
					data: { show: true },
				});
			}
		} else if (sharedService.isQuickChatAccessBtnVisible) {
			props.publishData({
				type: PubSubEventType.QuickAccessBtnVisibility,
				data: { show: false },
			});
		}
	};

	const handleFollowOutput = (isAtBottom: Boolean) => {
		if (isAtBottom) {
			return 'smooth';
		} else {
			return false;
		}
	};

	const onPinturaEditingDone = (files: Array<File>, fullSizes: any) => {
		// encode the file using the FileReader API

		let resolutions: any = fullSizes.map((isFullSize: boolean) => (isFullSize ? 0 : 1024));

		setShowPhotoEditor(false);
		sendMedia(files, resolutions);
	};

	const handleItemsRendered = (e: any) => {
		//info(`Chat::virtuoso-ItemsRendered: firstItem is ${firstItem}, itemsRendered`, e);

		if (e.slice(-1)[0]?.index > firstItem) {
			firstItem = e.slice(-1)[0].index;
			// virtuosoRef?.current?.scrollToIndex({
			// 	index: firstItem,
			// 	align: 'end',
			// 	behavior: 'auto',
			// 	offset: e.slice(-1)[0].offset,
			// });
			//info(`Chat::handleItemsRendered: firstItem updated to ${firstItem}`);
		}
	};

	const loggedInUserProfile = props.profile.loggedInUserProfile || ({} as MyProfile);
	const unreadMessages: any = []; /*props.chat.receiver.unreadMessages;*/

	let pageTitle = '',
		pageSubTitle = '';

	const contactItem = props.chat?.receiver as ContactItem;
	const groupItem = props.chat?.receiver as GroupItem;
	if (!isPersonalNotepad()) {
		if (contactItem?.username) {
			pageTitle = contactItem.alias ?? contactItem.userInfo?.profileName ?? selectedUserName ?? '';
			pageSubTitle = userProfession ?? contactItem?.userInfo?.profession ?? '';

			if (!selectedThread || selectedThread?.threadName === sharedService.defaultThreadId) {
				// Add greeting message
				const requestAccepted = {} as Message;
				requestAccepted.body = `You have added (${receiver?.alias || contactItem.userInfo?.profileName}) to your Contacts<br />Start chatting!`;
				requestAccepted.from = loggedInUserProfile.username;
				requestAccepted.to = contactItem.username;
				requestAccepted.datetime = moment(contactItem.created).toISOString();
				requestAccepted.type = ChatType.P2P;
				requestAccepted.messageType = MessageType.REQUEST_ACCEPTED;

				// messagesList = [requestAccepted, ...messagesList];
				if (contactItem.message) {
					const greetingMessage = {} as Message;
					greetingMessage.body = contactItem.message;
					greetingMessage.from = contactItem.username;
					greetingMessage.to = loggedInUserProfile.username;
					greetingMessage.datetime = moment(contactItem.created).toISOString();
					greetingMessage.type = ChatType.P2P;
					greetingMessage.messageType = MessageType.TEXT;

					// messagesList = [greetingMessage, ...messagesList];
				}
			}
		} else if (groupItem?.mucName) {
			pageTitle = groupItem.mucName ?? '';
			pageSubTitle = `${groupItem.memberAmount ?? 0} members`;
		}
	} else {
		pageTitle = loggedInUserProfile.profileName || loggedInUserProfile.username || '';
		pageSubTitle = loggedInUserProfile.profession || '';
	}
	return (
		<>
			<IonPage className="chat-page">
				<div className="chat-top-header">
					<TopNavbar
						{...props}
						containerClassName={selectingMessages ? 'selection-mode-topbar' : ''}
						showBack={selectingMessages ? false : true}
						isHideRightButton={false}
						pageTitle={pageTitle}
						onTitlePress={() => {
							if (personalNotepad) {
								_openFriendProfile('me');
							} else {
								_openFriendProfile(props.chat?.type);
							}
						}}
						pageTitleDescription={pageSubTitle}
						rightButtonIcon={personalNotepad ? '' : selectingMessages && allChatMsgCount.length === selectedMessageCount && selectedMessageCount > 1 ? selectAllicon : selectingMessages ? selectNotAllicon : ''}
						leftButtonIcon={selectingMessages ? CloseIcon : ''}
						hideSearchBar={true}
						showSearchIcon={selectingMessages ? false : !showChatSearchBar}
						onSearchIconPress={() => setShowChatSearchBar(true)}
						friendProfile={userDetail?.userInfo}
						onLeftButtonPress={() => {
							PubSub.publish(PubSubEventType.IsSelectedMsg, {
								action: PubSubEventType.IsSelectedMsg,
								isSelected: false,
							});
							componentHasMounted = false;
							Navigation.dashboardTab();
						}}
						onDropDownOptionPress={_handleMenuOptionPress}
						onRightButtonPress={
							selectingMessages
								? () => {
										if (selectingMessages && allChatMsgCount.length !== selectedMessageCount) {
											PubSub.publish(PubSubEventType.TotalChatMessages, {
												action: PubSubEventType.TotalChatMessages,
												totalMessageList: allChatMsgCount,
											});
										} else if (selectingMessages && allChatMsgCount.length === selectedMessageCount) {
											PubSub.publish(PubSubEventType.TotalChatMessages, {
												action: PubSubEventType.TotalChatMessages,
												totalMessageList: [],
											});
										}
								  }
								: undefined
						}
					/>
				</div>

				<IonContent id="chat-ion-content" scrollEvents={true}>
					<>
						<div className={threadDisplayType === 'grid' ? 'chat-thread-container' : 'chat-thread-container-list'}>
							{isMinimize && (
								<>
									<LongpressContainer
										className={'thread-longpress'}
										onLongPress={() => {
											setShowActionSheetFor(true);
										}}
									>
										<div className="thread-btn-container">
											<IonButton className={'active'}>{selectedThread?.threadName}</IonButton>
										</div>
									</LongpressContainer>
									{threadDisplayType === 'list' && <div style={{ flex: 1, minHeight: '48px' }} />}
								</>
							)}
							{((!isMinimize && isThreadContainer) || (isMinimize && threadDisplayType === 'list')) &&
								threadList.length > 0 &&
								threadList.map((item: ThreadItem, key: any) => {
									if (!isThreadContainer && key > 0) {
										return null;
									}
									if (isMinimize && selectedThread?.threadId === item.threadId) {
										return null;
									}
									let count: any = 0;
									var countThreeDotSet: boolean = false;
									let countThreeDot: any = '...';

									return (
										<LongpressContainer
											key={key}
											className={threadDisplayType === 'grid' ? 'thread-button' : 'thread-longpress'}
											onLongPress={() => {
												setShowActionSheetFor(true);

												if (selectedThread?.threadId !== item.threadId) {
													threadChangeHandle(item, false);
												}
											}}
										>
											<div className="thread-btn-container">
												<IonButton
													key={key}
													onClick={() => {
														if (isMinimize && threadDisplayType === 'list') {
															setThreadDisplayType('grid');
														}
														if (selectedThread?.threadId !== item.threadId) {
															threadChangeHandle(item, false);
														}
													}}
													className={selectedThread?.threadId === item.threadId ? 'active' : ''}
												>
													{item.threadName}
												</IonButton>
												{countThreeDotSet && <div className={threadDisplayType === 'grid' ? 'notification-grid-3Dot' : 'notification-list-3Dot'}>{countThreeDot}</div>}

												{count > 0 && <div className={threadDisplayType === 'grid' ? 'notification-grid' : 'notification-list'}>{count}</div>}
											</div>
										</LongpressContainer>
									);
								})}

							{isMinimize && threadDisplayType === 'list' && <div style={{ flex: 1 }} />}
						</div>
					</>

					{isMinimize && <div className="notification">75</div>}
					{isMinimize && (
						<div className="thread-menu">
							<IonIcon onClick={threadMenuDoubleClick} className="ct-icon" slot="icon-only" src="./assetS/icon/thread-menu.svg" />
						</div>
					)}

					{selectedUserName || contactItem?.username || groupItem?.name ? (
						<>
							<VirtualizedMessageList
								onSelectMessage={() => {
									setSelectingMessages(true);
								}}
								onDselect={() => {
									setSelectingMessages(false);
								}}
								selectedThread={selectedThread}
								contactItem={contactItem}
								groupItem={groupItem}
								loggedInUserProfile={loggedInUserProfile}
							/>

							{showQuickScrollComponent && isInitialDataLoadCompleted && !personalNotepad && unreadMessages.length > 0 /*unreadThreadlist.length > 0*/ && (
								<UnreadMsgAccessBtnsView
									props={props}
									isNewMessageSent={isNewMessageSent}
									chatBoxHeight={chatBoxHeight}
									unreadMessagesIds={unreadMessages} /*{unreadThreadlist}*/
									readNextUnreadMessage={async () => {
										await readNextUnreadMessage(0);
										unreadMessages.splice(0, unreadMessages.length - 1);
										//unreadThreadlist.splice(0, unreadThreadlist.length);
									}}
									readNextUnreadTaggedMessage={async () => {
										if (componentHasMounted && !isManualScrolling) {
											await readNextUnreadTaggedMessage(0);
										}
									}}
									// readUnreadMessage={async (messageId: any) => {
									// 	if (componentHasMounted && !isManualScrolling) {
									// 		await this.setReadMessagesByIds([messageId]);
									// 	}
									// }}
									readAllUnreadMessages={async () => {
										await setAllMessagesRead();
										unreadMessages.splice(0, unreadMessages.length - 1);
										//unreadThreadlist.splice(0, unreadThreadlist.length);

										// if (componentHasMounted) {
										// 	scrollTo(-1, 300, 'readAllUnreadMessages');
										// 	await this.setAllMessagesRead();
										//     //info('scrollTo-call-from', 'readAllUnreadMessages');
										// }
									}}
								/>
							)}
						</>
					) : !props.chat?.showLoading ? (
						<>
							<IonGrid className="chat-db-grid">
								<IonRow className="chat-db-row">
									<IonLabel color="light" style={{ textAlign: 'center' }}>
										<h1>{personalNotepad ? locale.chat.personal_chatpad : !componentHasMounted ? locale.reducers.chat.no_message : 'No message. Start chatting!'}</h1>
										{showReload ? (
											<IonButton color="#000000" onClick={() => {}}>
												{locale.global.reload}{' '}
											</IonButton>
										) : null}
									</IonLabel>
								</IonRow>
							</IonGrid>
						</>
					) : null}
				</IonContent>

				{userDetail?.status === ContactStatus.DELETE && (
					<div className="send-friend-request">
						{contactItem?.alias || contactItem?.userInfo?.profileName || 'Username'} has enabled Friend Confirmation.
						<span className="send-friend"> Send a friend request</span> to chat.
					</div>
				)}

				{selectingMessages ? (
					<ChatSelectView
						threadCount={threadList.length}
						onOption={async (OptionValue: string) => {
							onOptionSelect(OptionValue);
							setSelectingMessages(false);
						}}
					/>
				) : (
					<ChatInputBox
						activeThread={selectedThread}
						myRef={(ref: any) => (chatInputBoxRef.current = ref.current)}
						props={props}
						sendMedia={(img: any) => {
							sendMedia([img], [0]);
						}}
						allContacts={props.contact.contacts}
						loggedInUser={props.profile.loggedInUserProfile!}
						onMediaUpload={_onMediaUploadHandler}
						onLocationShare={_onLocationShare}
						onFileUpload={_onFileUploadHandler}
						onPasteImageFromClipboard={async (file: File) => {
							if (componentHasMounted) {
								setShowPhotoEditor(true);
								setMedias([file]);
							}
						}}
						onProfilePhotoPress={(userId: any) => {
							_openFriendProfile('group', userId);
						}}
						onChatBoxHeightChange={(chatBoxHeight: number) => {
							const virtualsoFooter = document.getElementById('virtualsoFooter');
							virtualsoFooter?.style.setProperty('height', chatBoxHeight + 8 + 'px');
							if (componentHasMounted) {
								setChatBoxHeight(chatBoxHeight);
							}
						}}
						onMessageSent={() => {
							isNewMessageSent = true;
						}}
					/>
				)}
			</IonPage>

			<FavoriteSearchModal
				show={showFavoriteSearchModal}
				onClose={() => {
					if (componentHasMounted) {
						setShowFavoriteSearchModal(false);
					}
				}}
			/>

			<MediaPreviewModal
				onViewAllMediaClick={() => {
					if (componentHasMounted) {
						setShowMediaPreview(false);
						selectedMediaMessageKey('');
						Navigation.openChatMedias();
					}
				}}
				selectedMediaMessageKey={selectedMediaMessageKey}
				show={showMediaPreview}
				onClose={() => {
					if (componentHasMounted) {
						setShowMediaPreview(false);
						setSelectedMediaMessageKey('');
					}
				}}
			/>

			{showPhotoEditor && medias && (
				<MultipleImageEditor
					openEditorType={OpenEditorType.FOR_SEND_IMAGE}
					medias={medias}
					show={showPhotoEditor}
					onClose={() => {
						if (componentHasMounted) {
							setShowPhotoEditor(false);
							setMedias(null);
						}
					}}
					onSave={onPinturaEditingDone}
				/>
			)}

			<AddNewThread
				show={showAddThreadPopup}
				onCloseCallBack={() => {
					setShowAddThreadPopup(false);
					setThreadPopOption('');
				}}
				threadName={threadPopOption === 'rename' ? selectedThread?.threadName && selectedThread?.threadName : ''}
				onCreatThread={async (threadAddName) => {
					const foundSameThread = threadList.findIndex((thread) => thread.threadName === threadAddName);
					if (foundSameThread >= 0) {
						props.showAlert({ alertMessage: `Thread name is already exists` });
					} else {
						if (threadList.length >= 9 && threadPopOption === 'newThread') {
							props.showAlert({ alertMessage: `You Have Already Created Max 9 Thread` });
						}
						if (threadPopOption === 'rename') {
							const threads: any = await props.updateThread({
								userOrMucId: contactItem.username ? contactItem.username : groupItem.name,
								type: contactItem.username ? ThreadType.P2P : ThreadType.GROUP,
								threadName: threadAddName,
								threadId: selectedThread?.threadId!,
							});
							setThreadList(threads);
							setShowAddThreadPopup(false);
						} else {
							const threadList: Array<ThreadItem> = await props.createThread({
								userOrMucId: contactItem.username ? contactItem.username : groupItem.name,
								type: contactItem.username ? ThreadType.P2P : ThreadType.GROUP,
								threadName: threadAddName,
							});
							setThreadList(threadList);

							let selectedThread = threadList.find((thread) => thread.threadName === threadAddName);
							setShowAddThreadPopup(false);
							if (selectedThread) {
								threadChangeHandle(selectedThread, false);
							} else {
								setShowThreadMenu(false);
							}
						}
					}
				}}
			/>

			<ThreadPopUp
				onOptionSelect={async (OptionValue: string) => {
					if (OptionValue) {
						setThreadPopOption(OptionValue);
					}

					if (OptionValue === 'newThread' || OptionValue === 'rename') {
						setShowAddThreadPopup(true);
					}
					if (OptionValue === 'delete') {
						const deleteThread: any = await props.deleteThread({
							mucId: selectedThread?.threadId!,
						});
						var finalListArray: any = [];
						deleteThread.map((list: any) => {
							finalListArray.push(list);
							setThreadList(finalListArray);
						});
					}
					if (OptionValue === 'manage') {
						openEditThread(threadList, navParams.receiver);
					}
					setShowActionSheetFor(false);
				}}
				onThreadContainer={() => {
					setIsThreadContainer(false);
				}}
				onMinimize={() => {
					setIsMinimize(true);
				}}
				threadName={selectedThread?.threadName ? selectedThread.threadName : ''}
				show={showActionSheetFor}
				onCloseCallBack={() => {
					setShowActionSheetFor(false);
				}}
			/>

			{showChatSearchBar && (
				<ChatSearchBar
					location={props.location}
					history={props.history}
					type={personalNotepad ? 'notepad' : props.chat?.type === ChatType.P2P ? 'p2p' : 'group'}
					show={showChatSearchBar}
					onCancel={() => {
						setShowChatSearchBar(false);
					}}
				/>
			)}

			{showThreadMenu && (
				<IonActionSheet
					mode="md"
					isOpen={showThreadMenu}
					onDidDismiss={() => {
						setShowThreadMenu(false);
					}}
					cssClass={'thread-menu-actionsheet'}
					header="Threads"
					buttons={[
						{
							text: 'Creat New',
							cssClass: 'creat-new-thread',
							handler: () => {
								setShowAddThreadPopup(true);
								setShowThreadMenu(false);
							},
						},
						{
							text: 'Manage/Edit',
							cssClass: 'edit-menu-button',
							handler: () => {
								openEditThread(threadList, navParams.receiver);
								setShowThreadMenu(false);
							},
						},
						{
							text: 'Minimize All',
							cssClass: 'edit-menu-button',
							handler: () => {
								setIsThreadContainer(false);
								setIsMinimize(true);
								setShowThreadMenu(false);
							},
						},
						{
							text: 'Cancel',
							cssClass: 'action-sheet-group-cancel',
							handler: () => {
								setShowThreadMenu(false);
							},
						},
					]}
				/>
			)}

			<DeleteContact
				type=""
				userData={'deleteMessage'}
				show={showDeletePopUp}
				onDeleteCallBack={() => {
					props.showToast({ toastMessage: '<img src="../assets/icon/right-thread.svg" />   Deleted', toastType: 'secondary' });
					// setShowToast(true);
					PubSub.publish(PubSubEventType.IsSelectedMsg, {
						action: PubSubEventType.IsSelectedMsg,
						isSelected: false,
					});
					setShowDeletePopUp(false);
				}}
				onCloseCallBack={() => {
					setShowDeletePopUp(false);
					PubSub.publish(PubSubEventType.IsSelectedMsg, {
						action: PubSubEventType.IsSelectedMsg,
						isSelected: false,
					});
				}}
			/>

			{showChatThread && (
				<ChatSelectViewThread
					// message={chatMediaMessage}
					// count={selectedMessages?.length}
					show={showChatThread}
					onCloseCallBack={() => {
						PubSub.publish(PubSubEventType.OnCancel, {
							action: PubSubEventType.OnCancel,
						});

						setShowChatThread(false);
						setShowToast(true);
					}}
					onOption={(OptionValue: string) => {
						onOptionSelect(OptionValue);
					}}
				/>
			)}
			{showMoveThread && (
				<MoveThreadPopUp
					onMoveCallBack={() => {
						props.showToast({ toastMessage: '<img src="../assets/icon/right-thread.svg" />   Moved to “UI/UX”', toastType: 'secondary' });
						setShowMoveThread(false);
						PubSub.publish(PubSubEventType.IsSelectedMsg, {
							action: PubSubEventType.IsSelectedMsg,
							isSelected: false,
						});
					}}
					show={showMoveThread}
					onCloseCallBack={() => {
						setShowMoveThread(false);
						PubSub.publish(PubSubEventType.IsSelectedMsg, {
							action: PubSubEventType.IsSelectedMsg,
							isSelected: false,
						});
					}}
				/>
			)}
		</>
	);
};

interface iProps extends RouteComponentProps<{ name: string }> {
	resetPublishData: any;
	publishData: any;
	reloadChat: any;
	uploadFile: (file: File) => Promise<UploadFileReponse>;
	showAlert: (payload: GlobalStateTypes) => void;
	createThread: (payload: CreateThreadRequestData) => Promise<Array<ThreadItem>>;
	listThread: (payload: GetThreadRequestData) => Promise<Array<ThreadItem>>;
	updateThread: (payload: UpdateThreadRequestData) => Promise<Array<ThreadItem>>;
	deleteThread: (payload: DeleteThreadRequestData) => Promise<Array<ThreadItem>>;
	showToast: (payload: GlobalStateTypes) => void;
	hideToast?: () => void;

	initChat: (payload: any) => void;
	receiver: any;
	type: String;
	history: any;
	loggedInUser: any;
	emptyMessage: string;
	errorMessage: string;
	location: any;
	chat: ChatStateTypes;
	profile: ProfileStateTypes;
	contact: ContactStateTypes;
	group: GroupStateTypes;
}

const mapStateToProps = (state: ReduxStates) => {
	return {
		profile: state.profile,
		chat: state.chat,
		contact: state.contact,
		group: state.group,
	};
};

const mapDispatchToProps = (dispatch: any) => ({
	searchContacts: (payload: String) => dispatch(searchContacts(payload)),
	publishData: (payload: String) => dispatch(publishData(payload)),
	reloadChat: (payload: any) => dispatch(reloadChat(payload)),
	resetPublishData: () => dispatch(resetPublishData()),
	uploadFile: (file: File) => dispatch(uploadFile(file)),
	initChat: (payload: any) => dispatch(initChat(payload)),
	showAlert: (payload: GlobalStateTypes) => dispatch(showAlert(payload)),
	createThread: (payload: CreateThreadRequestData) => dispatch(createThread(payload)),
	listThread: (payload: GetThreadRequestData) => dispatch(listThreads(payload)),
	updateThread: (payload: UpdateThreadRequestData) => dispatch(updateThread(payload)),
	deleteThread: (payload: DeleteThreadRequestData) => dispatch(deleteThread(payload)),
	showToast: (payload: GlobalStateTypes) => dispatch(showToast(payload)),
	// hideToast: () => dispatch(hideToast()),
});

export default connect(mapStateToProps, mapDispatchToProps)(Chat as any);
