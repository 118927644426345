import { IonButton, IonModal, IonIcon, IonToolbar, IonTitle, IonActionSheet } from '@ionic/react';
import React, { useCallback, useState } from 'react';
import ContactItem from '../ChatSearchBar/ContactItem/ContactItem';
import './ManageContacts.scss';
const CloseIcon = './assets/icon/Close.svg';

interface ManageContactsProps {
	show: boolean;
	onCancel?: any;
	contacts: any;
}

const ManageContacts: React.FC<ManageContactsProps> = ({ show, contacts, onCancel }) => {
	const [selectedContacts, setSelectedContacts] = useState<any>([]);
	const [showActionSheet, setShowActionSheet] = useState<boolean>(false);
	const onCloseCallBack = useCallback(() => {
		onCancel && onCancel();
	}, [onCancel]);

	return (
		<IonModal mode="ios" showBackdrop={false} isOpen={show} onDidDismiss={onCloseCallBack} swipeToClose={false} cssClass="manage-contacts-modal" animated={false}>
			<div className="container">
				<IonToolbar className="header-toolbar">
					<IonButton className="close-btn" slot="start" fill="clear">
						<IonIcon slot="icon-only" src={CloseIcon}></IonIcon>
					</IonButton>
					<IonTitle>“U” in Contacts</IonTitle>
				</IonToolbar>

				<div className="content">
					{contacts.map((item: any, key: any) => {
						return (
							<ContactItem
								selectMode={true}
								key={key}
								isSelected={selectedContacts.indexOf(item.id) !== -1}
								item={item}
								onItemSelect={() => {
									let selectedContactsList = selectedContacts;
									if (selectedContactsList.indexOf(item.id) === -1) {
										selectedContactsList.push(item.id);
									} else {
										selectedContactsList.splice(selectedContactsList.indexOf(item.id), 1);
									}
									setSelectedContacts([...selectedContactsList]);
								}}
							/>
						);
					})}
				</div>

				<div className="bottom-view">
					<IonButton fill="clear" disabled={selectedContacts.length === 0} className={'delete-btn' + (selectedContacts.length > 0 ? ' active' : '')} onClick={() => setShowActionSheet(true)}>
						Delete {selectedContacts.length > 0 ? <>({selectedContacts.length})</> : ''}
					</IonButton>
				</div>
			</div>

			<IonActionSheet
				mode="ios"
				header={'Delete “Username” and the chat history with them.'}
				isOpen={showActionSheet}
				onDidDismiss={() => setShowActionSheet(false)}
				cssClass="manage-contact-action-sheet"
				buttons={[
					{
						text: 'Delete Contact',
						role: 'destructive',
						handler: () => {
							setShowActionSheet(false);
						},
					},
					{
						text: 'Cancel',
						handler: () => {
							setShowActionSheet(false);
						},
					},
				]}
			></IonActionSheet>
		</IonModal>
	);
};

export default ManageContacts;
