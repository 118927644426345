import React, { useCallback } from 'react';
import { IonButton, IonIcon, IonModal, IonThumbnail } from '@ionic/react';
import './style.scss';
const PlusIcon = './../../../assets/icon/plusIcon-outline.svg';
const DemoImg = './../../../assets/img/pic3.jpeg';
const GroupImg = './../../../assets/img/groupimg.png';

interface ProjectCreatedProps {
	show: boolean;
	onCancel?: any;
}

const ProjectCreated: React.FC<ProjectCreatedProps> = ({ show, onCancel }) => {
	const onCloseCallBack = useCallback(() => {
		onCancel && onCancel();
	}, [onCancel]);

	return (
		<>
			<IonModal mode="ios" showBackdrop={true} isOpen={show} swipeToClose={true} cssClass="project-created-modal" animated={true}>
				<div className="project-created-component">
					<div className="celender-row">
						<div className="blank"></div>

						<div className="celender-container" slot="end">
							<IonIcon src={PlusIcon} />
							<div className="FEb-month">FEB</div>
							<div className="date">20</div>
						</div>
					</div>
					<div className="project-image-container">
						<div className="deadline-row">
							<div className="deadline">Deadline</div>
							<div className="project-time">3:30 PM</div>
						</div>
						<div className="image-container">
							<IonThumbnail className="image-thumbnail">
								<img src={DemoImg} />
							</IonThumbnail>
						</div>
					</div>
					<div className="group-detail-container">
						<IonThumbnail className="group-image">
							<img src={GroupImg} />
						</IonThumbnail>

						<div className="name-note-contaoner">
							<div className="group-name">Art</div>
							<div className="note-container">As you anticipate your material usage. Please take into consideration using the construction waste that you see!</div>
						</div>
					</div>
					<div className="add-task-container">
						<div className="title">Add Tasks</div>
						<div className="plus-icon">
							<IonIcon src={PlusIcon} />
						</div>
					</div>
					<IonButton onClick={onCancel}>close</IonButton>
				</div>
			</IonModal>
		</>
	);
};

export default ProjectCreated;
