import React from 'react';
import { IonActionSheet, IonButton, IonContent, IonIcon, IonItem, IonLabel, IonPage, IonText, IonToggle } from '@ionic/react';
import './style.scss';
import { RouteComponentProps } from 'react-router';
import { connect } from 'react-redux';
import { pageLoading, updateUser } from '../../../redux/actions/profile';
import { TopNavbar } from '../../common/header/topbar';
import { locale } from '../../../locales/local';
import { DefaultGroupPhoto, DefaultProfilePic } from '../../../helpers/common';
import { dbService } from '../../../services/dbService';
import AddContactItem from '../../../components/ContactItems/AddContactItem';
import RemoveContactItem from '../../../components/ContactItems/RemoveContactItem';
import { AddMembersPageActionType, AffiliationType, GroupMembersPageActionType } from '../../../services/enumService';
import { Navigation } from '../../../services/navigationService';
import { GroupInfoNavParams } from '../../../types/navigation';
import { GroupItem, MemberItem } from '../../../types/group';
import { GroupStateTypes, ProfileStateTypes, ReduxStates } from '../../../types/redux-states';
import { destroyMuc, getMucMembers, leaveMuc, updateGroup } from '../../../redux/actions/group';
import { MucRequestData, UpdateMucRequestFormData } from '../../../types/api-requests';
import GroupAboutModal from '../../../modals/GroupAboutModal/GroupAboutModal';
import MyAliasInGroupPopup from '../../../modals/MyAliasInGroupPopup/MyAliasInGroupPopup';
import CameraService from '../../../services/cameraService';
import ImageEditor, { OpenEditorType } from '../../../modals/ImageEditor/ImageEditor';
import ChooseTagsModal from '../../../modals/ChooseTagsModal/ChooseTagsModal';

const ArrowRightIcon = './assets/icon/arrow-right.svg';

interface iState {
	groupDetail?: GroupItem;
	isManager: boolean;
	searchText: string;
	currentActiveEditField: string;
	selectedContacts?: any;
	selectedContactIds?: any;
	groupName?: string;
	groupPhoto?: any;
	openActionSheetFor?: any;
	myAliasGroupPhoto?: any;
	mucMembers: Array<MemberItem>;
	ownerMember?: MemberItem;
	loggedinMember?: MemberItem;
	showGroupAboutModal: boolean;
	showMyAliasInGroupPopup: boolean;
	showPinturaEditor: boolean;
	showPinturaEditorFor: any;
	selectedImage?: any;
	showGroupNamePopup: boolean;
	showChooseTagModal: boolean;
}

interface iProps extends RouteComponentProps<{ name: string }> {
	profile: ProfileStateTypes;
	group: GroupStateTypes;
	pageLoading: Function;
	updateUser: Function;
	location: any;
	getMucMembers: (data: MucRequestData) => Promise<Array<MemberItem>>;
	updateGroup: (payload: UpdateMucRequestFormData) => Promise<GroupItem>;
	destroyMuc: (payload: MucRequestData) => Promise<any>;
	leaveMuc: (payload: MucRequestData) => Promise<any>;
}

class GroupInfo extends React.Component<iProps, iState> {
	limitShowContactOnThisPage = 8;
	cameraService = new CameraService();

	componentIsMounted: Boolean = false;
	componentIsUpdated: Boolean = false;

	navParams: GroupInfoNavParams = {} as any;

	constructor(props: iProps) {
		super(props);
		this.state = {
			mucMembers: [],
			isManager: false,
			showChooseTagModal: false,
			searchText: '',
			showGroupAboutModal: false,
			showMyAliasInGroupPopup: false,
			currentActiveEditField: '',
			showPinturaEditor: false,
			showPinturaEditorFor: '',
			showGroupNamePopup: false,
		};
	}

	componentDidMount = () => {
		this.componentIsMounted = true;
		Navigation.getNavParamData(this.props).then(async (res: any) => {
			this.navParams = res;

			const groupItem = this.navParams.groupDetail;
			const groupDetail = await dbService.getGroup(groupItem.name);

			if (groupDetail) {
				const members = this.props.group.activeGroupMembers;
				this.setMembersInfo(members);

				this.setState({
					groupDetail,
					myAliasGroupPhoto: groupDetail.aliasImage,
					groupName: groupDetail.mucName,
					groupPhoto: groupDetail.mucImage,
				});

				this.props
					.getMucMembers({
						mucId: groupDetail.name,
					})
					.then((members) => {
						this.setMembersInfo(members);
					});
			}
		});
	};

	setMembersInfo = (members: Array<MemberItem>) => {
		const owner = members.find((member) => member.affiliation === AffiliationType.OWNER);
		const loggedinMember = members.find((member) => member.username === this.props.profile.loggedInUserProfile?.username);

		this.setState({
			mucMembers: members,
			ownerMember: owner,
			loggedinMember: loggedinMember,
		});
	};

	getData = async () => {
		return await dbService.me();
	};

	componentDidUpdate = () => {
		if (!this.componentIsUpdated) {
			this.componentIsUpdated = true;
		}
	};

	_handleClearChatHistory = () => {};

	_handleDeleteOrLeaveGroup = () => {
		if (this.state.groupDetail) {
			const isGroupOwner = this.props.profile.loggedInUserProfile?.username === this.state.ownerMember?.username;
			if (isGroupOwner) {
				this.props
					.destroyMuc({
						mucId: this.state.groupDetail.name,
					})
					.then(() => {
						Navigation.openGroupChats();
					});
			} else {
				this.props
					.leaveMuc({
						mucId: this.state.groupDetail.name,
					})
					.then(() => {
						Navigation.openGroupChats();
					});
			}
		}
	};

	_renderMember = (member: MemberItem, key = '') => {
		return (
			<div className="admin-pic" key={key}>
				<img className="admin-img" src={member.aliasImage || member.profilePhoto || DefaultProfilePic} alt="profilePhoto" />
				<div className="name">{member.alias || member.profileName || member.username}</div>
			</div>
		);
	};

	_renderViewMoreMembers = () => {
		return (
			<div className="view-more-members">
				<IonButton
					className="value"
					fill="clear"
					onClick={() => {
						Navigation.openGroupMembers({
							data: this.state.groupDetail,
							actionType: GroupMembersPageActionType.ViewMoreMembers,
						});
					}}
				>
					<span className="placeholder">View More Members</span>
					<IonIcon src={ArrowRightIcon} />
				</IonButton>
			</div>
		);
	};

	_renderAddRemoveContact = () => {
		// if (this.state.loggedinMember && (this.state.loggedinMember.affiliation === AffiliationType.ADMIN || this.state.loggedinMember.affiliation === AffiliationType.OWNER)) {
		return (
			<>
				<AddContactItem
					onClick={() => {
						Navigation.openAddMembers({
							actionType: AddMembersPageActionType.ChooseContactForCurrentGroupInfoAdmin,
							previousSelectedContactsIds: this.state.mucMembers.map((member) => member.username),
							data: this.state.groupDetail,
						});
					}}
				/>
				<RemoveContactItem
					onClick={() => {
						Navigation.openGroupMembers({
							actionType: GroupMembersPageActionType.GroupInfo,
							isDeleteMode: true,
							data: this.state.groupDetail,
						});
					}}
				/>
			</>
		);
		// }
	};

	updateGroupDetails = (data: UpdateMucRequestFormData) => {
		this.props.updateGroup(data).then((groupItem) => {
			this.setState({ groupDetail: groupItem });
		});
	};

	_getActionsheetOptions = () => {
		const { openActionSheetFor } = this.state;

		const onPhotoAddedCallback = (photo: Array<File>) => {
			this.setState({
				showPinturaEditor: true,
				showPinturaEditorFor: openActionSheetFor,
				selectedImage: photo[0],
			});
		};
		const takePhoto = {
			text: locale.profile.upload_photo.take_photo,
			handler: () => {
				this.cameraService.capturePhotoFromCamera(onPhotoAddedCallback);
			},
		};

		const chooseFromAlbum = {
			text: locale.profile.upload_photo.choose_album,
			handler: () => {
				this.cameraService.choosePhotoFromAlbum(onPhotoAddedCallback);
			},
		};

		const cancelPhoto = {
			text: locale.global.cancel,
			handler: () => {
				this.setState({ openActionSheetFor: '' });
			},
		};

		if (openActionSheetFor === 'group-photo' || openActionSheetFor === 'alias-photo') {
			return [takePhoto, chooseFromAlbum, cancelPhoto];
		}
		return [];
	};

	_onPinturaEditingDone = (file: File) => {
		if (this.state.showPinturaEditorFor === 'group-photo') {
			this.setState({
				showPinturaEditor: false,
				showPinturaEditorFor: '',
				groupPhoto: file,
			});
			this.updateGroupDetails({
				mucImage: file,
				mucId: this.state.groupDetail?.name!,
			});
		} else if (this.state.showPinturaEditorFor === 'alias-photo') {
			this.setState({
				showPinturaEditor: false,
				showPinturaEditorFor: '',
				myAliasGroupPhoto: file,
			});
			this.updateGroupDetails({
				aliasImage: file,
				mucId: this.state.groupDetail?.name!,
			});
		}
	};

	render() {
		const otherMembers = this.state.mucMembers.filter((member: MemberItem) => member.affiliation !== AffiliationType.OWNER);
		const isNonAdmin = this.state.ownerMember?.username === this.props.profile.loggedInUserProfile?.username ? false : true;

		if (this.componentIsMounted) {
			return (
				<IonPage className="group-info-page">
					<TopNavbar {...this.props} groupInfo={this.state.groupDetail} hideSearchBar={true} pageTitle={'Group Info'} pageTitleDescription={(this.props.group.activeGroupMembers.length ?? this.state.groupDetail?.memberAmount) + ' Members'} showBack={true} />
					{this.state.mucMembers.length > 0 && (
						<IonContent className="has-topbar">
							{isNonAdmin ? (
								<>
									<div className="non-admin-container">
										<img alt="group-photo" src={this.state.groupDetail?.mucImage ?? DefaultGroupPhoto} className="nonadmin-img" />
									</div>
									<div className="non-architecture">{this.state.groupDetail?.mucName}</div>
									{this.state.groupDetail?.about && <div className="non-architecture-text">{this.state.groupDetail?.about}</div>}
								</>
							) : (
								<>
									<IonLabel className="participants">Paticipants</IonLabel>
									<div className="selected-contacts-container">
										{otherMembers.map((member: MemberItem, key: any) => {
											if (key >= this.limitShowContactOnThisPage) {
												return null;
											}
											return this._renderMember(member, key);
										})}

										{this._renderAddRemoveContact()}
									</div>

									{otherMembers.length > this.limitShowContactOnThisPage && this._renderViewMoreMembers()}

									{this.state.ownerMember && (
										<>
											<div className="participants">
												<IonLabel>Admin</IonLabel>
											</div>
											{this._renderMember(this.state.ownerMember)}
										</>
									)}

									<div className="group-field-item group-name-item">
										<div className="row-first" onClick={() => this.setState({ showGroupNamePopup: true })}>
											<div className="title-architecture">{this.state.groupDetail?.mucName}</div>
											<div className="value">
												<IonIcon src={ArrowRightIcon} />
											</div>
										</div>
									</div>

									<div className="group-field-item about-item" onClick={() => this.setState({ showGroupAboutModal: true })}>
										{!this.state.groupDetail?.about && (
											<div className="row-first">
												<div className="title">About</div>
												<IonButton className="value" fill="clear">
													<span className="placeholder">{locale.groups.not_set}</span>
													<IonIcon src={ArrowRightIcon} />
												</IonButton>
											</div>
										)}

										{this.state.groupDetail?.about && (
											<div className="row-second" style={{ marginBottom: '14px' }}>
												<div className="title" style={{ maxWidth: 'unset' }}>
													{this.state.groupDetail?.about}
												</div>
												<div className="arrow-adjust-view">
													<IonButton fill="clear" style={{ height: '24px' }} className="value">
														<IonIcon src={ArrowRightIcon} />
													</IonButton>
												</div>
											</div>
										)}
									</div>

									<div className="group-field-item" style={{ minHeight: '67px' }}>
										<div className="row-first">
											<div className="title">Memo</div>
											<IonButton className="value" fill="clear" onClick={() => {}}>
												<span className="placeholder">{locale.groups.not_set}</span>
												<IonIcon src={ArrowRightIcon} />
											</IonButton>
										</div>
										<div className="row-second">
											<div className="title">Bring attention to problems and solve them.</div>
										</div>
									</div>
								</>
							)}

							{!isNonAdmin && (
								<>
									<div className="group-field-item" style={{ minHeight: '67px' }}>
										<div className="row-first">
											<div className="title">Manage Group</div>
											<IonButton
												className="value"
												fill="clear"
												onClick={() => {
													Navigation.openManageGroup();
												}}
											>
												<IonIcon src={ArrowRightIcon} />
											</IonButton>
										</div>
									</div>

									<div className="group-field-item">
										<div className="row-first">
											<div className="title">Group Photo</div>
											<IonButton className="value" fill="clear" onClick={() => this.setState({ openActionSheetFor: 'group-photo' })}>
												<img src={this.state.groupDetail?.mucImage ?? DefaultGroupPhoto} alt="profilePhoto" />
												<IonIcon src={ArrowRightIcon} />
											</IonButton>
										</div>
									</div>
								</>
							)}

							<div className="group-field-item">
								<div className="row-first">
									<div className="title">My Alias</div>
									<IonButton className="value" fill="clear" onClick={() => this.setState({ showMyAliasInGroupPopup: true })}>
										{this.state.groupDetail?.alias ? <span className="data">{this.state.groupDetail?.alias}</span> : <span className="placeholder">Not Set</span>}
										<IonIcon src={ArrowRightIcon} />
									</IonButton>
								</div>
							</div>
							<div className="group-field-item">
								<div className="row-first">
									<div className="title">My Alias Photo</div>
									<IonButton
										className="value"
										fill="clear"
										onClick={() => {
											this.setState({ openActionSheetFor: 'alias-photo' });
										}}
									>
										<img src={this.state.groupDetail?.aliasImage ? this.state.groupDetail?.aliasImage : DefaultProfilePic} alt="profilePhoto" />
										<IonIcon src={ArrowRightIcon} />
									</IonButton>
								</div>
							</div>
							{/* <div className="group-field-item" style={{ minHeight: '67px' }}>
								<div className="row-first">
									<div className="title">Guidelines</div>
									<IonButton className="value" fill="clear">
										<span className="placeholder">{locale.groups.not_set}</span>
										<IonIcon src={ArrowRightIcon} />
									</IonButton>
								</div>
								<div className="row-second">
									<div className="title">Rules, Agreements, Password protections, and more</div>
								</div>
							</div> */}

							<IonItem detail={false} lines="none">
								<IonLabel>Mute Notifications</IonLabel>
								<div className={'swtich-item'}>
									<IonToggle className="toggle-customised" mode="ios" />
								</div>
							</IonItem>
							<IonItem detail={false} lines="none">
								<IonLabel>Stick on Top</IonLabel>
								<div className={'swtich-item'}>
									<IonToggle className="toggle-customised" mode="ios" />
								</div>
							</IonItem>

							<div className="group-field-item" style={{ minHeight: '67px' }}>
								<div className="row-first">
									<div className="title">Set Tags</div>
									<IonButton
										className="value"
										fill="clear"
										onClick={() => {
											this.setState({ showChooseTagModal: true });
										}}
									>
										<span className="placeholder">{this.state.groupDetail?.tags && this.state.groupDetail?.tags?.length > 0 ? this.state.groupDetail?.tags?.join(',') : locale.groups.not_set}</span>
										<IonIcon src={ArrowRightIcon} />
									</IonButton>
								</div>
								<div className="row-second">
									<div className="title">Classify Groups by Tags</div>
								</div>
							</div>

							{/* //////////// Non Admin Group  down */}

							{isNonAdmin && (
								<>
									<IonLabel className="participants">Participants</IonLabel>
									<div className="selected-contacts-container">
										{otherMembers.map((member: MemberItem, key: any) => {
											if (key >= this.limitShowContactOnThisPage) {
												return null;
											}
											return this._renderMember(member, key);
										})}

										{this._renderAddRemoveContact()}
									</div>
									{otherMembers.length > this.limitShowContactOnThisPage && this._renderViewMoreMembers()}

									{this.state.ownerMember && (
										<>
											<div className="participants">
												<IonLabel>Admin</IonLabel>
											</div>
											{this._renderMember(this.state.ownerMember)}
										</>
									)}
								</>
							)}

							{/*  end */}

							<div className="bottom-btns">
								<IonButton mode="ios" fill="clear" onClick={this._handleClearChatHistory}>
									Clear Chat History
								</IonButton>

								<IonButton mode="ios" fill="clear" onClick={this._handleDeleteOrLeaveGroup}>
									Delete and Leave
								</IonButton>
							</div>
							<div className="creater-container">
								<IonText>{locale.global.created} Apr 1, 2021</IonText>
								<div className="creater-name">by xiao_B</div>
							</div>
						</IonContent>
					)}

					<GroupAboutModal
						props={this.props}
						initialValue={this.state.groupDetail?.about}
						show={this.state.showGroupAboutModal}
						onCloseCallBack={() => this.setState({ showGroupAboutModal: false })}
						onConfirm={(value: string) => {
							this.updateGroupDetails({
								about: value,
								mucId: this.state.groupDetail?.name!,
							});

							this.setState({
								showGroupAboutModal: false,
							});
						}}
					/>

					<MyAliasInGroupPopup
						initialValue={this.state.groupDetail?.alias}
						show={this.state.showMyAliasInGroupPopup}
						photo={this.state.groupDetail?.aliasImage}
						onCloseCallBack={() => this.setState({ showMyAliasInGroupPopup: false })}
						onConfirm={(value: string) => {
							this.updateGroupDetails({
								alias: value,
								mucId: this.state.groupDetail?.name!,
							});
							this.setState({
								showMyAliasInGroupPopup: false,
							});
						}}
					/>
					{/* for group name */}
					<MyAliasInGroupPopup
						initialValue={this.state.groupDetail?.mucName}
						isGroupName={true}
						photo={this.state.groupDetail?.mucImage}
						show={this.state.showGroupNamePopup}
						onCloseCallBack={() => this.setState({ showGroupNamePopup: false })}
						onConfirm={(value: string) => {
							this.updateGroupDetails({
								name: value,
								mucId: this.state.groupDetail?.name!,
							});
							this.setState({
								showGroupNamePopup: false,
								groupName: value,
							});
						}}
					/>

					<ImageEditor
						openEditorType={OpenEditorType.FOR_PROFILE_GROUP_PHOTO}
						selectedImage={this.state.selectedImage}
						show={this.state.showPinturaEditor}
						onClose={() => {
							this.setState({
								showPinturaEditor: false,
								showPinturaEditorFor: '',
							});
						}}
						onSave={this._onPinturaEditingDone}
					/>

					<IonActionSheet
						header={this.state.openActionSheetFor === 'group-photo' ? 'Add Group Photo' : 'Add Alias Photo'}
						mode="ios"
						isOpen={this.state.openActionSheetFor ? true : false}
						onDidDismiss={() => this.setState({ openActionSheetFor: '' })}
						cssClass="group-manager-action-sheet"
						buttons={this._getActionsheetOptions()}
					></IonActionSheet>

					<ChooseTagsModal
						props={this.props}
						loggedInUser={this.props.profile.loggedInUserProfile}
						selectedTags={this.state.groupDetail?.tags || []}
						show={this.state.showChooseTagModal}
						onCancel={() => {
							this.setState({ showChooseTagModal: false });
						}}
						onDone={(updatedTags: any) => {
							this.updateGroupDetails({
								tags: updatedTags,
								mucId: this.state.groupDetail?.name!,
							});
							this.setState({ showChooseTagModal: false });
						}}
						onTagCreated={(value: string) => {
							const tags = this.state.groupDetail?.tags || [];
							tags.push(value);
							const groupDetail = this.state.groupDetail;
							if (groupDetail) {
								groupDetail.tags = tags;
							}
							this.setState({ groupDetail });
						}}
					/>
				</IonPage>
			);
		}
		return <></>;
	}
}

const mapStateToProps = (state: ReduxStates) => {
	return {
		profile: state.profile,
		group: state.group,
	};
};

const mapDispatchToProps = (dispatch: any) => ({
	updateUser: (payload: String) => dispatch(updateUser(payload)),
	pageLoading: (payload: any) => dispatch(pageLoading(payload)),
	getMucMembers: (payload: MucRequestData) => dispatch(getMucMembers(payload)),
	updateGroup: (payload: UpdateMucRequestFormData) => dispatch(updateGroup(payload)),
	destroyMuc: (payload: MucRequestData) => dispatch(destroyMuc(payload)),
	leaveMuc: (payload: MucRequestData) => dispatch(leaveMuc(payload)),
});

export default connect(mapStateToProps, mapDispatchToProps)(GroupInfo);
