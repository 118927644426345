import { IonButton, IonButtons, IonIcon, IonModal } from '@ionic/react';
import React, { useEffect, useState } from 'react';
import { AddMembersPageActionType, ChatMediaType, MessageType, PubSubEventType } from '../services/enumService';
import { Navigation } from '../services/navigationService';
import './style.scss';
import PubSub from 'pubsub-js';

const SaveToDevice = './assets/icon/savetodevice.svg';
const arrowRightButton = './assets/icon/arrow-right-light.svg';
const imageIconList = [
	{
		icon: 'maskman.svg',
		name: 'peter',
	},
	{
		icon: 'peter.svg',
		name: 'peter',
	},
	{
		icon: 'xylo.svg',
		name: 'peter',
	},
	{
		icon: 'peter.svg',
		name: 'peter',
	},
	{
		icon: 'xylo.svg',
		name: 'peter',
	},
	{
		icon: 'peter.svg',
		name: 'peter',
	},
	{
		icon: 'xylo.svg',
		name: 'peter',
	},
	{
		icon: 'xylo.svg',
		name: 'peter',
	},
	{
		icon: 'xylo.svg',
		name: 'peter',
	},
	{
		icon: 'xylo.svg',
		name: 'peter',
	},
];

interface ChatSelectViewThreadProps {
	// message: Message;
	// countLength: number;
	show: boolean;
	onCloseCallBack: () => void;
	onOption?: (OptionValue: string) => void;
}
let count: number = 0;
let messageType: string = '';
const ChatSelectViewThread: React.FC<ChatSelectViewThreadProps> = ({ show, onCloseCallBack, onOption }) => {
	const [countLength, setCountLength] = useState<Number>(count);
	useEffect(() => {
		const viewThreadPubSubToken = PubSub.subscribe(PubSubEventType.ViewThread, (msg: string, data: any) => {
			if (data.action === PubSubEventType.ViewThread) {
				count = data.selectedMessageLength;
				messageType = data.message.messageType;
				setCountLength(count);
			}
		});
		return () => PubSub.unsubscribe(viewThreadPubSubToken);
	}, [countLength, messageType]);
	return (
		<>
			<IonModal showBackdrop={true} isOpen={show} onDidDismiss={onCloseCallBack} swipeToClose={true} cssClass="chat-selectview-thread-modal" animated={true}>
				<div className="content">
					<div className="bottom-manage-sendThread-popup">
						<div className="manage-share-top">
							<div className="selected-send-file">
								<div className="manage">Send to ({countLength})</div>
								<IonIcon
									className="right-icon-thread"
									onClick={() => {
										Navigation.openAddMembers({
											actionType: AddMembersPageActionType.ChooseContactForForwordSketchpad,
										});
									}}
									src={arrowRightButton}
								></IonIcon>
							</div>

							<div className="shared">
								{imageIconList.map((item: any, key: any) => {
									return (
										<div className="shared-image" key={key}>
											<IonIcon src={'../assets/icon/' + item.icon}></IonIcon>
											<div className="icon-name">{item.name}</div>
										</div>
									);
								})}
							</div>
							<div className={messageType === MessageType.TEXT && countLength ? 'cancel' : 'cancel-save'} onClick={onCloseCallBack}>
								<p> Cancel</p>

								<IonButtons className="image-container">
									{(messageType === MessageType.MEDIA || messageType === MessageType.FILE) && countLength && (
										<IonButton
											fill="clear"
											className="image-button"
											onClick={() => {
												onOption && onOption('save');
											}}
										>
											<IonIcon slot="icon-only" src={SaveToDevice} />
										</IonButton>
									)}
								</IonButtons>
							</div>
						</div>
					</div>
				</div>
			</IonModal>
		</>
	);
};

export default ChatSelectViewThread;
