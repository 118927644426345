import React, { useCallback, useEffect, useState } from 'react';
import { IonButton, IonIcon, IonModal } from '@ionic/react';
import './style.scss';
// import DocViewer, { DocViewerRenderers } from '@cyntler/react-doc-viewer';
import axios from 'axios';

const closeIcon = './assets/icon/Close.svg';

interface PreviewFileProps {
	show: boolean;
	onCancel?: any;
	filePath: any;
	contentType: any;
	fileName?: string;
}

const PreviewFile: React.FC<PreviewFileProps> = ({ show, onCancel, filePath, contentType, fileName }) => {
	const onCloseCallBack = useCallback(() => {
		onCancel && onCancel();
	}, [onCancel]);

	const [docs, setDocs] = useState<any>([]);
	const [openModal, setOpenModal] = useState<boolean>(false);

	useEffect(() => {
		if (!openModal && show && filePath && contentType) {
			(async () => {
				const response = await axios.get(filePath, {
					headers: {},
					responseType: 'blob', // Important
				});

				const blob = new Blob([response.data], { type: contentType });
				const blobUrl = URL.createObjectURL(blob);

				setOpenModal(true);
				setDocs([{ uri: blobUrl, fileName: fileName }]);
			})();
		} else if (openModal && !show) {
			setOpenModal(false);
			setDocs([]);
		}
	}, [show, filePath, contentType, openModal]);

	return (
		<IonModal mode="ios" showBackdrop={true} isOpen={show} onDidDismiss={onCloseCallBack} swipeToClose={true} cssClass="preview-file-modal" animated={true}>
			{/* <TopNavbar isHideRightButton={true} {...props} pageTitle={fileName} hideSearchBar={true} leftButtonIcon={closeIcon} onRightButtonPress={() => {}} onLeftButtonPress={onCloseCallBack} /> */}
			{/* <DocViewer config={{ header: { disableFileName: true } }} documents={docs} pluginRenderers={DocViewerRenderers} /> */}
			<div className="close-button-container">
				<IonButton slot="start" className="back-button" onClick={onCloseCallBack}>
					<IonIcon slot="icon-only" icon={closeIcon} />
				</IonButton>
				<div className="filename-text">{fileName}</div>
			</div>
		</IonModal>
	);
};

export default PreviewFile;
