export const PROFILE_INIT = 'PROFILE_INIT';

export const PROFILE_FETCH_START = 'PROFILE_FETCH_START';
export const PROFILE_FETCH_SUCCESS = 'PROFILE_FETCH_SUCCESS';
export const PROFILE_FETCH_FAILED = 'PROFILE_FETCH_FAILED';

export const TAG_FETCH_START = 'TAG_FETCH_START';
export const TAG_FETCH_SUCCESS = 'TAG_FETCH_SUCCESS';
export const TAG_FETCH_FAILED = 'TAG_FETCH_FAILED';

export const GET_TAGS_VISIBILITY_START = 'GET_TAGS_VISIBILITY_START';
export const GET_TAGS_VISIBILITY_SUCCESS = 'GET_TAGS_VISIBILITY_SUCCESS';
export const GET_TAGS_VISIBILITY_FAILED = 'GET_TAGS_VISIBILITY_FAILED';

export const SET_TAGS_VISIBILITY_START = 'SET_TAGS_VISIBILITY_START';
export const SET_TAGS_VISIBILITY_SUCCESS = 'SET_TAGS_VISIBILITY_SUCCESS';
export const SET_TAGS_VISIBILITY_FAILED = 'SET_TAGS_VISIBILITY_FAILED';

export const ADD_CONTACTS_TO_TAG_START = 'ADD_CONTACTS_TO_TAG_START';
export const ADD_CONTACTS_TO_TAG_SUCCESS = 'ADD_CONTACTS_TO_TAG_SUCCESS';
export const ADD_CONTACTS_TO_TAG_FAILED = 'ADD_CONTACTS_TO_TAG_FAILED';

export const REMMOVE_CONTACTS_TO_TAG_START = 'REMMOVE_CONTACTS_TO_TAG_START';
export const REMMOVE_CONTACTS_TO_TAG_SUCCESS = 'REMMOVE_CONTACTS_TO_TAG_SUCCESS';
export const REMMOVE_CONTACTS_TO_TAG_FAILED = 'REMMOVE_CONTACTS_TO_TAG_FAILED';

export const DELETE_TAG_START = 'DELETE_TAG_START';
export const DELETE_TAG_SUCCESS = 'DELETE_TAG_SUCCESS';
export const DELETE_TAG_FAILED = 'DELETE_TAG_FAILED';

export const PROFILE_EDITED = 'PROFILE_EDITED';

export const PROFILE_UPDATING = 'PROFILE_UPDATING';
export const PROFILE_UPDATE_FAILED = 'PROFILE_UPDATE_FAILED';
export const PROFILE_UPDATE_SUCCESS = 'PROFILE_UPDATE_SUCCESS';

export const CHATONLY_PROFILE_UPDATING = 'CHATONLY_PROFILE_UPDATING';
export const CHATONLY_PROFILE_UPDATE_FAILED = 'CHATONLY_PROFILE_UPDATE_FAILED';
export const CHATONLY_PROFILE_UPDATE_SUCCESS = 'CHATONLY_PROFILE_UPDATE_SUCCESS';

export const PROFILE_UPDATE_ERROR = 'PROFILE_UPDATE_ERROR';
