import { IonButton, IonModal } from '@ionic/react';
import React from 'react';
import './style.scss';

interface MoveThreadPopUpProps {
	show: boolean;
	onMoveCallBack: () => void;
	onCloseCallBack: () => void;
}

const MoveThreadPopUp: React.FC<MoveThreadPopUpProps> = ({ show, onCloseCallBack, onMoveCallBack }) => {
	return (
		<>
			<IonModal showBackdrop={true} isOpen={show} onDidDismiss={onCloseCallBack} swipeToClose={true} cssClass="move-thread-modal" animated={true}>
				<div className="content">
					<div className="bottom-manage-sendThread-popup">
						<div className="select-content">
							<>
								<div
									className="move-to"
									onClick={() => {
										onMoveCallBack && onMoveCallBack();
									}}
								>
									Move to
								</div>
								<div className="thread-container">
									<div className="chat-button">Chat</div>
									<IonButton>Sport</IonButton>
									<IonButton>Familr Group</IonButton>
									<IonButton >UI/UX</IonButton>
									<IonButton>REARCH</IonButton>
									<IonButton>CLINT SIDE</IonButton>
									<IonButton>Message</IonButton>
									<IonButton>Clean</IonButton>
								</div>
								<div
									className="cancel"
									onClick={() => {
										onCloseCallBack();
									}}
								>
									Cancel
								</div>
							</>
						</div>
					</div>
				</div>
			</IonModal>
		</>
	);
};

export default MoveThreadPopUp;
