import React from 'react';
import { IonContent, IonImg, IonLabel, IonPage, IonThumbnail } from '@ionic/react';
import { RouteComponentProps } from 'react-router';
import { TopNavbar } from '../../common/header/topbar';
import { DefaultProfilePic } from '../../../helpers/common';
import './ChatOnly.scss';
import UserProfileMedia from '../../../components/UserProfileMedia';
import { Navigation } from '../../../services/navigationService';
import { ViewAsChatOnlyNavParams } from '../../../types/navigation';
import { ProfilePhotoItem } from '../../../types/profile';
import SharedService from '../../../services/sharedService';
import { ProfileStateTypes } from '../../../types/redux-states';
import { connect } from 'react-redux';

interface iProps extends RouteComponentProps<{ name: string }> {}
interface iProps {
	profile: ProfileStateTypes;
}

class ViewAsChatOnly extends React.Component<iProps> {
	componentIsMounted: Boolean = false;

	componentIsUpdated: Boolean = false;
	navParams: ViewAsChatOnlyNavParams = {} as any;

	constructor(props: iProps) {
		super(props);

		this.state = {
			slideIndex: -1,
		};
	}

	componentDidMount = async () => {
		this.componentIsMounted = true;

		Navigation.getNavParamData(this.props).then(async (res: any) => {
			this.navParams = res;
			this.setState({
				slideIndex: 0,
			});
		});
	};

	componentWillUnmount() {
		this.componentIsMounted = false;
	}

	componentDidUpdate = async () => {
		if (!this.componentIsUpdated) {
			this.componentIsUpdated = true;
		}
	};

	render() {
		const { profilePhotos } = this.props.profile.loggedInUserChatOnlyProfile || {};
		const activeChatOnlyProfilePhoto: ProfilePhotoItem = SharedService.extractProfilePhoto(profilePhotos!)!;

		return (
			<IonPage className="viewas-chatonly">
				<TopNavbar
					{...this.props}
					pageTitle={''}
					hideSearchBar={true}
					showBack={true}
					isHideRightButton={false}
					showSearchIcon={true}
					rightButtonDisabled={true}
					onLeftButtonPress={() => {
						Navigation.back();
					}}
				/>

				<IonContent>
					<div className="viewas-chat-only  has-bottom-navbar has-topbar">
						<div className="user-name-item">
							<div className="profile-row">
								<IonThumbnail className="profile-photo">
									<IonImg title="Change" src={activeChatOnlyProfilePhoto?.url || DefaultProfilePic} />
								</IonThumbnail>
							</div>

							<IonLabel className="name" color="light">
								{this.navParams.data?.displayName}
							</IonLabel>

							{this.navParams.data?.profession && (
								<IonLabel className="professiontxt" color="light">
									{this.navParams.data?.profession}
								</IonLabel>
							)}

							{this.navParams.data?.bio && (
								<IonLabel className="quote" color="light">
									{this.navParams.data?.bio}
								</IonLabel>
							)}

							{this.navParams.data?.website && (
								<IonLabel className="website" color="light">
									{this.navParams.data?.website}
								</IonLabel>
							)}

							{this.navParams.data?.region && (
								<IonLabel className="region" color="light">
									{this.navParams.data?.region}
								</IonLabel>
							)}
						</div>

						<div className="blanck"></div>
						<div className="userprofilemedai">
							<UserProfileMedia />
						</div>
						<div className="searchid-container">
							<div className="searchid">Searched Id</div>
							<div className="searchid">03/22/22</div>
						</div>
					</div>
				</IonContent>
			</IonPage>
		);
	}
}
const mapStateToProps = (state: any) => {
	return {
		profile: state.profile,
	};
};

export default connect(mapStateToProps)(ViewAsChatOnly);
