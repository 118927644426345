import React, { useCallback, useRef, useState } from 'react';
import { IonButton, IonButtons, IonContent, IonFooter, IonHeader, IonIcon, IonInput, IonLoading, IonModal, IonTitle, IonToolbar } from '@ionic/react';
import { chevronDown } from 'ionicons/icons';
import './SketchPad.scss';
import { FabricJSCanvas, useFabricJSEditor } from 'fabricjs-react';
import { fabric } from 'fabric';
import EmojiSearchModal from '../EmojiSearchModal/EmojiSearchModal';
import ScketchpadTextStylePopover from '../../components/ScketchpadTextStylePopover/ScketchpadTextStylePopover';
import ScketchpadShapeStylePopover from '../../components/ScketchpadShapeStylePopover/ScketchpadShapeStylePopover';

import { editorColors, EditingType, shapeStyles, textStyles, toolbarIcons, RevertIcon, SketchDownload, CloseIcon, DeleteIcon, TextStyleIcon, TextBgIcon, ArrowUpIcon } from './Data';
import SketchpadNavigation from './SketchpadNavigationMenu';
import store from '../../redux/store';
import { showStylishToast } from '../../redux/actions/global';
import { Navigation } from '../../services/navigationService';
import SharedService from '../../services/sharedService';
import { info } from '../../helpers/common';

interface iProps {
	show: boolean;
	onCancel?: any;
	onSave?: any;
}

const HeaderHeight = 88;
const FooterHeight = 134;
const shapeStrokeWidth = 4;
const controlsStrokeWidth = 2;
const controlsCornerSize = 8;

const SketchPad = ({ show, onCancel, onSave }: iProps) => {
	const { editor, onReady } = useFabricJSEditor();

	const [isDeleteButtonShow, setIsDeleteButtonShow] = useState(false),
		[isEditing, setIsEditing] = useState(true),
		[historyArr, setHistoryArr] = useState<any>([]),
		[sketchpadImg, setSketchpadImg] = useState<any>(''),
		[isChangesMade, setIsChangesMade] = useState(false),
		[chatBtnType, setChatBtnType] = useState('notepad'),
		[currentEditingType, setCurrentEditingType] = useState(EditingType.DRAWING),
		[showTextStylePopover, setShowTextStylePopover] = useState(false),
		[textStylePopoverEvent, setTextStylePopoverEvent] = useState(null),
		[selectedTextStyle, setSelectedTextStyle] = useState(textStyles[1]),
		[showShapeStylePopover, setShowShapeStylePopover] = useState(false),
		[shapeStylePopoverEvent, setShapeStylePopoverEvent] = useState(null),
		[selectedShapeStyle, setSelectedShapeStyle] = useState(shapeStyles[0]),
		[polygoShapeSide, setPolygoShapeSide] = useState(3),
		[starShapeSide, setStarShapeSide] = useState(5),
		[isEmojiModalShow, setIsEmojiModalShow] = useState(false),
		[editorActiveColor, setEditorActiveColor] = useState(editorColors[0]),
		[textBgChangeActive, setTextBgChangeActive] = useState(false),
		[openSelectComponent, setOpenSelectComponent] = useState(false),
		[isSave, setIsSave] = useState(false),
		contentRef = useRef<HTMLIonContentElement>(null),
		RevertButton = (
			<>
				<IonButton
					className="undo-btn"
					fill="clear"
					slot="icon-only"
					onClick={() => {
						if (historyArr.length > 0) {
							const lastHistoryItem = historyArr.pop();
							if (lastHistoryItem.type === 'object') {
								if (editor?.canvas) {
									const canvas = editor?.canvas;
									var currentlySelected = lastHistoryItem.value;
									if (currentlySelected) {
										canvas.remove(currentlySelected);
									}
								}
							} else if (lastHistoryItem.type === 'background') {
								let colorToBeChange = '#ffffff';

								if (historyArr.length > 0) {
									const lastColorObject = historyArr[historyArr.length - 1];
									if (lastColorObject.type === 'background') {
										colorToBeChange = lastColorObject.value;
									} else {
										colorToBeChange = '#fff';
										historyArr.forEach((element: any) => {
											if (element.type === 'background') {
												colorToBeChange = element.value;
											}
										});
									}
								}

								if (editor?.canvas) {
									const canvas: any = editor?.canvas;
									canvas.setBackgroundColor(colorToBeChange, canvas.requestRenderAllBound);
								}
							}

							setHistoryArr(historyArr);
						}
					}}
				>
					<IonIcon src={RevertIcon} />
				</IonButton>
				<IonButton
					onClick={(event: any) => {
						onDownloadImage();
					}}
				>
					<IonIcon src={SketchDownload} />
				</IonButton>
			</>
		),
		updateControlVisibility = useCallback((canvas) => {
			fabric.Object.prototype.objectCaching = false;

			var currentlySelected: any = canvas?.getActiveObject();

			switch (currentlySelected.type) {
				case 'rectangle':
					currentlySelected.setControlsVisibility({
						ml: false,
						mr: false,
						mb: false,
						mt: false,
						mtr: false,
					});

					break;
				case 'polygon':
					currentlySelected.setControlsVisibility({
						ml: false,
						mr: false,
						mb: false,
						mt: false,
						mtr: false,
					});
					break;
				case 'line':
					currentlySelected.setControlsVisibility({
						ml: true,
						mr: true,
						mb: true,
						mt: true,
						mtr: false,
					});
					break;
				case 'ellipse':
					currentlySelected.setControlsVisibility({
						ml: true,
						mr: true,
						mb: true,
						mt: true,
						mtr: false,
					});
					break;
				case 'arrow':
					currentlySelected.setControlsVisibility({
						ml: false,
						mr: false,
						mb: false,
						mt: false,
						// mtr: false,
					});
					break;
				case 'star':
					currentlySelected.setControlsVisibility({
						ml: false,
						mr: false,
						mb: false,
						mt: false,
						mtr: false,
					});
					break;
				case 'cross':
					currentlySelected.setControlsVisibility({
						ml: false,
						mr: false,
						mb: false,
						mt: false,
						mtr: false,
					});

					break;
			}
			canvas.renderAll();
		}, []);

	const activateDrawingMode = useCallback(
			(canvas) => {
								if (canvas) {
					canvas.freeDrawingBrush.color = editorColors[0];
					canvas.freeDrawingBrush.width = 5;
					canvas.isDrawingMode = true;

					canvas.setDimensions(
						{
							width: contentRef.current?.clientWidth || window.innerWidth,
							height: contentRef.current?.clientHeight || window.innerHeight - HeaderHeight - FooterHeight,
						},
						canvas.requestRenderAllBound
					);
				}
			},
			[contentRef]
		),
		onCanvasReady = useCallback(
			(canvas) => {
								onReady(canvas);
				activateDrawingMode(canvas);
				canvas.setBackgroundColor('#ffffff', canvas.requestRenderAllBound);

				canvas.on('after:render', () => {
					info('after:render');
					if (historyArr.length > 0) {
						setIsChangesMade(true);
					} else {
						setIsChangesMade(false);
					}
				});
				canvas.on('object:added', (object: any) => {
					info('object:added');
					historyArr.push({ type: 'object', value: object.target });
					setHistoryArr(historyArr);
				});

				canvas.on('selection:cleared', () => {
					info('selection:cleared');
					setIsDeleteButtonShow(false);
				});
				canvas.on('selection:created', (event: any) => {
					info('selection:created');
					updateControlVisibility(canvas);
					setIsDeleteButtonShow(true);
				});
				canvas.on('selection:updated', (event: any) => {
					updateControlVisibility(canvas);

					info('selection:updated');
				});

				// Controls UI customise
				fabric.Object.prototype.transparentCorners = false;
				fabric.Object.prototype.cornerColor = '#ffffff';
				fabric.Object.prototype.cornerStrokeColor = '#FF5E5E';
				fabric.Object.prototype.borderColor = '#FF5E5E';
				fabric.Object.prototype.strokeWidth = controlsStrokeWidth;
				fabric.Object.prototype.cornerStyle = 'circle';
				fabric.Object.prototype.cornerSize = controlsCornerSize;
			},
			[activateDrawingMode, onReady, historyArr, updateControlVisibility]
		);

	const activateBackgroundMode = useCallback((canvas) => {
		if (canvas) {
			canvas.isDrawingMode = false;
		}
	}, []);
	const activateEmojiMode = useCallback((canvas) => {
		if (canvas) {
			canvas.isDrawingMode = false;
			setIsEmojiModalShow(true);
		}
	}, []);
	const activateShapeMode = useCallback((canvas) => {
		canvas.isDrawingMode = false;

		fabric.Object.prototype.setControlsVisibility({
			ml: false,
			mr: false,
			mb: false,
			mt: false,
			mtr: false,
		});
	}, []);

	const activateTextMode = useCallback(
		(canvas) => {
			canvas.isDrawingMode = false;

			const xPos = 30;
			const yPos = 170;
			var text = new fabric.Textbox('', {
				cursorColor: 'red',
				fontSize: 30,
				fontWeight: selectedTextStyle.style.fontWeight,
				fontStyle: selectedTextStyle.style.fontStyle as any,
				fontFamily: 'Metropolis',
				top: yPos,
				left: xPos,
				width: window.innerWidth - xPos * 2,
			});
			canvas.add(text);
			canvas.setActiveObject(text);

			text.enterEditing();
			text.hiddenTextarea?.focus();

			fabric.Object.prototype.setControlsVisibility({
				ml: false,
				mr: false,
				mb: false,
				mt: false,
				// mtr:false,
			});
		},
		[selectedTextStyle]
	);

	const onEmojiClick = useCallback(
		(event, emojiObj) => {
			setIsEmojiModalShow(false);
			setIsDeleteButtonShow(true);

			if (editor?.canvas) {
				const canvas = editor?.canvas;
				fabric.Object.prototype.objectCaching = false;

				const xPos = (contentRef.current?.clientWidth || window.innerWidth) / 2;
				const yPos = (contentRef.current?.clientHeight || window.innerHeight) / 2;
				var text = new fabric.Text(emojiObj.emoji, {
					fontSize: 60,
					top: yPos,
					left: xPos,
				});
				canvas.add(text);
				canvas.setActiveObject(text);

				text.setControlsVisibility({
					ml: false,
					mr: false,
					mb: false,
					mt: false,
					// mtr:false,
				});
				canvas.requestRenderAll();
			}
		},
		[editor, contentRef]
	);
	const onTextStyleChange = useCallback(
		(item) => {
			if (editor?.canvas) {
				const canvas = editor?.canvas;
				fabric.Object.prototype.objectCaching = false;

				var currentlySelected: any = canvas.getActiveObject();
				if (currentlySelected) {
					currentlySelected.fontWeight = item.style.fontWeight;
					currentlySelected.fontStyle = item.style.fontStyle;
					canvas.requestRenderAll();
				}
			}
		},
		[editor]
	);

	const onShapeChange = useCallback(
		(item) => {
			if (editor?.canvas) {
				const canvas = editor?.canvas;
				fabric.Object.prototype.objectCaching = false;

				const xPos = (contentRef.current?.clientWidth || window.innerWidth) / 2;
				const yPos = (contentRef.current?.clientHeight || window.innerHeight) / 2;

				switch (item.value) {
					case 'rectangle':
						var rect = new fabric.Rect({
							type: 'rectangle',
							top: yPos - 50,
							left: xPos - 50,
							width: 100,
							height: 100,
							fill: 'transparent',
							stroke: editorActiveColor,
							strokeWidth: shapeStrokeWidth,
						});

						rect.setControlsVisibility({
							ml: false,
							mr: false,
							mb: false,
							mt: false,
							mtr: false,
						});

						canvas.add(rect);
						canvas.setActiveObject(rect);
						break;
					case 'polygon':
						let polygonVertices = [];
						const polygoncx = xPos;
						const polygoncy = yPos;
						const polygonspikes = polygoShapeSide >= 3 && polygoShapeSide <= 60 ? polygoShapeSide : 3;
						let polygonrot = (Math.PI / 2) * 3;
						const polygonstep = (Math.PI / polygonspikes) * 2;
						const polygonouterRadius = 50;

						for (let i = 0; i < polygonspikes; i++) {
							let x = polygoncx + Math.cos(polygonrot) * polygonouterRadius;
							let y = polygoncy + Math.sin(polygonrot) * polygonouterRadius;

							polygonVertices.push({
								x,
								y,
							});

							polygonrot += polygonstep;
						}

						const polygon = new fabric.Polygon(polygonVertices, {
							type: 'polygon',
							left: xPos - polygonouterRadius,
							top: yPos - polygonouterRadius,
							fill: 'transparent',
							stroke: editorActiveColor,
							strokeWidth: shapeStrokeWidth,
						});

						polygon.setControlsVisibility({
							ml: false,
							mr: false,
							mb: false,
							mt: false,
							mtr: false,
						});

						canvas.add(polygon);
						canvas.setActiveObject(polygon);

						break;
					case 'line':
						const line = new fabric.Line([xPos - 50, yPos, xPos + 50, yPos], {
							type: 'line',
							strokeWidth: shapeStrokeWidth,
							stroke: editorActiveColor,
						});

						line.setControlsVisibility({
							ml: true,
							mr: true,
							mb: true,
							mt: true,
							mtr: false,
						});

						canvas.add(line);
						canvas.setActiveObject(line);

						break;
					case 'ellipse':
						const ellipse = new fabric.Ellipse({
							type: 'ellipse',
							top: yPos - 50,
							left: xPos - 50,
							fill: 'transparent',
							stroke: editorActiveColor,
							strokeWidth: shapeStrokeWidth,
							rx: 50,
							ry: 50,
						});

						ellipse.setControlsVisibility({
							ml: true,
							mr: true,
							mb: true,
							mt: true,
							mtr: false,
						});

						canvas.add(ellipse);
						canvas.setActiveObject(ellipse);

						break;

					case 'arrow':
						const fromx = xPos - 50;
						const fromy = yPos;
						let tox = xPos + 50;
						let toy = yPos;

						var angle = Math.atan2(toy - fromy, tox - fromx);

						var headlen = 5; // arrow head size

						// bring the line end back some to account for arrow head.
						tox = tox - headlen * Math.cos(angle);
						toy = toy - headlen * Math.sin(angle);

						// calculate the points.
						var points = [
							{
								x: fromx, // start point
								y: fromy,
							},
							{
								x: fromx - (headlen / 4) * Math.cos(angle - Math.PI / 2),
								y: fromy - (headlen / 4) * Math.sin(angle - Math.PI / 2),
							},
							{
								x: tox - (headlen / 4) * Math.cos(angle - Math.PI / 2),
								y: toy - (headlen / 4) * Math.sin(angle - Math.PI / 2),
							},
							{
								x: tox - headlen * Math.cos(angle - Math.PI / 2) - headlen,
								y: toy - headlen * Math.sin(angle - Math.PI / 2) + headlen / 2,
							},
							{
								x: tox + headlen * Math.cos(angle), // tip
								y: toy + headlen * Math.sin(angle),
							},
							{
								x: tox - headlen * Math.cos(angle + Math.PI / 2) - headlen,
								y: toy - headlen * Math.sin(angle + Math.PI / 2) - headlen / 2,
							},
							{
								x: tox - (headlen / 4) * Math.cos(angle + Math.PI / 2),
								y: toy - (headlen / 4) * Math.sin(angle + Math.PI / 2),
							},
							{
								x: fromx - (headlen / 4) * Math.cos(angle + Math.PI / 2),
								y: fromy - (headlen / 4) * Math.sin(angle + Math.PI / 2),
							},
							{
								x: fromx,
								y: fromy,
							},
						];

						var pline = new fabric.Polyline(points, {
							type: 'arrow',
							fill: editorActiveColor,
							stroke: editorActiveColor,
							opacity: 1,
							strokeWidth: 1,
							originX: 'left',
							originY: 'top',
							selectable: true,
						});

						pline.setControlsVisibility({
							ml: true,
							mr: true,
							mb: true,
							mt: true,
							mtr: false,
						});

						canvas.add(pline);
						canvas.setActiveObject(pline);
						canvas.renderAll();

						break;
					case 'star':
						let startVertices = [];
						const cx = xPos;
						const cy = yPos;
						const spikes = starShapeSide >= 3 && starShapeSide <= 60 ? starShapeSide : 5;
						let rot = (Math.PI / 2) * 3;
						const step = Math.PI / spikes;
						const outerRadius = 50;
						const innerRadius = 20;

						for (let i = 0; i < spikes; i++) {
							let x = cx + Math.cos(rot) * outerRadius;
							let y = cy + Math.sin(rot) * outerRadius;

							startVertices.push({
								x,
								y,
							});

							rot += step;

							x = cx + Math.cos(rot) * innerRadius;
							y = cy + Math.sin(rot) * innerRadius;
							startVertices.push({
								x,
								y,
							});

							rot += step;
						}

						const star = new fabric.Polygon(startVertices, {
							type: 'star',
							left: xPos - outerRadius,
							top: yPos - outerRadius,
							fill: 'transparent',
							stroke: editorActiveColor,
							strokeWidth: shapeStrokeWidth,
						});

						star.setControlsVisibility({
							ml: false,
							mr: false,
							mb: false,
							mt: false,
							mtr: false,
						});

						canvas.add(star);
						canvas.setActiveObject(star);

						break;

					case 'cross':
						const crossSize = 200;
						const cross = new fabric.Polygon(
							[
								{
									x: xPos - crossSize / 4,
									y: yPos + crossSize / 4,
								},
								{
									x: xPos + crossSize / 4,
									y: yPos - crossSize / 4,
								},
								{ x: xPos, y: yPos },
								{
									x: xPos + crossSize / 4,
									y: yPos + crossSize / 4,
								},
								{
									x: xPos - crossSize / 4,
									y: yPos - crossSize / 4,
								},
								{ x: xPos, y: yPos },
								{
									x: xPos - crossSize / 4,
									y: yPos + crossSize / 4,
								},
							],
							{
								type: 'cross',
								left: xPos - crossSize / 4,
								top: yPos - crossSize / 4,
								fill: 'transparent',
								stroke: editorActiveColor,
								strokeWidth: shapeStrokeWidth,
							}
						);

						cross.setControlsVisibility({
							ml: false,
							mr: false,
							mb: false,
							mt: false,
							mtr: false,
						});

						canvas.add(cross);
						canvas.setActiveObject(cross);

						break;
				}
			}
		},
		[editor, starShapeSide, editorActiveColor, polygoShapeSide]
	);

	const updateColor = useCallback(
		(color) => {
			switch (currentEditingType) {
				case EditingType.DRAWING:
					if (editor?.canvas) {
						const canvas = editor?.canvas;
						canvas.freeDrawingBrush.color = color;
					}
					break;
				case EditingType.BACKGROUND:
					if (editor?.canvas) {
						const canvas: any = editor?.canvas;
						canvas.setBackgroundColor(color, canvas.requestRenderAllBound);
						historyArr.push({ type: 'background', value: color });
						setHistoryArr(historyArr);
					}
					break;
				case EditingType.TEXT:
					if (editor?.canvas) {
						const canvas = editor?.canvas;
						fabric.Object.prototype.objectCaching = false;

						var currentlySelected = canvas.getActiveObject();
						if (currentlySelected) {
							if (textBgChangeActive) {
								currentlySelected.backgroundColor = color;
							} else {
								currentlySelected.fill = color;
							}
							canvas.requestRenderAll();
						}
					}
					break;

				default:
					break;
			}
		},
		[editor, currentEditingType, historyArr, textBgChangeActive]
	);

	const stopEditing = useCallback(() => {
		const canvas = editor?.canvas;
		if (canvas) {
			canvas.isDrawingMode = false;
		}
		setIsEditing(false);
		setCurrentEditingType('');
	}, [editor]);

	const onOpenEmojiModalCallBack = useCallback(() => {
		activateEmojiMode(editor?.canvas);
	}, [editor, activateEmojiMode]);

	const onDeleteEmoji = useCallback(() => {
		if (editor?.canvas) {
			const canvas = editor?.canvas;
			var currentlySelected = canvas.getActiveObject();
			if (currentlySelected) {
				canvas.remove(currentlySelected);
			}
		}
	}, [editor]);

	const resetAllValue = useCallback(() => {
		setHistoryArr([]);
		setIsDeleteButtonShow(false);
		setIsEditing(true);
		setIsChangesMade(false);
		setCurrentEditingType(EditingType.DRAWING);
		setShowTextStylePopover(false);
		setTextStylePopoverEvent(null);
		setSelectedTextStyle(textStyles[1]);
		setShowShapeStylePopover(false);
		setShapeStylePopoverEvent(null);
		setSelectedShapeStyle(shapeStyles[0]);
		setIsEmojiModalShow(false);
		setEditorActiveColor(editorColors[0]);
		setTextBgChangeActive(false);
	}, []);

	const onDownloadImage = useCallback(() => {
		// setOpenSelectComponent(true);
		setIsSave(true);

		const canvas = editor?.canvas;
		if (canvas) {
			const downlaodImg = canvas.toDataURL({
				format: 'jpeg',
				quality: 0.95,
				multiplier: 1,
			});

			setTimeout(() => {
				var element = document.createElement('a');
				element.href = downlaodImg;
				element.download = 'image.jpg';
				element.click();
				store.dispatch(showStylishToast({ stylishToastMessage: 'Saved', stylishToastIcon: 'check' }));
				setIsSave(false);
			}, 1000);

			onSave && onSave(downlaodImg);

			resetAllValue();
		}
	}, [editor, onSave, resetAllValue]);
	const onMoreOptionClick = useCallback(() => {
		const canvas = editor?.canvas;
		if (canvas) {
			const downlaodImg = canvas.toDataURL({
				format: 'jpeg',
				quality: 0.95,
				multiplier: 1,
			});
			setSketchpadImg(downlaodImg);
		}
		resetAllValue();
	}, [editor, resetAllValue]);

	const onSendClick = useCallback(() => {
		const canvas = editor?.canvas;
		if (canvas) {
			const downlaodImg = canvas.toDataURL({
				format: 'jpeg',
				quality: 0.95,
				multiplier: 1,
			});
			setSketchpadImg(downlaodImg);

			var file = SharedService.dataURLtoFile(downlaodImg, 'sketchpad.png');
			resetAllValue();
			onSave && onSave(file);

			Navigation.personalNotepad({ receiver: sketchpadImg });
		}
		onCloseCallBack();
	}, [editor, resetAllValue, onSave, sketchpadImg]);

	const onCloseCallBack = useCallback(() => {
		resetAllValue();
		onCancel && onCancel();
	}, [onCancel, resetAllValue]);

	return (
		<>
			<IonModal mode="ios" showBackdrop={true} isOpen={show} onDidDismiss={onCloseCallBack} swipeToClose={true} cssClass="sketchpad-modal" animated={true}>
				<IonHeader className="ion-no-border">
					<IonToolbar className="top-toolbar">
						<IonButtons slot="start">
							<IonButton fill="clear" slot="icon-only" onClick={onCloseCallBack}>
								<IonIcon src={CloseIcon}></IonIcon>
							</IonButton>
						</IonButtons>

						<IonTitle>
							{currentEditingType === EditingType.DRAWING && 'Draw'}
							{currentEditingType === EditingType.EMOJII && 'Emoji'}
							{currentEditingType === EditingType.BACKGROUND && 'Background'}
							{currentEditingType === EditingType.TEXT && (
								<IonButton
									className="text-style-button"
									fill="clear"
									onClick={(event: any) => {
										setShowTextStylePopover(true);
										setTextStylePopoverEvent(event);
									}}
								>
									<IonIcon src={TextStyleIcon}></IonIcon>
									<div className="text">{selectedTextStyle.title}</div>
								</IonButton>
							)}

							{currentEditingType === EditingType.SHAPE && (
								<div className="shape-title-view">
									{selectedShapeStyle.value === 'polygon' && (
										<IonInput
											className="input"
											value={polygoShapeSide || ''}
											onIonChange={(e) => {
												const newValue = Math.min(Math.max(parseInt(e.detail.value!), 3), 60);
												setPolygoShapeSide(parseInt(e.detail.value!));

												setTimeout(() => {
													if (isNaN(newValue)) {
														setPolygoShapeSide(0);
													} else {
														setPolygoShapeSide(newValue);
													}
												}, 100);
											}}
											type="number"
											step="1"
											max="60"
											min="3"
											onIonBlur={() => {
												const newValue = Math.min(Math.max(polygoShapeSide, 3), 60);
												if (isNaN(newValue)) {
													setPolygoShapeSide(3);
												} else {
													setPolygoShapeSide(newValue);
												}
											}}
										></IonInput>
									)}
									{selectedShapeStyle.value === 'star' && (
										<IonInput
											className="input"
											value={starShapeSide || ''}
											type="number"
											step="1"
											min="3"
											max="60"
											onIonChange={(e) => {
												const newValue = Math.min(Math.max(parseInt(e.detail.value!), 3), 60);
												setStarShapeSide(parseInt(e.detail.value!));

												setTimeout(() => {
													if (isNaN(newValue)) {
														setStarShapeSide(0);
													} else {
														setStarShapeSide(newValue);
													}
												}, 100);
											}}
											onIonBlur={() => {
												const newValue = Math.min(Math.max(starShapeSide, 3), 60);
												if (isNaN(newValue)) {
													setStarShapeSide(3);
												} else {
													setStarShapeSide(newValue);
												}
											}}
										></IonInput>
									)}

									<IonButton
										fill="clear"
										className="shape-button"
										onClick={(event: any) => {
											onShapeChange(selectedShapeStyle);
										}}
									>
										<IonIcon slot="icon-only" src={selectedShapeStyle.iconActive}></IonIcon>
									</IonButton>
									<IonButton
										className="shape-style-button"
										fill="clear"
										onClick={(event: any) => {
											setShowShapeStylePopover(true);
											setShapeStylePopoverEvent(event);
										}}
									>
										<IonIcon slot="icon-only" className="shape-arrow-icon" src={chevronDown}></IonIcon>
									</IonButton>
								</div>
							)}
						</IonTitle>

						<IonButtons slot="end">{isChangesMade && RevertButton}</IonButtons>
					</IonToolbar>
				</IonHeader>

				<IonContent ref={contentRef}>
					<FabricJSCanvas className="sketchpad-canvas" onReady={onCanvasReady} />
				</IonContent>
				{!openSelectComponent && (
					<IonFooter className="ion-no-border">
						{!isEditing && (
							<div className="chat-btn-types">
								<IonButton
									// className={chatBtnType === 'send_in_chat' ? 'active' : ''}
									fill="clear"
									onClick={() => {
										onMoreOptionClick();
										setChatBtnType('send_in_chat');
										setOpenSelectComponent(true);
									}}
								>
									More
								</IonButton>
								<IonButton className={chatBtnType === 'notepad' ? 'active' : 'active'} fill="clear">
									Notepad
								</IonButton>
							</div>
						)}

						{currentEditingType !== EditingType.TEXT && isDeleteButtonShow && (
							<div className="delete-container">
								<IonButton fill="clear" slot="icon-only" onClick={onDeleteEmoji}>
									<IonIcon src={DeleteIcon} className="btdelete"></IonIcon>
								</IonButton>
							</div>
						)}

						{(currentEditingType === EditingType.TEXT || !isDeleteButtonShow) && (
							<>
								{isEditing && currentEditingType === EditingType.EMOJII && (
									<div className="arrowup-container">
										<IonButton fill="clear" slot="icon-only" onClick={onOpenEmojiModalCallBack}>
											<IonIcon src={ArrowUpIcon}></IonIcon>
										</IonButton>
									</div>
								)}
								<div className="bottom-tools-view">
									{isEditing && currentEditingType !== EditingType.EMOJII && (
										<div className="colors-container">
											{currentEditingType === EditingType.TEXT && (
												<div
													className={'text-bg-change-btn' + (textBgChangeActive ? ' active' : '')}
													onClick={() => {
														setTextBgChangeActive(!textBgChangeActive);
													}}
												>
													<IonIcon src={TextBgIcon}></IonIcon>
												</div>
											)}
											{editorColors.map((color, key) => {
												const active = editorActiveColor === color;
												return (
													<div
														onClick={() => {
															setEditorActiveColor(color);
															updateColor(color);
														}}
														className={'color-circle' + (active ? ' active' : '')}
														key={key}
														style={{
															backgroundColor: active ? 'transparent' : color,
															borderColor: color,
														}}
													></div>
												);
											})}
										</div>
									)}

									<div className="container">
										<div className="edit-options">
											{toolbarIcons.map((item, key) => {
												const active = currentEditingType === item.type;
												return (
													<IonButton
														fill="clear"
														key={key}
														className={'edit-type-btn drawing-btn' + (active ? ' active' : '')}
														onClick={() => {
															setCurrentEditingType(item.type);
															setIsEditing(true);
															setEditorActiveColor(editorColors[0]);

															switch (item.type) {
																case EditingType.DRAWING:
																	activateDrawingMode(editor?.canvas);
																	break;

																case EditingType.BACKGROUND:
																	activateBackgroundMode(editor?.canvas);

																	break;
																case EditingType.EMOJII:
																	activateEmojiMode(editor?.canvas);
																	break;
																case EditingType.TEXT:
																	activateTextMode(editor?.canvas);
																	break;
																case EditingType.SHAPE:
																	activateShapeMode(editor?.canvas);
																	break;

																default:
																	break;
															}
														}}
													>
														<IonIcon style={item.size} src={active ? item.iconActive : item.icon}></IonIcon>
													</IonButton>
												);
											})}
										</div>

										{isEditing ? (
											<IonButton disabled={!isChangesMade} fill="clear" className="send-btn" onClick={stopEditing}>
												Done
											</IonButton>
										) : (
											<IonButton
												fill="clear"
												className="send-btn"
												onClick={() => {
													setChatBtnType('notepad');
													onSendClick();
												}}
											>
												Send
											</IonButton>
										)}
									</div>
								</div>
							</>
						)}
					</IonFooter>
				)}
				<EmojiSearchModal
					show={isEmojiModalShow}
					onCancel={() => {
						setIsEmojiModalShow(false);
					}}
					onEmojiClick={onEmojiClick}
				></EmojiSearchModal>
				<ScketchpadTextStylePopover
					showPopover={showTextStylePopover}
					selectedValue={selectedTextStyle}
					onDismiss={() => {
						setShowTextStylePopover(false);
						setTextStylePopoverEvent(null);
					}}
					onItemSelect={(item: any) => {
						setSelectedTextStyle(item);
						setShowTextStylePopover(false);
						setTextStylePopoverEvent(null);
						onTextStyleChange(item);
					}}
					popoverEvent={textStylePopoverEvent}
					list={textStyles}
				/>

				<ScketchpadShapeStylePopover
					showPopover={showShapeStylePopover}
					selectedValue={selectedShapeStyle}
					onDismiss={() => {
						setShowShapeStylePopover(false);
						setShapeStylePopoverEvent(null);
					}}
					onItemSelect={(item: any) => {
						setSelectedShapeStyle(item);
						setShowShapeStylePopover(false);
						setShapeStylePopoverEvent(null);
						onShapeChange(item);
					}}
					popoverEvent={shapeStylePopoverEvent}
					list={shapeStyles}
				/>
				<SketchpadNavigation
					sketchpadImg={sketchpadImg}
					show={openSelectComponent}
					onCloseCallBack={() => {
						setOpenSelectComponent(false);
					}}
				/>
			</IonModal>
			<IonLoading isOpen={isSave} duration={500} message={'Saving'} />
		</>
	);
};

export default SketchPad;
