import { IonButton, IonModal, IonIcon } from '@ionic/react';
import React, { useCallback } from 'react';
import './EmojiSearchModal.scss';
import Picker from 'emoji-picker-react';

const ArrowDownIcon = './assets/icon/arrow-down.svg';

// use @giphy/js-fetch-api to fetch gifs, instantiate with your api key

interface EmojiSearchModalProps {
	show: boolean;
	onCancel?: any;
	onEmojiClick: any;
}

const EmojiSearchModal: React.FC<EmojiSearchModalProps> = ({ show, onCancel, onEmojiClick }) => {
	const onCloseCallBack = useCallback(() => {
		onCancel && onCancel();
	}, [onCancel]);

	return (
		<IonModal mode="ios" showBackdrop={true} isOpen={show} onDidDismiss={onCloseCallBack} swipeToClose={true} cssClass="emoji-search-modal" animated={true}>
			<div className="content">
				<div className="header">
					<IonButton fill="clear" slot="icon-only" onClick={onCloseCallBack}>
						<IonIcon src={ArrowDownIcon}></IonIcon>
					</IonButton>
				</div>

				<div className="emoji-container">
					<Picker onEmojiClick={onEmojiClick} disableSearchBar={false} disableSkinTonePicker={true} searchPlaceholder="Search Emoji" />
				</div>
			</div>
		</IonModal>
	);
};

export default EmojiSearchModal;
