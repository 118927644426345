import React from 'react';
import { IonContent, IonLabel, IonPage, IonToggle } from '@ionic/react';
import './style.scss';
import BottomNavbar from '../common/navbar/bottomNavbar';
import { RouteComponentProps } from 'react-router';
import { IonList, IonItem } from '@ionic/react';
import { locale } from '../../locales/local';
import { TopNavbar } from '../common/header/topbar';
import { Navigation } from '../../services/navigationService';

interface iProps extends RouteComponentProps<{ name: string }> {}

interface iState {}
class GeneralsPage extends React.Component<iProps, iState> {
	render() {
		return (
			<IonPage className="account-setting-page">
				<TopNavbar
					{...this.props}
					pageTitle={locale.global.general}
					showBack={true}
					hideSearchBar={true}
					onLeftButtonPress={() => {
						Navigation.back();
					}}
				/>

				<IonContent className="main-content-profile has-bottom-navbar has-topbar">
					<IonList className="profile-list-sec2" lines="none">
						<IonItem button lines="none" detail={false}>
							<IonLabel slot="start" color="light">
								<p className="edit-profile-start">{locale.global.dark_mode}</p>
							</IonLabel>
							<div className={'swtich-item'} slot="end">
								<IonToggle className="toggle-customised" mode="ios" />
							</div>
						</IonItem>
						<IonItem button lines="none" detail={true}>
							<IonLabel slot="start" color="light">
								<p className="edit-profile-start">{locale.global.language}</p>
							</IonLabel>
						</IonItem>
						<IonItem button lines="none" detail={true}>
							<IonLabel slot="start" color="light">
								<p className="edit-profile-start">{locale.global.text_size}</p>
							</IonLabel>
						</IonItem>

						<IonItem button lines="none" detail={true}>
							<IonLabel slot="start" color="light">
								<p className="edit-profile-start">{locale.global.background}</p>
							</IonLabel>
						</IonItem>
						<IonItem button lines="none" detail={true}>
							<IonLabel slot="start" color="light">
								<p className="edit-profile-start">{locale.global.photos_videos_files_calls}</p>
							</IonLabel>
						</IonItem>

						<IonItem button lines="none" detail={false}>
							<IonLabel slot="start" color="light">
								<p className="edit-profile-start">{locale.global.turn_off_speaker}</p>
							</IonLabel>
							<div className={'swtich-item'} slot="end">
								<IonToggle className="toggle-customised" mode="ios" />
							</div>
						</IonItem>

						<IonItem button lines="none" detail={true}>
							<IonLabel slot="start" color="light">
								<p className="edit-profile-start">{locale.global.manage_discover}</p>
							</IonLabel>
						</IonItem>

						<IonItem button lines="none" detail={true}>
							<IonLabel slot="start" color="light">
								<p className="edit-profile-start">{locale.global.tools}</p>
							</IonLabel>
						</IonItem>
						<IonItem
							button
							lines="none"
							detail={true}
							onClick={() => {
								Navigation.openDeleteAccount();
							}}
						>
							<IonLabel slot="start" color="light">
								<p className="edit-profile-start">{locale.global.delete_account}</p>
							</IonLabel>
						</IonItem>
						<IonItem button lines="none" detail={true}>
							<IonLabel slot="start" color="light">
								<p className="edit-profile-start">{locale.global.clear_all_chats}</p>
							</IonLabel>
						</IonItem>
					</IonList>
				</IonContent>
				<BottomNavbar unreadCount={0} {...this.props} />
			</IonPage>
		);
	}
}

export default GeneralsPage;
