import React from 'react';
import { IonButton, IonContent, IonPage, IonIcon, IonCheckbox } from '@ionic/react';
import './personalfolder.scss';
import { TopNavbar } from '../../common/header/topbar';
import { RouteComponentProps } from 'react-router';
import BottomNavbar from '../navbar/bottomNavbar';
import SelectFileModal from '../SelectFileModal';
import SelectComponent from '../SelectComponent';
import { Navigation } from '../../../services/navigationService';
import { PersonalFolderShowType } from '../../../services/enumService';
import { PersonalFolderNavParams } from '../../../types/navigation';
const CloseIcon = './assets/icon/Close.svg';

const folderList = [
	{
		image: 'Vector-folder.svg',
		isShared: true,
		isFavorite: true,
		FolderName: 'Folder-Name',
		FolderSize: '0.0 KB,Date',
		isSelected: false,
		isChecked: false,
	},
	{
		image: 'Vector-folder.svg',
		isShared: true,
		isFavorite: false,
		FolderName: 'Folder-Name',
		FolderSize: '0.0 KB,Date',
		isSelected: false,
		isChecked: false,
	},
	{
		image: 'Vector-folder.svg',
		isShared: false,
		isFavorite: true,
		FolderName: 'Folder-Name',
		FolderSize: '0.0 KB,Date',
		isSelected: false,
		isChecked: false,
	},
	{
		image: 'Vector-folder.svg',
		isShared: false,
		isFavorite: true,
		FolderName: 'Folder-Name',
		FolderSize: '0.0 KB,Date',
		isSelected: false,
		isChecked: false,
	},
	{
		image: 'Vector-folder.svg',
		isShared: false,
		isFavorite: true,
		FolderName: 'Folder-Name',
		FolderSize: '0.0 KB,Date',
		isSelected: false,
		isChecked: false,
	},
	{
		image: 'Vector-folder.svg',
		isShared: false,
		isFavorite: true,
		FolderName: 'Folder-Name',
		isSelected: false,
		isChecked: false,
	},
	{
		image: 'Vector-folder.svg',
		isShared: false,
		isFavorite: true,
		FolderName: 'folder-Name',
		FolderSize: '0.0 KB,Date',
		isSelected: false,
		isChecked: false,
	},
	{
		image: 'Vector-folder.svg',
		isShared: false,
		isFavorite: true,
		FolderName: 'folder-Name',
		FolderSize: '0.0 KB,Date',
		isSelected: false,
		isChecked: false,
	},
	{
		image: 'Vector-folder.svg',
		isShared: false,
		isFavorite: true,
		FolderName: 'folder-Name',
		FolderSize: '0.0 KB,Date',
		isSelected: false,
		isChecked: false,
	},
	{
		image: 'Vector-folder.svg',
		isShared: false,
		isFavorite: true,
		FolderName: 'folder-Name',
		FolderSize: '0.0 KB,Date',
		isSelected: false,
		isChecked: false,
	},
	{
		image: 'Vector-folder.svg',
		isShared: false,
		isFavorite: true,
		FolderName: 'folder-Name',
		FolderSize: '0.0 KB,Date',
		isSelected: false,
		isChecked: false,
	},
	{
		image: 'Vector-folder.svg',
		isShared: false,
		isFavorite: true,
		FolderName: 'folder-Name',
		FolderSize: '0.0 KB,Date',
		isSelected: false,
		isChecked: false,
	},
];
const imageList = [
	{
		image: 'pic-1.png',
		isShared: true,
		isFavorite: true,
		ImageName: 'IMAGE-NAME.jpg',
		ImageSize: '0.0 KB,Date',
		isSelected: false,
	},
	{
		image: 'Crean.svg',
		isShared: true,
		isFavorite: false,
		ImageName: 'IMAGE-NAME.jpg',
		ImageSize: '0.0 KB,Date',
		isSelected: false,
	},
	{
		image: 'pic-1.png',
		isShared: true,
		isFavorite: true,
		ImageName: 'IMAGE-NAME.jpg',
		ImageSize: '0.0 KB,Date',
		isSelected: false,
	},
	{
		image: 'Crean.svg',
		isShared: false,
		isFavorite: true,
		ImageName: 'IMAGE-NAME.jpg',
		ImageSize: '0.0 KB,Date',
		isSelected: false,
	},
	{
		image: 'pic-1.png',
		isShared: false,
		isFavorite: true,
		ImageName: 'IMAGE-NAME.jpg',
		ImageSize: '0.0 KB,Date',
		isSelected: false,
	},
	{
		image: 'Crean.svg',
		isShared: false,
		isFavorite: true,
		ImageName: 'IMAGE-NAME.jpg',
		ImageSize: '0.0 KB,Date',
		isSelected: false,
	},
	{
		image: 'Crean.svg',
		isShared: false,
		isFavorite: true,
		ImageName: 'IMAGE-NAME.jpg',
		ImageSize: '0.0 KB,Date',
		isSelected: false,
	},
	{
		image: 'pic-1.png',
		isShared: false,
		isFavorite: true,
		ImageName: 'IMAGE-NAME.jpg',
		ImageSize: '0.0 KB,Date',
		isSelected: false,
	},
	{
		image: 'Crean.svg',
		isShared: false,
		isFavorite: true,
		ImageName: 'IMAGE-NAME.jpg',
		ImageSize: '0.0 KB,Date',
		isSelected: false,
	},
	{
		image: 'pic-1.png',
		isShared: false,
		isFavorite: true,
		ImageName: 'IMAGE-NAME.jpg',
		ImageSize: '0.0 KB,Date',
		isSelected: false,
	},
	{
		image: 'Crean.svg',
		isShared: false,
		isFavorite: true,
		ImageName: 'IMAGE-NAME.jpg',
		ImageSize: '0.0 KB,Date',
		isSelected: false,
	},
	{
		image: 'pic-1.png',
		isShared: false,
		isFavorite: true,
		ImageName: 'IMAGE-NAME.jpg',
		ImageSize: '0.0 KB,Date',
		isSelected: false,
	},
];

const videoList = [
	{
		image: 'videoimage.svg',
		isShared: true,
		isFavorite: false,
		isPlay: true,
		VideoName: 'VIDEO-NAME.mov',
		VideoSize: '0.0 KB,Date',
		isSelected: false,
	},
	{
		image: 'videoimage.svg',
		isShared: true,
		isFavorite: false,
		isPlay: true,
		VideoName: 'VIDEO-NAME.mov',
		VideoSize: '0.0 KB,Date',
		isSelected: false,
	},
	{
		image: 'videoimage.svg',
		isShared: true,
		isFavorite: false,
		isPlay: true,
		VideoName: 'VIDEO-NAME.mov',
		VideoSize: '0.0 KB,Date',
		isSelected: false,
	},
	{
		image: 'videoimage.svg',
		isShared: false,
		isFavorite: true,
		isPlay: true,
		VideoName: 'VIDEO-NAME.mov',
		VideoSize: '0.0 KB,Date',
		isSelected: false,
	},
	{
		image: 'videoimage.svg',
		isShared: true,
		isFavorite: false,
		isPlay: true,
		VideoName: 'VIDEO-NAME.mov',
		VideoSize: '0.0 KB,Date',
		isSelected: false,
	},
	{
		image: 'videoimage.svg',
		isShared: false,
		isFavorite: true,
		isPlay: true,
		VideoName: 'VIDEO-NAME.mov',
		VideoSize: '0.0 KB,Date',
		isSelected: false,
	},
	{
		image: 'videoimage.svg',
		isShared: false,
		isFavorite: true,
		isPlay: true,
		VideoName: 'VIDEO-NAME.mov',
		VideoSize: '0.0 KB,Date',
		isSelected: false,
	},
	{
		image: 'videoimage.svg',
		isShared: false,
		isFavorite: true,
		isPlay: true,
		VideoName: 'VIDEO-NAME.mov',
		VideoSize: '0.0 KB,Date',
		isSelected: false,
	},
	{
		image: 'videoimage.svg',
		isShared: false,
		isFavorite: true,
		isPlay: true,
		VideoName: 'VIDEO-NAME.mov',
		VideoSize: '0.0 KB,Date',
		isSelected: false,
	},
	{
		image: 'videoimage.svg',
		isShared: false,
		isFavorite: true,
		isPlay: true,
		VideoName: 'VIDEO-NAME.mov',
		VideoSize: '0.0 KB,Date',
		isSelected: false,
	},
	{
		image: 'videoimage.svg',
		isShared: false,
		isFavorite: true,
		isPlay: true,
		VideoName: 'VIDEO-NAME.mov',
		VideoSize: '0.0 KB,Date',
		isSelected: false,
	},
	{
		image: 'videoimage.svg',
		isShared: false,
		isFavorite: true,
		isPlay: true,
		VideoName: 'VIDEO-NAME.mov',
		VideoSize: '0.0 KB,Date',
		isSelected: false,
	},
];
const fileList = [
	{
		image: 'Vector-1.svg',
		isShared: true,
		isFavorite: false,
		FileName: 'File Name.pdf',
		FileSize: '0.0 KB,Date',
		isSelected: false,
	},
	{
		image: 'Vector-1.svg',
		isShared: true,
		isFavorite: false,
		FileName: 'File Name.pdf',
		FileSize: '0.0 KB,Date',
		isSelected: false,
	},
	{
		image: 'Vector-1.svg',
		isShared: true,
		isFavorite: false,
		FileName: 'File Name.pdf',
		FileSize: '0.0 KB,Date',
		isSelected: false,
	},
	{
		image: 'PDF.svg',
		isShared: true,
		isFavorite: true,
		FileName: 'Folder-name.pdf',
		FileSize: '0.0 KB,Date',
		isSelected: false,
	},
	{
		image: 'EXCEL.svg',
		isShared: true,
		isFavorite: false,
		FileName: 'folder-name.pdf',
		FileSize: '0.0 KB,Date',
		isSelected: false,
	},
	{
		image: 'PPT.svg',
		isShared: false,
		isFavorite: true,
		FileSize: '0.0 KB,Date',
		FileName: 'folder-name.pdf',
		isSelected: false,
	},
	{
		image: 'Vector-1.svg',
		isShared: false,
		isFavorite: true,
		FileName: 'folder-name.pdf',
		FileSize: '0.0 KB,Date',
		isSelected: false,
	},
	{
		image: 'PDF.svg',
		isShared: false,
		isFavorite: true,
		FileName: 'folder-name.pdf',
		FileSize: '0.0 KB,Date',
		isSelected: false,
	},
	{
		image: 'EXCEL.svg',
		isShared: false,
		isFavorite: true,
		FileName: 'folder-name.pdf',
		FileSize: '0.0 KB,Date',
		isSelected: false,
	},
	{
		image: 'PPT.svg',
		isShared: false,
		isFavorite: true,
		FileName: 'folder-name.pdf',
		FileSize: '0.0 KB,Date',
		isSelected: false,
	},
];

// const newfolderList = folderList.map((item: any, key: any) => ({
// 	...item,
// }));

interface iProps {
	show: boolean;
	props?: any;
	onCloseCallBack: any;
	type: string;
	selectedType: string;
	location: any;
}

interface iProps extends RouteComponentProps<{ name: string }> {}

interface iState {
	selectedThread: any;
	selectedTab: any;
	displayType: string;
	isSelectMode: boolean;
	isUpdate: boolean;
	isModal: boolean;
	selectItem: any;
	onsearch: boolean;
	object: object;
	isArrowDown: boolean;
	inputVal: any;
}

class PersonalFolder extends React.Component<iProps, iState> {
	navParams: PersonalFolderNavParams = {} as any;
	componentIsMounted: Boolean = false;

	constructor(props: any) {
		super(props);

		this.state = {
			selectedThread: '',
			selectedTab: '',
			displayType: 'grid', //display type grid,list
			isSelectMode: false, //select type
			isUpdate: false,
			isModal: false,
			selectItem: null,
			onsearch: false,
			object: {},
			isArrowDown: false,
			inputVal: '',
		};
	}

	componentDidMount() {
		this.componentIsMounted = true;

		Navigation.getNavParamData(this.props).then((res: any) => {
			this.navParams = res;
			let datatype: any = this.navParams.showFolderType;
			let threadName: any = this.navParams.data;
			this.setState({
				selectedTab: datatype, //folder,image,video,file
				selectedThread: threadName,
			});
		});
	}

	componentWillUnmount() {
		this.componentIsMounted = false;
	}

	componentDidUpdate() {
		// let datatype: any = this.props.history.location.state;
	}

	handleInput1Change = (e: any) => {
		this.setState({ inputVal: e.target.value });
	};

	activeFolder(item: any) {
		if (this.state.isSelectMode) {
			item.isSelected = !item.isSelected;
			this.setState({
				isUpdate: !this.state.isUpdate,
			});
		}
	}

	selectedMod() {
		if (!this.state.isSelectMode) {
			Navigation.back();
			return;
		}

		if (this.state.selectedTab === 'folder') {
			folderList.map((item) => {
				item.isSelected = false;
				return item;
			});

			this.setState({ isUpdate: !this.state.isUpdate });
		} else if (this.state.selectedTab === 'image') {
			imageList.map((item) => {
				item.isSelected = false;
				return item;
			});

			this.setState({ isUpdate: !this.state.isUpdate });
		} else if (this.state.selectedTab === 'video') {
			videoList.map((item) => {
				item.isSelected = false;
				return item;
			});

			this.setState({ isUpdate: !this.state.isUpdate });
		} else if (this.state.selectedTab === 'file') {
			fileList.map((item) => {
				item.isSelected = false;
				return item;
			});

			this.setState({ isUpdate: !this.state.isUpdate });
		}
	}

	_tabItem = (title: string, type: string) => {
		return (
			<div className="tabitem ">
				<IonButton
					fill="clear"
					onClick={() => {
						this.setState({ selectedTab: type });
					}}
					className={this.state.selectedTab === type ? 'active' : ''}
				>
					{title}
				</IonButton>
			</div>
		);
	};

	render(): React.ReactNode {
		let pageTitle = '';
		let rightButtonText = '';
		if (this.state.selectedTab === PersonalFolderShowType.ThreadDetail) {
			rightButtonText = 'Select';
		} else if (this.state.onsearch) {
			rightButtonText = 'Cancel';
		}
		if (this.state.selectedTab === PersonalFolderShowType.ThreadDetail) {
			pageTitle = this.state.selectedThread.threadName;
		} else if (this.state.selectedTab === PersonalFolderShowType.Folder) {
			pageTitle = 'Folders';
		} else if (this.state.selectedTab === PersonalFolderShowType.Image) {
			pageTitle = 'Images';
		} else if (this.state.selectedTab === PersonalFolderShowType.Video) {
			pageTitle = 'Videos';
		} else if (this.state.selectedTab === PersonalFolderShowType.File) {
			pageTitle = 'Files';
		}

		let selectedItems: any = [];
		let List: any;
		let selectedName: any;
		switch (this.state.selectedTab) {
			case 'folder':
				List = folderList.length;
				selectedItems = folderList.filter((item) => item.isSelected);
				break;
			case 'image':
				List = imageList.length;
				selectedItems = imageList.filter((item) => item.isSelected);
				break;
			case 'video':
				List = videoList.length;
				selectedItems = videoList.filter((item) => item.isSelected);
				break;
			case 'file':
				List = fileList.length;
				selectedItems = fileList.filter((item) => item.isSelected);
				break;

			default:
				break;
		}

		// Star filter code
		// let selectedStar: any = [];
		// let StarList: any;
		// let selectedName: any
		// switch (this.state.selectedTab) {
		// 	case 'folder':
		// 		StarList = folderList.length;
		// 		selectedStar = folderList.filter((item) => item.isFavorite);
		// 		break;
		// case 'image':
		// 	StarList = imageList.length;
		// 	selectedStar = imageList.filter((item) => item.isFavorite);
		// 	break;
		// case 'video':
		// 	StarList = videoList.length;
		// 	selectedStar = videoList.filter((item) => item.isFavorite);
		// 	break;
		// 	default:
		// 		break;
		// }

		// end star

		// const style = {
		// 	transform: this.state.isArrowDown ? 'rotate(180deg)' : '',
		// 	transition: 'transform 150ms ease', // smooth transition
		// };
		return (
			<IonPage className="personal-folder">
				<TopNavbar
					isSearchButtonActive={this.state.onsearch}
					onSearchIconPress={() => {
						this.setState({ onsearch: !this.state.onsearch, isSelectMode: false });
					}}
					showSearchIcon={this.state.selectedTab === PersonalFolderShowType.ThreadDetail ? false : true}
					leftButtonIcon={CloseIcon}
					showBack={this.state.isSelectMode || this.state.onsearch ? false : true}
					hideSearchBar={this.state.onsearch ? false : true}
					rightButtonText={rightButtonText}
					isHideRightButton={false}
					isRightButtonActive={this.state.isSelectMode}
					onRightButtonPress={() => {
						if (this.state.isSelectMode) this.selectedMod();
						this.setState({
							isSelectMode: !this.state.isSelectMode,
							onsearch: false,
						});
					}}
					onLeftButtonPress={() => {
						this.selectedMod();
						this.setState({
							isSelectMode: false,
							onsearch: false,
							// this.handleInput1Change();
						});
					}}
					pageTitle={pageTitle}
					pageTitleDescription={this.state.selectedTab === PersonalFolderShowType.ThreadDetail ? '130.2 MB' : '137.5 GB'}
					{...this.props}
				/>
				{this.state.selectedTab === PersonalFolderShowType.ThreadDetail && <div className="thread-title">Octobar 2022</div>}

				<IonContent className="has-topbar has-bottom-navbar">
					{/* {this.state.selectedTab === PersonalFolderShowType.File ||
						this.state.selectedTab === PersonalFolderShowType.Folder ||
						this.state.selectedTab === PersonalFolderShowType.Image ||
						(this.state.selectedTab === PersonalFolderShowType.Video && ( */}
					{this.state.selectedTab === PersonalFolderShowType.ThreadDetail ? (
						''
					) : (
						<div className={this.state.onsearch ? 'tabs-hide' : 'tabs'}>
							{this._tabItem('Folders', 'folder')}
							{this._tabItem('Images', 'image')}
							{this._tabItem('Videos', 'video')}
							{this._tabItem('Files', 'file')}
						</div>
					)}
					{this.state.selectedTab === PersonalFolderShowType.ThreadDetail ? (
						''
					) : !this.state.onsearch ? (
						<div className="first">
							{!this.state.onsearch ? (
								<div className="videoimage">
									Upload Date
									<div className="container">
										<img
											alt=""
											// className="arrowup"
											src="../assets/icon/Arrowup.svg"
											onClick={() => {
												this.setState({ isArrowDown: !this.state.isArrowDown });
											}}
											className={this.state.isArrowDown ? 'arrowdown' : 'arrowup'}
										></img>
									</div>
								</div>
							) : (
								''
							)}

							<div
								className="menu"
								onClick={() => {
									if (this.state.displayType === 'grid') {
										this.setState({
											displayType: 'list',
										});
									} else if (this.state.displayType === 'list') {
										this.setState({
											displayType: 'grid',
										});
									}
								}}
							>
								{this.state.displayType === 'grid' ? <img alt="" className="inner-menu" src="../assets/icon/Menu.svg"></img> : <img alt="" className="inner-menu" src="../assets/icon/Listicon.svg"></img>}
							</div>
						</div>
					) : (
						''
					)}
					{this.state.selectedTab === PersonalFolderShowType.Folder && (
						<>
							{this.state.displayType === 'grid' ? (
								this.state.selectedTab === PersonalFolderShowType.ThreadDetail ? (
									''
								) : (
									<div className="folder-container" id="folderid">
										{folderList.map((item: any, key: any) => {
											return (
												// className={this.state.selectedTab === type ? 'active' : ''}

												<div
													onClick={() => {
														this.activeFolder(item);
													}}
													className={'folder-item ' + (this.state.isSelectMode ? 'folder-item-active' : '') + (item.isSelected ? ' after-select' : '')}
													key={key}
												>
													<div className="folder-image-container">
														<IonIcon className="folder-img" src={'../assets/img/' + item.image}></IonIcon>
														<div className="like">
															{item.isFavorite && <img alt="" className="inner-star" src={'../assets/img/Star-3.svg'}></img>}
															{item.isShared && <img alt="" className="inner-shared" src={'../assets/icon/profile.svg'}></img>}
														</div>
													</div>
													{this.state.isSelectMode ? (
														<IonCheckbox className="check" slot="end" color="primary" checked={item.isSelected} />
													) : (
														<img
															className="folder-dot"
															alt=""
															src={'../assets/icon/3doticon.svg'}
															onClick={() => {
																this.setState({ isModal: true });
																this.setState({ selectItem: item });
															}}
														></img>
													)}

													<div className="folder_name">{item.FolderName}</div>
												</div>
											);
										})}

										{[0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10].map((item: any, key: any) => {
											return <div className="folder-extra-bank" key={key}></div>;
										})}
									</div>
								)
							) : (
								<div className="folderlist-container">
									{folderList.map((item: any, key: any) => {
										return (
											<div
												onClick={() => {
													this.activeFolder(item);
												}}
												className={'folderlist-item ' + (this.state.isSelectMode ? 'folderlist-item-active' : '') + (item.isSelected ? ' after-select' : '')}
												key={key}
											>
												{this.state.isSelectMode ? (
													<IonCheckbox className="check" slot="start" color="primary" checked={item.isSelected} />
												) : (
													<img
														className="folderlist-dot"
														alt=""
														src={'../assets/icon/3doticon.svg'}
														onClick={() => {
															this.setState({ isModal: true });
															this.setState({ selectItem: item });
														}}
													></img>
												)}

												<div className="folderlist-img-div">
													<IonIcon className="folderlist-img" src={'../assets/img/' + item.image}></IonIcon>
												</div>

												<div className="folder-list">
													<div className="folderlist_name">{item.FolderName}</div>
													<div className="like">
														{item.isFavorite && <img alt="" className="inner-star" src={'../assets/icon/StarList.svg'}></img>}
														{item.isShared && <img alt="" className="inner-shared" src={'../assets/icon/profileList.svg'}></img>}
														<div className="foldersize_name">{item.FolderSize}</div>
													</div>
												</div>
												{/* <img
                          className="folderlist-dot"
                          src={'../assets/icon/3doticon.svg'}
                          onClick={() => {
                            this.setState({ isModal: true })
                            this.setState({ selectItem: item })
                          }}
                        ></img> */}
											</div>
										);
									})}
								</div>
							)}
						</>
					)}
					{''}
					{this.state.selectedTab === PersonalFolderShowType.Image || this.state.selectedTab === PersonalFolderShowType.ThreadDetail ? (
						<>
							{this.state.displayType === 'grid' ? (
								<div className="image-container" id="imageid">
									{imageList.map((item: any, key: any) => {
										return (
											<div
												onClick={() => {
													this.activeFolder(item);
												}}
												className={'image-item ' + (this.state.isSelectMode ? 'image-item-active' : '') + (item.isSelected ? ' after-select' : '')}
												key={key}
											>
												{/* <div className="imgage-item" key={key}> */}
												<img alt="" className="image-img" src={'../assets/img/' + item.image}></img>
												{this.state.isSelectMode ? (
													<IonCheckbox className="check" slot="end" color="primary" checked={item.isSelected} />
												) : (
													<img
														className="image-dot"
														alt=""
														src={'../assets/icon/3DotIcon-White.svg'}
														onClick={() => {
															this.setState({ isModal: true });
															this.setState({ selectItem: item });
														}}
													></img>
												)}

												<div className="like">
													{item.isFavorite && <img alt="" className="inner-star" src={'../assets/img/Star-3.svg'}></img>}
													{item.isShared && <img alt="" className="inner-shared" src={'../assets/icon/profile.svg'}></img>}
												</div>
											</div>
										);
									})}
								</div>
							) : (
								<div className="folderlist-container">
									{imageList.map((item: any, key: any) => {
										return (
											<div
												onClick={() => {
													this.activeFolder(item);
												}}
												className={'folderlist-item ' + (this.state.isSelectMode ? 'folderlist-item-active' : '') + (item.isSelected ? ' after-select' : '')}
												key={key}
											>
												{this.state.isSelectMode ? (
													<IonCheckbox className="check" slot="start" color="primary" checked={item.isSelected} />
												) : (
													<img
														className="folderlist-dot"
														alt=""
														src={'../assets/icon/3doticon.svg'}
														onClick={() => {
															this.setState({ isModal: true });
															this.setState({ selectItem: item });
														}}
													></img>
												)}

												<div className="folderlist-img-div">
													<img alt="" className="imagelist-img" src={'../assets/img/' + item.image}></img>
												</div>
												<div className="folder-list">
													<div className="folderlist_name">{item.ImageName}</div>
													<div className="like">
														{item.isFavorite && <img alt="" className="inner-star" src={'../assets/icon/StarList.svg'}></img>}
														{item.isShared && <img alt="" className="inner-shared" src={'../assets/icon/profileList.svg'}></img>}
														<div className="foldersize_name">{item.ImageSize}</div>
													</div>
												</div>
												{/* <img
                          className="folderlist-dot"
                          src={'../assets/icon/3doticon.svg'}
                          onClick={() => {
                            this.setState({ isModal: true })
                            this.setState({ selectItem: item })
                          }}
                        ></img> */}
											</div>
										);
									})}
								</div>
							)}
						</>
					) : (
						''
					)}
					{this.state.selectedTab === PersonalFolderShowType.Video && (
						<>
							{this.state.displayType === 'grid' ? (
								<div className="video-container" id="videoid">
									{videoList.map((item: any, key: any) => {
										return (
											<div
												onClick={() => {
													this.activeFolder(item);
												}}
												className={'video-item ' + (this.state.isSelectMode ? 'video-item-active' : '') + (item.isSelected ? ' after-select' : '')}
												key={key}
											>
												<img alt="" className="video-img" src={'../assets/img/' + item.image}></img>

												<div className="innerdiv-play">
													<div className="duration">0:38</div>

													{item.isPlay && <img alt="" className="inner-play" src={'../assets/img/Group.svg'}></img>}
												</div>

												{this.state.isSelectMode ? (
													<IonCheckbox className="check" slot="end" color="primary" checked={item.isSelected} />
												) : (
													<img
														src={'../assets/icon/3DotIcon-White.svg'}
														alt=""
														className="video-dot"
														onClick={() => {
															this.setState({ isModal: true });
															this.setState({ selectItem: item });
														}}
													></img>
												)}

												<div className="like">
													{item.isFavorite && <img alt="" className="inner-star" src={'../assets/img/Star-3.svg'}></img>}
													{item.isShared && <img alt="" className="inner-shared" src={'../assets/icon/profile.svg'}></img>}
												</div>
											</div>
										);
									})}
								</div>
							) : (
								<div className="folderlist-container">
									{videoList.map((item: any, key: any) => {
										return (
											<div
												onClick={() => {
													this.activeFolder(item);
												}}
												className={'folderlist-item ' + (this.state.isSelectMode ? 'folderlist-item-active' : '') + (item.isSelected ? ' after-select' : '')}
												key={key}
											>
												{this.state.isSelectMode ? (
													<IonCheckbox className="check" slot="start" color="primary" checked={item.isSelected} />
												) : (
													<img
														className="folderlist-dot"
														alt=""
														src={'../assets/icon/3doticon.svg'}
														onClick={() => {
															this.setState({ isModal: true });
															this.setState({ selectItem: item });
														}}
													></img>
												)}

												<div className="folderlist-img-div">
													<img alt="" className="imagelist-img" src={'../assets/img/' + item.image}></img>
													<div className="innerdiv-play">{item.isPlay && <img alt="" className="inner-play" src={'../assets/img/Group.svg'}></img>}</div>
												</div>
												<div className="folder-list">
													<div className="folderlist_name">{item.VideoName}</div>
													<div className="like">
														{item.isFavorite && <img alt="" className="inner-star" src={'../assets/icon/StarList.svg'}></img>}
														{item.isShared && <img alt="" className="inner-shared" src={'../assets/icon/profileList.svg'}></img>}
														<div className="foldersize_name">{item.VideoSize}</div>
													</div>
												</div>
												{/* <img
                          className="folderlist-dot"
                          src={'../assets/icon/3doticon.svg'}
                          onClick={() => {
                            this.setState({ isModal: true })
                            this.setState({ selectItem: item })
                          }}
                        ></img> */}
											</div>
										);
									})}
								</div>
							)}
						</>
					)}{' '}
					{this.state.selectedTab === PersonalFolderShowType.File && (
						<>
							{this.state.displayType === 'grid' ? (
								<div className="folder-container" id="fileid">
									{fileList.map((item: any, key: any) => {
										return (
											<div
												onClick={() => {
													this.activeFolder(item);
												}}
												className={'folder-item ' + (this.state.isSelectMode ? 'folder-item-active' : '') + (item.isSelected ? ' after-select' : '')}
												key={key}
											>
												<>
													<div className="file-image-container">
														{/* <div className="folder-item" key={key}> */}
														<img alt="" className="file-img" src={'../assets/img/' + item.image}></img>
													</div>
												</>

												{this.state.isSelectMode ? (
													<IonCheckbox className="check" slot="end" color="primary" checked={item.isSelected} />
												) : (
													<img
														className="folder-dot"
														alt=""
														src={'../assets/icon/3doticon.svg'}
														onClick={() => {
															this.setState({ isModal: true });
															this.setState({ selectItem: item });
														}}
													></img>
												)}

												<div className="folder_name">{item.FileName}</div>
											</div>
										);
									})}

									{[0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10].map((item: any, key: any) => {
										return <div className="folder-extra-bank" key={key}></div>;
									})}
								</div>
							) : (
								<div className="folderlist-container">
									{fileList.map((item: any, key: any) => {
										return (
											<div
												onClick={() => {
													this.activeFolder(item);
												}}
												className={'folderlist-item ' + (this.state.isSelectMode ? 'folderlist-item-active' : '') + (item.isSelected ? ' after-select' : '')}
												key={key}
											>
												{this.state.isSelectMode ? (
													<IonCheckbox className="check" slot="start" color="primary" checked={item.isSelected} />
												) : (
													<img
														className="folderlist-dot"
														alt=""
														src={'../assets/icon/3doticon.svg'}
														onClick={() => {
															this.setState({ isModal: true });
															this.setState({ selectItem: item });
														}}
													></img>
												)}

												<div className="folderlist-img-div">
													<img alt="" className="imagelist-img" src={'../assets/img/' + item.image}></img>
												</div>
												<div className="folder-list">
													<div className="folderlist_name">{item.FileName}</div>
													<div className="like">
														{item.isFavorite && <img alt="" className="inner-star" src={'../assets/icon/StarList.svg'}></img>}
														{item.isShared && <img alt="" className="inner-shared" src={'../assets/icon/profileList.svg'}></img>}
														<div className="foldersize_name">{item.FileSize}</div>
													</div>
												</div>
												{/* <img
                          className="folderlist-dot"
                          src={'../assets/icon/3doticon.svg'}
                          onClick={() => {
                            this.setState({ isModal: true })
                            this.setState({ selectItem: item })
                          }}
                        ></img> */}
											</div>
										);
									})}
								</div>
							)}
						</>
					)}
				</IonContent>

				{this.state.isSelectMode ? (
					<SelectComponent
						useFor={'selection'}
						List={List}
						selectedName={selectedName}
						selectedItems={selectedItems}
						onSelectAll={() => {
							if (this.state.selectedTab === 'folder') {
								folderList.map((item) => {
									item.isSelected = true;
									return item;
								});

								this.setState({ isUpdate: !this.state.isUpdate });
							} else if (this.state.selectedTab === 'image') {
								imageList.map((item) => {
									item.isSelected = true;
									return item;
								});

								this.setState({ isUpdate: !this.state.isUpdate });
							} else if (this.state.selectedTab === 'video') {
								videoList.map((item) => {
									item.isSelected = true;
									return item;
								});

								this.setState({ isUpdate: !this.state.isUpdate });
							} else if (this.state.selectedTab === 'file') {
								fileList.map((item) => {
									item.isSelected = true;
									return item;
								});

								this.setState({ isUpdate: !this.state.isUpdate });
							}
						}}
						item={this.state.selectItem}
						selectedTab={this.state.selectedTab}
						onCloseCallBack={() => {
							this.setState({ isModal: false });
						}}
						onToastCallBack={() => {
							this.setState({ isModal: false, selectItem: null });
						}}
					/>
				) : this.state.selectedTab === PersonalFolderShowType.ThreadDetail ? (
					<div className="select-filter-mode">
						<div className="select-all">Select All</div>
						<div className="filter-container">
							<div className="filter">Filter Mode:</div>
							<div className="all-by-size">All By Size</div>
						</div>
						<div className="clear">Clear</div>
					</div>
				) : (
					<BottomNavbar
						unreadCount={0}
						{...this.props}
						isPressStar={() => {
							this.setState({});
						}}
					/>
				)}
				<SelectFileModal
					item={this.state.selectItem}
					show={this.state.isModal}
					type={this.state.selectedTab}
					onCloseCallBack={() => {
						this.setState({ isModal: false, selectItem: null });
					}}
					onToastCallBack={() => {
						this.setState({ isModal: false });
					}}
				/>
			</IonPage>
		);
	}
}

export default PersonalFolder;
