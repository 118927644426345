import { IonBadge } from '@ionic/react';
import React from 'react';
import './style.scss';

interface NotificationBadgeProps {
	unreadCount?: number;
	className?: any;
	showOnlyCircle?: boolean;
}
const NotificationBadge: React.FC<NotificationBadgeProps> = ({ showOnlyCircle, className, unreadCount }) => {
	const style: any = {};
	let badgeValue: any = unreadCount || ' ';
	if (unreadCount) {
		if (unreadCount >= 10 && unreadCount <= 99) {
			style.width = '26px';
			style.height = '18px';
			style.fontSize = '10px';
		} else if (unreadCount >= 100) {
			style.width = '24px';
			style.height = '16px';
			style.fontSize = '18px';
			badgeValue = '···';
		}
	} else if (showOnlyCircle) {
		style.width = '10px';
		style.height = '10px';
		style.borderRadius = '5px';
	}
	return showOnlyCircle || unreadCount ? (
		<IonBadge className={'notification-badge ' + (className || '')} style={style}>
			{badgeValue}
		</IonBadge>
	) : null;
};

export default NotificationBadge;
