import React from 'react';
import { IonActionSheet, IonButton, IonContent, IonImg, IonPage, IonThumbnail } from '@ionic/react';
import './style.scss';
import { RouteComponentProps } from 'react-router';
import { locale } from '../../locales/local';
import { TopNavbar } from '../common/header/topbar';
import { DefaultProfilePic } from '../../helpers/common';
import { Navigation } from '../../services/navigationService';

interface iProps extends RouteComponentProps<{ name: string }> {}

interface iState {
	showActionSheetFor: boolean;
	showActionDeactivate: boolean;
}
class DeletesAccountPage extends React.Component<iProps, iState> {
	constructor(props: iProps) {
		super(props);

		this.state = {
			showActionSheetFor: false,
			showActionDeactivate: false,
		};
	}

	render() {
		return (
			<>
				<IonPage className="delete-account-page">
					<TopNavbar
						{...this.props}
						pageTitle={locale.global.delete_account}
						showBack={true}
						hideSearchBar={true}
						onLeftButtonPress={() => {
							Navigation.back();
						}}
					/>

					<IonContent className="main-content-profile has-bottom-navbar has-topbar">
						<div className="user-image-contaoner">
							<IonThumbnail className="user-image">
								<IonImg title="Change" src={DefaultProfilePic} />
							</IonThumbnail>
						</div>
						<div className="delete-message">Deactivate your account instead of deleting</div>
						<div className="delete-temp-message">Deactivating your account is temporary</div>
						<div className="delete-message-hidden">Your profile, photos, comments, and likes will be hidden until you enable it by logging back in.</div>
						<div className="delete-temp-message">Deleting your account is permanent</div>
						<div className="delete-message-hidden">Your profile, contacts, photos, comments, and likes will be permanently deleted.</div>
						<div className="free-space"></div>

						<div
							className="deactivate-button"
							onClick={() => {
								this.setState({ showActionDeactivate: true });
							}}
						>
							<IonButton className="action-btn" expand="block">
								Deactivate
							</IonButton>
						</div>
						<div
							className="delete-account-button"
							onClick={() => {
								this.setState({ showActionSheetFor: true });
							}}
						>
							Delete Account
						</div>
					</IonContent>
				</IonPage>

				<IonActionSheet
					mode="ios"
					isOpen={this.state.showActionSheetFor}
					onDidDismiss={() => {
						this.setState({ showActionSheetFor: false });
					}}
					cssClass="delete-menu-actionsheet"
					header="Deleting account cannot be undone"
					buttons={[
						{
							cssClass: 'thread-menu-button',
							text: 'Delete Account',
							role: 'destructive',
						},
						{
							text: 'Cancel',
							handler: () => {},
						},
					]}
				></IonActionSheet>

				<IonActionSheet
					header="Your Profile, photos, comments, and likes will be hidden until you enable it by logging back in.
                "
					mode="ios"
					isOpen={this.state.showActionDeactivate}
					onDidDismiss={() => {
						this.setState({ showActionDeactivate: false });
					}}
					cssClass="delete-menu-actionsheet"
					buttons={[
						{
							cssClass: 'thread-menu-button',

							text: 'Deactivate Account',
							role: 'destructive',
						},
						{
							text: 'Cancel',
							handler: () => {},
						},
					]}
				></IonActionSheet>
			</>
		);
	}
}

export default DeletesAccountPage;
