import React from 'react';
import { IonContent, IonLabel, IonPage, IonToggle } from '@ionic/react';
import './style.scss';
import { RouteComponentProps } from 'react-router';
import { IonList, IonItem } from '@ionic/react';
import { connect } from 'react-redux';
import { pageLoading, updateUser } from '../../redux/actions/profile';
import { TopNavbar } from '../common/header/topbar';
import { locale } from '../../locales/local';
import VisibleToFriend from '../../modals/VisibleToFriend';
import { ContactsListType } from '../../services/enumService';
import { Navigation } from '../../services/navigationService';

interface iProps extends RouteComponentProps<{ name: string }> {
	location: any;
}

interface iState {
	options: any;
	isMoment: boolean;
	showVisible: boolean;
	visibleOption: any;
}

class PrivacyPage extends React.Component<iProps, iState> {
	constructor(props: iProps) {
		super(props);
		this.state = {
			visibleOption: '',
			showVisible: false,
			isMoment: true,
			options: [
				{
					list: [
						{ title: locale.settings.require_friend_req, uiType: 'toggle', type: 'require_friend_request' },
						{ title: locale.settings.friendind_methods, uiType: 'detail', type: 'methods_for_friending_me' },
						{ title: locale.settings.show_groups, uiType: 'toggle', type: 'show_groups_in_common' },
						{ title: locale.settings.blocklist, uiType: 'detail', type: 'blocked_list' },
					],
				},
				{
					sectionTitle: locale.settings.moment_status,
					list: [
						{ title: locale.settings.hide_post, uiType: 'detail', type: 'hide_my_posts' },
						{ title: locale.settings.hide_thierpost, uiType: 'detail', type: 'hide_their_posts' },
						{ title: locale.settings.visible_friend, uiType: 'detail', type: 'visible_to_friends', value: 'All' },
						{ title: locale.settings.public_10min, uiType: 'toggle', type: 'make_last_moments_public' },
						{ title: locale.settings.authorizations, uiType: 'detail', type: 'authorizations' },
					],
				},
			],
		};
	}

	render() {
		return (
			<IonPage className="privacy-setting-page">
				<TopNavbar
					{...this.props}
					pageTitle="Privacy"
					showBack={true}
					isHideRightButton={true}
					hideSearchBar={true}
					onLeftButtonPress={() => {
						Navigation.back();
					}}
				/>

				<IonContent className="main-content-profile has-bottom-navbar has-topbar">
					<IonList>
						{/* <IonList className="profile-list-sec2" lines="none">
						{this.state.options.map((section: any, secKey: any) => {
							return (
								<React.Fragment key={secKey}>
									{section.sectionTitle && <IonListHeader mode="md">{section.sectionTitle}</IonListHeader>}
									{section.list &&
										section.list.map((item: any, key: string) => {
											return (
										value: 'All'		<IonItem key={secKey + '' + key} button lines="none" detail={item.uiType === 'detail' ? true : false}>
													<IonLabel slot="start" color="light">
														<p>{item.title}</p>
													</IonLabel>
													{item.uiType === 'toggle' && (
														<IonToggle
															mode="ios"
															slot="end"
															className="toggle-customised"
															value={item.value}
															onChange={() => {
																let options = this.state.options;
																let list = options[secKey].list;
																list[key].value = !list[key].value;
																options[secKey].list = list;
																this.setState({ options: options });
															}}
														/>
													)}
													{item.uiType === 'detail' && <IonText slot="end">{item.value}</IonText>}
												</IonItem>
											);
										})}
								</React.Fragment>
							);
						})}
					</IonList> */}

						<IonItem detail={true} lines="none" onClick={() => Navigation.openContactsList({ contactsListType: ContactsListType.BLOCK })}>
							<IonLabel>Block List </IonLabel>
						</IonItem>
						<IonItem detail={true} lines="none" onClick={() => Navigation.openContactsList({ contactsListType: ContactsListType.CHAT_ONLY })}>
							<IonLabel>Chat Only</IonLabel>
						</IonItem>
						<div className="moments">Moments</div>
						{this.state.isMoment && (
							<IonItem detail={true} lines="none" onClick={() => Navigation.openContactsList({ contactsListType: ContactsListType.MY_MOMENTS })}>
								<IonLabel>Hide My Posts</IonLabel>
							</IonItem>
						)}
						<IonItem detail={true} lines="none" onClick={() => Navigation.openContactsList({ contactsListType: ContactsListType.THERE_MOMENTS })}>
							<IonLabel>Hide Their Posts</IonLabel>
						</IonItem>

						<IonItem detail={true} lines="none" onClick={() => this.setState({ showVisible: true })}>
							<IonLabel>Visible to Friends</IonLabel>
							<p className="visible-all" slot="end">
								{this.state.visibleOption ? this.state.visibleOption : 'All'}
							</p>
						</IonItem>
						<IonItem detail={false} lines="none">
							<IonLabel>Make Last 10 Moments Public</IonLabel>
							<div className={'swtich-item'}>
								<IonToggle className="toggle-customised" mode="ios" />
							</div>
						</IonItem>
					</IonList>
				</IonContent>

				<VisibleToFriend
					selectedOption={(option: any) => {
						this.setState({ visibleOption: option });
					}}
					show={this.state.showVisible}
					onCloseCallBack={() => {
						this.setState({ showVisible: false });
					}}
				/>
			</IonPage>
		);
	}
}

const mapStateToProps = (state: any) => {
	return {
		profile: state.profile,
		loggedInUser: state.global.loggedInUser,
	};
};

const mapDispatchToProps = (dispatch: any) => ({
	updateUser: (payload: String) => dispatch(updateUser(payload)),
	pageLoading: (payload: any) => dispatch(pageLoading(payload)),
});

export default connect(mapStateToProps, mapDispatchToProps)(PrivacyPage);
