import React from 'react';
import { IonButton, IonButtons, IonContent, IonIcon, IonItem, IonLabel, IonList, IonPage, IonToolbar, IonNote, IonSearchbar } from '@ionic/react';
import './style.scss';
import { checkmark } from 'ionicons/icons';

import { RouteComponentProps } from 'react-router';
import { isBlank } from '../../../helpers/common';

interface iProps extends RouteComponentProps<{ name: string }> {}
interface iState {
	selectedLocation: String;
}

const locations = [
	{ title: 'Holistika Tulum' },
	{ title: 'Trece Lunas' },
	{
		title: 'Ikal Bungalows Tulum',
		description: 'Heaven, Region 15 Manzana 34 Calle 5 Lote 19',
	},
	{ title: 'Curry By Po Thai', description: 'Central Park' },
	{
		title: 'Tulum Food Trucks',
		description: 'Av. Satelite Sur #12 Col. Huracanes',
	},
	{ title: 'MAIA Tulum' },
	{ title: 'Jungle Keva' },
	{ title: 'Villa Wayak' },
	{ title: 'Holistika' },
];

export default class AddLocation extends React.Component<iProps, iState> {
	constructor(props: iProps) {
		super(props);

		this.state = {
			selectedLocation: '',
		};
	}

	componentDidMount() {}

	render() {
		const { selectedLocation } = this.state;
		let isLocationSelected = !isBlank(selectedLocation);

		return (
			<IonPage className="add-location-page">
				<IonToolbar className="post-header" mode="ios">
					<IonButtons slot="start">
						<IonButton style={{ fontSize: '16px' }} color={'dark'} onClick={(e) => this.props.history.goBack()}>
							Cancel
						</IonButton>
					</IonButtons>

					<IonButtons slot="end">
						<IonButton className="post-btn" fill="solid" color={'danger'} onClick={(e) => this.props.history.goBack()}>
							Post
						</IonButton>
					</IonButtons>
				</IonToolbar>
				<div className="searchbar-container">
					<IonSearchbar mode="ios" animated={true}></IonSearchbar>
				</div>
				<IonContent>
					<IonList mode="ios">
						<IonItem>
							<IonLabel className="ion-text-wrap">
								<h3 style={{ color: '#999' }}>Hide Location</h3>
							</IonLabel>
							{!isLocationSelected && (
								<IonButton fill="clear" color="dark" slot="end">
									<IonIcon icon={checkmark} slot="end" />
								</IonButton>
							)}
						</IonItem>

						{locations.map((item, key) => {
							return (
								<IonItem
									key={key}
									onClick={() => {
										this.setState({ selectedLocation: item.title });
									}}
								>
									<IonLabel className="ion-text-wrap">
										<h3>{item.title}</h3>
										<IonNote>{item.description}</IonNote>
									</IonLabel>
									{selectedLocation === item.title && (
										<IonButton fill="clear" color="dark" slot="end">
											<IonIcon icon={checkmark} slot="end" />
										</IonButton>
									)}
								</IonItem>
							);
						})}
					</IonList>
				</IonContent>
			</IonPage>
		);
	}
}
