import React from 'react';
import { IonButton, IonContent, IonLoading, IonPage } from '@ionic/react';
import './style.scss';
import { RouteComponentProps } from 'react-router';
import { IonList, IonItem } from '@ionic/react';
import { DefaultProfilePic } from '../../helpers/common';
import { connect } from 'react-redux';
import { updateChatOnlyProfile, updateMyProfile } from '../../redux/actions/profile';
import MultiInputListItem from '../../components/MultiInputListItem';
import PhotoInputItem from '../../components/PhotoInputItem';
import { locale } from '../../locales/local';
import { TopNavbar } from '../common/header/topbar';
import { UpdateChatOnlyProfileData, UpdateChatOnlyProfileRequestData, UpdateProfileData, UpdateProfileRequestData } from '../../types/api-requests';
import AddNewLinkPopUp from '../../modals/AddNewLinkPopUp';
import { MyChatOnlyProfile, MyProfile } from '../../types/profile';
import SharedService from '../../services/sharedService';
import { ProfileStateTypes } from '../../types/redux-states';
import { Navigation } from '../../services/navigationService';
import { EditProfileNavParams } from '../../types/navigation';

interface iProps extends RouteComponentProps<{ name: string }> {
	profile: ProfileStateTypes;
	location: any;
	updateMyProfile: (data: UpdateProfileRequestData) => Promise<unknown>;
	updateChatOnlyProfile: (data: UpdateChatOnlyProfileRequestData) => Promise<unknown>;
}

interface iState {
	name?: string;
	username?: string;
	region?: string;
	quote?: string;
	website?: string;
	profession?: string;
	currentActiveEditField?: 'profilephoto' | 'name' | 'profession' | 'quote' | 'website' | 'region' | undefined;
	myProfile?: MyProfile | MyChatOnlyProfile | null;
	loggedInUserChatOnlyProfile?: any;
	loader: boolean;
	profilePhoto?: any;
	chatOnlyProfilePhoto?: any;
}

class ProfileEditor extends React.Component<iProps, iState> {
	componentIsMounted: Boolean = false;
	componentIsUpdated: Boolean = false;

	navParams: EditProfileNavParams = {} as any;

	constructor(props: iProps) {
		super(props);

		this.state = {
			currentActiveEditField: undefined,
			myProfile: undefined,
			loggedInUserChatOnlyProfile: undefined,
			name: undefined,
			username: undefined,
			region: undefined,
			profession: undefined,
			quote: undefined,
			website: undefined,
			loader: false,
		};
	}

	componentDidMount = async () => {
		this.componentIsMounted = true;

		Navigation.getNavParamData(this.props).then((res: any) => {
			this.navParams = res;
			this.setPageData();
		});
	};

	componentWillUnmount(): void {
		this.componentIsMounted = false;
	}

	setPageData = async () => {
		const myChatOnlyProfile = this.props.profile.loggedInUserChatOnlyProfile;
		const myProfile = this.navParams?.showChatOnly ? myChatOnlyProfile : this.props.profile.loggedInUserProfile;
		if (myProfile) {
			this.setState({
				myProfile: myProfile,
				name: myProfile.profileName || '',
				username: myProfile.username || '',
				region: myProfile.region || '',
				profession: myProfile.profession || '',
				quote: myProfile.bio || '',
				website: myProfile.website || '',
				profilePhoto: myProfile?.profilePhotos.length > 0 ? SharedService.extractProfilePhoto(myProfile.profilePhotos)?.url : DefaultProfilePic,
				chatOnlyProfilePhoto: !this.navParams?.showChatOnly && myChatOnlyProfile && myChatOnlyProfile.profilePhotos?.length > 0 ? SharedService.extractProfilePhoto(myChatOnlyProfile.profilePhotos)?.url : null,
			});
		}
	};

	componentDidUpdate = async () => {
		if (!this.componentIsUpdated) {
			this.componentIsUpdated = true;
			setTimeout(async () => {
				await this.setPageData();
			}, 500);
		}
	};

	submitChanges() {
		this.setState({ loader: true });
		// todo need to review this.  profile photo should be sent on its own and not included, no qr code needed - commenting those out for now
		const user: UpdateProfileData = {
			displayName: this.state.name || '',
			profession: this.state.profession || '',
			region: this.state.region || '',
			bio: this.state.quote || '',
			website: this.state.website || '',
		};

		this.props
			.updateMyProfile({ user })
			.then(() => {})
			.catch(() => {})
			.finally(() => {
				this.setState({ loader: false });
			});
	}
	submitChangesChatOnly() {
		this.setState({ loader: true });
		// todo need to review this.  profile photo should be sent on its own and not included, no qr code needed - commenting those out for now
		const profile: UpdateChatOnlyProfileData = {
			displayName: this.state.name || '',
			profession: this.state.profession || '',
			region: this.state.region || '',
			bio: this.state.quote || '',
			website: this.state.website || '',
		};
		this.props
			.updateChatOnlyProfile({ profile })
			.then(() => {})
			.catch(() => {})
			.finally(() => {
				this.setState({ loader: false });
			});
	}
	_viewAsChatOnly() {
		const profile: UpdateChatOnlyProfileData = {
			displayName: this.state.name || '',
			profession: this.state.profession || '',
			region: this.state.region || '',
			bio: this.state.quote || '',
			website: this.state.website || '',
		};
		Navigation.openViewChatOnly({
			data: profile,
		});
	}
	render() {
		const { currentActiveEditField, profilePhoto, chatOnlyProfilePhoto } = this.state;

		return (
			<IonPage className="profile-edit-page">
				<TopNavbar
					{...this.props}
					pageTitle={this.navParams?.showChatOnly ? 'Chats Only Profile' : locale.global.edit_profile}
					hideSearchBar={true}
					showBack={true}
					isHideRightButton={false}
					onLeftButtonPress={() => {
						Navigation.back();
					}}
				/>

				<IonContent className="main-content-profile has-topbar has-bottom-navbar">
					<IonList lines="none">
						{this.navParams?.showChatOnly ? <div className="chat-only-msg">Chats only are for contacts like a boss, a professor, teacher, landlords, vendors, acquaintances, or anyone you feel that does not have the right to see your personal side.</div> : ''}
						<PhotoInputItem
							isEdittable={true}
							fieldKey={'profilePhoto'}
							label={'Profile Photo'}
							photo={profilePhoto}
							isDisabled={currentActiveEditField && currentActiveEditField !== 'profilephoto'}
							onItemClick={() => {
								this.navParams?.showChatOnly
									? Navigation.openProfileUploadPhotoChatOnly({
											showChatOnly: true,
									  })
									: Navigation.openProfileUploadPhoto();
							}}
						/>
						{!this.navParams?.showChatOnly && (
							<PhotoInputItem
								isEdittable={true}
								fieldKey={'chatonlyPhoto'}
								label={'Chat Only Photo'}
								photo={chatOnlyProfilePhoto}
								isDisabled={currentActiveEditField && currentActiveEditField !== 'profilephoto'}
								onItemClick={() =>
									Navigation.openEditChatOnlyProfile({
										showChatOnly: true,
									})
								}
							/>
						)}
						{!this.navParams?.showChatOnly && <MultiInputListItem key="username" inputType="text" isEdittable={false} profileValueKey={'id'} label="User ID" value={this.state.username} props={this.props} />}
						<MultiInputListItem
							key="name"
							inputType="text"
							isEdittable={true}
							shouldDisabled={currentActiveEditField && currentActiveEditField !== 'name'}
							profileValueKey={'name'}
							label="Username"
							value={this.state.name}
							isEditMode={currentActiveEditField === 'name'}
							onEditModeOn={() => {
								this.setState({ currentActiveEditField: 'name' });
							}}
							onChange={(value: any) => {
								this.setState({ name: value });
							}}
							props={this.props}
						/>
						<MultiInputListItem
							inputType="text"
							shouldDisabled={currentActiveEditField && currentActiveEditField !== 'profession'}
							options={[{ title: 'Not Set', value: 'Not Set' }]}
							isEdittable={true}
							profileValueKey={'profession'}
							label="Profession"
							value={this.state.profession || ''}
							isEditMode={currentActiveEditField === 'profession'}
							onEditModeOn={() => {
								this.setState({ currentActiveEditField: 'profession' });
							}}
							onChange={(value: any) => {
								this.setState({ profession: value });
							}}
							props={this.props}
						/>
						<MultiInputListItem
							inputType="multiline"
							isEdittable={true}
							charLimit={150}
							shouldDisabled={currentActiveEditField && currentActiveEditField !== 'quote'}
							options={[{ title: 'Not Set', value: 'Not Set' }]}
							profileValueKey={'quote'}
							label="Bio"
							value={this.state.quote || ''}
							isEditMode={currentActiveEditField === 'quote'}
							onEditModeOn={() => {
								this.setState({ currentActiveEditField: 'quote' });
							}}
							onChange={(value: any) => {
								this.setState({ quote: value });
							}}
							props={this.props}
						/>
						<MultiInputListItem
							inputType={'text'}
							isEdittable={true}
							shouldDisabled={currentActiveEditField && currentActiveEditField !== 'website'}
							options={[{ title: 'Not Set', value: 'Not Set' }]}
							profileValueKey={'website'}
							label="Website"
							value={this.state.website || ''}
							onEditModeOn={() => {
								this.setState({ currentActiveEditField: 'website' });
							}}
							onChange={(value: any) => {
								this.setState({ website: value });
							}}
							props={this.props}
						/>
						<MultiInputListItem
							inputType="dropdown"
							shouldDisabled={currentActiveEditField && currentActiveEditField !== 'region'}
							options={[{ title: 'Planet Earth', value: 'Planet Earth' }]}
							isEdittable={true}
							profileValueKey={'region'}
							label="Region"
							value={this.state.region || ''}
							isEditMode={currentActiveEditField === 'region'}
							onEditModeOn={() => {
								this.setState({ currentActiveEditField: 'region' });
							}}
							onChange={(value: any) => {
								this.setState({ region: value });
							}}
							props={this.props}
						/>
						{currentActiveEditField && currentActiveEditField !== 'website' && (
							<IonItem key={'SaveCancel'} className="save-cancel-btns" button lines="none" detail={false}>
								<IonButton
									slot="end"
									className="edit-cancel-btn"
									onClick={() => {
										this.setState({ currentActiveEditField: undefined, quote: this.state.myProfile?.bio, profession: this.state.myProfile?.profession, name: this.state.myProfile?.profileName, region: this.state.myProfile?.region });
									}}
								>
									{locale.global.cancel}
								</IonButton>

								<IonButton
									className="edit-save-btn"
									slot="end"
									onClick={() => {
										this.setState({
											currentActiveEditField: undefined,
											loader: true,
										});
										this.navParams?.showChatOnly ? this.submitChangesChatOnly() : this.submitChanges();
									}}
								>
									{locale.global.done}
								</IonButton>
							</IonItem>
						)}
						<div className="blanck"></div>
						{this.navParams?.showChatOnly && (
							<>
								<div className="view-as-container">
									<IonButton
										class="view-as"
										onClick={() => {
											this._viewAsChatOnly();
										}}
									>
										View As
									</IonButton>
								</div>
							</>
						)}
					</IonList>
				</IonContent>

				<IonLoading cssClass="my-custom-class" isOpen={this.state.loader} duration={500} onDidDismiss={() => this.setState({ loader: false })} message="Updating..." spinner="bubbles" />

				{currentActiveEditField === 'website' && (
					<AddNewLinkPopUp
						defaultValue={this.state.website!}
						onAddLink={(addLink: any) => {
							this.setState({ website: addLink, currentActiveEditField: undefined }, () => {
								this.navParams?.showChatOnly ? this.submitChangesChatOnly() : this.submitChanges();
							});
						}}
						show={currentActiveEditField === 'website'}
						onCloseCallBack={() => {
							this.setState({ currentActiveEditField: undefined });
						}}
					/>
				)}
			</IonPage>
		);
	}
}

const mapStateToProps = (state: any) => {
	return {
		profile: state.profile,
	};
};

const mapDispatchToProps = (dispatch: any) => ({
	updateMyProfile: (payload: UpdateProfileRequestData) => dispatch(updateMyProfile(payload)),
	updateChatOnlyProfile: (payload: UpdateChatOnlyProfileRequestData) => dispatch(updateChatOnlyProfile(payload)),
});

export default connect(mapStateToProps, mapDispatchToProps)(ProfileEditor);
