import { IonFabButton, IonIcon, IonModal, IonToolbar } from '@ionic/react';
// import { connect } from 'http2';
import { connect } from 'react-redux';
import React, { useCallback, useEffect, useMemo, useState, useRef } from 'react';
import { QrReader } from 'react-qr-reader';
import { info } from '../../helpers/common';
import { getProfileByToken } from '../../redux/actions/profile';
import store from '../../redux/store';
import { NavigationRoutes, ProfileFriendActionTypes } from '../../services/enumService';
import { Navigation } from '../../services/navigationService';
import SharedService from '../../services/sharedService';
import { UserProfile } from '../../types/profile';
import './style.scss';
import { ContactStateTypes, ReduxStates } from '../../types/redux-states';
import { ContactItem } from '../../types/contact';
const ScanQrCode = './../../../assets/icon/scan-qrcode.svg';
const ScanGallery = './../../../assets/icon/scan-gallery.svg';
const ScanGradiantLine = './../../../assets/icon/scan-gradiant-line.svg';
const BackIcon = './../../../assets/icon/qrBack.svg';

interface QrCodeComponentProps {
	show: boolean;
	onClose?: any;
	contact: any;
}

const QrCodeScanModal: React.FC<QrCodeComponentProps> = ({ show, onClose, contact }) => {
	const mounted = useRef(false);
	const [startScanning, setStartScanning] = useState(false),
		[selectedScanOption, setSelectedScanOption] = useState('Scan'),
		handleError = useCallback((error) => {
			info('Qr Error ', console.error);
		}, []),
		handleScan = useCallback((response) => {
			info('all contact', contact);
			info('all', contact.contacts);
			info('QR Scan success', response);
			if (response && response.indexOf(NavigationRoutes.PROFILE_FRIEND) !== -1) {
				// http://192.168.1.7:8100/profile-friend?token=XQVevT8FvTK9NONPNQpxJeu3JH29c0MJdwqMrjJU3ZMhS5XhOaGwkyYI2Ra2Ysnm
				const token = SharedService.getQueryStringValue(response, 'token');
				if (token) {
					const profileTokenRes: any = store.dispatch(getProfileByToken({ token }));
					profileTokenRes
						.then((friendProfile: UserProfile) => {
							if (contact.contacts?.find((item: ContactItem) => item.username === friendProfile.username)) {
								Navigation.profileFriend({
									data: friendProfile,
									friendProfileActionType: ProfileFriendActionTypes.ContactView,
								});
							}
							// } else if (contact.blockedList?.find((item: ContactItem) => item.username === friendProfile.username)) {
							// 	Navigation.profileFriend({
							// 		data: friendProfile,
							// 		friendProfileActionType: ProfileFriendActionTypes.ContactAdd,
							// 	});
							// }
							else if (contact.receivedRequests?.find((item: ContactItem) => item.username === friendProfile.username)) {
								Navigation.profileFriend({
									data: friendProfile,
									friendProfileActionType: ProfileFriendActionTypes.ContactRequestView,
								});
							} else if (contact.sentRequests?.find((item: ContactItem) => item.username === friendProfile.username)) {
								Navigation.profileFriend({
									data: friendProfile,
									friendProfileActionType: ProfileFriendActionTypes.ContactPending,
								});
							} else {
								Navigation.profileFriend({
									data: friendProfile,
									friendProfileActionType: ProfileFriendActionTypes.ContactAdd,
								});
							}
							// contact.contacts.map((_contact: ContactItem, key: number) => {
							// 	{
							// 		info('friend', friendProfile.username);
							// 	}
							// 	{
							// 		info('username', _contact.username);
							// 	}
							// 	if (friendProfile.username === _contact.username) {
							// 		Navigation.profileFriend({
							// 			data: friendProfile,
							// 			friendProfileActionType: ProfileFriendActionTypes.ContactView,
							// 		});
							// 	} else {
							// 		Navigation.profileFriend({
							// 			data: friendProfile,
							// 			friendProfileActionType: ProfileFriendActionTypes.ContactAdd,
							// 		});
							// 	}
							// });
						})
						.catch((_: any) => {});
				}
			}
		}, []),
		scanOptions = useMemo(() => ['Scan', 'Translate'], []);

	useEffect(() => {
		if (show) {
			setTimeout(() => {
				setStartScanning(true);
			}, 2000);
		}
	}, [show]);

	useEffect(() => {
		mounted.current = true;
		return () => {
			mounted.current = false;
		};
	}, []);

	return (
		<IonModal animated={false} showBackdrop={true} isOpen={show} onDidDismiss={onClose} swipeToClose={false} cssClass="qrcode-scan-modal">
			<div className="content-view">
				<div className="qrview-container" onClick={onClose}>
					<div className="backicon">
						<IonIcon className="back" icon={BackIcon}></IonIcon>
					</div>

					{startScanning && <img src={ScanGradiantLine} className="scanning-animated-line" alt="scanline" />}

					<div className="qr-container">
						{mounted.current && (
							<QrReader
								scanDelay={1000}
								videoId="qrScannerForUserAdd"
								constraints={{
									facingMode: 'environment',
									height: window.outerHeight,
								}}
								containerStyle={{
									width: window.outerWidth + 'px',
									height: window.outerHeight + 'px',
								}}
								videoContainerStyle={{
									width: window.outerWidth + 'px',
									height: window.outerHeight + 'px',
								}}
								videoStyle={{
									width: window.outerWidth + 'px',
									height: window.outerHeight + 'px',
								}}
								className="qrreader"
								onResult={(result, error) => {
									if (!!result) {
										handleScan(result.getText());
									}

									if (!!error) {
										console.info(error);
										handleError(error);
									}
								}}
							/>
						)}

						<div id="qrScannerForUserAdd" />
					</div>

					<div className="scan-btns">
						<div className="scan-qr-barcode-title">
							Scan
							<br />
							QR code / barcode
							<br />
						</div>

						<IonToolbar>
							<IonFabButton slot="start">
								<IonIcon icon={ScanQrCode}></IonIcon>
							</IonFabButton>
							<IonFabButton slot="end">
								<IonIcon icon={ScanGallery}></IonIcon>
							</IonFabButton>
						</IonToolbar>
					</div>
				</div>
				<div className="bottom-view">
					<div className="scan-options-row">
						{scanOptions.map((item, key) => {
							return (
								<div key={key} className={'scan-option-item ' + (selectedScanOption === item ? 'active' : '')} onClick={() => setSelectedScanOption(item)}>
									{item}
								</div>
							);
						})}
					</div>
				</div>
			</div>
		</IonModal>
	);
};

const mapStateToProps = (state: ReduxStates) => {
	return {
		contact: state.contact,
	};
};
export default connect(mapStateToProps)(QrCodeScanModal);

// export default QrCodeScanModal;
