import { IonButton, IonIcon, IonInput, IonModal } from '@ionic/react';
import React, { createRef, useEffect, useState } from 'react';
import './MyAliasInGroupPopup.scss';
import { DefaultProfilePic } from '../../helpers/common';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/swiper-bundle.min.css';
import 'swiper/swiper.min.css';

const ClearIcon = './assets/icon/close-icon.svg';
// const BlankUserProfile = './assets/img/blank-user-profile.png';

interface MyAliasInGroupPopupProps {
	initialValue: any;
	show: boolean;
	onCloseCallBack: any;
	onConfirm: any;
	isGroupName?: boolean;
	photo?: any;
}

const MyAliasInGroupPopup: React.FC<MyAliasInGroupPopupProps> = ({ photo, initialValue, show, onCloseCallBack, onConfirm, isGroupName }) => {
	const [value, setValue] = useState<any>();
	const tagInputRef: React.RefObject<HTMLIonInputElement> = createRef();

	useEffect(() => {
		setValue(initialValue);
	}, [initialValue]);

	useEffect(() => {
		if (show) {
			const tagNameInput: any = document.getElementById('tagNameInput');
			if (tagNameInput && tagNameInput.setFocus) {
				setTimeout(() => {
					tagNameInput.setFocus();
				}, 500);
			}
		}
	}, [show]);
	return (
		<IonModal showBackdrop={true} isOpen={show} onDidDismiss={onCloseCallBack} swipeToClose={true} cssClass="my-alias-in-group-popup" animated={true}>
			<div className="container">
				<div className="content-view">
					<div className="title">{isGroupName ? 'Group Name' : 'My Alias in Group'}</div>

					<div className="tag-input-container">
						<img src={photo ?? DefaultProfilePic} alt="profilePhoto" />
						<div className="input-view">
							<IonInput ref={tagInputRef} id="tagNameInput" placeholder={isGroupName ? 'Group name' : 'My alias in group'} className="tag-name-input" value={value} onIonChange={(event) => setValue(event.detail.value)} />
							{value && (
								<IonIcon
									className="clear-icon"
									src={ClearIcon}
									onClick={() => {
										setValue('');
										const tagNameInput: any = document.getElementById('tagNameInput');
										if (tagNameInput && tagNameInput.setFocus) {
											tagNameInput.setFocus();
										}
									}}
								></IonIcon>
							)}
						</div>
					</div>

					<div className="message">{isGroupName ? '' : ' Your Alias is only visible to members in this group'}</div>
					<div className="btns-view">
						<IonButton fill="clear" className="delete-btn" onClick={onCloseCallBack}>
							Cancel
						</IonButton>
						<IonButton fill="clear" className="ok-btn" onClick={() => onConfirm(value)} disabled={!value?.trim() || value === initialValue}>
							Done
						</IonButton>
					</div>
				</div>
			</div>
		</IonModal>
	);
};
export default MyAliasInGroupPopup;
