import { IonIcon, IonToolbar, IonInput, IonButtons } from '@ionic/react';
import { happyOutline, closeCircle, chatboxEllipsesOutline, micOutline } from 'ionicons/icons';
import React, { useState, useEffect, useRef } from 'react';
import './style.scss';

interface ContainerProps {
	autoFocus?: boolean;
	onClose?: any;
	showClose?: boolean;
	showMessageBtn?: boolean;
	showMic?: boolean;
	showEmoji?: boolean;
}

const BottomInputBox: React.FC<ContainerProps> = ({ onClose, autoFocus, showClose, showMic, showMessageBtn, showEmoji }) => {
	const [message, setMessage] = useState('');
	const inputRef: React.RefObject<HTMLIonInputElement> = useRef(null);

	useEffect(() => {
		if (autoFocus) {
			setTimeout(() => {
				inputRef.current?.setFocus();
			}, 1000);
		}
	}, [inputRef, autoFocus]);

	return (
		<IonToolbar
			className="bottom-input-box bottom-toolbar"
			onClick={(event) => {
				event.stopPropagation();
			}}
		>
			<IonButtons slot="start">{showMessageBtn && <IonIcon slot="start" icon={chatboxEllipsesOutline} />}</IonButtons>

			<form
				onSubmit={(e) => {
					e.preventDefault();
					// this.submitMessage();
				}}
			>
				<IonInput ref={inputRef} className="messeger-input" debounce={1} autofocus={autoFocus} enterkeyhint="send" value={String(message)} onIonChange={(e: any) => setMessage(e.detail.value)} />
			</form>
			<IonButtons slot="primary">
				{showMic && <IonIcon style={{ marginLeft: '-30px' }} slot="end" icon={micOutline} />}

				{showClose && <IonIcon slot="end" icon={closeCircle} onClick={onClose} />}

				{showEmoji && <IonIcon slot="end" icon={happyOutline} />}
			</IonButtons>
		</IonToolbar>
	);
};

export default BottomInputBox;
