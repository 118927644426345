import React, { createRef } from 'react';
import { IonButton, IonButtons, IonContent, IonIcon, IonItem, IonLabel, IonList, IonPage, IonTitle, IonToolbar, IonTextarea, IonImg } from '@ionic/react';
import './style.scss';
import { locationOutline, chevronForward, personOutline, atOutline, addOutline } from 'ionicons/icons';
import { CameraDirection, CameraResultType, CameraSource, Camera } from '@capacitor/camera';

import { RouteComponentProps } from 'react-router';
import { isBlank } from '../../../helpers/common';
import { Navigation } from '../../../services/navigationService';

interface iProps extends RouteComponentProps<{ name: string }> {}
interface iState {
	postTextValue: String;
	postType: String;
	photos: Array<any>;
}

export default class CreatePost extends React.Component<iProps, iState> {
	textAreaRef: any = createRef();

	constructor(props: iProps) {
		super(props);
		const passedData: any = props.location.state;

		this.state = {
			photos: [],
			postTextValue: '',
			postType: passedData && passedData.postType ? passedData.postType : 'media',
		};
	}

	componentDidMount() {
		setTimeout(() => {
			this.textAreaRef?.current?.setFocus();
		}, 1000);
	}

	async takePhotoFromCamera(callBack: Function, isFrontCamera = false) {
		let allowEditing = false;

		const capturedPhoto = await Camera.getPhoto({
			resultType: CameraResultType.DataUrl,
			source: CameraSource.Camera,
			quality: 85,
			allowEditing,
			height: 500,
			correctOrientation: true,
			direction: isFrontCamera ? CameraDirection.Front : CameraDirection.Rear,
		});
		try {
			if (callBack != null) {
				callBack(capturedPhoto);
			}
		} catch (e) {}
	}

	render() {
		const { postTextValue, postType } = this.state;
		let isPostBtnEnabled = !isBlank(postTextValue);

		return (
			<IonPage className="create-post-page">
				<IonToolbar className="post-header" mode="ios">
					<IonButtons slot="start">
						<IonButton style={{ fontSize: '16px' }} color={'dark'} onClick={(e) => this.props.history.goBack()}>
							Cancel
						</IonButton>
					</IonButtons>

					<IonTitle color="medium">Status</IonTitle>

					<IonButtons slot="end">
						<IonButton className="post-btn" disabled={!isPostBtnEnabled} fill="solid" color={isPostBtnEnabled ? 'danger' : 'medium'} onClick={(e) => this.props.history.goBack()}>
							Post
						</IonButton>
					</IonButtons>
				</IonToolbar>

				<IonContent>
					<IonTextarea ref={this.textAreaRef} autoGrow={true} autofocus={true} value={String(postTextValue)} onIonChange={(e) => this.setState({ postTextValue: String(e.detail.value) })}></IonTextarea>

					{postType === 'media' && (
						<div className="media-container">
							{this.state.photos.map((item, key) => {
								return <IonImg src={item.dataUrl} key={key} className="media-item-size media-item"></IonImg>;
							})}

							<IonButton
								className="media-item-size add-media-btn"
								onClick={() => {
									this.takePhotoFromCamera((photo: any) => {
										var photos = this.state.photos;
										photos.push(photo);
										this.setState({ photos });
									}, true);
								}}
							>
								<IonIcon icon={addOutline}></IonIcon>
							</IonButton>

							<div style={{ width: '30%' }}></div>
							<div style={{ width: '30%' }}></div>
						</div>
					)}

					<IonList lines="none" mode="ios">
						<IonItem
							onClick={() => {
								Navigation.openAddLocation();
							}}
						>
							<IonButton fill="clear" color="dark" slot="start">
								<IonIcon icon={locationOutline} slot="icon-only" />
							</IonButton>
							<IonLabel>Location</IonLabel>
							<IonButton fill="clear" color="dark" slot="end">
								<IonIcon icon={chevronForward} slot="end" />
							</IonButton>
						</IonItem>

						<IonItem>
							<IonButton fill="clear" color="dark" slot="start">
								<IonIcon icon={atOutline} slot="icon-only" />
							</IonButton>
							<IonLabel>Mention</IonLabel>
							<IonButton fill="clear" color="dark" slot="end">
								<IonIcon icon={chevronForward} slot="end" />
							</IonButton>
						</IonItem>

						<IonItem
							onClick={() => {
								Navigation.openShareWith();
							}}
						>
							<IonButton fill="clear" color="dark" slot="start">
								<IonIcon icon={personOutline} slot="icon-only" />
							</IonButton>
							<IonLabel>Share With</IonLabel>
							<IonButton fill="clear" color="dark" slot="end">
								All
								<IonIcon icon={chevronForward} slot="end" />
							</IonButton>
						</IonItem>

						<IonItem>
							<IonButton fill="clear" color="dark" slot="start">
								<IonIcon src="assets/icon/share.svg" slot="icon-only" />
							</IonButton>
							<IonLabel>Link to outside account</IonLabel>
							<IonButton fill="clear" color="dark" slot="end">
								<IonIcon icon={chevronForward} slot="end" />
							</IonButton>
						</IonItem>
					</IonList>
				</IonContent>
			</IonPage>
		);
	}
}
