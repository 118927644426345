import { IonButton, IonCheckbox, IonIcon, IonImg } from '@ionic/react';
import React from 'react';
import './ContactItem.scss';
const UserPlaceHolder = './assets/icon/user-placeholder.svg';
const ThreeDotIcon = './assets/icon/3doticon.svg';

interface ContactItemProps {
	item: any;
	selectMode?: boolean;
	isSelected?: boolean;
	onItemSelect?: any;
	onMoreOptionClick?: any;
}

const ContactItem: React.FC<ContactItemProps> = ({ item, selectMode, isSelected, onItemSelect, onMoreOptionClick }) => {
	return (
		<div className="item-contact">
			{selectMode && (
				<IonCheckbox
					mode={'ios'}
					slot={'start'}
					checked={isSelected}
					onIonChange={() => {
						onItemSelect && onItemSelect();
					}}
				/>
			)}

			<div className="img-view">
				<IonImg src={UserPlaceHolder}></IonImg>
			</div>
			<div className="detail">
				<div className="name">
					<span className="matching">U</span>
					{item.name}
				</div>
				<div className="profession">{item.profession}</div>
			</div>
			{selectMode && (
				<IonButton fill="clear" slot="end" className="more-option-btn" onClick={() => onMoreOptionClick && onMoreOptionClick()}>
					<IonIcon slot="icon-only" src={ThreeDotIcon}></IonIcon>
				</IonButton>
			)}
		</div>
	);
};

export default ContactItem;
