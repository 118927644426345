import React from 'react';
import { IonButton, IonIcon } from '@ionic/react';
import './style.scss';

const StarredIcon = './assets/icon/starred.svg';

interface iProps {
	alphabets: any;
	showStarredInsideAlbhabetList?: boolean;
	onGoToStarredContact?: any;
	onAlphabetSelect?: any;
}

export const ContactAlbhabetView: React.FC<iProps> = ({ alphabets, showStarredInsideAlbhabetList, onGoToStarredContact, onAlphabetSelect }) => {
	//ABCDEFGHIJKLMNOPQRSTUVWXYZ

	return (
		<div className="contact-alphabet-view">
			<div className="container">
				{showStarredInsideAlbhabetList && (
					<IonButton fill="clear" onClick={onGoToStarredContact}>
						<IonIcon src={StarredIcon}></IonIcon>
					</IonButton>
				)}

				{alphabets.map((char: string, index: number) => (
					<IonButton key={index} fill="clear" onClick={() => onAlphabetSelect && onAlphabetSelect(char)}>
						{char}
					</IonButton>
				))}
			</div>
		</div>
	);
};
