import React from 'react';
import { IonContent, IonPage, IonAlert, IonItem, IonList, IonInput, IonLabel, IonIcon } from '@ionic/react';
import './AddMembers.scss';
import { RouteComponentProps } from 'react-router';
import { TopNavbar } from '../../common/header/topbar';
import { ContactsList } from '../components/ContactsList';
import { connect } from 'react-redux';
import _ from 'lodash';
import { AddMembersPageActionType, ChatMessageState, ChatType, ContactPrivacySettingType, ContactsListMode, ContactsListType, MessageType } from '../../../services/enumService';
import { ContactAlbhabetView } from '../components/ContactAlbhabetView';
import SharedService, { sharedService } from '../../../services/sharedService';
import { addUserToTag } from '../../../redux/actions/profile';
import ShareMessageModal from '../../../modals/shareMessageModal';
import { ContactStateTypes, ProfileStateTypes, ReduxStates } from '../../../types/redux-states';
import { AddContactToNewProjectRequestData, AddUsersToMucRequestData, AddUserToTagRequestData, ChangePrivacySettingData, NewProject } from '../../../types/api-requests';
import { ContactItem } from '../../../types/contact';
import { DefaultProfilePic, info, sortByAlias } from '../../../helpers/common';
import { Navigation } from '../../../services/navigationService';
import { AddMembersNavParams } from '../../../types/navigation';
import { changePrivacySetting } from '../../../redux/actions/contact';
import { addMembersToGroup } from '../../../redux/actions/group';
import { GroupItem } from '../../../types/group';
import { xmpp } from '../../../services/xmpp';
import { dbService } from '../../../services/dbService';
import { initChat } from '../../../redux/actions/chat';
import { locale } from '../../../locales/local';
import { GlobalStateTypes } from '../../../types/redux-states';
import { showToast } from '../../../redux/actions/global';
import store from '../../../redux/store';
import { showStylishToast } from '../../../redux/actions/global';
import { UserProfile } from '../../../types/profile';

const CloseIcon = './assets/icon/Close.svg';
const GroupsChatIcon = './assets/icon/groups-chats-icon.svg';
const ChatsOnlyIcon = './assets/icon/chats-only-icon.svg';
const TaggedFilled = './assets/icon/tag-filled.svg';
const ContactIcon = './assets/icon/contactIcon.svg';
const AddContactList = './assets/icon/addContactList.svg';

interface iProps extends RouteComponentProps<{ name: string }> {
	profile: ProfileStateTypes;
	contact: ContactStateTypes;
	showStylishToast: (payload: GlobalStateTypes) => void;
	showToast: (payload: GlobalStateTypes) => void;
	addUserToTag: (payload: AddUserToTagRequestData) => Promise<unknown>;
	addContactToNewProject: (payload: AddContactToNewProjectRequestData) => Promise<unknown>;
	changePrivacySetting: (payload: ChangePrivacySettingData) => Promise<unknown>;
	addMembersToGroup: (payload: AddUsersToMucRequestData) => Promise<unknown>;
	initChat: (payload: any) => void;
	props: any;
	location: any;
	dashboard: any;
	resetValues: Function; //redux
	updateUser?: any; //redux
	pageLoading?: any; //redux
	hidePageLoading?: any; //redux
}
interface iState {
	loggedInUser?: any;
	contacts: any;
	contactsType?: any;
	selectedContactsId: any;
	selectedGroupsId: any;
	selectedChatOnlyId: any;
	selectedTagsId: any;
	previousSelectedContactsIds?: any;
	pageActionType?: string;
	tagName?: string;
	showSearchContactsModal: boolean;
	showAlert: boolean;
	alertShow: boolean;
	isactiveGroups: boolean;
	isactiveTags: boolean;
	isactiveContacts: boolean;
	isactiveChatOnly: boolean;
	loader: boolean;
	openShareMessageModal: boolean;
	isMultiple: boolean;
	isAssignContact: boolean;
	isForwardMessage: boolean;
	showToast: boolean;
}

enum ContactsType {
	All = 'all',
	Contacts = 'Contacts',
	GroupChats = 'groups-chats',
	Tags = 'tags',
	ChatsOnly = 'chats-only',
}

class AddMembers extends React.Component<iProps, iState> {
	componentIsMounted: boolean = false;
	navParams: AddMembersNavParams = {} as any;

	constructor(props: iProps) {
		super(props);

		this.state = {
			loggedInUser: {},
			showSearchContactsModal: false,
			showAlert: false,
			alertShow: false,
			contacts: [],
			selectedContactsId: [],
			selectedGroupsId: [],
			selectedChatOnlyId: [],
			selectedTagsId: [],
			previousSelectedContactsIds: [],

			isactiveGroups: false,
			isAssignContact: false,
			isForwardMessage: false,
			isactiveTags: false,
			isactiveContacts: true,
			isactiveChatOnly: false,
			tagName: '',
			loader: false,
			openShareMessageModal: false,
			isMultiple: false,
			showToast: false,
		};
	}

	getData = async () => {
		let user = await dbService.me(),
			contacts: any = user.contacts.filter((_contact: any) => _contact.status === 'confirmed').sort(sortByAlias);
		/*contacts: any = await apiService.getContacts(false, 'confirmed').then((_contacts: any) => {
				let contacts: any = [];

				if (_contacts && _contacts.length > 0) {
					contacts = _contacts.sort(sortByAlias);
				}

				return contacts;
			});*/

		return [user, contacts];
	};

	setPageData = async () => {
		let [user, contacts] = await this.getData();

		this.setState((state, props) => ({
			...state,
			loggedInUser: user,
			contacts: contacts,
		}));
	};

	async componentDidMount() {
		this.componentIsMounted = true;

		Navigation.getNavParamData(this.props).then((res: any) => {
			this.navParams = res;

			let state: any = this.props.history.location?.state;
			let contactsType = ContactsType.All;
			let actionType = this.navParams?.actionType;
			if (state?.addContactType === 'groups') {
				contactsType = ContactsType.GroupChats;
			} else if (state?.addContactType === 'friends') {
				contactsType = ContactsType.ChatsOnly;
			}

			// Select default contact for create new group if  group contact come from perticular contact/chat
			let selectedContactsId = [];
			if (actionType === AddMembersPageActionType.ChooseContactForGroup && this.navParams.data && this.navParams.data.username) {
				selectedContactsId.push((this.navParams.data as UserProfile).username);
			}

			this.setState({
				previousSelectedContactsIds: state?.previousSelectedContactsIds || [],
				contactsType: contactsType,
				pageActionType: actionType,
				selectedContactsId: selectedContactsId,
			});
		});
	}

	componentWillUnmount() {
		this.componentIsMounted = false;
	}

	setSearchText() {}

	_onDone = () => {};

	_onClose = () => {
		Navigation.back();
	};

	createNewTag = () => {
		const { tagName, selectedContactsId, selectedChatOnlyId, selectedGroupsId, selectedTagsId } = this.state;

		const allSelectedItems = [...selectedContactsId, ...selectedChatOnlyId, ...selectedGroupsId, ...selectedTagsId];

		if (tagName && allSelectedItems && allSelectedItems.length > 0) {
			this.props
				.addUserToTag({
					request: {
						usernames: allSelectedItems,
						tag: tagName,
					},
				})
				.then(() => {
					Navigation.back();
				});
		}
	};

	async scrollToContactSection(elementId: string, ionContentId: string) {
		const element: any = document.getElementById(elementId);
		let ionContent: any = document.getElementById(ionContentId);
		if (ionContent) {
			const elem = await ionContent.getScrollElement();
			elem?.scrollTo(0, element.offsetTop);
		}
	}

	_onContactSelect = (contact: ContactItem) => {
		const { selectedContactsId, selectedGroupsId, selectedChatOnlyId, selectedTagsId, pageActionType, isactiveContacts, isactiveGroups, isactiveChatOnly, isactiveTags } = this.state;

		if ((pageActionType === AddMembersPageActionType.ChooseContactWithShareContact || pageActionType === AddMembersPageActionType.ChooseContactWithAdditionalOptionShareConatact || pageActionType === AddMembersPageActionType.ChooseContactForShareContactInGroup) && !this.state.isMultiple) {
			const tmpList = [contact.username];

			this.setState({ openShareMessageModal: true, selectedContactsId: tmpList });
			return;
		}
		if (pageActionType === AddMembersPageActionType.ChooseContactForOwnerShip) {
			selectedContactsId[0] = contact.username;
			this.setState({
				selectedContactsId: selectedContactsId,
			});
			return;
		}
		if (pageActionType === AddMembersPageActionType.ChooseContactForSecondaryAdmin) {
			selectedContactsId[0] = contact.username;
			this.setState({
				selectedContactsId: selectedContactsId,
			});
			return;
		}
		if (pageActionType === AddMembersPageActionType.ChooseContactForTertiaryAdmin) {
			selectedContactsId[0] = contact.username;
			this.setState({
				selectedContactsId: selectedContactsId,
			});
			return;
		}

		let tmpList: any;
		if (isactiveContacts) tmpList = selectedContactsId || [];
		else if (isactiveGroups) tmpList = selectedGroupsId || [];
		else if (isactiveChatOnly) tmpList = selectedChatOnlyId || [];
		else if (isactiveTags) tmpList = selectedTagsId || [];

		if (pageActionType === AddMembersPageActionType.ChooseContactForNewProject) {
			if (tmpList.length > 0 && tmpList[0] === contact.username) {
				tmpList = [];
			} else {
				tmpList = [contact.username];
			}
		} else {
			const contactIdIndex = tmpList.indexOf(contact.username);
			if (contactIdIndex === -1) {
				// if (pageActionType === AddMembersPageActionType.ChooseContactForOwnerShip && tmpList.length > -1) {
				// this.setState({ showAlert: true });
				// }
				if ((pageActionType === AddMembersPageActionType.ChooseContactForGroup || pageActionType === AddMembersPageActionType.ChooseContactForCurrentGroup) && [...selectedChatOnlyId, ...selectedContactsId].length >= sharedService.groupMaxMembersLimit) {
					this.setState({ showAlert: true });
				} else {
					const selectedContactsContainerElem = document.getElementById('selectedContactsContainer');
					if (selectedContactsContainerElem && selectedContactsContainerElem?.scrollWidth > 0) {
						setTimeout(() => {
							selectedContactsContainerElem?.scrollTo(selectedContactsContainerElem.scrollWidth, 0);
						}, 200);
					}
					tmpList.push(contact.username);
				}
			} else {
				tmpList.splice(contactIdIndex, 1);
			}
		}

		if (isactiveContacts) {
			this.setState({
				selectedContactsId: tmpList,
			});
			if (pageActionType === AddMembersPageActionType.ChooseContactWithShareContact && selectedContactsId.length > 9) {
				this.setState({
					alertShow: true,
				});
			}
			if (pageActionType === AddMembersPageActionType.ChooseContactForForwordSketchpad && selectedContactsId.length > 10) {
				this.setState({
					alertShow: true,
				});
			}
		} else if (isactiveGroups)
			this.setState({
				selectedGroupsId: tmpList,
			});
		else if (isactiveChatOnly)
			this.setState({
				selectedChatOnlyId: tmpList,
			});
		else if (isactiveTags)
			this.setState({
				selectedTagsId: tmpList,
			});
	};

	extractContactName = (contact: any) => {
		return contact?.alias || contact?.username || contact?.userId || '';
	};

	_shareDataWithContact = async (mediaFiles: Array<File> = [], message: string) => {
		const { selectedContactsId } = this.state;
		let singleContact: ContactItem | undefined;
		let mediaResolutions: any = [],
			isImageVideo: Boolean = true;
		if (selectedContactsId.length === 1) {
			singleContact = this.props.contact.contacts?.find((c: ContactItem) => c.username === selectedContactsId[0]);
		}
		let files: any = [];
		const _mediaResolutions: any = [];

		let messageType: any,
			messageState = ChatMessageState.ORIGINAL,
			relatedMessageId = '';
		if (isImageVideo) {
			messageType = MessageType.MEDIA;
			for (let index in mediaFiles) {
				let outputFile: File | null = null;

				const media = mediaFiles[index],
					mediaResolution = parseInt(index) === 0 ? mediaResolutions[index] : 0; // Remove this condition when image editor impletmented or multiple images

				// Remove this condition when image editor impletmented or multiple images
				if (parseInt(index) === 0) {
					_mediaResolutions.push(mediaResolution === 0 ? 'full image' : 'reduced image');
				} else {
					// No need this after image editor implemented for multiple images
					_mediaResolutions.push('full image');
				}

				if (mediaResolution !== 0) {
					outputFile = media;
				} else {
					outputFile = media;
				}

				if (outputFile) {
					files.push(outputFile);
				}
			}
		} else {
			files = mediaFiles;
		}
		let conversationType = ChatType.P2P;
		for (const receiverId of selectedContactsId) {
			info('receiverId::', receiverId);
			await xmpp.sendMessage({
				messageBody: files,
				messageState: messageState,
				messageType: messageType,
				relatedMessageId: relatedMessageId,
				conversationType: conversationType,
				receiverId,
				isShareContact: true,
			});
		}

		if (singleContact) {
			// Load message history for selected contact
			await this.props.initChat({
				receiver: { ...singleContact, handlerText: locale.dashboard.start_chat },
				type: ChatType.P2P,
			});
			Navigation.chat({ receiver: singleContact! });
		} else {
			const myProfile = this.props.profile.loggedInUserProfile;
			await this.props.initChat({
				receiver: { ...myProfile, handlerText: locale.dashboard.start_chat },
				type: ChatType.P2P,
			});
			if (myProfile) {
				Navigation.personalNotepad({ receiver: myProfile });
			}
		}
	};

	_shareContact = async (contactItem: any, actionType: string, message: string) => {
		const { selectedContactsId } = this.state;
		let singleContact: ContactItem | undefined;

		if (selectedContactsId.length === 1) {
			singleContact = this.props.contact.contacts?.find((c: ContactItem) => c.username === selectedContactsId[0]);
		}

		let messageType: any = MessageType.CONTACT,
			messageState = ChatMessageState.ORIGINAL,
			relatedMessageId = '';

		let conversationType = actionType === AddMembersPageActionType.ChooseContactForShareContactInGroup ? ChatType.GROUP : ChatType.P2P;

		if (actionType === AddMembersPageActionType.ChooseContactWithAdditionalOptionShareConatact || actionType === AddMembersPageActionType.ChooseContactForShareContactInGroup) {
			let receiverId = '';
			if (contactItem.username || contactItem.name) {
				receiverId = actionType === AddMembersPageActionType.ChooseContactForShareContactInGroup ? contactItem.name : contactItem.username;
			}
			await xmpp.sendMessage({
				messageBody: singleContact?.userInfo,
				messageState: messageState,
				messageType: messageType,
				relatedMessageId: relatedMessageId,
				conversationType,
				receiverId,
			});
			await this.props.initChat({
				receiver: { ...contactItem, handlerText: locale.dashboard.start_chat },
				type: actionType === AddMembersPageActionType.ChooseContactForShareContactInGroup ? ChatType.GROUP : ChatType.P2P,
			});
			Navigation.chat({ receiver: contactItem });
		} else if (actionType === AddMembersPageActionType.ChooseContactWithShareContact) {
			for (const receiverId of selectedContactsId) {
				await xmpp.sendMessage({
					messageBody: contactItem,
					messageState: messageState,
					messageType: messageType,
					relatedMessageId: relatedMessageId,
					conversationType: conversationType,
					receiverId,
					isShareContact: true,
				});
			}

			if (singleContact) {
				// Load message history for selected contact
				await this.props.initChat({
					receiver: { ...singleContact, handlerText: locale.dashboard.start_chat },
					type: ChatType.P2P,
				});
				// this.props.showStylishToast({ stylishToastMessage: 'Send', stylishToastIcon: 'check' });

				Navigation.chat({ receiver: singleContact! });
			} else {
				Navigation.dashboardTab();
				// this.props.showStylishToast({ stylishToastMessage: 'Send', stylishToastIcon: 'check' });
			}
		}
	};

	_forwardMesssage = async (forwordedMsg: any, message: string) => {
		const { selectedContactsId } = this.state;
		let singleContact: ContactItem | undefined;

		if (selectedContactsId.length === 1) {
			singleContact = this.props.contact.contacts?.find((c: ContactItem) => c.username === selectedContactsId[0]);
		}
		let conversationType = ChatType.P2P;

		for (const receiverId of selectedContactsId) {
			info('receiverId::', receiverId);
			await xmpp.forwardMessage({
				receiverId,
				conversationType: conversationType,
				messageToForward: forwordedMsg,
			});
		}

		if (singleContact) {
			// Load message history for selected contact
			await this.props.initChat({
				receiver: { ...singleContact, handlerText: locale.dashboard.start_chat },
				type: ChatType.P2P,
			});
			Navigation.chat({ receiver: singleContact! });
			store.dispatch(
				showToast({
					toastMessage: '<img src="../assets/icon/right-thread.svg" />   Forwarded',
					toastType: 'secondary',
					toastButtonText: 'UNDO',
					toastCallBack: () => {
						store.dispatch(
							showToast({
								toastMessage: '<img src="../assets/icon/right-thread.svg" />   Cancelled',
								toastType: 'secondary',
							})
						);
						info('toastCallBack::undo');
					},
				})
			);
		} else {
			Navigation.dashboardTab();
			store.dispatch(
				showToast({
					toastMessage: '<img src="../assets/icon/right-thread.svg" />   Forwarded',
					toastType: 'secondary',
					toastButtonText: 'UNDO',
					toastCallBack: () => {
						store.dispatch(
							showToast({
								toastMessage: '<img src="../assets/icon/right-thread.svg" />   Cancelled',
								toastType: 'secondary',
							})
						);
						info('toastCallBack::undo');
					},
				})
			);
		}
	};

	confirmOk = () => {
		{
			this.props.contact.contacts?.map((contact: any) => {
				const cont = contact.id;
				return contact;
			});
		}
	};

	_renderChatTypeSelection = () => {
		const { pageActionType } = this.state;
		const temContactArray: any = this.state.selectedContactsId;
		const temGroupArray: any = this.state.selectedGroupsId;
		const temChatOnlyArray: any = this.state.selectedChatOnlyId;
		const temTagsArray: any = this.state.selectedTagsId;

		return (
			pageActionType !== AddMembersPageActionType.ChooseContactWithShareContact && (
				<div className={'chat-type-selection'}>
					{(pageActionType === AddMembersPageActionType.ChooseContactWithCreateNewTag ||
						pageActionType === AddMembersPageActionType.ChooseContactForGroup ||
						pageActionType === AddMembersPageActionType.ChooseContactWithShareContact ||
						pageActionType === AddMembersPageActionType.ChooseContactForTag ||
						pageActionType === AddMembersPageActionType.ChooseContactForNewProject ||
						pageActionType === AddMembersPageActionType.ChooseContactForCurrentGroup ||
						pageActionType === AddMembersPageActionType.ChooseContactForCurrentGroupInfoAdmin ||
						this.state.isAssignContact) && (
						<div
							onClick={() =>
								this.setState({
									contactsType: ContactsType.Contacts,
									isactiveContacts: true,
									isactiveChatOnly: false,
									isactiveGroups: false,
									isactiveTags: false,
								})
							}
							className={this.state.isactiveContacts ? 'type-active' : 'type-item'}
						>
							<img alt="" src={ContactIcon} />
							<p className="title">Contacts{temContactArray.length > 0 ? '(' + temContactArray.length + ')' : ''} </p>
						</div>
					)}

					{(pageActionType === AddMembersPageActionType.ChooseContactForAddChatOnlyContact ||
						pageActionType === AddMembersPageActionType.ChooseContactWithShareContact ||
						pageActionType === AddMembersPageActionType.ChooseContactForTag ||
						pageActionType === AddMembersPageActionType.ChooseContactWithCreateNewTag ||
						pageActionType === AddMembersPageActionType.ChooseContactForGroup ||
						pageActionType === AddMembersPageActionType.ChooseContactForNewProject ||
						pageActionType === AddMembersPageActionType.ChooseContactForCurrentGroup ||
						pageActionType === AddMembersPageActionType.ChooseContactForCurrentGroupInfoAdmin ||
						this.state.isAssignContact ||
						this.state.isForwardMessage) && (
						<div
							onClick={() =>
								this.setState({
									contactsType: ContactsType.GroupChats,
									isactiveGroups: true,
									isactiveChatOnly: false,
									isactiveContacts: false,
									isactiveTags: false,
								})
							}
							className={this.state.isactiveGroups ? 'type-active' : 'type-item'}
						>
							<img alt="" src={GroupsChatIcon} />
							{pageActionType !== AddMembersPageActionType.ForwardMessage ? <p className="title">Groups{temGroupArray.length > 0 ? '(' + temGroupArray.length + ')' : ''}</p> : <p className="title">Groups Chats{temGroupArray.length > 0 ? '(' + temGroupArray.length + ')' : ''}</p>}
						</div>
					)}
					{(pageActionType === AddMembersPageActionType.ChooseContactForAddChatOnlyContact ||
						pageActionType === AddMembersPageActionType.ChooseContactWithCreateNewTag ||
						pageActionType === AddMembersPageActionType.ChooseContactWithShareContact ||
						pageActionType === AddMembersPageActionType.ChooseContactForGroup ||
						pageActionType === AddMembersPageActionType.ChooseContactForTag ||
						pageActionType === AddMembersPageActionType.ChooseContactForNewProject ||
						pageActionType === AddMembersPageActionType.ChooseContactForCurrentGroup ||
						pageActionType === AddMembersPageActionType.ChooseContactForCurrentGroupInfoAdmin ||
						this.state.isAssignContact ||
						this.state.isForwardMessage) && (
						<div
							onClick={() =>
								this.setState({
									contactsType: ContactsType.ChatsOnly,
									isactiveChatOnly: true,
									isactiveContacts: false,
									isactiveGroups: false,
									isactiveTags: false,
								})
							}
							className={this.state.isactiveChatOnly ? 'type-active' : 'type-item'}
						>
							<img alt="" src={ChatsOnlyIcon} />
							<p className="title">Chats Only{temChatOnlyArray.length > 0 ? '(' + temChatOnlyArray.length + ')' : ''}</p>
						</div>
					)}
					{(pageActionType === AddMembersPageActionType.ChooseContactForGroup || pageActionType === AddMembersPageActionType.ChooseContactForCurrentGroup || pageActionType === AddMembersPageActionType.ChooseContactForCurrentGroupInfoAdmin || this.state.isForwardMessage) && (
						<div
							className={this.state.isactiveTags ? 'type-active' : 'type-item'}
							onClick={() =>
								this.setState({
									contactsType: ContactsType.Tags,
									isactiveTags: true,
									isactiveGroups: false,
									isactiveContacts: false,
									isactiveChatOnly: false,
								})
							}
						>
							<img alt="" src={TaggedFilled} />
							<p className="title">Tags{temTagsArray.length > 0 ? '(' + temTagsArray.length + ')' : ''} </p>
						</div>
					)}
				</div>
			)
		);
	};

	newProjectContact = () => {
		const request: NewProject = {
			user: 'demo',
			profession: 'demo1',
			profilePhoto: 'demo2',
		};

		this.props.addContactToNewProject({ request }).finally(() => {
			Navigation.back();
		});
	};

	_handleDoneButtonClick = () => {
		const { pageActionType, selectedContactsId, selectedGroupsId, selectedChatOnlyId, previousSelectedContactsIds } = this.state;
		let list: any = [];
		const allSelectedItems = [...selectedContactsId, ...selectedGroupsId, ...selectedChatOnlyId];
		list = previousSelectedContactsIds.concat(allSelectedItems);

		if (pageActionType === AddMembersPageActionType.ChooseContactForGroup) {
			Navigation.openGroupManager({
				selectedContactIds: allSelectedItems,
			});
		} else if (pageActionType === AddMembersPageActionType.ChooseContactForCurrentGroupInfoAdmin) {
			const groupDetail = this.navParams.data as GroupItem;

			this.props
				.addMembersToGroup({
					mucId: groupDetail.name,
					users: allSelectedItems.join(','),
				})
				.finally(() => {
					Navigation.back();
				});
		} else if (pageActionType === AddMembersPageActionType.ChooseContactForCurrentGroup) {
			if (allSelectedItems.length > 0) {
				Navigation.openGroupManager({
					selectedContactIds: list,
				});
			}
		} else if (pageActionType === AddMembersPageActionType.ChooseContactForOwnerShip) {
			this.setState({ showAlert: true });
			// Navigation.openGroupInfo();
		} else if (pageActionType === AddMembersPageActionType.ChooseContactForSecondaryAdmin || pageActionType === AddMembersPageActionType.ChooseContactForTertiaryAdmin) {
			Navigation.openGroupAdmin();
		} else if (pageActionType === AddMembersPageActionType.ChooseContactWithShareContact) {
			this.setState({ isMultiple: true });
			if (this.state.isMultiple) {
				this.setState({ openShareMessageModal: true });
			}
		} else if (pageActionType === AddMembersPageActionType.ChooseContactForForwordSketchpad || this.state.pageActionType === AddMembersPageActionType.ForwardMessage) {
			this.setState({ openShareMessageModal: true });
		} else if (pageActionType === AddMembersPageActionType.ChooseContactForTag) {
			let selectedContacts = this.props.contact.contacts?.filter((contact) => list.indexOf(contact.username) !== -1);
			let tagDetaildata: any;
			if (selectedContacts && selectedContacts.length > 0) {
				tagDetaildata = {
					contacts: selectedContacts,
					tag: this.navParams.tagName,
					hide: '',
				};
			}
			Navigation.openTagInfo({
				tagDetail: tagDetaildata,
			});
		} else if (pageActionType === AddMembersPageActionType.ChooseContactWithCreateNewTag) {
			this.createNewTag();
		} else if (pageActionType === AddMembersPageActionType.ChooseContactForAddChatOnlyContact) {
			this.props
				.changePrivacySetting({
					users: allSelectedItems,
					privacySetting: ContactPrivacySettingType.CHATS_ONLY,
				})
				.finally(() => {
					Navigation.back();
				});
		}
	};

	render() {
		const { contactsType, pageActionType, selectedContactsId, selectedChatOnlyId, selectedGroupsId, previousSelectedContactsIds } = this.state;

		const contacts = this.props.contact.contacts;

		let alphabetsList = contacts
			? contacts.map((contact: ContactItem) => {
					const contactName = contact?.alias || contact.userInfo.profileName;
					return contactName.charAt(0);
			  })
			: [];
		alphabetsList = _.uniq(alphabetsList).sort();

		let pageTitle = '',
			rightBtnTitle = 'Done()',
			rightButtonDisable = true,
			showSelectedContactsView = false,
			rightButtonColor = {};

		const allSelectedItems = [...selectedContactsId, ...selectedGroupsId, ...selectedChatOnlyId];

		// if (pageActionType === AddMembersPageActionType.ChooseContactForTag) {
		// 	if (allSelectedItems?.length > 0) {
		// 		showSelectedContactsView = true;

		// 		rightBtnTitle = 'Done (' + allSelectedItems.length + ')';
		// 		rightButtonDisable = false;
		// 	}

		// 	if (contactsType === ContactsType.All) {
		// 		pageTitle = 'Contacts';
		// 	} else if (contactsType === ContactsType.GroupChats) {
		// 		pageTitle = 'Groups';
		// 	} else if (contactsType === ContactsType.Contacts) {
		// 		pageTitle = 'Contacts';
		// 	} else {
		// 		pageTitle = 'Chats Only';
		// 	}
		// }

		let containerClass = '';

		if (pageActionType === AddMembersPageActionType.ChooseContactForTag) {
			pageTitle = 'Add to Tag';

			if (allSelectedItems?.length > 0) {
				containerClass = ' for-add-tag-selected-contacts';
				showSelectedContactsView = true;
				rightButtonDisable = false;
				rightBtnTitle = 'Done (' + allSelectedItems.length + ')';
			}
		} else if (pageActionType === AddMembersPageActionType.ChooseContactForAddChatOnlyContact) {
			containerClass = ' for-chat-only-contact';

			const navData = this.navParams.data;
			if (navData.type === ContactsListType.THERE_MOMENTS) {
				pageTitle = 'Hide Their Posts';
			} else if (navData.type === ContactsListType.MY_MOMENTS) {
				pageTitle = 'Hide My Posts';
			}
			rightBtnTitle = 'Done (' + ')';
			pageTitle = 'Contacts';

			if (allSelectedItems?.length > 0) {
				rightBtnTitle = 'Add (' + allSelectedItems.length + ')';
				containerClass = ' for-chat-only-contact-with-selected-contact';
				showSelectedContactsView = true;
				rightButtonDisable = false;
			}
		} else if (pageActionType === AddMembersPageActionType.ChooseContactForOwnerShip) {
			pageTitle = 'Select New Owner';
			rightBtnTitle = 'Done';
			containerClass = ' choose-contact-for-ownership';
			if (allSelectedItems?.length > 0) {
				rightButtonDisable = false;
			}
		} else if (pageActionType === AddMembersPageActionType.ChooseContactForSecondaryAdmin) {
			pageTitle = 'Secondary Admin';
			rightBtnTitle = 'Done';
			containerClass = ' choose-contact-for-secondaryadmin';

			if (allSelectedItems?.length > 0) {
				containerClass = ' selected-contact-for-secondaryadmin';
				showSelectedContactsView = true;
				rightButtonDisable = false;
			}
		} else if (pageActionType === AddMembersPageActionType.ChooseContactForTertiaryAdmin) {
			pageTitle = 'Tertiary Admin';
			rightBtnTitle = 'Done';
			containerClass = ' choose-contact-for-secondaryadmin';

			if (allSelectedItems?.length > 0) {
				containerClass = ' selected-contact-for-secondaryadmin';
				showSelectedContactsView = true;
				rightButtonDisable = false;
			}
		} else if (pageActionType === AddMembersPageActionType.ChooseContactWithCreateNewTag) {
			pageTitle = 'New Tag';
			containerClass = ' for-create-new-tag';

			if (allSelectedItems?.length > 0) {
				containerClass = ' for-create-new-tag-with-selected-contact';
				rightButtonDisable = false;
				showSelectedContactsView = true;
				rightBtnTitle = 'Done (' + allSelectedItems.length + ')';
				if (allSelectedItems?.length > 0 && this.state.tagName) {
					rightButtonDisable = false;
				}
			}
		} else if (this.state.isAssignContact) {
			pageTitle = 'Assign Contact';
			if (this.state.isactiveGroups) {
				pageTitle = 'Assign Group';
			}
			rightBtnTitle = 'Done';
			// if (allSelectedItems?.length > 0) {
			// 	rightButtonDisable = false;
			// 	showSelectedContactsView = true;
			// 	rightBtnTitle = 'Done (' + allSelectedItems.length + ')';
			// }
		} else if (pageActionType === AddMembersPageActionType.ChooseContactForNewProject) {
			pageTitle = 'Assign Contact';
			rightBtnTitle = 'Done';

			if (allSelectedItems?.length > 0) {
				showSelectedContactsView = true;
				rightButtonDisable = false;
				containerClass = ' selected-contact';
			}
			if (this.state.isactiveGroups) {
				pageTitle = 'Assign Group';
			}
		} else if (pageActionType === AddMembersPageActionType.ChooseContactForForwordSketchpad || this.state.pageActionType === AddMembersPageActionType.ForwardMessage) {
			pageTitle = 'Select Chat';
			if (this.state.pageActionType === AddMembersPageActionType.ForwardMessage) {
				containerClass = ' for-forward-message';
			}
			if (this.state.pageActionType === AddMembersPageActionType.ChooseContactForForwordSketchpad || this.state.isAssignContact) {
				containerClass = ' for-forward-sketchpad';
				rightBtnTitle = 'Done (' + allSelectedItems.length + ')';
			}
			if (this.state.isForwardMessage) {
				pageTitle = 'Contacts';
				rightBtnTitle = 'Done (' + allSelectedItems.length + ')';
			}
			if (this.state.isactiveGroups) {
				pageTitle = 'Groups';
				rightBtnTitle = 'Done (' + allSelectedItems.length + ')';
			}
			if (this.state.isactiveTags) {
				pageTitle = 'Tags';
			}
			if (this.state.isactiveChatOnly) {
				pageTitle = 'Chat Only';
				containerClass = ' for-chat-only-contact';
			}
			if (allSelectedItems?.length > 0) {
				containerClass = ' forward-message-with-selected-contact';
				rightButtonDisable = false;
				showSelectedContactsView = true;
				rightBtnTitle = 'Done (' + allSelectedItems.length + ')';
			}

			if (allSelectedItems?.length === 11) {
				showSelectedContactsView = true;
				rightButtonDisable = false;
				rightBtnTitle = 'Done (11)';
			}
		} else if (pageActionType === AddMembersPageActionType.ChooseContactWithAdditionalOptionShareConatact || pageActionType === AddMembersPageActionType.ChooseContactForShareContactInGroup) {
			pageTitle = 'Selecte Contact';
			containerClass = ' for-share-conatct';
			rightBtnTitle = '';
		} else if (pageActionType === AddMembersPageActionType.ChooseContactWithShareContact) {
			pageTitle = 'Select a Chat';
			rightBtnTitle = 'Multiple';
			rightButtonColor = { '--color': '#000000', fontWeight: '400' };
			rightButtonDisable = false;
			containerClass = ' for-share-conatct';

			if (this.state.isMultiple) {
				rightButtonDisable = true;

				if (allSelectedItems?.length > 0) {
					showSelectedContactsView = true;
					rightButtonDisable = false;
					containerClass = ' for-share-conatct-with-selected-contact';
				}
				pageTitle = 'Select Multiple Chats';
				rightBtnTitle = 'Done (' + allSelectedItems.length + ')';
				rightButtonColor = {};
			}
			if (allSelectedItems?.length === 10) {
				showSelectedContactsView = true;
				rightButtonDisable = false;
				rightBtnTitle = 'Done (9)';
			}
		} else if (pageActionType === AddMembersPageActionType.ChooseContactForGroup || pageActionType === AddMembersPageActionType.ChooseContactForCurrentGroup || pageActionType === AddMembersPageActionType.ChooseContactForCurrentGroupInfoAdmin) {
			// let state: any = this.props.history.location.state;
			pageTitle = 'Create Group';
			if (allSelectedItems?.length > 0) {
				containerClass = ' selected-contact';
				showSelectedContactsView = true;
				if (pageActionType === AddMembersPageActionType.ChooseContactForCurrentGroup || pageActionType === AddMembersPageActionType.ChooseContactForCurrentGroupInfoAdmin) {
					rightBtnTitle = 'Done (' + allSelectedItems.length + ')';
					if (allSelectedItems?.length > 0) {
						rightButtonDisable = false;
					}
				} else {
					rightBtnTitle = 'Next (' + allSelectedItems.length + ')';
					if (allSelectedItems?.length > 1) {
						rightButtonDisable = false;
					}
				}
			} else {
				if (pageActionType === AddMembersPageActionType.ChooseContactForCurrentGroup || pageActionType === AddMembersPageActionType.ChooseContactForCurrentGroupInfoAdmin) {
					rightBtnTitle = 'Done ()';
				} else {
					rightBtnTitle = 'Next ()';
				}
			}
		}

		const isHideSearchBar = pageActionType === AddMembersPageActionType.ChooseContactWithCreateNewTag;
		let finalContactsList;
		if (this.state.pageActionType === AddMembersPageActionType.ChooseContactWithShareContact || pageActionType === AddMembersPageActionType.ChooseContactWithAdditionalOptionShareConatact || pageActionType === AddMembersPageActionType.ChooseContactForShareContactInGroup) {
			finalContactsList = this.props.contact.contacts?.filter((_contact) => {
				return _contact.username !== this.navParams.data?.username;
			});
		} else if (this.state.pageActionType === AddMembersPageActionType.ChooseContactForForwordSketchpad || this.state.pageActionType === AddMembersPageActionType.ForwardMessage) {
			finalContactsList = this.props.contact.contacts?.filter((_contact) => {
				return _contact.username !== this.navParams.data?.username;
			});
		} else if (this.state.pageActionType === AddMembersPageActionType.ChooseContactWithCreateNewTag) {
			finalContactsList = contacts;
		}
		// if (this.state.isactiveGroups) {
		// 	finalContactsList = contacts ? contacts.filter((_contact: ContactItem) => _contact.alias) : [];
		// }
		else if (this.state.isactiveContacts) {
			finalContactsList = contacts ? contacts.filter((_contact: ContactItem) => _contact.alias) : [];
		}
		// else if (this.state.isactiveChatOnly) {
		// 	finalContactsList = this.state.contacts.filter((_contact: ContactItem) => _contact.type === 'chat' && _contact.privacySetting === ContactPrivacySettingType.CHATS_ONLY);
		// }
		// else if (this.state.isactiveContacts) {
		// finalContactsList = contacts;
		// finalContactsList = this.state.contacts.filter((_contact: ContactItem) => (_contact.type === 'chat' || _contact.type === undefined) && _contact.privacySetting === ContactPrivacySettingType.MOMENTS);
		// }
		return (
			<>
				<IonPage className="add-members-page">
					<TopNavbar
						{...this.props}
						leftButtonIcon={CloseIcon}
						showBack={
							pageActionType === AddMembersPageActionType.ChooseContactForCurrentGroup ||
							pageActionType === AddMembersPageActionType.ChooseContactForCurrentGroupInfoAdmin ||
							pageActionType === AddMembersPageActionType.ChooseContactWithShareContact ||
							pageActionType === AddMembersPageActionType.ChooseContactWithAdditionalOptionShareConatact ||
							pageActionType === AddMembersPageActionType.ChooseContactForShareContactInGroup
								? false
								: true
						}
						containerClassName={pageTitle !== 'Select Chat' ? 'selection-mode-topbar' : ''}
						pageTitle={pageTitle}
						rightButtonText={rightBtnTitle}
						rightButtonColor={rightButtonColor}
						searchTypeHandler={this.setSearchText}
						rightButtonDisabled={rightButtonDisable}
						onSearchBarFocus={() => {}}
						hideSearchBar={isHideSearchBar}
						onLeftButtonPress={() => {
							Navigation.back();
						}}
						onRightButtonPress={this._handleDoneButtonClick}
						appendAdditionalViewOnBottom={
							<>
								{pageActionType === AddMembersPageActionType.ChooseContactWithCreateNewTag && (
									<IonList className="create-new-tag-list" lines="none">
										<IonItem className="tagname-title-item">
											<div>Tag Name</div>
										</IonItem>
										<IonItem className="tag-field-item" lines="none">
											<IonInput
												// value={this.state.tagName}
												id="tagnameinputfield"
												placeholder="Enter Tag name"
												className="tag-input"
												onIonChange={(event) => {
													this.setState({ tagName: String(event.detail.value) });
												}}
												clearInput={false}
											/>
										</IonItem>
									</IonList>
								)}

								{showSelectedContactsView && (
									<div className={'selected-contacts-container'} id="selectedContactsContainer">
										{contacts &&
											contacts.map((_contact: ContactItem, key: number) => {
												const contactIndex = allSelectedItems.indexOf(_contact.username);
												if (contactIndex === -1) return null;
												const profilePhoto = SharedService.extractProfilePhoto(_contact.userInfo.profilePhotos)?.url || DefaultProfilePic;
												return (
													<React.Fragment key={key}>
														{pageActionType === AddMembersPageActionType.ChooseContactForNewProject ? (
															<div className="new-project-container">
																<div className={'contact-item-new-project'} key={key} onClick={() => this._onContactSelect(_contact)}>
																	<img alt="" src={SharedService.fixBase64String(profilePhoto)} />
																	<div className="user-name">
																		<div className="name">{_contact.username}</div>
																		<div className="profession">{_contact.userInfo.profession} </div>
																	</div>
																</div>

																<div className="close-icon" slot="end" onClick={() => this._onContactSelect(_contact)}>
																	<IonIcon src={CloseIcon}></IonIcon>
																</div>
															</div>
														) : (
															<div className={'contact-item'} key={key} onClick={() => this._onContactSelect(_contact)}>
																<img alt="" src={SharedService.fixBase64String(profilePhoto)} />
																<div className="title"> {_contact.username}</div>
															</div>
														)}
													</React.Fragment>
												);
											})}
									</div>
								)}

								{this._renderChatTypeSelection()}
							</>
						}
					/>

					<IonContent
						id="add-members-content"
						className={(isHideSearchBar ? 'has-topbar' : 'has-topbar-with-searchbar') + ' ' + containerClass}
						style={{
							'--background': contactsType === ContactsType.All ? '#fff' : 'transparent',
						}}
					>
						{(this.state.contactsType === ContactsType.ChatsOnly || this.state.isactiveChatOnly) && (
							<div className={!showSelectedContactsView ? 'chat-only-view-container' : 'chat-only--view-selected'}>
								<div className="chats-only-msg">'Chats Only' friends are excluded from seeing any personal information, Profile Photos, or your moments.</div>
							</div>
						)}

						{!this.state.isactiveChatOnly &&
							(pageActionType === AddMembersPageActionType.ChooseContactWithShareContact || pageActionType === AddMembersPageActionType.ChooseContactForForwordSketchpad || this.state.pageActionType === AddMembersPageActionType.ForwardMessage) &&
							(!this.state.isForwardMessage || !this.state.isAssignContact) && (
								<div className={this.state.pageActionType === AddMembersPageActionType.ForwardMessage ? 'recent-container-forword-message' : 'recent-container'}>
									<IonLabel className="recent">Recent Chats</IonLabel>
									{(pageActionType === AddMembersPageActionType.ChooseContactForForwordSketchpad || pageActionType === AddMembersPageActionType.ForwardMessage) && (
										<IonIcon
											className="add-contact"
											onClick={() => {
												pageActionType === AddMembersPageActionType.ForwardMessage ? this.setState({ isForwardMessage: true }) : this.setState({ isAssignContact: true });
											}}
											src={AddContactList}
										></IonIcon>
									)}
								</div>
							)}

						{(this.state.pageActionType === AddMembersPageActionType.ChooseContactWithShareContact ||
							pageActionType === AddMembersPageActionType.ChooseContactWithAdditionalOptionShareConatact ||
							pageActionType === AddMembersPageActionType.ChooseContactForShareContactInGroup ||
							this.state.pageActionType === AddMembersPageActionType.ChooseContactForForwordSketchpad ||
							this.state.pageActionType === AddMembersPageActionType.ForwardMessage ||
							this.state.pageActionType === AddMembersPageActionType.ChooseContactForShareContactInGroup) && (
							<ShareMessageModal
								shareMultiTypeData={this.state.pageActionType === AddMembersPageActionType.ChooseContactWithAdditionalOptionShareConatact ? this.navParams.data.userInfo : this.navParams.data && this.navParams.data}
								actionType={this.state.pageActionType}
								sharingType={
									this.state.pageActionType === AddMembersPageActionType.ChooseContactWithShareContact ||
									this.state.pageActionType === AddMembersPageActionType.ChooseContactWithAdditionalOptionShareConatact ||
									this.state.pageActionType === AddMembersPageActionType.ChooseContactForForwordSketchpad ||
									this.state.pageActionType === AddMembersPageActionType.ChooseContactForShareContactInGroup
										? 'contact'
										: 'chat'
								}
								show={this.state.openShareMessageModal}
								shareData={
									this.state.pageActionType === AddMembersPageActionType.ChooseContactForShareContactInGroup
										? this.navParams.data.mucName
										: this.state.pageActionType === AddMembersPageActionType.ChooseContactWithAdditionalOptionShareConatact
										? this.navParams.data?.userInfo?.profileName
										: this.navParams.data?.profileName
								}
								contacts={finalContactsList}
								selectedContacts={[...previousSelectedContactsIds, ...(this.state.isactiveContacts ? selectedContactsId : this.state.isactiveGroups ? selectedGroupsId : selectedChatOnlyId)]}
								onClose={() => this.setState({ openShareMessageModal: false, selectedContactsId: '' })}
								onSend={(message: any) => {
									if (this.state.pageActionType === AddMembersPageActionType.ChooseContactForForwordSketchpad) {
										const file = SharedService.dataURLtoFile(this.navParams.data, 'sketchpad.png');
										this._shareDataWithContact([file], message);
									} else if (
										this.state.pageActionType === AddMembersPageActionType.ChooseContactWithShareContact ||
										this.state.pageActionType === AddMembersPageActionType.ChooseContactWithAdditionalOptionShareConatact ||
										this.state.pageActionType === AddMembersPageActionType.ChooseContactForShareContactInGroup
									) {
										this._shareContact(this.navParams.data, this.state.pageActionType, message);
									} else if (this.state.pageActionType === AddMembersPageActionType.ForwardMessage) {
										this._forwardMesssage(this.navParams.data, message);
									}
									this.setState({ openShareMessageModal: false });
								}}
							/>
						)}
						<div className={this.state.pageActionType === AddMembersPageActionType.ForwardMessage ? 'contact-list-component-forward' : !showSelectedContactsView ? 'contact-list-component' : 'contact-list-component-selected'}>
							<ContactsList
								props={this.props}
								showDisableContactChecked={true}
								disabledContacts={previousSelectedContactsIds}
								starredContacts={[]}
								shouldDisableGoToViewProfile={
									(pageActionType === AddMembersPageActionType.ChooseContactWithAdditionalOptionShareConatact || pageActionType === AddMembersPageActionType.ChooseContactForShareContactInGroup || pageActionType === AddMembersPageActionType.ChooseContactWithShareContact) && !this.state.isMultiple
								}
								contacts={finalContactsList || []}
								contactListMode={
									!this.state.isMultiple && (pageActionType === AddMembersPageActionType.ChooseContactWithAdditionalOptionShareConatact || pageActionType == AddMembersPageActionType.ChooseContactWithShareContact || pageActionType === AddMembersPageActionType.ChooseContactForShareContactInGroup)
										? ContactsListMode.NormalContactsList
										: ContactsListMode.ChooseContactsList
								}
								selectedContacts={[...previousSelectedContactsIds, ...(this.state.isactiveContacts ? selectedContactsId : this.state.isactiveGroups ? selectedGroupsId : selectedChatOnlyId)]}
								onContactSelect={this._onContactSelect}
								isGroupList={this.state.isactiveGroups}
							/>
						</div>

						<ContactAlbhabetView
							alphabets={alphabetsList}
							showStarredInsideAlbhabetList={true}
							onAlphabetSelect={async (albhabet: any) => {
								this.scrollToContactSection('section_' + albhabet, 'add-members-content');
							}}
							onGoToStarredContact={async () => {
								this.scrollToContactSection('section_starred', 'add-members-content');
							}}
						/>
						<IonAlert
							mode="ios"
							isOpen={this.state.alertShow}
							onDidDismiss={() => this.setState({ alertShow: false })}
							cssClass="alertShow-controller-class"
							message={pageActionType === AddMembersPageActionType.ChooseContactForForwordSketchpad || pageActionType === AddMembersPageActionType.ForwardMessage ? 'Up to 10 Contacts' : '9 chat maximum'}
							buttons={[
								{
									text: 'OK',
									role: 'destructive',
								},
							]}
						/>
					</IonContent>

					{/* <IonLoading mode="md" isOpen={this.props.dashboard.isLoading || this.state.loader} message={this.state.loader ? 'Loading' : this.props.dashboard.loaderMessage} /> */}
					<div className="alert">
						<IonAlert
							mode="ios"
							cssClass="alertShow-ownerShip"
							isOpen={this.state.showAlert}
							onDidDismiss={() => this.setState({ showAlert: false })}
							message={pageActionType === AddMembersPageActionType.ChooseContactForOwnerShip ? 'You are transfering ownership to (Username). This may not be undone.' : sharedService.groupMaxMembersLimit + ' member maximum'}
							buttons={[
								{
									text: 'Cancel',
									role: 'cancel',
								},
								{
									text: 'OK',
									role: 'destructive',
									// 	handler: () => {
									// 	this.confirmOk();
									// },
								},
							]}
						/>
					</div>
				</IonPage>
			</>
		);
	}
}

const mapStateToProps = (state: ReduxStates) => {
	return {
		profile: state.profile,
		contact: state.contact,
	};
};

const mapDispatchToProps = (dispatch: any) => ({
	showStylishToast: (payload: GlobalStateTypes) => dispatch(showStylishToast(payload)),
	initChat: (payload: any) => dispatch(initChat(payload)),
	changePrivacySetting: (payload: ChangePrivacySettingData) => dispatch(changePrivacySetting(payload)),
	addUserToTag: (payload: AddUserToTagRequestData) => dispatch(addUserToTag(payload)),
	addMembersToGroup: (payload: AddUsersToMucRequestData) => dispatch(addMembersToGroup(payload)),
	// addContactToNewProject: (payload: AddContactToNewProjectRequestData) => dispatch(addContactToNewProject(payload)),
});

export default connect(mapStateToProps, mapDispatchToProps)(AddMembers);
