import { IonIcon, IonImg, IonItem, IonList, IonText } from '@ionic/react';
import { heartSharp } from 'ionicons/icons';
import React, { useCallback } from 'react';
import { useLongPress } from 'use-long-press';
import './style.scss';

interface CommentItemProps {
	item: any;
	selected: boolean;
	onSelect: Function;
	onClick: any;
}

const CommentItem: React.FC<CommentItemProps> = ({ selected, item, onSelect, onClick }) => {
	const callback = useCallback(() => {
		onSelect();
	}, [onSelect]);

	const bind = useLongPress(callback, {
		threshold: 800,
	});

	return (
		<IonItem className="likes-item" {...bind} onClick={onClick} style={{ backgroundColor: selected ? '#C4C4C4' : '' }}>
			<IonText className="usernametxt">{item.user}</IonText>
			<IonText className="comment">{item.comment}</IonText>
		</IonItem>
	);
};

interface ContainerProps {
	selectedCommentIndex?: any;
	likes?: Array<any>;
	comments?: Array<any>;
	onCommentSelect?: Function;
	onCommentClick?: Function;
}

const LikeCommentsContainer: React.FC<ContainerProps> = ({ selectedCommentIndex, likes, comments, onCommentSelect, onCommentClick }) => {
	return (
		<div className="like-comment-container">
			<div className="comments-view">
				<IonIcon icon={heartSharp} color="danger" className="like-icon"></IonIcon>

				{likes?.map((item, key) => {
					return (
						<div className="comment-item" key={key}>
							<IonImg className="user-img" key={key} src={'/assets/img/' + item.profile}></IonImg>
						</div>
					);
				})}
			</div>

			<IonList lines="none" className="likes-view" mode="ios">
				{comments?.map((item, key) => {
					return (
						<CommentItem
							selected={selectedCommentIndex === key}
							item={item}
							key={key}
							onClick={onCommentClick}
							onSelect={() => {
								if (onCommentSelect) {
									onCommentSelect(key);
								}
							}}
						/>
					);
				})}
			</IonList>
		</div>
	);
};

export default LikeCommentsContainer;
