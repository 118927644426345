import { apiService } from '../../services/apiService';
import { SEARCH_USER_ERROR, SEARCH_USER_RESETTED, SEARCH_USER_FAILED, SEARCH_USER_REQUESTED, SEARCH_USER_SUCCESS } from '../constants/searchUser';
import { locale } from '../../locales/local';

export function resetSearchState() {
	return { type: SEARCH_USER_RESETTED };
}

export function searchPageLoading(payload: String) {
	return { type: SEARCH_USER_REQUESTED, payload: { loader: payload, loaderMessage: locale.global.searching } };
}

export function searchUserContact(payload: String) {
	return function (dispatch: any) {
		dispatch({ type: SEARCH_USER_REQUESTED, payload: { loader: true, loaderMessage: locale.global.searching } });
		apiService
			.getUsers(payload)
			.then((response: any) => {
				if (response.Error) {
					dispatch({ type: SEARCH_USER_FAILED, payload: response });
				} else {
					dispatch({ type: SEARCH_USER_SUCCESS, payload: response, showInvite: response.length > 0 || true });
				}
			})
			.catch((error: any) => {
				dispatch({ type: SEARCH_USER_ERROR, payload: error });
			});
	};
}
