import React from 'react';
import { IonToggle, IonPage, IonButton, IonIcon, IonContent, IonItem, IonList, IonInput, IonTextarea, IonLabel, IonText } from '@ionic/react';
import './style.scss';
import { checkmarkOutline, chevronForwardOutline } from 'ionicons/icons';
import { connect } from 'react-redux';
import { ChatType, ContactPrivacySettingType, ContactsListType, ProfileFriendActionTypes } from '../../services/enumService';
import ToastStylish from '../../components/ToastStylish';
import { locale } from '../../locales/local';
import { TopNavbar } from '../common/header/topbar';
import AddNewTagPopup from '../../modals/AddNewTagPopup';
import SharedService from '../../services/sharedService';
import ChooseTagsModal from '../../modals/ChooseTagsModal/ChooseTagsModal';
import { ContactStateTypes, ProfileStateTypes, ReduxStates } from '../../types/redux-states';
import { acceptInvitation, rejectInvitation, sendInvitation } from '../../redux/actions/contact';
import { AcceptInvitationRequestData, GetProfileByUsernameRequestData, InvitationItem, RejectInvitationRequestData, SendInvitationRequestData } from '../../types/api-requests';
import { ProfilePhotoItem, UserProfile } from '../../types/profile';
import { getMyTags, getProfileByUsername } from '../../redux/actions/profile';
import { DefaultProfilePic } from '../../helpers/common';
import { initChat } from '../../redux/actions/chat';
import { ContactItem } from '../../types/contact';
import { historyManagement, Navigation } from '../../services/navigationService';
import { AddContactsNavParams } from '../../types/navigation';
const CrossIcon = './assets/icon/crossicon.svg';

interface iProps {
	profile: ProfileStateTypes;
	contact: ContactStateTypes;
	getMyTags: () => Promise<unknown>;
	sendInvitation: (data: SendInvitationRequestData) => Promise<unknown>;
	acceptInvitation: (data: AcceptInvitationRequestData) => Promise<unknown>;
	rejectInvitation: (data: RejectInvitationRequestData) => Promise<unknown>;
	getProfileByUsername: (data: GetProfileByUsernameRequestData) => Promise<UserProfile>;
	initChat: (payload: any) => void;
	addContacts: any;
	loggedInUser: any;
	history: any;
	dashboard: any;
	location: any;
	confirmContact: Function;
	resetValues: Function;
	searchHandler?: any;
	searchTypeHandler?: any;
}
interface iState {
	friendProfile: UserProfile;
	personalMessage: string;
	tags: any;
	alias: string;
	friendPrivacySettingType: any;
	loggedInUser: any;
	muteNotification?: boolean;
	showChooseTagModal: boolean;
	stickOnTop?: boolean;
	starred?: boolean;
	showToast: boolean;
	toastMessage: string;
	toastIcon?: string;
	contact?: any;
	showAddTagPopup?: any;
	loadingMessage: any;
	counting: any;
}
class AddContacts extends React.Component<iProps, iState> {
	contactsPrivacySettings = [
		{
			title: locale.add_contacts.moments.title,
			description: locale.add_contacts.moments.description,
			value: ContactPrivacySettingType.MOMENTS,
		},
		{
			title: locale.add_contacts.chats_only.title,
			description: locale.add_contacts.chats_only.description,
			value: ContactPrivacySettingType.CHATS_ONLY,
		},
	];
	searchHandler: any;
	searchTypeHandler: any;
	componentIsMounted: Boolean = false;
	componentIsUpdating: Boolean = false;
	visibleFields = {
		personalMessage: false,
		tags: false,
		alias: false,
		privacySetting: false,
		contactSetting: false,
	};

	navParams: AddContactsNavParams = {} as any;

	constructor(props: iProps) {
		super(props);

		this.state = {
			showToast: false,
			showChooseTagModal: false,
			toastMessage: '',
			toastIcon: '',
			loggedInUser: undefined,
			muteNotification: false,
			stickOnTop: false,
			starred: false,
			loadingMessage: '',
			showAddTagPopup: false,
			contact: {},
			counting: false,
			friendProfile: {} as any,
			personalMessage: '',
			tags: [],
			alias: '',
			friendPrivacySettingType: null,
		};
	}

	async componentDidMount() {
		this.componentIsMounted = true;

		Navigation.getNavParamData(this.props).then(async (res: any) => {
			this.navParams = res;
			const friendProfile: UserProfile = this.navParams.data;
			this.setState({ friendProfile });

			this.setFieldsVisibility();

			await this.setPageData();

			if (!this.props.profile.myTags || this.props.profile.myTags.length === 0) {
				this.props.getMyTags();
			}

			//info('addContact::componentDidMount:end');
			if (!this.state.friendProfile && this.props.location.search) {
				const locsearch = this.props.location.search;
				const username = SharedService.getQueryStringValue(locsearch, 'username');
				const actionType = SharedService.getQueryStringValue(locsearch, 'actionType') as ProfileFriendActionTypes;
				if (username) {
					this.props
						.getProfileByUsername({ username })
						.then((friendProfile: UserProfile) => {
							this.navParams.friendProfileActionType = actionType || ProfileFriendActionTypes.ContactAdd;
							this.setState({ friendProfile, personalMessage: `${locale.add_contacts.personal_message_prefix}${this.props.profile.loggedInUserProfile?.username}`, alias: friendProfile?.profileName });
							this.setFieldsVisibility();
						})
						.catch((_: any) => {});
				}
			}
		});
	}

	setFieldsVisibility = () => {
		if (this.navParams.friendProfileActionType === ProfileFriendActionTypes.ContactAdd) {
			this.visibleFields.personalMessage = true;
			this.visibleFields.alias = true;
			this.visibleFields.tags = true;
			this.visibleFields.contactSetting = false;
			this.visibleFields.privacySetting = true;
		} else if (this.navParams.friendProfileActionType === ProfileFriendActionTypes.ContactRequestView) {
			this.visibleFields.personalMessage = false;
			this.visibleFields.alias = true;
			this.visibleFields.tags = true;
			this.visibleFields.contactSetting = false;
			this.visibleFields.privacySetting = true;
		}
	};

	async setPageData() {
		const { loggedInUserProfile } = this.props.profile;

		const savedStateData: any = await Navigation.getSavedStateData();

		if (savedStateData) {
			this.setState({
				personalMessage: savedStateData?.personalMessage || '',
				tags: savedStateData?.tags || [],
				alias: savedStateData?.alias || '',
				friendPrivacySettingType: savedStateData?.friendPrivacySettingType || null,
			});
		} else {
			this.setState({
				personalMessage: `${locale.add_contacts.personal_message_prefix}${loggedInUserProfile?.profileName}`,
				alias: this.state.friendProfile?.profileName,
			});
		}
	}

	async componentDidUpdate() {
		if (this.componentIsMounted && !this.componentIsUpdating) {
			//info('addContact::componentDidUpdate:start');
			this.componentIsUpdating = true;

			this.componentIsUpdating = false;
			//info('addContact::componentDidUpdate:end');
		} else {
			//info('addContact::componentDidUpdate:ignored');
		}
	}

	sendRequest = () => {
		const { alias, tags, friendProfile, friendPrivacySettingType } = this.state;

		const invitation: InvitationItem = { privacySetting: friendPrivacySettingType, username: friendProfile.username, alias, groups: tags.join(';'), message: this.state.personalMessage };
		this.props
			.sendInvitation({ invitation })
			.then(() => {
				this.setState({
					toastIcon: checkmarkOutline,
					toastMessage: locale.toast_messages.sent,
					showToast: true,
				});

				setTimeout(() => {
					Navigation.contactsTab();
				}, 1000);
			})
			.catch((error) => {});
	};

	confirmContact = () => {
		const { alias, tags, friendProfile, friendPrivacySettingType } = this.state;

		const invitation: InvitationItem = { privacySetting: friendPrivacySettingType, username: friendProfile.username, alias, groups: tags.join(';') };
		this.props
			.acceptInvitation({ invitation })
			.then(async (contacts: any) => {
				this.setState({
					toastIcon: checkmarkOutline,
					toastMessage: locale.toast_messages.accepted,
					showToast: true,
				});

				const contactItem: ContactItem = contacts?.find((item: ContactItem) => item.username === friendProfile.username) || friendProfile;
				await this.props.initChat({
					receiver: { ...contactItem, handlerText: locale.dashboard.start_chat },
					type: ChatType.P2P,
				});

				historyManagement.clearAll();
				Navigation.chat({ receiver: contactItem });
			})
			.catch((error) => {});
	};

	viewChatOnly = () => {
		Navigation.openContactsList({ contactsListType: ContactsListType.CHAT_ONLY });
	};

	_backHandler = () => {
		historyManagement.updateCurrentPathData({ stateData: null });
		Navigation.back();
	};

	_renderHeader() {
		return <TopNavbar {...this.props} hideSearchBar={true} onLeftButtonPress={this._backHandler} showBack={true} pageTitle={this.navParams.friendProfileActionType === ProfileFriendActionTypes.ContactAdd ? locale.add_contacts.info.send_friend_req : locale.add_contacts.info.edit_friend_req} />;
	}

	_renderSwitchItem(title: any, value: any, onChange: any) {
		return (
			<div className={'swtich-item'}>
				<IonToggle className="toggle-customised" mode="ios" value={value} onChange={onChange} />
				<IonLabel>{title}</IonLabel>
			</div>
		);
	}

	setSearchText() {}

	render() {
		const { alias, tags } = this.state,
			charLimit = 175;
		const { profilePhotos } = this.props.profile.loggedInUserChatOnlyProfile || {};
		const activeChatOnlyProfilePhoto: ProfilePhotoItem = SharedService.extractProfilePhoto(profilePhotos || [])!;

		return (
			<IonPage className="add-contact-page">
				{this._renderHeader()}

				<IonContent className="auth-wrapper contact-wrap has-bottom-navbar has-topbar" fullscreen>
					<IonList style={{ background: 'transparent' }}>
						{this.visibleFields.personalMessage && (
							<>
								<IonItem lines="none" className="Greetings">
									<div className="greeting-textcount">
										<IonLabel position="stacked">Greetings Message</IonLabel>
										{this.state.counting ? <IonText className="reaminig-letter-label">{this.state.personalMessage ? charLimit - this.state.personalMessage.length : charLimit}</IonText> : ''}
									</div>

									<IonTextarea
										rows={3}
										cols={20}
										value={this.state.personalMessage}
										onFocus={() => {
											this.setState({ counting: true });
										}}
										onBlur={() => {
											setTimeout(() => {
												this.setState({ counting: false });
											}, 100);
										}}
										onIonChange={(e: any) => {
											if (charLimit && e.detail.value && charLimit < e.detail.value.length) {
												this.setState({
													personalMessage: this.state.personalMessage!,
												});
											} else {
												this.setState({ personalMessage: e.detail.value! });
											}
										}}
									></IonTextarea>
								</IonItem>

								<IonItem lines="none">
									{this.state.counting ? (
										<IonIcon
											src={CrossIcon}
											slot="end"
											className="cross"
											onClick={() => {
												this.setState({ personalMessage: '' });
											}}
										></IonIcon>
									) : (
										''
									)}{' '}
								</IonItem>
							</>
						)}

						{this.visibleFields.alias && (
							<IonItem lines="none" className="alias-input">
								<IonLabel position="stacked">
									{locale.add_contacts.label.set_alias} for {this.state.friendProfile?.username}
								</IonLabel>
								<IonInput style={{ color: '#000000' }} disabled={false} value={alias} onIonChange={(e) => this.setState({ alias: e.detail.value! })}></IonInput>
							</IonItem>
						)}

						{this.visibleFields.tags && (
							<IonItem lines="none">
								<IonLabel position="stacked" className="set-tag">
									{locale.add_contacts.label.set_tags}
								</IonLabel>
								<IonItem
									lines="none"
									className="tags-inner-item"
									onClick={() => {
										if (this.props.profile?.myTags && this.props.profile?.myTags.length > 0) {
											this.setState({ showChooseTagModal: true });
										} else {
											this.setState({ showAddTagPopup: true });
										}
									}}
								>
									<div className="tag-input">
										<div className="inner-tag-input">
											{!tags || tags.length === 0 ? (
												<div className="placeholder-txt">Classify Contacts by Tags</div>
											) : (
												<>
													<div className="tagname">{tags.join()},</div>

													<div className="add-tag">add tags</div>
												</>
											)}
										</div>

										<IonIcon icon={chevronForwardOutline}></IonIcon>
									</div>
								</IonItem>
							</IonItem>
						)}

						{this.visibleFields.contactSetting && (
							<div className="setting-toggle-container">
								{this._renderSwitchItem(locale.add_contacts.info.mute_notifications, this.state.muteNotification, () => {
									this.setState({
										muteNotification: !this.state.muteNotification,
									});
								})}
								<div className="vertical-line"></div>
								{this._renderSwitchItem(locale.add_contacts.info.stick_on_top, this.state.stickOnTop, () => {
									this.setState({ stickOnTop: !this.state.stickOnTop });
								})}
								<div className="vertical-line"></div>
								{this._renderSwitchItem(locale.add_contacts.info.starred, this.state.starred, () => {
									this.setState({ starred: !this.state.starred });
								})}
							</div>
						)}

						{this.visibleFields.privacySetting && (
							<>
								<br />
								<IonLabel className="privacy-title">{locale.add_contacts.label.privacy_settings}</IonLabel>

								{this.contactsPrivacySettings.map((item, key) => {
									const isSelected = this.state.friendPrivacySettingType === item.value;
									return (
										<div
											key={key}
											className={'privacy-item ' + (isSelected ? 'selected' : '')}
											onClick={() => {
												this.setState({ friendPrivacySettingType: item.value });
											}}
										>
											<IonLabel>
												<h2>{item.title}</h2>
												<p>{item.description}</p>
											</IonLabel>
											{item.value === ContactPrivacySettingType.CHATS_ONLY && (
												<IonItem
													detail={true}
													lines="none"
													className={'right' + (isSelected ? 'right-selected' : '')}
													onClick={() => {
														historyManagement.updateCurrentPathData({
															stateData: {
																personalMessage: this.state.personalMessage,
																tags: this.state.tags,
																alias: this.state.alias,
																friendPrivacySettingType: this.state.friendPrivacySettingType,
															},
														});
														Navigation.openProfileUploadPhotoChatOnly();
													}}
												>
													<div className="profile-container">
														<img src={activeChatOnlyProfilePhoto ? activeChatOnlyProfilePhoto?.url : DefaultProfilePic} className="profile-image-container" />
														<div className="profile-title-container">
															<div className={'progile-photo-title' + (isSelected ? 'selected' : '')}> Profile Photo</div>
															<div className={'tap-change' + (isSelected ? 'tap-selected' : '')}>Tap to change</div>
														</div>
													</div>
												</IonItem>
											)}
										</div>
									);
								})}

								{/* {this.props.location.state?.addAnother && (
									<IonItem detail={true} lines="none" onClick={this.viewChatOnly}>
										<IonLabel>Set Chat Only profile photo</IonLabel>
										<div className="chat-image">
											<IonImg src={DefaultProfilePic} />
										</div>
									</IonItem>
								)} */}
							</>
						)}
					</IonList>
					<div className="send-div">
						{this.navParams.friendProfileActionType === ProfileFriendActionTypes.ContactAdd && (
							<IonButton className="send-button-buttom" disabled={!this.state.friendPrivacySettingType || !this.state.personalMessage} onClick={this.sendRequest}>
								{locale.add_contacts.info.send}
							</IonButton>
						)}

						{this.navParams.friendProfileActionType === ProfileFriendActionTypes.ContactRequestView && (
							<div className="buttons">
								<IonButton className="send-button-buttom" disabled={!this.state.friendPrivacySettingType} onClick={this.confirmContact}>
									Accept
								</IonButton>
								<IonButton className="cancel-button-buttom" onClick={this._backHandler}>
									Cancel
								</IonButton>
							</div>
						)}
					</div>

					{/* {this.props.auth.hasErrors ? <><IonRow><IonCol><p className="warnings">{this.props.auth.errorMessage}</p></IonCol></IonRow></> : null} */}
				</IonContent>

				{/* <IonLoading isOpen={this.props.addContacts.isLoading} onDidDismiss={() => store.dispatch({ type: ADD_USER_SHOW_LOADER, payload: false })} message={locale.global.please_wait} duration={5000} />
				<IonLoading isOpen={this.props.dashboard.isLoading} message={this.props.dashboard.loaderMessage} /> */}
				{/* <IonLoading mode="md" isOpen={this.state.isLoading} message={'Creating...'} /> */}

				<ToastStylish
					show={this.state.showToast}
					message={this.state.toastMessage}
					svgIcon={this.state.toastIcon}
					onClose={() => {
						if (this.componentIsMounted) {
							this.setState({
								showToast: false,
								toastIcon: '',
								toastMessage: '',
							});
						}
					}}
				/>
				<AddNewTagPopup
					show={this.state.showAddTagPopup}
					onCloseCallBack={() => {
						this.setState({ showAddTagPopup: false });
					}}
					onTagCreated={(value: string) => {
						const tmpTagsList = this.state.tags;
						tmpTagsList.push(value);
						this.setState({ tags: tmpTagsList, showAddTagPopup: false });
					}}
				/>

				{/* <SearchContactsModal
					searchTypeHandler={this.setSearchText}
					searchHandler={(event: any) => {
s					}}
					show={this.state.showSearchContactsModal}
					onClose={() => this.setState({ showSearchContactsModal: false })}
				/> */}

				<ChooseTagsModal
					props={this.props}
					loggedInUser={this.props.profile.loggedInUserProfile}
					selectedTags={this.state.tags}
					show={this.state.showChooseTagModal}
					onCancel={() => {
						this.setState({ showChooseTagModal: false });
					}}
					onDone={(updatedTags: any) => {
						this.setState({ showChooseTagModal: false, tags: updatedTags });
					}}
					onTagCreated={(value: string) => {
						const tmpTagsList = this.state.tags;
						tmpTagsList.push(value);
						this.setState({ tags: tmpTagsList, showAddTagPopup: false });
					}}
				/>
			</IonPage>
		);
	}
}

const mapStateToProps = (state: ReduxStates) => {
	return {
		profile: state.profile,
		contact: state.contact,

		loggedInUser: state.global.loggedInUser,
		isLoggedIn: state.auth.isLoggedIn,
	};
};
const mapDispatchToProps = (dispatch: any) => ({
	getMyTags: () => dispatch(getMyTags()),
	initChat: (payload: any) => dispatch(initChat(payload)),
	sendInvitation: (payload: SendInvitationRequestData) => dispatch(sendInvitation(payload)),
	acceptInvitation: (payload: AcceptInvitationRequestData) => dispatch(acceptInvitation(payload)),
	rejectInvitation: (payload: RejectInvitationRequestData) => dispatch(rejectInvitation(payload)),
	getProfileByUsername: (payload: GetProfileByUsernameRequestData) => dispatch(getProfileByUsername(payload)),
});

export default connect(mapStateToProps, mapDispatchToProps)(AddContacts);
