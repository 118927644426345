import { combineReducers } from 'redux';
import pubsub from './pubsub';
import auth from './auth';
import global from './global';
import register from './register';
import dashboard from './dashboard';
import xmpp from './xmpp';
import chat from './chat';
import profile from './profile';
import contact from './contact';
import group from './group';
import searchContact from './searchContact';
import addContacts from './addContacts';
import thread from './thread';

export default combineReducers({ contact, global, pubsub, group, auth, register, dashboard, xmpp, chat, profile, searchContact, addContacts, thread });
