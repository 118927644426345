import { PUBSUB_RESET_VALUES, PUBLISH_DATA } from '../constants/common';

const initialState = {
	publishType: '',
	publishedData: null,
};

// eslint-disable-next-line import/no-anonymous-default-export
export default function (state = initialState, action: any) {
	let fullState: any;

	switch (action.type) {
		case PUBSUB_RESET_VALUES: {
			fullState = {
				publishType: '',
				publishedData: null,
			};
			break;
		}
		case PUBLISH_DATA: {
			fullState = {
				...state,
				isLoading: action.payload,
				publishType: action.payload.type,
				publishedData: action.payload.data,
			};
			break;
		}

		default:
			fullState = state;
	}
	return fullState;
}
