import React, { useMemo } from 'react';
import { IonIcon, IonLabel, IonTabBar, IonTabButton } from '@ionic/react';
import './style.scss';
import { locale } from '../../../locales/local';
import NotificationBadge from '../../../components/NotificationBadge';
import { Link } from 'react-router-dom';

interface ContainerProps {
	unreadCount?: number;
	history?: any;
	dashboard?: any;
	ispressAdd?: Function;
	isPressStar?: Function;
}

// const defaultProps: ContainerProps = {
// 	unreadCount: 0,
// };

export const BottomNavbar: React.FC<ContainerProps> = (props: any) => {
	const extractPath = props.location?.pathnamonRightButtonPresse?.replace(/^[/]/, '');

	const newContactRequestCount = useMemo(() => (props.dashboard?.conversations ? props.dashboard.conversations.filter((_conversation: any) => _conversation.status === 'pendingConfirm') : 0), [props]).length;

	return (
		<>
			<IonTabBar key="bottombar" slot="bottom" className={'bottom-tabs-bar '}>
				<IonTabButton className={'bottom-tabs ' + (extractPath === 'auth' ? 'tab-selected' : '')}>
					<Link to="/auth" className="link"></Link>
					<IonIcon className="ionIcon1" src="/assets/icon/tab-chat-selected.svg" color="primary"></IonIcon>
					<IonIcon className="ionIcon2" src="/assets/icon/tab-chat.svg" color="primary"></IonIcon>
					<IonLabel>{locale.bottom_navbar.titles.chat}</IonLabel>
					<NotificationBadge unreadCount={props.unreadCount} />
				</IonTabButton>
				<IonTabButton className={'bottom-tabs ' + (extractPath === 'contacts' ? 'tab-selected' : '')}>
					<Link to={{ pathname: '/personal-folder', state: { filterType: 'starred' } }} className="link"></Link>
					<IonIcon className="ionIcon1" src="/assets/icon/shape-star-active.svg"></IonIcon>
					<IonIcon className="ionIcon2" src="/assets/icon/shape-star.svg"></IonIcon>
					<IonLabel>{locale.bottom_navbar.titles.starred}</IonLabel>
					{/* <NotificationBadge className="dot-circle" showOnlyCircle={true} /> */}
					<NotificationBadge unreadCount={newContactRequestCount} />
				</IonTabButton>

				<IonTabButton className={'bottom-tabs large-btn '}>
					<div
						onClick={(event: any) => {
							event.stopPropagation();
							props.ispressAdd();
						}}
					>
						<Link to="#" className="link"></Link>

						<IonIcon className="ionIcon1" src="/assets/icon/tab-add-selected.svg"></IonIcon>
						<IonIcon className="ionIcon2" src="/assets/icon/tab-add.svg"></IonIcon>
					</div>
				</IonTabButton>

				<IonTabButton className={'bottom-tabs ' + (extractPath === 'discover' ? 'tab-selected' : '')}>
					<Link to={{ pathname: '/personal-folder', state: { filterType: 'shared' } }} className="link"></Link>
					<IonIcon className="ionIcon1" src="/assets/icon/shared-search.svg"></IonIcon>
					<IonIcon className="ionIcon2" src="/assets/icon/shared-search.svg"></IonIcon>
					<IonLabel>{locale.bottom_navbar.titles.shared}</IonLabel>
					{/* <NotificationBadge className="dot-circle" showOnlyCircle={true} /> */}
				</IonTabButton>
				<IonTabButton className={'bottom-tabs ' + (extractPath === 'profile' ? 'tab-selected' : '')}>
					<Link to="/profile" className="link"></Link>
					<IonIcon className="ionIcon1" src="/assets/icon/files-1.svg"></IonIcon>
					<IonIcon className="ionIcon2" src="/assets/icon/files-1.svg"></IonIcon>
					<IonLabel>{locale.bottom_navbar.titles.files}</IonLabel>
					{/* <NotificationBadge className="dot-circle" showOnlyCircle={true} /> */}
				</IonTabButton>
			</IonTabBar>
		</>
	);
};

export default BottomNavbar;
