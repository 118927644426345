import React from 'react';
import { IonAlert, IonContent, IonLabel, IonPage, IonToggle } from '@ionic/react';
import './style.scss';
import { ContactPrivacySettingType } from '../../services/enumService';
import { RouteComponentProps } from 'react-router';
import { IonList, IonItem } from '@ionic/react';
import { connect } from 'react-redux';
import { pageLoading, updateUser } from '../../redux/actions/profile';
import { TopNavbar } from '../common/header/topbar';
import { DefaultProfilePic } from '../../helpers/common';
import { ProfilePhotoItem } from '../../types/profile';
import SharedService from '../../services/sharedService';
import { ContactStateTypes, GlobalStateTypes, ProfileStateTypes } from '../../types/redux-states';
import { locale } from '../../locales/local';
import { ChangePrivacySettingData } from '../../types/api-requests';
import { changePrivacySetting } from '../../redux/actions/contact';
import { showStylishToast } from '../../redux/actions/global';
import { Navigation } from '../../services/navigationService';
import { UserPrivacyNavParams } from '../../types/navigation';
interface iProps extends RouteComponentProps<{ name: string }> {}
interface iProps {
	profile: ProfileStateTypes;
	contact: ContactStateTypes;
	location: any;
	showStylishToast: (payload: GlobalStateTypes) => void;
	changePrivacySetting: (data: ChangePrivacySettingData) => Promise<unknown>;
}
interface iState {
	showAlert: boolean;
	friendPrivacySettingType?: ContactPrivacySettingType;
}

class UserPrivacy extends React.Component<iProps, iState> {
	componentIsMounted: Boolean = false;

	contactsPrivacySettings = [
		{
			title: locale.add_contacts.moments.title,
			description: locale.add_contacts.moments.description,
			value: ContactPrivacySettingType.MOMENTS,
		},
		{
			title: locale.add_contacts.chats_only.title,
			description: locale.add_contacts.chats_only.description,
			value: ContactPrivacySettingType.CHATS_ONLY,
		},
	];

	navParams: UserPrivacyNavParams = {} as any;

	constructor(props: iProps) {
		super(props);

		this.state = {
			showAlert: false,
		};
	}

	componentDidMount = async () => {
		this.componentIsMounted = true;

		Navigation.getNavParamData(this.props).then((res: any) => {
			this.navParams = res;
			this.setState({
				friendPrivacySettingType: this.navParams.contactItem?.privacySetting || ContactPrivacySettingType.MOMENTS,
			});
		});
	};

	componentWillUnmount(): void {
		this.componentIsMounted = false;
	}

	updatePrivacySetting = (setting: ContactPrivacySettingType) => {
		this.props.changePrivacySetting({ privacySetting: setting, users: [this.navParams.contactItem?.username || ''] }).finally(() => {
			this.props.showStylishToast({ stylishToastMessage: 'Updated', stylishToastIcon: 'check' });
		});
	};

	render() {
		const { profilePhotos } = this.props.profile.loggedInUserChatOnlyProfile || {};

		const activeChatOnlyProfilePhoto: ProfilePhotoItem = SharedService.extractProfilePhoto(profilePhotos!)!;

		return (
			<>
				<IonPage className="userprivacy-setting-page">
					<TopNavbar
						{...this.props}
						pageTitle="Privacy"
						showBack={true}
						isHideRightButton={true}
						hideSearchBar={true}
						onLeftButtonPress={() => {
							Navigation.back();
						}}
					/>

					<IonContent className="main-content-profile has-bottom-navbar has-topbar">
						{this.contactsPrivacySettings.map((item, key) => {
							const isSelected = this.state.friendPrivacySettingType === item.value;
							return (
								<div
									key={key}
									className={'privacy-item ' + (isSelected ? 'selected' : '')}
									onClick={() => {
										if (item.value === ContactPrivacySettingType.CHATS_ONLY) {
											this.updatePrivacySetting(ContactPrivacySettingType.CHATS_ONLY);
										} else if (item.value === ContactPrivacySettingType.MOMENTS) {
											this.setState({ showAlert: true });
										}

										this.setState({ friendPrivacySettingType: item.value });
									}}
								>
									<IonLabel>
										<h2>{item.title}</h2>
										<p>{item.description}</p>
									</IonLabel>
									{item.value === ContactPrivacySettingType.CHATS_ONLY && (
										<IonItem
											detail={true}
											lines="none"
											className={'right' + (isSelected ? 'right-selected' : '')}
											onClick={() => {
												this.setState({ friendPrivacySettingType: item.value });
											}}
										>
											<div className="profile-container">
												<img alt="" src={activeChatOnlyProfilePhoto?.url || DefaultProfilePic} className="profile-image-container" />
												<div className="profile-title-container">
													<div className={'progile-photo-title' + (isSelected ? 'selected' : '')}> Profile Photo</div>
													<div className={'tap-change' + (isSelected ? 'tap-selected' : '')}>Tap to change</div>
												</div>
											</div>
										</IonItem>
									)}
								</div>
							);
						})}

						<IonList>
							<div className="moments">Moments</div>
							{this.state.friendPrivacySettingType === ContactPrivacySettingType.MOMENTS && (
								<IonItem detail={false} lines="none">
									<IonLabel>Hide My Posts</IonLabel>
									<div className={'swtich-item'}>
										<IonToggle className="toggle-customised" mode="ios" />
									</div>
								</IonItem>
							)}

							<IonItem detail={false} lines="none">
								<IonLabel>Hide Their Posts</IonLabel>
								<div className={'swtich-item'}>
									<IonToggle className="toggle-customised" mode="ios" />
								</div>
							</IonItem>
						</IonList>
					</IonContent>
				</IonPage>
				<IonAlert
					mode="ios"
					isOpen={this.state.showAlert}
					onDidDismiss={() => this.setState({ showAlert: false })}
					cssClass="remove-warning-alert"
					message="By removing contacts from Chats Only list you are aware they will now be able to view your moments, status, and other profile information, etc...?"
					buttons={[
						{
							text: 'Cancel',
							role: 'cancel',
						},
						{
							text: 'OK',
							role: 'destructive',
							cssClass: 'ok',
							handler: () => {
								this.updatePrivacySetting(ContactPrivacySettingType.MOMENTS);
							},
						},
					]}
				/>
			</>
		);
	}
}

const mapStateToProps = (state: any) => {
	return {
		profile: state.profile,
		contact: state.contact,
		loggedInUser: state.global.loggedInUser,
	};
};

const mapDispatchToProps = (dispatch: any) => ({
	updateUser: (payload: String) => dispatch(updateUser(payload)),
	pageLoading: (payload: any) => dispatch(pageLoading(payload)),
	showStylishToast: (payload: GlobalStateTypes) => dispatch(showStylishToast(payload)),
	changePrivacySetting: (payload: ChangePrivacySettingData) => dispatch(changePrivacySetting(payload)),
});

export default connect(mapStateToProps, mapDispatchToProps)(UserPrivacy);
