export const SEARCH_USER_RESETTED = 'SEARCH_USER_RESETTED';
export const SEARCH_USER_SHOW_LOADER = 'SEARCH_USER_SHOW_LOADER';
export const SEARCH_USER_REQUESTED = 'SEARCH_USER_REQUESTED';
export const SEARCH_USER_SUCCESS = 'SEARCH_USER_SUCCESS';
export const SEARCH_USER_SUCCESS_WITH_LOADING = 'SEARCH_USER_SUCCESS_WITH_LOADING';
export const SEARCH_USER_FAILED = 'SEARCH_USER_FAILED';
export const SEARCH_USER_ERROR = 'SEARCH_USER_ERROR';
export const SEARCH_USER_END = 'SEARCH_USER_END';

export const ADD_USER_RESET_VALUES = 'ADD_USER_RESET_VALUES';
export const ADD_USER_SHOW_LOADER = 'ADD_USER_SHOW_LOADER';
export const ADD_USER_REQUESTED = 'ADD_USER_REQUESTED';
export const ADD_USER_SUCCESS = 'ADD_USER_SUCCESS';
export const ADD_USER_FAILED = 'ADD_USER_FAILED';
export const ADD_USER_ERROR = 'ADD_USER_ERROR';

export const UPDATE_CONTACT_RESET_VALUES = 'UPDATE_CONTACT_RESET_VALUES';
export const UPDATE_CONTACT_SHOW_LOADER = 'UPDATE_CONTACT_SHOW_LOADER';
export const UPDATE_CONTACT_REQUESTED = 'UPDATE_CONTACT_REQUESTED';
export const UPDATE_CONTACT_SUCCESS = 'UPDATE_CONTACT_SUCCESS';
export const UPDATE_CONTACT_FAILED = 'UPDATE_CONTACT_FAILED';
export const UPDATE_CONTACT_ERROR = 'UPDATE_CONTACT_ERROR';
