import { CameraDirection, CameraResultType, CameraSource, Camera } from '@capacitor/camera';
import UtilService from './util';
import Util from './util';

export default class CameraService {
	public capturePhotoFromCamera = (callBack: any, selfie: boolean = false) => {
		this.detectWebcam((hasWebcam: boolean) => {
			if (hasWebcam) {
				this.takePhotoFromCamera((photo: any) => {
					let fileArr: any = [];
					fileArr.push(photo?.dataUrl);
					callBack && callBack(fileArr);
				});
			} else {
				this.triggerUpload(
					'camera',
					(photo: any) => {
						callBack && callBack(photo);
					},
					'photo',
					false,
					true
				);
				// this.takePhotoFromCamera(
				// 	(photo: any) => {
				// 		callBack && callBack(photo?.dataUrl);
				// 	},
				// 	true,
				// 	selfie
				// );
			}
		});
	};

	/**
	 * choosePhotoFromPhotos
	 */
	public async choosePhotoFromPhotos(callBack: any) {
		let allowEditing = false;

		const options = { resultType: CameraResultType.DataUrl, source: CameraSource.Prompt, quality: 70, allowEditing, height: 512, width: 512, correctOrientation: true, webUseInput: true };
		const capturedPhoto = await Camera.getPhoto(options);
		try {
			callBack && callBack(capturedPhoto?.dataUrl);
		} catch (e) {
			alert('Photo error' + JSON.stringify(e));
		}
	}

	public choosePhotoFromAlbum = (callBack: any) => {
		this.triggerUpload('album', (photo: any) => {
			callBack && callBack(photo, 'image');
		});
	};

	public chooseMediaFromAlbum = (callBack: any, canMultipleSelect = false) => {
		this.triggerUpload(
			'album',
			(media: any) => {
				if (canMultipleSelect) {
					callBack && callBack(media);
				} else {
					if (media.indexOf('image') !== -1) {
						callBack && callBack(media, 'image');
					} else if (media.indexOf('video') !== -1) {
						callBack && callBack(media, 'video');
					}
				}
			},
			'photovideo',
			// 'photo',
			canMultipleSelect
		);
	};

	public chooseFileFromAlbum = (callBack: any, canMultipleSelect = false) => {
		this.triggerUpload(
			'album',
			(media: any) => {
				if (canMultipleSelect) {
					callBack && callBack(media);
				} else {
					if (media.indexOf('image') !== -1) {
						callBack && callBack(media, 'image');
					} else if (media.indexOf('video') !== -1) {
						callBack && callBack(media, 'video');
					} else {
						callBack && callBack(media, 'file');
					}
				}
			},
			'file',
			canMultipleSelect
		);
	};

	triggerUpload(type = 'album', callBack: any = null, mediaType = 'photo', canMultipleSelect = false, selfie = false) {
		let input: any = document.createElement('input') as HTMLInputElement;
		input.id = 'camera-input';
		input.type = 'file';
		input.hidden = true;

		if (canMultipleSelect) {
			input.multiple = true;
		}

		if (mediaType === 'photo') {
			input.accept = 'image/*';
		} else if (mediaType === 'photovideo') {
			input.accept = 'image/png,image/jpeg,video/mp4,video/quicktime';
		} else if (mediaType === 'file') {
			input.accept = '*/*';
		}

		if (type === 'camera') {
			if (selfie) {
				input.capture = 'user'; //for front
			} else {
				input.capture = 'environment';
			}
		}

		document.body.appendChild(input);

		const onChange = (event: any) => {
			const fileCount = event.target.files.length;
			let fileArr: any = [];

			for (let index = 0; index < fileCount; index++) {
				const file = event.target.files[index];
				fileArr.push(file);
				if (!canMultipleSelect) {
					break;
				}
			}
			callBack && callBack(fileArr);
			input = null;
		};

		input.addEventListener('change', onChange);

		input.onerror = (error: any) => {
			alert('File pick error' + JSON.stringify(error));
		};

		setTimeout(function () {}, 200);
		input.click();
	}

	async takePhotoFromCamera(callBack: any = null, webUseInput: boolean = false, selfie: boolean = false) {
		let allowEditing = false;

		const options = { resultType: CameraResultType.DataUrl, source: CameraSource.Camera, quality: 70, allowEditing, height: 512, correctOrientation: true, webUseInput: webUseInput, direction: selfie ? CameraDirection.Front : CameraDirection.Rear };
		const capturedPhoto = await Camera.getPhoto(options);
		try {
			callBack && callBack(capturedPhoto);
		} catch (e) {}
	}

	detectWebcam = (callback: any) => {
		let md = navigator.mediaDevices;
		if (!md || !md.enumerateDevices) return callback(false);
		md.enumerateDevices().then((devices) => {
			callback(devices.some((device) => 'videoinput' === device.kind));
		});
	};
}

export const cameraService = new CameraService();
