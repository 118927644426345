import { IonModal, IonText, IonIcon } from '@ionic/react';
import React, { useEffect } from 'react';
import './style.scss';
import { checkmarkOutline } from 'ionicons/icons';

interface ToastStylishProps {
	show: boolean;
	svgIcon?: any;
	iconType?: 'check' | undefined;
	duration?: number;
	message: string;
	onClose: any;
}

const ToastStylish: React.FC<ToastStylishProps> = ({ show, onClose, iconType, duration, svgIcon, message }) => {
	useEffect(() => {
		if (show) {
			setTimeout(
				() => {
					onClose && onClose();
				},
				duration ? duration : 2000
			);
		}
	}, [show, onClose, duration]);

	return (
		<IonModal showBackdrop={false} isOpen={show} swipeToClose={false} cssClass="toast-stylish-modal" animated={false}>
			<div className="toast-container">
				{svgIcon && <IonIcon src={svgIcon} />}
				{iconType === 'check' && <IonIcon src={checkmarkOutline} />}
				<IonText>{message}</IonText>
			</div>
		</IonModal>
	);
};

export default ToastStylish;
