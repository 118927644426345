import { IonItem, IonInput, IonLabel, IonSelect, IonSelectOption, IonTextarea, IonToggle, IonIcon } from '@ionic/react';
import React from 'react';
import './style.scss';
import { chevronForward } from 'ionicons/icons';

interface MultiInputListItemProps {
	inputType?: 'text' | 'dropdown' | 'multiline' | 'toggle';
	isEdittable?: boolean;
	isEditMode?: boolean;
	shouldDisabled?: boolean;
	profileValueKey?: any;
	options?: any;
	label?: any;
	value?: any;
	className?: string;
	onEditModeOn?: any;
	onChange?: any;
	props?: any;
	charLimit?: number;
}

const MultiInputListItem: React.FC<MultiInputListItemProps> = ({ isEdittable, className, profileValueKey, label, shouldDisabled, isEditMode, value, onEditModeOn, onChange, props, inputType, options, charLimit }) => {
	return (
		<IonItem
			mode="ios"
			className={'multi-input-list-item ' + (className ? className : '')}
			disabled={shouldDisabled}
			key={profileValueKey}
			lines="none"
			detail={false}
			onClick={() => {
				if (isEdittable) {
					onEditModeOn && onEditModeOn();
				}
			}}
		>
			<IonLabel>{label}</IonLabel>
			{isEditMode ? (
				<InputField charLimit={charLimit} options={options} value={value} props={props} inputType={inputType} onChange={onChange} />
			) : (
				<p className="edit-profile-p" slot="end">
					{value}
				</p>
			)}

			<div slot="end" className="detail-arrow-icon-view">
				{isEdittable && <IonIcon icon={chevronForward} />}
			</div>
		</IonItem>
	);
};

interface InputFieldProps {
	inputType?: 'text' | 'dropdown' | 'multiline' | 'toggle';
	options?: any;
	value?: any;
	onChange?: any;
	props?: any;
	charLimit?: number;
}

const InputField: React.FC<InputFieldProps> = ({ value, onChange, inputType, options, charLimit }) => {
	return (
		<div slot="end" onClick={(event: any) => event.stopPropagation()} className="input-field-container">
			{inputType === 'dropdown' && (
				<IonSelect
					interface="action-sheet"
					mode="ios"
					slot="end"
					value={String(value)}
					placeholder=""
					onIonChange={(e: any) => {
						onChange && onChange(e.detail.value!);
						// store.dispatch({ type: PROFILE_EDITED, payload: e.detail.value !== value });
					}}
					onIonCancel={() => {}}
				>
					{options &&
						options.map((item: any, key: any) => {
							return (
								<IonSelectOption key={key} value={item.value}>
									{item.title}
								</IonSelectOption>
							);
						})}
				</IonSelect>
			)}
			{inputType === 'text' && (
				<IonInput
					className="edit-profile-info"
					// enterkeyhint="done"
					slot="end"
					autofocus={true}
					value={String(value)}
					onKeyPress={(e) => {
						if ((e.which === 13 || e.key === 'Enter') && !e.shiftKey) {
							e.preventDefault();
						}
					}}
					onIonChange={(e: any) => {
						onChange && onChange(e.detail.value!);
						// store.dispatch({ type: PROFILE_EDITED, payload: e.detail.value !== value });
					}}
				/>
			)}

			{inputType === 'toggle' && <IonToggle className="toggle-customised" mode="ios" value={value} onChange={() => onChange && onChange(!value)} />}

			{inputType === 'multiline' && (
				<IonTextarea
					autoGrow={true}
					className="edit-profile-bio"
					// enterkeyhint="done"
					rows={1}
					slot="end"
					autofocus={true}
					value={String(value)}
					onKeyPress={(e) => {
						if ((e.which === 13 || e.key === 'Enter') && !e.shiftKey) {
							e.preventDefault();
						}
					}}
					onIonChange={(e: any) => {
						if (charLimit && e.detail.value && charLimit < e.detail.value.length) {
							onChange && onChange(value);
						} else {
							onChange && onChange(e.detail.value!);
						}
						// store.dispatch({ type: PROFILE_EDITED, payload: e.detail.value !== value });
					}}
				/>
			)}

			{/* {charLimit && charLimit > 0 && (
        <IonText className="reaminig-letter-label">
          {value ? charLimit - value.length : charLimit}
        </IonText>
      )} */}
		</div>
	);
};

export default MultiInputListItem;
