import React, { useEffect, useState } from 'react';
import './style.css';
import { Gif } from '@giphy/react-components';
import { GiphyFetch } from '@giphy/js-fetch-api';
import { Config } from '../config/config';

const gf = new GiphyFetch(Config.GIPHY_API_KEY);

interface GifThreadItemComponentProps {
	giphyId: string;
	width?: number;
}
export const GifThreadItemComponent = ({ giphyId, width }: GifThreadItemComponentProps) => {
	const [data, setData] = useState<any>(null);
	useEffect(() => {
		gf.gif(giphyId).then((gif: any) => {
			setData(gif.data);
		});
	}, [giphyId]);
	return <div className="giphy-type-message">{data && <Gif gif={data} noLink={true} backgroundColor={'#ddd'} overlay={() => null} width={width || 155} />}</div>;
};
