import { IonContent, IonModal } from '@ionic/react';
import React, { useCallback, useEffect, useState } from 'react';
import IconTextButton from '../../components/IconTextButton';
import ProjectsComponent from './ProjectsComponent';
import QrCodeComponent from './QrCodeComponent';
import './style.scss';
import { locale } from '../../locales/local';
import QrCodeScanModal from './QrCodeScanModal';
import SketchPad from '../../modals/SketchPad/SketchPad';
import ProjectCreatComponent from './ProjectCreatComponent';
import { dbService } from '../../services/dbService';
import { Navigation } from '../../services/navigationService';
import NewProject from '../../modals/NewProject';
import ProjectCreated from '../../modals/ProjectCreated';

interface QuickAccessPopupProps {
	show: boolean;
	showMyQrCodeOnly?: boolean;
	showTagOnly?: boolean;
	props: any;
	onClose?: any;
}

const QuickAccessPopup: React.FC<QuickAccessPopupProps> = ({ show, showMyQrCodeOnly, onClose, props }) => {
	const hotKeyOptions = [
		{
			icon: 'tags.svg',
			title: locale.quickaccess.tags,
			type: 'tag',
		},
		{
			icon: 'qrcode.svg',
			selectedIcon: 'qrcode-selected.svg',
			title: locale.quickaccess.qr_code,
			type: 'qrcode',
		},
		{
			icon: 'todo.svg',
			selectedIcon: 'todos-selected.svg',
			title: locale.quickaccess.todo,
			type: 'todo',
		},
		{
			icon: 'sketch.svg',
			selectedIcon: 'transfer-selected.svg',
			title: locale.quickaccess.transfer,
			type: 'sketch',
		},

		{
			icon: 'scan.svg',
			selectedIcon: 'scan-selected.svg',
			title: locale.quickaccess.scan,
			type: 'scan',
		},
		{
			icon: 'Drive.svg',
			selectedIcon: 'drive-selected.svg',
			title: locale.quickaccess.drive,
			type: 'drive',
		},
	];

	const [showQrScanModal, setShowQrScanModal] = useState(false);
	const [showProjectCreated, setShowProjectCreated] = useState(false);
	const [selectedHotKey, setSelectedHotKey] = useState('');
	const [currentUser, setCurrentUser] = useState<any>(null);
	const [showCreateNewProjectComponent, setShowCreateNewProjectComponent] = useState<any>(false);
	const [showSketchPadModal, setShowSketchPadModal] = React.useState<boolean>(false);

	const [noToDoTask, setNoToDoTask] = useState(true);

	useEffect(() => {
		if (show) {
			dbService.me().then((_user: any) => setCurrentUser(_user));
		}
	}, [show]);

	const onCloseCallBack = useCallback(
		(event: any) => {
			setSelectedHotKey('');
			onClose && onClose();
		},
		[onClose]
	);

	return (
		<IonModal
			animated={false}
			showBackdrop={false}
			isOpen={show}
			onDidDismiss={onCloseCallBack}
			swipeToClose={false}
			cssClass={'quick-access-popup ' + (selectedHotKey === 'qrcode' ? 'blurbackground' : '') + (selectedHotKey === 'todo' && !noToDoTask ? 'blurbackground' : '') + (selectedHotKey === 'sketch  ' ? 'blurbackground' : '')}
		>
			<IonContent className="has-bottom-navbar" onClick={onCloseCallBack}>
				<div className="hotkey-container">
					<div className="hotkey-content-container">
						{selectedHotKey === 'drive'}

						{(selectedHotKey === 'qrcode' || showMyQrCodeOnly) && <QrCodeComponent loggedInUser={currentUser} onClose={() => setSelectedHotKey('')} onQrComponent={(e: any) => onCloseCallBack(e)} />}
						{selectedHotKey === 'todo' && (
							<>
								{!showCreateNewProjectComponent && (
									<>
										{noToDoTask && (
											<ProjectCreatComponent
												onCreateNewProject={() => {
													// for test only
													setNoToDoTask(false);
													setShowCreateNewProjectComponent(true);
												}}
											/>
										)}

										{!noToDoTask && (
											<ProjectsComponent
												onCreateNewProject={() => {
													// for test only
													setNoToDoTask(false);
													setShowCreateNewProjectComponent(true);
												}}
												onClose={() => setSelectedHotKey('')}
											/>
										)}
									</>
								)}

								{showCreateNewProjectComponent && (
									<NewProject
										onCreatProject={() => {
											setShowProjectCreated(true);
										}}
										props={props}
										show={showCreateNewProjectComponent}
										onCancel={() => setShowCreateNewProjectComponent(false)}
									/>
								)}
							</>
						)}
					</div>
					{!showMyQrCodeOnly && !showCreateNewProjectComponent && (
						<div
							className="hotkey-card"
							onClick={(event) => {
								event.stopPropagation();
							}}
						>
							{hotKeyOptions.map((item, key) => {
								return (
									<IconTextButton
										key={key}
										selected={item.type === selectedHotKey}
										icon={item.icon}
										selectedIcon={item.selectedIcon || item.icon}
										title={item.title}
										containerStyle={{ width: '60px' }}
										onClick={() => {
											if (selectedHotKey === item.type) {
												setSelectedHotKey('');

												//for test only
												if (selectedHotKey === 'todo') {
													setNoToDoTask(false);
													onClose();
												}
												//
											} else {
												if (item.type === 'scan') {
													setShowQrScanModal(true);
												} else if (item.type === 'tag') {
													Navigation.openTags();
													onClose();
												} else if (item.type === 'drive') {
													Navigation.openPersonalStorage();
													onClose();
												} else if (item.type === 'sketch') {
													setShowSketchPadModal(true);
												} else {
													setSelectedHotKey(item.type);
												}
											}
										}}
									/>
								);
							})}
						</div>
					)}
				</div>
			</IonContent>
			{showProjectCreated && <ProjectCreated show={showProjectCreated} onCancel={() => setShowProjectCreated(false)} />}

			<SketchPad
				show={showSketchPadModal}
				onCancel={() => setShowSketchPadModal(false)}
				onSave={(img: any) => {
					setShowSketchPadModal(false);
					// sendMedia && sendMedia(img);
				}}
			/>

			{showQrScanModal && (
				<QrCodeScanModal
					show={showQrScanModal}
					onClose={() => {
						setShowQrScanModal(false);
					}}
				/>
			)}
		</IonModal>
	);
};

export default QuickAccessPopup;
