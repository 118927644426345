import { IonButton, IonModal } from '@ionic/react';
import React from 'react';
import './style.scss';
import { connect } from 'react-redux';
import { updateUser } from '../redux/actions/profile';

interface BlockpopupProps {
	show: boolean;
	isOpenfrom: any;
	onCloseCallBack: () => void;
	onBlockCallBack: () => void;
}

const Blockpopup: React.FC<BlockpopupProps> = ({ show, isOpenfrom, onCloseCallBack, onBlockCallBack }) => {
	return (
		<IonModal showBackdrop={true} isOpen={show} onDidDismiss={onCloseCallBack} swipeToClose={true} cssClass="block-popup" animated={true}>
			<div className="container">
				<div className="content-view">
					<div className="block-container">
						{isOpenfrom === 'profileFriend' ? (
							<div className="block-content">When you block a user request, they will no longer be able to search and find your User ID to send further requests.</div>
						) : (
							<div className="block-content">When you block a user, you will not receive any messages from them and you will not see each others public content.</div>
						)}
						<div className="btns-view">
							<IonButton
								fill="clear"
								className="create-btn"
								onClick={() => {
									onBlockCallBack();
								}}
							>
								Block
							</IonButton>
							<IonButton fill="clear" className="delete-btn" onClick={onCloseCallBack}>
								Cancel
							</IonButton>
						</div>
					</div>
				</div>
			</div>
		</IonModal>
	);
};

const mapStateToProps = (state: any) => {
	return {};
};
const mapDispatchToProps = (dispatch: any) => ({
	updateUser: (payload: String) => dispatch(updateUser(payload)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Blockpopup);
