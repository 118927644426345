import React, { createRef } from 'react';
import { IonActionSheet, IonButton, IonContent, IonIcon, IonInput, IonLabel, IonPage, IonToggle } from '@ionic/react';
import './style.scss';
import { RouteComponentProps } from 'react-router';
import { IonList, IonItem } from '@ionic/react';
import { TopNavbar } from '../common/header/topbar';
import { connect } from 'react-redux';
import { AddMembersPageActionType, ContactPrivacySettingType } from '../../services/enumService';
import { sortByAlias } from '../../helpers/common';
import SharedService, { sharedService } from '../../services/sharedService';
import { locale } from '../../locales/local';
import { dbService } from '../../services/dbService';
import { ContactStateTypes, ProfileStateTypes, ReduxStates } from '../../types/redux-states';
import { ContactItem, ContactsTagItem } from '../../types/contact';
import { AddUserToTagRequestData, DeleteTagRequestData, RemoveUserFromTagRequestData } from '../../types/api-requests';
import { addUserToTag, deleteTag, removeUserFromTag } from '../../redux/actions/profile';
import { Navigation } from '../../services/navigationService';

const ContactImg = './assets/img/blank-user-profile.png';
const CloseIcon = './assets/icon/close-icon-outline.svg';
const CloseCircleIcon = './assets/icon/close-circle.svg';
const ClearIcon = './assets/icon/close-icon.svg';
const GridIcon = './assets/icon/Listicon.svg';
const ListIcon = './assets/icon/Group1.svg';
let isTag = '';

interface iProps extends RouteComponentProps<{ name: string }> {
	profile: ProfileStateTypes;
	contact: ContactStateTypes;
	removeUserFromTag: (payload: RemoveUserFromTagRequestData) => Promise<unknown>;
	addUserToTag: (payload: AddUserToTagRequestData) => Promise<unknown>;
	deleteTag: (payload: DeleteTagRequestData) => Promise<unknown>;

	location: any;
}
interface iState {
	loggedInUser?: any;
	removedContactsId?: any;
	contacts: any;
	isTagNameEdit: boolean;
	isTagNameFocus: boolean;
	isTagContactsEdit: boolean;
	tagName: string;
	tagsList: any;
	isDeleteMode: boolean;
	isAddNew: boolean;
	showActionSheetFor: string;
	selectedContact: any;
	tagDetail: ContactsTagItem;
	isContactAddOrRemove: boolean;
	displayType: string;
	taggedContacts: any;
	isTagHide: any;
}

let tempState: any = null;

class TagInfo extends React.Component<iProps, iState> {
	tagNameInputRef: any = createRef();
	componentIsMounted: Boolean = false;
	componentIsUpdated: Boolean = false;

	constructor(props: iProps) {
		super(props);

		const state: any = props.history.location.state || tempState;
		this.setState({ isTagHide: state?.tagDetail.hide });
		if (props.history.location.state) {
			tempState = props.history.location.state;
		}

		let tagsList: any = [];
		if (state?.isAddNew) {
			tagsList = [
				{ section_name: 'Chats Only', type: 'chat_only' },
				{
					section_name: 'Members',
					type: 'members',
				},

				{ section_name: 'Groups', type: 'groups' },
			];
		} else if (state?.tagDetail) {
			tagsList = [
				{ section_name: 'Chats Only', type: 'chat_only' },

				{
					section_name: 'Members',
					type: 'members',
				},
				{
					section_name: 'Groups',
					type: 'groups',
				},
			];
		}
		this.state = {
			isAddNew: state?.isAddNew,
			loggedInUser: undefined,
			contacts: [],
			isDeleteMode: false,
			tagName: state?.tagDetail ? state?.tagDetail.tag : '',
			isTagHide: state?.tagDetail ? state?.tagDetail.hide : '',
			isTagNameEdit: state?.isAddNew,
			isTagNameFocus: false,
			showActionSheetFor: '',
			isTagContactsEdit: false,
			isContactAddOrRemove: false,
			tagsList: tagsList,
			removedContactsId: [],
			selectedContact: null,
			tagDetail: state?.tagDetail,
			displayType: 'grid',
			taggedContacts: state?.tagDetail?.contacts
				? state?.tagDetail.contacts.sort((a: ContactItem, b: ContactItem) => {
						let a1: string = (a?.alias || a?.userInfo.profileName)?.toLowerCase(),
							b1: string = (b?.alias || b?.userInfo.profileName)?.toLowerCase();

						return a1 > b1 ? 1 : b1 > a1 ? -1 : 0;
				  })
				: [],
		};
	}

	getData = async () => {
		let user = await dbService.me(),
			contacts: any = user.contacts.filter((_contact: any) => _contact.status === 'confirmed').sort(sortByAlias);

		return [user, contacts];
	};

	setPageData = async () => {
		const { contacts } = this.props.contact;

		let isContactAddOrRemove = false;

		let taggedContacts: any = this.state.taggedContacts;

		if (sharedService.callBackData.taginfo) {
			if (sharedService.callBackData.taginfo.tagContactIds) {
				const tagContactIds = sharedService.callBackData.taginfo.tagContactIds;
				setTimeout(() => {
					delete sharedService.callBackData.taginfo;
				}, 2000);
				const prevContactIds = taggedContacts.map((_item: ContactItem) => _item.username);
				taggedContacts = contacts ? contacts.filter((_contact: ContactItem) => [...prevContactIds, ...tagContactIds].indexOf(_contact.username) !== -1) : [];
				isContactAddOrRemove = true;
				if (tempState?.tagDetail?.contacts) {
					tempState.tagDetail.contacts = taggedContacts;
				}
			}
		}

		this.setState((state: any, props: any) => ({
			...state,
			taggedContacts,
			isContactAddOrRemove,
		}));

		this.componentIsMounted = true;
	};

	componentDidMount = async () => {
		if (!this.componentIsMounted) {
			await this.setPageData();
		}
	};

	componentDidUpdate = async () => {
		if (!this.componentIsUpdated) {
			this.componentIsUpdated = true;
			await this.setPageData();
		}
	};

	setSearchText() {}
	searchAllGroups(searchValue: string, props: any) {
		Navigation.openSearchContacts({ searchTerm: searchValue });
	}

	_onContactSelect = (cont: any) => {};

	_renderSwitchItem = (title: any, value: any, onChange: any) => {
		return (
			<div className={'swtich-itemchatTags'} slot="end">
				<IonLabel>{title}</IonLabel>
				<IonToggle slot="end" className="toggle-customised" mode="ios" value={value} onChange={onChange} />
			</div>
		);
	};

	_handleTopBarRightButtonClick = async () => {
		const { taggedContacts, isContactAddOrRemove, removedContactsId, tagDetail, tagName } = this.state;

		if (this.state.isDeleteMode && taggedContacts?.length !== 0) {
			this.setState({ isDeleteMode: false });
		} else {
			if (removedContactsId.length > 0 || (tagDetail && tagDetail.tag !== tagName) || isContactAddOrRemove || this.state.isTagHide === '') {
				if ((tagDetail && tagDetail.tag !== tagName) || this.state.isTagHide === '') {
					const availableContactIds = taggedContacts.map((_item: ContactItem) => _item.username);
					await this.props.addUserToTag({ request: { tag: tagName, usernames: availableContactIds } });
					if (tagDetail && tagDetail.tag !== tagName) {
						await this.props.deleteTag({ tags: [tagDetail.tag] });
					}
				} else if (removedContactsId.length > 0) {
					await this.props.removeUserFromTag({ request: { tag: tagDetail.tag, usernames: removedContactsId } });
				}

				Navigation.openTags();
			} else {
				Navigation.openAddMembers({
					actionType: AddMembersPageActionType.ChooseContactForTag,
				});
			}
		}
	};

	render() {
		const { tagsList, tagName, isAddNew, isContactAddOrRemove, taggedContacts } = this.state;
		let rightBtnTitle = 'Done';
		let rightButtonDisable = true;

		if (this.state.removedContactsId?.length > 0 || this.state.isTagHide === '') {
			rightBtnTitle = 'Done';
			rightButtonDisable = false;
		}
		if (isTag || isContactAddOrRemove || (this.state.tagDetail && this.state.tagDetail.tag !== this.state.tagName) || (!this.state.tagDetail && this.state.tagName)) {
			rightButtonDisable = false;
		}
		if (this.state.tagName.length === 0) {
			rightBtnTitle = 'Done';
			rightButtonDisable = true;
		}

		return (
			<>
				<IonPage className="taginfo-page">
					<TopNavbar
						{...this.props}
						isHideRightButton={false}
						showBack={false}
						hideSearchBar={true}
						leftButtonIcon={CloseIcon}
						pageTitle={isAddNew ? 'New Tag' : `Tag(${taggedContacts?.length || ''})`}
						rightButtonText={rightBtnTitle}
						rightButtonDisabled={rightButtonDisable}
						onLeftButtonPress={() => {
							if (this.state.isDeleteMode) {
								this.setState({ isDeleteMode: false });
							} else {
								Navigation.back();
							}
						}}
						onRightButtonPress={this._handleTopBarRightButtonClick}
					/>

					<IonContent className="main-content-profile has-bottom-navbar has-topbar">
						<IonList lines="none">
							<IonItem className="tagname-title-item">
								<div>Tag Name</div>
								<IonButton
									slot="end"
									fill="clear"
									className="list-grid"
									onClick={() => {
										if (this.state.displayType === 'grid') {
											this.setState({
												displayType: 'list',
											});
										} else if (this.state.displayType === 'list') {
											this.setState({
												displayType: 'grid',
											});
										}
									}}
								>
									{this.state.displayType === 'grid' ? <IonIcon slot="icon-only" src={ListIcon} /> : <IonIcon slot="icon-only" src={GridIcon} />}
								</IonButton>
							</IonItem>
							<IonItem
								className="tag-field-item"
								lines="none"
								onClick={() => {
									if (!this.state.isTagNameEdit) {
										const tagNameInput: any = document.getElementById('tagnameinputfield');
										if (tagNameInput && tagNameInput.setFocus) {
											tagNameInput.setFocus();
										}
									}
									this.setState({ isTagNameEdit: true });
								}}
							>
								<IonInput
									value={tagName}
									id="tagnameinputfield"
									placeholder="Set Tag name"
									className="tag-input"
									onIonChange={(event) => {
										this.setState({ tagName: String(event.detail.value) });
									}}
									ref={this.tagNameInputRef}
									clearInput={false}
									onFocus={() => {
										this.setState({ isTagNameFocus: true });
									}}
									onBlur={() => {
										this.setState({ isTagNameFocus: false });
									}}
								/>

								{this.state.isTagNameEdit && tagName && (
									<IonButton
										slot="end"
										fill="clear"
										className="clear-icon"
										onClick={() => {
											this.setState({ tagName: '' });
											const tagNameInput: any = document.getElementById('tagnameinputfield');
											if (tagNameInput && tagNameInput.setFocus) {
												tagNameInput.setFocus();
											}
										}}
									>
										<IonIcon slot="icon-only" src={ClearIcon} />
									</IonButton>
								)}
							</IonItem>
						</IonList>

						{tagsList.map((item: any, key: any) => {
							let sortedMembers: any = [];
							if (item.type === 'chat_only') {
								sortedMembers = taggedContacts.filter((_contact: ContactItem) => _contact.type === 'chat' && _contact.privacySetting === ContactPrivacySettingType.MOMENTS);
							} else if (item.type === 'members') {
								sortedMembers = taggedContacts.filter(
									(_contact: ContactItem) => (_contact.type === 'chat' || _contact.type === undefined) && (_contact.privacySetting === ContactPrivacySettingType.MOMENTS || _contact.privacySetting === ContactPrivacySettingType.CHATS_ONLY || _contact.privacySetting === undefined)
								);
							} else if (item.type === 'groups') {
								sortedMembers = taggedContacts.filter((_contact: ContactItem) => _contact.type === 'group');
							}

							if (sortedMembers.length === 0) {
								return null;
							}

							return (
								<React.Fragment key={key}>
									<div className="list-devider">
										{item.section_name} ({sortedMembers.length > 0 && sortedMembers.length})
									</div>

									<div className={this.state.displayType === 'grid' ? 'contacts-item-container' : 'contacts-item-container-list'}>
										{sortedMembers.length > 0
											? sortedMembers.map((contact: ContactItem, cKey: any) => {
													return (
														<div className="contact-item" key={cKey}>
															{this.state.isDeleteMode && (
																<div className="cross">
																	<IonButton
																		className="remove-icon"
																		fill="clear"
																		onClick={() => {
																			const memberIndex = taggedContacts.findIndex((_member: ContactItem) => _member.username === contact.username);

																			if (memberIndex >= 0) {
																				taggedContacts.splice(memberIndex, 1);
																			}

																			const removedContactsId = this.state.removedContactsId;
																			removedContactsId.push(contact.username);
																			this.setState({
																				removedContactsId: removedContactsId,
																				isContactAddOrRemove: true,
																			});
																		}}
																	>
																		<IonIcon src={CloseCircleIcon} />
																	</IonButton>
																</div>
															)}

															<img className="contact-img" src={SharedService.extractProfilePhoto(contact.userInfo.profilePhotos)?.url || ContactImg} alt="" />
															<div className="contact-name">{contact.type === 'group' ? contact.username : contact.alias || contact.userInfo.profileName}</div>
														</div>
													);
											  })
											: ''}
									</div>
								</React.Fragment>
							);
						})}
					</IonContent>

					{taggedContacts && taggedContacts.length > 0 && (
						<div className="bottom-buttons-view">
							<IonButton className="remove-btn" fill="clear" disabled={this.state.isDeleteMode} onClick={() => this.setState({ isDeleteMode: true })}>
								Remove
							</IonButton>
							<div style={{ flex: 1 }}></div>
							<IonButton
								className="add-btn"
								fill="clear"
								onClick={() => {
									Navigation.openAddMembers({
										tagName: tagName,
										actionType: AddMembersPageActionType.ChooseContactForTag,
										previousSelectedContactsIds: taggedContacts.map((_item: any) => _item.username),
									});
								}}
							>
								Add
							</IonButton>
						</div>
					)}
				</IonPage>

				<IonActionSheet
					mode="ios"
					isOpen={this.state.showActionSheetFor !== ''}
					onDidDismiss={() => this.setState({ showActionSheetFor: '' })}
					cssClass="manage-contact-action-sheet"
					buttons={
						this.state.showActionSheetFor === 'remove-contact'
							? [
									{
										text: '',
										role: 'destructive',
										handler: () => {},
									},
									{
										text: locale.contacts.manage_contact.cancel,
										handler: () => {},
									},
							  ]
							: []
					}
				></IonActionSheet>
			</>
		);
	}
}

const mapStateToProps = (state: ReduxStates) => {
	return {
		profile: state.profile,
		contact: state.contact,

		loggedInUser: state.global.loggedInUser,
		isLoggedIn: state.auth.isLoggedIn,
	};
};

const mapDispatchToProps = (dispatch: any) => ({
	removeUserFromTag: (payload: RemoveUserFromTagRequestData) => dispatch(removeUserFromTag(payload)),
	addUserToTag: (payload: AddUserToTagRequestData) => dispatch(addUserToTag(payload)),
	deleteTag: (payload: DeleteTagRequestData) => dispatch(deleteTag(payload)),
});

export default connect(mapStateToProps, mapDispatchToProps)(TagInfo);
