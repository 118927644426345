import { ProfileStateTypes } from '../../types/redux-states';
import { USER_PROFILE_SHOW_LOADER, USER_PROFILE_RESET_VALUES } from '../constants/common';
import {
	PROFILE_INIT,
	PROFILE_EDITED,
	PROFILE_FETCH_FAILED,
	PROFILE_FETCH_START,
	PROFILE_FETCH_SUCCESS,
	PROFILE_UPDATE_FAILED,
	PROFILE_UPDATE_SUCCESS,
	PROFILE_UPDATING,
	TAG_FETCH_START,
	TAG_FETCH_SUCCESS,
	TAG_FETCH_FAILED,
	SET_TAGS_VISIBILITY_START,
	SET_TAGS_VISIBILITY_SUCCESS,
	SET_TAGS_VISIBILITY_FAILED,
	GET_TAGS_VISIBILITY_START,
	GET_TAGS_VISIBILITY_SUCCESS,
	GET_TAGS_VISIBILITY_FAILED,
	CHATONLY_PROFILE_UPDATING,
	CHATONLY_PROFILE_UPDATE_FAILED,
	CHATONLY_PROFILE_UPDATE_SUCCESS,
} from '../constants/profile';

const initialState: ProfileStateTypes = {
	isLoggedIn: false,
	errorMessage: '',
	showLoading: false,
	isLoading: false,
	showToast: false,
	loggedInUserProfile: null,
	loggedInUserChatOnlyProfile: null,
	myTags: null,
	hideAllTags: false,
};
// eslint-disable-next-line import/no-anonymous-default-export
export default function (state = initialState, action: any) {
	switch (action.type) {
		case USER_PROFILE_RESET_VALUES: {
			state.errorMessage = '';
			state.showLoading = false;
			state.isLoading = false;
			state.showToast = false;
			break;
		}

		// Init cache profile data

		case PROFILE_INIT: {
			state.loggedInUserProfile = action.payload.loggedInUserProfile;
			state.loggedInUserChatOnlyProfile = action.payload.loggedInUserChatOnlyProfile;
			state.myTags = action.payload.myTags;
			state.hideAllTags = action.payload.hideAllTags;
			break;
		}

		// My Profile Fetching
		case PROFILE_FETCH_START: {
			state.isLoading = true;
			break;
		}
		case PROFILE_FETCH_SUCCESS: {
			state.isLoading = false;
			state.loggedInUserProfile = action.payload;
			break;
		}
		case PROFILE_FETCH_FAILED: {
			state.isLoading = false;
			break;
		}

		// My tags fetching
		case TAG_FETCH_START: {
			state.isLoading = true;
			break;
		}

		case TAG_FETCH_SUCCESS: {
			state.isLoading = false;
			state.myTags = action.payload;
			break;
		}

		case TAG_FETCH_FAILED: {
			state.isLoading = false;
			break;
		}

		// Tags Visibility
		case GET_TAGS_VISIBILITY_START:
		case SET_TAGS_VISIBILITY_START: {
			state.isLoading = true;
			break;
		}

		case GET_TAGS_VISIBILITY_SUCCESS:
		case SET_TAGS_VISIBILITY_SUCCESS: {
			state.isLoading = false;
			state.hideAllTags = action.payload;
			break;
		}

		case GET_TAGS_VISIBILITY_FAILED:
		case SET_TAGS_VISIBILITY_FAILED: {
			state.isLoading = false;
			break;
		}

		// ChatOnly Profile Updating
		case CHATONLY_PROFILE_UPDATING: {
			state.isLoading = true;
			break;
		}

		case CHATONLY_PROFILE_UPDATE_FAILED: {
			state.isLoading = false;
			state.errorMessage = action.payload;
			break;
		}

		case CHATONLY_PROFILE_UPDATE_SUCCESS: {
			let loggedInUserChatOnlyProfileData: any = state.loggedInUserChatOnlyProfile;
			if (loggedInUserChatOnlyProfileData) {
				state.loggedInUserChatOnlyProfile = { ...loggedInUserChatOnlyProfileData, ...action.payload };
			} else {
				state.loggedInUserChatOnlyProfile = action.payload;
			}
			state.isLoading = false;

			break;
		}

		// Profile Updating
		case PROFILE_UPDATING: {
			state.isLoading = true;
			break;
		}

		case PROFILE_UPDATE_FAILED: {
			state.isLoading = false;
			state.errorMessage = action.payload;
			break;
		}

		case PROFILE_UPDATE_SUCCESS: {
			let loggedInUserProfileData: any = state.loggedInUserProfile;
			if (loggedInUserProfileData) {
				state.loggedInUserProfile = { ...loggedInUserProfileData, ...action.payload };
			} else {
				state.loggedInUserProfile = action.payload;
			}
			state.isLoading = false;

			break;
		}

		case PROFILE_EDITED: {
			break;
		}

		case USER_PROFILE_SHOW_LOADER: {
			state.isLoading = action.payload;
			break;
		}

		default:
			break;
	}
	return { ...state };
}
