import { IonButton, IonModal, IonIcon, IonSearchbar } from '@ionic/react';
import React, { forwardRef, useCallback, useEffect, useImperativeHandle, useRef, useState } from 'react';
import './style.scss';
import { Grid } from '@giphy/react-components';
import { GiphyFetch } from '@giphy/js-fetch-api';
import CustomSpinner from '../../components/CustomSpinner';
import { Config } from '../../config/config';
import { GiphyItem } from '../../types/giphy';
import emojiDatas, { Emoji } from '@emoji-mart/data';

const ArrowDownIcon = './assets/icon/arrow-down.svg';

// use @giphy/js-fetch-api to fetch gifs, instantiate with your api key
const gf = new GiphyFetch(Config.GIPHY_API_KEY);

interface EmojiStickerSearchModalProps {
	displayTypes: Array<'emoji' | 'sticker'>;
	show: boolean;
	onCancel?: any;
	onSelectGiphy?: (giphy: GiphyItem) => void;
	onSelectEmoji?: (emoji: any) => void;
}

const EmojiStickerSearchModal: React.FC<EmojiStickerSearchModalProps> = ({ show, displayTypes, onCancel, onSelectGiphy, onSelectEmoji }) => {
	const [searchTerm, setSearchTerm] = useState<string>('');
	const searchLoadingViewRef = useRef<any>(null);
	const [emojjis, setEmojjis] = useState<any>([]);

	useEffect(() => {
		const allEmojis = (emojiDatas as any).emojis;

		let filteredEmojis;
		if (searchTerm) {
			filteredEmojis = Object.values(allEmojis).filter((emoji: any) => {
				return emoji.keywords.findIndex((keyword: string) => keyword.toLowerCase().includes(searchTerm.toLowerCase())) > -1;
			});
		} else {
			filteredEmojis = Object.values(allEmojis);
		}
		setEmojjis(filteredEmojis);
	}, [searchTerm]);

	const onCloseCallBack = useCallback(() => {
		onCancel && onCancel();
	}, [onCancel]);

	const fetchGifs = (offset: number) => {
		return new Promise((resolve: (value: any) => void, reject) => {
			(searchTerm ? gf.search(searchTerm, { offset, limit: 20 }) : gf.trending({ offset, limit: 20 })).then((result) => {
				console.log('EmojiStickerSearchModal::fetchGifs', JSON.stringify(result));
				resolve(result);
			});
		});
	};
	const onEmojiClick = (event: any, emojiObject: any) => {
		onSelectEmoji && onSelectEmoji(emojiObject);
	};

	return (
		<IonModal mode="ios" showBackdrop={true} isOpen={show} onDidDismiss={onCloseCallBack} swipeToClose={true} cssClass="emoji-sticker-search-modal" animated={true}>
			<div className="content">
				<div className="header">
					<IonButton fill="clear" slot="icon-only" onClick={onCloseCallBack}>
						<IonIcon src={ArrowDownIcon}></IonIcon>
					</IonButton>
				</div>

				<IonSearchbar
					mode="ios"
					placeholder="Search GIPHY"
					className="searchbar"
					value={searchTerm}
					onIonChange={(event: any) => {
						setSearchTerm(event.detail.value);
						searchLoadingViewRef.current?.showHide(true);
					}}
				/>

				{displayTypes?.includes('emoji') && (
					<>
						<div className="content-title">Emoji</div>
						<div className="emoji-container">
							{[emojjis.slice(0, Math.floor(emojjis.length / 2)), emojjis.slice(Math.floor(emojjis.length / 2) + 1, emojjis.length)].map((emojis: any, key) => (
								<div className="row" key={key}>
									{emojis.map((emoji: Emoji) => {
										return (
											<div className="emoji-item" key={emoji.id} onClick={(event: any) => onEmojiClick(event, { emoji: emoji.skins[0].native })}>
												{emoji.skins[0].native}
											</div>
										);
									})}
								</div>
							))}
						</div>
					</>
				)}

				{displayTypes?.includes('sticker') && (
					<>
						<div className="content-title">Stickers</div>
						<SearchLoadingView ref={searchLoadingViewRef} />

						<div className="stickers-container">
							<Grid
								width={window.innerWidth - 32}
								gutter={16}
								columns={4}
								fetchGifs={fetchGifs}
								key={searchTerm}
								noLink={true}
								overlay={() => null}
								onGifsFetched={() => {
									searchLoadingViewRef.current?.showHide(false);
								}}
								onGifClick={(gif: any) => {
									onSelectGiphy && onSelectGiphy(gif);
								}}
							/>
						</div>
					</>
				)}
			</div>
		</IonModal>
	);
};

const SearchLoadingView = forwardRef((_, ref) => {
	const [isSearching, setIsSearching] = useState<boolean>(false);

	useImperativeHandle(ref, () => ({
		showHide: (show: boolean) => {
			setIsSearching(show);
		},
	}));
	return (
		<>
			{isSearching && (
				<div className="searching-view">
					<CustomSpinner size={16} />
					<div className="search-text">Searching...</div>
				</div>
			)}
		</>
	);
});

export default EmojiStickerSearchModal;
