import React from "react";
import { IonBadge, IonButton, IonButtons, IonContent, IonFooter, IonIcon, IonLabel, IonNote, IonPage, IonToolbar } from "@ionic/react";
import "./style.scss";
import { RouteComponentProps } from "react-router";
import { closeSharp } from "ionicons/icons";
import { IonList, IonItem } from "@ionic/react";
import { TopNavbar } from "../common/header/topbar";
import { ContactsList } from "./components/ContactsList";
import { connect } from "react-redux";
import { AddMembersPageActionType, ContactsListMode, ContactsListType, ContactsPageActionType } from "../../services/enumService";
import { SelectedContacts } from "./components/SelectedContacts";
import { resetValues } from "../../redux/actions/dashboard";
import { locale } from "../../locales/local";
import { info } from "../../helpers/common";
import _ from "lodash";
import { ContactAlbhabetView } from "./components/ContactAlbhabetView";
import ChatSearchBar from "../../modals/ChatSearchBar/ChatSearchBar";
import { ContactStateTypes, ReduxStates } from "../../types/redux-states";
import { ContactItem } from "../../types/contact";
import { Navigation } from "../../services/navigationService";

const TagsIcon = "./assets/icon/tags.svg";
const RecentlyAddedIcon = "./assets/icon/recently-added.svg";
const OfficialAccIcon = "./assets/icon/official.svg";
const ChatIcon = "./assets/icon/tab-chat.svg";
const GroupCreateIcon = "./assets/icon/group-create.svg";
const StarredIcon = "./assets/icon/starred.svg";

interface iProps extends RouteComponentProps<{ name: string }> {
  contact: ContactStateTypes;
  //addGroup: Function;
  resetValues: Function;
  location: any;
}
interface iState {
  isContactSelectMode: boolean;
  loggedInUser?: any;
  contacts: any;
  selectedContacts?: any[];
  disabledContacts?: any[];
  showSearchContactsModal: boolean;
  showChatSearchBar: boolean;
  pageActionType: string;
}

class Contacts extends React.Component<iProps, iState> {
  starredContacts = [{ title: "Trevor W." }, { title: "Carlos" }];
  componentIsMounted: Boolean = false;
  componentIsUpdated: Boolean = false;
  constructor(props: iProps) {
    super(props);

    let params: any = props.history.location.state;

    let userdata: any = {};

    if (params && params.receiver) {
      const contactItem: ContactItem = params.receiver;
      userdata = contactItem;
    }
    const disabledContacts = [],
      locationState = props.location.state,
      actionType = locationState?.actionType,
      defaultGroupMember = locationState?.defaultGroupMember;

    let selectedContacts: any = locationState?.selectedContacts || [],
      isContactSelectMode = actionType === ContactsPageActionType.CreateGroup || actionType === ContactsPageActionType.ForwardMessage || actionType === ContactsPageActionType.ChooseContactForTag;

    if (actionType === ContactsPageActionType.CreateGroup && defaultGroupMember) {
      selectedContacts.push(defaultGroupMember._id);
      disabledContacts.push(defaultGroupMember._id);
    }

    this.state = {
      showChatSearchBar: false,
      loggedInUser: {},
      selectedContacts: selectedContacts,
      disabledContacts: disabledContacts,
      isContactSelectMode: isContactSelectMode,
      showSearchContactsModal: false,
      contacts: this.props.contact.contacts,
      pageActionType: actionType,
    };

    this.props.resetValues();
  }

  async componentDidMount() {
    if (!this.componentIsMounted) {
    }
  }

  async componentDidUpdate() {
    if (!this.componentIsUpdated) {
      this.componentIsUpdated = true;
    }
  }

  setSearchText() {}

  searchAllGroups(searchValue: string, props: any) {
    Navigation.openSearchContacts({
      searchTerm: searchValue,
    });
  }

  _onContactSelect = (_contact: ContactItem) => {
    try {
      if (this.state.pageActionType) {
        let tmpList: any = this.state.selectedContacts || [];
        const contactIdIndex = tmpList.indexOf(_contact?.userInfo.userId);
        if (contactIdIndex === -1) {
          tmpList.push(_contact.userInfo.userId);
        } else {
          tmpList.splice(contactIdIndex, 1);
        }
        this.setState({ selectedContacts: tmpList });
      }
    } catch (error) {}
  };

  _onDone = () => {
    if (this?.state && this.state?.pageActionType === ContactsPageActionType.ChooseContactForTag) {
      Navigation.back();
    } else {
      if (this.state.selectedContacts && this.state.selectedContacts?.length > 0) {
        Navigation.openGroupManager({
          selectedContactIds: [this.state.loggedInUser?._id, ...this.state.selectedContacts],
        });
      }
    }
  };

  async scrollToContactSection(elementId: string, ionContentId: string) {
    const element: any = document.getElementById(elementId);
    let ionContent: any = document.getElementById(ionContentId);
    if (ionContent) {
      const elem = await ionContent.getScrollElement();
      elem?.scrollTo(0, element.offsetTop);
    }
  }

  render() {
    const { isContactSelectMode, selectedContacts, disabledContacts, pageActionType } = this.state;

    let pageTitle: string = "",
      disableDoneBtnForContactSelectMode: boolean = false,
      showSelectedContacts: boolean = false,
      visibleOptions: any[] = [];

    if (pageActionType === ContactsPageActionType.ChooseContactForTag) {
      visibleOptions = ["import_from_group"];
      pageTitle = locale.contacts.friends;
      showSelectedContacts = false;
      disableDoneBtnForContactSelectMode = !selectedContacts || selectedContacts?.length <= 0 || !disabledContacts || selectedContacts?.length <= disabledContacts.length;
    } else if (pageActionType === ContactsPageActionType.CreateGroup || pageActionType === ContactsPageActionType.ForwardMessage) {
      visibleOptions = ["work_accounts", "starred"];
      showSelectedContacts = true;
      pageTitle = locale.contacts.friends;
      disableDoneBtnForContactSelectMode = !selectedContacts || selectedContacts?.length <= 1 || !disabledContacts || selectedContacts?.length <= disabledContacts.length;
    } else {
      visibleOptions = ["recent_friends", "group_chats", "tags", "official_accounts", "work_accounts"];
      pageTitle = locale.contacts.title;
    }

    let alphabetsList = this.state.contacts.map((contact: any) => {
      const contactName = contact?.alias || contact?.username || contact?.userId || "";
      return contactName.charAt(0);
    });

    alphabetsList = _.uniq(alphabetsList).sort();
    return (
      <>
        <IonPage className="contacts-page">
          <TopNavbar
            {...this.props}
            isHideRightButton={isContactSelectMode}
            leftButtonIcon={isContactSelectMode ? closeSharp : GroupCreateIcon}
            pageTitle={pageTitle}
            searchTypeHandler={this.setSearchText}
            searchHandler={this.searchAllGroups}
            onSearchBarFocus={() => {
              if (!isContactSelectMode) {
                this.setState({
                  showChatSearchBar: true,
                });
              }
            }}
            onLeftButtonPress={() => {
              Navigation.openAddMembers({
                actionType: AddMembersPageActionType.ChooseContactForGroup,
              });

              // if (isContactSelectMode) {
              // 	if (this.state.isContactSelectMode) {
              // 		this.setState({ isContactSelectMode: false });
              // 	} else {
              // 		Navigation.back();
              // 	}
              // } else {
              // 	this.setState({ isContactSelectMode: true });
              // }
            }}
          />

          {showSelectedContacts && (
            <>
              <SelectedContacts contacts={this.props.contact.contacts} selectedContacts={selectedContacts} props={this.props} onContactSelect={this._onContactSelect} />
            </>
          )}

          <IonContent id="contacts-content" className="main-content-profile has-bottom-navbar has-topbar-with-searchbar">
            <div className="sec-3">
              <>
                <IonList className="contact-groups-list">
                  {_.includes(visibleOptions, "recent_friends") && (
                    <IonItem className="recent_friends" button onClick={Navigation.contactRecent} lines="none" detail={false}>
                      <div className="icon-div" slot="start">
                        <IonIcon src={RecentlyAddedIcon} />
                        {this.props.contact.receivedRequests && this.props.contact.receivedRequests.length > 0 && <IonBadge>{this.props.contact.receivedRequests.length}</IonBadge>}
                      </div>
                      <IonLabel>New Friends</IonLabel>
                    </IonItem>
                  )}

                  {_.includes(visibleOptions, "group_chats") && (
                    <IonItem
                      className="group_chats"
                      button
                      onClick={() => {
                        Navigation.openGroupChats();
                      }}
                      lines="none"
                      detail={false}
                    >
                      <div className="icon-div" slot="start">
                        <IonIcon src="assets/icon/group.svg" />
                      </div>
                      <IonLabel>{locale.contacts.group_chats}</IonLabel>
                    </IonItem>
                  )}
                  {_.includes(visibleOptions, "tags") && (
                    <IonItem className="tags" button onClick={() => Navigation.openTags()} lines="none" detail={false}>
                      <div className="icon-div" slot="start">
                        <IonIcon src={TagsIcon} />
                      </div>
                      <IonLabel>{locale.contacts.tags}</IonLabel>
                    </IonItem>
                  )}
                  {_.includes(visibleOptions, "official_accounts") && (
                    <IonItem className="official_accounts" button onClick={() => info("Official Accounts")} lines="none" detail={false}>
                      <div className="icon-div" slot="start">
                        <IonIcon src={OfficialAccIcon} />
                      </div>
                      <IonLabel>{locale.contacts.official_accounts}</IonLabel>
                    </IonItem>
                  )}
                  {_.includes(visibleOptions, "work_accounts") && (
                    <IonItem className="work_accounts" button onClick={() => Navigation.openContactsList({ contactsListType: ContactsListType.CHAT_ONLY })} lines="none" detail={isContactSelectMode}>
                      <div className="icon-div" slot="start">
                        <IonIcon src={ChatIcon} />
                      </div>
                      <IonLabel>{locale.contacts.chats_only}</IonLabel>
                      {isContactSelectMode && <IonNote slot="end">{locale.contacts.selected}: 2</IonNote>}
                    </IonItem>
                  )}
                  {_.includes(visibleOptions, "import_from_group") && (
                    <IonItem button onClick={() => {}} lines="none" detail={false}>
                      <div className="icon-div" slot="start">
                        <IonIcon src="assets/icon/group.svg" />
                      </div>
                      <IonLabel>{locale.contacts.import_from_group}</IonLabel>
                    </IonItem>
                  )}
                  {_.includes(visibleOptions, "starred") && (
                    <IonItem button onClick={() => info("Starred")} lines="none" detail={true}>
                      <div className="icon-div" slot="start">
                        <IonIcon src={StarredIcon} />
                      </div>
                      <IonLabel>{locale.contacts.starred}</IonLabel>
                      {isContactSelectMode && <IonNote slot="end">{locale.contacts.selected}: 3</IonNote>}
                    </IonItem>
                  )}
                </IonList>
              </>
            </div>

            <ContactsList
              props={this.props}
              selectedContacts={selectedContacts}
              disabledContacts={disabledContacts}
              starredContacts={[]}
              contacts={this.props.contact.contacts!}
              contactListMode={isContactSelectMode ? ContactsListMode.ChooseContactsList : ContactsListMode.NormalContactsList}
              onContactSelect={this._onContactSelect}
            />

            {isContactSelectMode && (
              <>
                <br />
                <br />
                <br />
                <br />
                <br />
              </>
            )}
            <ContactAlbhabetView
              alphabets={alphabetsList}
              showStarredInsideAlbhabetList={true}
              onAlphabetSelect={(albhabet: any) => {
                this.scrollToContactSection("section_" + albhabet, "contacts-content");
              }}
              onGoToStarredContact={async () => {
                this.scrollToContactSection("section_starred", "contacts-content");
              }}
            />
          </IonContent>
          {isContactSelectMode && (
            <IonFooter className="selected-contact-mode-footer">
              <IonToolbar className="toolbar-wrapper">
                <IonButtons slot="end">
                  <IonButton className="send-button" disabled={disableDoneBtnForContactSelectMode} onClick={this._onDone}>
                    {locale.global.done}
                    {selectedContacts && selectedContacts.length > 0 && "(" + selectedContacts.length + ")"}
                  </IonButton>
                </IonButtons>
              </IonToolbar>
            </IonFooter>
          )}
        </IonPage>

        <ChatSearchBar
          history={this.props.history}
          location={this.props.location}
          type={"contacts"}
          show={this.state.showChatSearchBar}
          onCancel={() => {
            this.setState({ showChatSearchBar: false });
          }}
        />
      </>
    );
  }
}

const mapStateToProps = (state: ReduxStates) => {
  return {
    contact: state.contact,
    loggedInUser: state.global.loggedInUser,
    isLoggedIn: state.auth.isLoggedIn,
  };
};

const mapDispatchToProps = (dispatch: any) => ({
  resetValues: () => dispatch(resetValues()),
  //addGroup: (payload: any) => dispatch(addGroup(payload)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Contacts);
