import React, { useCallback, useState } from 'react';
import { Link, Redirect, Route } from 'react-router-dom';
import { IonAlert, IonApp, IonIcon, IonLabel, IonLoading, IonRouterOutlet, IonTabBar, IonTabButton, IonTabs, IonToast } from '@ionic/react';

import QuickAccessPopup from '../quickaccess/QuickAccessPopup';
import QuickAccessModal from '../quickaccess/QuickAccessModal';
import NotificationBadge from '../../components/NotificationBadge';
import { UpdateDotsData } from '../../types/api-requests';
import { locale } from '../../locales/local';
import { LongpressContainer } from '../../components/LongpressContainer';
import { ContactStateTypes, ProfileStateTypes, ReduxStates } from '../../types/redux-states';
import { updateDots } from '../../redux/actions/profile';
import { connect } from 'react-redux';
import { NavigationRoutes } from '../../services/enumService';
import { sharedService } from '../../services/sharedService';
import { historyManagement, tabsRoutes } from '../../services/navigationService';
import { info } from '../../helpers/common';

interface ContainerProps {
	updateDots?: (payload: UpdateDotsData) => void;
	profile?: ProfileStateTypes;
	contact?: ContactStateTypes;
	unreadCount?: number;
	history?: any;
	location?: any;
}

const defaultProps: ContainerProps = {
	unreadCount: 0,
};

var isPlusButtonLongress = false;

const Tabs = (props: ContainerProps) => {
	sharedService.rootHistory = props.history;

	const [showQuickAccessModal, setShowQuickAccessModal] = useState(false);
	const [showQuickAccessPopup, setShowQuickAccessPopup] = useState(false);
	const [showTagspage] = useState(false);

	const extractPath = props.location?.pathname?.replace(/^[/]/, '');

	const _handleTabChange = useCallback((data: UpdateDotsData) => {
		props.updateDots && props.updateDots(data);
	}, []);

	return (
		<IonApp>
			<IonTabs>
				<IonRouterOutlet animated={false}>
					<Route exact path={NavigationRoutes.TABS} render={() => <Redirect exact to={NavigationRoutes.DASHBOARD} />} />

					{tabsRoutes.map((item, key) => (
						<Route key={key} exact={item.exact} path={item.path} component={item.component as any} />
					))}
				</IonRouterOutlet>

				<IonTabBar
					slot="bottom"
					className={'bottom-tabs-bar '}
					onIonTabsDidChange={(event) => {
						historyManagement.clearAll();

						if (event.detail.tab === 'contacts') {
							historyManagement.add(NavigationRoutes.CONTACTS);
							!props.profile?.loggedInUserProfile?.contactDot && _handleTabChange({ contact: true });
						} else if (event.detail.tab === 'discover') {
							historyManagement.add(NavigationRoutes.DISCOVER);
							!props.profile?.loggedInUserProfile?.discoverDot && _handleTabChange({ discover: true });
						} else if (event.detail.tab === 'profile') {
							historyManagement.add(NavigationRoutes.PROFILE);
							!props.profile?.loggedInUserProfile?.meDot && _handleTabChange({ me: true });
						} else {
							historyManagement.add(NavigationRoutes.DASHBOARD);
						}
					}}
				>
					<IonTabButton tab="auth" href="/auth/dashboard" className={'bottom-tabs '}>
						<IonIcon className="ionIcon1" src="/assets/icon/tab-chat-selected.svg" color="primary"></IonIcon>
						<IonIcon className="ionIcon2" src="/assets/icon/tab-chat.svg" color="primary"></IonIcon>
						<IonLabel>{locale.bottom_navbar.titles.chat}</IonLabel>
						<NotificationBadge unreadCount={props.unreadCount} />
					</IonTabButton>
					<IonTabButton tab="contacts" href="/auth/contacts" className={'bottom-tabs '}>
						<IonIcon className="ionIcon1" src="/assets/icon/tab-contacts-selected.svg"></IonIcon>
						<IonIcon className="ionIcon2" src="/assets/icon/tab-contacts.svg"></IonIcon>
						<IonLabel>{locale.bottom_navbar.titles.contacts}</IonLabel>
						{!props.profile?.loggedInUserProfile?.contactDot && <NotificationBadge className="dot-circle" showOnlyCircle={true} />}
						<NotificationBadge unreadCount={props.contact?.receivedRequests?.length} />
					</IonTabButton>

					<IonTabButton className={'bottom-tabs large-btn ' + (extractPath === 'quick-access' || showQuickAccessModal ? 'tab-selected' : '')}>
						<Link to="#">
							<LongpressContainer
								threshold={500}
								onLongPress={() => {
									isPlusButtonLongress = true;
									// props.history?.push('/quick-access-qrcode', { showMyQrCodeOnly: true });
									info('onLongPress');
									setShowQuickAccessModal(true);
									!props.profile?.loggedInUserProfile?.hotkeyDot && _handleTabChange({ hotkey: true });
								}}
								onFinish={() => {
									info('onLongPress Finish');
									// setShowQuickAccessModal(false);
									setTimeout(() => {
										isPlusButtonLongress = false;
									}, 2000);
								}}
								onCancel={() => {
									info('onLongPress Cancel');
									setShowQuickAccessModal(false);
									isPlusButtonLongress = false;
								}}
								onClick={() => {
									info('setShowQuickAccessModal false');
									!props.profile?.loggedInUserProfile?.hotkeyDot && _handleTabChange({ hotkey: true });
									setShowQuickAccessModal(false);
									if (!isPlusButtonLongress) {
										info('setShowQuickAccessPopup true');

										//   if (props.location?.pathname !== '/quick-access') {
										setShowQuickAccessPopup(true);
										//   }
									}
								}}
							>
								<IonIcon className="ionIcon1" src="/assets/icon/tab-add-selected.svg"></IonIcon>
								<IonIcon className="ionIcon2" src="/assets/icon/tab-add.svg"></IonIcon>
							</LongpressContainer>
						</Link>
						{!props.profile?.loggedInUserProfile?.hotkeyDot && <NotificationBadge className="dot-circle" showOnlyCircle={true} />}{' '}
					</IonTabButton>

					<IonTabButton tab="discover" href="/auth/discover" className={'bottom-tabs '}>
						<IonIcon className="ionIcon1" src="/assets/icon/tab-discover-selected.svg"></IonIcon>
						<IonIcon className="ionIcon2" src="/assets/icon/tab-discover.svg"></IonIcon>
						<IonLabel>{locale.bottom_navbar.titles.discover}</IonLabel>
						{!props.profile?.loggedInUserProfile?.discoverDot && <NotificationBadge className="dot-circle" showOnlyCircle={true} />}{' '}
					</IonTabButton>
					<IonTabButton tab="profile" href="/auth/profile" className={'bottom-tabs'}>
						<IonIcon className="ionIcon1" src="/assets/icon/tab-me-selected.svg"></IonIcon>
						<IonIcon className="ionIcon2" src="/assets/icon/tab-me.svg"></IonIcon>
						<IonLabel>{locale.bottom_navbar.titles.me}</IonLabel>
						{!props.profile?.loggedInUserProfile?.meDot && <NotificationBadge className="dot-circle" showOnlyCircle={true} />}{' '}
					</IonTabButton>
				</IonTabBar>
			</IonTabs>

			<QuickAccessModal
				loggedInUserProfile={props.profile?.loggedInUserProfile!}
				show={showQuickAccessModal}
				onClose={() => {
					setShowQuickAccessModal(false);
				}}
			/>

			<QuickAccessPopup
				props={props}
				showTagOnly={showTagspage}
				show={showQuickAccessPopup}
				onClose={() => {
					setShowQuickAccessPopup(false);
				}}
			/>
		</IonApp>
	);
};

Tabs.defaultProps = defaultProps;

const mapStateToProps = (state: ReduxStates) => {
	return {
		contact: state.contact,
		profile: state.profile,
	};
};

const mapDispatchToProps = (dispatch: any) => ({
	updateDots: (payload: UpdateDotsData) => dispatch(updateDots(payload)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Tabs);
