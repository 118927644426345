import { LOGIN_REQUESTED, LOGIN_SUCCESS, LOGIN_FAILED, LOGIN_ERROR, REGISTER, LOGOUT_REQUESTED, SET_PASSWORD, SET_USERNAME, LOGIN_INIT_CONTACTS } from '../constants/auth';
import { AUTH_SHOW_LOADER, AUTH_FORM_ERROR, AUTH_RESET_VALUES } from '../constants/common';
import { locale } from '../../locales/local';
import { AuthStateTypes } from '../../types/redux-states';

const initialState: AuthStateTypes = {
	isLoggedIn: false,
	hasErrors: false,
	title: locale.reducers.auth.init_state.login,
	userId: '',
	password: '',
	placeholder_pwd: locale.reducers.auth.init_state.password,
	submitLabel: locale.reducers.auth.init_state.log_in,
	errorMessage: '',
	showLoading: false,
	loginSuccess: false,
	loaderText: locale.reducers.auth.init_state.logging_in,
	isAuthUser: false,
	showToast: false,
};

// eslint-disable-next-line import/no-anonymous-default-export
export default function (state = initialState, action: any) {
	switch (action.type) {
		case LOGIN_REQUESTED: {
			state.isLoggedIn = false;
			break;
		}

		case LOGIN_SUCCESS: {
			state.isLoggedIn = true;
			state.errorMessage = '';
			break;
		}

		case LOGIN_FAILED: {
			state.isLoggedIn = false;
			state.errorMessage = action.payload.error_description;
			break;
		}

		case AUTH_RESET_VALUES: {
			state.hasErrors = false;
			state.errorMessage = '';
			state.showToast = false;
			state.isLoggedIn = false;
			break;
		}

		case LOGIN_INIT_CONTACTS: {
			break;
		}

		case LOGOUT_REQUESTED: {
			break;
		}

		case SET_PASSWORD: {
			state.hasErrors = action.payload.hasErrors;
			state.password = action.payload.password;
			break;
		}

		case SET_USERNAME: {
			state.hasErrors = action.payload.hasErrors;
			state.userId = action.payload.userId;
			break;
		}

		case LOGIN_ERROR: {
			state.hasErrors = true;
			state.isAuthUser = false;
			state.errorMessage = action.payload;

			break;
		}

		case AUTH_SHOW_LOADER: {
			state.showLoading = action.payload;
			break;
		}

		case AUTH_FORM_ERROR: {
			state.showLoading = false;
			state.hasErrors = true;
			state.errorMessage = action.payload.errorMessage;
			break;
		}

		case REGISTER: {
			break;
		}

		default:
			break;
	}
	return { ...state };
}
