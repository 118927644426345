import React, { useState } from 'react';
import { IonActionSheet, IonButton, IonIcon, IonImg, IonLabel, IonText, IonThumbnail } from '@ionic/react';
import { ellipsisHorizontal, heartOutline, heartSharp, chatboxOutline, trashOutline, copyOutline } from 'ionicons/icons';
import LikeCommentsContainer from './components/LikeCommentsContainer';
import BottomInputBox from './components/BottomInputBox';
import { isBlank } from '../../helpers/common';
import PhotoViewer from './components/PhotoViewer';
import { LongpressContainer } from '../../components/LongpressContainer';
import { info } from '../../helpers/common';
interface MomentsProps {
	moments: Array<any>;
	onChange: Function;
	isMyMoments?: boolean;
}

const MomentsContent: React.FC<MomentsProps> = ({ isMyMoments, moments, onChange }) => {
	const [showCommentModal, setShowCommentModal] = useState(false);
	const [showPhotoOptionsSheet, setShowPhotoOptionsSheet] = useState(false);
	const [showPhotoViewer, setShowPhotoViewer] = useState(false);
	const [selectedCommentIndex, setSelectedCommentIndex] = useState(-1);

	const onLikeBtnClick = (key: any, status: Boolean) => {
		var list = moments;
		list[key].liked = status;
		onChange(list);
	};

	const onCommentBtnClick = (key?: any, visibility?: string) => {
		var list = moments;

		for (let index = 0; index < list.length; index++) {
			const item = list[index];
			if (item.optionShow === 'open' && index !== key) {
				item.optionShow = 'close';
			}
		}

		if (!isBlank(key)) {
			if (!isBlank(visibility)) {
				list[key].optionShow = visibility;
			} else if (list[key].optionShow === '' || list[key].optionShow === 'close') {
				list[key].optionShow = 'open';
			} else {
				list[key].optionShow = 'close';
			}
		}

		onChange(list);
	};

	const handleOutSideClick = () => {
		onCommentBtnClick();

		setShowCommentModal(false);
		setSelectedCommentIndex(-1);
	};

	return (
		<div style={{ width: '100%', height: '100%' }} onClick={handleOutSideClick}>
			<LongpressContainer
				className="top-bg"
				onLongPress={() => {
					setShowPhotoOptionsSheet(true);
				}}
			>
				{/* <IonImg src="assets/img/demo-usermoments-topbg.png" /> */}
				<IonImg src="https://data.monkingme.space/file/mm-img-bucket/500_GHGoFpfToMbsGY906FPbY_1ufsiz_tp2NXjibhs-Pk2PkG_C0IetTQt" />
				{/* <IonImg src="https://media.macphun.com/img/uploads/customer/blog/1548348087/15483505255c49f43d0c1ab5.02000369.jpeg?q=85&w=1680" /> */}
				<div className="overlay"></div>
			</LongpressContainer>

			<div className="name-profileimg-view">
				<div className="labels-view">
					<IonLabel className="name">Trevor W.</IonLabel>
					<IonLabel className="link">www.playtestmake.com</IonLabel>
				</div>
				<IonThumbnail>
					<IonImg src="assets/img/joker.jpg" />
				</IonThumbnail>
			</div>

			<div className="moments-list">
				{moments.map((item, key) => {
					const isLiked = moments[key].liked;

					if (isMyMoments) {
						return (
							<div className="list-item" key={key}>
								<div className="left-content">
									<IonText style={{ fontSize: 17 }}>09 Mar</IonText>
									<IonLabel>Beijing, CN</IonLabel>
								</div>

								<div className="right-content">
									<div className="photos-container" style={{ flexDirection: 'column' }}>
										{item.imgs.map((img: String, innerKey: any) => {
											return (
												<div
													style={{
														display: 'flex',
														flexDirection: 'row',
														marginBottom: 4,
													}}
												>
													<IonThumbnail key={innerKey} onClick={() => setShowPhotoViewer(true)}>
														<IonImg src={'assets/img/' + img} />
													</IonThumbnail>
													<IonLabel
														className="location"
														style={{
															fontSize: 14,
															marginLeft: 4,
														}}
													>
														2020 - Design Hijack Tulum “When Art/Design"
													</IonLabel>
												</div>
											);
										})}
									</div>
								</div>
							</div>
						);
					}

					return (
						<div className="list-item" key={key}>
							<div className="left-content">
								<IonThumbnail>
									<IonImg src={'assets/img/' + item.profile} />
								</IonThumbnail>
								<IonLabel>{item.date}</IonLabel>
							</div>

							<div className="right-content">
								<IonLabel className="name">{item.name}</IonLabel>
								<IonLabel className="title">{item.title}</IonLabel>
								<div className="photos-container">
									{item.imgs.map((img: String, innerKey: any) => {
										return (
											<IonThumbnail key={innerKey} onClick={() => setShowPhotoViewer(true)}>
												<IonImg src={'assets/img/' + img} />
											</IonThumbnail>
										);
									})}
								</div>

								<div className="item-bottom-view">
									<IonLabel className="location">{item.location}</IonLabel>

									<div
										className="option-container"
										onClick={(event) => {
											event.stopPropagation();
										}}
									>
										<div className={`option-view ${moments[key].optionShow}`}>
											{selectedCommentIndex !== -1 ? (
												<>
													<IonButton
														fill="clear"
														onClick={() => {
															var textField = document.createElement('textarea');
															textField.innerText = item.comments[selectedCommentIndex].comment;
															document.body.appendChild(textField);
															textField.select();
															document.execCommand('copy');
															textField.remove();

															setSelectedCommentIndex(-1);
															onCommentBtnClick(key, 'close');
														}}
													>
														<IonIcon slot="start" icon={copyOutline} />
														Copy
													</IonButton>

													<IonButton
														fill="clear"
														onClick={() => {
															setSelectedCommentIndex(-1);
															onCommentBtnClick(key, 'close');
														}}
													>
														<IonIcon slot="start" icon={trashOutline} />
														Delete
													</IonButton>
												</>
											) : (
												<>
													{isLiked ? (
														<IonButton fill="clear" onClick={() => onLikeBtnClick(key, false)}>
															<IonIcon color="danger" slot="start" icon={heartSharp} />
															Cancel
														</IonButton>
													) : (
														<IonButton fill="clear" onClick={() => onLikeBtnClick(key, true)}>
															<IonIcon slot="start" icon={heartOutline} />
															Like
														</IonButton>
													)}
													<IonButton
														fill="clear"
														onClick={() => {
															setShowCommentModal(true);
															onCommentBtnClick(key, 'close');
														}}
													>
														<IonIcon slot="start" icon={chatboxOutline} />
														Comment
													</IonButton>
												</>
											)}
										</div>

										<button className="option-btn" onClick={() => onCommentBtnClick(key)}>
											<IonIcon icon={ellipsisHorizontal} />
										</button>
									</div>
								</div>

								<LikeCommentsContainer
									selectedCommentIndex={selectedCommentIndex}
									likes={item.likes}
									comments={item.comments}
									onCommentSelect={(index: any) => {
										onCommentBtnClick(key, 'open');
										setSelectedCommentIndex(index);
									}}
									onCommentClick={() => {
										onCommentBtnClick(key, 'close');
										setSelectedCommentIndex(-1);
									}}
								/>
							</div>
						</div>
					);
				})}
			</div>

			{showCommentModal && (
				<BottomInputBox
					showClose={true}
					showEmoji={true}
					autoFocus={true}
					onClose={() => {
						setShowCommentModal(false);
					}}
				></BottomInputBox>
			)}

			<PhotoViewer show={showPhotoViewer} onClose={() => setShowPhotoViewer(false)} photos={['assets/img/demo-moments-topbg.png', 'assets/img/demo-moments-topbg.png', 'assets/img/demo-usermoments-topbg.png']}></PhotoViewer>

			<PhotoOptionSheet
				show={showPhotoOptionsSheet}
				onClose={() => {
					setShowPhotoOptionsSheet(false);
				}}
			></PhotoOptionSheet>
		</div>
	);
};
export default MomentsContent;

interface PhotoOptionSheetProps {
	show: boolean;
	onClose?: any;
}
const PhotoOptionSheet: React.FC<PhotoOptionSheetProps> = ({ show, onClose }) => {
	return (
		<IonActionSheet
			isOpen={show}
			onDidDismiss={onClose}
			translucent={true}
			buttons={[
				{
					text: 'Take Photo',
					handler: () => {},
				},
				{
					text: 'Choose from Album',
					handler: () => {},
				},
				{
					text: 'Favorite',
					handler: () => {},
				},
				{
					text: 'Edit',
					handler: () => {},
				},
				{
					text: 'Cancel',
					role: 'cancel',
					handler: () => {
						onClose();
						info('Cancel clicked');
					},
				},
			]}
		></IonActionSheet>
	);
};
