import { IonButton } from '@ionic/react';
import React from 'react';
import ContactItem from './ContactItem/ContactItem';
import './HelperComponents.scss';

interface SearchContactsProps {
	contacts: any;
	onManageContacts: any;
}

const SearchContacts: React.FC<SearchContactsProps> = ({ contacts, onManageContacts }) => {
	return (
		<div className="search-contacts">
			<div className="title-container">
				<div className="group-title">Contacts</div>
				<IonButton slot="end" fill="clear" onClick={() => onManageContacts && onManageContacts()}>
					Manage
				</IonButton>
			</div>

			{contacts.map((_item: any, _key: any) => {
				if (_key > 2) return null;
				return <ContactItem key={'item-contact' + _key} item={_item} />;
			})}
		</div>
	);
};

export default SearchContacts;
