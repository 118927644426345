import { DbController } from './DbController';
import { MyProfile } from '../types/profile';

import { IndexDbCollections } from './DbController';
import { ContactItem } from '../types/contact';
import { Message } from '../types/message';
import store from '../redux/store';
import { ReduxStates } from '../types/redux-states';
import moment from 'moment';
import { UPDATE_LAST_MESSAGES } from '../redux/constants/chat';
import { GroupItem, MemberItem } from '../types/group';
import { ThreadItem } from '../types/thread';

export const dbService = {
	listDatabases: async () => await DbController.getDatabases(),

	closeDatabase: async (database: any = undefined) => {
		try {
			await DbController.closeDatabase(database);
		} catch (error) {}
	},

	deleteDatabase: async (database: any = undefined) => {
		try {
			await DbController.deleteDatabase(database);
		} catch (error) {}
	},

	me: async () => {
		try {
			let user: any = await DbController.get(IndexDbCollections.USER.name);
			return user;
		} catch (error) {}
		return null;
	},

	meChatOnly: async () => {
		try {
			let profile: any = await DbController.get(IndexDbCollections.CHATONLYUSER.name);
			return profile;
		} catch (error) {}
		return null;
	},

	myTags: async () => {
		try {
			return await DbController.where(IndexDbCollections.DATA.name, IndexDbCollections.DATA.primaryIndex, IndexDbCollections.DATA.TAGS);
		} catch (error) {}
		return null;
	},

	favGifs: async () => {
		try {
			return await DbController.where(IndexDbCollections.DATA.name, IndexDbCollections.DATA.primaryIndex, IndexDbCollections.DATA.FAV_GIFS);
		} catch (error) {}
		return null;
	},

	addNavigation: async (history: any) => {
		try {
			const data = { [IndexDbCollections.NAVIGATION.HISTORY]: history, [IndexDbCollections.NAVIGATION.primaryIndex]: IndexDbCollections.NAVIGATION.HISTORY };
			const updateResTag = await DbController.update(IndexDbCollections.NAVIGATION.name, data, IndexDbCollections.NAVIGATION.HISTORY);
			if (!updateResTag) {
				DbController.put(IndexDbCollections.NAVIGATION.name, data);
			}
		} catch (error) {}
	},

	navigationHistory: async () => {
		try {
			const list = await DbController.where(IndexDbCollections.NAVIGATION.name, IndexDbCollections.NAVIGATION.primaryIndex, IndexDbCollections.NAVIGATION.HISTORY);
			return list && list.length > 0 ? list[0][IndexDbCollections.NAVIGATION.HISTORY] : [];
		} catch (error) {}
		return null;
	},

	addThread: async (thread: { userOrMucId: string; list: Array<ThreadItem> }) => await DbController.put(IndexDbCollections.THREADS.name, thread),

	getThreads: async (userOrMucId: string) => {
		try {
			const list = await DbController.where(IndexDbCollections.THREADS.name, IndexDbCollections.THREADS.primaryIndex, userOrMucId);
			if (list && list.length > 0) {
				return list[0].list as Array<ThreadItem>;
			}
		} catch (error) {}
		return [] as Array<ThreadItem>;
	},

	addMembers: async (membersData: { group: string; list: Array<MemberItem> }) => {
		try {
			await DbController.put(IndexDbCollections.GROUP_MEMBERS.name, membersData);
		} catch (error) {}
	},

	getMembers: async (groupId: string) => {
		try {
			const list = await DbController.where(IndexDbCollections.GROUP_MEMBERS.name, IndexDbCollections.GROUP_MEMBERS.primaryIndex, groupId);
			if (list && list.length > 0) {
				return list[0].list as Array<MemberItem>;
			}
		} catch (error) {}
		return [] as Array<MemberItem>;
	},

	myContacts: async () => {
		try {
			const contacts: Array<ContactItem> = await DbController.get(IndexDbCollections.CONTACTS.name);
			return contacts;
		} catch (error) {}
		return null;
	},

	groups: async () => {
		try {
			const groups: Array<GroupItem> = await DbController.get(IndexDbCollections.GROUPS.name);
			return groups;
		} catch (error) {}
		return null;
	},

	getGroup: async (mucId: string) => {
		try {
			const groupItem = await DbController.where(IndexDbCollections.GROUPS.name, IndexDbCollections.GROUPS.primaryIndex, mucId);
			if (groupItem && groupItem.length > 0) {
				return groupItem[0];
			}
		} catch (error) {}
		return null;
	},

	addUser: async (user: any) => await DbController.put(IndexDbCollections.USER.name, user),

	addMessageInfo: async (messageInfo: { firstId: string; lastId: string; count: number; username: string }) => {
		try {
			await DbController.put(IndexDbCollections.MESSAGE_INFO.name, messageInfo);
		} catch (error) {}
	},

	getMessageInfo: async (username: string) => {
		try {
			const messagesInfos = await DbController.where(IndexDbCollections.MESSAGE_INFO.name, IndexDbCollections.MESSAGE_INFO.primaryIndex, username);
			if (messagesInfos && messagesInfos.length > 0) {
				return messagesInfos[0];
			}
		} catch (error) {}
		return null;
	},

	addMessage: async (message: Message) => {
		try {
			const states = store.getState() as ReduxStates;
			const lastMessages = states.chat?.lastMessages || {};
			const lastMessage: Message = lastMessages[message.username];
			if (lastMessage) {
				const lastMessageTime = moment(lastMessage.datetime);
				const newMessageTime = moment(message.datetime);
				if (newMessageTime.diff(lastMessageTime, 'seconds') > 0) {
					lastMessages[message.username] = message;
				}
			} else {
				lastMessages[message.username] = message;
			}
			store.dispatch({ type: UPDATE_LAST_MESSAGES, payload: { lastMessages } });
		} catch (error) {}

		message.usernameMessageKey = `${message.username}_${message.messageKey}`;
		const updateResTag = await DbController.update(IndexDbCollections.MESSAGES.name, message, IndexDbCollections.MESSAGES.primaryIndex);
		if (updateResTag) {
			return updateResTag;
		}
		return await DbController.put(IndexDbCollections.MESSAGES.name, message);
	},

	getMessages: async (username: string) => {
		try {
			const messages: Array<Message> = await DbController.whereLike(IndexDbCollections.MESSAGES.name, IndexDbCollections.MESSAGES.primaryIndex, `${username}_`, 'datetime', false);
			return messages;
		} catch (error) {}
		return null;
	},

	getLastMessage: async (username: string) => {
		try {
			const messages: Array<Message> = await DbController.whereLike(IndexDbCollections.MESSAGES.name, IndexDbCollections.MESSAGES.primaryIndex, `${username}`, 'datetime', true);
			if (messages && messages.length > 0) {
				return messages[0];
			}
		} catch (error) {}
		return null;
	},

	updateUser: async (user: MyProfile) => {
		try {
			if (await DbController.update('user', user, user.userId)) {
				return true;
			}
		} catch (error) {}
		return false;
	},

	getConfirmedContacts: async () =>
		await DbController.whereObject('contacts', {
			type: 'chat',
			status: 'confirmed',
		}),

	getPendingReply: async () =>
		await DbController.whereObject('contacts', {
			type: 'chat',
			status: 'pendingReply',
		}),

	getPendingConfirm: async () =>
		await DbController.whereObject('contacts', {
			type: 'chat',
			status: 'pendingConfirm',
		}),

	getContactBy_username: async (username: string) => {
		const list = await DbController.where(IndexDbCollections.CONTACTS.name, IndexDbCollections.CONTACTS.primaryIndex, username);
		return list && list.length > 0 ? list[0] : null;
	},
};
