import React from 'react';
import { IonIcon, IonImg, IonLabel, IonThumbnail, IonNote, IonText, IonButton } from '@ionic/react';
import { ellipsisHorizontal, locationOutline, timeOutline } from 'ionicons/icons';
import BottomInputBox from './components/BottomInputBox';

interface ContainerProps {
	moments: Array<any>;
}

const StatusContent: React.FC<ContainerProps> = ({ moments }) => {
	return (
		<>
			<div className="status-list">
				{moments.map((item, key) => {
					return (
						<React.Fragment key={key}>
							<div className="list-item" key={key}>
								<div className="header-view">
									<div className="left-content">
										<IonThumbnail>
											<IonImg src={'assets/img/' + item.profile} />
										</IonThumbnail>
										<div className="time">
											<IonIcon icon={timeOutline}></IonIcon>
											<IonNote>2 min</IonNote>
										</div>
									</div>

									<div className="right-content">
										<IonButton fill="clear" size="small" className="option-btn" onClick={() => {}}>
											<IonIcon icon={ellipsisHorizontal} />
										</IonButton>

										<IonLabel className="name">
											CNN
											<IonNote>@CNN</IonNote>
										</IonLabel>
										<div className="location">
											<IonIcon color="medium" icon={locationOutline}></IonIcon>
											<IonNote>Dallas, TX, USA</IonNote>
										</div>

										<IonText className="status-text">One of these things is true: Either Trump is irrationally clinging to hope or he has seen more than the public has so far seen about election fraud and he is letting the press hang itself before revealing it.</IonText>

										<div className="icon-btns-container">
											<button className="icon-btn">
												<IonIcon src="assets/icon/view-light.svg"></IonIcon>
												<IonLabel>2.3K</IonLabel>
											</button>
											<button className="icon-btn">
												<IonIcon src="assets/icon/comment-light.svg"></IonIcon>
												<IonLabel>33</IonLabel>
											</button>
											<button className="icon-btn">
												<IonIcon src="assets/icon/heart-light.svg"></IonIcon>
												<IonLabel>124K</IonLabel>
											</button>
											<button className="icon-btn">
												<IonIcon src="assets/icon/share-light.svg"></IonIcon>
											</button>
										</div>
									</div>
								</div>
							</div>
						</React.Fragment>
					);
				})}

				<BottomInputBox showMessageBtn={true} showEmoji={true} showMic={true} />
			</div>
		</>
	);
};
export default StatusContent;
