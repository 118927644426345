import { IonIcon } from '@ionic/react';
import React, { useEffect, useMemo, useState } from 'react';
import { connect } from 'react-redux';
import { publishData, resetPublishData } from '../../../../redux/actions/pubsub';
import { ChatMediaType, ChatMessageState, MessageType } from '../../../../services/enumService';
import UtilService from '../../../../services/util';
import { Media } from '../../../../types/message';
import './style.scss';
import { Gif } from '@giphy/react-components';
import { GiphyFetch } from '@giphy/js-fetch-api';
import { Config } from '../../../../config/config';
import { GifThreadItemComponent } from '../../../../components/GifThreadItemComponent';

const MessageFileBg = './assets/icon/message-file-bg.svg';
const AudioIcon = './assets/icon/audio-icon.svg';
const CloseIconOutlineIcon = './assets/icon/close-icon-outline.svg';

const gf = new GiphyFetch(Config.GIPHY_API_KEY);

interface iProps {
	messageData: any;

	onClose: any;
}

const ChatReplyView: React.FC<iProps> = ({ messageData, onClose }) => {
	let userProfilePic = null;
	let userProfileName: string = '';
	let message = '';
	userProfileName = messageData.senderProfileName || messageData.sender;
	userProfilePic = messageData.userProfilePic;
	if (messageData?.messageType === MessageType.TEXT || messageData?.messageType === ChatMessageState.ORIGINAL || messageData?.messageType === ChatMessageState.REPLY) {
		message = messageData.body.replace(/&quot;/g, '');
	}
	const isTextType: Boolean = useMemo(() => messageData.messageType === MessageType.TEXT, [messageData.messageType]);
	const isGifType: Boolean = useMemo(() => messageData.messageType === MessageType.GIF, [messageData.messageType]);
	const isMediaType: Boolean = useMemo(() => messageData.messageType === MessageType.MEDIA, [messageData.messageType]);
	const isFileType: Boolean = useMemo(() => messageData.messageType === MessageType.FILE, [messageData.messageType]);

	return messageData ? (
		<div className="chat-reply-view">
			<img className="profile-img" src={userProfilePic} alt="profilePicture" />
			<div className={'user-title-msg ' + (isMediaType || isFileType || isGifType ? 'image-type' : isTextType ? 'text-type' : '')}>
				<span className="title" dangerouslySetInnerHTML={{ __html: userProfileName.replace('(', '(<span class="paranthesis">').replace(')', '</span>)') + ':' }}></span>
				&nbsp;
				{isTextType && <span dangerouslySetInnerHTML={{ __html: message }}></span>}
				{(isMediaType || (isFileType && [ChatMediaType.IMAGE, ChatMediaType.VIDEO].includes((messageData.media as Media).mediaType))) && <img className="msg-image" src={messageData.media?.thumbnail || messageData.media?.mediaFile} alt="media" />}
				{isFileType && (
					<>
						{(messageData.media as Media).mediaType === ChatMediaType.AUDIO && (
							<div className="audio-type-message">
								<img className="icon" src={AudioIcon} alt="media" />
								<div className="file-name">{messageData.media?.duration ? `${UtilService.formatDuration(messageData.media?.duration)}` : 'N/A'}</div>
							</div>
						)}
						{(messageData.media as Media).mediaType === ChatMediaType.DOCUMENT && (
							<div className="file-type-message" style={{ transform: 'scale(0.6)', position: 'absolute' }}>
								<img className="file-bg" src={MessageFileBg} alt="media" />
								<div className="file-name">{messageData.media?.mediaFile?.split('/').pop()}</div>
								<div className="size-type-view">
									<div className="size">{UtilService.formatSize(messageData.media?.size || 0)}</div> | <div className="type">{messageData.media?.mediaFile?.split('.').pop().toUpperCase()}</div>
								</div>
							</div>
						)}
					</>
				)}
				{isGifType && (
					<div className="giphy-type-message">
						<GifThreadItemComponent giphyId={messageData.body.id || messageData.body.giphyId} width={30} />
					</div>
				)}
			</div>
			<div className="close-button" onClick={() => onClose()}>
				<IonIcon src={CloseIconOutlineIcon} />
			</div>
		</div>
	) : (
		<></>
	);
};

const mapStateToProps = (state: any) => {
	return {
		pubsub: state.pubsub,
	};
};

const mapDispatchToProps = (dispatch: any) => ({
	resetPublishData: () => dispatch(resetPublishData()),
	publishData: (payload: any) => dispatch(publishData(payload)),
});

export default connect(mapStateToProps, mapDispatchToProps)(ChatReplyView);
