import { info } from '../../helpers/common';
import { apiService } from '../../services/apiService';
import { IndexDbCollections } from '../../services/DbController';
import { DbController } from '../../services/DbController';
import { ContactStatus } from '../../services/enumService';
import SharedService from '../../services/sharedService';
import { AcceptInvitationRequestData, BlockUserRequestData, ChangePrivacySettingData, RejectInvitationRequestData, RemoveRosterItemRequestData, SendInvitationRequestData, UnBlockUserRequestData } from '../../types/api-requests';
import { ApiResponse } from '../../types/api-responses';
import { ContactItem } from '../../types/contact';
import { ContactStateTypes, GlobalStateTypes } from '../../types/redux-states';
import { SHOW_TOAST } from '../constants/common';
import {
	ACCEPT_INVITATION_FAILED,
	ACCEPT_INVITATION_START,
	ACCEPT_INVITATION_SUCCESS,
	BLOCK_USER_FAILED,
	BLOCK_USER_START,
	BLOCK_USER_SUCCESS,
	UNBLOCK_USER_FAILED,
	UNBLOCK_USER_START,
	UNBLOCK_USER_SUCCESS,
	GET_ROASTER_ITEMS_FAILED,
	GET_ROASTER_ITEMS_START,
	GET_ROASTER_ITEMS_SUCCESS,
	REMOVE_ROASTER_ITEM_FAILED,
	REMOVE_ROASTER_ITEM_START,
	REMOVE_ROASTER_ITEM_SUCCESS,
	REJECT_INVITATION_FAILED,
	REJECT_INVITATION_START,
	REJECT_INVITATION_SUCCESS,
	SEND_INVITATION_FAILED,
	SEND_INVITATION_START,
	SEND_INVITATION_SUCCESS,
	GET_BLOCKED_ROASTER_ITEMS_START,
	GET_BLOCKED_ROASTER_ITEMS_SUCCESS,
	GET_BLOCKED_ROASTER_ITEMS_FAILED,
	GET_INVITATION_REQUEST_SUCCESS,
	GET_INVITATION_REQUEST_FAILED,
	GET_INVITATION_REQUEST_START,
	CHANGe_CONTACT_PRIVACY_SETTING_START,
	CHANGe_CONTACT_PRIVACY_SETTING_FAILED,
	CHANGe_CONTACT_PRIVACY_SETTING_SUCCESS,
} from '../constants/contact';
import { hideLoader, showLoader, showStylishToast } from './global';

export function sendInvitation(payload: SendInvitationRequestData) {
	return async function (dispatch: any) {
		dispatch({ type: SEND_INVITATION_START });
		showLoader({ loaderMessage: 'Sending...' });

		return new Promise(async (resolve, reject) => {
			await apiService
				.sendInvitation(payload)
				.then(async (response: ApiResponse) => {
					if (response.success) {
						const result = response.result;
						dispatch({ type: SEND_INVITATION_SUCCESS });

						dispatch(getRosterItems());
						resolve(result);
					} else {
						dispatch({ type: SEND_INVITATION_FAILED, payload: response });
						reject(response);

						dispatch({
							type: SHOW_TOAST,
							payload: {
								showToast: true,
								toastMessage: response.message,
								toastType: 'danger',
							} as GlobalStateTypes,
						});
					}
				})
				.catch((error: any) => {
					dispatch({ type: SEND_INVITATION_FAILED, payload: error });
					dispatch({
						type: SHOW_TOAST,
						payload: {
							showToast: true,
							toastMessage: SharedService.extractErrorMessage(error),
							toastType: 'danger',
						} as GlobalStateTypes,
					});
					reject(error);
				})
				.finally(() => {
					hideLoader();
				});
		});
	};
}

export function acceptInvitation(payload: AcceptInvitationRequestData) {
	return async function (dispatch: any) {
		dispatch({ type: ACCEPT_INVITATION_START });
		showLoader({ loaderMessage: 'Confirming...' });

		return new Promise(async (resolve, reject) => {
			await apiService
				.acceptInvitation(payload)
				.then(async (response: ApiResponse) => {
					if (response.success) {
						const result = response.result;
						const contacts = await dispatch(getRosterItems());
						dispatch({ type: ACCEPT_INVITATION_SUCCESS });
						resolve(contacts);
					} else {
						dispatch({ type: ACCEPT_INVITATION_FAILED, payload: response });
						dispatch({
							type: SHOW_TOAST,
							payload: {
								showToast: true,
								toastMessage: response.message,
								toastType: 'danger',
							} as GlobalStateTypes,
						});
						reject(response);
					}
				})
				.catch((error: any) => {
					dispatch({ type: ACCEPT_INVITATION_FAILED, payload: error });
					dispatch({
						type: SHOW_TOAST,
						payload: {
							showToast: true,
							toastMessage: SharedService.extractErrorMessage(error),
							toastType: 'danger',
						} as GlobalStateTypes,
					});
					reject(error);
				})
				.finally(() => {
					hideLoader();
				});
		});
	};
}

export function rejectInvitation(payload: RejectInvitationRequestData) {
	return async function (dispatch: any) {
		dispatch({ type: REJECT_INVITATION_START });
		showLoader({ loaderMessage: 'Rejecting...' });

		return new Promise(async (resolve, reject) => {
			await apiService
				.rejectInvitation(payload)
				.then(async (response: ApiResponse) => {
					if (response.success) {
						const result = response.result;
						await dispatch(getRosterItems());
						dispatch({ type: REJECT_INVITATION_SUCCESS });
						resolve(result);
					} else {
						dispatch({ type: REJECT_INVITATION_FAILED, payload: response });
						dispatch({
							type: SHOW_TOAST,
							payload: {
								showToast: true,
								toastMessage: response.message,
								toastType: 'danger',
							} as GlobalStateTypes,
						});
						reject(response);
					}
				})
				.catch((error: any) => {
					dispatch({ type: REJECT_INVITATION_FAILED, payload: error });
					dispatch({
						type: SHOW_TOAST,
						payload: {
							showToast: true,
							toastMessage: SharedService.extractErrorMessage(error),
							toastType: 'danger',
						} as GlobalStateTypes,
					});
					reject(error);
				})
				.finally(() => {
					hideLoader();
				});
		});
	};
}

export function blockUser(payload: BlockUserRequestData) {
	return async function (dispatch: any) {
		dispatch({ type: BLOCK_USER_START });
		showLoader({ loaderMessage: 'Loading...' });

		return new Promise(async (resolve, reject) => {
			await apiService
				.blockUser(payload)
				.then(async (response: ApiResponse) => {
					if (response.success) {
						const result = response.result;
						dispatch({ type: BLOCK_USER_SUCCESS });
						dispatch(getRosterItems());
						dispatch(getBlockedRosterItems());
						resolve(result);
					} else {
						dispatch({ type: BLOCK_USER_FAILED, payload: response });
						dispatch({
							type: SHOW_TOAST,
							payload: {
								showToast: true,
								toastMessage: response.message,
								toastType: 'danger',
							} as GlobalStateTypes,
						});
						reject(response);
					}
				})
				.catch((error: any) => {
					dispatch({ type: BLOCK_USER_FAILED, payload: error });
					dispatch({
						type: SHOW_TOAST,
						payload: {
							showToast: true,
							toastMessage: SharedService.extractErrorMessage(error),
							toastType: 'danger',
						} as GlobalStateTypes,
					});
					reject(error);
				})
				.finally(() => {
					hideLoader();
				});
		});
	};
}

export function unBlockUser(payload: UnBlockUserRequestData) {
	return async function (dispatch: any) {
		dispatch({ type: UNBLOCK_USER_START });
		showLoader({ loaderMessage: 'Loading...' });

		return new Promise(async (resolve, reject) => {
			await apiService
				.unBlockUser(payload)
				.then(async (response: ApiResponse) => {
					if (response.success) {
						const result = response.result;
						dispatch({ type: UNBLOCK_USER_SUCCESS });
						dispatch(getRosterItems());
						dispatch(getBlockedRosterItems());
						resolve(result);
					} else {
						dispatch({ type: UNBLOCK_USER_FAILED, payload: response });
						dispatch({ type: SHOW_TOAST, payload: { showToast: true, toastMessage: response.message, toastType: 'danger' } as GlobalStateTypes });
						reject(response);
					}
				})
				.catch((error: any) => {
					dispatch({ type: UNBLOCK_USER_FAILED, payload: error });
					dispatch({ type: SHOW_TOAST, payload: { showToast: true, toastMessage: SharedService.extractErrorMessage(error), toastType: 'danger' } as GlobalStateTypes });
					reject(error);
				})
				.finally(() => {
					hideLoader();
				});
		});
	};
}

export function changePrivacySetting(payload: ChangePrivacySettingData, options?: { loaderMessage: string }) {
	return async function (dispatch: any) {
		dispatch({ type: CHANGe_CONTACT_PRIVACY_SETTING_START });
		showLoader({ loaderMessage: options?.loaderMessage || 'Updating...' });
		return new Promise(async (resolve, reject) => {
			await apiService
				.changePrivacySetting({ param: payload })
				.then(async (response: ApiResponse) => {
					if (response.success) {
						const result = response.result;
						dispatch({ type: CHANGe_CONTACT_PRIVACY_SETTING_FAILED });
						dispatch(getRosterItems());
						resolve(result);
					} else {
						dispatch({ type: CHANGe_CONTACT_PRIVACY_SETTING_FAILED, payload: response });
						reject(response);
					}
				})
				.catch((error: any) => {
					dispatch({ type: CHANGe_CONTACT_PRIVACY_SETTING_SUCCESS, payload: error });
					reject(error);
				})
				.finally(() => {
					hideLoader();
				});
		});
	};
}

export function getRosterItems() {
	return async function (dispatch: any) {
		dispatch({ type: GET_ROASTER_ITEMS_START });

		return new Promise(async (resolve, reject) => {
			try {
				const rostersRes: ApiResponse = await apiService.getRosterItems();

				info('getRosterItems response');
				if (rostersRes && !rostersRes.error) {
					const rosters = rostersRes && rostersRes.result ? rostersRes.result : [];
					const sentRequests: Array<ContactItem> = [],
						receivedRequests: Array<ContactItem> = [],
						contacts: Array<ContactItem> = [];

					rosters.forEach(async (item: ContactItem) => {
						if (item.status === ContactStatus.BOTH) contacts.push(item);
						if (item.status === ContactStatus.OUT_REQUEST) sentRequests.push(item);
						if (item.status === ContactStatus.IN_REQUEST) receivedRequests.push(item);
					});
					[...sentRequests, ...receivedRequests, ...contacts].forEach(async (item: ContactItem) => {
						try {
							const updateRes = await DbController.update(IndexDbCollections.CONTACTS.name, item, item.username);
							if (!updateRes) {
								await DbController.put(IndexDbCollections.CONTACTS.name, item);
							}
						} catch (error) {}
					});

					dispatch({
						type: GET_ROASTER_ITEMS_SUCCESS,
						payload: {
							sentRequests,
							receivedRequests,
							contacts,
						} as ContactStateTypes,
					});
					resolve(contacts);
				} else {
					resolve([]);
				}
			} catch (error) {
				dispatch({ type: GET_ROASTER_ITEMS_FAILED, payload: error });
				reject(error);
			}
		});
	};
}

export function getInvitationRequests() {
	return async function (dispatch: any) {
		dispatch({ type: GET_INVITATION_REQUEST_START });

		return new Promise(async (resolve, reject) => {
			try {
				const invitationsRes: ApiResponse = await apiService.getInvitationRequests();
				info('getInvitationRequests response');
				const invitations: Array<ContactItem> = invitationsRes && invitationsRes.result ? invitationsRes.result : [];

				invitations.forEach(async (item: ContactItem) => {
					item.status = ContactStatus.IN_REQUEST;
					try {
						const updateRes = await DbController.update(IndexDbCollections.CONTACTS.name, item, item.username);
						if (!updateRes) {
							await DbController.put(IndexDbCollections.CONTACTS.name, item);
						}
					} catch (error) {}
				});

				dispatch({
					type: GET_INVITATION_REQUEST_SUCCESS,
					payload: { receivedRequests: invitations } as ContactStateTypes,
				});
				resolve(invitations);
			} catch (error) {
				dispatch({ type: GET_INVITATION_REQUEST_FAILED, payload: error });
				reject(error);
			}
		});
	};
}

export function getBlockedRosterItems() {
	return async function (dispatch: any) {
		dispatch({ type: GET_BLOCKED_ROASTER_ITEMS_START });

		return new Promise(async (resolve, reject) => {
			try {
				const rostersRes: ApiResponse = await apiService.getBlockedRosterItems();
				const rosters = rostersRes && rostersRes.result ? rostersRes.result : [];

				rosters.forEach(async (item: ContactItem) => {
					item.status = ContactStatus.BLOCKED;
				});

				rosters.forEach(async (item: ContactItem) => {
					try {
						const updateRes = await DbController.update(IndexDbCollections.CONTACTS.name, item, item.username);
						if (!updateRes) {
							await DbController.put(IndexDbCollections.CONTACTS.name, item);
						}
					} catch (error) {}
				});

				dispatch({
					type: GET_BLOCKED_ROASTER_ITEMS_SUCCESS,
					payload: { blockedList: rosters } as ContactStateTypes,
				});
				resolve(rosters);
			} catch (error) {
				dispatch({ type: GET_BLOCKED_ROASTER_ITEMS_FAILED, payload: error });
				reject(error);
			}
		});
	};
}

export function removeRosterItem(payload: RemoveRosterItemRequestData) {
	return async function (dispatch: any) {
		dispatch({ type: REMOVE_ROASTER_ITEM_START });
		showLoader({ loaderMessage: 'Deleting...' });

		return new Promise(async (resolve, reject) => {
			await apiService
				.removeRosterItem(payload)
				.then(async (response: ApiResponse) => {
					if (response.success) {
						const result = response.result;
						try {
							await DbController.delete(IndexDbCollections.CONTACTS.name, {
								username: payload.username,
								deleteBy: 'username',
							});
						} catch (error) {}
						await dispatch(getRosterItems());
						dispatch({ type: REMOVE_ROASTER_ITEM_SUCCESS, payload: result });
						dispatch(
							showStylishToast({
								stylishToastMessage: 'Deleted',
								stylishToastIcon: 'check',
							})
						);
						resolve(result);
					} else {
						dispatch({ type: REMOVE_ROASTER_ITEM_FAILED, payload: response });
						dispatch({
							type: SHOW_TOAST,
							payload: {
								showToast: true,
								toastMessage: response.message,
								toastType: 'danger',
							} as GlobalStateTypes,
						});
						reject(response);
					}
				})
				.catch((error: any) => {
					dispatch({ type: REMOVE_ROASTER_ITEM_FAILED, payload: error });
					dispatch({
						type: SHOW_TOAST,
						payload: {
							showToast: true,
							toastMessage: SharedService.extractErrorMessage(error),
							toastType: 'danger',
						} as GlobalStateTypes,
					});
					reject(error);
				})
				.finally(() => {
					hideLoader();
				});
		});
	};
}
