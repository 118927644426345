import { IonButton, IonIcon, IonInput, IonModal } from '@ionic/react';
import React, { createRef, useCallback, useEffect, useState } from 'react';
import './style.scss';
import { connect } from 'react-redux';
import { updateUser } from '../redux/actions/profile';
import { pageLoading, hidePageLoading } from '../redux/actions/dashboard';
import { dbService } from '../services/dbService';
import { info } from '../helpers/common';

const ClearIcon = './assets/icon/close-icon.svg';

interface AddNewTagPopupProps {
	show: boolean;
	pageLoading: any;
	hidePageLoading: any;
	onCloseCallBack: any;
	onTagCreated: any;
	updateUser?: any;
}

const AddNewTagPopup: React.FC<AddNewTagPopupProps> = ({ show, updateUser, pageLoading, hidePageLoading, onCloseCallBack, onTagCreated }) => {
	const [tagName, setTagName] = useState<string | null | undefined>('');
	const tagInputRef: React.RefObject<HTMLIonInputElement> = createRef();

	useEffect(() => {
		if (!show && tagName) {
			setTagName('');
		}
	}, [show, tagName]);

	const addNewTagConfirm = useCallback(async () => {
		const loggedInUser = await dbService.me();
		info('id', loggedInUser);
		let existingTags = [],
			isTagAlreadyAvailable = false;
		if (loggedInUser && loggedInUser.tags && loggedInUser.tags.length > 0) {
			// Checghk if tag is already added
			existingTags = loggedInUser.tags;
			existingTags.some((tag: string) => {
				if (tag === tagName) {
					isTagAlreadyAvailable = true;
					return true;
				}
				return false;
			});
		}
		if (!isTagAlreadyAvailable) {
			existingTags.push(tagName);
			pageLoading({ loaderMessage: 'Creating...' });
			updateUser({
				_id: loggedInUser._id,
				tags: existingTags,
				updateServer: true,
				callBack: (status: boolean, response: any) => {
					hidePageLoading();
					if (status) {
						onTagCreated && onTagCreated(tagName);
					}
				},
			});
		}
	}, [tagName, hidePageLoading, onTagCreated, pageLoading, updateUser]);
	return (
		<IonModal showBackdrop={true} isOpen={show} onDidDismiss={onCloseCallBack} swipeToClose={true} cssClass="add-new-tag-popup" animated={true}>
			<div className="container">
				<div className="content-view">
					<div className="title">New Tag</div>
					<div className="msg-tag">Tags are to help users keep their chats organized.</div>

					<div className="tag-input-container">
						<div className="input-view">
							<IonInput ref={tagInputRef} id="tagNameInput" placeholder="Family, Creatives, Blockchain, etc" className="tag-name-input" value={tagName} onIonChange={(event) => setTagName(event.detail.value)} />
							{tagName && (
								<IonIcon
									className="clear-icon"
									src={ClearIcon}
									onClick={() => {
										setTagName('');
										const tagNameInput: any = document.getElementById('tagNameInput');
										if (tagNameInput && tagNameInput.setFocus) {
											tagNameInput.setFocus();
										}
									}}
								></IonIcon>
							)}
						</div>
					</div>
					<div className="btns-view">
						<IonButton fill="clear" className="delete-btn" onClick={onCloseCallBack}>
							Cancel
						</IonButton>
						<IonButton fill="clear" className="ok-btn" onClick={addNewTagConfirm} disabled={!tagName}>
							Create Tag
						</IonButton>
					</div>
				</div>
			</div>
		</IonModal>
	);
};

const mapStateToProps = (state: any) => {
	return {};
};
const mapDispatchToProps = (dispatch: any) => ({
	updateUser: (payload: String) => dispatch(updateUser(payload)),
	pageLoading: (payload: any) => dispatch(pageLoading(payload)),
	hidePageLoading: () => dispatch(hidePageLoading()),
});

export default connect(mapStateToProps, mapDispatchToProps)(AddNewTagPopup);
