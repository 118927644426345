import React, { useEffect, useState } from 'react';
import { locale } from '../../locales/local';
import Picker, { SKIN_TONE_NEUTRAL } from 'emoji-picker-react';
import { IonButton, IonIcon } from '@ionic/react';
import './Emoji.scss';
import { add, remove } from 'ionicons/icons';
import EmojiStickerSearchModal from '../../modals/EmojiStickerSearchModal/EmojiStickerSearchModal';
import { GiphyItem } from '../../types/giphy';
import { Grid } from '@giphy/react-components';
import { dbService } from '../../services/dbService';
import FavouriteStickerRemoveModal from '../../modals/FavouriteStickerRemoveModal/FavouriteStickerRemoveModal';
import SharedService, { sharedService } from '../../services/sharedService';
import PubSub from 'pubsub-js';
import { PubSubEventType } from '../../services/enumService';

const SearchIcon = './assets/icon/search.svg';
const EmojiIcon = './assets/icon/smiley-icon.svg';
const EmojiActiveIcon = './assets/icon/emoji-icon-active.svg';
const StickerIcon = './assets/icon/stickericon.svg';
const StickerActiveIcon = './assets/icon/stickericon-active.svg';
const CloseIcon = './assets/icon/close-arrow.svg';

const GifCountPerSlot = 20;

interface iProps {
	message?: string;
	onCancel?: () => void;
	onEmojiChoose?: (event: any) => void;
	onGiphyChoose?: (giphy: GiphyItem) => void;
}

const Emoji: React.FC<iProps> = ({ message, onEmojiChoose, onGiphyChoose, onCancel }) => {
	const [optionType, setOptionType] = useState<string>('emoji');
	const [isSearchOpen, setIsSearchOpen] = useState<boolean>(false);
	const [isFavouriteStickerRemoveModalOpen, setIsFavouriteStickerRemoveModalOpen] = useState<boolean>(false);
	const [isSearchOpenForFavAdd, setIsSearchOpenForFavAdd] = useState<boolean>(false);
	const [favGifsList, setFavGifsList] = useState<Array<GiphyItem>>([]);

	const getFavoriteGifs = async () => {
		dbService.favGifs().then((gifsData) => {
			if (gifsData && gifsData.length > 0) {
				setFavGifsList(gifsData[0].fav_gifs);
			}
		});
	};

	useEffect(() => {
		const favGifsAddedPubsubToken = PubSub.subscribe(PubSubEventType.FavGifsUpdated, (msg: any, data: any) => {
			if (data.action === PubSubEventType.FavGifsUpdated) {
				setFavGifsList([]);
				getFavoriteGifs();
			}
		});
		return () => {
			PubSub.unsubscribe(favGifsAddedPubsubToken);
		};
	}, []);

	useEffect(() => {
		sharedService.isEmojiStickerViewOpened = true;
		getFavoriteGifs();
		return () => {
			sharedService.isEmojiStickerViewOpened = false;
		};
	}, []);

	const addGifToFav = async (giphy: GiphyItem) => {
		setFavGifsList([]);
		const gifs = await SharedService.addFavGif({
			id: giphy.id,
			fixed_widthObj: giphy.images.fixed_width,
		});
		setTimeout(() => {
			setFavGifsList([...gifs]);
		}, 100);
	};

	const onEmojiClick = (event: any, emojiObject: any) => {
		onEmojiChoose && onEmojiChoose(emojiObject);
	};
	const onCancelClick = () => {
		onCancel && onCancel();
	};

	const fetchGifs = (offset: number) => {
		return new Promise((resolve: (value: any) => void, reject) => {
			if (favGifsList && favGifsList.length >= offset) {
				const latestData = favGifsList.slice(offset, offset + GifCountPerSlot);
				resolve({
					data: latestData,
					pagination: {
						total_count: favGifsList.length,
						count: latestData.length,
						offset: offset,
					},
				});
			} else {
				resolve([]);
			}
		});
	};

	return (
		<>
			<div className="emoji-sticker-choose-view">
				<IonButton fill="clear" onClick={() => setIsSearchOpen(true)}>
					<IonIcon src={SearchIcon}></IonIcon>
				</IonButton>
				<IonButton fill="clear" onClick={() => setOptionType('emoji')}>
					<IonIcon src={optionType === 'emoji' ? EmojiActiveIcon : EmojiIcon}></IonIcon>
				</IonButton>
				<IonButton fill="clear" onClick={() => setOptionType('sticker')}>
					<IonIcon src={optionType === 'sticker' ? StickerActiveIcon : StickerIcon}></IonIcon>
				</IonButton>
			</div>
			{optionType === 'emoji' && (
				<>
					<Picker onEmojiClick={onEmojiClick} disableAutoFocus={true} skinTone={SKIN_TONE_NEUTRAL} disableSkinTonePicker={true} groupNames={locale.chat.emoji_labels} groupVisibility={{ flags: false }} native={true} preload={true} />

					{message && (
						<div className="send-cancel-btns">
							<IonButton className="clear-btn" fill="clear" onClick={onCancelClick}>
								<IonIcon src={CloseIcon}></IonIcon>
							</IonButton>
							{/* <IonButton className="send-btn" fill="clear">
							Send
						</IonButton> */}
						</div>
					)}
				</>
			)}

			{optionType === 'sticker' && (
				<div className="stickers-view-container">
					<div className="title-container">
						<div className="title">Custom Stickers</div>
						{favGifsList.length > 0 && (
							<IonButton
								className="add-remove-sticker-btn"
								fill="clear"
								onClick={() => {
									setIsFavouriteStickerRemoveModalOpen(true);
								}}
							>
								<IonIcon icon={remove}></IonIcon>
							</IonButton>
						)}
						<IonButton
							className="add-remove-sticker-btn"
							fill="clear"
							onClick={() => {
								setIsSearchOpenForFavAdd(true);
							}}
						>
							<IonIcon icon={add}></IonIcon>
						</IonButton>
					</div>
					<div className="stickers">
						{favGifsList.length > 0 && (
							<Grid
								width={window.innerWidth - 32}
								gutter={16}
								columns={4}
								fetchGifs={fetchGifs}
								noLink={true}
								overlay={() => null}
								onGifsFetched={() => {}}
								onGifClick={(gif: any) => {
									onGiphyChoose && onGiphyChoose(gif);
								}}
							/>
						)}
					</div>
				</div>
			)}

			{isFavouriteStickerRemoveModalOpen && (
				<FavouriteStickerRemoveModal
					show={isFavouriteStickerRemoveModalOpen}
					onDimissed={() => {
						setIsFavouriteStickerRemoveModalOpen(false);
					}}
					onRemoved={(gifs: Array<GiphyItem>) => {
						setFavGifsList([]);

						setTimeout(() => {
							setFavGifsList([...gifs]);
						}, 100);
					}}
				/>
			)}

			<EmojiStickerSearchModal
				show={isSearchOpen || isSearchOpenForFavAdd}
				displayTypes={isSearchOpenForFavAdd ? ['sticker'] : ['emoji', 'sticker']}
				onCancel={() => {
					setIsSearchOpenForFavAdd(false);
					setIsSearchOpen(false);
				}}
				onSelectEmoji={(emoji: any) => {
					if (!isSearchOpenForFavAdd) {
						setIsSearchOpen(false);
						onEmojiChoose && onEmojiChoose(emoji);
					}
				}}
				onSelectGiphy={(giphy: GiphyItem) => {
					if (isSearchOpenForFavAdd) {
						addGifToFav(giphy);
						setTimeout(() => {
							setIsSearchOpenForFavAdd(false);
						}, 100);
					} else {
						setIsSearchOpen(false);
						onGiphyChoose && onGiphyChoose(giphy);
					}
				}}
			/>
		</>
	);
};

export default Emoji;
