import { REGISTER_REQUESTED, REGISTER_SUCCESS, REGISTER_FAILED, REGISTER_ERROR, USERNAME_ERROR, USERNAME_AVAILABLE, USERNAME_UNAVAILABLE, SET_REGISTER_USERNAME, SET_REGISTER_EMAIL, SET_REGISTER_PASSWORD, SET_REGISTER_CPASSWORD } from '../constants/register';
import { REGISTER_SHOW_LOADER, REGISTER_FORM_ERROR, REGISTER_GENERAL_ERROR, REGISTER_RESET_VALUES } from '../constants/common';
import { locale } from '../../locales/local';

const initialState = {
	isLoggedIn: false,
	hasErrors: false,
	errorMessage: '',
	showLoading: false,
	userIdAvailable: false,
	creatingAccount: false,
	title: locale.register.title,
	userId: '',
	password: '',
	email: '',
	phone: null,
	cPassword: '',
	placeholder_username: locale.register.placeholder.username,
	placeholder_email: locale.register.placeholder.email,
	placeholder_phone: locale.register.placeholder.phone,
	placeholder_pwd: locale.register.placeholder.password,
	placeholder_cpwd: locale.register.placeholder.confirm_password_full,
	loadingText: locale.global.please_wait,
};
// eslint-disable-next-line import/no-anonymous-default-export
export default function (state = initialState, action: any) {
	switch (action.type) {
		case REGISTER_RESET_VALUES: {
			state.hasErrors = false;
			state.userIdAvailable = false;
			state.errorMessage = '';
			state.showLoading = false;
			state.creatingAccount = false;
			break;
		}

		case REGISTER_SHOW_LOADER: {
			state.showLoading = action.payload;
			break;
		}

		case REGISTER_FORM_ERROR: {
			state.showLoading = false;
			state.hasErrors = true;
			state.errorMessage = action.payload.errorMessage;
			break;
		}

		case SET_REGISTER_USERNAME: {
			state.hasErrors = action.payload.hasErrors;
			state.userId = action.payload.userId;
			break;
		}
		case SET_REGISTER_EMAIL: {
			state.hasErrors = action.payload.hasErrors;
			state.email = action.payload.email;
			break;
		}
		case SET_REGISTER_PASSWORD: {
			state.hasErrors = action.payload.hasErrors;
			state.password = action.payload.password;
			break;
		}
		case SET_REGISTER_CPASSWORD: {
			state.hasErrors = action.payload.hasErrors;
			state.cPassword = action.payload.cpassword;
			break;
		}

		case USERNAME_UNAVAILABLE: {
			state.userIdAvailable = false;
			state.hasErrors = true;
			state.showLoading = false;
			state.errorMessage = action.payload;
			break;
		}

		case USERNAME_AVAILABLE: {
			state.hasErrors = false;
			state.userIdAvailable = true;
			state.showLoading = false;
			break;
		}

		case USERNAME_ERROR: {
			state.hasErrors = true;
			state.showLoading = false;
			state.errorMessage = action.payload.errorMessage;
			break;
		}

		case REGISTER_REQUESTED: {
			state.hasErrors = false;
			state.errorMessage = '';
			state.showLoading = true;
			state.creatingAccount = true;
			break;
		}

		case REGISTER_SUCCESS: {
			state.hasErrors = false;
			state.showLoading = false;
			state.isLoggedIn = true;
			state.errorMessage = '';
			state.creatingAccount = false;
			break;
		}

		case REGISTER_FAILED: {
			state.hasErrors = true;
			state.showLoading = false;
			state.creatingAccount = false;
			state.errorMessage = action.payload.Error?.code ? ` ${action.payload.Error.message}` : locale.global.technical_difficulties;
			break;
		}

		case REGISTER_ERROR: {
			state.hasErrors = true;
			state.showLoading = false;
			state.creatingAccount = false;
			state.errorMessage = action.payload;
			break;
		}

		case REGISTER_GENERAL_ERROR: {
			state.hasErrors = true;
			state.showLoading = false;
			state.creatingAccount = false;
			state.errorMessage = action.payload.errorMessage;
			break;
		}

		default:
			break;
	}
	return { ...state };
}
