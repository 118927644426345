import React from 'react';
import { IonAlert, IonButton, IonCheckbox, IonContent, IonLabel, IonPage, IonToggle } from '@ionic/react';
import './style.scss';
import { RouteComponentProps } from 'react-router';
import { IonItem } from '@ionic/react';
import { TopNavbar } from '../../../common/header/topbar';
import { GlobalStateTypes, ProfileStateTypes, ReduxStates } from '../../../../types/redux-states';
import AddNewThread from '../../../../modals/AddNewThread';
import DeleteContact from '../../../../modals/DeleteContact';
import { PersonalFolderShowType, ThreadType } from '../../../../services/enumService';
import { Navigation } from '../../../../services/navigationService';
import { EditThreadNavParams } from '../../../../types/navigation';
import { connect } from 'react-redux';
import { CreateThreadRequestData } from '../../../../types/api-requests';
import { showAlert } from '../../../../redux/actions/global';
import { createThread } from '../../../../redux/actions/thread';
import { ThreadItem } from '../../../../types/thread';
import { ContactItem } from '../../../../types/contact';
import { GroupItem } from '../../../../types/group';

interface iProps extends RouteComponentProps<{ name: string }> {
	profile: ProfileStateTypes;
	showAlert: (payload: GlobalStateTypes) => void;
	createThread: (payload: CreateThreadRequestData) => Promise<Array<ThreadItem>>;
}
interface iState {
	selectitem: any;
	threadList: any;
	showAddThreadPopup: boolean;
	selectedThread: any;
	isManagingThread: boolean;
	threadsList: any;
	showAlert: boolean;
	allSelectedItems: any;
	loggedInUser?: any;
	contacts: any;
	isLoading?: boolean;
	loadingMessage?: string;
	showAddTagPopup: boolean;
	isManagingTag: boolean;
	selectededitThread?: any;
	showActionSheetFor?: any;
	showToast: boolean;
	toastMessage: string;
	toastIcon: string;
	showDeletedAllBgMsg: boolean;
	contactItemType: any;
	groupItemType: any;
}

class editThread extends React.Component<iProps, iState> {
	componentIsMounted: Boolean = false;
	componentIsUpdated: Boolean = false;
	navParams: EditThreadNavParams = {} as any;

	tagUpdating: boolean = false;

	constructor(props: iProps) {
		super(props);

		this.state = {
			selectitem: null,
			threadList: [],
			showAddThreadPopup: false,
			showAlert: false,
			selectedThread: [],
			isManagingThread: false,
			threadsList: [],
			allSelectedItems: [],
			showAddTagPopup: false,
			selectededitThread: [],
			contacts: undefined,
			isManagingTag: false,
			isLoading: false,
			loadingMessage: '',
			showToast: false,
			showDeletedAllBgMsg: false,
			toastMessage: '',
			toastIcon: '',
			contactItemType: '',
			groupItemType: '',
		};
	}

	componentDidMount = async () => {
		Navigation.getNavParamData(this.props).then(async (res: any) => {
			this.navParams = res;

			let contactItem: ContactItem | undefined;
			let groupItem: GroupItem | undefined;
			let listChatThread: any = [];

			if (this.navParams && this.navParams.data) {
				contactItem = this.navParams.data?.itemType as ContactItem;
				groupItem = this.navParams.data.itemType as GroupItem;
				listChatThread = this.navParams.data.item as ThreadItem;
				this.setState({ threadList: listChatThread, contactItemType: contactItem });
			}
		});
	};

	_renderSwitchItem = (title: any, value: any, onChange: any) => {
		return (
			<div className={'swtich-item'} slot="end">
				<IonLabel>{title}</IonLabel>
				<IonToggle
					slot="end"
					className="toggle-customised"
					mode="ios"
					checked={value}
					onIonChange={(event: any) => {
						if (this.componentIsMounted) {
							onChange(event);
						}
					}}
				/>
			</div>
		);
	};
	threadDetail(item: any) {
		Navigation.openPersonalFolder({
			data: item,
			showFolderType: PersonalFolderShowType.ThreadDetail,
		});
	}

	render() {
		const { threadsList } = this.state;
		let rightBtnTitle = 'Done()';
		if (this.state.allSelectedItems.length > 0) {
			rightBtnTitle = 'Done(' + this.state.allSelectedItems.length + ')';
		}

		return (
			<>
				<IonPage className="editThread-page">
					<TopNavbar
						{...this.props}
						isHideRightButton={false}
						showBack={true}
						hideSearchBar={true}
						pageTitle={'Threads'}
						rightButtonText={this.state.isManagingThread ? rightBtnTitle : ''}
						pageTitleDescription={'Total Size'}
						onLeftButtonPress={() => {
							Navigation.back();
						}}
						onRightButtonPress={() => {
							{
								this.state.threadList.length >= 9
									? this.props.showAlert({ alertMessage: `You Have Already Created Max 9 Thread` })
									: this.state.threadList.length <= 9 && (this.state.isManagingThread ? this.state.allSelectedItems.length > 0 && this.setState({ showAlert: true }) : this.setState({ showAddThreadPopup: true }));
							}
						}}
					/>

					<IonContent className="main-content-profile has-bottom-navbar has-topbar">
						<div className="folderlist-container">
							{this.state.threadList.length > 0 &&
								this.state.threadList.map((item: any, key: any) => {
									return (
										<div className={'folderlist-item' + (this.state.allSelectedItems.indexOf(item) !== -1 ? ' after-select' : '')} onClick={() => this.threadDetail(item)}>
											{this.state.isManagingThread && (
												<IonCheckbox
													onClick={(event) => {
														event.stopPropagation();
													}}
													className="check"
													slot="start"
													color="primary"
													key={key}
													checked={this.state.allSelectedItems.indexOf(item) !== -1}
													onIonChange={(event) => {
														const isChecked = event.detail.checked;
														const ids = this.state.allSelectedItems;
														const threadIdIndex = ids.indexOf(item);

														if (isChecked) {
															if (threadIdIndex === -1) {
																ids.push(item);
															}
														} else {
															if (threadIdIndex !== -1) {
																ids.splice(threadIdIndex, 1);
															}
														}
														this.setState({ allSelectedItems: ids });
													}}
												/>
											)}

											<div className="folderlist_name">
												{item.threadName}

												<img className="folderlist-dot" alt="" src={'../assets/icon/3doticon.svg'}></img>
												<div className="folder-list">
													<div className="like">130.2 MB</div>
												</div>
											</div>
										</div>
									);
								})}
						</div>
					</IonContent>
					{threadsList && threadsList?.length === 0 && (
						<IonItem className="manage-toggle-item" lines="none">
							{!this.state.isManagingThread && threadsList && threadsList.length === 0 && (
								<>
									<IonButton
										fill="clear"
										className="title"
										onClick={() => {
											this.setState({ isManagingThread: true });
										}}
									>
										Manage
									</IonButton>

									{this._renderSwitchItem('Minimize', this.props.profile?.hideAllTags, (event: any) => {
										if (!this.tagUpdating) {
											this.tagUpdating = true;
											// this.props.setTagsVisibility({ tagsVisibility: { hideAll: !this.props.profile.hideAllTags } }).then(() => {
											// 	setTimeout(() => {
											// 		this.tagUpdating = false;
											// 	}, 500);
											// });
										}
									})}
								</>
							)}

							{this.state.isManagingThread && (
								<>
									<IonButton
										fill="clear"
										className="cancel-btn"
										onClick={() => {
											this.setState({ isManagingThread: false, allSelectedItems: [] });
										}}
									>
										Cancel
									</IonButton>
									<IonButton
										fill="clear"
										slot="end"
										className="delete-tags-btn"
										disabled={this.state.allSelectedItems.length === 0}
										onClick={() => {
											this.setState({ showActionSheetFor: true });
										}}
									>
										Clear ({this.state.allSelectedItems.length > 0 ? this.state.allSelectedItems.length : ''})
									</IonButton>
								</>
							)}
						</IonItem>
					)}
				</IonPage>

				<AddNewThread
					show={this.state.showAddThreadPopup}
					onCloseCallBack={() => {
						this.setState({ showAddThreadPopup: false });
					}}
					threadName={''}
					onCreatThread={async (threadAddName) => {
						const foundSameThread = this.state.threadList.findIndex((thread: any) => thread.threadName === threadAddName);
						if (foundSameThread >= 0) {
							this.props.showAlert({ alertMessage: `Thread name is already exists` });
						} else {
							const threadList: Array<ThreadItem> = await this.props.createThread({
								userOrMucId: this.state.contactItemType.username ? this.state.contactItemType.username : this.state.groupItemType.name,
								type: this.state.contactItemType?.username ? ThreadType.P2P : ThreadType.GROUP,
								threadName: threadAddName,
							});
							// let selectedThread = threadList.find((thread) => thread.threadName === threadAddName);
							this.setState({ threadList, showAddThreadPopup: false });

							// if (selectedThread) {
							// 	this._threadChangeHandle(selectedThread, false);
							// } else {
							// 	this.setState({ showThreadMenu: false });
							// }
						}
					}}
				/>
				<DeleteContact
					type=""
					userData={'threadMessage'}
					show={this.state.showActionSheetFor}
					onDeleteCallBack={() => {
						('');
					}}
					onCloseCallBack={() => {
						this.setState({ showActionSheetFor: false });
					}}
				/>

				<IonAlert
					mode="ios"
					isOpen={this.state.showAlert}
					onDidDismiss={() => this.setState({ showAlert: false })}
					cssClass="alert-controller-class"
					header="DELETE THREAD"
					message={'Thread and its contents will be removed from this device only'}
					buttons={[{ cssClass: 'delete', text: 'Delete', role: 'destructive' }]}
				/>
			</>
		);
	}
}
const mapStateToProps = (state: ReduxStates) => {
	return {};
};

const mapDispatchToProps = (dispatch: any) => ({
	showAlert: (payload: GlobalStateTypes) => dispatch(showAlert(payload)),
	createThread: (payload: CreateThreadRequestData) => dispatch(createThread(payload)),
});

export default connect(mapStateToProps, mapDispatchToProps)(editThread as any);

// export default editThread;
