import React from 'react';
import { IonButton, IonContent, IonPage } from '@ionic/react';
import './style.scss';
import { RouteComponentProps } from 'react-router';
import { connect } from 'react-redux';
import { pageLoading, updateUser } from '../../redux/actions/profile';
import { TopNavbar } from '../common/header/topbar';
import { DefaultProfilePic } from '../../helpers/common';
import { dbService } from '../../services/dbService';
import _ from 'lodash';
import { Navigation } from '../../services/navigationService';
const CloseIcon = './assets/icon/Close.svg';

const FieldsKey = {
	GroupPhoto: 'groupPhoto',
	GroupName: 'groupName',
	GroupQrCode: 'groupQrCode',
	GroupNotice: 'groupNot  ice',
	GroupMemo: 'groupMemo',
	MyAliasInGroup: 'myAliasInGroup',
};

interface iProps extends RouteComponentProps<{ name: string }> {
	profile: any;
	loggedInUser: any;
	pageLoading: Function;
}

class GroupGuidelines extends React.Component<iProps, any> {
	componentIsMounted: Boolean = false;
	componentIsUpdated: Boolean = false;
	constructor(props: iProps) {
		super(props);

		this.state = {
			isNonAdmin: false,
			loggedInUser: undefined,
			isManager: undefined,
			currentActiveEditField: '',
			[FieldsKey.GroupPhoto]: null,
			[FieldsKey.GroupName]: 'Not Set',
			[FieldsKey.GroupQrCode]: '',
			[FieldsKey.GroupNotice]: 'This Group is Blah Blah Blah Blah Blah Blah Blah Blah Blah Blah Blah Blah Blah Blah Blah Blah Blah Blah Blah Blah Blah Blah Blah Blah Blah Blah... ',
			[FieldsKey.GroupMemo]: 'Stage 1 needs to be finished by next week!!!',
			[FieldsKey.MyAliasInGroup]: '',
		};
		this.submitChanges = this.submitChanges.bind(this);
	}

	getData = async () => {
		return await dbService.me();
	};

	setPageData = async () => {
		let user = await this.getData();

		this.setState((state: any, props: any) => ({
			...state,
			loggedInUser: user,
			isManager: _.includes(this.state.groupDetail?.groupAdmins, user._id),
		}));

		this.componentIsMounted = true;
	};

	componentDidMount = () => {
		this.setPageData();
	};

	componentDidUpdate = () => {
		if (!this.componentIsUpdated) {
			this.componentIsUpdated = true;
			this.setPageData();
		}
	};

	submitChanges() {}

	render() {
		if (this.componentIsMounted) {
			return (
				<IonPage className="group-guideline-page">
					<TopNavbar
						{...this.props}
						hideSearchBar={true}
						leftButtonIcon={CloseIcon}
						onLeftButtonPress={() => {
							Navigation.back();
						}}
						pageTitle=""
					/>
					<IonContent className="main-content-profile has-topbar">
						<div className="non-admin-container">
							<img src={DefaultProfilePic} className="nonadmin-img" alt="ProfilePhoto" />
						</div>
						<div className="non-architecture">ARCHITECTURE</div>
						<div className="total-member">50 Members</div>
						<div className="security-alert">You are not friends with other people in this group. Be alert to security risks</div>
						<div className="point-one">1. This group chat contains a lot of people. Be aware that you may receive many messages after joining.</div>
						<div className="point-two">2. You need to verify your real name to accept this invitation. Bind a bank card to verify your name.</div>
						<div className="point-one">3. To keep BE-SOCIETY safe for all members, do not spread any illegal or non-compliant contect in group chats.</div>
						<div className="join-button">
							<IonButton className="join">Join Group Chat</IonButton>
						</div>
					</IonContent>
					<div className="encryption-view">
						<h3>Encryption</h3>
						<p>All communication within this group chat is end-to-end encrypted</p>
					</div>
				</IonPage>
			);
		} else {
			return <></>;
		}
	}
}

const mapStateToProps = (state: any) => {
	return {
		profile: state.profile,
		loggedInUser: state.global.loggedInUser,
	};
};

const mapDispatchToProps = (dispatch: any) => ({
	updateUser: (payload: String) => dispatch(updateUser(payload)),
	pageLoading: (payload: any) => dispatch(pageLoading(payload)),
});

export default connect(mapStateToProps, mapDispatchToProps)(GroupGuidelines);
