import Dexie from 'dexie';
import { getInternalStorage } from '../helpers/common';
import { info, logError, isJsonString } from '../helpers/common';
import md5 from 'md5';
import { LocalStorageKeys, SessionStorageKeys } from './enumService';

export const IndexDbCollections = {
	USER: {
		name: 'user',
		primaryIndex: 'userId',
	},
	CHATONLYUSER: {
		name: 'chatonlyuser',
		primaryIndex: 'userId',
	},
	DATA: {
		name: 'data',
		primaryIndex: 'type',
		QR_CODE_TOKEN: 'qrcodetoken',
		TAGS: 'tags',
		FAV_GIFS: 'fav_gifs',
	},
	CONTACTS: {
		name: 'contacts',
		primaryIndex: 'username',
	},
	GROUPS: {
		name: 'groups',
		primaryIndex: 'name',
	},
	GROUP_MEMBERS: {
		name: 'group_members',
		primaryIndex: 'group',
	},
	MESSAGES: {
		name: 'messages',
		primaryIndex: 'usernameMessageKey',
	},
	MESSAGE_INFO: {
		name: 'messageinfo',
		primaryIndex: 'username',
	},
	NAVIGATION: {
		name: 'navigation',
		primaryIndex: 'type',
		HISTORY: 'history',
	},
	THREADS: {
		name: 'threads',
		primaryIndex: 'userOrMucId',
	},
};

const indexedDb = {
	db: null as Dexie | null,
	inError: false as boolean,

	initialise: async () => {
		const cookies = getInternalStorage();
		if (cookies[LocalStorageKeys.Uuid] && cookies[LocalStorageKeys.Uuid] !== 'undefined' && cookies[LocalStorageKeys.DB]) {
			indexedDb.db = new Dexie(cookies[LocalStorageKeys.DB], { autoOpen: true });
			indexedDb.db.version(cookies[LocalStorageKeys.DBVersion] || 1).stores({
				[IndexDbCollections.USER.name]: `&${IndexDbCollections.USER.primaryIndex}`,
				[IndexDbCollections.CHATONLYUSER.name]: `&${IndexDbCollections.CHATONLYUSER.primaryIndex}`,
				[IndexDbCollections.DATA.name]: `&${IndexDbCollections.DATA.primaryIndex}`,
				[IndexDbCollections.CONTACTS.name]: `&${IndexDbCollections.CONTACTS.primaryIndex}`,
				[IndexDbCollections.MESSAGES.name]: `&${IndexDbCollections.MESSAGES.primaryIndex}`,
				[IndexDbCollections.MESSAGE_INFO.name]: `&${IndexDbCollections.MESSAGE_INFO.primaryIndex}`,
				[IndexDbCollections.NAVIGATION.name]: `&${IndexDbCollections.NAVIGATION.primaryIndex}`,
				[IndexDbCollections.GROUPS.name]: `&${IndexDbCollections.GROUPS.primaryIndex}`,
				[IndexDbCollections.THREADS.name]: `&${IndexDbCollections.THREADS.primaryIndex}`,
				[IndexDbCollections.GROUP_MEMBERS.name]: `&${IndexDbCollections.GROUP_MEMBERS.primaryIndex}`,
			});
			info('indexedDb::initialize: initialized');
		} else {
			info('cookies[LocalStorageKeys.Uuid] undefined');
		}
	},

	getDb: async () => {
		let response: any;
		if (indexedDb.db) {
			response = indexedDb.db as any;
		} else if (!indexedDb.inError) {
			await indexedDb.initialise();
			response = indexedDb.db as any;
		}

		return response;
	},

	getDatabases: async () => await Dexie.getDatabaseNames(),

	getDatabase: async () => {
		let cookies: any = getInternalStorage();
		const db = md5(`${cookies[LocalStorageKeys.Uuid]}`);
		await Dexie.exists(db);
	},

	closeDatabase: async (database: any = undefined) => await indexedDb.db?.close(),

	deleteDatabase: async (database: any = undefined) => {
		let cookies: any = getInternalStorage();
		const db = md5(`${cookies[LocalStorageKeys.Uuid]}`);
		await Dexie.delete(database || db);
	},

	get: async (collection: any, sortBy: any = undefined, reverse: Boolean = false) => {
		try {
			return (await indexedDb.getDb())[collection]
				.toArray()
				.then((result: any[]) => {
					let response: any[] = result;

					if (sortBy) {
						response = response.sort((a: any, b: any) => (a[sortBy] > b[sortBy] ? 1 : a[sortBy] < b[sortBy] ? -1 : 0));
					}

					if (reverse) {
						response = response.reverse();
					}

					return collection === IndexDbCollections.USER.name || collection === IndexDbCollections.CHATONLYUSER.name ? response[0] : response;
				})
				.catch(async (e: any) => {
					if (!indexedDb.inError) {
						indexedDb.inError = true;
						await indexedDb.initialise();
						return await indexedDb.get(collection, sortBy, reverse);
					} else {
						logError(`indexedDb::get::Open failed querying ${collection}: ${e.stack}`);
						return [];
					}
				});
		} catch (error) {
			return null;
		}
	},

	whereLike: async (collection: any, key: String, value: any, sortBy: any = undefined, reverse: Boolean = false) => {
		return (await indexedDb.getDb())[collection]
			.where(key)
			.startsWith(value)
			.toArray()
			.then((result: any[]) => {
				let response: any[] = result;

				if (sortBy) {
					response = response.sort((a: any, b: any) => (a[sortBy] > b[sortBy] ? 1 : a[sortBy] < b[sortBy] ? -1 : 0));
				}

				if (reverse) {
					response = response.reverse();
				}

				return response;
			})
			.catch(async (e: any) => {
				if (!indexedDb.inError) {
					indexedDb.inError = true;
					await indexedDb.initialise();
					return await indexedDb.where(collection, key, value, sortBy, reverse);
				} else {
					logError(`indexedDb::where::Open "${collection}" failed querying ${isJsonString(key) ? JSON.stringify(key) : key} for ${value}: ${e.stack}`);
					return [];
				}
			});
	},

	where: async (collection: any, key: String, value: any, sortBy: any = undefined, reverse: Boolean = false) => {
		return (await indexedDb.getDb())[collection]
			.where(key)
			.equals(value)
			.toArray()
			.then((result: any[]) => {
				let response: any[] = result;

				if (sortBy) {
					response = response.sort((a: any, b: any) => (a[sortBy] > b[sortBy] ? 1 : a[sortBy] < b[sortBy] ? -1 : 0));
				}

				if (reverse) {
					response = response.reverse();
				}

				return response;
			})
			.catch(async (e: any) => {
				if (!indexedDb.inError) {
					indexedDb.inError = true;
					await indexedDb.initialise();
					return await indexedDb.where(collection, key, value, sortBy, reverse);
				} else {
					logError(`indexedDb::where::Open "${collection}" failed querying ${isJsonString(key) ? JSON.stringify(key) : key} for ${value}: ${e.stack}`);
					return [];
				}
			});
	},

	whereObject: async (collection: any, query: any, sortBy: any = undefined, reverse: Boolean = false) =>
		await (
			await indexedDb.getDb()
		)[collection]
			.where(query)
			.toArray()
			.then((result: any) => {
				let response: any[] = result;
				if (sortBy) {
					response = response.sort((a: any, b: any) => (a[sortBy] > b[sortBy] ? 1 : a[sortBy] < b[sortBy] ? -1 : 0));
				}

				if (reverse) {
					response = response.reverse();
				}

				return response;
			})
			.catch(async (e: any) => {
				if (!indexedDb.inError) {
					indexedDb.inError = true;
					await indexedDb.initialise();
					return await indexedDb.whereObject(collection, query, sortBy, reverse);
				} else {
					logError(`indexedDb::whereObject::Open ${collection} failed querying ${query}: ${e.stack}`);
					return [];
				}
			}),

	whereIn: async (collection: any, index: string, range: any[], sortBy: any = undefined, reverse: Boolean = false) =>
		await (
			await indexedDb.getDb()
		)[collection]
			.where(index)
			.inAnyRange([range])
			.toArray()
			.then((result: any) => {
				let response: any[] = result;
				if (sortBy) {
					response = response.sort((a: any, b: any) => (a[sortBy] > b[sortBy] ? 1 : a[sortBy] < b[sortBy] ? -1 : 0));
				}

				if (reverse) {
					response = response.reverse();
				}

				return response;
			})
			.catch(async (e: any) => {
				if (!indexedDb.inError) {
					indexedDb.inError = true;
					await indexedDb.initialise();
					return await indexedDb.whereIn(collection, index, range, sortBy, reverse);
				} else {
					logError(`indexedDb::whereObject::Open ${collection} failed querying ${index} for ${JSON.stringify(range)}: ${e.stack}`);
					return [];
				}
			}),

	clear: async (collection: any) => (await indexedDb.getDb())[collection].clear(),

	put: async (collection: any, data: any, key: number = -1) => {
		let response: any;
		if (key >= 0) {
			response = (await indexedDb.getDb())[collection]
				.put(data, key)
				.then(() => data)
				.catch(async (e: any) => {
					if (!indexedDb.inError) {
						indexedDb.inError = true;
						await indexedDb.initialise();
						return await indexedDb.put(collection, data, key);
					} else {
						logError(`Open failed: for ${collection} failed putting ${data} at ${key}: ${JSON.stringify(e)}`);
						return data;
					}
				});
		} else {
			response = (await indexedDb.getDb())[collection]
				.put(data)
				.then(() => data)
				.catch(async (e: any) => {
					if (!indexedDb.inError) {
						indexedDb.inError = true;
						await indexedDb.initialise();
						return await indexedDb.put(collection, data, key);
					} else {
						logError(`indexedDb::put::Open failed: ${e.stack}`);
						return data;
					}
				});
		}

		return response;
	},

	bulkPut: async (collection: any, data: any) => {
		return (await indexedDb.getDb())[collection].bulkPut(data).catch(async (e: any) => {
			if (!indexedDb.inError) {
				indexedDb.inError = true;
				await indexedDb.initialise();
				return await indexedDb.bulkPut(collection, data);
			} else {
				logError(`indexedDb::bulkPut::Open for ${collection} failed putting ${JSON.stringify(data)}: ${e.stack}`);
				return data;
			}
		});
	},

	putWhere: async (collection: any, key: any, value: any, data: any) => {
		return (await indexedDb.getDb())[collection]
			.where(key)
			.equals(value)
			.modify(data)
			.catch(async (e: any) => {
				if (!indexedDb.inError) {
					indexedDb.inError = true;
					await indexedDb.initialise();
					return await indexedDb.putWhere(collection, key, value, data);
				} else {
					logError(`indexedDb::putWhere::Open for ${collection} failed putting ${key} at ${value}: ${e.stack}`);
					return data;
				}
			});
	},

	modifyWhere: async (collection: any, key: any, value: any, data: any) => await indexedDb.putWhere(collection, key, value, data),

	update: async (collection: any, data: any, key: any = -1) => {
		let response: any,
			db = await indexedDb.getDb();

		if (isNaN(key)) {
			response = await db[collection]
				.update(key, data)
				.then((updated: any) => {
					return updated === 1;
				})
				.catch(async (e: any) => {
					if (!indexedDb.inError) {
						indexedDb.inError = true;
						await indexedDb.initialise();
						return await indexedDb.update(collection, data, key);
					} else {
						logError(`indexedDb::update::Open failed: for ${collection} failed updating ${key}: ${e.message}`);
						logError(`indexedDb::update::stack: ${JSON.stringify(e.stack)}`);
						return data;
					}
				});
		} else {
			response = await db[collection]
				.put(data)
				.then(() => data)
				.catch(async (e: any) => {
					if (!indexedDb.inError) {
						indexedDb.inError = true;
						await indexedDb.initialise();
						return await indexedDb.put(collection, data, key);
					} else {
						logError(`indexedDb::update::Open failed: ${e.stack}`);
						return data;
					}
				});
		}

		return response;
	},

	delete: async (collection: any, data: any) =>
		(await indexedDb.getDb())[collection]
			.where(data.deleteBy)
			.equals(data[data.deleteBy])
			.delete()
			.then(() => info(`Deleted from ${collection}: ${JSON.stringify(data)}`))
			.catch(async (e: any) => {
				if (!indexedDb.inError) {
					indexedDb.inError = true;
					await indexedDb.initialise();
					return await indexedDb.delete(collection, data);
				} else {
					logError(`indexedDb::delete::Open failed: ${e.stack}`);
				}
			}),
};

export const DbController = indexedDb;
