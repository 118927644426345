import {
	Login,
	Register,
	VerificationRequired,
	Chat,
	Tabs,
	ManageContact,
	PrivacyPage,
	UserPrivacyPage,
	ChatContacts,
	GroupSetting,
	GroupManager,
	GroupInfo,
	ManageGroup,
	GroupAdmin,
	GroupGuidline,
	Tags,
	TagInfo,
	AddMembers,
	GroupMembers,
	ChatMedias,
	PersonalStorage,
	PersonalFolder,
	ChatOnly,
	EditThread,
	CameraPage,
	uploadPhotoChatOnly,
	ViewAsChatOnly,
	MomentsPage,
	CreatePost,
	AddLocation,
	ShareWith,
	SearchContacts,
	AddContact,
	CreateGroup,
	ProfileEditor,
	Dashboard,
	Contacts,
	Discover,
	ProfilePage,
	SettingsPage,
	UploadPhoto,
	MyQRCode,
	ProfileFriend,
	RecentContacts,
	GeneralsPage,
	DeleteAccountPage,
} from '../pages';
import GroupChats from '../pages/contacts/groupChats/groupChats';
import { ContactItem } from '../types/contact';
import { GroupItem } from '../types/group';

import {
	AddContactsNavParams,
	AddMembersNavParams,
	ChatContactsNavParams,
	ChatNavParams,
	ContactsListNavParams,
	CreatePostNavParams,
	EditChatOnltProfileNavParams,
	EditProfileNavParams,
	EditThreadNavParams,
	GroupInfoNavParams,
	GroupManagerNavParams,
	GroupMembersNavParams,
	HistoryManagementPathData,
	ManageContactsNavParams,
	MyProfileNavParams,
	PersonalFolderNavParams,
	ProfileFriendNavParams,
	ProfileUploadPhotoChatonlyNavParams,
	SearchContactNavParams,
	TagInfoNavParams,
	TagsNavParams,
	UserPrivacyNavParams,
	ViewAsChatOnlyNavParams,
} from '../types/navigation';
import { MyProfile } from '../types/profile';
import { dbService } from './dbService';
import { NavigationRoutes } from './enumService';
import { sharedService } from './sharedService';

export const mainRoutes = [
	{
		path: NavigationRoutes.LOGIN,
		component: Login,
		exact: true,
	},
	{
		path: NavigationRoutes.VERIFICATIONS_REQUIRED,
		component: VerificationRequired,
		exact: true,
	},
	{
		path: NavigationRoutes.SIGNUP,
		component: Register,
		exact: true,
	},
	{
		path: NavigationRoutes.TABS,
		component: Tabs,
	},
	{
		path: `${NavigationRoutes.CHAT}/:userId`,
		component: Chat,
		exact: true,
	},
	{
		path: `${NavigationRoutes.GROUP_CHAT}/:mucId`,
		component: Chat,
		exact: true,
	},
	{
		path: NavigationRoutes.EDIT_THREADS,
		component: EditThread,
		exact: true,
	},
	{
		path: `${NavigationRoutes.PERSONAL_NOTEPAD}/:userId`,
		component: Chat,
		exact: true,
	},
	{
		path: NavigationRoutes.MOMENTS,
		component: MomentsPage,
		exact: true,
	},
	{
		path: NavigationRoutes.CREATE_POST,
		component: CreatePost,
		exact: true,
	},
	{
		path: NavigationRoutes.ADD_LOCATION,
		component: AddLocation,
		exact: true,
	},
	{
		path: NavigationRoutes.SHARE_WITH,
		component: ShareWith,
		exact: true,
	},
	{
		path: NavigationRoutes.EDIT_CHATONLY_PROFILE,
		component: ProfileEditor,
		exact: true,
	},
	{
		path: NavigationRoutes.EDIT_PROFILE,
		component: ProfileEditor,
		exact: true,
	},
	{
		path: NavigationRoutes.PROFILE_UPLOAD_PHOTO_CHATONLY,
		component: uploadPhotoChatOnly,
		exact: true,
	},
	{
		path: NavigationRoutes.VIEWAS_CHATONLY,
		component: ViewAsChatOnly,
		exact: true,
	},
	{
		path: NavigationRoutes.CHAT_CONTACTS,
		component: ChatContacts,
		exact: true,
	},
	{
		path: NavigationRoutes.GROUP_CHATS,
		component: GroupChats,
		exact: true,
	},
	{
		path: NavigationRoutes.SEARCH_CONTACTS,
		component: SearchContacts,
		exact: true,
	},
	{
		path: NavigationRoutes.ADD_CONTACTS,
		component: AddContact,
		exact: true,
	},
	{
		path: NavigationRoutes.MANAGE_CONTACTS,
		component: ManageContact,
		exact: true,
	},
	{
		path: NavigationRoutes.ADD_MEMBERS,
		component: AddMembers,
		exact: true,
	},
	{
		path: NavigationRoutes.CONTACTS_LIST,
		component: ChatOnly,
		exact: true,
	},
	{
		path: NavigationRoutes.TAGS,
		component: Tags,
		exact: true,
	},
	{
		path: NavigationRoutes.TAGS_INFO,
		component: TagInfo,
		exact: true,
	},
	{
		path: NavigationRoutes.CAMERA,
		component: CameraPage,
		exact: true,
	},
	{
		path: NavigationRoutes.PRIVACY,
		component: PrivacyPage,
		exact: true,
	},
	{
		path: NavigationRoutes.NEW_GROUP,
		component: CreateGroup,
		exact: true,
	},
	{
		path: NavigationRoutes.GROUP_MANAGER,
		component: GroupManager,
		exact: true,
	},
	{
		path: NavigationRoutes.GROUP_SETTING,
		component: GroupSetting,
		exact: true,
	},
	{
		path: NavigationRoutes.GROUP_INFO,
		component: GroupInfo,
		exact: true,
	},
	{
		path: NavigationRoutes.MANAGE_GROUP,
		component: ManageGroup,
		exact: true,
	},
	{
		path: NavigationRoutes.GROUP_GUIDELINE,
		component: GroupGuidline,
		exact: true,
	},
	{
		path: NavigationRoutes.GROUP_ADMIN,
		component: GroupAdmin,
		exact: true,
	},
	{
		path: NavigationRoutes.GROUP_MEMBERS,
		component: GroupMembers,
		exact: true,
	},
	{
		path: NavigationRoutes.CHAT_MEDIAS,
		component: ChatMedias,
		exact: true,
	},
	{
		path: NavigationRoutes.PERSONAL_STORAGE,
		component: PersonalStorage,
		exact: true,
	},
	{
		path: NavigationRoutes.PERSONAL_FOLDER,
		component: PersonalFolder,
		exact: true,
	},
	{
		path: NavigationRoutes.USER_PRIVACY,
		component: UserPrivacyPage,
		exact: true,
	},
	{
		path: NavigationRoutes.PROFILE_UPLOAD_PHOTO,
		component: UploadPhoto,
		exact: true,
	},
	{
		path: NavigationRoutes.PROFILE_FRIEND,
		component: ProfileFriend,
		exact: true,
	},
	{
		path: NavigationRoutes.My_PROFILE,
		component: ProfilePage,
		exact: true,
	},
	{
		path: NavigationRoutes.DELETE_ACCOUNT,
		component: DeleteAccountPage,
		exact: true,
	},
];
export const tabsRoutes = [
	{
		path: NavigationRoutes.DASHBOARD,
		component: Dashboard,
		exact: true,
	},
	{
		path: NavigationRoutes.CONTACTS,
		component: Contacts,
		exact: true,
	},
	{
		path: NavigationRoutes.DISCOVER,
		component: Discover,
		exact: true,
	},
	{
		path: NavigationRoutes.PROFILE,
		component: ProfilePage,
		exact: true,
	},
	{
		path: NavigationRoutes.RECENT_CONTACTS,
		component: RecentContacts,
		exact: true,
	},
	{
		path: NavigationRoutes.MY_QRCODE,
		component: MyQRCode,
		exact: true,
	},
	{
		path: NavigationRoutes.SETTINGS,
		component: SettingsPage,
		exact: true,
	},
	{
		path: NavigationRoutes.GENERAL,
		component: GeneralsPage,
		exact: true,
	},
];

let history: Array<HistoryManagementPathData> = [];

export const getCurrentPath = () => {
	return `${window.location.pathname}${window.location.search}`;
};

export var historyManagement = {
	add: async (path: NavigationRoutes | string, data: any = null) => {
		history = await dbService.navigationHistory();

		if (history && history.length > 0) {
			if (history[history.length - 1].path === path) {
				return history;
			} else {
				const historyIndex = history.findIndex((item) => item.path === path);
				if (historyIndex >= 0) {
					history.splice(historyIndex, 1);
				}
			}
		} else {
			history = [];
		}

		history.push({
			path,
			data,
		});

		await dbService.addNavigation(history);
	},

	updateCurrentPathData: async (data?: HistoryManagementPathData) => {
		const currentath = getCurrentPath();
		history = await dbService.navigationHistory();

		const historyIndex = history.findIndex((item) => item.path === currentath);
		if (history && history.length > 0 && historyIndex >= 0) {
			history[historyIndex] = {
				...history[historyIndex],
				...data,
			};
		}
		await dbService.addNavigation(history);
	},

	removeLastPath: async () => {
		history = await dbService.navigationHistory();

		if (history && history.length > 0) {
			history.pop();
		}

		await dbService.addNavigation(history);

		return history;
	},

	clearAll: async () => {
		history = [];
		await dbService.addNavigation(history);
		return history;
	},

	findPathData: async (path: string) => {
		history = await dbService.navigationHistory();

		const pathData = history.find((item) => item.path === path);
		return pathData?.data;
	},
};

export default class NavigationService {
	back = async () => {
		const beforePath = getCurrentPath();
		// sharedService.rootHistory.goBack();
		const list = await historyManagement.removeLastPath();
		// setTimeout(() => {
		const afterPath = getCurrentPath();
		if (beforePath === afterPath) {
			if (list && list.length > 0) {
				sharedService.rootHistory.replace(list[list.length - 1].path);
			}
		}
		// }, 100);
	};

	getNavParamData(props: any) {
		return new Promise(async (resolve) => {
			if (props.history?.location?.state) {
				resolve(props.location.state || props.history.location.state);
			} else {
				const path = getCurrentPath();
				const data = await historyManagement.findPathData(path);
				resolve(data);
			}
		});
	}

	async getSavedStateData() {
		history = await dbService.navigationHistory();
		const path = getCurrentPath();

		return new Promise((resolve) => {
			const pathData = history.find((item) => item.path === path);
			resolve(pathData?.stateData);
		});
	}

	navigate(to: string, data?: any) {
		historyManagement.add(to, data);
		// sharedService.rootHistory.push(to, data);
		sharedService.rootHistory.replace(to, data);
	}

	navigateRoot(to: string) {
		historyManagement.clearAll();
		historyManagement.add(to);
		sharedService.rootHistory.replace(to);
	}

	// Roots tabs

	dashboardTab = () => {
		this.navigateRoot(NavigationRoutes.DASHBOARD);
	};

	contactsTab = () => {
		this.navigateRoot(NavigationRoutes.CONTACTS);
	};

	discoverTab = () => {
		this.navigateRoot(NavigationRoutes.DISCOVER);
	};

	profileTab = () => {
		this.navigateRoot(NavigationRoutes.PROFILE);
	};
	// -- Roots tabs end

	myProfile = (data: MyProfileNavParams) => {
		this.navigate(data.shouldBack ? NavigationRoutes.My_PROFILE : NavigationRoutes.PROFILE, data);
	};

	chat = (data: ChatNavParams) => {
		const contactItem = data.receiver as ContactItem;
		const groupItem = data.receiver as GroupItem;

		if (contactItem.username) {
			this.navigate(`${NavigationRoutes.CHAT}/${contactItem.username}`, data);
		} else if (groupItem.mucName) {
			this.navigate(`${NavigationRoutes.GROUP_CHAT}/${groupItem.name}`, data);
		}
	};

	personalNotepad = (data: ChatNavParams) => {
		const myProfile = data.receiver as MyProfile;

		this.navigate(`${NavigationRoutes.PERSONAL_NOTEPAD}/${myProfile.username}`, data);
	};

	contactRecent = () => {
		this.navigate(NavigationRoutes.RECENT_CONTACTS);
	};

	profileFriend = (data: ProfileFriendNavParams) => {
		this.navigate(`${NavigationRoutes.PROFILE_FRIEND}?username=${data.data?.username}`, data);
	};

	openContactsList = (data?: ContactsListNavParams) => {
		this.navigate(NavigationRoutes.CONTACTS_LIST, data);
	};

	openPrivacy = () => {
		this.navigate(NavigationRoutes.PRIVACY);
	};

	openSearchContacts = (data?: SearchContactNavParams) => {
		this.navigate(NavigationRoutes.SEARCH_CONTACTS, data);
	};

	openEditProfile = (data?: EditProfileNavParams) => {
		this.navigate(NavigationRoutes.EDIT_PROFILE, data);
	};

	openEditChatOnlyProfile = (data?: EditChatOnltProfileNavParams) => {
		this.navigate(NavigationRoutes.EDIT_CHATONLY_PROFILE, data);
	};

	openAddMembers = (data: AddMembersNavParams) => {
		this.navigate(NavigationRoutes.ADD_MEMBERS, data);
	};

	openChatMedias = () => {
		this.navigate(NavigationRoutes.CHAT_MEDIAS);
	};

	openPersonalStorage = () => {
		this.navigate(NavigationRoutes.PERSONAL_STORAGE);
	};

	openPersonalFolder = (data: PersonalFolderNavParams) => {
		this.navigate(NavigationRoutes.PERSONAL_FOLDER, data);
	};

	openSetting = () => {
		this.navigate(NavigationRoutes.SETTINGS);
	};

	openManageGroup = () => {
		this.navigate(NavigationRoutes.MANAGE_GROUP);
	};

	openGroupGuideline = () => {
		this.navigate(NavigationRoutes.GROUP_GUIDELINE);
	};

	openTags = (data?: TagsNavParams) => {
		this.navigate(NavigationRoutes.TAGS, data);
	};

	openGroupChats = (data?: TagsNavParams) => {
		this.navigate(NavigationRoutes.GROUP_CHATS, data);
	};

	openGroupInfo = (data?: GroupInfoNavParams) => {
		this.navigate(NavigationRoutes.GROUP_INFO, data);
	};

	openGroupAdmin = () => {
		this.navigate(NavigationRoutes.GROUP_ADMIN);
	};

	openDeleteAccount = () => {
		this.navigate(NavigationRoutes.DELETE_ACCOUNT);
	};

	openGeneral = () => {
		this.navigate(NavigationRoutes.GENERAL);
	};

	openGroupMembers = (data: GroupMembersNavParams) => {
		this.navigate(NavigationRoutes.GROUP_MEMBERS, data);
	};

	openMoments = () => {
		this.navigate(NavigationRoutes.MOMENTS);
	};

	openProfileUploadPhoto = () => {
		this.navigate(NavigationRoutes.PROFILE_UPLOAD_PHOTO);
	};

	openProfileUploadPhotoChatOnly = (data?: ProfileUploadPhotoChatonlyNavParams) => {
		this.navigate(NavigationRoutes.PROFILE_UPLOAD_PHOTO_CHATONLY, data);
	};

	openManageContacts = (data?: ManageContactsNavParams) => {
		this.navigate(NavigationRoutes.MANAGE_CONTACTS, data);
	};

	openUserPrivacy = (data: UserPrivacyNavParams) => {
		this.navigate(NavigationRoutes.USER_PRIVACY, data);
	};

	openGroupManager = (data: GroupManagerNavParams) => {
		this.navigate(NavigationRoutes.GROUP_MANAGER, data);
	};

	openChatContacts = (data: ChatContactsNavParams) => {
		this.navigate(NavigationRoutes.CHAT_CONTACTS, data);
	};

	openEditThreads = (data: EditThreadNavParams) => {
		this.navigate(NavigationRoutes.EDIT_THREADS, data);
	};

	addContacts = (data: AddContactsNavParams) => {
		this.navigate(`${NavigationRoutes.ADD_CONTACTS}?username=${data.data.username}&actionType=${data.friendProfileActionType}`, data);
	};

	openMyQRCode = () => {
		this.navigate(NavigationRoutes.MY_QRCODE);
	};

	openTagInfo = (data?: TagInfoNavParams) => {
		this.navigate(NavigationRoutes.TAGS_INFO, data);
	};

	openCreatePost = (data?: CreatePostNavParams) => {
		this.navigate(NavigationRoutes.CREATE_POST, data);
	};

	openAddLocation = () => {
		this.navigate(NavigationRoutes.ADD_LOCATION);
	};

	openShareWith = () => {
		this.navigate(NavigationRoutes.SHARE_WITH);
	};

	openViewChatOnly = (data: ViewAsChatOnlyNavParams) => {
		this.navigate(NavigationRoutes.VIEWAS_CHATONLY, data);
	};
}

export const Navigation = new NavigationService();
