import { IonCol, IonContent, IonIcon, IonInput, IonList, IonPage, IonRow, IonNav, IonLabel, IonItem, IonCheckbox, IonToolbar, IonButtons, IonButton, IonModal } from '@ionic/react';
import * as React from 'react';
import './style.scss';
import { NavContext } from '@ionic/react';
import { registerUserName, register, resetValues } from '../../redux/actions/register';
import { connect } from 'react-redux';
import { Redirect } from 'react-router-dom';
import CustomSpinner from '../../components/CustomSpinner';
import { locale } from '../../locales/local';
import CameraService from '../../services/cameraService';
import ImageEditor, { OpenEditorType } from '../../modals/ImageEditor/ImageEditor';
import { getInternalStorage } from '../../helpers/common';
import store from '../../redux/store';
import { SHOW_TOAST } from '../../redux/constants/common';
import { RegisterUsernameRequestData, CreateAccountRequestData, CreateAccountUser } from '../../types/api-requests';
import SharedService from '../../services/sharedService';
import { LocalStorageKeys, NavigationRoutes } from '../../services/enumService';

const BackIcon = './../../assets/icon/back.svg';
const ErrorIcon = './../../assets/icon/error.svg';
const SignupProfilePhoto = './../../assets/img/signup-profile-photo.svg';
const CameraIcon = './../../assets/img/camera-icon.svg';
const AlbumIcon = './../../assets/img/album-icon.svg';

interface iProps {
	history: any;
	register: any;
	title: String;
	hasErrors: boolean;
	showLoading: boolean;
	errorMessage: String;
	registrationSuccess: boolean;
	userIdAvailable: boolean;
	pubKey: String;
	privKey: String;
	registerUserName: (data: RegisterUsernameRequestData) => Promise<unknown>;
	registerHandler: (data: CreateAccountRequestData) => Promise<unknown>;
	resetValues: Function;
}

interface iState {
	step: number;
	hasErrors: boolean;
	hasApiErrors: boolean;
	errorMessage: string;
	firstname: string;
	lastname: string;
	email: string;
	password: string;
	cpassword: string;
	displayName: string;
	userid: string;
	isUsernameFocussed: boolean;
	showPassword: boolean;
	selectedImage: any;
	profilePhoto: any;
	showPinturaEditor: boolean;
	usernameReserveCode: string;
}

const cameraService = new CameraService();

class RegisterComponent extends React.Component<iProps, iState> {
	useridInputRef: any = React.createRef();
	usernameInputRef: any = React.createRef();
	passwordInputRef: any = React.createRef();
	confirmPasswordInputRef: any = React.createRef();
	static contextType = NavContext;
	nav = IonNav;
	isRegisterSubmitted = false;

	constructor(props: iProps) {
		super(props);
		this.registerUser = this.registerUser.bind(this);
		this.navigateTo = this.navigateTo.bind(this);
		this.validateInput = this.validateInput.bind(this);
		this.proceedToNextStep = this.proceedToNextStep.bind(this);
		this.getLayout = this.getLayout.bind(this);
		this.state = {
			errorMessage: '',
			hasErrors: false,
			hasApiErrors: false,
			step: 1,
			email: '',
			cpassword: '',
			firstname: '',
			lastname: '',
			password: '',
			displayName: '',
			userid: '',
			selectedImage: null,
			profilePhoto: null,
			showPinturaEditor: false,
			isUsernameFocussed: false,
			showPassword: false,
			usernameReserveCode: '',
		};

		const verficationStatus = localStorage.getItem(LocalStorageKeys.Verfication);
		if (verficationStatus !== 'verified') {
			this.props.history.replace('/');
		}
	}

	navigateTo() {
		this.context.navigate('/home', 'back');
	}

	proceedToNextStep = async (step: number) => {
		const activeElement: any = document.activeElement;
		if (activeElement) {
			activeElement.blur();
		}

		if (this.validateInput(this.state.step)) {
			if (step === 1) {
				const payload: any = {
					username: this.state.userid.toLowerCase(),
				};
				this.props
					.registerUserName(payload)
					.then(() => {
						if (this.validateInput(this.state.step)) {
							this.setState({ step: this.state.step + 1 });
							setTimeout(() => {
								this.usernameInputRef?.current?.setFocus(true);
							}, 300);
						}
						this.isRegisterSubmitted = false;
					})
					.catch((error: any) => {
						this.setState({ hasApiErrors: true, errorMessage: error.message });
					});
			} else if (step === 4) {
				this.registerUser();
			} else {
				if (this.state.step === 2) {
					setTimeout(() => {
						this.passwordInputRef?.current?.setFocus(true);
					}, 300);
				}
				this.setState({ step: this.state.step + 1 });
			}
		}
	};

	componentWillUnmount() {
		window.removeEventListener('resize', this.onResize);
	}

	onResize = () => {
		const element = document.getElementById('profilePhoto');
		const creatingAccountLoader = document.getElementById('creatingAccountLoader');

		if (element && creatingAccountLoader) {
			const rect = element.getBoundingClientRect();
			const imgTop = rect.top + document.body.scrollTop,
				imgWidth = rect.width,
				imgHeight = rect.height,
				imgLeft = rect.left,
				imgRight = rect.right;
			creatingAccountLoader.style.top = imgTop + 'px';
			creatingAccountLoader.style.width = imgWidth + 'px';
			creatingAccountLoader.style.height = imgHeight + 'px';
			creatingAccountLoader.style.left = imgLeft + 'px';
			creatingAccountLoader.style.right = imgRight + 'px';
		}
	};

	componentDidMount() {
		window.addEventListener('resize', this.onResize);
	}

	registerUser() {
		if (this.validateInput(this.state.step) && !this.isRegisterSubmitted) {
			this.isRegisterSubmitted = true;
			let reserveduname = getInternalStorage()[LocalStorageKeys.ReservedUname];
			if (reserveduname) {
				reserveduname = JSON.parse(reserveduname);
				const { userid, displayName, password } = this.state;
				let payload: CreateAccountUser = {
					uuid: reserveduname.result,
					username: userid.toLowerCase(),
					displayName: displayName,
					password: password,
					profilePhoto: '',
				};

				if (this.state.profilePhoto) {
					payload.profilePhoto = SharedService.fixBase64String(this.state.profilePhoto);
				}

				this.props
					.registerHandler({ user: payload })
					.then(() => {
						this.isRegisterSubmitted = false;
						this.props.history.replace(NavigationRoutes.TABS);
					})
					.catch((error) => {
						this.isRegisterSubmitted = false;
						this.setState({ hasApiErrors: true, errorMessage: error.message });
						this.props.resetValues();
					});
			} else {
				this.isRegisterSubmitted = false;
				store.dispatch({
					type: SHOW_TOAST,
					payload: {
						showToast: true,
						toastMessage: 'Please enter username first',
					},
				});
			}
		}
	}

	validateInput(step: number) {
		const { displayName, userid, password, cpassword } = this.state;
		switch (step) {
			case 1:
				if (!userid) {
					this.setState({
						hasErrors: true,
						errorMessage: locale.register.error.userid,
					});
					return false;
				}
				break;
			case 2:
				if (!displayName) {
					this.setState({
						hasErrors: true,
						errorMessage: locale.register.error.username,
					});
					return false;
				}
				break;
			case 3:
				if (password !== cpassword) {
					this.setState({
						hasErrors: true,
						errorMessage: locale.register.error.password,
					});
					return false;
				}
				break;
		}
		return true;
	}

	moveToNextField = (event: any, nextFieldRef: any, callBack: any = null) => {
		if (event.key === 'Enter' || event.key === 'Tab') {
			event.preventDefault && event.preventDefault();
			if (callBack) {
				callBack();
			}
			if (nextFieldRef && nextFieldRef.current) {
				nextFieldRef?.current.setFocus(true);
			}
		}
	};

	onPinturaEditingDone = (file: any) => {
		// encode the file using the FileReader API
		const reader = new FileReader();
		reader.onloadend = async () =>
			this.setState(
				{
					showPinturaEditor: false,
					hasApiErrors: false,
					hasErrors: false,
					selectedImage: null,
					profilePhoto: reader.result,
				},
				() => {}
			);
		reader.readAsDataURL(file);
	};

	_renderFieldWarning(title: String, onClick: any = null) {
		return (
			<IonRow className="input-helper-row" onClick={onClick}>
				<IonCol>{title}</IonCol>
			</IonRow>
		);
	}

	_renderStepUserId = () => {
		return (
			<>
				<div className="step-heading">
					<h1>{locale.register.createUserId}</h1>
					<IonLabel>
						{locale.register.hints.userId}
						<span className="heighlight">{locale.register.hints.userIdSpan}</span>
					</IonLabel>
				</div>

				<IonItem className="auth-input-item top-space" lines="none">
					<MyInput
						required
						value={String(this.state.userid)}
						type="text"
						placeholder="User ID"
						onIonChange={(e: any) =>
							this.setState({
								hasErrors: false,
								hasApiErrors: false,
								userid: e.detail.value!,
							})
						}
						clearInput
						enterkeyhint={'next'}
						onKeyUp={(event: any) => {
							this.moveToNextField(event, null, () => {
								this.proceedToNextStep(this.state.step);
							});
						}}
						onInputRef={(ref: any) => (this.useridInputRef = ref)}
					></MyInput>
					<IonLabel slot="end">@{locale.global.app_name}</IonLabel>
				</IonItem>
				{this._renderFieldWarning(locale.register.hints.lettersNumbersPeriods)}

				<p className="register-description">
					{locale.register.info.userId}
					<span className="heighlight">{locale.register.info.privacyPolicy}</span>
					{locale.register.info.userId2}
					<span className="heighlight">{locale.register.info.termsOfService}</span>.
				</p>
			</>
		);
	};

	_renderStepUsername = () => {
		return (
			<>
				<div className="step-heading">
					<h1>{locale.register.createUsername}</h1>
					<IonLabel>{locale.register.hints.username}</IonLabel>
				</div>
				<IonItem className="auth-input-item top-space" lines="none">
					<MyInput
						required
						value={String(this.state.displayName)}
						placeholder={this.state.isUsernameFocussed ? locale.register.placeholder.usernameIsFocussed : locale.register.placeholder.username}
						onIonChange={(e: any) =>
							this.setState({
								hasErrors: false,
								hasApiErrors: false,
								displayName: e.detail.value!,
							})
						}
						clearInput
						onFocus={() => this.setState({ isUsernameFocussed: true })}
						onBlur={() => this.setState({ isUsernameFocussed: false })}
						onKeyUp={(event: any) => {
							this.moveToNextField(event, null, () => {
								this.proceedToNextStep(this.state.step);
							});
						}}
						onInputRef={(ref: any) => (this.usernameInputRef = ref)}
					></MyInput>
				</IonItem>

				{this._renderFieldWarning(locale.register.hints.lessIsMore)}

				<p className="besociety-description">{locale.register.info.username}</p>
			</>
		);
	};

	_renderStepCreatePasswordLayout = () => {
		return (
			<>
				<div className="step-heading">
					<h1>{locale.register.createPassword}</h1>
				</div>

				<IonItem className="auth-input-item" style={{ marginTop: 90 }} lines="none">
					<MyInput
						required
						value={String(this.state.password)}
						placeholder={locale.register.placeholder.password}
						type={this.state.showPassword ? 'text' : 'password'}
						onIonChange={(e: any) =>
							this.setState({
								hasErrors: false,
								hasApiErrors: false,
								password: e.detail.value!,
							})
						}
						clearInput
						onKeyUp={(event: any) => {
							this.moveToNextField(event, this.confirmPasswordInputRef);
						}}
						onInputRef={(ref: any) => (this.passwordInputRef = ref)}
					></MyInput>
				</IonItem>
				{this._renderFieldWarning(locale.register.hints.password)}

				<IonItem className="auth-input-item" lines="none">
					<MyInput
						required
						value={String(this.state.cpassword)}
						placeholder="Confirm"
						type={this.state.showPassword ? 'text' : 'password'}
						onIonChange={(e: any) =>
							this.setState({
								hasErrors: false,
								hasApiErrors: false,
								cpassword: e.detail.value!,
							})
						}
						clearInput
						enterkeyhint={'done'}
						onKeyUp={(event: any) => {
							this.moveToNextField(event, null, () => {
								this.proceedToNextStep(this.state.step);
							});
						}}
						onInputRef={(ref: any) => (this.confirmPasswordInputRef = ref)}
					></MyInput>
				</IonItem>

				<IonItem lines="none" className="show-password-checkbox-item">
					<IonCheckbox mode="ios" id="personalDevice" slot="start" value="off" checked={this.state.showPassword} onIonChange={(event) => this.setState({ showPassword: event.detail.checked })}></IonCheckbox>
					<IonLabel>Show Password</IonLabel>
				</IonItem>
			</>
		);
	};

	_renderStepProfilePhoto = () => {
		return (
			<>
				<div className="profile-photo-view">
					<div className="step-heading">
						<h1>{locale.register.addProfilePhoto}</h1>
						<IonLabel>{locale.register.hints.profilePhoto}</IonLabel>

						<div className="username-id-view">
							<IonLabel className="userid-txt">{this.state.displayName}</IonLabel>
							<IonLabel className="username-txt">@{this.state.userid}</IonLabel>
						</div>
						<img id="profilePhoto" className="profile-photo" src={this.state.profilePhoto ? this.state.profilePhoto : SignupProfilePhoto} alt="profilePhoto" />

						<div className="photo-btns-container">
							<IonButton
								fill="clear"
								onClick={() => {
									cameraService.capturePhotoFromCamera((photo: any) => {
										this.setState({
											showPinturaEditor: true,
											selectedImage: photo,
										});
									}, true);
								}}
							>
								<img src={CameraIcon} alt="cameraIcon" />
							</IonButton>
							<IonButton
								fill="clear"
								onClick={() => {
									cameraService.choosePhotoFromAlbum((photo: Array<File>) => {
										this.setState({
											showPinturaEditor: true,
											selectedImage: photo[0],
										});
									});
								}}
							>
								<img src={AlbumIcon} alt="albumIcon" />
							</IonButton>
						</div>
					</div>
				</div>

				<ImageEditor
					openEditorType={OpenEditorType.FOR_PROFILE_GROUP_PHOTO}
					selectedImage={this.state.selectedImage}
					show={this.state.showPinturaEditor}
					onClose={() => {
						this.setState({ showPinturaEditor: false });
					}}
					imageOptions={{ targetWidth: 512, targetHeight: 512, quality: 0.5 }}
					onSave={this.onPinturaEditingDone}
				/>
			</>
		);
	};

	_renderSignupButton = () => {
		let isDisabled: boolean = false;

		switch (this.state.step) {
			case 1:
				isDisabled = !this.state.userid;
				break;
			case 2:
				isDisabled = !this.state.displayName;
				break;
			case 3:
				isDisabled = !this.state.password || !this.state.cpassword;
				break;
			case 4:
				isDisabled = false;
				break;
			default:
				break;
		}

		return (
			<>
				<IonRow style={{ marginTop: '25px', marginBottom: '50px' }}>
					<IonCol className="auth-button-col">
						<IonButton
							disabled={this.state.hasErrors || isDisabled}
							className="auth-button"
							color="#000000"
							type="submit"
							fill="solid"
							onClick={(event) => {
								this.proceedToNextStep(this.state.step);
							}}
						>
							{this.state.step === 4 ? 'Create Account' : this.state.step === 1 ? 'Register' : 'Next'}
						</IonButton>
					</IonCol>
				</IonRow>
			</>
		);
	};

	_renderErrorsView = () => {
		const { hasErrors, hasApiErrors, errorMessage } = this.state;
		return (
			<div
				style={{
					flex: 1,
					display: 'flex',
					alignItems: 'center',
					justifyContent: 'center',
				}}
			>
				{(hasErrors || hasApiErrors) && (
					<div className="signup-error-view">
						<IonIcon src={ErrorIcon} />
						<IonLabel>{errorMessage}</IonLabel>
					</div>
				)}
			</div>
		);
	};

	getLayout() {
		let returnValue: any;
		switch (this.state.step) {
			case 1:
				returnValue = this._renderStepUserId();
				break;
			case 2:
				returnValue = this._renderStepUsername();
				break;
			case 3:
				returnValue = this._renderStepCreatePasswordLayout();
				break;
			case 4:
				returnValue = this._renderStepProfilePhoto();
				break;
			default:
				returnValue = this._renderStepUserId();
				break;
		}
		return (
			<div className="formContainer">
				<IonList className="input-item-list">
					{/* {this.state.hasErrors ? (
												<>
													<IonRow>
														<IonCol>
															<p className="warnings">{this.state.errorMessage}</p>
														</IonCol>
													</IonRow>
												</>
											) : null} */}
					{returnValue}
				</IonList>
			</div>
		);
	}

	_renderSpinnerView = () => {
		let imgTop: number = 0,
			imgWidth: number = 0,
			imgHeight: number = 0,
			imgLeft: number = 0,
			imgRight: number = 0;

		const element = document.getElementById('profilePhoto');

		if (element) {
			const rect = element.getBoundingClientRect();
			imgTop = rect.top + document.body.scrollTop;
			imgWidth = rect.width;
			imgHeight = rect.height;
			imgLeft = rect.left;
			imgRight = rect.right;
		}

		return (
			<IonModal animated={false} isOpen={true} showBackdrop={false} cssClass="creating-account-modal">
				<div className="screen-loader-container">
					<div
						className="creating-account-loader"
						id="creatingAccountLoader"
						style={{
							top: imgTop + 'px',
							width: imgWidth + 'px',
							height: imgHeight + 'px',
							left: imgLeft,
							right: imgRight,
						}}
					>
						<div className="title">{locale.global.app_name}</div>
						<div className="description">{locale.global.tagline}</div>
						<div
							style={{
								flex: 1,
								display: 'flex',
								alignItems: 'center',
								justifyContent: 'center',
							}}
						>
							<CustomSpinner size={80} />
						</div>
						<IonLabel className="loading-text">Creating Account...</IonLabel>
					</div>
				</div>
			</IonModal>
		);
	};

	_renderSignupHeaderToolbar = () => {
		const { step } = this.state;
		return (
			<IonToolbar className="header-toolbar">
				<IonButtons slot="start">
					<IonButton
						slot="start"
						onClick={() => {
							if (step === 1) this.props.history.goBack();
							else if (step > 1) this.setState({ step: step - 1 });
						}}
					>
						<IonIcon style={{ width: '22px', height: '18px' }} slot="icon-only" src={BackIcon} />
					</IonButton>
				</IonButtons>
			</IonToolbar>
		);
	};

	render() {
		const verficationStatus = localStorage.getItem(LocalStorageKeys.Verfication);

		return !verficationStatus ? null : (
			<>
				{this.props.register.isLoggedIn ? (
					<Redirect to="/auth" />
				) : (
					<IonPage className="auth-page">
						{this._renderSignupHeaderToolbar()}

						<IonContent className="auth-wrapper" fullscreen>
							<div className="inner-content">
								<div
									style={{
										opacity: !this.props.register.creatingAccount && this.props.register.showLoading ? 0 : 1,
										flex: 1,
										display: 'flex',
										flexDirection: 'column',
									}}
								>
									{this.getLayout()}

									{this._renderErrorsView()}

									{this._renderSignupButton()}
								</div>

								{!this.props.register.creatingAccount && this.props.register.showLoading && (
									<div className="screen-loader-container">
										<CustomSpinner size={124} />
										<IonLabel>{this.props.register.loadingText}</IonLabel>
									</div>
								)}

								{this.props.register.creatingAccount && this._renderSpinnerView()}

								<br />
							</div>
						</IonContent>
					</IonPage>
				)}
			</>
		);
	}
}

const mapStateToProps = (state: any) => ({ register: state.register });

const mapDispatchToProps = (dispatch: any) => ({
	registerHandler: (payload: CreateAccountRequestData) => dispatch(register(payload)),
	registerUserName: (payload: RegisterUsernameRequestData) => dispatch(registerUserName(payload)),
	resetValues: () => dispatch(resetValues()),
});

export default connect(mapStateToProps, mapDispatchToProps)(RegisterComponent);

const MyInput: React.FC<any> = ({ onInputRef, ...props }) => {
	const inputRef: React.RefObject<HTMLIonInputElement> = React.useRef(null);
	React.useEffect(() => {
		onInputRef && onInputRef(inputRef);
	}, [inputRef, onInputRef]);
	return <IonInput {...props} ref={inputRef} />;
};
