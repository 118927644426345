import { IonActionSheet, IonButton, IonImg, IonModal, IonThumbnail } from '@ionic/react';
import React, { useState } from 'react';
import './style.scss';
import { DefaultProfilePic } from '../helpers/common';
import SharedService from '../services/sharedService';
import { ProfilePhotoItem } from '../types/profile';

interface DeleteContactProps {
	show: boolean;
	onCloseCallBack: () => void;
	type: string;
	userData: any;
	onDeleteCallBack: () => void;
}

const DeleteContact: React.FC<DeleteContactProps> = ({ show, onCloseCallBack, type, userData, onDeleteCallBack }) => {
	const [showClearAll, setShowClearAll] = useState<boolean>(false);

	const activeProfilePhoto: ProfilePhotoItem = SharedService.extractProfilePhoto(userData?.profilePhotos!)!;

	return (
		<>
			<IonModal showBackdrop={true} isOpen={show} onDidDismiss={onCloseCallBack} swipeToClose={true} cssClass="delete-file-modal" animated={true}>
				<div className="content">
					{userData === 'threadMessage' ? <div className="message-title">Text, Images, Video, and Files</div> : userData === 'deleteMessage' ? '' : <div className="storage">Delete Contact?</div>}
					{userData === 'threadMessage' || userData === 'deleteMessage' ? (
						''
					) : (
						<div className="image">
							<IonThumbnail className="default-image-container">
								<IonImg title="Change" src={activeProfilePhoto?.url || DefaultProfilePic} className="default-image" />
							</IonThumbnail>
						</div>
					)}

					{userData === 'threadMessage' ? (
						<>
							<div
								className="clear-msg"
								onClick={() => {
									setShowClearAll(true);
									onCloseCallBack();
								}}
							>
								Clear All Messages
							</div>
							<div className="message-size">130.2 MB</div>
						</>
					) : (
						<>
							<div className="user-name"> {userData?.profileName}</div>
							<div className="storege-msg">{userData === 'deleteMessage' ? 'Really want to delete this message?' : 'Delete contact and all associated chats.'}</div>
							<div className="move-trash">
								<IonButton onClick={onDeleteCallBack}>Delete</IonButton>
							</div>
						</>
					)}

					<div className="cancel" onClick={onCloseCallBack}>
						<p> Cancel</p>
					</div>
				</div>
			</IonModal>
			<IonActionSheet
				mode="ios"
				isOpen={showClearAll}
				onDidDismiss={() => setShowClearAll(false)}
				cssClass="manage-contact-action-sheet"
				header="Clearing ll Text, Media and Files from chat. Unable to recover once cleared."
				buttons={[
					// {
					// 	text: ',
					// 	role: 'destructive',
					// 	handler: () => {},
					// },
					// {
					// 	text: 'Clearing all Text, Media and Files from chat. Unable to recover once cleared.',
					// 	role: 'structive',
					// 	handler: () => {},
					// },

					{
						text: 'Clear All',
						role: 'structive',
					},
					// {
					// 	text: 'Clear All ',
					// 	role: 'destructive',
					// },

					{
						text: 'Cancel',
						handler: () => {},
					},
				]}
			></IonActionSheet>
		</>
	);
};

export default DeleteContact;
