import { apiService } from '../../services/apiService';
import { SHOW_LOADER, USER_LOGIN, USER_PROFILE_RESET_VALUES, USER_PROFILE_SHOW_LOADER } from '../constants/common';
import {
	PROFILE_FETCH_START,
	PROFILE_FETCH_SUCCESS,
	PROFILE_FETCH_FAILED,
	PROFILE_UPDATE_ERROR,
	PROFILE_UPDATE_FAILED,
	PROFILE_UPDATE_SUCCESS,
	PROFILE_UPDATING,
	TAG_FETCH_START,
	TAG_FETCH_FAILED,
	TAG_FETCH_SUCCESS,
	DELETE_TAG_START,
	DELETE_TAG_SUCCESS,
	DELETE_TAG_FAILED,
	SET_TAGS_VISIBILITY_FAILED,
	SET_TAGS_VISIBILITY_SUCCESS,
	SET_TAGS_VISIBILITY_START,
	ADD_CONTACTS_TO_TAG_SUCCESS,
	ADD_CONTACTS_TO_TAG_FAILED,
	ADD_CONTACTS_TO_TAG_START,
	REMMOVE_CONTACTS_TO_TAG_START,
	REMMOVE_CONTACTS_TO_TAG_SUCCESS,
	REMMOVE_CONTACTS_TO_TAG_FAILED,
	CHATONLY_PROFILE_UPDATING,
	CHATONLY_PROFILE_UPDATE_SUCCESS,
	CHATONLY_PROFILE_UPDATE_FAILED,
} from '../constants/profile';
import { compressSelectedFile, getInternalStorage } from '../../helpers/common';
import { MyChatOnlyProfile, MyProfile, UserProfile } from '../../types/profile';
import {
	ActivateProfilePhotoRequestData,
	AddProfilePhotoRequestData,
	AddUserToTagRequestData,
	DeleteTagRequestData,
	GetProfileByTokenRequestData,
	GetProfileByUsernameRequestData,
	HideProfilePhotoRequestData,
	RemoveProfilePhotoRequestData,
	RemoveUserFromTagRequestData,
	SetTagVisibilityRequestData,
	UnHideProfilePhotoRequestData,
	UpdateChatOnlyProfileRequestData,
	UpdateDotsData,
	UpdateProfileRequestData,
} from '../../types/api-requests';
import { GlobalStateTypes } from '../../types/redux-states';
import { ApiResponse } from '../../types/api-responses';
import { DbController } from '../../services/DbController';
import { IndexDbCollections } from '../../services/DbController';
import { dbService } from '../../services/dbService';
import { hideLoader, showLoader, showToast } from './global';
import { getRosterItems } from './contact';

export function resetValues() {
	return { type: USER_PROFILE_RESET_VALUES };
}

export function pageLoading(payload: any) {
	return { type: USER_PROFILE_SHOW_LOADER, payload: payload };
}

export function getMyProfile() {
	return async function (dispatch: any) {
		dispatch({
			type: PROFILE_FETCH_START,
			payload: {},
		});

		return new Promise(async (resolve, reject) => {
			await apiService
				.getUserProfile()
				.then(async (response: any) => {
					if (response.success) {
						const result = response.result;
						dispatch({ type: PROFILE_FETCH_SUCCESS, payload: result });
						if (!(await apiService.updateUser(result))) {
							await apiService.addUser(result);
						}
						resolve(result);
					} else {
						dispatch({ type: PROFILE_FETCH_FAILED, payload: response });
						reject(response);
					}
				})
				.catch((error: any) => {
					dispatch({ type: PROFILE_FETCH_FAILED, payload: error });
					reject(error);
				});
		});
	};
}

export function getMyChatOnlyProfile(userId: string) {
	return async function (dispatch: any) {
		return new Promise(async (resolve, reject) => {
			await apiService
				.getUserProfile({ userId })
				.then(async (response: ApiResponse) => {
					if (response.success) {
						const result: MyChatOnlyProfile = response.result;

						await apiService.updateChatOnlyUser(result);

						dispatch({
							type: CHATONLY_PROFILE_UPDATE_SUCCESS,
							payload: result,
						});

						resolve(result);
					} else {
						reject(response.message);
					}
				})
				.catch((error: any) => {
					reject(error);
				});
		});
	};
}

export function getMyTags() {
	return async function (dispatch: any) {
		dispatch({ type: TAG_FETCH_START });

		return new Promise(async (resolve, reject) => {
			await apiService
				.getTags()
				.then(async (response: ApiResponse) => {
					if (response.success) {
						const result = response.result;
						const { tags, hideAll } = result;

						const updateData = {
							hideAll,
							[IndexDbCollections.DATA.TAGS]: tags,
							type: IndexDbCollections.DATA.TAGS,
						};
						try {
							const updateResTag = await DbController.update(IndexDbCollections.DATA.name, updateData, IndexDbCollections.DATA.TAGS);
							if (!updateResTag) {
								DbController.put(IndexDbCollections.DATA.name, updateData);
							}
						} catch (error) {}

						dispatch({ type: SET_TAGS_VISIBILITY_SUCCESS, payload: hideAll });
						dispatch({ type: TAG_FETCH_SUCCESS, payload: tags });

						resolve(tags);
					} else {
						dispatch({ type: TAG_FETCH_FAILED, payload: response.message });
						reject(response.message);
					}
				})
				.catch((error: any) => {
					dispatch({
						type: TAG_FETCH_FAILED,
						payload: error.message || error.error,
					});
					reject(error.message || error.error);
				});
		});
	};
}

export function deleteTag(data: DeleteTagRequestData) {
	return async function (dispatch: any) {
		dispatch({ type: DELETE_TAG_START });
		showLoader({ loaderMessage: 'Deleting...' });

		return new Promise(async (resolve, reject) => {
			await apiService
				.deleteTag(data)
				.then(async (response: ApiResponse) => {
					if (response.success) {
						const result = response.result;

						await dispatch(getMyTags());

						dispatch({ type: DELETE_TAG_SUCCESS, payload: result });
						resolve(result);
					} else {
						dispatch({ type: DELETE_TAG_FAILED, payload: response.message });
						reject(response.message);
					}
				})
				.catch((error: any) => {
					dispatch({
						type: DELETE_TAG_FAILED,
						payload: error.message || error.error,
					});
					reject(error.message || error.error);
				})
				.finally(() => {
					hideLoader();
				});
		});
	};
}

export function setTagsVisibility(data: SetTagVisibilityRequestData) {
	return async function (dispatch: any) {
		dispatch({ type: SET_TAGS_VISIBILITY_START });
		showLoader({ loaderMessage: 'Updating...' });

		return new Promise(async (resolve, reject) => {
			await apiService
				.setTagsVisibility(data)
				.then(async (response: ApiResponse) => {
					if (response.success) {
						const result = response.result;

						const { tags, hideAll } = result;
						const updateData = {
							hideAll,
							[IndexDbCollections.DATA.TAGS]: tags,
							type: IndexDbCollections.DATA.TAGS,
						};
						try {
							const updateResTag = await DbController.update(IndexDbCollections.DATA.name, updateData, IndexDbCollections.DATA.TAGS);
							if (!updateResTag) {
								DbController.put(IndexDbCollections.DATA.name, updateData);
							}
						} catch (error) {}

						dispatch({ type: SET_TAGS_VISIBILITY_SUCCESS, payload: hideAll });
						dispatch({ type: TAG_FETCH_SUCCESS, payload: tags });

						resolve(tags);
					} else {
						dispatch({
							type: SET_TAGS_VISIBILITY_FAILED,
							payload: response.message,
						});
						reject(response.message);
					}
				})
				.catch((error: any) => {
					dispatch({
						type: SET_TAGS_VISIBILITY_FAILED,
						payload: error.message || error.error,
					});
					reject(error.message || error.error);
				})
				.finally(() => {
					hideLoader();
				});
		});
	};
}

export function addUserToTag(data: AddUserToTagRequestData) {
	return async function (dispatch: any) {
		dispatch({ type: ADD_CONTACTS_TO_TAG_START });

		showLoader({ loaderMessage: 'Saving Tag...' });

		return new Promise(async (resolve, reject) => {
			await apiService
				.addUserToTag(data)
				.then(async (response: ApiResponse) => {
					if (response.success) {
						const result = response.result;
						// Refresh contacts and tags for new tags or contact added to tags
						await dispatch(getMyTags());
						await dispatch(getRosterItems());
						dispatch({ type: ADD_CONTACTS_TO_TAG_SUCCESS, payload: result });
						resolve(result);
					} else {
						dispatch({
							type: ADD_CONTACTS_TO_TAG_FAILED,
							payload: response.message,
						});
						dispatch(showToast({ toastMessage: response.message }));
						reject(response.message);
					}
				})
				.catch((error: any) => {
					dispatch({
						type: ADD_CONTACTS_TO_TAG_FAILED,
						payload: error.message || error.error,
					});
					dispatch(showToast({ toastMessage: error.message || error.error }));
					reject(error.message || error.error);
				})
				.finally(() => {
					hideLoader();
				});
		});
	};
}

export function removeUserFromTag(data: RemoveUserFromTagRequestData) {
	return async function (dispatch: any) {
		dispatch({ type: REMMOVE_CONTACTS_TO_TAG_START });
		showLoader({ loaderMessage: 'Saving Tag...' });

		return new Promise(async (resolve, reject) => {
			await apiService
				.removeUserFromTag(data)
				.then(async (response: ApiResponse) => {
					if (response.success) {
						const result = response.result;
						// Refresh contacts and tags for new tags or contact added to tags
						await dispatch(getMyTags());
						await dispatch(getRosterItems());
						dispatch({
							type: REMMOVE_CONTACTS_TO_TAG_SUCCESS,
							payload: result,
						});
						resolve(result);
					} else {
						dispatch({
							type: REMMOVE_CONTACTS_TO_TAG_FAILED,
							payload: response.message,
						});
						dispatch(showToast({ toastMessage: response.message }));
						reject(response.message);
					}
				})
				.catch((error: any) => {
					dispatch({
						type: REMMOVE_CONTACTS_TO_TAG_FAILED,
						payload: error.message || error.error,
					});
					dispatch(showToast({ toastMessage: error.message || error.error }));
					reject(error.message || error.error);
				})
				.finally(() => {
					hideLoader();
				});
		});
	};
}

/**
 * Other user profile by token
 * @param data  GetProfileByTokenRequestData
 * @returns
 */
export function getProfileByToken(data: GetProfileByTokenRequestData) {
	return async function (dispatch: any) {
		dispatch({
			type: SHOW_LOADER,
			payload: {
				isLoading: true,
				loaderMessage: 'Searching...',
			} as GlobalStateTypes,
		});

		return new Promise(async (resolve, reject) => {
			await apiService
				.getProfileByToken(data)
				.then(async (response: any) => {
					if (response.success) {
						const result = response.result;
						resolve(result);
					} else {
						reject(response);
					}
				})
				.catch((error: any) => {
					reject(error);
				})
				.finally(() => {
					dispatch({
						type: SHOW_LOADER,
						payload: {
							isLoading: false,
							loaderMessage: '',
						} as GlobalStateTypes,
					});
				});
		});
	};
}

/**
 * Other user profile by username
 * @param data  GetProfileByTokenRequestData
 * @returns
 */
export function getProfileByUsername(data: GetProfileByUsernameRequestData): (dispath: any) => Promise<UserProfile> {
	return async function (dispatch: any) {
		dispatch({
			type: SHOW_LOADER,
			payload: {
				isLoading: true,
				loaderMessage: 'Searching...',
			} as GlobalStateTypes,
		});
		return new Promise(async (resolve, reject) => {
			await apiService
				.getProfileByUsername(data)
				.then(async (response: ApiResponse) => {
					if (response.success) {
						resolve(response.result);
					} else {
						reject(response);
					}
				})
				.catch((error: any) => {
					reject(error);
				})
				.finally(() => {
					dispatch({
						type: SHOW_LOADER,
						payload: {
							isLoading: false,
							loaderMessage: '',
						} as GlobalStateTypes,
					});
				});
		});
	};
}

export function updateMyProfile(payload: UpdateProfileRequestData) {
	return async function (dispatch: any) {
		dispatch({
			type: PROFILE_UPDATING,
		});

		return new Promise(async (resolve, reject) => {
			await apiService
				.updateMyProfile(payload)
				.then(async (response: any) => {
					if (response.success) {
						const result = response.result;

						let myProfileData: MyProfile = await dbService.me();
						if (myProfileData) {
							myProfileData = { ...myProfileData, ...payload.user };
						}
						await apiService.updateUser(myProfileData);

						dispatch({ type: PROFILE_UPDATE_SUCCESS, payload: payload });
						resolve(result);
					} else {
						dispatch({ type: PROFILE_UPDATE_FAILED, payload: response });
						reject(response);
					}
				})
				.catch((error: any) => {
					dispatch({ type: PROFILE_UPDATE_FAILED, payload: error });
					reject(error);
				});
		});
	};
}

export function updateDots(payload: UpdateDotsData) {
	return async function (dispatch: any) {
		return new Promise(async (resolve, reject) => {
			await apiService
				.updateDots({ dot: payload })
				.then(async (response: any) => {
					if (response.success) {
						const result = response.result;
						dispatch(getMyProfile());
						resolve(result);
					} else {
						reject(response);
					}
				})
				.catch((error: any) => {
					reject(error);
				});
		});
	};
}

export function updateChatOnlyProfile(payload: UpdateChatOnlyProfileRequestData) {
	return async function (dispatch: any) {
		dispatch({
			type: CHATONLY_PROFILE_UPDATING,
		});

		return new Promise(async (resolve, reject) => {
			await apiService
				.updateChatOnlyProfile(payload)
				.then(async (response: ApiResponse) => {
					if (response.success) {
						const result = response.result;
						let myProfileData: MyProfile = await dbService.me();
						let myChatOnlyProfileData: MyChatOnlyProfile = (await dbService.meChatOnly()) || {};
						if (myChatOnlyProfileData) {
							myChatOnlyProfileData = {
								...myChatOnlyProfileData,
								...payload.profile,
								userId: myProfileData.userId,
							};
						}
						await apiService.updateChatOnlyUser(myChatOnlyProfileData);

						dispatch({
							type: CHATONLY_PROFILE_UPDATE_SUCCESS,
							payload: myChatOnlyProfileData,
						});
						resolve(result);
					} else {
						dispatch({
							type: CHATONLY_PROFILE_UPDATE_FAILED,
							payload: response,
						});
						reject(response);
					}
				})
				.catch((error: any) => {
					dispatch({ type: CHATONLY_PROFILE_UPDATE_FAILED, payload: error });
					reject(error);
				});
		});
	};
}

export function updateChatOnlyProfilePhoto(file: File) {
	return async function (dispatch: any) {
		showLoader({ loaderMessage: 'Uploading...' });

		return new Promise(async (resolve, reject) => {
			const formData = new FormData();
			formData.append('profilePhoto', file);

			await apiService
				.updateChatOnlyProfilePhoto(formData)
				.then(async (response: ApiResponse) => {
					if (response.success) {
						let myProfileData: MyProfile = await dbService.me();
						await dispatch(getMyChatOnlyProfile(myProfileData.userId));
						resolve(true);
					} else {
						dispatch(showToast({ toastMessage: response.message }));
						reject(response);
					}
				})
				.catch((error: any) => {
					reject(error);
				})
				.finally(() => {
					hideLoader();
				});
		});
	};
}

function updateMyProfilePhoto(dispatch: any, payload: AddProfilePhotoRequestData | RemoveProfilePhotoRequestData | ActivateProfilePhotoRequestData | HideProfilePhotoRequestData | UnHideProfilePhotoRequestData, updateType: 'add' | 'remove' | 'activate' | 'hide' | 'unhide') {
	dispatch({
		type: PROFILE_UPDATING,
	});

	return new Promise(async (resolve, reject) => {
		const handleSuccess = async (response: any) => {
			if (response.success) {
				const result = response.result;

				let myProfileData: MyProfile = await dbService.me();
				if (myProfileData) {
					myProfileData = { ...myProfileData, profilePhotos: result };
				}
				await apiService.updateUser(myProfileData);

				dispatch({ type: PROFILE_UPDATE_SUCCESS, payload: myProfileData });
				resolve(result);
			} else {
				dispatch({ type: PROFILE_UPDATE_FAILED, payload: response });
				reject(response);
			}
		};

		const handleError = (error: any) => {
			dispatch({ type: PROFILE_UPDATE_FAILED, payload: error });
			reject(error);
		};

		switch (updateType) {
			case 'add':
				await apiService
					.addProfilePhoto(payload as AddProfilePhotoRequestData)
					.then(handleSuccess)
					.catch(handleError);
				break;

			case 'remove':
				await apiService
					.removeProfilePhoto(payload as RemoveProfilePhotoRequestData)
					.then(handleSuccess)
					.catch(handleError);
				break;

			case 'activate':
				await apiService
					.activateProfilePhoto(payload as ActivateProfilePhotoRequestData)
					.then(handleSuccess)
					.catch(handleError);
				break;

			case 'hide':
				await apiService
					.hideProfilePhoto(payload as HideProfilePhotoRequestData)
					.then(handleSuccess)
					.catch(handleError);
				break;

			case 'unhide':
				await apiService
					.unHideProfilePhoto(payload as UnHideProfilePhotoRequestData)
					.then(handleSuccess)
					.catch(handleError);
				break;

			default:
				break;
		}
	});
}

export function addProfilePhoto(payload: AddProfilePhotoRequestData) {
	return async function (dispatch: any) {
		return updateMyProfilePhoto(dispatch, payload, 'add');
	};
}

export function removeProfilePhoto(payload: RemoveProfilePhotoRequestData) {
	return async function (dispatch: any) {
		return updateMyProfilePhoto(dispatch, payload, 'remove');
	};
}
export function activateProfilePhoto(payload: ActivateProfilePhotoRequestData) {
	return async function (dispatch: any) {
		return updateMyProfilePhoto(dispatch, payload, 'activate');
	};
}
export function hideProfilePhoto(payload: HideProfilePhotoRequestData) {
	return async function (dispatch: any) {
		return updateMyProfilePhoto(dispatch, payload, 'hide');
	};
}
export function unhideProfilePhoto(payload: UnHideProfilePhotoRequestData) {
	return async function (dispatch: any) {
		return updateMyProfilePhoto(dispatch, payload, 'unhide');
	};
}

export function updateUser(payload: any) {
	const callBack: any = payload.callBack,
		updateServer: Boolean = payload.updateServer || false;

	if (callBack) {
		delete payload.callBack;
	}

	if (updateServer) {
		delete payload.updateServer;
	}

	return async function (dispatch: any) {
		dispatch({
			type: PROFILE_UPDATING,
			payload: {},
		});

		await apiService
			.updateUser(payload)
			.then((response: any) => {
				if (response.Error) {
					dispatch({ type: PROFILE_UPDATE_FAILED, payload: response });
					callBack && callBack(false, response.errorMessage);
				} else {
					dispatch({ type: USER_LOGIN, payload: response });
					dispatch({
						type: PROFILE_UPDATE_SUCCESS,
						payload: response,
						postData: payload,
					});
					callBack && callBack(true, response);
				}
			})
			.catch((error: any) => {
				dispatch({
					type: PROFILE_UPDATE_ERROR,
					payload: error,
				});
				callBack && callBack(false, error.errorMessage);
			});
	};
}

export function uploadMedia(payload: any) {
	return async function () {
		// create the thumbnail
		payload.profileThumb = await compressSelectedFile(payload.profilePhoto, {
			x: 40,
			y: 40,
			fit: 'contain',
			upscale: false,
		});
		return await apiService.uploadMedia(payload);
	};
}
