import { IonButton, IonModal, IonIcon, IonSearchbar } from '@ionic/react';
import React, { useCallback, useEffect, useState } from 'react';
import './style.scss';
import { Grid } from '@giphy/react-components';
import { GiphyFetch } from '@giphy/js-fetch-api';
import { Config } from '../../config/config';
import { GiphyItem } from '../../types/giphy';
import { dbService } from '../../services/dbService';
import { DbController, IndexDbCollections } from '../../services/DbController';

const CheckMark = './assets/icon/selectAll.svg';

// use @giphy/js-fetch-api to fetch gifs, instantiate with your api key
const gf = new GiphyFetch(Config.GIPHY_API_KEY);

interface FavouriteStickerRemoveModalProps {
	show: boolean;
	onDimissed?: () => void;
	onRemoved?: (gifs: Array<GiphyItem>) => void;
}

const FavouriteStickerRemoveModal: React.FC<FavouriteStickerRemoveModalProps> = ({ show, onDimissed, onRemoved }) => {
	const [showModal, setShowModal] = useState<boolean>(false);
	const [favGifsList, setFavGifsList] = useState<Array<GiphyItem>>([]);
	const [selectedGifIds, setSelectedGifIds] = useState<Array<string>>([]);

	const mounted = React.useRef(false);

	useEffect(() => {
		mounted.current = true;
		return () => {
			mounted.current = false;
		};
	}, []);

	useEffect(() => {
		if (show && mounted.current) {
			(async () => {
				dbService.favGifs().then((gifsData) => {
					if (mounted.current) {
						if (gifsData && gifsData.length > 0) {
							setFavGifsList(gifsData[0].fav_gifs);
						}
						setShowModal(true);
					}
				});
			})();
		}
	}, [show, mounted.current]);

	const removeGifToFav = async () => {
		if (selectedGifIds && selectedGifIds.length > 0) {
			selectedGifIds.forEach(async (id) => {
				const findIndex = favGifsList.findIndex((obj: any) => obj.id === id);
				if (findIndex >= 0) {
					favGifsList.splice(findIndex, 1);
				}
			});
			const updateData = {
				[IndexDbCollections.DATA.FAV_GIFS]: favGifsList,
				type: IndexDbCollections.DATA.FAV_GIFS,
			};
			try {
				const updateResGif = await DbController.update(IndexDbCollections.DATA.name, updateData, IndexDbCollections.DATA.FAV_GIFS);
				if (!updateResGif) {
					DbController.put(IndexDbCollections.DATA.name, updateData);
				}
			} catch (error) {}
		}
		if (mounted.current) {
			setFavGifsList([...favGifsList]);
			onRemoved && onRemoved(favGifsList);
			setShowModal(false);
		}
	};

	const onCloseCallBack = useCallback(() => {
		onDimissed && onDimissed();
	}, [onDimissed]);

	const fetchGifs = (offset: number) => {
		const GifCountPerSlot = 20;
		return new Promise((resolve: (value: any) => void, reject) => {
			if (favGifsList && favGifsList.length >= offset) {
				const latestData = favGifsList.slice(offset, offset + GifCountPerSlot);
				resolve({
					data: latestData,
					pagination: {
						total_count: favGifsList.length,
						count: latestData.length,
						offset: offset,
					},
				});
			} else {
				resolve([]);
			}
		});
	};

	return (
		<IonModal mode="ios" showBackdrop={true} isOpen={showModal} onDidDismiss={onCloseCallBack} swipeToClose={true} cssClass="favourite-sticker-remove-modal" animated={true}>
			<div className="content">
				<div className="header">
					<div className="title">Favorite Sticker</div>
					<div className="subtitle">{favGifsList.length} of Stickers</div>
				</div>

				<div className="stickers-container">
					{favGifsList.length > 0 && (
						<Grid
							width={window.innerWidth - 32}
							gutter={16}
							columns={4}
							fetchGifs={fetchGifs}
							noLink={true}
							overlay={({ gif }) => (selectedGifIds.includes(gif.id as string) ? <IonIcon className="giphy-close-icon-checked" src={CheckMark} /> : <div className="giphy-close-icon"></div>)}
							onGifsFetched={() => {}}
							onGifClick={(gif: any) => {
								const findIndex = selectedGifIds.findIndex((obj: any) => obj === gif.id);
								if (findIndex >= 0) {
									selectedGifIds.splice(findIndex, 1);
								} else {
									selectedGifIds.push(gif.id);
								}
								if (mounted.current) {
									setSelectedGifIds([...selectedGifIds]);
								}
							}}
						/>
					)}
				</div>

				<div className="bottom-buttons">
					<IonButton
						fill="clear"
						className={'close-btn'}
						onClick={() => {
							if (mounted.current) {
								setShowModal(false);
							}
						}}
					>
						Close
					</IonButton>
					<IonButton fill="clear" disabled={selectedGifIds.length === 0} className={'delete-btn' + (selectedGifIds.length > 0 ? ' active' : '')} onClick={removeGifToFav}>
						Delete {selectedGifIds.length > 0 ? <>({selectedGifIds.length})</> : ''}
					</IonButton>
				</div>
			</div>
		</IonModal>
	);
};

export default FavouriteStickerRemoveModal;
