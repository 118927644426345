import { IonModal, IonSearchbar } from '@ionic/react';
import React, { forwardRef, useCallback, useImperativeHandle, useRef, useState } from 'react';
import './style.scss';
import Map, { Marker } from 'react-map-gl';
import { IonButton, IonIcon, IonItem, IonList } from '@ionic/react';
import axios from 'axios';

import './style.scss';

import 'mapbox-gl/dist/mapbox-gl.css';
import { logError } from '../helpers/common';
import { getDistance } from 'geolib';
import { MapBoxApiBaseUrl, MapToken } from '../services/sharedService';

const LocationNetwork = './assets/icon/location_network.svg';
const CurrentLocation = './assets/icon/currentLocation.svg';
const LocationPin = './assets/icon/location_pin.svg';
const LocationPinRed = './assets/icon/pinRed.svg';
const LocationDirection = './assets/icon/location_direction.svg';

interface ShareLocationModalProps {
	onClose: () => void;
	selectedLocation?: any;
	viewLocation?: boolean;
	data?: any;
}

export interface ShareLocationModalMethods {
	show: (currentLocation: any) => void;
}

const ShareLocationModal = forwardRef(({ onClose, selectedLocation, viewLocation, data }: ShareLocationModalProps, ref: React.ForwardedRef<ShareLocationModalMethods>) => {
	const [showModal, setShowModal] = useState(false);

	const [viewport, setViewport] = useState({
		latitude: data?.center[1] || 22.5292514,
		longitude: data?.center[2] || 75.8872642,
		zoom: 16,
	});

	const mapRef = useRef<any>(null);
	const [locationsList, setLocationsList] = useState<any>([]);
	const [visible, setVisible] = useState(false);
	const [showMap, setShowMap] = useState(false);
	const [option, setOption] = React.useState<any>();

	useImperativeHandle(ref, () => ({
		show: (location?: any) => {
			setShowModal(true);
			if (!location) {
				navigator.geolocation.getCurrentPosition(
					(pos) => {
						setViewport({
							...viewport,
							latitude: pos.coords.latitude,
							longitude: pos.coords.longitude,
						});
						viewlocation({ latitude: pos.coords.latitude, longitude: pos.coords.longitude });
						setTimeout(() => {
							setShowMap(true);
						}, 200);
						console.log('getCurrentPosition::success', pos);
					},
					(error) => {
						console.error('getCurrentPosition::error', error);
					}
				);
			} else {
				setViewport({
					...viewport,
					latitude: location?.latitude,
					longitude: location?.longitude,
				});
				viewlocation(location);

				setTimeout(() => {
					setShowMap(true);
				}, 200);
			}
		},
	}));

	const onCloseCallBack = useCallback(() => {
		setShowModal(false);
		setShowMap(false);
		onClose();
	}, [onClose]);

	const selectLocation = async () => {
		selectedLocation && selectedLocation(option);
		onCloseCallBack();

		//https://api.mapbox.com/styles/v1/mapbox/streets-v11/static/pin-s+9ed4bd(-122.4241,37.78)/-122.4241,37.78,14.25,0,60/600x600?access_token=YOUR_MAPBOX_ACCESS_TOKEN

		/*
		Replace YOUR_MAPBOX_ACCESS_TOKEN with your Mapbox access token, -122.4241,37.78,14.25 with the longitude, latitude, and zoom level respectively, and pin-s+9ed4bd(-122.4241,37.78) with marker customization.
*/
	};

	const updateDistanceInLocation = (currentLocation: any, locations: any) => {
		for (let i = 0; i < locations.length; i++) {
			locations[i]['distance'] = getDistance({ latitude: currentLocation.latitude, longitude: currentLocation.longitude }, { longitude: locations[i].center[0], latitude: locations[i].center[1] }, 1);
		}
		return locations;
	};

	const viewLocationApiCalling = useRef<boolean>(false);
	const viewLocationTmpStore = useRef<any>();

	const viewlocation = async (location: any) => {
		if (viewLocationApiCalling.current) {
			viewLocationTmpStore.current = location;
			return;
		}
		viewLocationApiCalling.current = true;
		viewLocationTmpStore.current = null;
		await axios({
			method: 'get',
			url: `${MapBoxApiBaseUrl}geocoding/v5/mapbox.places/${location.longitude},${location.latitude}.json?access_token=${MapToken}`,
		})
			.then((_response: any) => {
				setOption(null);
				setLocationsList(updateDistanceInLocation(location, _response.data.features.slice(0, 3)));
			})
			.catch((err: any) => {
				logError('serverApi::fetch::' + err.message);
			})
			.finally(() => {
				viewLocationApiCalling.current = false;
				if (viewLocationTmpStore.current) {
					viewlocation(viewLocationTmpStore.current);
				}
			});
	};

	const searchLocationApiCalling = useRef<boolean>(false);
	const searchLocationTmpStore = useRef<any>();

	const onSearch = async (search_text: any) => {
		if (searchLocationApiCalling.current) {
			searchLocationTmpStore.current = search_text;
			return;
		}
		searchLocationApiCalling.current = true;
		searchLocationTmpStore.current = null;

		await axios({
			method: 'get',
			url: `${MapBoxApiBaseUrl}geocoding/v5/mapbox.places/${search_text}.json?access_token=${MapToken}`,
		})
			.then((_response: any) => {
				if (_response?.data?.features?.length > 0) {
					const coordinates = _response?.data?.features[0].geometry.coordinates;
					setViewport({ ...viewport, latitude: coordinates[1], longitude: coordinates[0] });
					setOption(null);
					setLocationsList(updateDistanceInLocation(viewport, _response.data.features));
				} else {
					console.log(`No results found for '${search_text}'`);
				}
			})
			.catch((err: any) => {
				logError('serverApi::fetch:: API server is offline' + err.message);
			})
			.finally(() => {
				searchLocationApiCalling.current = false;
				if (searchLocationTmpStore.current) {
					onSearch(searchLocationTmpStore.current);
				}
			});
	};

	const handleViewportChange = (newViewport: any) => {
		setViewport({ ...viewport, ...newViewport });
	};

	return (
		<IonModal showBackdrop={false} isOpen={showModal} onDidDismiss={onCloseCallBack} swipeToClose={false} cssClass={'share-location-modal'} animated={true}>
			<div className="share-location-map-container">
				<div className="map-content">
					{showMap && (
						<Map
							{...viewport}
							// onTouchMove={(evt: any) => {
							// 	const newViewPort = { ...viewport, latitude: evt.lngLat.lat, longitude: evt.lngLat.lng };
							// 	console.log('Map::onTouchMove', newViewPort);
							// 	setViewport(newViewPort);
							// 	viewlocation(newViewPort);
							// }}
							// onMouseMove={(evt: any) => {
							// 	const newViewPort = { ...viewport, latitude: evt.lngLat.lat, longitude: evt.lngLat.lng };
							// 	console.log('Map::onMouseMove', newViewPort);
							// 	setViewport(newViewPort);
							// 	viewlocation(newViewPort);
							// }}
							mapboxAccessToken={MapToken}
							mapStyle="mapbox://styles/mapbox/streets-v11"
							style={{ width: '100%', height: '100%' }}
							dragPan={true}
							touchZoomRotate={true}
							onDrag={(event) => {
								const newViewPort = { ...viewport, latitude: event.viewState.latitude, longitude: event.viewState.longitude };
								setViewport(newViewPort);
								viewlocation(newViewPort);
							}}
							ref={mapRef}
							// onViewportChange={(nextViewport: any) => setViewport(nextViewport)}
						>
							<Marker longitude={viewport.longitude} latitude={viewport.latitude}>
								<img src={LocationPinRed} />
							</Marker>
						</Map>
					)}
				</div>
				{showModal && viewLocation ? (
					<>
						<div className="view-location-buttons">
							<div className="location-network">
								<img className="location-icon" src={LocationPin} alt="" />
							</div>
							<div className="current-location">
								<img className="location-icon" src={CurrentLocation} alt="" />
							</div>
						</div>
						<div className="view-content">
							<IonList lines="none">
								<IonItem
									className={'location-block'}
									lines="none"
									onClick={() => {
										setVisible(!visible);
										setOption(null);
									}}
								>
									{/* {option === item && <IonIcon className="right-icon" slot="end" src="assets/icon/Combined Shape.svg"></IonIcon>} */}
									<div className="location-content">
										<div className="location-text"> {data.text}</div>
										<div className="location-address">
											{Math.trunc(data.dist)} KM | {data.place_name}
										</div>
									</div>
								</IonItem>
							</IonList>
							<div className="bottom-buttons">
								<IonButton className="cancel" onClick={onCloseCallBack}>
									Cancel
								</IonButton>
								<IonButton className="share" onClick={onCloseCallBack}>
									<img src={LocationDirection} />
								</IonButton>
							</div>
						</div>
					</>
				) : (
					locationsList.length > 0 && (
						<>
							<div className="location-buttons">
								<IonButton fill="clear" shape="round" className="location-network">
									<IonIcon slot="icon-only" src={LocationNetwork}></IonIcon>
								</IonButton>
								<IonButton
									fill="clear"
									shape="round"
									className="current-location"
									onClick={() => {
										navigator.geolocation.getCurrentPosition(
											(pos) => {
												setViewport({
													...viewport,
													latitude: pos.coords.latitude,
													longitude: pos.coords.longitude,
												});
											},
											(error) => {
												console.error('getCurrentPosition::error', error);
											}
										);
									}}
								>
									<IonIcon slot="icon-only" src={CurrentLocation}></IonIcon>
								</IonButton>
							</div>
							<div className="content">
								<IonSearchbar animated={true} mode="ios" id="searchBar" className="searchbar-wrapper" style={{ color: '#000000' }} debounce={1} showCancelButton="never" onIonChange={(e) => onSearch(e.detail.value)} />
								<div className="list-container">
									<IonList lines="none">
										{locationsList?.map((item: any, key: any) => {
											return (
												<IonItem
													key={key}
													className={'location-block'}
													lines="none"
													onClick={() => {
														handleViewportChange({
															...viewport,
															latitude: item.geometry.coordinates[1],
															longitude: item.geometry.coordinates[0],
														});
														setViewport({ ...viewport, latitude: item.geometry.coordinates[1], longitude: item.geometry.coordinates[0] });
														setOption(item);
													}}
												>
													{option?.id === item.id && <IonIcon className="right-icon" slot="end" src="assets/icon/Combined Shape.svg" />}

													<div className="location-content">
														<div className="location-text"> {item.text}</div>
														<div className="location-address">
															{item.distance > 1000 ? item.distance / 1000 + ' km' : item.distance + ' m'} | {item.place_name}
														</div>
													</div>
												</IonItem>
											);
										})}
									</IonList>
								</div>
								<div className="bottom-buttons">
									<IonButton className="cancel" onClick={onCloseCallBack}>
										Cancel
									</IonButton>
									<IonButton
										disabled={!option}
										className="share"
										onClick={() => {
											selectLocation();
										}}
									>
										Share
									</IonButton>
								</div>
							</div>
						</>
					)
				)}
			</div>
		</IonModal>
	);
});

export default ShareLocationModal;
