import React, { createRef } from 'react';
import { IonActionSheet, IonContent, IonIcon, IonImg, IonLoading, IonPage, IonSlide, IonSlides } from '@ionic/react';
import './style.scss';
import { RouteComponentProps } from 'react-router';
import { DefaultProfilePic } from '../../helpers/common';
import { connect } from 'react-redux';
import { updateMyProfile, pageLoading, resetValues, addProfilePhoto, removeProfilePhoto, activateProfilePhoto, hideProfilePhoto, unhideProfilePhoto } from '../../redux/actions/profile';
import { showToast } from '../../redux/actions/global';
import store from '../../redux/store';
import { SHOW_TOAST } from '../../redux/constants/common';
import ImageEditor, { OpenEditorType } from '../../modals/ImageEditor/ImageEditor';
import { TopNavbar } from '../common/header/topbar';
import ToastStylish from '../../components/ToastStylish';
import { checkmarkOutline } from 'ionicons/icons';
import CameraService from '../../services/cameraService';
import { locale } from '../../locales/local';
import { info } from '../../helpers/common';
import _ from 'lodash';
import { ActivateProfilePhotoRequestData, AddProfilePhotoRequestData, HideProfilePhotoRequestData, RemoveProfilePhotoRequestData, UnHideProfilePhotoRequestData, UpdateProfileRequestData } from '../../types/api-requests';
import { GlobalStateTypes, ProfileStateTypes, ReduxStates } from '../../types/redux-states';
import { ProfilePhotoItem } from '../../types/profile';
import SharedService from '../../services/sharedService';
import { Navigation } from '../../services/navigationService';
const Back = './assets/icon/left-arrow.svg';
const Next = './assets/icon/right-arrow.svg';

interface iProps extends RouteComponentProps<{ name: string }> {
	profile: ProfileStateTypes;
	global: GlobalStateTypes;

	showToast: (payload: GlobalStateTypes) => void;

	updateMyProfile: (data: UpdateProfileRequestData) => Promise<unknown>;
	addProfilePhoto: (data: AddProfilePhotoRequestData) => Promise<unknown>;
	removeProfilePhoto: (data: RemoveProfilePhotoRequestData) => Promise<unknown>;
	activateProfilePhoto: (data: ActivateProfilePhotoRequestData) => Promise<unknown>;
	hideProfilePhoto: (data: HideProfilePhotoRequestData) => Promise<unknown>;
	unhideProfilePhoto: (data: UnHideProfilePhotoRequestData) => Promise<unknown>;

	resetValues: Function;
	pageLoading: Function;
}
interface iState {
	selectedImage: any;
	showEditor: boolean;
	editedImage: any;
	loggedInUser?: any;
	openActionSheetFor: 'current-photo' | 'past-photo' | 'no-photo' | undefined;
	isLoading: boolean;
	loadingMessage: string;
	showToast: boolean;
	showBottomMenu: boolean;
	toastMessage: string;
	slideIndex: any;
}

class UploadPhoto extends React.Component<iProps, iState> {
	slidesRef: any = createRef();
	cameraService = new CameraService();

	componentIsMounted: Boolean = false;

	componentIsUpdated: Boolean = false;

	constructor(props: iProps) {
		super(props);

		this.state = {
			loggedInUser: {},
			slideIndex: 0,
			showBottomMenu: false,
			openActionSheetFor: undefined,
			showEditor: false,
			editedImage: null,
			isLoading: false,
			loadingMessage: '',
			showToast: false,
			toastMessage: '',
			selectedImage: null,
		};
	}

	setPageData = async () => {
		this.componentIsMounted = true;
		this.setState((state: any, props: any) => ({
			...state,
			loggedInUser: this.props.profile.loggedInUserProfile,
		}));
	};

	componentDidMount = async () => {
		if (!this.componentIsMounted) {
			await this.setPageData();
		}
	};

	componentDidUpdate = async () => {
		if (!this.componentIsUpdated) {
			this.componentIsUpdated = true;
			await this.setPageData();
		}
	};

	detectWebcam = (callback: any) => {
		let md = navigator.mediaDevices;
		if (!md || !md.enumerateDevices) return callback(false);
		md.enumerateDevices().then((devices) => {
			callback(devices.some((device) => 'videoinput' === device.kind));
		});
	};

	savePhoto = () => {
		this.setState({ isLoading: true, loadingMessage: locale.global.adding });
		_.delay(() => {
			this.setState({
				...this.state,
				isLoading: false,
				loadingMessage: '',
				showToast: true,
				toastMessage: locale.profile.upload_photo.saved_to_album,
				showBottomMenu: true,
			});
		}, 2000);
	};

	handleApiErrorResponse = (error: any) => {
		this.setState({
			isLoading: false,
			loadingMessage: '',
		});

		this.props.showToast({ toastMessage: SharedService.extractErrorMessage(error), toastType: 'danger', showToast: true });
	};

	handleApiSuccessResponse = (toastMessage: string, moveToFirstIndex: boolean = false) => {
		this.setState({
			isLoading: false,
			loadingMessage: '',
			showToast: true,
			toastMessage,
		});

		if (moveToFirstIndex) {
			_.delay(() => {
				this.slidesRef?.current?.slideTo(0);
				this.componentIsUpdated = false;
			}, 1000);
		}
	};

	uploadPhoto = async () => {
		if (this.state.editedImage) {
			this.setState({
				isLoading: true,
				loadingMessage: locale.profile.upload_photo.uploading,
			});
			this.props
				.addProfilePhoto({ base64Image: this.state.editedImage })
				.then(() => this.handleApiSuccessResponse('Photo uploaded successfully', true))
				.catch(this.handleApiErrorResponse);
		} else {
			store.dispatch({
				type: SHOW_TOAST,
				payload: {
					showToast: true,
					toastMessage: locale.profile.upload_photo.select_image,
				},
			});
		}
	};

	onActivateProfilePhoto = async (id: number) => {
		this.setState({
			isLoading: true,
			loadingMessage: locale.profile.upload_photo.updating,
		});

		this.props
			.activateProfilePhoto({ id })
			.then(() => this.handleApiSuccessResponse('Photo activated successfully', true))
			.catch(this.handleApiErrorResponse);
	};

	onDeleteProfilePhoto = async (id: number) => {
		this.setState({
			isLoading: true,
			loadingMessage: locale.profile.upload_photo.updating,
		});

		this.props
			.removeProfilePhoto({ id })
			.then(() => this.handleApiSuccessResponse('Profile photo deleted successfully', true))
			.catch(this.handleApiErrorResponse);
	};

	onHideProfilePhoto = async (id: number) => {
		this.setState({
			isLoading: true,
			loadingMessage: locale.profile.upload_photo.updating,
		});

		this.props
			.hideProfilePhoto({ id })
			.then(() => this.handleApiSuccessResponse('Photo hided', false))
			.catch(this.handleApiErrorResponse);
	};

	onUnhideProfilePhoto = async (id: number) => {
		this.setState({
			isLoading: true,
			loadingMessage: locale.profile.upload_photo.updating,
		});
		this.props
			.unhideProfilePhoto({ id })
			.then(() => this.handleApiSuccessResponse('Profile unhided successfully', false))
			.catch(this.handleApiErrorResponse);
	};

	onSlideChange = (event: any) => {
		const slideRef = event.currentTarget as HTMLIonSlidesElement;
		slideRef?.getActiveIndex().then((index: number) => {
			info(index);
			this.setState({ slideIndex: index });
		});
	};

	onPinturaEditingDone = (file: any) => {
		// encode the file using the FileReader API
		const reader = new FileReader();
		reader.onloadend = async () =>
			this.setState({ showEditor: false, editedImage: reader.result }, () => {
				this.uploadPhoto();
			});
		reader.readAsDataURL(file);
	};

	onBtnClicked = async (direction: string) => {
		const swiper = await this.slidesRef.current.getSwiper();
		if (direction === 'next') {
			swiper.slideNext();
		} else if (direction === 'prev') {
			swiper.slidePrev();
		}
	};

	_getActionsheetOptions = () => {
		const { openActionSheetFor } = this.state;

		const findSelectedProfilePhoto = () => {
			if (this.props.profile.loggedInUserProfile && this.props.profile.loggedInUserProfile.profilePhotos.length > this.state.slideIndex) {
				const photo: ProfilePhotoItem = this.props.profile.loggedInUserProfile?.profilePhotos[this.state.slideIndex];
				return photo;
			}
			return null;
		};
		const item: ProfilePhotoItem = findSelectedProfilePhoto() as ProfilePhotoItem;

		const deletePhoto = {
			text: locale.profile.upload_photo.delete_photo,
			role: 'destructive',
			handler: () => {
				item && this.onDeleteProfilePhoto(item.id);
			},
		};
		const copyItem = {
			text: locale.profile.upload_photo.copy,
			handler: () => {
				// this.setState({
				// 	openActionSheetFor: undefined,
				// 	showToast: true,
				// 	toastMessage: 'Copied',
				// });
				this.props.showToast({ toastMessage: 'Functionality Not implemented yet', toastType: 'danger', showToast: true });
			},
		};
		const savePhoto = {
			text: locale.profile.upload_photo.save_photo,
			handler: () => {
				// this.savePhoto();
				this.props.showToast({ toastMessage: 'Functionality Not implemented yet', toastType: 'danger', showToast: true });
			},
		};

		const makeProfilePhoto = {
			text: locale.profile.upload_photo.make_profile_photo,
			handler: () => {
				item && this.onActivateProfilePhoto(item.id);
			},
		};

		const takePhoto = {
			text: locale.profile.upload_photo.take_photo,
			handler: () => {
				this.cameraService.capturePhotoFromCamera((photo: Array<File>) => {
					this.setState({
						selectedImage: photo[0],
						showEditor: true,
					});
				}, true);
			},
		};

		const chooseFromAlbum = {
			text: locale.profile.upload_photo.choose_album,
			handler: () => {
				this.cameraService.choosePhotoFromAlbum((photo: Array<File>) => {
					this.setState({
						selectedImage: photo[0],
						showEditor: true,
					});
				});
			},
		};

		const hidePhoto = {
			text: locale.profile.upload_photo.hide_photo,
			handler: () => {
				item && this.onHideProfilePhoto(item.id);
			},
		};

		const unHidePhoto = {
			text: 'Unhide',
			handler: () => {
				item && this.onUnhideProfilePhoto(item.id);
			},
		};

		const cancelPhoto = {
			class: 'cancel',
			text: locale.global.cancel,
			role: 'cancel',
			handler: () => {
				this.setState({ openActionSheetFor: undefined });
				info('Cancel clicked');
			},
		};

		if (openActionSheetFor === 'current-photo') {
			return [deletePhoto, copyItem, savePhoto, takePhoto, chooseFromAlbum, cancelPhoto];
		} else if (openActionSheetFor === 'past-photo') {
			let options: Array<any> = [deletePhoto];
			options = [...options, copyItem, savePhoto, item.hide ? unHidePhoto : hidePhoto];
			if (!item.hide) {
				options.push(makeProfilePhoto);
			}
			options = [...options, takePhoto, chooseFromAlbum, cancelPhoto];

			return options;
		} else {
			return [takePhoto, chooseFromAlbum, cancelPhoto];
		}
	};

	_showActionSheet = () => {
		if (!this.props.profile.loggedInUserProfile?.profilePhotos || this.props.profile.loggedInUserProfile?.profilePhotos.length === 0) {
			this.setState({ openActionSheetFor: 'no-photo' });
		} else if (this.state.slideIndex === 0 && this.props.profile.loggedInUserProfile?.profilePhotos.length > 0 && !this.props.profile.loggedInUserProfile?.profilePhotos[0].hide) {
			this.setState({ openActionSheetFor: 'current-photo' });
		} else {
			this.setState({ openActionSheetFor: 'past-photo' });
		}
	};

	render() {
		const { slideIndex } = this.state;

		const slideOpts = {
			initialSlide: slideIndex,
		};
		const { profilePhotos } = this.props.profile.loggedInUserProfile || {};

		return (
			this.componentIsMounted && (
				<IonPage className="upload-photo-page">
					<TopNavbar
						{...this.props}
						pageTitle={profilePhotos && profilePhotos.length > 0 ? slideIndex + 1 + ' ' + locale.global.of + ' ' + profilePhotos.length : locale.global.profilephoto}
						hideSearchBar={true}
						showBack={false}
						leftButtonIcon={'./assets/icon/edit-photo-back.svg'}
						isHideRightButton={false}
						onRightButtonPress={() => {
							this._showActionSheet();
						}}
						onLeftButtonPress={() => {
							Navigation.back();
						}}
					/>

					<IonContent className={'has-bottom-navbar has-topbar'}>
						{profilePhotos && profilePhotos.length > 0 && (
							<IonSlides key={profilePhotos.join('_')} options={slideOpts} onIonSlideDidChange={this.onSlideChange} ref={this.slidesRef}>
								{profilePhotos.map((profilePhoto: ProfilePhotoItem, key: any) => {
									return (
										<IonSlide key={key}>
											<React.Fragment>
												<div className="profile-placeholder-container">
													<IonIcon
														className="back-icon"
														onClick={(event) => {
															event.stopPropagation();
															this.onBtnClicked('prev');
														}}
														src={Back}
													></IonIcon>

													<IonImg
														className="profile-placeholder"
														onClick={(event) => {
															event.stopPropagation();
															this._showActionSheet();
														}}
														src={profilePhoto.url}
													></IonImg>

													<IonIcon
														className="next-icon"
														onClick={(event) => {
															event.stopPropagation();
															this.onBtnClicked('next');
														}}
														src={Next}
													></IonIcon>
												</div>
											</React.Fragment>
										</IonSlide>
									);
								})}
							</IonSlides>
						)}

						{(!profilePhotos || profilePhotos.length === 0) && (
							<IonSlides options={slideOpts} onIonSlideDidChange={this.onSlideChange} ref={this.slidesRef}>
								<IonSlide>
									<React.Fragment>
										<div style={{ flex: 1 }}></div>
										<div className="profile-placeholder-container" onClick={this._showActionSheet}>
											{!this.state.editedImage && <IonImg className="profile-placeholder" src={DefaultProfilePic}></IonImg>}
											{this.state.editedImage && <IonImg className="profile-placeholder" src={this.state.editedImage}></IonImg>}
										</div>
										<div style={{ flex: 1 }}></div>
									</React.Fragment>
								</IonSlide>
							</IonSlides>
						)}
					</IonContent>

					<ImageEditor
						imageOptions={{ targetWidth: 512, targetHeight: 512, quality: 0.5 }}
						openEditorType={OpenEditorType.FOR_PROFILE_GROUP_PHOTO}
						selectedImage={this.state.selectedImage}
						show={this.state.showEditor}
						onClose={() => {
							this.setState({ showEditor: false });
						}}
						onSave={this.onPinturaEditingDone}
					/>

					<IonLoading isOpen={this.state.isLoading} backdropDismiss={false} message={this.state.loadingMessage} />
					<ToastStylish
						show={this.state.showToast}
						message={this.state.toastMessage}
						svgIcon={checkmarkOutline}
						onClose={() => {
							this.setState({ showToast: false, toastMessage: '' });
						}}
					/>

					<IonActionSheet
						mode="ios"
						isOpen={this.state.openActionSheetFor !== undefined}
						onDidDismiss={() => {
							this.setState({ openActionSheetFor: undefined });
						}}
						cssClass="upload-photo-option-action-sheet"
						buttons={this._getActionsheetOptions()}
					/>
				</IonPage>
			)
		);
	}
}

const mapStateToProps = (state: ReduxStates) => {
	return {
		profile: state.profile,
		global: state.global,
	};
};

const mapDispatchToProps = (dispatch: any) => ({
	updateMyProfile: (payload: UpdateProfileRequestData) => dispatch(updateMyProfile(payload)),
	addProfilePhoto: (payload: AddProfilePhotoRequestData) => dispatch(addProfilePhoto(payload)),
	removeProfilePhoto: (payload: RemoveProfilePhotoRequestData) => dispatch(removeProfilePhoto(payload)),
	activateProfilePhoto: (payload: ActivateProfilePhotoRequestData) => dispatch(activateProfilePhoto(payload)),
	hideProfilePhoto: (payload: HideProfilePhotoRequestData) => dispatch(hideProfilePhoto(payload)),
	unhideProfilePhoto: (payload: UnHideProfilePhotoRequestData) => dispatch(unhideProfilePhoto(payload)),
	showToast: (payload: GlobalStateTypes) => dispatch(showToast(payload)),

	resetValues: () => dispatch(resetValues()),
	pageLoading: (payload: any) => dispatch(pageLoading(payload)),
});

export default connect(mapStateToProps, mapDispatchToProps)(UploadPhoto);
