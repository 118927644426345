import { GroupStateTypes } from '../../types/redux-states';
import { GROUP_LIST_UPDATED, MEMBERS_LIST_UPDATED } from '../constants/group';

const initialState: GroupStateTypes = {
	isLoading: false,
	groups: [],
	activeGroupMembers: [],
};

export default function (state = initialState, action: any) {
	switch (action.type) {
		case GROUP_LIST_UPDATED:
			if (action.payload) {
				state.groups = action.payload.groups || [];
			}
			break;

		case MEMBERS_LIST_UPDATED:
			if (action.payload) {
				state.activeGroupMembers = action.payload.activeGroupMembers || [];
			}
			break;

		default:
			break;
	}
	return { ...state };
}
