import { xmpp } from './../services/xmpp';
import { getInternalStorage, setInternalStorage, info, logError } from './../helpers/common';
import { apiService } from './../services/apiService';
import _ from 'lodash';
import store from './../redux/store';
import { SHOW_APP_UPDATE_POPUP } from './../redux/constants/common';
import { locale } from './../locales/local';
import { createNotification } from './../components/Notifications/notifications';
import axios from 'axios';
import { LocalStorageKeys, NavigationRoutes, NetworkStatus, SessionStorageKeys } from './enumService';

let cookies: any = getInternalStorage(),
	upgradeNotified: boolean = false;

const appManager = {
	askNotificationPermission: async () => {
		setInternalStorage(SessionStorageKeys.SupportsPush, window.PushManager !== undefined);
		setInternalStorage(SessionStorageKeys.SupportsNotification, window.Notification !== undefined);

		cookies = getInternalStorage();

		if (!cookies[SessionStorageKeys.SupportsNotification]) {
			setInternalStorage(SessionStorageKeys.DesktopNotifications, false);
			info('Notification is not supported.');
		} else if (!cookies[SessionStorageKeys.DesktopNotifications]) {
			info('Notification permission was previously denied.');
		} else {
			if (window.Notification.permission === 'granted') {
				// If it's okay let's create a notification
				info('Notification permission previously granted.');
				setInternalStorage(SessionStorageKeys.DesktopNotifications, true);
			} else if (window.Notification.permission !== 'denied') {
				// must be explicity compared to 'denied'
				let permission = await window.Notification.requestPermission();
				// If the user accepts, let's create a notification
				if (permission === 'granted') {
					setInternalStorage(SessionStorageKeys.DesktopNotifications, true);
					info('Notification permission granted.');
				} else {
					setInternalStorage(SessionStorageKeys.DesktopNotifications, false);
					info('Notification permission denied.');
				}
			}
		}
	},
	handleVisibility: async (event: any) => {
		const updateVisibility = async (event: any) => {
			cookies = getInternalStorage();

			if (xmpp.isReady) {
				if (document.visibilityState === 'visible') {
					if (cookies[SessionStorageKeys.MobileDevice]) {
						setInternalStorage(SessionStorageKeys.Active, true);
					}
					await xmpp.sendPresence();
					await apiService.refreshMessages();
				}
			} else if (xmpp.isDisconnected) {
				if (!xmpp.isReady) {
					await xmpp.xmppManager();
				}
			} else {
				info(`AppManager::handleVisibility::updateVisibility::${event.type} ${document.visibilityState} xmpp.isReady: ${xmpp.isReady} xmpp.isDisconnecting ${xmpp.isDisconnecting}`, event);

				if (!xmpp.isReady) {
					await xmpp.xmppManager();
				}
			}

			setInternalStorage(SessionStorageKeys.Active, document.visibilityState === 'visible');
		};

		const getHiddenPropName = (document: Document & { msHidden?: boolean; webkitHidden?: boolean }) => {
			let response: any;

			if (typeof document.hidden !== 'undefined') {
				response = 'visibilitychange';
			} else if (typeof document.msHidden !== 'undefined') {
				response = 'msvisibilitychange';
			} else if (typeof document.webkitHidden !== 'undefined') {
				response = 'webkitvisibilitychange';
			}

			return response;
		};

		let visibilityChange = getHiddenPropName(document);

		if (!cookies[SessionStorageKeys.Listeners]) {
			cookies[SessionStorageKeys.Listeners] = [];
		}

		document.addEventListener(visibilityChange, (event: any) => updateVisibility(event), { capture: true, passive: true });
		cookies[SessionStorageKeys.Listeners].push({ element: 'document', event: visibilityChange, fn: updateVisibility });
		document.addEventListener('pagehide', (event: any) => updateVisibility(event), { capture: true, passive: true });
		cookies[SessionStorageKeys.Listeners].push({ element: 'document', event: 'pagehide', fn: updateVisibility });
		setInternalStorage(SessionStorageKeys.Listeners, cookies[SessionStorageKeys.Listeners]);
		setInternalStorage(SessionStorageKeys.Active, document.visibilityState === 'visible');
	},
	reload: async (delay: number = 1000) => {
		const cookies = getInternalStorage(),
			currentMetadata = await apiService.ping().then((response: any) => response || require('../metadata.json')),
			currentVersion = `${currentMetadata.buildMajor}.${currentMetadata.buildMinor}.${currentMetadata.buildRevision} ${currentMetadata.buildTag}`,
			dbVersion = currentMetadata.dbVersion;

		_.defer(() => {
			_.delay(async () => {
				if (dbVersion && parseInt(cookies[LocalStorageKeys.DBVersion]) !== dbVersion) {
					setInternalStorage(LocalStorageKeys.DBVersion, dbVersion);
					// logoutUser(true);
				} else {
					setInternalStorage(SessionStorageKeys.Version, currentVersion);
					info(`AppManager::reload: set version to ${currentVersion}`);
					window.location.href = NavigationRoutes.TABS;
				}
			}, delay);
		});
	},
	checkVersion: async () => {
		setInternalStorage(SessionStorageKeys.Active, document.visibilityState === 'visible');

		const cookies = getInternalStorage(),
			currentMetadata: any =
				!xmpp.isReloading && cookies[LocalStorageKeys.Uuid] && window.navigator.onLine
					? await apiService
							.ping({ uuid: cookies[LocalStorageKeys.Uuid] })
							.then(async (_response: any) => {
								let response: any;

								if (_response?.Error === 'TypeError: Failed to fetch') {
									cookies[SessionStorageKeys.Network] = NetworkStatus.OFFLINE;

									response = require('../metadata.json');
								} else {
									cookies[SessionStorageKeys.Network] = NetworkStatus.ONLINE;
									response = _response ? _response : require('../metadata.json');
								}

								setInternalStorage(SessionStorageKeys.Network, cookies[SessionStorageKeys.Network]);
								return response;
							})
							.catch(async (error: any) => {
								cookies[SessionStorageKeys.Network] = NetworkStatus.OFFLINE;
								setInternalStorage(SessionStorageKeys.Network, NetworkStatus.OFFLINE);
								logError('AppManager::checkVersion:: Error: ', error);
								return require('../metadata.json');
							})
					: require('../metadata.json'),
			currentVersion: string = `${currentMetadata.buildMajor}.${currentMetadata.buildMinor}.${currentMetadata.buildRevision} ${currentMetadata.buildTag}`,
			dbVersion: number = currentMetadata.dbVersion;

		if (!cookies[SessionStorageKeys.IsApp] && !xmpp.isReloading) {
			info(`AppManager::checkVersion: ${currentVersion} (${cookies[SessionStorageKeys.Network]})`);

			if (window.navigator.onLine && cookies[LocalStorageKeys.Uuid] && cookies[SessionStorageKeys.Version] !== currentVersion && !cookies[SessionStorageKeys.SkipUpdates] && cookies[SessionStorageKeys.Version] && !cookies?.version?.endsWith('LOCAL') && !window.location.href.includes('local')) {
				const origin = window.location.origin;
				let upgradeReady: Boolean = false;

				axios({
					url: origin,
					method: 'HEAD',
					timeout: 1000,
				})
					.then(async (res: any) => {
						if (res.status === 200 && !upgradeReady && !currentVersion.includes('undefined')) {
							upgradeReady = true;
							let upgradeDb = parseInt(cookies[LocalStorageKeys.DBVersion]) !== dbVersion;
							info(`AppManager::checkVersion: new version ${currentVersion} to replace ${cookies[SessionStorageKeys.Version]} is ready.`);

							if (upgradeDb) {
								info(`AppManager::checkVersion: new db version ${dbVersion} to replace ${cookies[LocalStorageKeys.DBVersion]} is ready.`);
							}

							if (!cookies[SessionStorageKeys.MobileDevice]) {
								store.dispatch({
									type: SHOW_APP_UPDATE_POPUP,
									payload: {
										showAppUpdatePopup: true,
										appUpdatePopupHeading: `${locale.global.app_name}`,
										appUpdatePopupSubHeading: `${currentVersion}`,
										appUpdatePopupMessageTitle: `${locale.global.whats_new}`,
										appUpdatePopupMessage: `${locale.global[upgradeDb ? 'alert_reset' : 'alert_update']} ${currentVersion}`,
										appUpdatePopupActionBtnTitle: locale.global.upgrade_button,
										appUpdatePopupCallBack: async (res: any) => {
											xmpp.isReloading = true;
											setInternalStorage(SessionStorageKeys.Reloading, true);

											store.dispatch({
												type: SHOW_APP_UPDATE_POPUP,
												payload: {
													appUpdatePopupLoading: true,
													appUpdatePopupLoadingMessage: `${locale.global.upgrading} ${currentVersion}`,
												},
											});

											appManager.reload(1000);
										},
									},
								});

								if (!upgradeNotified) {
									await createNotification(
										{
											type: 'control',
											from: 'control',
											body: `${locale.global.upgrade_notification} ${currentVersion}`,
										},
										true
									);
									upgradeNotified = true;
								}
							} else {
								info(`AppManager::checkVersion: new version was not updated on this mobile device.  Toggle network on/off to force reload the app.`);
							}
						}
					})
					.catch((err) => {
						upgradeReady = false;
						info(`AppManager::checkVersion: new version ${currentVersion} to replace ${cookies[SessionStorageKeys.Version]} is not ready. Error was:`, err);
					});
			}
		}

		return `${currentVersion} (${cookies[SessionStorageKeys.Network]})`;
	},
};

export const AppManager = appManager;
