import { getActiveConversation, info, isConversationOpen, isPersonalNotepad, setInternalStorage } from '../../helpers/common';
import { apiService } from '../../services/apiService';
import { dbService } from '../../services/dbService';
import { LocalStorageKeys, PubSubEventType } from '../../services/enumService';
import { sharedService } from '../../services/sharedService';
import { xmpp } from '../../services/xmpp';
import { ApiResponse } from '../../types/api-responses';
import { ContactItem } from '../../types/contact';
import { GroupItem } from '../../types/group';
import { Message } from '../../types/message';
import { ReduxStates } from '../../types/redux-states';
import { CHAT_INIT, CHAT_RESET, CHAT_RELOAD, UPDATE_CHAT_HISTORY, UPDATE_LAST_MESSAGES } from '../constants/chat';
import { ACTIVE_THREAD_UPDATED } from '../constants/thread';
import store from '../store';
import { getMucMembers } from './group';
import PubSub from 'pubsub-js';

export function initChat(payload: { type: string; receiver: any }) {
	return async (dispatch: any) => {
		return new Promise(async (resolve) => {
			dispatch({ type: CHAT_RESET, payload: {} });
			dispatch({ type: ACTIVE_THREAD_UPDATED, payload: { activeThreadInChat: undefined } });

			// const userProfile = payload.receiver as ContactItem;
			const groupItem = payload.receiver as GroupItem;

			// if group chat open then fetch members
			if (groupItem.name) {
				dispatch(getMucMembers({ mucId: groupItem.name }));
			}

			// const sendIqRes = await xmpp.client.sendIQ({
			// 	vcard: {
			// 		format: NS_VCARD_TEMP,
			// 	},
			// 	id: 'vcard' + Math.floor(10000 * Math.random()),
			// 	to: `${'sdev1'}@${Config.xmppServer}`,
			// 	type: 'get',
			// });

			// dispatch({ type: CHAT_INIT, payload: { ...payload, history: currentHistory } });
			dispatch({ type: CHAT_INIT, payload: { ...payload } });

			resolve(true);
		});
	};
}

export function translateMessage({ message, currentLanguageCode }: { message: Message; currentLanguageCode: string }) {
	return async function (dispatch: any) {
		return new Promise(async (resolve, reject) => {
			try {
				if (message.translated) {
					resolve(message.translation);
					return;
				}
				const translateRes: ApiResponse = await apiService.translateMessage({ text: message.body, targetLanguageCode: currentLanguageCode });
				if (translateRes.success) {
					const translatedText = translateRes.result;
					message.translated = true;
					if (message.body === translatedText) {
						resolve(translatedText);
						return;
					}
					message.translation = translatedText;
					await dbService.addMessage(message);

					await PubSub.publish(PubSubEventType.ChatMessage, {
						action: PubSubEventType.Translated,
						message: message,
					});

					resolve(translatedText);
				} else {
					reject(translateRes.error);
				}
			} catch (error) {
				reject(error);
			}
		});
	};
}

export function getMessageIdPrefix() {
	return async (_: any) => {
		return new Promise(async (resolve) => {
			const response: ApiResponse = await apiService.getMessageIdPrefix();
			if (response.success) {
				setInternalStorage(LocalStorageKeys.MessageIdPrefixResult, response.result);
				setInternalStorage(LocalStorageKeys.MessageIdPrefixTimestamp, response.timestamp);
			}
			resolve(true);
		});
	};
}

export function updateActiveChatHistory(message: Message) {
	return async (dispatch: any) => {
		return new Promise(async (resolve) => {
			// const active = isConversationOpen();
			// if (active) {
			// 	const activeChatUsername = getActiveConversation();
			// 	const activeThreadInChat = store.getState().thread?.activeThreadInChat;
			// 	const history = await dbService.getMessages(activeThreadInChat ? activeThreadInChat.threadId : activeChatUsername);
			// 	dispatch({ type: UPDATE_CHAT_HISTORY, payload: { history: history } });
			// }

			// const active = isConversationOpen();
			// info('chat::updateActiveChatHistory::isConversationOpen', active);

			await PubSub.publish(PubSubEventType.ChatMessage, {
				action: PubSubEventType.ChatMessage,
				message: message,
			});

			// if (active) {
			// 	const activeChatUsername = getActiveConversation();
			// 	const activeThreadInChat = store.getState().thread?.activeThreadInChat;

			// 	if ((activeThreadInChat && activeThreadInChat.threadId === message.username) || (activeChatUsername && activeChatUsername === message.username && ((isPersonalNotepad() && message.isPersonalNotepad) || !isPersonalNotepad()))) {
			// 		info('chat::updateActiveChatHistory::bePubsub.send');

			// 		await PubSub.publish(PubSubEventType.ChatMessage, {
			// 			action: PubSubEventType.ChatMessage,
			// 			message: message,
			// 		});
			// 	}
			// } else {
			// 	// if ((isPersonalNotepad() && message.isPersonalNotepad) || !isPersonalNotepad()) {
			// 	// 	await PubSub.publish(PubSubEventType.ChatMessage, {
			// 	// 		action: PubSubEventType.ChatMessage,
			// 	// 		message: message,
			// 	// 	});
			// 	// }

			// 	await PubSub.publish(PubSubEventType.ChatMessage, {
			// 		action: PubSubEventType.ChatMessage,
			// 		message: message,
			// 	});
			// }

			resolve(true);
		});
	};
}

export function getMessages({ username, index, isGroupChat }: { username: string; index: number; isGroupChat: boolean }) {
	info(`chat::getMessages::username::${username} index::${index}`);

	return async (dispatch: any) => {
		return new Promise(async (resolve) => {
			const history = await dbService.getMessages(username);
			// const history = DemoMessagesList;
			const messageInfo = await dbService.getMessageInfo(username);

			info(`chat::getMessages::Total count ${messageInfo?.count} available count ${history?.length}`);
			if (index === 0) {
				dispatch({ type: UPDATE_CHAT_HISTORY, payload: { history: history } });
			}

			if (history && history.length > index && (history.length >= sharedService.messagePerSlot || history.length === messageInfo?.count)) {
				info('chat::getMessages::Message fetching from localdb');

				let startingIndex = history.length - index - sharedService.messagePerSlot;
				startingIndex = startingIndex < 0 ? 0 : startingIndex;
				const finalH = history.splice(startingIndex, history.length);
				dispatch({ type: UPDATE_CHAT_HISTORY, payload: { history: finalH } });
				const isNoMoreData = history.length === messageInfo?.count;
				resolve({ messages: finalH, isNoMoreData });
			} else {
				info('chat::getMessages::Message fetching from xmpp');
				let firstMessageId = '',
					lastMessageId = '',
					remainingMessage = 0,
					messageCountWillBeReceived = 0;

				const messagesFetched: Array<Message> = [];

				let isFetchComleted = false;

				const onMessagedFetchedComplete = async () => {
					info('chat::getMessages::onMessageFetched::Requested message fetched');
					const history = await dbService.getMessages(username);
					if (history) {
						let startingIndex = history.length - index - sharedService.messagePerSlot;
						const finalH = history.splice(startingIndex, history.length);
						const isNoMoreData = history.length === messageInfo?.count || history.length < sharedService.messagePerSlot;
						resolve({ messages: finalH, isNoMoreData });
					} else {
						resolve({ messages: [], isNoMoreData: true });
					}

					PubSub.unsubscribe(messagePubSubToken);
					// dispatch({ type: UPDATE_CHAT_HISTORY, payload: { history: history } });
				};

				const checkForAllMessageFetched = async () => {
					if (messagesFetched.length >= messageCountWillBeReceived && messageCountWillBeReceived > 0) {
						isFetchComleted = true;
						onMessagedFetchedComplete();
					}
				};

				let timeoutRef: any = null;

				const messagePubSubToken = PubSub.subscribe(PubSubEventType.ChatMessage, (msg: string, data: any) => {
					const message = data.message as Message;
					if (message?.from === username || message?.to === username) {
						messagesFetched.push(message);
					}
					info(`chat::getMessages::onMessageFetched::message::received${messagesFetched.length}::`, message);
					checkForAllMessageFetched();

					clearTimeout(timeoutRef);
					timeoutRef = setTimeout(() => {
						if (!isFetchComleted) {
							isFetchComleted = true;
							onMessagedFetchedComplete();
						}
					}, 2000);
				});

				info('chat::getMessages::sendIQForArchivedMessages');
				const iqResponse = await xmpp.sendIQForArchivedMessages(username, sharedService.messagePerSlot, messageInfo?.firstId || '', isGroupChat);
				info('chat::getMessages::iqResponse', iqResponse);

				firstMessageId = iqResponse?.archive.paging.first;
				lastMessageId = iqResponse?.archive.paging.last;
				remainingMessage = iqResponse?.archive.paging.count - index;
				messageCountWillBeReceived = remainingMessage >= sharedService.messagePerSlot ? sharedService.messagePerSlot : remainingMessage;
				checkForAllMessageFetched();

				info('chat::getMessages::messageCountWillBeReceived', messageCountWillBeReceived);

				await dbService.addMessageInfo({
					firstId: firstMessageId,
					lastId: lastMessageId,
					count: iqResponse?.archive.paging.count,
					username: username,
				});

				if (messageCountWillBeReceived === 0) {
					resolve({ messages: [], isNoMoreData: true });
				}
			}
		});
	};
}

export function lastMessagesForAllUsers() {
	return async (dispatch: any) => {
		return new Promise(async (resolve) => {
			const lastMessages: any = {};
			try {
				const states: ReduxStates = store.getState();
				let contacts = states.contact.contacts;
				let groups = states.group.groups;
				if (!contacts || contacts.length === 0) {
					contacts = (await dbService.myContacts()) || [];
				}
				if (!groups || groups.length === 0) {
					groups = (await dbService.groups()) || [];
				}

				if (contacts && contacts.length > 0) {
					for (const contact of contacts) {
						const message = await dbService.getLastMessage(contact.username);
						if (message) {
							lastMessages[message.username] = message;
						}
					}
					dispatch({ type: UPDATE_LAST_MESSAGES, payload: { lastMessages } });
				}

				if (groups && groups.length > 0) {
					for (const group of groups) {
						const message = await dbService.getLastMessage(group.name);
						if (message) {
							lastMessages[message.username] = message;
						}
					}

					dispatch({ type: UPDATE_LAST_MESSAGES, payload: { lastMessages } });
				}
			} catch (error) {
				console.error('Error in getting last messages', error);
			}
			resolve(lastMessages);
		});
	};
}

export function reloadChat(payload: any) {
	return async (dispatch: any) => {
		const conversation: any = await apiService.getConversationByHash(payload.conversationHash),
			history: any = conversation.messages;
		dispatch({ type: CHAT_RELOAD, payload: { ...payload, history: history } });
	};
}

export function uploadFile(file: File, messageKey?: string, thumbnail?: File) {
	return async function (dispatch: any) {
		return new Promise((resolve) => {
			const formData = new FormData();
			formData.append('file', file);
			if (thumbnail) {
				formData.append('thumbnail', thumbnail);
			}
			if (messageKey) {
				formData.append('messageKey', messageKey);
			}

			apiService
				.uploadFile(formData)
				.then((response: ApiResponse) => {
					if (response.success) {
						resolve(response.result);
					} else {
						resolve(false);
					}
				})
				.catch((error: any) => {
					resolve(false);
				})
				.finally(() => {});
		});
	};
}
