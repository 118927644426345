import { XMPP_CLIENT_INIT, XMPP_CONTACT_REQ } from '../constants/xmpp';

const initialState = {
	client: null,
};
// eslint-disable-next-line import/no-anonymous-default-export
export default function (state = initialState, action: any) {
	let returnValue: any;
	switch (action.type) {
		case XMPP_CONTACT_REQ: {
			returnValue = {
				...state,
				data: action.payload.data,
			};
			break;
		}
		case XMPP_CLIENT_INIT: {
			if (!state.client) {
				returnValue = {
					...state,
					client: action.payload,
				};
			} else {
				returnValue = {
					...state,
				};
			}
			break;
		}
		default:
			returnValue = { ...state };
			break;
	}
	return returnValue;
}
