import React from 'react';
import { IonContent, IonLabel, IonPage } from '@ionic/react';
import './style.scss';
import { RouteComponentProps } from 'react-router';
import { connect } from 'react-redux';
import { pageLoading, updateUser } from '../../redux/actions/profile';
import { TopNavbar } from '../common/header/topbar';
// import _ from 'lodash';
import { dbService } from '../../services/dbService';
import { DefaultProfilePic } from '../../helpers/common';
import AddContactItem from '../../components/ContactItems/AddContactItem';
import { AddMembersPageActionType } from '../../services/enumService';
import { Navigation } from '../../services/navigationService';

interface iProps extends RouteComponentProps<{ name: string }> {
	profile: any;
	loggedInUser: any;
	pageLoading: Function;
	updateUser: Function;
	location: any;
}

class GroupAdmin extends React.Component<iProps, any> {
	componentIsMounted: Boolean = false;
	componentIsUpdated: Boolean = false;
	constructor(props: iProps) {
		super(props);
	}

	componentDidMount = async () => {
		let user: any = await dbService.me();

		this.setState({
			loggedInUser: user,
		});

		this.componentIsMounted = true;
	};

	componentDidUpdate = async () => {
		if (!this.componentIsUpdated) {
			let user: any = await dbService.me();

			this.componentIsUpdated = true;

			this.setState({
				loggedInUser: user,
			});

			this.componentIsMounted = true;
		}
	};
	submitChanges() {}

	render() {
		if (this.componentIsMounted) {
			return (
				<IonPage className="group-admin-page">
					<TopNavbar
						{...this.props}
						hideSearchBar={true}
						pageTitle="Group Admin "
						showBack={true}
						onLeftButtonPress={() => {
							Navigation.back();
						}}
					/>

					<IonContent className="main-content-profile has-topbar">
						<div className="admin-container">
							<img className="default-img" src="./assets/icon/default-key.svg" alt="default-img" />
						</div>
						<div className="admin-permissions">
							<div className="permission-heading">The group admin has the following permissions</div>
							<div className="one">1. Issue Memo’s</div>
							<div className="one">2. Set up how new members can join, and approval new requests.</div>
							<div className="one">3. Add and Remove group members</div>
							<div className="one">4. Manage Chat Threads </div>
							<div className="one">5. Assign Tasks </div>
							<div className="one">6. Share QR Code</div>
						</div>

						<div className="participants">
							<IonLabel>Primary</IonLabel>
						</div>
						<div className="admin-pic">
							<img className="admin-img" src={this.state.myAliasGroupPhoto ? this.state.myAliasGroupPhoto : DefaultProfilePic} alt="profilePhoto" />
						</div>
						<div className="name">Username</div>

						<div className="secondary">
							<IonLabel>Secondary</IonLabel>
						</div>
						<div className="add">
							<AddContactItem
								onClick={() => {
									Navigation.openAddMembers({
										actionType: AddMembersPageActionType.ChooseContactForSecondaryAdmin,
									});
								}}
							/>
						</div>
						<div className="tertiary">
							<IonLabel>Tertiary</IonLabel>
						</div>
						<div className="add">
							<AddContactItem
								onClick={() => {
									Navigation.openAddMembers({
										actionType: AddMembersPageActionType.ChooseContactForTertiaryAdmin,
										previousSelectedContactsIds: this.state.selectedContactIds,
									});
								}}
							/>
						</div>
						<div className="blank"></div>
					</IonContent>
				</IonPage>
			);
		} else {
			return <></>;
		}
	}
}

const mapStateToProps = (state: any) => {
	return {
		profile: state.profile,
		loggedInUser: state.global.loggedInUser,
	};
};

const mapDispatchToProps = (dispatch: any) => ({
	updateUser: (payload: String) => dispatch(updateUser(payload)),
	pageLoading: (payload: any) => dispatch(pageLoading(payload)),
});

export default connect(mapStateToProps, mapDispatchToProps)(GroupAdmin);
