import React from 'react';
import { IonImg, IonThumbnail } from '@ionic/react';
import './style.scss';
import NotificationBadge from '../../../components/NotificationBadge';
import SharedService from '../../../services/sharedService';
import { DefaultGroupPhoto, DefaultProfilePic } from '../../../helpers/common';

const ConversationProfilePhoto: React.FC<any> = ({ conversation }: { conversation: any }) => {
	return (
		<div className="conversation-profile-pic-container">
			<IonThumbnail className="thumb-user" slot="start" onClick={() => {}}>
				<IonImg src={conversation.username ? SharedService.extractProfilePhoto(conversation.userInfo.profilePhotos)?.url ?? DefaultProfilePic : conversation.mucName ? conversation.mucImage ?? DefaultGroupPhoto : ''}></IonImg>
			</IonThumbnail>
			<NotificationBadge unreadCount={0} />
		</div>
	);
};
export default ConversationProfilePhoto;
