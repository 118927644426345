export const REGISTER_SHOW_LOADER = 'REGISTER_SHOW_LOADER';
export const AUTH_SHOW_LOADER = 'AUTH_SHOW_LOADER';
export const DASHBOARD_SHOW_LOADER = 'DASHBOARD_SHOW_LOADER';
export const USER_PROFILE_SHOW_LOADER = 'USER_PROFILE_SHOW_LOADER';

export const REGISTER_FORM_ERROR = 'REGISTER_FORM_ERROR';
export const LOGIN_FORM_ERROR = 'LOGIN_FORM_ERROR';
export const AUTH_FORM_ERROR = 'AUTH_FORM_ERROR';
export const DASHBOARD_FORM_ERROR = 'DASHBOARD_FORM_ERROR';

export const REGISTER_GENERAL_ERROR = 'REGISTER_GENERAL_ERROR';

export const REGISTER_RESET_VALUES = 'REGISTER_RESET_VALUES';
export const AUTH_RESET_VALUES = 'AUTH_RESET_VALUES';
export const DASHBOARD_RESET_VALUES = 'DASHBOARD_RESET_VALUES';
export const USER_PROFILE_RESET_VALUES = 'USER_PROFILE_RESET_VALUES';
export const PUBSUB_RESET_VALUES = 'PUBSUB_RESET_VALUES';
export const USER_LOGIN = 'USER_LOGIN';
export const USER_LOGOUT = 'USER_LOGOUT';

export const SHOW_ALERT = 'SHOW_ALERT';
export const SHOW_STYLISH_TOAST = 'SHOW_STYLISH_TOAST';
export const SHOW_TOAST = 'SHOW_TOAST';
export const SHOW_APP_UPDATE_POPUP = 'SHOW_APP_UPDATE_POPUP';
export const SHOW_LOADER = 'SHOW_LOADER';

export const PUBLISH_DATA = 'PUBLISH_DATA';
