export const CONTACT_RESET_STATES = 'CONTACT_RESET_STATES';

export const CONTACT_INIT = 'CONTACT_INIT';

export const SEND_INVITATION_START = 'SEND_INVITATION_START';
export const SEND_INVITATION_FAILED = 'SEND_INVITATION_FAILED';
export const SEND_INVITATION_SUCCESS = 'SEND_INVITATION_SUCCESS';

export const ACCEPT_INVITATION_START = 'ACCEPT_INVITATION_START';
export const ACCEPT_INVITATION_FAILED = 'ACCEPT_INVITATION_FAILED';
export const ACCEPT_INVITATION_SUCCESS = 'ACCEPT_INVITATION_SUCCESS';

export const REJECT_INVITATION_START = 'REJECT_INVITATION_START';
export const REJECT_INVITATION_FAILED = 'REJECT_INVITATION_FAILED';
export const REJECT_INVITATION_SUCCESS = 'REJECT_INVITATION_SUCCESS';

export const BLOCK_USER_START = 'BLOCK_USER_START';
export const BLOCK_USER_FAILED = 'BLOCK_USER_FAILED';
export const BLOCK_USER_SUCCESS = 'BLOCK_USER_SUCCESS';

export const UNBLOCK_USER_START = 'BLOCK_USER_START';
export const UNBLOCK_USER_FAILED = 'BLOCK_USER_FAILED';
export const UNBLOCK_USER_SUCCESS = 'BLOCK_USER_SUCCESS';

export const CHANGe_CONTACT_PRIVACY_SETTING_START = 'CHANGe_CONTACT_PRIVACY_SETTING_START';
export const CHANGe_CONTACT_PRIVACY_SETTING_FAILED = 'CHANGe_CONTACT_PRIVACY_SETTING_FAILED';
export const CHANGe_CONTACT_PRIVACY_SETTING_SUCCESS = 'CHANGe_CONTACT_PRIVACY_SETTING_SUCCESS';

export const GET_INVITATION_REQUEST_START = 'GET_INVITATION_REQUEST_START';
export const GET_INVITATION_REQUEST_FAILED = 'GET_INVITATION_REQUEST_FAILED';
export const GET_INVITATION_REQUEST_SUCCESS = 'GET_INVITATION_REQUEST_SUCCESS';

export const GET_ROASTER_ITEMS_START = 'GET_ROASTER_ITEMS_START';
export const GET_ROASTER_ITEMS_FAILED = 'GET_ROASTER_ITEMS_FAILED';
export const GET_ROASTER_ITEMS_SUCCESS = 'GET_ROASTER_ITEMS_SUCCESS';

export const GET_BLOCKED_ROASTER_ITEMS_START = 'GET_BLOCKED_ROASTER_ITEMS_START';
export const GET_BLOCKED_ROASTER_ITEMS_FAILED = 'GET_BLOCKED_ROASTER_ITEMS_FAILED';
export const GET_BLOCKED_ROASTER_ITEMS_SUCCESS = 'GET_BLOCKED_ROASTER_ITEMS_SUCCESS';

export const REMOVE_ROASTER_ITEM_START = ' REMOVE_ROASTER_ITEM_START';
export const REMOVE_ROASTER_ITEM_FAILED = 'REMOVE_ROASTER_ITEM_FAILED';
export const REMOVE_ROASTER_ITEM_SUCCESS = 'REMOVE_ROASTER_ITEM_SUCCESS';
