import { USER_LOGIN, USER_LOGOUT, SHOW_LOADER, SHOW_ALERT, SHOW_TOAST, SHOW_APP_UPDATE_POPUP, SHOW_STYLISH_TOAST } from '../constants/common';
import { locale } from '../../locales/local';
import { GlobalStateTypes } from '../../types/redux-states';

const initialState: GlobalStateTypes = {
	isLoggedIn: false,
	darkTheme: false,
	xmpp: false,

	showToast: false,
	toastMessage: '',
	toastType: 'primary',
	toastCallBack: null,
	toastButtonText: '',

	showStylishToast: false,
	stylishToastMessage: '',
	stylishToastIcon: 'check',

	showAlert: false,
	alertTitle: '',
	alertMessage: '',

	loaderMessage: locale.reducers.global.init_state.connecting,
	isLoading: false,
	appUpdatePopupLoading: false,
	appUpdatePopupLoadingMessage: '',
	showAppUpdatePopup: false,
	appUpdatePopupHeading: '',
	appUpdatePopupSubHeading: '',
	appUpdatePopupMessageTitle: '',
	appUpdatePopupMessage: '',
	appUpdatePopupActionBtnTitle: '',
	showAppUpdatePopupCssClass: '',
	appUpdatePopupCallBack: null,
};

// eslint-disable-next-line import/no-anonymous-default-export
export default function (state = initialState, action: any) {
	switch (action.type) {
		case USER_LOGIN: {
			state.isLoggedIn = true;
			state.loggedInUser = action.payload;
			break;
		}
		case USER_LOGOUT: {
			state.isLoggedIn = false;
			state.loggedInUser = {};
			break;
		}

		case SHOW_TOAST: {
			state.showToast = action.payload.showToast;
			state.toastMessage = action.payload.toastMessage;
			state.toastType = action.payload.toastType;
			state.toastButtonText = action.payload.toastButtonText;
			state.toastCallBack = action.payload.toastCallBack;
			break;
		}

		case SHOW_STYLISH_TOAST: {
			state.showStylishToast = action.payload.showStylishToast;
			state.stylishToastMessage = action.payload.stylishToastMessage;
			if (action.payload.stylishToastIcon) state.stylishToastIcon = action.payload.stylishToastIcon;
			break;
		}

		case SHOW_ALERT: {
			state.showAlert = action.payload.showAlert;
			state.alertMessage = action.payload.alertMessage;
			state.alertTitle = action.payload.alertTitle;
			break;
		}

		case SHOW_LOADER: {
			state.isLoading = action.payload.isLoading;
			state.loaderMessage = action.payload.loaderMessage;
			break;
		}

		case SHOW_APP_UPDATE_POPUP: {
			if (action.payload.appUpdatePopupLoading !== undefined) {
				state.appUpdatePopupLoading = action.payload.appUpdatePopupLoading;
			}
			if (action.payload.appUpdatePopupLoadingMessage !== undefined) {
				state.appUpdatePopupLoadingMessage = action.payload.appUpdatePopupLoadingMessage;
			}
			if (action.payload.showAppUpdatePopup !== undefined) {
				state.showAppUpdatePopup = action.payload.showAppUpdatePopup;
			}
			if (action.payload.appUpdatePopupHeading !== undefined) {
				state.appUpdatePopupHeading = action.payload.appUpdatePopupHeading;
			}
			if (action.payload.appUpdatePopupSubHeading !== undefined) {
				state.appUpdatePopupSubHeading = action.payload.appUpdatePopupSubHeading;
			}
			if (action.payload.appUpdatePopupMessageTitle !== undefined) {
				state.appUpdatePopupMessageTitle = action.payload.appUpdatePopupMessageTitle;
			}
			if (action.payload.appUpdatePopupMessage !== undefined) {
				state.appUpdatePopupMessage = action.payload.appUpdatePopupMessage;
			}
			if (action.payload.appUpdatePopupActionBtnTitle !== undefined) {
				state.appUpdatePopupActionBtnTitle = action.payload.appUpdatePopupActionBtnTitle;
			}
			if (action.payload.showAppUpdatePopupCssClass !== undefined) {
				state.showAppUpdatePopupCssClass = action.payload.showAppUpdatePopupCssClass;
			}
			if (action.payload.appUpdatePopupCallBack !== undefined) {
				state.appUpdatePopupCallBack = action.payload.appUpdatePopupCallBack;
			}
			break;
		}
		default:
			break;
	}
	return { ...state };
}
