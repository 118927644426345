import { DASHBOARD_CREATE_GROUP_FAILED, DASHBOARD_CREATE_GROUP_REQUESTED, DASHBOARD_CREATE_GROUP_SUCCESS, DASHBOARD_SEARCH_ERROR, DASHBOARD_HIDE_LOADER, DASHBOARD_SEARCH_FAILED, DASHBOARD_SEARCH_REQUESTED, DASHBOARD_SEARCH_SUCCESS } from '../constants/dashboard';

import { DASHBOARD_SHOW_LOADER, DASHBOARD_FORM_ERROR, DASHBOARD_RESET_VALUES, SHOW_TOAST } from '../constants/common';
import { apiService } from '../../services/apiService';
import { locale } from '../../locales/local';
import store from '../store';
import { Navigation } from '../../services/navigationService';
import { ChatType } from '../../services/enumService';
import { showToast } from './global';

export function resetValues() {
	return { type: DASHBOARD_RESET_VALUES };
}

export function pageLoading(payload: any) {
	return { type: DASHBOARD_SHOW_LOADER, payload: payload };
}

export function hidePageLoading() {
	return { type: DASHBOARD_HIDE_LOADER };
}

export function formHasErrors(payload: any) {
	return { type: DASHBOARD_FORM_ERROR, payload: payload };
}

export function searchContacts(payload: String) {
	return async function () {
		store.dispatch({ type: DASHBOARD_SEARCH_REQUESTED, payload: { loader: true, loaderMessage: locale.global.searching } });

		let response: any = await apiService.getUsers(payload);
		if (response.Error) {
			store.dispatch({ type: DASHBOARD_SEARCH_FAILED, payload: response });
			store.dispatch({ type: DASHBOARD_SEARCH_ERROR, payload: response.Error });
			store.dispatch(
				showToast({
					toastMessage: response.Error || locale.global.error_occured,
					toastType: 'danger',
				})
			);
		} else {
			store.dispatch({ type: DASHBOARD_SEARCH_SUCCESS, payload: response, showInvite: response.length > 0 || true });
		}

		return response;
	};
}

export function addGroup(payload: any) {
	return async function () {
		store.dispatch({ type: DASHBOARD_CREATE_GROUP_REQUESTED });

		// sending to server, so addGroup will prefix the photos upon return, update the user and add the group as a contact
		let response: any = await apiService.addGroup({ ...payload, type: ChatType.GROUP }, true);

		if (!response.Error) {
			store.dispatch({ type: DASHBOARD_CREATE_GROUP_SUCCESS, payload: response });
			Navigation.chat({
				receiver: response,
			});
		} else {
			store.dispatch({ type: DASHBOARD_CREATE_GROUP_FAILED, payload: response.Error });
			store.dispatch({ type: SHOW_TOAST, payload: { showToast: true, toastMessage: response.Error.message || response.Error } });
		}
	};
}
