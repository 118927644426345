import React from 'react';
import { IonContent, IonPage, IonAlert, IonButton } from '@ionic/react';
import './ChatOnly.scss';
import { RouteComponentProps } from 'react-router';
import { TopNavbar } from '../../common/header/topbar';
import { ContactsList } from '../components/ContactsList';
import { connect } from 'react-redux';
import _ from 'lodash';
import { resetValues } from '../../../redux/actions/dashboard';
import { AddMembersPageActionType, ContactPrivacySettingType, ContactsListMode, ContactsListType } from '../../../services/enumService';
import { ContactAlbhabetView } from '../components/ContactAlbhabetView';
import { sharedService } from '../../../services/sharedService';
import { updateChatOnlyProfile } from '../../../redux/actions/profile';
import { ChangePrivacySettingData, UpdateChatOnlyProfileRequestData } from '../../../types/api-requests';
import { ContactStateTypes, ProfileStateTypes, ReduxStates } from '../../../types/redux-states';
import { changePrivacySetting, getBlockedRosterItems } from '../../../redux/actions/contact';
import { ContactItem } from '../../../types/contact';
import { ContactsListNavParams } from '../../../types/navigation';
import { Navigation } from '../../../services/navigationService';

const BlankUserProfile = './assets/img/blank-user-profile.png';

interface iProps extends RouteComponentProps<{ name: string }> {
	resetValues: Function;
	location: any;
	dashboard: any;
	contact: ContactStateTypes;
	profile: ProfileStateTypes;
	updateChatOnlyProfile: (data: UpdateChatOnlyProfileRequestData) => Promise<unknown>;
	changePrivacySetting: (data: ChangePrivacySettingData) => Promise<unknown>;
	getBlockedRosterItems: () => any;
}
interface iState {
	loggedInUser?: any;
	contacts: any;
	contactsType: any;
	selectedContactsId: any;
	selectedContacts: any;
	pageActionType: string;
	showSearchContactsModal: boolean;
	showAlert: boolean;
	isSelectMode: boolean;
	RemoveAlert: boolean;
	contactListType: any;
}

const ContactsType = {
	All: 'all',
	GroupChats: 'groups-chats',
	Tags: 'tags',
	ChatsOnly: 'chats-only',
};

class ChatOnly extends React.Component<iProps, iState> {
	isComponentMounted: boolean = false;
	navParams: ContactsListNavParams = {} as any;

	constructor(props: iProps) {
		super(props);

		this.state = {
			loggedInUser: {},
			showSearchContactsModal: false,
			showAlert: false,
			contacts: [],
			selectedContactsId: [],
			selectedContacts: [],
			contactsType: '',
			pageActionType: '',
			isSelectMode: false,
			RemoveAlert: false,
			contactListType: '',
		};
	}

	async componentDidMount() {
		this.isComponentMounted = true;

		Navigation.getNavParamData(this.props).then((res: any) => {
			this.navParams = res;
			this.setState({
				contacts: this.props.contact.contacts,
				contactListType: this.navParams.contactsListType ? this.navParams.contactsListType : ContactPrivacySettingType.CHATS_ONLY,
			});
		});
	}

	componentWillUnmount() {
		this.isComponentMounted = false;
	}

	async componentDidUpdate() {}

	setSearchText() {}

	_onDone = () => {};

	_onClose = () => {
		Navigation.back();
	};

	confirmRemove = () => {
		if (this.state.selectedContacts && this.state.selectedContacts.length > 0) {
			const usernames = this.state.selectedContacts.map((item: ContactItem) => item.username);
			this.props.changePrivacySetting({ privacySetting: ContactPrivacySettingType.MOMENTS, users: usernames }).finally(() => {
				this.setState({
					isSelectMode: false,
					selectedContacts: [],
					selectedContactsId: [],
				});
			});
		}
	};

	async scrollToContactSection(elementId: string, ionContentId: string) {
		const element: any = document.getElementById(elementId);
		let ionContent: any = document.getElementById(ionContentId);
		if (ionContent) {
			const elem = await ionContent.getScrollElement();
			elem?.scrollTo(0, element.offsetTop);
		}
	}

	_onContactSelect = (contact: any) => {
		let tmpList: any = this.state.selectedContactsId || [];
		let contactsList: any = this.state.selectedContacts || [];
		const contactIdIndex = tmpList.indexOf(contact.username);
		if (contactIdIndex === -1) {
			if (this.state.selectedContactsId.length >= sharedService.groupMaxMembersLimit) {
				this.setState({ showAlert: true });
			} else {
				const selectedContactsContainerElem = document.getElementById('selectedContactsContainer');
				if (selectedContactsContainerElem && selectedContactsContainerElem?.scrollWidth > 0) {
					setTimeout(() => {
						selectedContactsContainerElem?.scrollTo(selectedContactsContainerElem.scrollWidth, 0);
					}, 200);
				}
				tmpList.push(contact.username);
				contactsList.push(contact);
			}
		} else {
			tmpList.splice(contactIdIndex, 1);
			contactsList.splice(contactIdIndex, 1);
		}
		this.setState({
			selectedContactsId: tmpList,
			selectedContacts: contactsList,
		});
	};

	extractContactName = (contact: any) => {
		return contact?.alias || contact?.username || contact?.userId || '';
	};

	render() {
		const { contactsType, pageActionType, selectedContacts } = this.state;
		let alphabetsList = this.props.contact.contacts?.map((contact: any) => {
			const contactName = contact?.alias || contact?.username || contact?.userId || '';
			return contactName.charAt(0);
		});
		alphabetsList = _.uniq(alphabetsList).sort();
		let contactsList: Array<ContactItem> = [];
		if (this.state.contactListType === ContactsListType.BLOCK) {
			contactsList = this.props.contact.blockedList || [];
		} else if (this.state.contactListType === ContactsListType.MY_MOMENTS) {
			contactsList =
				this.props.contact.contacts?.filter((contact: ContactItem) => {
					return contact.privacySetting === ContactPrivacySettingType.CHATS_ONLY;
				}) || [];
		} else {
			contactsList =
				this.props.contact.contacts?.filter((contact: ContactItem) => {
					return contact.privacySetting === ContactPrivacySettingType.CHATS_ONLY;
				}) || [];
		}

		let showSelectedContactsView = false,
			previousSelectedContactsIds;
		let pageTitle: string = '';
		let hideSearchBar: boolean = false;
		if (this.state.contactListType === ContactsListType.MY_MOMENTS) {
			pageTitle = 'Hide My Posts' + ' ' + '(' + contactsList.length + ')';
			hideSearchBar = true;
		} else if (this.state.contactListType === ContactsListType.THERE_MOMENTS) {
			pageTitle = 'Hide Their Posts' + ' ' + '(' + contactsList.length + ')';
			hideSearchBar = true;
		} else if (this.state.contactListType === ContactsListType.BLOCK) {
			pageTitle = 'Blocked List' + ' ' + '(' + contactsList.length + ')';
		} else if (this.state.contactListType === ContactsListType.CHAT_ONLY) {
			pageTitle = 'Chat Only' + ' ' + '(' + contactsList.length + ')';
		}

		return (
			<>
				<IonPage className="chatonly-page">
					<TopNavbar
						{...this.props}
						pageTitle={pageTitle}
						showBack={true}
						rightButtonDisabled={false}
						hideSearchBar={hideSearchBar}
						searchTypeHandler={this.setSearchText}
						isHideRightButton={[ContactsListType.BLOCK, ContactsListType.THERE_MOMENTS, ContactsListType.MY_MOMENTS].indexOf(this.state.contactListType!) !== -1}
						onLeftButtonPress={() => {
							Navigation.back();
						}}
						onRightButtonPress={() => {
							Navigation.openEditProfile({
								showChatOnly: true,
							});
							if (pageActionType === AddMembersPageActionType.ChooseContactForGroup) {
								Navigation.openGroupManager({
									selectedContactIds: this.state.selectedContactsId,
									selectedContacts: this.state.selectedContacts,
								});
							} else if (pageActionType === AddMembersPageActionType.ChooseContactForCurrentGroup) {
								const state: any = this.props.history.location.state;
								if (state?.fromPageKey) {
									sharedService.callBackData = {
										...sharedService.callBackData,
										[state?.fromPageKey]: {
											groupAllContactIds: state?.previousSelectedContactsIds ? [...state.previousSelectedContactsIds, ...this.state.selectedContactsId] : [...this.state.selectedContactsId],
										},
									};
								}
								Navigation.profileTab();
							}
						}}
						appendAdditionalViewOnBottom={
							showSelectedContactsView ? (
								<div className="selected-contacts-container" id="selectedContactsContainer">
									{selectedContacts.map((_contact: any, key: string) => {
										const profilePhoto = _contact.hasOwnProperty('profilePhoto') && _contact.profilePhoto ? _contact.profilePhoto : BlankUserProfile;
										return (
											<div className="contact-item" key={key} onClick={() => this._onContactSelect(_contact)}>
												<img alt="" src={profilePhoto} />
												<div className="title">{this.extractContactName(_contact)}</div>
											</div>
										);
									})}
								</div>
							) : null
						}
					/>

					<IonContent
						id="add-members-content"
						className={hideSearchBar === true ? 'has-topbar' : 'has-topbar-with-searchbar'}
						style={{
							'--background': contactsType === ContactsType.All ? '#fff' : 'transparent',
						}}
					>
						{showSelectedContactsView && <div className="selected-contacts-space-fill"></div>}

						{this.state.contactListType === ContactsListType.CHAT_ONLY && <div className="chats-only-msg">Chats Only friends are excluded from seeing any personal information, Profile Photos, or your moments.</div>}

						<ContactsList
							props={this.props}
							showDisableContactChecked={true}
							disabledContacts={previousSelectedContactsIds}
							starredContacts={[]}
							contacts={contactsList}
							contactListMode={this.state.isSelectMode ? ContactsListMode.ChooseContactsList : ''}
							selectedContacts={this.state.selectedContactsId}
							onContactSelect={this._onContactSelect}
							isChatOnly={'chatOnly'}
						/>
						<ContactAlbhabetView
							alphabets={alphabetsList}
							showStarredInsideAlbhabetList={true}
							onAlphabetSelect={async (albhabet: any) => {
								this.scrollToContactSection('section_' + albhabet, 'add-members-content');
							}}
							onGoToStarredContact={async () => {
								this.scrollToContactSection('section_starred', 'add-members-content');
							}}
						/>
					</IonContent>

					{this.state.contactListType !== ContactsListType.BLOCK && (
						<>
							{this.state.isSelectMode ? (
								<div className="bottom-button">
									<IonButton
										fill="clear"
										className="cancel-after"
										onClick={() =>
											this.setState({
												isSelectMode: false,
												selectedContacts: [],
												selectedContactsId: [],
											})
										}
									>
										Cancel
									</IonButton>

									<IonButton fill="clear" className={this.state.selectedContacts.length > 0 ? 'active' : 'done'} onClick={() => this.setState({ showAlert: true })}>
										Remove(
										{this.state.selectedContacts.length > 0 && this.state.selectedContacts.length})
									</IonButton>
								</div>
							) : (
								<div className="bottom-button">
									<IonButton fill="clear" className="remove" onClick={() => this.setState({ isSelectMode: true })}>
										Remove
									</IonButton>
									<IonButton
										fill="clear"
										className="add"
										onClick={() => {
											Navigation.openAddMembers({
												actionType: AddMembersPageActionType.ChooseContactForAddChatOnlyContact,
												data: { type: this.state.contactListType },
												previousSelectedContactsIds: contactsList.map((_item: any) => _item.username),
											});
										}}
									>
										Add
									</IonButton>
								</div>
							)}
						</>
					)}

					<IonAlert
						isOpen={this.state.RemoveAlert}
						onDidDismiss={() => {
							this.setState({ RemoveAlert: false });
						}}
						header="Alert"
						subHeader="Important message"
						message="This is an alert!"
						buttons={['OK']}
					/>
					<IonAlert
						mode="ios"
						isOpen={this.state.showAlert}
						onDidDismiss={() => this.setState({ showAlert: false })}
						cssClass="remove-warning-alert"
						message={
							this.state.contactListType === ContactsListType.THERE_MOMENTS
								? 'After removing, you’ll see selected contacts moments in feed. Continue removing?'
								: this.state.contactListType === ContactsListType.MY_MOMENTS
								? 'After removing, selected friends will see your updates on Moments. Continue removing?'
								: 'By removing contacts from Chats Only list you are aware they will now be able to view your moments, status, and other profile information, etc...?'
						}
						buttons={[
							{
								text: 'Cancel',
								role: 'cancel',
							},
							{
								text: 'OK',
								role: 'destructive',
								cssClass: 'ok',
								handler: () => {
									this.confirmRemove();
								},
							},
						]}
					/>
				</IonPage>
			</>
		);
	}
}

const mapStateToProps = (state: ReduxStates) => {
	return {
		contact: state.contact,
		// dashboard: state.dashboard,
		loggedInUser: state.global.loggedInUser,
		isLoggedIn: state.auth.isLoggedIn,
		profile: state.profile,
	};
};

const mapDispatchToProps = (dispatch: any) => ({
	resetValues: () => dispatch(resetValues()),
	getBlockedRosterItems: () => dispatch(getBlockedRosterItems()),
	changePrivacySetting: (payload: ChangePrivacySettingData) => dispatch(changePrivacySetting(payload, { loaderMessage: 'Removing' })),
	updateChatOnlyProfile: (payload: UpdateChatOnlyProfileRequestData) => dispatch(updateChatOnlyProfile(payload)),
});

export default connect(mapStateToProps, mapDispatchToProps)(ChatOnly);
